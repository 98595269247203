import React from "react";
import { Card, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import CreateButton from "./CreateButton";
import DeleteButton from "./DeleteButton";
import ProductionListSelector from "./ProductionListSelector";
import RenameButton from "./RenameButton";
import SaveButton from "./SaveButton";
import styled from "styled-components";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
`;

const Selector = styled.div`
  margin-right: 6px;
`;

const Actions = styled.div`
  flex: 1;
  text-align: right;
  & > * {
    margin: 6px;
  }
`;

const useStyles = makeStyles((theme) => ({
  titleBar: {
    flex: "0 0 auto",
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const TitleBar = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Card className={classes.titleBar}>
      <Typography variant="h1" gutterBottom>
        {t("productionList:production_lists")}
      </Typography>

      <Row>
        <Selector>
          <ProductionListSelector />
        </Selector>
        <Actions>
          <CreateButton />
          <RenameButton />
          <SaveButton />
          <DeleteButton />
        </Actions>
      </Row>
    </Card>
  );
};

export default TitleBar;
