import React from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import AddIcon from "@mui/icons-material/AddCircleOutlineSharp";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
    width: 200,
    height: "100%",
    borderRadius: 5,
    padding: 5,
    border: `1px solid ${theme.palette.grey[400]}`,
  },
  icon: {
    fontSize: (props) => (props.size === "large" ? 50 : 40),
    marginBottom: theme.spacing(1),
  },
  disabled: {
    opacity: 0.4,
  },
}));

const EmptyCard = ({ message, size, onClick, disabled }) => {
  const classes = useStyles({ size });

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.disabled]: disabled,
      })}
      onClick={(event) => {
        if (!disabled) onClick(event);
      }}
    >
      <AddIcon className={classes.icon} />
      <Typography variant={size === "large" ? "h4" : "body1"} color="inherit">
        {message}
      </Typography>
    </div>
  );
};

EmptyCard.propTypes = {
  disabled: PropTypes.bool,
  message: PropTypes.string,
  onClick: PropTypes.func,
};

EmptyCard.defaultProps = {
  disabled: false,
};

export default EmptyCard;
