import React from "react";
import AddIcon from "@mui/icons-material/AddCircle";
import IconButton from "@mui/material/IconButton";

interface Props {
  disabled: boolean;
  onClick: () => void;
};

const AddButton = ({ disabled, onClick }: Props) => {
  return (
    <IconButton color="primary" disabled={disabled} onClick={onClick}>
      <AddIcon sx={{ fontSize: 30 }} />
    </IconButton>
  )
};

export default AddButton;
