import React from "react";
import { Box, Chip, SxProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { RangeInput } from "@mui/lab/DateRangePicker/RangeTypes";
import { Dayjs } from "dayjs";
import useDefaultPlantStartEndTimes from "../hooks/useDefaultPlantStartEndTimes";

interface Props {
  sx?: SxProps;
  onDateRangeChange: (dateRange: RangeInput<Dayjs>) => void;
};

const PresetDateRanges = ({ sx = {}, onDateRangeChange }: Props) => {
  const { t } = useTranslation();
  const plantWorkHours = useDefaultPlantStartEndTimes();
  const buttons = [
    {
      label: t("railMessageEventsMetrics:today"),
      range: [plantWorkHours.startTime, plantWorkHours.endTime],
    },
    {
      label: t("railMessageEventsMetrics:yesterday"),
      range: [plantWorkHours.startTime.subtract(1, "day"), plantWorkHours.endTime.subtract(1, "day")],
    },
    {
      label: t("railMessageEventsMetrics:one_week"),
      range: [plantWorkHours.endTime.subtract(1, "week"), plantWorkHours.endTime],
    },
    {
      label: t("railMessageEventsMetrics:one_month"),
      range: [plantWorkHours.endTime.subtract(1, "month"), plantWorkHours.endTime],
    },
    {
      label: t("railMessageEventsMetrics:six_months"),
      range: [plantWorkHours.endTime.subtract(6, "month"), plantWorkHours.endTime],
    },
    {
      label: t("railMessageEventsMetrics:one_year"),
      range: [plantWorkHours.endTime.subtract(1, "year"), plantWorkHours.endTime],
    },
  ];

  return (
    <Box display="flex" sx={{ ...sx }}>
      {buttons.map((button) => (
        <Chip
          key={button.label}
          sx={{ mb: 1, mr: 1 }}
          label={button.label}
          color="secondary"
          variant="outlined"
          onClick={() => onDateRangeChange(button.range as RangeInput<Dayjs>)}
        />
      ))}
    </Box>
  )
};

export default PresetDateRanges;
