import React from "react";
import { Button, FormControlLabel, SxProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import ClearIcon from "@mui/icons-material/ClearAll";
import { useSlingInputStation } from "../../../selectors/slingInputStations";
import { useCurrentRole } from "../../../selectors/useRole";
import { clearWeightsForSis } from "../../../api/SlingInputStationApi";

interface Props {
  sisId: number;
  disabled?: boolean;
  sx?: SxProps;
};

const ConciseClearWeightsButton = ({ sisId, disabled, sx }: Props) => {
  const { t } = useTranslation();
  const sis = useSlingInputStation(sisId) || {};
  const { canControlInputLifts } = useCurrentRole();

  return (
    <FormControlLabel
      control={
        <Button
          sx={(theme) => ({
            borderRadius: "50%",
            padding: 2,
            fontSize: 40,

            "&:disabled": {
              backgroundColor: theme.palette.grey[200],
            },

            "& #start-icon": {
              fontSize: 40,
            },
          })}
          variant="contained"
          disableElevation={true}
          size="large"
          disabled={disabled || !canControlInputLifts}
          onClick={() => clearWeightsForSis(sis?.id)}
        >
          <ClearIcon id="start-icon" />
        </Button>
      }
      label={t<string>("slingInputStation:reset_weights")}
      labelPlacement="top"
      sx={sx}
    />
  );
};

export default ConciseClearWeightsButton;
