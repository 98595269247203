import { LOGIN_STARTED, LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT_STARTED, LOGOUT_SUCCESS, LOGOUT_FAILURE } from "./types";
import {
  getCurrentUser,
  login as eVueLogin,
  loginCardReader as eVueLoginCardReader,
  logout as eVueLogout,
} from "../api/SessionAPI";
import { showAlertWarning, showAlertSuccess, showAlertError } from "./index";
import { getUserUISettings } from "../selectors/useUser";
import store from "../store";
import i18n from "../i18n";
import dayjs, { mapToLocaleString } from "../dayjs";

export function initializeSession() {
  return (dispatch) => {
    getCurrentUser()
      .then((res) => {
        if (res.data.currentUser) {
          dispatch(showAlertSuccess(i18n.t("users:welcome_back")));
          return dispatch(loginSuccess(res.data.currentUser));
        }
      })
      .catch((err) => console.log(err));
  };
}

export function login(username, password) {
  return (dispatch) => {
    dispatch(loginStarted());
    dispatch(showAlertWarning(i18n.t("users:logging_in")));
    eVueLogin(username, password)
      .then((res) => {
        dispatch(loginSuccess(res.data.currentUser));
        dispatch(showAlertSuccess(i18n.t("users:welcome_back")));
      })
      .catch((_err) => {
        dispatch(loginFailure());
        dispatch(showAlertError(i18n.t("users:incorrect_password")));
      });
  };
}

export function loginCardReader(swipe) {
  return (dispatch) => {
    dispatch(loginStarted());
    dispatch(showAlertWarning(i18n.t("users:logging_in")));
    eVueLoginCardReader(swipe)
      .then((res) => {
        dispatch(loginSuccess(res.data.currentUser));
        dispatch(showAlertSuccess(i18n.t("users:welcome_back")));
      })
      .catch((_err) => {
        dispatch(loginFailure());
        dispatch(showAlertError(i18n.t("users:unrecognized_card")));
      });
  };
}

export function loginSuccess(userId) {
  const state = store.getState();
  const curLang = (state.users[userId] || {}).language || state.uiSettings.defaultLanguage;
  i18n.changeLanguage(curLang);
  dayjs.locale(mapToLocaleString(curLang));

  const userUISettings = getUserUISettings(state, userId);

  return {
    type: LOGIN_SUCCESS,
    data: {
      userId,
      batchTopColor: userUISettings.batchTopColor,
      batchBottomColor: userUISettings.batchBottomColor,
      batchTopText: userUISettings.batchTopText,
      batchBottomText: userUISettings.batchBottomText,
      overviewContext: userUISettings.overviewContext,
    },
  };
}

export function loginFailure() {
  return {
    type: LOGIN_FAILURE,
  };
}

export function loginStarted() {
  return {
    type: LOGIN_STARTED,
  };
}

export function logout() {
  return (dispatch) => {
    dispatch(logoutStarted());
    dispatch(showAlertWarning(i18n.t("users:logging_out")));
    eVueLogout()
      .then((res) => {
        dispatch(logoutSuccess());
        dispatch(showAlertSuccess(i18n.t("users:goodbye")));
      })
      .catch((_err) => dispatch(logoutFailure()));
  };
}

export function logoutSuccess() {
  const defaultLanguage = store.getState().uiSettings.defaultLanguage;
  i18n.changeLanguage(defaultLanguage);
  dayjs.locale(mapToLocaleString(defaultLanguage));
  return {
    type: LOGOUT_SUCCESS,
  };
}

export function logoutFailure() {
  return {
    type: LOGOUT_FAILURE,
  };
}

export function logoutStarted() {
  return {
    type: LOGOUT_STARTED,
  };
}
