import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import _ from "lodash";
import Client from "../../api/Client";

const tagSelectionSlice = createSlice({
  name: "tagSelection",
  initialState: {},
  reducers: {
    setData: (_state, action) => {
      return { ...action.payload };
    },
  },
});

export function tagSelectionLoad() {
  return (dispatch) => {
    Client.get(`control_api/v1/rails/all_tag_rails`)
      .then((response) => {
        dispatch(tagSelectionSlice.actions.setData(response.data));
      })
      .catch((error) => {
        const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
        console.log(errorMessage);
      });
  };
}

// Selectors
export const useTagSelection = (id) =>
  useSelector((state) => state.tagSelection[id], _.isEqual) || { destRailIds: [], storageRailIds: [], otherIds: [] };

export default tagSelectionSlice.reducer;
