import React, { useEffect, useRef, useState, useCallback } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { VariableSizeGrid } from "react-window";
import FlagCard from "../FlagCard";
import EmptyCard from "../EmptyCard";
import SelectionDialogWithFilter from "./SelectionDialogWithFilter";
import { useCategory, useCategories } from "../../selectors/categories";
import CategoryCardStandard from "../FlagSelectors/CategoryCardStandard";
import { filterAndSortSelections } from "./utilities";

const DisplayedButton = ({ customDisplayedButton, selected, size, disabled, onClick }) => {
  const { t } = useTranslation();

  if (customDisplayedButton) {
    return (
      <div style={{ width: "fit-content", height: "fit-content" }} onClick={() => !disabled && onClick()}>
        {React.cloneElement(customDisplayedButton, { disabled: disabled })}
      </div>
    );
  } else {
    return selected?.id ? (
      <FlagCard
        name={selected.name}
        label={selected.number}
        imageUrl={selected.imageUrl}
        color={selected.color}
        size={size}
        disabled={disabled}
        handleClick={onClick}
      />
    ) : (
      <EmptyCard message={t("common:select_category")} size={size} onClick={onClick} disabled={disabled} />
    );
  }
};

const GridSelection = (props) => {
  const { selection, customSelectionElement, highlight, style, rowIndex, disabled, onClick, setRowHeight } = props;
  const Selection = customSelectionElement || CategoryCardStandard;
  const selectionRef = useRef({});

  useEffect(() => {
    if (selectionRef.current) {
      setRowHeight(rowIndex, selectionRef.current.clientHeight);
    }
  }, [selectionRef]);

  return (
    <div
      ref={selectionRef}
      style={{
        ...style,
        height: "fit-content",
        paddingBottom: 16,
      }}
    >
      <Selection
        name={selection.name}
        number={selection.number}
        imageUrl={selection.imageUrl}
        color={selection.color}
        highlight={highlight}
        disabled={disabled}
        onClick={onClick}
      />
    </div>
  );
};

const VirtualizedList = ({ selections, customSelectionElement, selected, onSelectionClick }) => {
  const columnWidth = 200;
  const columnGap = 8;
  const rowHeight = 116;

  const [dialogContentWidth, setDialogContentWidth] = useState(null);
  const [dialogContentHeight, setDialogContentHeight] = useState(null);
  const dialogContentRef = useCallback((node) => {
    if (node !== null) {
      setDialogContentWidth(node.getBoundingClientRect().width);
      setDialogContentHeight(node.getBoundingClientRect().height);
    }
  }, []);

  const columnCount = Math.floor(dialogContentWidth / (columnWidth + columnGap));
  const rowCount = Math.ceil(selections.length / columnCount);

  const gridRef = useRef();
  const elementHeights = useRef({});
  const setRowHeight = (index, height) => {
    gridRef.current.resetAfterRowIndex(0);
    const rowHeight = elementHeights.current[index] || 0;

    if (height > rowHeight) {
      elementHeights.current = { ...elementHeights.current, [index]: height };
    }
  };

  const getRowHeight = (index) => {
    return elementHeights.current[index] || rowHeight;
  };

  return (
    <Box ref={dialogContentRef} width="100%" height="100%">
      {dialogContentWidth && dialogContentHeight && (
        <VariableSizeGrid
          ref={gridRef}
          columnCount={columnCount}
          columnWidth={() => columnWidth + columnGap}
          height={dialogContentHeight}
          width={dialogContentWidth}
          rowCount={rowCount}
          rowHeight={getRowHeight}
          overscanRowCount={5}
        >
          {({ columnIndex, rowIndex, style }) => {
            const foundIndex = rowIndex * columnCount + columnIndex;
            const selection = selections[foundIndex];

            if (!selection) return null;
            return (
              <GridSelection
                key={selection?.id}
                customSelectionElement={customSelectionElement}
                selection={selection}
                style={style}
                rowIndex={rowIndex}
                highlight={selection.id === selected.id}
                setRowHeight={setRowHeight}
                onClick={() => onSelectionClick(selection?.id)}
              />
            );
          }}
        </VariableSizeGrid>
      )}
    </Box>
  );
};

const CategoryFlagSelector = (props) => {
  const {
    selectedId,
    disabled,
    customDisplayedButton,
    size,
    customSelectionElement,
    providedCategoriesList,
    onClick,
    onSelectionClick,
  } = props;
  const { t } = useTranslation();
  const selected = useCategory(selectedId);
  const categories = useCategories() || [];
  const [dialogOpen, setDialogOpen] = useState(false);

  const [order, setOrder] = useState("asc");
  const [sortBy, setSortBy] = useState("number");
  const [searchTerm, setSearchTerm] = useState(null);

  const handleChangeSortBy = (selected) => {
    if (selected === sortBy) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      setOrder("asc");
      setSortBy(selected);
    }
  };

  const sortedAndFilteredSelections = filterAndSortSelections({
    selections: (providedCategoriesList || categories).slice(),
    searchTerm,
    sortBy,
    order,
  });

  return (
    <>
      <DisplayedButton
        customDisplayedButton={customDisplayedButton}
        selected={selected}
        size={size}
        disabled={disabled}
        onClick={() => {
          if (!disabled) {
            setDialogOpen(true);
            onClick();
          }
        }}
      />

      {dialogOpen && (
        <SelectionDialogWithFilter
          open={dialogOpen}
          title={t("common:select_category")}
          sortBy={sortBy}
          order={order}
          onChangeSortBy={handleChangeSortBy}
          onChangeSearchTerm={(searchTerm) => setSearchTerm(searchTerm)}
          handleClose={() => setDialogOpen(false)}
        >
          {categories.length < 1 ? (
            <Typography variant="overline" color="primary">
              {t("visitBar:no_categories_available")}
            </Typography>
          ) : (
            <VirtualizedList
              selections={sortedAndFilteredSelections}
              customSelectionElement={customSelectionElement}
              selected={selected}
              onSelectionClick={(selectionId) => {
                setDialogOpen(false);
                onSelectionClick(selectionId);
              }}
            />
          )}
        </SelectionDialogWithFilter>
      )}
    </>
  );
};

CategoryFlagSelector.propTypes = {
  selectedId: PropTypes.number,
  disabled: PropTypes.bool,
  customDisplayedButton: PropTypes.node,
  size: PropTypes.oneOf(["large", "medium"]),
  customSelectionElement: PropTypes.elementType,
  providedCategoriesList: PropTypes.array,
  onClick: PropTypes.func,
  onSelectionClick: PropTypes.func,
};

CategoryFlagSelector.defaultProps = {
  disabled: false,
  size: "medium",
  onClick: () => ({}),
  onSelectionClick: () => ({}),
};

export default CategoryFlagSelector;
