import React from "react";
import { useTranslation } from "react-i18next";
import SlimFlagCardV2 from "../../../../components/FlagSelectors/SlimFlagCardV2";
import type { Rail } from "../../../../dto/eVue/Rail";
import { RailGroupNames } from "../../../../constants/RailGroup";

const RailCardWrapper = ({ rail, onClick }: { rail: Rail, onClick: (railId: number) => void }) => {
  const { t } = useTranslation();
  const railGroup = t(`railGroups:${RailGroupNames[rail.railGroup] || ""}`)

  return (
    <SlimFlagCardV2
      label={rail.id?.toString()}
      topText={`${rail.label} - ${rail.name}`}
      bottomText={railGroup}
      color={rail.color}
      disabled={false}
      border={true}
      handleClick={() => onClick(rail.id)}
    />
  );
};

export default RailCardWrapper;
