import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Box, Button, Dialog, DialogContent, DialogActions, Grid, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import RemoveIcon from "@mui/icons-material/RemoveCircleOutlineSharp";
import CloseIcon from "@mui/icons-material/CloseSharp";
import { useRail } from "../../selectors/rails";
import EmptyCard from "../EmptyCard";
import FlagCard from "../FlagCard";
import { useTagSelection } from "../../features/tagSelection/tagSelectionSlice";

const DestinationRailCard = ({ railId, size, onClick, highlight, disabled }) => {
  const rail = useRail(railId);
  if (!rail.id) return null;

  return (
    <FlagCard
      key={rail.id}
      name={rail.name}
      label={rail.label}
      imageUrl={rail.imageUrl}
      color={rail.color}
      size={size}
      handleClick={() => onClick(rail.id)}
      highlight={highlight}
      disabled={disabled}
    />
  );
};

DestinationRailCard.propTypes = {
  disabled: PropTypes.bool,
  highlight: PropTypes.bool,
  onClick: PropTypes.func,
  railId: PropTypes.number,
};

DestinationRailCard.defaultProps = {
  disabled: false,
};

const StyledDialog = withStyles({
  paper: {
    height: "100%",
    maxHeight: "calc(100vh - 180px)",
  },
})(Dialog);

const DestinationRailFlagSelector = ({ railId, selectedTagId, size, disabled, onSelect }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const tagRails = useTagSelection(railId);
  const { destRailsIds, storageRailsIds, otherIds } = tagRails || {};

  const handleDestinationRailSelect = (railId) => {
    setOpen(false);
    onSelect(railId);
  };

  const handleClearDestinationRail = () => {
    setOpen(false);
    onSelect(null);
  };

  const handleOnClick = () => setOpen(true);

  const handleClose = () => setOpen(false);

  let destinationIds = storageRailsIds || [];
  let label = t("common:storage");
  if (destRailsIds?.length > 0) {
    destinationIds = destRailsIds || [];
    label = t("visitBar:destinations");
  }

  return (
    <>
      {selectedTagId ? (
        <DestinationRailCard size={size} onClick={handleOnClick} railId={selectedTagId} disabled={disabled} />
      ) : (
        <EmptyCard message={t("visitBar:select_destination")} size={size} onClick={handleOnClick} disabled={disabled} />
      )}

      {open && (
        <StyledDialog open={open} maxWidth="md" onBackdropClick={handleClose} fullWidth={true}>
          <Typography sx={{ pt: 2, pr: 3, pb: 2, pl: 3 }} variant="h3">
            {t("visitBar:select_destination")}
          </Typography>

          <DialogContent dividers={true}>
            {destinationIds.length < 1 && otherIds?.length < 1 && (
              <Typography variant="overline" color="primary">
                {t("visitBar:no_destinations_available")}
              </Typography>
            )}

            {destinationIds.length > 0 && otherIds?.length > 0 && (
              <Box mb={1}>
                <Typography variant="overline" color="primary">
                  {label}
                </Typography>
              </Box>
            )}

            {destinationIds.length > 0 && (
              <Grid container spacing={2}>
                {destinationIds.map((railId) => (
                  <Grid item key={railId}>
                    <DestinationRailCard
                      railId={railId}
                      onClick={handleDestinationRailSelect}
                      highlight={railId === selectedTagId}
                    />
                  </Grid>
                ))}
              </Grid>
            )}

            {destinationIds.length > 0 && otherIds?.length > 0 && (
              <Box mt={4} mb={1}>
                <Typography variant="overline" color="primary">
                  {t("common:other")}
                </Typography>
              </Box>
            )}
            <Grid container spacing={2}>
              {otherIds?.map((railId) => (
                <Grid item key={railId}>
                  <DestinationRailCard
                    railId={railId}
                    onClick={handleDestinationRailSelect}
                    highlight={railId === selectedTagId}
                  />
                </Grid>
              ))}
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button
              variant="outlined"
              disableElevation
              disableFocusRipple
              startIcon={<RemoveIcon />}
              onClick={handleClearDestinationRail}
            >
              {t("common:clear")}
            </Button>

            <Button
              variant="outlined"
              disableElevation
              disableFocusRipple
              startIcon={<CloseIcon />}
              onClick={handleClose}
            >
              {t("common:close")}
            </Button>
          </DialogActions>
        </StyledDialog>
      )}
    </>
  );
};

export default DestinationRailFlagSelector;
