import { useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import Client from "../api/Client";
import EditIcon from "@mui/icons-material/Edit";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
}));

const CustomFields = (props) => {
  const classes = useStyles();
  const { className, ...rest } = props;
  const { t } = useTranslation();
  const [fields, setFields] = useState([]);

  const valueRef = useRef("");

  const [open, setOpen] = useState(false);
  const [editingField, setEditingField] = useState(null);
  const [apiError, setApiError] = useState(null);

  const fetchFields = () => {
    Client.get("control_api/v1/custom_fields")
      .then((response) => {
        setFields(response.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    const refreshInterval = setInterval(fetchFields, 5000);
    fetchFields();

    return () => {
      clearInterval(refreshInterval);
    };
  }, []);

  const handleClickOpen = (field) => {
    setEditingField(field);
    setOpen(true);
  };

  const handleSubmit = () => {
    Client.put(`control_api/v1/custom_fields/${editingField.id}`, { value: valueRef.current.value })
      .then((response) => {
        fetchFields();
        setEditingField(null);
        setOpen(false);
      })
      .catch((error) => {
        setApiError(error);
      });
  };

  const handleCancel = () => {
    setEditingField(null);
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent>
          <Typography variant="h1" gutterBottom>
            System Parameters
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                {/* <TableCell>ID</TableCell> */}
                <TableCell>Parameter</TableCell>
                <TableCell>Value</TableCell>
                <TableCell align="center" sx={{ pr: 3 }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.map((field) => {
                return (
                  <TableRow hover key={`field-${field.id}`}>
                    {/* <TableCell>{field.id}</TableCell> */}
                    <TableCell>{field.key}</TableCell>
                    <TableCell>{field.value}</TableCell>
                    <TableCell align="center" onClick={() => handleClickOpen(field)}>
                      <IconButton variant="outlined" color="primary">
                        <EditIcon sx={{ fontSize: "1.1rem" }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </CardContent>
      </Card>

      <Dialog open={open} onClose={handleCancel}>
        <DialogTitle>Editing System Parameter {editingField?.id}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            System parameters are set for specific purposes and should not be changed unless instructed to do so.
          </DialogContentText>
          {apiError && <DialogContentText color="error">{apiError}</DialogContentText>}
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={editingField?.value}
            type="email"
            fullWidth
            variant="standard"
            inputRef={valueRef}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CustomFields;
