import React from "react";
import Dialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/CloseSharp";
import { Breakpoint } from "@mui/system"
import { useTranslation } from "react-i18next";

interface Props {
  actionButtons?: React.ReactElement[],
  children: React.ReactElement[],
  closeOnClick?: boolean,
  dividers?: boolean,
  header?: React.ReactElement,
  footer?: React.ReactElement,
  fullWidth?: boolean,
  handleClose: () => void,
  open: boolean,
  maxWidth?: Breakpoint | false
  showLoader?: boolean,
  subtitle: string,
  title: string,
}

const SelectionDialog = (props: Props) => {
  const {
    actionButtons = [],
    children,
    closeOnClick = true,
    dividers = true,
    header,
    footer,
    fullWidth = false,
    handleClose,
    open,
    maxWidth = "md",
    showLoader,
    subtitle,
    title,
  } = props;
  const { t } = useTranslation();

  const handleSelectionClicked = () => {
    if (closeOnClick) {
      handleClose();
    }
  };

  const Content = ({ items }: { items: React.ReactElement[] }) => (
    <>
      {showLoader && <CircularProgress sx={{ color: "info.main" }} />}
      {items?.map((item, i) => {
        return (
          <div key={i} style={{ margin: 8 }} onClick={handleSelectionClicked}>
            {item}
          </div>
        );
      })}
    </>
  );

  return (
    <Dialog
      open={open}
      maxWidth={maxWidth}
      onBackdropClick={handleClose}
      fullWidth={fullWidth}
      sx={{
        ".MuiPaper-root": {
          maxHeight: "calc(100% - 180px)"
        }
      }}
    >
      {header && <Box>{header}</Box>}
      {!header && (
        <Box display="flex" flexDirection="column" px={3} py={2}>
          <Typography variant="h3">{title}</Typography>
          {subtitle && <Typography gutterBottom>{subtitle}</Typography>}
          <IconButton
            sx={{
              position: "absolute",
              right: 1,
              top: 1,
              color: "grey.500",
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      )}

      <DialogContent
        dividers={dividers}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        <Content items={children} />
      </DialogContent>

      <DialogActions>
        {footer && (
          <Box sx={{ padding: 2, width: "100%" }}>
            {footer}
          </Box>
        )}
        {!footer && (
          <>
            {actionButtons}
            <Button variant="outlined" autoFocus onClick={handleClose} startIcon={<CloseIcon />}>
              {t<string>("common:close")}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default SelectionDialog;
