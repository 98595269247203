import { useEffect, useState } from "react";

// TODO: Update lodash to get this functionality
const isNumeric = (key: string) => {
  const parsedKey = parseInt(key);
  return parsedKey && parsedKey >= 0;
};

const manageKeyPress = (key: string, buffer: string) => {
  let updatedBuffer = buffer;
  if (isNumeric(key)) {
    updatedBuffer += key;
  }
  return updatedBuffer;
};

/**
 * On KeyDown, start capture of input from the barcode scanner,
 * unless currently typing in an input field
 */
const TIME = 100;
let timer: NodeJS.Timeout | null = null;
let buffer = "";
let currentKey = "";
const keyDownHandler = (event: KeyboardEvent, onAcceptScan: (scannedNumber: string) => void) => {
  currentKey = event.key;

  if (!timer) {
    timer = setTimeout(() => {
      if (isNumeric(buffer) && currentKey === "Enter") {
        onAcceptScan(buffer)
      }

      currentKey = "";
      buffer = "";
      timer = null;
    }, TIME);
  }
  buffer = manageKeyPress(currentKey, buffer);
};

const useNumberTicketScanner = (): [string | undefined, number] => {
  const [numberScan, setNumberScan] = useState<string | undefined>();
  const [scanCount, setScanCount] = useState(0);

  const onAcceptScan = (scannedNumber: string) => {
    setNumberScan(scannedNumber);
    setScanCount((current) => current + 1);
  };

  useEffect(() => {
    const handleOnScan = (e: KeyboardEvent) => keyDownHandler(e, onAcceptScan);
    window.addEventListener("keydown", handleOnScan, { capture: true });
    return () => window.removeEventListener("keydown", handleOnScan, { capture: true });
  }, []);

  return [numberScan, scanCount];
};

export default useNumberTicketScanner;
