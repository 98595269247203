import React, { useMemo } from "react";
import _ from "lodash";
import { Box, Button, ButtonGroup, Grid, SxProps } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { useTranslation } from "react-i18next";
import SearchField from "../../../../components/SetupPages/SearchField";
import { SortingOrder } from "../../../../enum/eVue/SortingOrder";
import { useCurrentUser } from "../../../../selectors/useUser";
import { RailGroupNames } from "../../../../constants/RailGroup";
import FormDropdown from "./FormDropdown";

interface Props {
  sx: SxProps;
  sortBy: string;
  order: SortingOrder;
  railGroup?: number;
  searchPlaceholder?: string;
  onChangeRailGroup: (railGroup: number) => void;
  onChangeSortBy: (sortBy: string) => void;
  onChangeSearchTerm: (searchTerm: string) => void;
};

const FilterControls = (props: Props) => {
  const {
    sx,
    sortBy,
    order = SortingOrder.ASC,
    searchPlaceholder = "",
    railGroup = 0,
    onChangeRailGroup,
    onChangeSortBy,
    onChangeSearchTerm,
  } = props;
  const debouncedChangeHandler = useMemo(() => {
    return _.debounce(onChangeSearchTerm, 300);
  }, []);

  const { t } = useTranslation();
  const currentUser = useCurrentUser();
  const railGroupDrodownOptions = useMemo(() => {
    return Object.keys(RailGroupNames).map((rgk) => ({
      key: rgk,
      value: rgk,
      text: t(`railGroups:${RailGroupNames[parseInt(rgk)]}`),
    }));
  }, [currentUser]);

  return (
    <Box display="flex" alignItems="flex-end" justifyContent="space-between" sx={sx}>
      <ButtonGroup variant="outlined" size="large" disableRipple={true}>
        <Button
          color={sortBy === "id" ? "secondary" : "primary"}
          endIcon={
            sortBy === "id" && order === SortingOrder.DESC ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />
          }
          onClick={() => onChangeSortBy("id")}
        >
          ID
        </Button>

        <Button
          color={sortBy === "label" ? "secondary" : "primary"}
          endIcon={
            sortBy === "label" && order === SortingOrder.DESC ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />
          }
          onClick={() => onChangeSortBy("label")}
        >
          {t("railsSetup:label")}
        </Button>
      </ButtonGroup>

      <Box display="flex" alignItems="flex-end">
        <SearchField
          initialValue=""
          sx={{ width: 250, maxHeight: 50, mr: 1 }}
          placeholder={searchPlaceholder}
          onChange={debouncedChangeHandler}
          onSubmitSearchTerm={onChangeSearchTerm}
        />

        <FormDropdown
          label={t("railsSetup:rail_group")}
          currentValue={railGroup ? railGroup.toString() : ""}
          options={railGroupDrodownOptions}
          showNone={true}
          onChange={(railGroup) => onChangeRailGroup(parseInt(railGroup) || 0)}
        />
      </Box>
    </Box>
  );
};

export default FilterControls;
