import React from "react";
import moment from "moment";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useMaintenanceRailsForSystem } from "../../selectors/rails";
import { useRailVisitsForRail } from "../../selectors/railVisits";
import { useBarcodeFromId } from "../../selectors/barcodes";
import { useBatch } from "../../selectors/batches";
import { Avatar, Divider, List, ListItem, ListItemAvatar, ListItemText, ListSubheader } from "@mui/material";
import { useTheme } from "@emotion/react";

const RailSection = ({ rail, maintData }) => {
  const { t } = useTranslation();
  const visits = useRailVisitsForRail(rail.id);
  return (
    <>
      <ListSubheader>{rail.name}</ListSubheader>
      {visits.length ? (
        visits.map((visit) => <VisitItem key={visit.id} visit={visit} maintData={maintData} />)
      ) : (
        <ListItem>
          <ListItemAvatar>
            <Avatar>--</Avatar>
          </ListItemAvatar>
          <ListItemText primary={t("barcodeScanner:no_bags")} />
        </ListItem>
      )}
    </>
  );
};

const VisitItem = ({ maintData, visit }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const batch = useBatch(visit.batchId);
  const barcode = useBarcodeFromId(batch.barcodeId);
  const md = maintData.find((x) => x.slingId === visit.batchId) || { reason: "unknown" };

  let reasonText = "---";

  switch (md.reason) {
    case "no_read":
      reasonText = t("barcodeScanner:failed_to_scan");
      break;
    case "requested":
      reasonText = `${t("barcodeScanner:maintenance_requested")} - ${md.notes || t("common:unknown")}`;
      break;
    case "cycle_limit":
      reasonText = t("barcodeScanner:maintenance_due_cycles", { cycles: md.previousCycles });
      break;
    case "day_limit":
      const now = moment();
      const prev = moment(md.previousMaintenanceAt);
      const daysSince = now.diff(prev, "days");
      reasonText = t("barcodeScanner:maintenance_due_days", { days: daysSince });
      break;
    default:
      reasonText = t("common:unknown");
  }

  return (
    <>
      <ListItem>
        <ListItemAvatar>
          <Avatar
            sx={{
              color: theme.palette.getContrastText(theme.palette.primary.main),
              bgcolor: theme.palette.primary.main,
            }}
          >
            {visit.position}
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={barcode.slingNumber || barcode.barcodeNumber || "---"} secondary={reasonText} />
      </ListItem>
    </>
  );
};

const MaintenanceQueue = ({ systemId, railId, maintData }) => {
  const { t } = useTranslation();

  const rails = useMaintenanceRailsForSystem(systemId);
  const railsExcludindCurrentRail = rails.filter((rail) => rail.id !== railId);
  const sortedRails = railsExcludindCurrentRail.sort((a, b) => (a.id > b.id ? -1 : 1));

  return (
    <List sx={{ pb: 0 }} subheader={<ListSubheader>{t("barcodeScanner:maintenance_queue")}</ListSubheader>}>
      {sortedRails.map((rail) => (
        <div key={rail.id}>
          <Divider />
          <RailSection key={rail.id} rail={rail} maintData={maintData} />
        </div>
      ))}
    </List>
  );
};

export default MaintenanceQueue;
