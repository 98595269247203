import React from "react";
import { Button, FormControlLabel } from "@mui/material";
import Icon from "@mui/icons-material/PlayForWork";
import { useTranslation } from "react-i18next";

const ProdPointsListButton = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <FormControlLabel
      control={
        <Button sx={{ padding: 1, width: "100%" }} disableElevation={true} onClick={onClick}>
          <Icon sx={{ width: 52, height: 52 }} />
        </Button>
      }
      label={t("productionPointsSetup:production_points")}
      labelPlacement="bottom"
    />
  );
};

export default ProdPointsListButton;
