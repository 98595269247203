import _ from "lodash";
import store from "../store";
import { showAlertError, showAlertSuccess } from "../actions";
import i18n from "../i18n";
import Client from "./Client.js";

export const sendToMaintenance = (barcodeId) => {
  Client.put(`control_api/v1/barcodes/${barcodeId}`, { manualMaintenanceDue: true })
    .then((response) => {
      console.info("Barcode manual_maintenance_due ok");
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const updateNotes = (barcodeId, notes) => {
  Client.put(`control_api/v1/barcodes/${barcodeId}`, { notes })
    .then((response) => {
      console.info("Barcode update notes ok");
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const addBarcode = ({ successCB, failureCB, ...rest }) => {
  Client.post("control_api/v1/barcodes", { ...rest })
    .then((response) => {
      successCB();
      return store.dispatch(showAlertSuccess(i18n.t("common:success")));
    })
    .catch((error) => {
      const errorsByKey = _.get(error, "response.data.error", "API Error");
      failureCB(errorsByKey);
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${JSON.stringify(errorsByKey)}`));
    });
};

export const updateBarcode = ({ barcodeId, successCB, failureCB, ...rest }) => {
  Client.put(`control_api/v1/barcodes/${barcodeId}`, { ...rest })
    .then((response) => {
      if (successCB) {
        successCB();
      }
      return store.dispatch(showAlertSuccess(i18n.t("common:success")));
    })
    .catch((error) => {
      const errorsByKey = _.get(error, "response.data", "API Error");
      if (failureCB) {
        failureCB(errorsByKey);
      }
      return store.dispatch(showAlertError(i18n.t("common:error")));
    });
};

export const deleteBarcode = ({ barcodeId, successCB, failureCB }) => {
  Client.delete(`control_api/v1/barcodes/${barcodeId}`)
    .then((response) => {
      successCB();
      return store.dispatch(showAlertSuccess(i18n.t("common:success")));
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      failureCB();
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const fetchSlingTypes = ({ successCB }) => {
  Client.get("control_api/v1/sling_types")
    .then((response) => successCB && successCB(response.data))
    .catch((error) => {
      return store.dispatch(showAlertError(i18n.t("common:error")));
    });
};
