import React from "react";
import { makeStyles } from "@mui/styles";
import { Avatar } from "@mui/material";

const defaultColor = "#167adf";

const useStyles = makeStyles((theme) => ({
  label: {
    color: (props) => theme.palette.getContrastText(props.color || defaultColor),
    backgroundColor: (props) => props.color || defaultColor,
    fontSize: 16,
  },
}));

const NumberBox = ({ label, color }) => {
  const classes = useStyles({ color });

  return (
    <Avatar variant="rounded" className={classes.label}>
      {label}
    </Avatar>
  );
};

export default NumberBox;
