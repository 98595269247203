import React, { useState } from "react";
import CustomerConfigCard from "./CustomerConfigCard";
import EmptyCard from "./EmptyCard";
import SelectionDialog from "./SelectionDialog";

const CustomerConfigSelector = ({ customerId, disabled, handleSelect, selected, selections, subtitle, title }) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      {selected && selected.id ? (
        <CustomerConfigCard
          key={selected.id}
          name={selected.name}
          label={selected.number}
          imageUrl={selected.imageUrl}
          color={selected.color}
          handleClick={() => {
            if (!disabled) setDialogOpen(true);
          }}
          disabled={disabled}
        />
      ) : (
        <EmptyCard
          message={title}
          onClick={() => {
            if (!disabled) setDialogOpen(true);
          }}
          disabled={disabled}
        />
      )}

      {dialogOpen && (
        <SelectionDialog open={dialogOpen} title={title} subtitle={subtitle} handleClose={() => setDialogOpen(false)}>
          {selections.map((selection) => {
            return (
              <CustomerConfigCard
                key={selection.id}
                name={selection.name}
                imageUrl={selection.imageUrl}
                color={selection.color}
                handleClick={() => handleSelect(customerId, selection.id)}
                highlight={selection.id === selected?.id}
              />
            );
          })}
        </SelectionDialog>
      )}
    </>
  );
};

export default CustomerConfigSelector;
