import { useSelector, shallowEqual } from "react-redux";
import _ from "lodash";
import store from "../store";

export const useDevices = () => useSelector((state) => state.devices, _.isEqual);

export const useDevicesIds = () => useSelector((state) => Object.keys(state.devices), shallowEqual);

export const useHardwareControlsDevicesIds = () =>
  useSelector((state) => {
    const devices = Object.values(state.devices);
    return devices.filter((device) => !device.rebootable).map((device) => device.id);
  }, shallowEqual);

export const useInterfaceDevicesIds = () =>
  useSelector((state) => {
    const devices = Object.values(state.devices);
    return devices.filter((device) => device.rebootable).map((device) => device.id);
  }, shallowEqual);

export const useRailports = () =>
  useSelector((state) => {
    const devices = Object.values(state.devices);
    return devices.reduce((railports, currentDevice) => {
      if (currentDevice.railport) {
        railports[currentDevice.name] = currentDevice;
      }

      return railports;
    }, {});
  }, _.isEqual);

export const useDeviceFromId = (deviceId) => useSelector((state) => state.devices[deviceId], shallowEqual);

export const useDeviceFromName = (deviceName) =>
  useSelector((state) => {
    return Object.values(state.devices)?.find((device) => device.name === deviceName);
  }, shallowEqual);

export const getNameFromIPAddress = ({ ipAddress, state = store.getState() }) => {
  const foundDevice = Object.values(state.devices).find((device) => device.ipAddress === ipAddress);
  return foundDevice?.name;
};

export const getAttributeForDevice = ({ id, attribute, state }) => {
  const user = state.devices[id] || {};
  return user[attribute];
};
