import type { StorageRuleDTO } from "../dto/StorageRuleDTO";

interface Props {
  storageRulesetId?: number;
  railId?: number;
  categoryId?: number;
  customerId?: number;
  storageGroupId?: number;
};

class StorageRule implements StorageRuleDTO {
  storageRulesetId?: number;
  railId?: number;
  categoryId?: number;
  customerId?: number;
  storageGroupId?: number;

  constructor({ storageRulesetId, railId, categoryId, customerId, storageGroupId }: Props) {
    this.storageRulesetId = storageRulesetId;
    this.railId = railId;
    this.categoryId = categoryId;
    this.customerId = customerId;
    this.storageGroupId = storageGroupId;
  };
};

export default StorageRule;
