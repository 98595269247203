import React from "react";
import { useCategory } from "../../../selectors/categories";
import { Category } from "../../../dto/eVue/Category";

interface Props {
  categoryId: number;
  children: (category: Category) => JSX.Element;
};

const CategoryDataWrapper = ({ categoryId, children }: Props) => {
  const category = useCategory(categoryId);
  return children(category);
};

export default CategoryDataWrapper;
