import React, { useEffect, useState } from "react";
import { FormControl, FormHelperText, Select, Typography, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { fetchSlingTypes } from "../../api/BarcodeApi";

const SlingTypeSelector = ({ error = "", selectedST, disabled, onChange }) => {
  const { t } = useTranslation();
  const [slingTypeList, setSlingTypeList] = useState();
  useEffect(() => {
    fetchSlingTypes({ successCB: (data) => setSlingTypeList(data) });
  }, []);

  const handleChange = (e) => onChange(e.target.value === "none" ? null : e.target.value);

  return !slingTypeList || slingTypeList?.length === 0 ? (
    <Typography variant="caption">{t("common:none")}</Typography>
  ) : (
    <FormControl sx={{ width: "100%" }} variant="outlined" error={error.length > 0}>
      <Select value={selectedST || "none"} disabled={disabled} onChange={handleChange}>
        <MenuItem value="none">
          <em>{t("common:none")}</em>
        </MenuItem>
        {slingTypeList.map((st) => (
          <MenuItem key={st.id} value={st.id}>
            {st.name}
          </MenuItem>
        ))}
      </Select>

      {error.length > 0 && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default SlingTypeSelector;
