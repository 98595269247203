import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Box, Grid, Typography } from "@mui/material";
import { assignCategoryToSis, assignCustomerToSis, updateSis } from "../../api/SlingInputStationApi";
import { useCustomer } from "../../selectors/customers";
import { useCategory } from "../../selectors/categories";
import { useSlingInputStation } from "../../selectors/slingInputStations";
import { useLiveWeightForRailId } from "../../selectors/rails";
import { getRailVisitsCountForRail } from "../../selectors/railVisits";
import { useCurrentRole } from "../../selectors/useRole";
import CustomerFlagSelector from "../FlagSelectors/CustomerFlagSelector";
import CategoryFlagSelector from "../FlagSelectors/CategoryFlagSelector";
import DestinationRailFlagSelector from "../FlagSelectors/DestinationRailFlagSelector";
import WeightInputButton from "./WeightInputButton";
import { NONE, KEYPAD } from "./weightInputTypes";
import WeightStatusWithBag from "./WeightStatusWithBag";
import { getWeightToUse } from "./utils";
import ConciseCaptureWeightButton from "./ConciseCaptureWeightButton";

const CondensedSingleSlingInput = (props) => {
  const { sisId, position, label, showCaptureWeightButton, hideWeightInput } = props;
  const { t } = useTranslation();
  const { canControlInputLifts } = useCurrentRole();

  const slingInputStation = useSlingInputStation(sisId);
  const {
    railId,
    category1Id,
    category2Id,
    customer1Id,
    customer2Id,
    enableTagSelection,
    tag1,
    tag2,
    weightInputType = KEYPAD,
  } = slingInputStation;

  const liveWeight = useLiveWeightForRailId(railId);
  const weight = getWeightToUse({ weightInputType, liveWeight, position, sis: slingInputStation });

  const categoryId = position === 1 ? category1Id : category2Id;
  const category = useCategory(categoryId);

  const customerId = position === 1 ? customer1Id : customer2Id;
  const customer = useCustomer(customerId) || {};

  const tagId = position === 1 ? tag1 : tag2;

  const railVisitsCount = useSelector((state) => getRailVisitsCountForRail(state, railId));
  const slingLoaded = railVisitsCount >= position;

  const handleCategorySelect = (categoryId) => {
    return assignCategoryToSis({ sisId, categoryId, position });
  };

  const handleCustomerSelect = (customerId) => {
    return assignCustomerToSis({ sisId, customerId, position });
  };

  const handleTagSelect = (tagId) => {
    return updateSis({ sisId, data: { [position === 1 ? "tag1" : "tag2"]: tagId } });
  };

  return (
    <Grid container direction="column" alignItems="center">
      <Grid
        item
        sx={{
          mb: 2,
          "& span": {
            fontSize: 40,
          },
        }}
      >
        <Typography variant="overline">{label}</Typography>
      </Grid>

      <Grid item sx={{ width: "100%" }}>
        <WeightStatusWithBag
          weight={weight || 0}
          sis={slingInputStation}
          category={category}
          slingLoaded={slingLoaded}
          position={position}
          showWeight={weightInputType !== NONE}
          condensedVersion={true}
        />
      </Grid>

      <Grid item container justifyContent="center" alignItems="flex-start" spacing={2} sx={{ pt: 4, pb: 2 }}>
        <Grid item>
          <Typography variant="h5">{t("common:category")}</Typography>
          <Box>
            <CategoryFlagSelector
              selectedId={categoryId}
              size="large"
              disabled={!canControlInputLifts}
              onSelectionClick={handleCategorySelect}
            />
          </Box>
        </Grid>

        <Grid item>
          <Typography variant="h5">{t("common:customer")}</Typography>
          <Box>
            <CustomerFlagSelector
              selectedId={customer.id}
              size="large"
              handleSelect={handleCustomerSelect}
              disabled={!canControlInputLifts}
            />
          </Box>
        </Grid>

        {enableTagSelection && (
          <Grid item>
            <Typography variant="h5">{t("common:destination")}</Typography>
            <Box>
              <DestinationRailFlagSelector
                railId={railId}
                selectedTagId={tagId}
                size="large"
                disabled={!canControlInputLifts}
                onSelect={handleTagSelect}
              />
            </Box>
          </Grid>
        )}

        {showCaptureWeightButton && (
          <Grid item>
            <ConciseCaptureWeightButton sisId={sisId} position={position} size="xLarge" />
          </Grid>
        )}
      </Grid>

      <Grid item container spacing={2} justifyContent="center">
        {weightInputType === KEYPAD && !hideWeightInput && (
          <Grid item>
            <WeightInputButton
              key={`weight-input-${sisId}-${position}`}
              sisId={sisId}
              weight={slingLoaded ? weight : 0}
              position={position}
              size="xLarge"
              disabled={!canControlInputLifts || !slingLoaded}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default CondensedSingleSlingInput;
