import * as actions from "../actions/types";

const initialState = {
  currentUserId: null,
  busy: false,
};

export default function localReducer(state = initialState, action) {
  switch (action.type) {
    case actions.LOGIN_STARTED:
      return { ...state, busy: true };
    case actions.LOGIN_SUCCESS:
      return { ...state, currentUserId: action.data.userId, busy: false };
    case actions.LOGIN_FAILURE:
      return { ...state, currentUserId: undefined, busy: false };
    case actions.LOGOUT_STARTED:
      return { ...state, busy: true };
    case actions.LOGOUT_SUCCESS:
      return { ...state, currentUserId: undefined, busy: false };
    default:
      return state;
  }
}
