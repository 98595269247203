import _ from "lodash";
import { showAlertError, showAlertSuccess } from "../actions";
import store from "../store";
import i18n from "../i18n";
import Client from "./Client.js";

export const loadCellTare = (loadCellId) => {
  Client.post(`control_api/v1/load_cells/${loadCellId}/tare`, {})
    .then((response) => {
      store.dispatch(showAlertSuccess(i18n.t("common:success")));
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const loadCellSpan = (loadCellId, calibrationWeight) => {
  Client.post(`control_api/v1/load_cells/${loadCellId}/span`, {
    calibrationWeight: calibrationWeight,
  })
    .then((response) => {
      store.dispatch(showAlertSuccess(i18n.t("common:success")));
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};
