import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, FormControlLabel } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PublishIcon from "@mui/icons-material/Publish";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "50%",
    padding: theme.spacing(2),
    fontSize: 40,
    backgroundColor: theme.palette.success["dark"],

    "&:disabled": {
      backgroundColor: theme.palette.grey[200],
    },
    "&:hover:enabled": {
      backgroundColor: theme.palette.success["main"],
    },

    "& #start-icon": {
      fontSize: 40,
    },
  },
}));

const SubmitButtonContent = ({ sx, disabled, onClick }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <FormControlLabel
      control={
        <Button
          className={classes.button}
          size="large"
          disabled={disabled}
          variant="contained"
          disableElevation={true}
          onClick={onClick}
        >
          <PublishIcon id="start-icon" />
        </Button>
      }
      label={t("common:submit")}
      labelPlacement="top"
      sx={sx}
    />
  );
};

const SubmitButton = ({ sx, disabled, onClick }) => {
  return <SubmitButtonContent sx={sx} disabled={disabled} onClick={onClick} />;
};

SubmitButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default SubmitButton;
