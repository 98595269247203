import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import * as actions from "../actions";

const events = ["dragstart", "keypress", "mousedown", "mousewheel", "scroll", "touchstart"];
let warningTimer = null;

export const useUserAutoLogout = () => {
  const currentUserId = useSelector((state) => state.session.currentUserId);
  const user = useSelector((state) => state.users[currentUserId]);
  const logoutTime = useSelector((state) => state.roles[user?.roleId]?.logoutTime);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [countdown, setCountdown] = useState(null);

  const handleWarning = useCallback(() => {
    setOpen(true);
    setCountdown(60);
  }, []);

  const handleReset = useCallback(() => {
    setOpen(false);
    setCountdown(null);
    if (warningTimer) clearTimeout(warningTimer);
    warningTimer = setTimeout(handleWarning, (logoutTime - 1) * 60 * 1000);
  }, [logoutTime, handleWarning]);

  useEffect(() => {
    if (currentUserId && logoutTime) {
      handleReset();
      events.forEach((event) => {
        window.addEventListener(event, handleReset);
      });
    }

    return () => {
      setOpen(false);
      setCountdown(null);
      if (warningTimer) clearTimeout(warningTimer);
      events.forEach((event) => {
        window.removeEventListener(event, handleReset);
      });
    };
  }, [currentUserId, logoutTime, handleReset]);

  useEffect(() => {
    if (!countdown || countdown < 0) return;
    const intervalId = setTimeout(() => {
      setCountdown((time) => time - 1);
      if (countdown <= 1) dispatch(actions.logout());
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [countdown, dispatch]);

  return {
    open,
    timeLeft: countdown,
  };
};
