import React from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useHasCakeBreakers, useSendToDryer } from "../../selectors/uiSettings";
import FormCheckbox from "../SetupPages/FormCheckbox";
import StyledGridItem from "./StyledGridItem";

const FinishingGroup = ({ currentValues, disabled, onFieldChange }) => {
  const { t } = useTranslation();
  const hasCakeBreakers = useHasCakeBreakers();
  const showSendToDryer = useSendToDryer();

  return (
    <>
      <Grid item>
        <Typography variant="h4">{t("categoriesSetup:finishing")}</Typography>
      </Grid>

      <Grid container>
        {showSendToDryer && (
          <StyledGridItem item>
            <FormCheckbox
              fieldName="dried"
              label={t("categoriesSetup:send_to_dryer")}
              currentValues={currentValues}
              disabled={disabled}
              onFieldChange={onFieldChange}
            />
          </StyledGridItem>
        )}

        {hasCakeBreakers && (
          <StyledGridItem item>
            <FormCheckbox
              fieldName="bypassCakeBreaker"
              label={t("categoriesSetup:bypass_cake_breaker")}
              currentValues={currentValues}
              disabled={disabled}
              onFieldChange={onFieldChange}
            />
          </StyledGridItem>
        )}
      </Grid>
    </>
  );
};

export default FinishingGroup;
