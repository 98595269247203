import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { showMessage, selectSelectedSortConfig, selectEnableClone } from "../redux/sortConfigurationsSlice";
import { useCurrentRole } from "../../../selectors/useRole";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import TextEditorDialog from "../../../components/TextEditorDialog";
import { useCloneSortConfigurationMutation } from "../services/sortConfigurations";
import { showAlertError } from "../../../actions";

const CloneButton = () => {
  const dispatch = useAppDispatch();
  const enableClone = useAppSelector(selectEnableClone);
  const selectedSortConfiguration = useAppSelector(selectSelectedSortConfig, _.isEqual);
  const [cloneConfig, { isLoading, isError, error }] = useCloneSortConfigurationMutation();
  const { t } = useTranslation();
  const role = useCurrentRole();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (error) {
      const responseError = error as { data: { error?: string }};
      dispatch(showAlertError(`${t("common:error")}: ${responseError.data?.error}`))
    };
  }, [isError])

  return (
    <>
      <Button
        sx={{
          maxWidth: 200,
          "&:disabled": {
            backgroundColor: "grey.200",
          },
        }}
        variant="outlined"
        disabled={!enableClone || !role.canEditSortConfigs || isLoading}
        startIcon={<FileCopyIcon />}
        onClick={() => setOpen(true)}
      >
        {t("common:clone")}
      </Button>

      <TextEditorDialog
        open={open}
        fullWidth={true}
        maxWidth="md"
        message={t("sortAssignments:enter_new_name")}
        initialValue={`${t("common:copy_of")} ${selectedSortConfiguration?.name}`}
        onClose={() => setOpen(false)}
        onSubmit={(name: string) => {
          setOpen(false);
          dispatch(showMessage(t("sortAssignments:creating_sort_configuration")));
          cloneConfig({
            name,
            systemId: selectedSortConfiguration?.systemId,
            slingAssignments: selectedSortConfiguration?.slingAssignments
          });
        }}
      />
    </>
  );
};

export default CloneButton;
