import React from "react";
import { Avatar, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { parseLabel } from "../../utils";
import { useCurrentUser } from "../../selectors/useUser";
import { useDispatch } from "react-redux";
import { useUserCardReader } from "../../selectors/userCardReader";
import Person from "@mui/icons-material/Person";
import UserAutoLogout from "../../components/UserAutoLogout";
import * as actions from "../../actions";

const useStyles = makeStyles((theme) => ({
  avatar: {
    color: "#ffffff",
    backgroundColor: "#bdbdbd",
  },
  avatarName: {
    color: theme.palette.getContrastText("#167adf"),
    backgroundColor: "#167adf",
  },
  button: {
    minWidth: 64,
  },
  [theme.breakpoints.down("sm")]: {
    avatar: {
      width: 32,
      height: 32,
    },
    avatarName: {
      width: 32,
      height: 32,
      fontSize: 16,
    },
    button: {
      minWidth: 0,
    },
  },
}));

const UserAvatar = (props) => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const classes = useStyles();
  useUserCardReader();

  const clickHandler = () => {
    if (currentUser.id) {
      dispatch(actions.logout());
    } else {
      dispatch(actions.toggleLogin());
    }
  };

  return (
    <>
      <Button onClick={clickHandler} className={classes.button}>
        {currentUser.id ? (
          <>
            {currentUser.imageUrl ? (
              <Avatar src={currentUser.imageUrl} className={classes.avatar} />
            ) : (
              <Avatar className={classes.avatarName}>
                {parseLabel(`${currentUser.firstname || ""} ${currentUser.lastname || ""}` || currentUser.login)}
              </Avatar>
            )}
          </>
        ) : (
          <Avatar className={classes.avatar}>
            <Person />
          </Avatar>
        )}
      </Button>
      <UserAutoLogout />
    </>
  );
};

export default UserAvatar;
