import React, { useCallback, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import { VariableSizeGrid } from "react-window";

interface SelectionProps {
  style: React.CSSProperties;
  rowIndex: number;
  setRowHeight: (rowIndex: number, height: number) => void;
  children: JSX.Element;
};

interface Props {
  count: number;
  children: (selectionIndex: number) => JSX.Element;
};


const GridSelectionWrapper = ({ style, rowIndex, setRowHeight, children }: SelectionProps) => {
  const selectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectionRef.current) {
      setRowHeight(rowIndex, selectionRef.current.clientHeight);
    }
  }, [selectionRef]);

  return (
    <div
      ref={selectionRef}
      style={{
        ...style,
        height: "fit-content",
      }}
    >
      {children}
    </div>
  );
};


const VirtualListWrapper = ({ count, children }: Props) => {
  const defaultColumnWidth = 216;
  const defaultRowHeight = 90;
  const columnGap = 12;

  const [dialogContentWidth, setDialogContentWidth] = useState(0);
  const [dialogContentHeight, setDialogContentHeight] = useState(0);
  const dialogContentRef = useCallback((node) => {
    if (node !== null) {
      setDialogContentWidth(node.getBoundingClientRect().width);
      setDialogContentHeight(node.getBoundingClientRect().height);
    }
  }, []);

  const columnCount = Math.floor(dialogContentWidth / defaultColumnWidth) || 0;
  const rowCount = columnCount > 0 ? Math.ceil(count / columnCount) : 0;

  const gridRef = useRef<VariableSizeGrid>(null);
  const elementHeights = useRef<{[index: number]: number}>({});
  const setRowHeight = (index: number, height: number) => {
    const current = elementHeights.current;
    const rowHeight = current[index];

    if (height > (rowHeight || 0)) {
      if (gridRef.current) {
        gridRef.current?.resetAfterRowIndex(0);
      };
      current[index] = height;
    }
  };

  const getRowHeight = (index: number) => {
    const rowHeight = elementHeights.current[index] || defaultRowHeight;
    return rowHeight + columnGap;
  };

  return (
    <Box ref={dialogContentRef} width="100%" height="100%">
      {count > 0 ? (
        dialogContentWidth && dialogContentHeight && (
          <VariableSizeGrid
            ref={gridRef}
            columnCount={columnCount}
            columnWidth={() => defaultColumnWidth}
            height={dialogContentHeight}
            width={dialogContentWidth}
            rowCount={rowCount}
            rowHeight={getRowHeight}
            overscanRowCount={3}
          >
            {({ columnIndex, rowIndex, style }) => {
              const foundIndex = rowIndex * columnCount + columnIndex;

              return (
                <GridSelectionWrapper
                  key={`virtual-selection-${foundIndex}`}
                  style={style}
                  rowIndex={rowIndex}
                  setRowHeight={setRowHeight}
                >
                  {children(foundIndex)}
                </GridSelectionWrapper>
              );
            }}
          </VariableSizeGrid>
        )
      ) : undefined}
    </Box>
  );
};

export default VirtualListWrapper;
