import _ from "lodash";
import store from "../store";
import { showAlertError, showAlertSuccess } from "../actions";
import i18n from "../i18n";
import Client from "./Client.js";
import { CategoryGroup } from "../definitions/CategoryGroup";

type ErrorsByKey = { [key: number]: string[] };
interface addCatGroup { categoryGroup: CategoryGroup, successCB?: () => void, errorCB?: (errors: ErrorsByKey) => void };
interface updateCatGroup {
  categoryGroupId: number,
  categoryIds: number[],
  successCB?: () => void,
  failureCB?: (errors: ErrorsByKey) => void,
  [index: string]: any
};
interface deleteCatGroup {
  categoryGroupId: number,
  successCB?: () => void,
  failureCB?: (errors: ErrorsByKey) => void,
};

export const addCategoryGroup = ({ categoryGroup, successCB, errorCB }: addCatGroup) => {
  Client.post("control_api/v1/category_groups", categoryGroup)
    .then((response) => {
      if (successCB) {
        successCB();
      }

      store.dispatch(showAlertSuccess(i18n.t("common:success")));
    })
    .catch((error) => {
      const errorsByKey = _.get(error, "response.data.error", "API Error");
      if (errorCB) {
        errorCB(errorsByKey);
      }

      store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorsByKey}`));
    });
};

export const updateCategoryGroup = ({ categoryGroupId, successCB, failureCB, ...rest }: updateCatGroup) => {
  Client.put(`control_api/v1/category_groups/${categoryGroupId}`, { ...rest })
    .then((response) => {
      successCB && successCB();
      return store.dispatch(showAlertSuccess(i18n.t("common:success")));
    })
    .catch((error) => {
      const errorsByKey = _.get(error, "response.data", "API Error");
      failureCB && failureCB(errorsByKey);
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorsByKey}`));
    });
};

export const deleteCategoryGroup = ({ categoryGroupId, successCB, failureCB }: deleteCatGroup) => {
  Client.delete(`control_api/v1/category_groups/${categoryGroupId}`)
    .then((response) => {
      successCB && successCB();
      return store.dispatch(showAlertSuccess(i18n.t("common:success")));
    })
    .catch((error) => {
      const errorsByKey = _.get(error, "response.data", "API Error");
      failureCB && failureCB(errorsByKey);
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorsByKey}`));
    });
};
