export const CATEGORIES = "/categories";
export const CONVEYORS = "/conveyors";
export const CUSTOMER_CHANGES = "/customer_changes";
export const CUSTOM_VIEW = "/custom_view";
export const CUSTOMERS = "/customers";
export const DISCHARGER = "/discharger";
export const EVUE_WEBPAGE = "/evue_webpage";
export const LOAD_CELLS = "/load_cells";
export const METRICS_MESSAGES_LOG = "/metrics/messages_log";
export const OVERVIEW = "/overview";
export const PANELS = "/panels";
export const PRODUCTION_LISTS = "/production_lists";
export const RAILS = "/rails";
export const SERVO_LIFT_CONTROLS = "/servo_lift_controls";
export const SETUP_BAGS = "/setup/bags";
export const SETUP_CATEGORIES = "/setup/categories";
export const SETUP_CATEGORY_GROUPS = "/setup/category_groups";
export const SETUP_CUSTOMERS = "/setup/customers";
export const SETUP_LABOR_TASKS = "/setup/labor_tasks";
export const SETUP_NETWORK_DEVICES = "/setup/network_devices";
export const SETUP_PRODUCTION_POINTS = "/setup/production_points";
export const SETUP_RAILS = "/setup/rails";
export const SETUP_ROLES = "/setup/roles";
export const SETUP_SYSTEM = "/setup/system";
export const SETUP_USERS = "/setup/users";
export const SLING_INPUT_STATION = "/sling_input_station";
export const SLX_WEBPAGE = "/slx_webpage";
export const SORT_ASSIGNMENTS = "/sort_assignments";
export const STATIC_BAG = "/static_bag";
export const STORAGE_ASSIGNMENTS = "/storage_assignments";
export const STORAGE_MANAGER = "/storage_manager";
export const TECHNICIAN_PORTAL = "/technician_portal";
export const TOOLS = "/tools";
