import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import HourglassFullIcon from "@mui/icons-material/HourglassFull";
import SnoozeIcon from "@mui/icons-material/Snooze";

/**
 * SLX Producer View status icon
 *
 * Used as:
 * ```js
 *   <StatusIcon status={producerView.status} />
 * ```
 */
const StatusIcon = ({ size = "medium", status }) => {
  switch (status) {
    case "PRODUCTION":
      return <CheckIcon fontSize={size} />;

    case "FAILURE":
    case "LINE_RESTRAINT":
      return <ErrorIcon fontSize={size} />;

    case "WAITING":
      return <HourglassFullIcon fontSize={size} />;

    default:
    case "NOT_IN_USE":
    case "NOT_SCHEDULED":
      return <SnoozeIcon fontSize={size} />;
  }
};

export default StatusIcon;
