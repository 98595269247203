import React from "react";
import { TextField } from "@mui/material";
import FormFieldLabel from "./FormFieldLabel";

const FormTextField = (props) => {
  const { fieldName, label, helperText, currentValues, errors, disabled, onFieldChange, ...rest } = props;
  return (
    <>
      <FormFieldLabel label={label} />
      <TextField
        name={fieldName}
        value={currentValues[fieldName] || ""}
        margin="none"
        variant="outlined"
        error={Boolean(errors[fieldName])}
        helperText={errors[fieldName] || helperText}
        fullWidth={true}
        disabled={disabled}
        onChange={(e) => onFieldChange(fieldName, e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
        {...rest}
      />
    </>
  );
};

export default FormTextField;
