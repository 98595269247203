import { useSelector, shallowEqual } from "react-redux";
import _ from "lodash";
import store from "../store";

export const useBarcodes = () => useSelector((state) => state.barcodes, _.isEqual);

export const useBarcodeIds = () =>
  useSelector((state) => {
    return Object.keys(state.barcodes).map((id) => parseInt(id));
  }, _.isEqual);

const formattedBarcode = (barcode) => {
  if (!barcode) return {};

  return {
    id: barcode.id,
    barcodeNumber: barcode.barcodeNumber,
    slingTypeId: barcode.slingTypeId,
    slingNumber: barcode.slingNumber,
    systemId: barcode.systemId,
    cyclesSinceMaintenance: barcode.cyclesSinceMaintenance,
    lastMaintainedAt: barcode.lastMaintainedAt,
    manualMaintenanceDue: barcode.manualMaintenanceDue,
    notes: barcode.notes,
    scheduledMaintenanceDue: barcode.scheduledMaintenanceDue,
    lifetimeCycles: barcode.lifetimeCycles,
    status: barcode.statusText,
  };
};

export const useBarcodeFromNumber = (barcodeNumber, systemId) => {
  const barcodes = useBarcodes();
  const barcode = Object.values(barcodes).find((barcode) => {
    return barcode.barcodeNumber === barcodeNumber && barcode.systemId === systemId;
  });

  return formattedBarcode(barcode);
};

export const useBarcodeFromId = (id) => {
  return useSelector((state) => formattedBarcode(state.barcodes[id]), shallowEqual);
};

export const getBarcodeFromId = (id, state) => state.barcodes[id];

export const useBarcodeFromSlingNumberForSystem = (slingNumber, systemId) => {
  const barcodes = useBarcodes();
  let barcode;
  if (slingNumber && slingNumber.length > 0) {
    barcode = Object.values(barcodes).find((barcode) => {
      return barcode.slingNumber === slingNumber && barcode.systemId === systemId;
    });
  }

  return formattedBarcode(barcode);
};

export const getBarcodeFromSlingNumberForSystem = (slingNumber, systemId) => {
  const state = store.getState();
  const barcodes = state.barcodes;

  let barcode;
  if (slingNumber && slingNumber.length > 0) {
    barcode = Object.values(barcodes).find((barcode) => {
      return barcode.slingNumber === slingNumber && barcode.systemId === systemId;
    });
  }

  return formattedBarcode(barcode);
};

export const useBarcodeFromScannerId = (scannerId) =>
  useSelector((state) => {
    const scanner = state.scanners[scannerId] || {};
    const { lastScannedValue, systemId } = scanner;
    const barcode = Object.values(state.barcodes).find((barcode) => {
      return barcode.barcodeNumber === lastScannedValue && barcode.systemId === systemId;
    });

    return barcode;
  }, shallowEqual);

export const useSlingNumbersForSystem = (systemId) =>
  useSelector((state) => {
    const allBarcodes = state.barcodes;
    const barcodesForSystem = Object.values(allBarcodes).reduce((acc, barcode) => {
      if (barcode.systemId === systemId) {
        acc.push(barcode.slingNumber);
      }
      return acc;
    }, []);

    return barcodesForSystem;
  }, shallowEqual);
