import { eVueApi } from "../../../services/eVue";

// TODO: Move this up since local attributes are no longer used in storage manager, but for now are used in both categories and customers setup Pages. Maybe a folder above both of those?
// TODO: Add tags here under 'providesTags' and in eVueApi file
export const localAttributesApi = eVueApi.injectEndpoints({
  endpoints: (builder) => ({
    getLocalAttributes: builder.query({
      query: () => "/local_attrs",
    }),
  }),
  overrideExisting: false,
});

export const { useGetLocalAttributesQuery } = localAttributesApi;
