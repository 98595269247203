import _ from "lodash";
import { showAlertError, showAlertSuccess } from "../actions";
import store from "../store";
import i18n from "../i18n";
import Client from "./Client";

export const addCustomer = ({ customer, successCB, failureCB }) => {
  Client.post("control_api/v1/customers", customer)
    .then((response) => {
      if (successCB) {
        successCB();
      }

      store.dispatch(showAlertSuccess(i18n.t("common:success")));
    })
    .catch((error) => {
      const errorsByKey = _.get(error, "response.data.error", "API Error");
      if (failureCB) {
        failureCB(errorsByKey);
      }

      store.dispatch(showAlertError(`${i18n.t("common:error")}: ${JSON.stringify(errorsByKey)}`));
    });
};

export const setupUpdateCustomer = ({ customer, successCB, errorCB }) => {
  Client.put(`control_api/v1/customers/${customer.id}`, customer)
    .then((response) => {
      store.dispatch(showAlertSuccess(i18n.t("common:success")));
      if (successCB) {
        successCB();
      }
    })
    .catch((error) => {
      const errorMessage = _.get(error, "response.data.error", "API Error");
      if (errorCB) {
        errorCB(errorMessage);
      }

      store.dispatch(showAlertError(`${i18n.t("common:error")}: ${JSON.stringify(errorMessage)}`));
    });
};

export const updateCustomer = ({ customer, successCB, errorCB }) => {
  Client.put(`control_api/v1/customers/${customer.id}`, customer)
    .then((response) => {
      if (successCB) {
        successCB();
      }
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));

      if (errorCB) errorCB();
    });
};

export const fetchCustomer = ({ customerId, successCB }) => {
  Client.get(`control_api/v1/customers/${customerId}`)
    .then((response) => {
      if (successCB) {
        successCB(response.data);
      }
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data.error", "API Error"));
      store.dispatch(showAlertError(i18n.t("common:fetch_error", { error: errorMessage })));
    });
};

export const deleteCustomer = ({ customerId, force, successCB, failureCB }) => {
  Client.delete(`control_api/v1/customers/${customerId}`, { force })
    .then((response) => {
      if (successCB) {
        successCB();
      }

      store.dispatch(showAlertSuccess(i18n.t("common:success")));
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data.error", "API Error"));
      if (failureCB) {
        failureCB();
      }

      store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};
