import React, { useState } from "react";
import _ from "lodash";
import { Divider, Paper } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSystem } from "../selectors/systems";
import StorageGroupManagerView from "../features/storageManager/components/StorageGroupManagerView";
import StorageManagerHeading from "../features/storageManager/components/StorageManagerHeading";
import ViewTabs from "../features/storageManager/components/ViewTabs";
import OverridesView from "../features/storageManager/components/AutoOverridesView/OverridesView";
import { StorageMngrViews } from "../features/storageManager/enum/StorageMngrViews";

interface Params {
  systemId: string;
};

const StorageManager = () => {
  const params: Params = useParams();
  const system = useSystem(params.systemId) || {};

  const [currentView, setCurrentView] = useState<StorageMngrViews>(StorageMngrViews.GROUPS);

  return (
    <Paper square={true} elevation={0} sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <StorageManagerHeading system={system}>
        <ViewTabs view={currentView} onTabClick={(value: StorageMngrViews) => setCurrentView(value)} />
      </StorageManagerHeading>

      <Divider />

      {currentView === StorageMngrViews.OVERRIDES ?
        <OverridesView system={system} /> :
        <StorageGroupManagerView system={system} />
      }
    </Paper>
  )
};

export default StorageManager;
