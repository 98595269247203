export enum SliceTypes {
  AI = "AI",
  AO = "AO",
  DI = "DI",
  DO = "DO",
  GAI = "GAI",
  GAO = "GAO",
  GI = "GI",
  GO = "GO",
  GR = "GR",
  GSG = "GSG",
  LC = "LC",
  R2 = "R2",
  R4 = "R4",
  RS = "RS",
  RS232 = "RS232",
  SG = "SG",
  SIS = "SIS",
  SM = "SM",
  SOS = "SOS",
};
