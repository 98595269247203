import React from "react";
import { Box, Button } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { IndicatorStatusTypes } from "../features/panels/enum/IndicatorStatusTypes";

// TODO: Conside separating status 'on' and 'off' from 'fault' as params. 'fault' could maybe be it's own param that way
// components using this don't have to know about 'on' and 'off'

interface Props {
  status?: IndicatorStatusTypes;
  label?: string;
  button?: boolean;
  onClick?: VoidFunction;
  disabled?: boolean;
  size?: number;
};

type PartialProps = {
  [K in keyof Props]?: Props[K];
};

const statusToColorMap: { [key: string]: string } = {
  [IndicatorStatusTypes.ON]: "success.dark",
  [IndicatorStatusTypes.FAULT]: "warning.dark",
  [IndicatorStatusTypes.OFF]: "error.dark",
};

const IconComponent = ({ disabled, size, status = IndicatorStatusTypes.OFF }: PartialProps) => {
  return (
    <Box
      sx={{
        width: size,
        height: size,
        borderRadius: "50%",
        backgroundColor: statusToColorMap[status],
        opacity: disabled ? 0.4 : 1
      }}
    ></Box>
  );
};

const CircleIndicator = (props: Props) => {
  const { status = IndicatorStatusTypes.OFF, label, button = true, onClick, disabled, size = 50 } = props;
  let iconComponent = <IconComponent disabled={disabled} size={size} status={status} />;
  if (button) {
    iconComponent = (
      <Button disabled={disabled} onClick={onClick}>
        {iconComponent}
      </Button>
    );
  }

  if (!label) return iconComponent;

  return (
    <FormControlLabel
      sx={{
        marginLeft: 0,
        marginRight: 0,
        "& .MuiFormControlLabel-label": {
          "&.Mui-disabled": {
            color: "inherit",
          },
        },
      }}
      control={iconComponent}
      label={label}
      labelPlacement="top"
    />
  );
};

export default CircleIndicator;
