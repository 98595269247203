import React from "react";
import { useCustomer } from "../../selectors/customers";
import FlagCard from "../FlagCard";

const CustomerFlagCard = ({ selectedId, onClick }) => {
  const selected = useCustomer(selectedId);

  return (
    <FlagCard
      name={selected.name}
      label={selected.number}
      imageUrl={selected.imageUrl}
      color={selected.color}
      handleClick={onClick}
    />
  );
};

export default CustomerFlagCard;
