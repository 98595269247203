import { useSelector, shallowEqual } from "react-redux";
import store from "../store";

export const useRole = (roleId) =>
  useSelector((state) => {
    /**
     * Role entity
     */
    const role = state.roles[roleId] || {};

    /**
     * Role interface
     */
    return formatRole(role);
  }, shallowEqual);

export const useCurrentRole = () =>
  useSelector((state) => {
    const user = state.users[state.session.currentUserId] || {};
    const currentRole = state.roles[user.roleId || state.uiSettings.guestRoleId] || {};

    return formatRole(currentRole);
  }, shallowEqual);

export const getCurrentRole = (state = store.getState()) => {
  const user = state.users[state.session.currentUserId] || {};
  const currentRole = state.roles[user.roleId || state.uiSettings.guestRoleId] || {};
  return formatRole(currentRole);
};

export const formatRole = (role) => {
  return {
    id: role.id,
    name: role.name,
    canAddSlings: role.addSlings,
    canCalibrate: role.calibrate,
    canConfigureMetrics: role.configureMetrics,
    canControlInputLifts: role.inputLifts,
    canCustomerChange: role.sortCustomerChange,
    canDeleteSlings: role.deleteSlings,
    canEditBarcodes: role.barcodeSetup,
    canEditMasterData: role.masterData,
    canEditPicklists: role.picklists,
    canEditRails: role.rails,
    canEditSlings: role.editSlings,
    canEditSortConfigs: role.sortConfigurations,
    canEditStorageConfigs: role.routes,
    canEditSystemParams: role.editSystemParameters,
    canEditValidations: role.editValidation,
    canForceCustomerChange: role.forceCustomerChange,
    canForceIO: role.forceOutputs,
    canHaltSystem: role.haltSystem,
    canManage: role.laborAdministrator,
    canManageUsers: role.manageUsers,
    canOperateConveyors: role.conveyorOperation,
    canOperatePicklists: role.picklistOperation,
    canResetRail: role.reset,
    canSendSortStations: role.sendStations,
    canToggleRails: role.railOnoffs,
    canTagSlings: role.tag,
    canToggleScanners: role.toggleScanners,
    canTroubleshootAlarm: role.troubleshootAlarm,
    canUseExternalPage: role.externalPage,
    canValidateRail: role.railValidation,
    canWasherComm: role.washerCommunications,
    logoutTime: role.logoutTime,
  };
};

export const formatForUpdate = (role) => {
  return {
    id: role.id,
    name: role.name,
    addSlings: role.canAddSlings,
    barcodeSetup: role.canEditBarcodes,
    calibrate: role.canCalibrate,
    configureMetrics: role.canConfigureMetrics,
    conveyorOperation: role.canOperateConveyors,
    deleteSlings: role.canDeleteSlings,
    editSlings: role.canEditSlings,
    editSystemParameters: role.canEditSystemParams,
    editValidation: role.canEditValidations,
    externalPage: role.canUseExternalPage,
    forceCustomerChange: role.canForceCustomerChange,
    forceOutputs: role.canForceIO,
    haltSystem: role.canHaltSystem,
    inputLifts: role.canControlInputLifts,
    laborAdministrator: role.canManage,
    logoutTime: role.logoutTime,
    manageUsers: role.canManageUsers,
    masterData: role.canEditMasterData,
    picklists: role.canEditPicklists,
    picklistOperation: role.canOperatePicklists,
    rails: role.canEditRails,
    railOnoffs: role.canToggleRails,
    railValidation: role.canValidateRail,
    reset: role.canResetRail,
    routes: role.canEditStorageConfigs,
    sendStations: role.canSendSortStations,
    sortConfigurations: role.canEditSortConfigs,
    sortCustomerChange: role.canCustomerChange,
    tag: role.canTagSlings,
    toggleScanners: role.canToggleScanners,
    troubleshootAlarm: role.canTroubleshootAlarm,
    washerCommunications: role.canWasherComm,
  };
};
