import PropTypes from "prop-types";
import React from "react";

// material-ui
import { makeStyles } from "@mui/styles";

import WarningIcon from "@mui/icons-material/Warning";
import ErrorIcon from "@mui/icons-material/Error";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import InfoIcon from "@mui/icons-material/Info";
import BuildIcon from "@mui/icons-material/Build";

import Avatar from "@mui/material/Avatar";

// style constant
const useStyles = makeStyles((theme) => ({
  warning: {
    color: theme.palette.getContrastText(theme.palette.warning.main),
    backgroundColor: theme.palette.warning.main,
  },
  error: {
    color: theme.palette.getContrastText(theme.palette.error.main),
    backgroundColor: theme.palette.error.main,
  },
  info: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
  },
  grey: {
    color: theme.palette.getContrastText(theme.palette.grey[500]),
    backgroundColor: theme.palette.grey[500],
  },
}));

const MessageClassIcon = ({ messageClass }) => {
  const classes = useStyles();

  let MeIcon;
  let meColor;

  switch (messageClass) {
    case "WARNING":
      MeIcon = WarningIcon;
      meColor = classes.warning;
      break;

    case "STOP":
      MeIcon = ErrorIcon;
      meColor = classes.error;
      break;

    case "INFO":
      MeIcon = InfoIcon;
      meColor = classes.info;
      break;

    case "SERVICE":
      MeIcon = BuildIcon;
      meColor = classes.grey;
      break;

    default:
      MeIcon = NotInterestedIcon;
      meColor = classes.info;
      break;
  }

  return (
    <Avatar className={meColor}>
      <MeIcon />
    </Avatar>
  );
};

MessageClassIcon.propTypes = {
  messageClass: PropTypes.string,
};

export default MessageClassIcon;
