import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import _ from "lodash";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableSortLabel,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { withStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { SETUP_USERS } from "../../constants/Paths";
import { useUsersIds, useUser, getAttributeFromUser, getUser } from "../../selectors/useUser";
import { getRoleName } from "../../selectors/roles";
import { useCurrentRole } from "../../selectors/useRole";
import store from "../../store";
import { sortingComparer } from "../../utils/sort";
import SearchField from "../SetupPages/SearchField";
import AddUserButton from "./AddUserButton";

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.grey[100],
    },
  },
}))(TableRow);

const UserRow = ({ id, onClick }) => {
  const currentRole = useCurrentRole();
  const user = useUser(id) || {};
  const { firstname, lastname, roleId, employeeNumber, cardValue, imageUrl } = user;
  const roleName = getRoleName(roleId);

  return (
    <StyledTableRow>
      <StyledTableCell align="left">
        <Box>
          <Avatar src={imageUrl}></Avatar>
          <Typography variant="h5">{firstname}</Typography>
        </Box>
      </StyledTableCell>
      <StyledTableCell align="left">
        <Typography variant="h5">{lastname}</Typography>
      </StyledTableCell>
      <StyledTableCell align="left">{roleName}</StyledTableCell>
      <StyledTableCell align="right">{employeeNumber}</StyledTableCell>
      <StyledTableCell align="right">{cardValue}</StyledTableCell>
      <StyledTableCell align="right">
        <IconButton onClick={onClick}>{currentRole.canManageUsers ? <EditIcon /> : <VisibilityIcon />}</IconButton>
      </StyledTableCell>
    </StyledTableRow>
  );
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    padding: theme.spacing(2),
  },
  body: {
    fontSize: 14,
    padding: theme.spacing(2),

    "& .MuiBox-root": {
      display: "flex",
      alignItems: "center",
    },
    "& .MuiAvatar-root": {
      marginRight: theme.spacing(1),
    },
  },
}))(TableCell);

const StyledTableSortLabel = withStyles((theme) => ({
  root: {
    "&$active": {
      color: theme.palette.common.black,
    },
  },
  active: {},
  icon: {
    color: "inherit !important",
  },
}))(TableSortLabel);

const filterIds = ({ ids, searchTerm, state }) => {
  return ids.filter((id) => {
    if (!searchTerm) return true;

    const user = getUser(id, state);
    const { firstname, lastname, roleId, employeeNumber, cardValue } = user;
    const roleName = getRoleName(roleId, state);
    const userAttributes = [firstname, lastname, roleName, employeeNumber, cardValue]
      .filter((value) => value)
      .toString()
      .toLowerCase();

    return _.includes(userAttributes, searchTerm?.toLowerCase());
  });
};

const sortIds = ({ ids, orderBy, order, state }) => {
  if (ids.length < 1) return [];
  return ids.sort((a, b) => {
    let valueA = getAttributeFromUser({ id: a, attribute: orderBy, state });
    let valueB = getAttributeFromUser({ id: b, attribute: orderBy, state });
    return sortingComparer(valueA, valueB, order);
  });
};

const UsersList = () => {
  const { t } = useTranslation();
  const ids = useUsersIds();
  const location = useLocation();

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("firstname");
  const [searchTerm, setSearchTerm] = useState(location.state?.searchTerm);

  const handleOrderBy = (selected) => {
    if (selected === orderBy) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      setOrder("asc");
      setOrderBy(selected);
    }
  };

  const state = store.getState();
  const filteredIds = filterIds({ ids, searchTerm, state });
  const sortedIds = sortIds({ ids: filteredIds, order, orderBy, state });

  const list = useRef();
  useEffect(() => {
    const scrollPos = location.state?.listScrollPos;
    if (scrollPos) {
      list.current.scrollTop = scrollPos;
    }
  }, []);

  const history = useHistory();
  const handleOnClick = (id) => {
    const newLocation = {
      pathname: `${SETUP_USERS}/${id}`,
      state: { listScrollPos: list.current.scrollTop, searchTerm },
    };
    history.push(newLocation);
  };

  return (
    <Paper square={true} elevation={0} sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box padding={3} display="flex" justifyContent="space-between" alignItems="flex-end">
        <Typography variant="h1">{t("usersSetup:users_setup")}</Typography>

        <SearchField
          sx={{ width: 350 }}
          initialValue={location.state?.searchTerm}
          onSubmitSearchTerm={(searchTerm) => setSearchTerm(searchTerm)}
        />
      </Box>

      <Divider />

      <TableContainer ref={list}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell sortDirection={order}>
                <StyledTableSortLabel
                  align="left"
                  active={orderBy === "firstname"}
                  direction={order}
                  onClick={() => handleOrderBy("firstname")}
                >
                  {t("common:first_name")}
                </StyledTableSortLabel>
              </StyledTableCell>

              <StyledTableCell align="left" sortDirection={order}>
                <StyledTableSortLabel
                  active={orderBy === "lastname"}
                  direction={order}
                  onClick={() => handleOrderBy("lastname")}
                >
                  {t("common:last_name")}
                </StyledTableSortLabel>
              </StyledTableCell>

              <StyledTableCell align="left" sortDirection={order}>
                <StyledTableSortLabel
                  active={orderBy === "roleName"}
                  direction={order}
                  onClick={() => handleOrderBy("roleName")}
                >
                  {t("usersSetup:role")}
                </StyledTableSortLabel>
              </StyledTableCell>

              <StyledTableCell align="right" sortDirection={order}>
                <StyledTableSortLabel
                  active={orderBy === "employeeNumber"}
                  direction={order}
                  onClick={() => handleOrderBy("employeeNumber")}
                >
                  {t("usersSetup:employee_number")}
                </StyledTableSortLabel>
              </StyledTableCell>

              <StyledTableCell align="right" sortDirection={order}>
                <StyledTableSortLabel
                  active={orderBy === "cardValue"}
                  direction={order}
                  onClick={() => handleOrderBy("cardValue")}
                >
                  {t("usersSetup:card_number")}
                </StyledTableSortLabel>
              </StyledTableCell>

              <StyledTableCell align="right">
                <AddUserButton size="small" />
              </StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {sortedIds.map((id) => (
              <UserRow key={id} id={id} onClick={() => handleOnClick(id)} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default UsersList;
