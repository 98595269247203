import _ from "lodash";
import store from "../store";
import { showAlertError, showAlertSuccess } from "../actions";
import i18n from "../i18n";
import Client from "./Client.js";

export const fetchCategorySetupOptions = ({ successCB }) => {
  Client.get("control_api/v1/categories/setup_options")
    .then((response) => successCB(response.data))
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(i18n.t("common:fetch_error"), { error: errorMessage }));
    });
};

export const fetchCategory = ({ categoryId, successCB }) => {
  Client.get(`control_api/v1/categories/${categoryId}`)
    .then((response) => successCB(response.data))
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(i18n.t("common:fetch_error"), { error: errorMessage }));
    });
};

export const addCategory = ({ successCB, failureCB, ...rest }) => {
  Client.post("control_api/v1/categories", { ...rest })
    .then((response) => {
      successCB();
      return store.dispatch(showAlertSuccess(i18n.t("common:success")));
    })
    .catch((error) => {
      const errorsByKey = _.get(error, "response.data", "API Error");
      failureCB(errorsByKey);
      return store.dispatch(showAlertError(i18n.t("common:error")));
    });
};

export const updateCategory = ({ categoryId, successCB, failureCB, ...rest }) => {
  Client.put(`control_api/v1/categories/${categoryId}`, { ...rest })
    .then((response) => {
      successCB();
      return store.dispatch(showAlertSuccess(i18n.t("common:success")));
    })
    .catch((error) => {
      const errorsByKey = _.get(error, "response.data", "API Error");
      failureCB(errorsByKey);
      return store.dispatch(showAlertError(i18n.t("common:error")));
    });
};

export const deleteCategory = ({ categoryId, force, successCB, failureCB }) => {
  Client.delete(`control_api/v1/categories/${categoryId}`, { force })
    .then((response) => {
      successCB();
      return store.dispatch(showAlertSuccess(i18n.t("common:success")));
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      failureCB();
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};
