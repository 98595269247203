const columnWidths = {
  system: "6%",
  rail: "15%",
  startTime: "22%",
  endTime: "22%",
  duration: "5%",
  messageTextId: "25%",
  level: "5%",
};

export default columnWidths;
