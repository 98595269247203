import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import CustomerIcon from "@mui/icons-material/Business";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useCustomer } from "../../../../selectors/customers";
import { useCurrentRole } from "../../../../selectors/useRole";
import FlagCardSlim from "../../../../components/FlagSelectors/FlagCardSlim";
import type { Customer } from "../../../../dto/eVue/Customer";
import type { StorageRuleDTO } from "../../dto/StorageRuleDTO";

interface DataWrapperProps {
  id: number;
  children: (customer: Customer) => JSX.Element;
}

const CustomerCardData = ({ id, children }: DataWrapperProps) => {
  const customer = useCustomer(id);
  return children(customer);
};

interface Props {
  rules: StorageRuleDTO[];
  selectedRules?: number[];
  addButton?: JSX.Element;
  onSelectRule?: (id: number) => void;
};

const CustomersRules = ({ rules, selectedRules = [], addButton, onSelectRule }: Props) => {
  const { t } = useTranslation();
  const currentRole = useCurrentRole();

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Typography variant="overline" color="secondary" sx={{ mb: addButton ? 0 : 1 }}>
          {t("common:customers")}
        </Typography>

        {addButton}
      </Box>

      <Grid container spacing={1}>
        {rules?.map((rule) => {
          return rule.customerId ? (
            <Grid item key={`customerIds-${rule.customerId}`}>
              <CustomerCardData id={rule.customerId}>
                {(customer) => (
                  <FlagCardSlim
                    name={customer.name}
                    imageUrl={customer.imageUrl}
                    imageIcon={<CustomerIcon />}
                    color={customer.color}
                    number={customer.number}
                    border={true}
                    disabled={!currentRole.canOperatePicklists}
                    highlight={rule?.id && selectedRules?.includes(rule.id)}
                    onClick={() => onSelectRule && rule?.id && onSelectRule(rule.id)}
                  />
                )}
              </CustomerCardData>
            </Grid>
          ) : undefined;
        })}
      </Grid>
    </Box>
  )
};

export default CustomersRules;
