import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import RailIcon from "@mui/icons-material/CallSplit";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useRail } from "../../../../selectors/rails";
import { useCurrentRole } from "../../../../selectors/useRole";
import FlagCardSlim from "../../../../components/FlagSelectors/FlagCardSlim";
import { Rail } from "../../../../dto/eVue/Rail";
import type { StorageRuleDTO } from "../../dto/StorageRuleDTO";

interface SRDataProps {
  railId: number;
  children: (rail: Rail) => JSX.Element;
}

const SourceRailCardData = ({ railId, children }: SRDataProps) => {
  const rail = useRail(railId);
  return children(rail);
};

interface Props {
  rules: StorageRuleDTO[];
  selectedRules?: number[];
  addButton?: JSX.Element;
  onSelectRule?: (id: number) => void;
};

const SourceRailsRules = ({ rules, selectedRules = [], addButton, onSelectRule }: Props) => {
  const { t } = useTranslation();
  const currentRole = useCurrentRole();

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Typography variant="overline" color="secondary" sx={{ mb: addButton ? 0 : 1 }}>
          {t("common:rails")}
        </Typography>

        {addButton}
      </Box>

      <Grid container spacing={1}>
        {rules?.map((rule) => {
          return rule.railId ? (
            <Grid item key={`sourceRailIds-${rule.railId}`}>
              <SourceRailCardData railId={rule.railId}>
                {(rail: Rail) => (
                  <FlagCardSlim
                    imageUrl={rail.imageUrl}
                    imageIcon={<RailIcon />}
                    color={rail.color}
                    number={rail.number}
                    name={rail.name}
                    border={true}
                    disabled={!currentRole.canOperatePicklists}
                    highlight={rule?.id && selectedRules?.includes(rule.id)}
                    onClick={() => onSelectRule && rule?.id && onSelectRule(rule.id)}
                  />
                )}
              </SourceRailCardData>
            </Grid>
          ) : undefined;
        })}
      </Grid>
    </Box>
  )
};

export default SourceRailsRules;
