import React from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { Backdrop, Box, CardContent, CircularProgress, Divider, Grid, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { SETUP_NETWORK_DEVICES } from "../../constants/Paths";
import { useCurrentRole } from "../../selectors/useRole";
import { useDeviceFromId } from "../../selectors/devices";
import { updateDevice } from "../../api/DeviceApi";
import FormEditor from "../SetupPages/FormEditor";
import FormTextField from "../SetupPages/FormTextField";
import SaveButton from "../SaveButton";
import NetworkDevicesListButton from "./NetworkDevicesListButton";
import FormDeviceTypeSelector from "./FormDeviceTypeSelector";

const NetworkDeviceEdit = () => {
  const params = useParams();
  const { t } = useTranslation();
  const currentRole = useCurrentRole();

  const device = useDeviceFromId(params.id) || {};

  const location = useLocation();
  const history = useHistory();
  const handleGoToList = () => {
    const newLocation = {
      pathname: SETUP_NETWORK_DEVICES,
      state: {
        listScrollPos: location.state?.listScrollPos,
        view: location.state?.view,
        searchTerm: location.state?.searchTerm,
      },
    };

    history.push(newLocation);
  };

  return (
    <Paper square={true} elevation={0} sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box padding={3} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h1">
          {t(`networkDevicesSetup:${currentRole.canEditBarcodes ? "edit_device" : "view_device"}`)}
        </Typography>
        <NetworkDevicesListButton onClick={handleGoToList} />
      </Box>

      <Divider />

      {device ? (
        <SetupForm device={device} onSubmitDone={handleGoToList} />
      ) : (
        <Backdrop sx={(theme) => ({ zIndex: theme.zIndex.drawer + 1, color: theme.palette.common.white })} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Paper>
  );
};

const SetupForm = ({ device = {}, onSubmitDone }) => {
  const { t } = useTranslation();
  const currentRole = useCurrentRole();

  return (
    <FormEditor
      originalValues={{
        name: device.name || "",
        description: device.description || "",
        url: device.url || "",
        ipAddress: device.ipAddress || "",
        deviceType: device.deviceType || "",
      }}
      onSubmit={({ currentValues, actions }) => {
        const valuesForSubmission = Object.keys(currentValues).reduce((acc, key) => {
          return { ...acc, [key]: currentValues[key] === "" ? null : currentValues[key] };
        }, {});

        if (device.id) {
          updateDevice({
            deviceId: device.id,
            successCB: () => {
              actions.finishSubmit();
              onSubmitDone();
            },
            failureCB: (errors) => {
              actions.finishSubmit();
              actions.catchErrors(errors);
            },
            ...valuesForSubmission,
          });
        }
      }}
    >
      {(props) => {
        const { currentValues, dirtyForm, errors, isSubmitting, setFieldValue, handleSubmit } = props;

        const formDisabled = !currentRole.canEditBarcodes || isSubmitting;

        return (
          <>
            <CardContent sx={{ height: "100%", overflow: "auto" }}>
              <Backdrop
                sx={(theme) => ({ zIndex: theme.zIndex.drawer + 1, color: theme.palette.common.white })}
                open={isSubmitting}
              >
                <CircularProgress color="inherit" />
              </Backdrop>

              <Grid container direction="column" sx={{ maxWidth: "25%" }}>
                <Grid item>
                  <FormTextField
                    fieldName="name"
                    label={t("common:name")}
                    currentValues={currentValues}
                    errors={errors}
                    disabled={true}
                    onFieldChange={setFieldValue}
                  />
                </Grid>

                <Grid item>
                  <FormTextField
                    fieldName="description"
                    label={t("common:description")}
                    currentValues={currentValues}
                    errors={errors}
                    disabled={formDisabled}
                    onFieldChange={setFieldValue}
                  />
                </Grid>

                {device.rebootable && (
                  <Grid item>
                    <FormTextField
                      fieldName="url"
                      label={t("networkDevicesSetup:url")}
                      currentValues={currentValues}
                      errors={errors}
                      disabled={formDisabled}
                      onFieldChange={setFieldValue}
                    />
                  </Grid>
                )}

                <Grid item>
                  <FormTextField
                    fieldName="ipAddress"
                    label={t("networkDevicesSetup:ip_address")}
                    currentValues={currentValues}
                    errors={errors}
                    disabled={formDisabled}
                    onFieldChange={setFieldValue}
                  />
                </Grid>

                <Grid item>
                  <FormDeviceTypeSelector
                    currentValues={currentValues}
                    disabled={formDisabled}
                    onFieldChange={(deviceTypeId) => setFieldValue("deviceType", deviceTypeId)}
                  />
                </Grid>
              </Grid>
            </CardContent>

            <Divider />

            <Grid item container justifyContent="flex-end" sx={{ padding: 3 }}>
              <SaveButton disabled={formDisabled || !dirtyForm} onClick={handleSubmit}>
                {t("common:submit")}
              </SaveButton>
            </Grid>
          </>
        );
      }}
    </FormEditor>
  );
};

export default NetworkDeviceEdit;
