import React from "react";
import { useTranslation } from "react-i18next";
import { useCategories } from "../../selectors/categories";
import FlagCard from "../FlagCard";
import SelectionDialog from "../SelectionDialog";

interface Props {
  open: boolean,
  selectedCategoryIds: number[],
  footer: React.ReactElement,
  handleSelect: (catId: number) => void,
  onClose(): void,
}

const MultiCategorySelector = ({ open, selectedCategoryIds = [], footer, handleSelect, onClose }: Props) => {
  const categories = useCategories();
  const sortedCategories = categories.slice().sort((a, b) => a.number - b.number);
  const { t } = useTranslation();

  if (!open) return null;
  return (
    <SelectionDialog
      open={open}
      title={t("common:select_categories")}
      subtitle=""
      closeOnClick={false}
      handleClose={onClose}
      footer={footer}
    >
      {sortedCategories.map((selection) => {
        return (
          <FlagCard
            key={selection.id}
            name={selection.name}
            label={selection.number}
            imageUrl={selection.imageUrl}
            color={selection.color}
            handleClick={() => handleSelect(selection.id)}
            highlight={selectedCategoryIds.includes(selection.id)}
          />
        );
      })}
    </SelectionDialog>
  );
};

export default MultiCategorySelector;
