import React from "react";
import { makeStyles } from "@mui/styles";
import { useLocale } from "../selectors/useLocale";
import AlertSnackbar from "../components/AlertSnackbar";
import Topbar from "./components/Topbar";

const useStyles = makeStyles((theme) => ({
  content: {
    ...theme.typography.mainContent,
    marginTop: "84px",
    padding: 0,
    borderRadius: 0,
    minHeight: "calc(100vh - 84px)",
    height: "calc(100vh - 84px)",
    overflowY: "auto",
    boxSizing: "border-box",
  },
}));

const MainLayout = (props) => {
  const classes = useStyles();
  useLocale();

  return (
    <div>
      <Topbar />
      <AlertSnackbar />

      <main className={classes.content}>{props.children}</main>
    </div>
  );
};

export default MainLayout;
