import React from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { useGetLocalAttributesQuery } from "../../features/storageManager/services/localAttributes";
import type { LocalAttribute } from "../../dto/eVue/LocalAttribute";
import type { LocalAttributeForResource } from "../../dto/eVue/LocalAttributeForResource";
import type { Customer } from "../../dto/eVue/Customer";
// TODO: Promote or move imports below where it makes sense
import FormCheckbox from "../../features/storageManager/components/RailOverridePage/FormCheckbox";
import FormNumberInput from "../../features/storageManager/components/RailOverridePage/FormNumberInput";
import FormDropdown from "../../features/storageManager/components/RailOverridePage/FormDropdown";
import FormTextInput from "../../features/storageManager/components/RailOverridePage/FormTextInput";
import StyledGridItem from "./StyledGridItem";

interface Props {
  currentValues: Customer;
  disabled: boolean;
  onFieldChange: (field: string, value: LocalAttributeForResource) => void;
};

interface FormFieldProps {
  fieldKey: string;
  label: string;
  currentValue: LocalAttribute["default"];
  options?: string[];
  disabled: boolean;
  showError?: boolean;
  onSelect: (pick: LocalAttributeForResource, hasError?: boolean) => void;
};

const FormField = ({ fieldKey, label, currentValue, options = [], disabled, showError, onSelect }: FormFieldProps) => {
  const { t } = useTranslation();

  switch (typeof currentValue) {
    case "boolean":
      return (
        <FormCheckbox
          label={label}
          currentValue={currentValue}
          disabled={disabled}
          onChange={(value) => onSelect({ [fieldKey]: value })}
        />
      )

    case "number":
      return (
        <FormNumberInput
          label={label}
          initialValue={currentValue}
          disabled={disabled}
          errorMessage={showError ? t("common:error") : ""}
          onChange={(value) => onSelect({ [fieldKey]: value }, typeof value === "string")}
        />
      )

    case "string":
    default:
      return options.length > 0 ? (
        <FormDropdown
          label={label}
          currentValue={currentValue}
          disabled={disabled}
          options={options}
          onChange={(value) => onSelect({ [fieldKey]: value })}
        />
      ) : (
        <FormTextInput
          label={label}
          initialValue={currentValue}
          disabled={disabled}
          errorMessage={showError ? t("common:error") : ""}
          onChange={(value) => onSelect({ [fieldKey]: value })}
        />
      )
  }
};

const LocalAttributesGroup = ({ currentValues, disabled, onFieldChange }: Props) => {
  const { t } = useTranslation();
  const localAttrsForCustomer = currentValues.localAttrs;
  const localAttributesQuery = useGetLocalAttributesQuery({});
  const allLocalAttributes: LocalAttribute[] = localAttributesQuery.data?.localAttrs || [];

  const handleSelect = (pick: LocalAttributeForResource, hasError?: boolean) => {
    const updatedLocalAttributes = {
      ...localAttrsForCustomer,
      ...pick,
    }
    onFieldChange("localAttrs", updatedLocalAttributes);
  };

  const localAttrsForCustomerCount = Object.keys(localAttrsForCustomer || {})?.length;
  if (allLocalAttributes.length < 1 || !localAttrsForCustomer || localAttrsForCustomerCount < 1) return null;
  return (
    <Grid container direction="column">
      {Object.keys(localAttrsForCustomer).map((laKey) => {
        const la = allLocalAttributes.find((la) => la.key === _.snakeCase(laKey));

        if (!la) return null;
        const editedValue = localAttrsForCustomer[laKey];
        return (
          <StyledGridItem key={la.key}>
            <FormField
              fieldKey={laKey}
              label={la.label}
              currentValue={editedValue || la.default}
              options={la.options || []}
              disabled={disabled}
              showError={false}
              onSelect={handleSelect}
            />
          </StyledGridItem>
        )
      })}
    </Grid>
  )
}

export default LocalAttributesGroup;
