import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import dayjs, { Dayjs } from "dayjs";
import { RangeInput } from "@mui/lab/DateRangePicker/RangeTypes";
import {
  Box,
  Divider,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import Loader from "../../../components/Loader";
import { useGetRailMessageEventsQuery } from "../services/railMessageEvents";
import type { TableColumns } from "../dto/TableColumns";
import useDefaultPlantStartEndTimes from "../hooks/useDefaultPlantStartEndTimes";
import RailMessageEventRow from "./RailMessageEventRow";
import FilterListButton from "./FilterListButton";
import type { RMEListFilters } from "../dto/RMEListFilters";
import columnWidths from "../columnWidths";
import DateRangeSelector from "./DateRangeSelector";

// TODO: Support either desktop or mobile date range picker
// TODO: Message text id filter has same translation for two keys. Need to fix this in client. Server does show different translations. Also some are missing translations in client(look in railMessages.json)

const headersList:  { key: TableColumns, translation: string, align: "left" | "right", width: string }[] = [
  { key: "system", translation: "common:system", align: "left", width: columnWidths.system },
  { key: "rail", translation: "common:rail", align: "left", width: columnWidths.rail },
  { key: "startTime", translation: "common:start_time", align: "right", width: columnWidths.startTime },
  { key: "endTime", translation: "common:end_time", align: "right", width: columnWidths.endTime },
  { key: "duration", translation: "common:duration", align: "right", width: columnWidths.duration },
  {
    key: "messageTextId",
    translation: "railMessageEventsMetrics:message",
    align: "left",
    width: columnWidths.messageTextId,
  },
  { key: "level", translation: "railMessageEventsMetrics:level", align: "left", width: columnWidths.level },
];

const RailMessageEventsList = () => {
  const { t } = useTranslation();
  const list = useRef<HTMLDivElement>(null);

  const plantWorkHours = useDefaultPlantStartEndTimes();
  const [dateRange, setDateRange] = useState<RangeInput<Dayjs>>([plantWorkHours.startTime, plantWorkHours.endTime]);
  const [pageOffset, setPageOffset] = useState(1);
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<TableColumns>("rail");
  const [filters, setFilters] = useState<RMEListFilters>({ railIds: [], levels: [], messageTextIds: [] });

  const startTime = dateRange[0] as Dayjs;
  const endTime = dateRange[1] as Dayjs;

  const { data, isLoading, isSuccess, isFetching, refetch } = useGetRailMessageEventsQuery({
    startTime: startTime.format(),
    endTime: endTime.format(),
    order,
    orderBy,
    pageOffset: pageOffset === 1 ? 0 : (pageOffset - 1) * 100,
    railIds: filters.railIds,
    levels: filters.levels,
    messageTextIds: filters.messageTextIds,
  });
  const pageTotal = data?.meta?.pageTotal || 0;

  useEffect(() => {
    if (list?.current) {
      list.current.scrollTop = 0;
    }

    if (endTime > dayjs()) {
      refetch();
    };
  }, [pageOffset]);

  useEffect(() => {
    if (endTime > dayjs()) {
      refetch();
    };
  }, [
    startTime.format(),
    endTime.format(),
    order,
    orderBy,
    filters.levels,
    filters.railIds,
    filters.messageTextIds,
  ]);

  const handleOrderBy = (selected: TableColumns) => {
    if (selected === orderBy) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      setOrder("asc");
      setOrderBy(selected);
    }
  };

  const handleOnDateRangeChange = (updatedDateRange: RangeInput<Dayjs>) => {
    setDateRange(updatedDateRange);
    setPageOffset(1);
  };

  const handleOnApplyFilters = (filters: RMEListFilters) => {
    setFilters(filters);
    setPageOffset(1);
  };

  return (
    <Paper square={true} elevation={0} sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box padding={3} display="flex" justifyContent="space-between" alignItems="flex-end">
        <Typography variant="h1">{t("menus:messages_log")}</Typography>

        <Box display="flex" alignItems="center">
          <Box sx={{ ml: 0.5 }} display="flex" flexDirection="column" width={350}>
            <DateRangeSelector dateRange={dateRange} onDateRangeChange={handleOnDateRangeChange} />
          </Box>
          <Box sx={{ ml: 2 }}>
            <FilterListButton onApplyFilters={handleOnApplyFilters} />
          </Box>
        </Box>
      </Box>

      <Divider />

      <Loader open={isLoading || isFetching} message={t("common:loading")} transitionDuration={1000} />

      <TableContainer ref={list} sx={{ height: "calc(100% - 100px)"}}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              {headersList.map((header) => (
                <TableCell
                  key={header.key}
                  sortDirection={order}
                  sx={{ padding: 2, width: header.width }}
                  align={header.align || "left"}
                >
                  <TableSortLabel
                    active={orderBy === header.key}
                    direction={order}
                    onClick={() => handleOrderBy(header.key)}
                  >
                  {t(header.translation)}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          {isSuccess && (
            <TableBody>
              {data?.railMessageEvents?.map((rme) => <RailMessageEventRow key={rme.id} railMessageEvent={rme} />)}
            </TableBody>
          )}
        </Table>
      </TableContainer>

      {pageTotal > 0 && (
        <Box padding={2}>
          <Pagination
            color="primary"
            count={pageTotal}
            page={pageOffset || 1}
            onChange={(e, page: number) => setPageOffset(page)}
          />
        </Box>
      )}
    </Paper>
  );
};

export default RailMessageEventsList;
