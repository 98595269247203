import React, { useCallback, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { FixedSizeList as List } from "react-window";
import dayjs from "dayjs";
import _ from "lodash";
import {
  Box,
  Divider,
  IconButton,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { withStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { SETUP_BAGS } from "../../constants/Paths";
import store from "../../store";
import { sortingComparer } from "../../utils/sort";
import { useBarcodeIds, useBarcodeFromId, getBarcodeFromId } from "../../selectors/barcodes";
import { getSystemFromId } from "../../selectors/systems";
import { useSystem } from "../../selectors/systems";
import { useCurrentRole } from "../../selectors/useRole";
import SearchField from "../SearchField";
import AddBagButton from "./AddBagButton";

const StyledTableSortLabel = withStyles((theme) => ({
  root: {
    "&$active": {
      color: theme.palette.common.black,
    },
  },
  active: {},
  icon: {
    color: "inherit !important",
  },
}))(TableSortLabel);

const StyledTableRow = withStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    boxSizing: "border-box",
    width: "100%",
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    padding: theme.spacing(2),
    height: 74,
    borderBottom: "none",
  },
  body: {
    fontSize: 14,
    padding: theme.spacing(2),
    borderBottom: "none",

    "& .MuiBox-root": {
      display: "flex",
      alignItems: "center",
    },
    "& .MuiAvatar-root": {
      marginRight: theme.spacing(1),
    },
  },
}))(TableCell);

const columnWidths = {
  system: "8%",
  slingNumber: "8%",
  barcodeNumber: "8%",
  lastMaintainedAt: "16%",
  cyclesSinceMaintenance: "12%",
  lifetimeCycles: "10%",
  notes: "20%",
  status: "11%",
  action: "7%",
};

const HeadersRow = ({ order, orderBy, onOrderBy }) => {
  const { t } = useTranslation();

  const headers = [
    {
      orderBy: "system",
      label: t("common:system"),
      align: "left",
      width: columnWidths["system"],
    },
    {
      orderBy: "slingNumber",
      label: t("common:bag_number"),
      align: "right",
      width: columnWidths["slingNumber"],
    },
    {
      orderBy: "barcodeNumber",
      label: t("common:barcode_number"),
      align: "right",
      width: columnWidths["barcodeNumber"],
    },
    {
      orderBy: "lastMaintainedAt",
      label: t("bagsSetup:last_maint_at"),
      align: "left",
      width: columnWidths["lastMaintainedAt"],
    },
    {
      orderBy: "cyclesSinceMaintenance",
      label: t("bagsSetup:cycles_since_last_maint"),
      align: "right",
      width: columnWidths["cyclesSinceMaintenance"],
    },
    {
      orderBy: "lifetimeCycles",
      label: t("bagsSetup:lifetime_cycles"),
      align: "right",
      width: columnWidths["lifetimeCycles"],
    },
    { orderBy: "notes", label: t("common:notes"), align: "left", width: columnWidths["notes"] },
    { orderBy: "status", label: t("common:status"), align: "left", width: columnWidths["status"] },
  ];

  return (
    <StyledTableRow sx={{ pr: 2, minWidth: "100%" }} component="div">
      {headers.map((heading) => (
        <StyledTableCell
          key={heading.orderBy}
          id={`${heading.orderBy}-header`}
          align={heading.align}
          sx={{ flexBasis: heading.width }}
          component="div"
        >
          {heading.label && (
            <StyledTableSortLabel
              active={orderBy === heading.orderBy}
              direction={order}
              sx={{ width: "100%" }}
              onClick={() => onOrderBy(heading.orderBy)}
            >
              <Typography noWrap={true}>{heading.label}</Typography>
            </StyledTableSortLabel>
          )}
        </StyledTableCell>
      ))}

      <StyledTableCell
        key="action"
        id="action-header"
        align="right"
        component="div"
        sx={{ flexBasis: columnWidths["action"] }}
      >
        <AddBagButton size="small" />
      </StyledTableCell>
    </StyledTableRow>
  );
};

const getHeaderWidth = (key) => {
  const header = document.getElementById(`${key}-header`);
  return header?.getBoundingClientRect().width;
};

const BarcodeRow = ({ index, style, data: { items, onClick } }) => {
  const barcodeId = items[index];
  const barcode = useBarcodeFromId(barcodeId);
  const { systemId, slingNumber, barcodeNumber, cyclesSinceMaintenance, lifetimeCycles, notes, status } = barcode;
  const system = useSystem(systemId);
  const currentRole = useCurrentRole();
  const { t } = useTranslation();

  let statusText;

  switch (status) {
    case "requested":
      statusText = t("barcodeScanner:maintenance_requested");
      break;
    case "overdue":
      statusText = t("barcodeScanner:overdue");
      break;
  }

  const lastMaintainedAt = dayjs(barcode.lastMaintainedAt);

  return (
    <StyledTableRow style={style} sx={{ backgroundColor: index % 2 ? "common.white" : "grey.100" }} component="div">
      <StyledTableCell align="left" sx={{ flexBasis: getHeaderWidth("system") }} component="div">
        {system?.name}
      </StyledTableCell>

      <StyledTableCell align="right" sx={{ flexBasis: getHeaderWidth("slingNumber") }} component="div">
        {slingNumber}
      </StyledTableCell>

      <StyledTableCell align="right" sx={{ flexBasis: getHeaderWidth("barcodeNumber") }} component="div">
        {barcodeNumber}
      </StyledTableCell>

      <StyledTableCell align="left" sx={{ flexBasis: getHeaderWidth("lastMaintainedAt") }} component="div">
        {lastMaintainedAt.isValid() && lastMaintainedAt.format("LLL")}
      </StyledTableCell>

      <StyledTableCell align="right" sx={{ flexBasis: getHeaderWidth("cyclesSinceMaintenance") }} component="div">
        {cyclesSinceMaintenance}
      </StyledTableCell>

      <StyledTableCell align="right" sx={{ flexBasis: getHeaderWidth("lifetimeCycles") }} component="div">
        {lifetimeCycles}
      </StyledTableCell>

      <StyledTableCell align="left" sx={{ flexBasis: getHeaderWidth("notes") }} component="div">
        <Typography variant="h5">{notes}</Typography>
      </StyledTableCell>

      <StyledTableCell align="left" sx={{ flexBasis: getHeaderWidth("status") }} component="div">
        <Typography variant="h5">{statusText}</Typography>
      </StyledTableCell>

      <StyledTableCell align="right" sx={{ flexBasis: getHeaderWidth("action") }} component="div">
        <IconButton onClick={() => onClick(barcodeId)}>
          {currentRole.canEditBarcodes ? <EditIcon /> : <VisibilityIcon />}
        </IconButton>
      </StyledTableCell>
    </StyledTableRow>
  );
};

const DebouncedSearchField = ({ initialValue, onSubmitSearchTerm }) => {
  const { t } = useTranslation();

  const debouncedChangeHandler = useMemo(() => {
    return _.debounce(onSubmitSearchTerm, 300);
  }, []);

  return (
    <SearchField
      initialValue={initialValue}
      placeholder={t("bagsSetup:search_instructions")}
      onChange={debouncedChangeHandler}
      onSubmitSearchTerm={onSubmitSearchTerm}
    />
  );
};

const filterBarcodeIds = (barcodeIds, searchTerm) => {
  const state = store.getState();
  return barcodeIds.filter((id) => {
    if (!searchTerm) return true;
    const barcode = getBarcodeFromId(id, state);
    const barcodeNumberMatch = _.includes(barcode.barcodeNumber, searchTerm);
    const barcodeSlingNumberMatch = _.includes(barcode.slingNumber, searchTerm);

    return barcodeNumberMatch || barcodeSlingNumberMatch;
  });
};

const sortBarcodeIds = ({ barcodeIds, orderBy, order }) => {
  const state = store.getState();
  if (barcodeIds.length < 1) return [];
  return barcodeIds.sort((a, b) => {
    const barcodeA = getBarcodeFromId(a, state);
    const barcodeB = getBarcodeFromId(b, state);
    let valueA = barcodeA[orderBy];
    let valueB = barcodeB[orderBy];

    if (orderBy === "lastMaintainedAt") {
      const dayA = dayjs(valueA);
      valueA = dayA.isValid() && dayA;

      const dayB = dayjs(valueB);
      valueB = dayB.isValid() && dayB;
    }

    if (orderBy === "system") {
      valueA = getSystemFromId(barcodeA.systemId, state)?.name;
      valueB = getSystemFromId(barcodeB.systemId, state)?.name;
    }

    return sortingComparer(valueA, valueB, order, orderBy === "lastMaintainedAt");
  });
};

const BagsList = () => {
  const barcodeIds = useBarcodeIds();
  const { t } = useTranslation();
  const location = useLocation();
  const initialScrollPos = location.state?.listScrollPos;

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("slingNumber");
  const [searchTerm, setSearchTerm] = useState(location.state?.searchTerm);
  const [scrollPos, setScrollPos] = useState(initialScrollPos);

  const handleOrderBy = (selected) => {
    if (selected === orderBy) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      setOrder("asc");
      setOrderBy(selected);
    }
  };

  const filteredBarcodeIds = filterBarcodeIds(barcodeIds, searchTerm);
  const sortedBarcodeIds = sortBarcodeIds({ barcodeIds: filteredBarcodeIds, orderBy, order });

  const [tableWidth, setTableWidth] = useState(null);
  const [tableHeight, setTableHeight] = useState(null);
  const tableContainerRef = useCallback((node) => {
    if (node !== null) {
      setTableWidth(node.getBoundingClientRect().width);
      setTableHeight(node.getBoundingClientRect().height - 76);
    }
  }, []);

  const [headersReady, setHeadersReady] = useState(false);
  const headersRef = useCallback((node) => {
    if (node !== null) {
      setHeadersReady(true);
    }
  }, []);

  const debouncedOnScrollHandler = useMemo(() => {
    return _.debounce((offset) => setScrollPos(offset), 300);
  }, []);

  const history = useHistory();
  const handleOnClick = (id) => {
    const newLocation = {
      pathname: `${SETUP_BAGS}/${id}`,
      state: { listScrollPos: scrollPos, searchTerm },
    };
    history.push(newLocation);
  };

  return (
    <Paper square={true} elevation={0} sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box padding={3} display="flex" justifyContent="space-between" alignItems="flex-end">
        <Typography variant="h1">{t("bagsSetup:bags_setup")}</Typography>

        <Box width={350}>
          <DebouncedSearchField initialValue={searchTerm} onSubmitSearchTerm={setSearchTerm} />
        </Box>
      </Box>

      <Divider />

      <TableContainer
        ref={tableContainerRef}
        component="div"
        sx={{ height: "calc(100vh - 185px)", overflowX: "auto", overflowY: "hidden" }}
      >
        <Table size="small" sx={{ height: "100%", width: "100%" }} component="div">
          <TableHead ref={headersRef} component="div">
            <HeadersRow order={order} orderBy={orderBy} onOrderBy={handleOrderBy} />
          </TableHead>

          <TableBody component="div">
            {headersReady && tableWidth && tableHeight && (
              <List
                height={tableHeight}
                width={tableWidth}
                itemCount={sortedBarcodeIds.length}
                itemSize={74}
                itemData={{
                  items: sortedBarcodeIds,
                  onClick: (id) => handleOnClick(id),
                }}
                initialScrollOffset={scrollPos}
                onScroll={({ scrollOffset }) => debouncedOnScrollHandler(scrollOffset)}
              >
                {BarcodeRow}
              </List>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default BagsList;
