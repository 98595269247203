import React from "react";
import clsx from "clsx";
import _ from "lodash";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";

const defaultColor = "#167adf";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: 200,
    height: "100%",
    borderWidth: (props) => (props.highlight ? "3px" : "1px"),
    borderStyle: "solid",
    borderColor: (props) => (props.highlight ? theme.palette.info.light : theme.palette.grey[400]),
    borderRadius: 5,
    padding: theme.spacing(2),
  },
  disabled: {
    opacity: 0.4,
  },
}));

const CustomerConfigCard = ({ name, color, highlight, disabled, handleClick }) => {
  const classes = useStyles({ highlight, color });

  const onClick = (event) => {
    if (!disabled && _.isFunction(handleClick)) handleClick(event);
  };

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.disabled]: disabled,
      })}
      onClick={onClick}
    >
      <Typography variant="body1" color="inherit">
        {name}
      </Typography>
    </div>
  );
};

CustomerConfigCard.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  handleClick: PropTypes.func,
  highlight: PropTypes.bool,
  name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

CustomerConfigCard.defaultProps = {
  color: defaultColor,
  disabled: false,
  name: null,
};

export default CustomerConfigCard;
