import { eVueApi } from "../../../services/eVue";
import type { SystemConfigs } from "../dto/SystemConfigs";

export const systemConfigsApi = eVueApi.injectEndpoints({
  endpoints: (builder) => ({
    getSystemConfigs: builder.query<SystemConfigs, void>({
      query: () => "/evue_configs",
      transformResponse: (result: { evueConfigs: SystemConfigs }) => {
        return result.evueConfigs;
      },
      providesTags: (result) => result ?
        [
          ...Object.values(result).map((config) => {
            return { type: "SystemConfigs", id: config.parameter } as const;
          }),
          { type: "SystemConfigs", id: "LIST" },
        ]
      :
        [{ type: "SystemConfigs", id: "LIST" }],
    }),
    updateSystemConfigs: builder.mutation({
      query(configs) {
        return {
          url: "/evue_configs/update_configs",
          method: "POST",
          body: configs,
        }
      },
      transformResponse: (result: { evueConfigs: SystemConfigs }) => {
        return result.evueConfigs;
      },
      invalidatesTags: () => [{ type: "SystemConfigs", id: "LIST" }],
    }),
  }),
  overrideExisting: false,
});

export const { useGetSystemConfigsQuery, useUpdateSystemConfigsMutation } = systemConfigsApi;
