import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { Button } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { createConfig, showMessage } from "../../redux/storageConfigEditor";
import { useCurrentRole } from "../../selectors/useRole";
import TextEditorDialog from "../TextEditorDialog";

const useStyles = makeStyles((theme) => ({
  actionButton: {
    maxWidth: 200,
    "&:disabled": {
      backgroundColor: theme.palette.grey[200],
    },
  },
}));

const CreateButton = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const role = useCurrentRole();

  return (
    <>
      <Button
        className={classes.actionButton}
        variant="outlined"
        disabled={!role.canEditStorageConfigs}
        startIcon={<Add />}
        onClick={() => setOpen(true)}
      >
        {t("common:create")}
      </Button>

      <TextEditorDialog
        open={open}
        fullWidth={true}
        maxWidth="md"
        message={t("storageAssignments:enter_new_name")}
        initialValue={t("storageAssignments:new_storage_config")}
        onClose={() => setOpen(false)}
        onSubmit={(name) => {
          setOpen(false);
          dispatch(showMessage(t("storageAssignments:creating_configuration")));
          dispatch(createConfig(name));
        }}
      />
    </>
  );
};

export default CreateButton;
