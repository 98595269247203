import { useSelector, shallowEqual } from "react-redux";
import store from "../store";

export const useLocal = () => {
  return useSelector((state) => state.local);
};

export const local = (receivedState) => {
  const state = receivedState || store.getState();
  return state.local || {};
};

export const useShowLogin = () => useSelector((state) => state.local.showLogin);

export const useSelectedIOPoints = () =>
  useSelector((state) => {
    return state.local.selectedIOPoints;
  }, shallowEqual);

export const getShowRailSystemControls = (state) => {
  const localState = local(state);
  return localState.showRailSystemControls;
};

export const getShowRailSystemSettings = (state) => {
  const localState = local(state);
  return localState.showRailSystemSettings;
};

export const getBatchTopColorType = (state) => {
  const localState = local(state);
  return localState.batchTopColor;
};

export const getBatchBottomColorType = (state) => {
  const localState = local(state);
  return localState.batchBottomColor;
};

export const getBatchTopTextType = (state) => {
  const localState = local(state);
  return localState.batchTopText;
};

export const getBatchBottomTextType = (state) => {
  const localState = local(state);
  return localState.batchBottomText;
};

export const getRailShowId = (state) => {
  const localState = local(state);
  return localState.railShowId;
};

export const getShowPLCTroubleshoot = (state) => {
  const localState = local(state);
  return localState.showPLCTroubleshoot;
};

export const getShowSeverTroubleshoot = (state) => {
  const localState = local(state);
  return localState.showServerTroubleshoot;
};

export const getShowBatchHighlighter = (state) => {
  return local(state).showBatchHighlighter;
};

export const getBatchCategoryHighlight = (state) => {
  return local(state).batchCategoryHighlight;
};

export const getBatchCustomerHighlight = (state) => {
  return local(state).batchCustomerHighlight;
};

export const getBatchBarcodeHighlight = (state) => {
  return local(state).batchBarcodeHighlight;
};

export const useShowAbout = () => useSelector((state) => state.local.showAbout);

export const useSelectedRailVisit = () => useSelector((state) => state.local.selectedRailVisit, shallowEqual);

export const useRecentLogins = () => useSelector((state) => local(state).recentLogins, shallowEqual);

export const useSelectedRailport = () => useSelector((state) => state.local.selectedRailport);

export const useSelectedMachineId = () => useSelector((state) => state.local?.selectedMachine);
