import _ from "lodash";
import { eVueApi } from "../../../services/eVue";
import type { RailMessageEvent } from "../../../dto/eVue/RailMessageEvent";
import type { SortDirection } from "@mui/material";
import type { TableColumns } from "../dto/TableColumns";

const RAIL_MESSAGE_EVENTS = "RailMessageEvents";

type GetRailMessageQueryType = {
  startTime?: string,
  endTime?: string;
  order?: SortDirection;
  orderBy?: TableColumns;
  pageOffset?: number;
  railIds?: number[];
  levels?: string[];
  messageTextIds?: string[];
};

type GetRailMessageQueryMeta = {
  levelOptions: string[];
  messageTextIds: string[];
  pageTotal: number;
};

export const railMessageEventsApi = eVueApi.injectEndpoints({
  endpoints: (builder) => ({
    getRailMessageEvents: builder.query<{ railMessageEvents: RailMessageEvent[], meta: GetRailMessageQueryMeta }, GetRailMessageQueryType>({
      query: ({ startTime, endTime, order, orderBy, pageOffset, railIds, levels, messageTextIds }) => ({
        url: "/rail_message_events/metrics",
        method: "GET",
        params: {
          startTime,
          endTime,
          order,
          orderBy: _.snakeCase(orderBy),
          pageOffset,
          railIds,
          levels,
          messageTextIds,
        }
      }),
      keepUnusedDataFor: 60,
      transformResponse: (result: { railMessageEvents: RailMessageEvent[], meta: GetRailMessageQueryMeta }) => {
        return {
          railMessageEvents: result.railMessageEvents,
          meta: result.meta,
        };
      },
      providesTags: (result) => {
        const configs = Object.values(result?.railMessageEvents || {})
        return configs.length > 0 ?
        [...configs.map(({ id }) => ({ type: RAIL_MESSAGE_EVENTS, id }) as const), RAIL_MESSAGE_EVENTS]
      :
        [RAIL_MESSAGE_EVENTS]
      },
    })
  }),
  overrideExisting: false,
});

export const {
  useGetRailMessageEventsQuery,
} = railMessageEventsApi;
