import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/CloseSharp";
import { Box, Button, Dialog, DialogContent, DialogActions, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import SourceRailCard from "../RailOverridePage/SourceRailCard";
import { useStorageRailIdsForSystemId } from "../../../../selectors/systems";
import AddButton from "../AddButton";
import { StrgRulesetRoutingTypes } from "../../enum/StrgRulesetRoutingTypes";
import {
  useAddStorageRulesetMutation,
  getRailIdsForStorageRulesets,
  useGetStorageRulesetsQuery,
} from "../../services/storageRulesets";
import useStorageGroupAlertMessages from "../../hooks/useStorageGroupAlertMessages";

interface Props {
  systemId?: number;
  disabled?: boolean;
};

interface RailSelectionProps {
  systemId?: number;
  disabled?: boolean;
  onSelect: (railId: number) => void;
  onClose: VoidFunction;
};

const RailSelectionWindow = ({ systemId, disabled = false, onSelect, onClose }: RailSelectionProps) => {
  const { t } = useTranslation();
  const { data, isLoading, isFetching } = useGetStorageRulesetsQuery();
  const railIdsWithRulesets = data ? getRailIdsForStorageRulesets(data) : [];
  const storageRailIds = useStorageRailIdsForSystemId(systemId);
  const filteredRailIds = storageRailIds.filter((rId: number) => !railIdsWithRulesets.includes(rId));

  return (
    <>
      <Box pt={2} pb={2} pl={3} pr={3}>
        <Typography variant="h3">{t("storageManager:add_storage_ruleset")}</Typography>
        <Typography sx={{ mb: 2 }} gutterBottom>
          {t("storageManager:select_rail_to_override")}
        </Typography>
      </Box>

      <DialogContent dividers={true}>
        <Grid container spacing={2}>
          {filteredRailIds?.length > 0 ? (
            filteredRailIds.map((railId: number) => (
              <Grid item key={`storageRail-${railId}`}>
                <SourceRailCard
                  railId={railId}
                  selected={false}
                  disabled={disabled || isLoading || isFetching}
                  onClick={onSelect}
                />
              </Grid>
            ))
          ) : (
            <Grid item>
              <Typography>{t("common:none")}</Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" autoFocus onClick={onClose} startIcon={<CloseIcon />}>
          {t("common:close")}
        </Button>
      </DialogActions>
    </>
  );
};

interface RoutingTypeProps {
  disabled: boolean;
  onSelect: (routingType: StrgRulesetRoutingTypes) => void;
  onClose: VoidFunction;
};

const RoutingTypeWindow = ({ disabled, onSelect, onClose }: RoutingTypeProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Box pt={2} pb={2} pl={3} pr={3}>
        <Typography variant="h3">{t("storageManager:add_storage_ruleset")}</Typography>
        <Typography sx={{ mb: 2 }} gutterBottom>
          {t("storageManager:routing_type")}
        </Typography>
      </Box>

      <DialogContent dividers={true} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Button
          variant="contained"
          disableElevation
          disabled={disabled}
          sx={{ mb: 2 }}
          onClick={() => onSelect(StrgRulesetRoutingTypes.MANUAL)}
        >
          {t("storageManager:manual")}
        </Button>
        <Button
          variant="contained"
          disableElevation
          disabled={disabled}
          onClick={() => onSelect(StrgRulesetRoutingTypes.CUSTOM)}
        >
          {t("storageManager:custom")}
        </Button>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" autoFocus onClick={onClose} startIcon={<CloseIcon />}>
          {t("common:close")}
        </Button>
      </DialogActions>
    </>
  );
};

const RailOverrideSelection = ({ systemId, disabled = false }: Props) => {
  const [open, setOpen] = useState(false);
  const [selectedRail, setSelectedRail] = useState<number>();

  const [addStorageRuleset] = useAddStorageRulesetMutation();
  const { onSuccess, onFailure } = useStorageGroupAlertMessages();

  const handleOnSelectRail = (railId: number) => {
    setSelectedRail(railId);
  };

  const handleOnSelectRoutingType = (routinType: StrgRulesetRoutingTypes) => {
    const newStorageRuleset = {
      railId: selectedRail,
      routingType: routinType,
    };

    return addStorageRuleset(newStorageRuleset).then(() => {
      onSuccess();
      handleOnClose();
    }).catch((error) => {
      onFailure(error);
    });
  };

  const handleOnClose = () => {
    setOpen(false);
    setSelectedRail(undefined);
  };

  return (
    <>
      <AddButton disabled={disabled || !systemId} onClick={() => setOpen(true)} />

      {open && (
        <Dialog
          open={open}
          maxWidth={selectedRail ? "sm" : "md"}
          fullWidth
          onBackdropClick={handleOnClose}
          TransitionProps={{
            unmountOnExit: true,
          }}
          sx={{ "& .MuiDialog-paper": { maxHeight: "calc(100vh - 180px)", height: "100%" } }}
        >
          {!selectedRail ? (
            <RailSelectionWindow
              systemId={systemId}
              disabled={disabled}
              onSelect={handleOnSelectRail}
              onClose={handleOnClose}
            />
          ) : (
            <RoutingTypeWindow disabled={disabled} onSelect={handleOnSelectRoutingType} onClose={handleOnClose} />
          )}
        </Dialog>
      )}
    </>
  )
};

export default RailOverrideSelection;
