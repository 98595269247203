import React from "react";
import { Select, MenuItem, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCurrentRole } from "../../selectors/useRole";
import { useDevices } from "../../selectors/devices";
import FormFieldLabel from "../SetupPages/FormFieldLabel";

const FormMultiDeviceSelector = ({ currentValues, disabled, onFieldChange }) => {
  const { t } = useTranslation();
  const currentRole = useCurrentRole();
  const allDevices = useDevices();
  const railports = Object.values(allDevices).filter((device) => device.railport) || [];

  return (
    <>
      <FormFieldLabel label={t("railsSetup:permitted_devices")} />

      {railports.length > 0 ? (
        <Select
          variant="outlined"
          multiple
          value={currentValues["permissions"] || []}
          disabled={disabled || !currentRole.canManageUsers}
          onChange={(event) => onFieldChange("permissions", event.target.value)}
        >
          {railports.map((rp) => (
            <MenuItem key={rp.ipAddress} value={rp.ipAddress}>
              {rp.name}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Typography>{t("railsSetup:no_devices_available")}</Typography>
      )}
    </>
  );
};

export default FormMultiDeviceSelector;
