import React from "react";
import { ButtonGroup, Button } from "@mui/material";
import { SxProps } from "@mui/material/styles";
import type { SystemConfig } from "../dto/SystemConfig";
import { useGetSystemConfigsQuery } from "../services/systemConfigs";

const extractTabNames = (configs: { [key: string]: SystemConfig }) => {
  const tabNames = Object.values(configs).reduce((acc: string[], config: SystemConfig) => {
    acc.push(config.category);
    return acc;
  }, []);

  return tabNames.filter((value, index, self) => self.indexOf(value) === index);
};

interface Props {
  selectedTab: string;
  sx: SxProps;
  onSelect: (value: string) => void;
};

const Tabs = ({ selectedTab, sx, onSelect }: Props) => {
  const { data } = useGetSystemConfigsQuery();

  if (!data) return null;
  const tabs = extractTabNames(data);

  return (
    <ButtonGroup disableElevation variant="outlined" color="secondary" sx={sx}>
      {tabs.map((tab) => (
        <Button
          key={tab}
          sx={(theme) => ({ backgroundColor: selectedTab === tab ? theme.palette.secondary.light : "inherit" })}
          onClick={() => onSelect(tab)}
        >
          {tab}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default Tabs;
