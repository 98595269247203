import React from "react";
import { useTranslation } from "react-i18next";
import FormFieldGroupLabel from "../SetupPages/FormFieldGroupLabel";
import FormCheckbox from "../SetupPages/FormCheckbox";

const FormDaysForAlertsCheckboxes = ({ currentValues, disabled, onFieldChange }) => {
  const { t } = useTranslation();
  return (
    <>
      <FormFieldGroupLabel label={t("usersSetup:days_to_receive_alerts")} />

      {["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"].map((day) => (
        <FormCheckbox
          key={day}
          fieldName={day}
          label={t(`usersSetup:${day}`)}
          currentValues={currentValues}
          disabled={disabled}
          onFieldChange={() => onFieldChange(day, !currentValues[day])}
        />
      ))}
    </>
  );
};

export default FormDaysForAlertsCheckboxes;
