import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { showAlertError, showAlertSuccess } from "../../../actions/alertActions";
import { useAppDispatch } from "../../../hooks/useAppDispatch";

type APIResponseStatus = {
  isSuccess: boolean;
  isError: boolean;
};

export const useInformRequestStatusToUser = <T extends APIResponseStatus>(result: T) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (result.isSuccess) {
      dispatch(showAlertSuccess(t("common:success")));
    }
    if (result.isError) {
      dispatch(showAlertError(t("common:error")));
    }
  }, [result.isSuccess, result.isError]);
};
