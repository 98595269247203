import React from "react";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { useHistory } from "react-router-dom";
import { SETUP_CATEGORIES } from "../../constants/Paths";
import { useCurrentRole } from "../../selectors/useRole";

const AddCategoryButton = ({ size, onClick }) => {
  const currentRole = useCurrentRole();
  const history = useHistory();

  const handleOnClick = () => {
    history.push(`${SETUP_CATEGORIES}/new`);

    if (onClick) {
      onClick();
    }
  };

  return (
    <Fab size={size} color="primary" disabled={!currentRole.canEditMasterData} onClick={handleOnClick}>
      <AddIcon />
    </Fab>
  );
};

export default AddCategoryButton;
