import _ from "lodash";
import * as actions from "../actions/types";
import { objFromArray } from "../utils";

const initialState = {};

export default function productionListReducer(state = initialState, action) {
  switch (action.type) {
    case actions.INITIALIZE_PRODUCTION_LISTS:
      return objFromArray(action.productionLists);

    case actions.UPDATE_PRODUCTION_LIST:
      const plId = action.productionList.id;
      return {
        ...state,
        [plId]: { ...state[plId], ...action.productionList },
      };

    case actions.UPDATE_NESTED_STATES_PRODUCTION_LISTS:
      const merged = action.data.reduce((acc, pl) => {
        acc[pl.id] = { ...state[pl.id], ...pl };
        return acc;
      }, {});

      return { ...state, ...merged };

    case actions.DELETE_PRODUCTION_LIST:
      return _.omit(state, action.id);

    default:
      return state;
  }
}
