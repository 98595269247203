import { SortConfiguration } from "../../../dto/eVue/SortConfiguration";
import { eVueApi } from "../../../services/eVue";

const SORT_CONFIGS = "SortConfigs";

export const sortConfigurationsApi = eVueApi.injectEndpoints({
  endpoints: (builder) => ({
    getSortConfigurations: builder.query<{[key: number]: SortConfiguration}, void>({
      query: () => "/sort_configurations",
      keepUnusedDataFor: 60,
      transformResponse: (result: { sortConfigurations: {[key: number]: SortConfiguration }}) => {
        return result.sortConfigurations;
      },
      providesTags: (result) => {
        const configs = Object.values(result || {})
        return configs.length > 0 ?
        [...configs.map(({ id }) => ({ type: SORT_CONFIGS, id }) as const), SORT_CONFIGS]
      :
        [SORT_CONFIGS]
      },
    }),
    cloneSortConfiguration: builder.mutation<SortConfiguration, Partial<SortConfiguration>>({
      query({ name, systemId, slingAssignments }) {
        return {
          url: "/sort_configurations",
          method: "POST",
          body: {
            name,
            systemId,
            slingAssignments,
          },
        }
      },
      transformResponse: (response: any) => response.sortConfiguration,
      invalidatesTags: [SORT_CONFIGS],
    }),
    createSortConfiguration: builder.mutation<SortConfiguration, Partial<SortConfiguration>>({
      query({ name, systemId, slingAssignments }) {
        return {
          url: "/sort_configurations",
          method: "POST",
          body: {
            name,
            systemId,
            slingAssignments,
          },
        }
      },
      transformResponse: (response: any) => response.sortConfiguration,
      invalidatesTags: [SORT_CONFIGS],
    }),
    updateSortConfiguration: builder.mutation<SortConfiguration, SortConfiguration>({
      query({ id, ...body }) {
        return {
          url: `/sort_configurations/${id}`,
          method: "PUT",
          body: body,
        }
      },
      transformResponse: (response: any) => response.sortConfiguration,
      invalidatesTags: (result, error, { id }) => [{ type: SORT_CONFIGS, id }],
    }),
    deleteSortConfiguration: builder.mutation({
      query(id) {
        return {
          url: `/sort_configurations/${id}`,
          method: "DELETE",
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: SORT_CONFIGS, id }],
    })
  }),
  overrideExisting: false,
});

export const {
  useGetSortConfigurationsQuery,
  useCloneSortConfigurationMutation,
  useUpdateSortConfigurationMutation,
  useCreateSortConfigurationMutation,
  useDeleteSortConfigurationMutation,
} = sortConfigurationsApi;
