import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import BaseDialog from "../components/BaseDialog";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import { getShowPLCTroubleshoot } from "../selectors/local";
import * as actions from "../actions";

const useStyles = makeStyles((theme) => ({
  titleWrapper: {
    marginBottom: theme.spacing(1),
  },
  title: {
    marginTop: theme.spacing(5),
  },
  imageAndCaption: {
    marginBottom: theme.spacing(2),
  },
  image: {
    width: "100%",
    backgroundColor: theme.palette.grey[100],
    borderRadius: 5,
    border: `1px solid ${theme.palette.grey[500]}`,
  },
  substep: {
    paddingLeft: theme.spacing(2),
  },
}));

const PLCTroubleshootDialog = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const showPLCTroubleshoot = useSelector((state) => getShowPLCTroubleshoot(state));

  return (
    <BaseDialog
      key="plc-troubleshoot-dialog"
      title={t("common:plc_offline")}
      fullWidth={true}
      closeOnClick={false}
      open={showPLCTroubleshoot}
      minHeight={250}
      maxWidth="md"
      handleClose={() => dispatch(actions.hidePLCTroubleshoot())}
    >
      <Grid container direction="column">
        <Grid item className={classes.titleWrapper}>
          <Typography variant="h4" color="secondary">
            {t("plcTroubleshootDialog:step_1_title")}
          </Typography>
        </Grid>

        <Grid item container wrap="nowrap">
          <Grid item container direction="column" className={classes.imageAndCaption} xs={5}>
            <img className={classes.image} src="images/reference/ped-panel.jpg" />
            <Typography variant="caption" align="center">
              {t("plcTroubleshootDialog:caption_ped_panel")}
            </Typography>
          </Grid>

          <Grid item xs={7}>
            <List component="nav" dense={true} disablePadding={true}>
              <ListItem>
                <Typography variant="h5">{t("plcTroubleshootDialog:step_1_1")}</Typography>
              </ListItem>

              <ListItem>
                <Typography variant="h5">{t("plcTroubleshootDialog:step_1_2")}</Typography>
              </ListItem>
            </List>
          </Grid>
        </Grid>

        <Grid item container wrap="nowrap">
          <Grid item container direction="column" className={classes.imageAndCaption} xs={5}>
            <img className={classes.image} src="images/reference/bnr-PLC.jpg" />
            <Typography variant="caption" align="center">
              {t("plcTroubleshootDialog:caption_plc")}
            </Typography>
          </Grid>

          <Grid item xs={7}>
            <List component="nav" dense={true} disablePadding={true}>
              <ListItem>
                <Typography variant="h5">{t("plcTroubleshootDialog:step_1_3")}</Typography>
              </ListItem>

              <ListItem>
                <Typography variant="h5">{t("plcTroubleshootDialog:step_1_4")}</Typography>
              </ListItem>
            </List>
          </Grid>
        </Grid>

        <Grid item className={classes.titleWrapper}>
          <Typography variant="h4" color="secondary" className={classes.title}>
            {t("plcTroubleshootDialog:step_2_title")}
          </Typography>
        </Grid>

        <Grid item container wrap="nowrap">
          <Grid item container direction="column" className={classes.imageAndCaption} xs={5}>
            <img src="images/reference/hyperVSearch.jpg" className={classes.image} />
            <Typography variant="caption" align="center">
              {t("plcTroubleshootDialog:caption_start_menu_search")}
            </Typography>
          </Grid>

          <Grid item xs={7}>
            <Typography variant="h5" className={classes.substep}>
              {t("plcTroubleshootDialog:step_2_1")}
            </Typography>
          </Grid>
        </Grid>

        <Grid item container wrap="nowrap">
          <Grid item container direction="column" className={classes.imageAndCaption} xs={5}>
            <img src="images/reference/hyperVManager.jpg" className={classes.image} />
            <Typography variant="caption" align="center">
              {t("plcTroubleshootDialog:caption_hyper_v")}
            </Typography>
          </Grid>

          <Grid item xs={7}>
            <Typography variant="h5" className={classes.substep}>
              {t("plcTroubleshootDialog:step_2_2")}
            </Typography>
          </Grid>
        </Grid>

        <Grid item container wrap="nowrap">
          <Grid item container direction="column" className={classes.imageAndCaption} xs={5}>
            <img src="images/reference/resetConfirmPrompt.jpg" className={classes.image} />
            <Typography variant="caption" align="center">
              {t("plcTroubleshootDialog:caption_reset_dialog")}
            </Typography>
          </Grid>

          <Grid item xs={7}>
            <Typography variant="h5" className={classes.substep}>
              {t("plcTroubleshootDialog:step_2_3")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </BaseDialog>
  );
};

export default PLCTroubleshootDialog;
