import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "calc(100% - 30px)",
  },
  tabPanel: {
    overflow: "auto",
    height: "calc(100% - 99px)",
  },
  tabs: {
    marginBottom: "24px",
    "& a": {
      minHeight: "auto",
      minWidth: "10px",
      padding: "12px 8px",
      marginRight: "18px",
      color: theme.palette.grey[600],
    },
    "& a.Mui-selected": {
      color: theme.palette.primary.main,
    },
    "& a ": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    "& a > svg": {
      marginBottom: "0px !important",
      marginRight: "10px",
    },
    "& a > span + svg": {
      margin: "0px 0px 0px auto !important",
      width: "14px",
      height: "14px",
    },
  },
}));

export default function TabContainer(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const { tab1, tab1Icon, tab1Label, tab2, tab2Icon, tab2Label, tab3, tab3Icon, tab3Label, tab4, tab4Icon, tab4Label } =
    props;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs value={value} onChange={handleChange} className={classes.tabs}>
        {tab1Label && <Tab label={tab1Label} {...{ id: 0 }} icon={tab1Icon} component={RouterLink} to="#" />}
        {tab2Label && <Tab label={tab2Label} {...{ id: 1 }} icon={tab2Icon} component={RouterLink} to="#" />}
        {tab3Label && <Tab label={tab3Label} {...{ id: 2 }} icon={tab3Icon} component={RouterLink} to="#" />}
        {tab4Label && <Tab label={tab4Label} {...{ id: 3 }} icon={tab4Icon} component={RouterLink} to="#" />}
      </Tabs>
      {tab1 && (
        <Box className={classes.tabPanel} hidden={value !== 0}>
          {tab1}
        </Box>
      )}
      {tab2 && (
        <Box className={classes.tabPanel} hidden={value !== 1}>
          {tab2}
        </Box>
      )}
      {tab3 && (
        <Box className={classes.tabPanel} hidden={value !== 2}>
          {tab3}
        </Box>
      )}
      {tab4 && (
        <Box className={classes.tabPanel} hidden={value !== 3}>
          {tab4}
        </Box>
      )}
    </div>
  );
}
