import { INITIALIZE_IO_POINTS, UPDATE_IO_POINTS, UPDATE_IO_POINT } from "./types";
import { configureChannel } from "../utils";

export function ioPointSubscribe() {
  return (dispatch) => {
    ioPointsChannel.on("multi_io_update", (msg) => {
      dispatch(updateIoPoints(msg.data));
    });

    ioPointsChannel.on("update", (msg) => {
      dispatch(updateIoPoint(msg));
    });

    ioPointsChannel.on("init", (msg) => {
      dispatch(initializeIoPoints(msg.data));
    });
  };
}

export function updateIoPoints(ioPoints) {
  return {
    type: UPDATE_IO_POINTS,
    ioPoints: ioPoints,
  };
}

export const updateIoPoint = (ioPoint) => {
  return {
    type: UPDATE_IO_POINT,
    ioPoint: ioPoint,
  };
};

export function initializeIoPoints(ioPoints) {
  return {
    type: INITIALIZE_IO_POINTS,
    ioPoints: ioPoints,
  };
}

export const ioPointsChannel = configureChannel("io_points");
