import React, { useEffect, useState } from "react";
import { Box, Button, Grid, RadioGroup, Radio, FormControlLabel, Typography } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import { useCategoriesByIds } from "../../selectors/categories";
import SaveButton from "../../components/SaveButton";
import BaseDialog from "../../components/BaseDialog";
import NumberBox from "../../components/NumberBox";
import CategoryCardSlim from "../../components/FlagSelectors/CategoryCardSlim";

const StrategySelector = ({ pick, editedStrategy, onStrategyChange }) => {
  const { t } = useTranslation();
  const strategyOptions = ["historical_demand", "oldest"];
  const traitCategories = useCategoriesByIds(pick?.categoryIds);

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="overline" sx={{ mb: 1 }}>
        {t("productionList:category_group")}
      </Typography>

      <Box display="flex" alignItems="center" mb={4}>
        {!isNaN(pick.number) && <NumberBox label={pick.number} color={pick.color} />}
        <Typography variant="h3" sx={{ ml: 1, mr: 4 }}>
          {pick.name}
        </Typography>
      </Box>

      <Box display="flex">
        <Box display="flex" flexDirection="column" sx={{ mr: 8 }}>
          <Typography sx={{ mb: 1 }} variant="overline">
            {t("productionList:available_selections")}
          </Typography>

          {strategyOptions && (
            <RadioGroup value={editedStrategy || "none"} onChange={(e) => onStrategyChange(e.target.value)}>
              {strategyOptions.map((strategyOption) => (
                <FormControlLabel
                  key={strategyOption}
                  value={strategyOption}
                  control={<Radio disabled={false} />}
                  label={t(`productionList:${strategyOption}`)}
                />
              ))}
            </RadioGroup>
          )}
        </Box>

        <Box display="flex" flexDirection="column" width="fit-content">
          <Typography variant="overline" sx={{ mb: 1 }}>
            {t("productionList:categories_in_group")}
          </Typography>

          <Grid container columns={2} columnSpacing={1}>
            {traitCategories?.map((cat) => (
              <Grid item key={`category-${cat.id}`} sx={{ mb: 1, width: 200 }}>
                <CategoryCardSlim
                  imageUrl={cat.imageUrl}
                  number={cat.number}
                  name={cat.name}
                  border={true}
                  onClick={() => ({})}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

const StrategySelectorButton = ({ pick, disabled, onSubmit }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [editedStrategy, setEditedStrategy] = useState();
  useEffect(() => {
    setEditedStrategy(pick?.strategy);
  }, []);

  const handleSaveStrategy = () => {
    onSubmit(editedStrategy);
    setOpen(false);
  };

  return (
    <>
      {pick?.strategy ? (
        <Button
          sx={{ cursor: "default" }}
          variant="outlined"
          disableFocusRipple
          disableRipple
          disableTouchRipple
          disabled={disabled}
          onClick={() => setOpen(true)}
        >
          {t(`productionList:${pick?.strategy}`) || ""}
        </Button>
      ) : (
        <Button
          variant="outlined"
          color="error"
          startIcon={<WarningIcon />}
          disableElevation={true}
          disabled={disabled}
          onClick={() => setOpen(true)}
        >
          {t("productionList:set_strategy")}
        </Button>
      )}

      {open && (
        <BaseDialog
          open={open}
          fullWidth={true}
          handleClose={() => setOpen(false)}
          title={t("productionList:strategy_selector")}
          subtitle={t("productionList:select_strategy")}
          actionButtons={[
            <SaveButton key="save-button" disabled={disabled} onClick={handleSaveStrategy}>
              {t("common:save")}
            </SaveButton>,
          ]}
        >
          <StrategySelector
            pick={pick?.entity}
            editedStrategy={editedStrategy}
            onStrategyChange={(strategy) => setEditedStrategy(strategy)}
          />
        </BaseDialog>
      )}
    </>
  );
};

export default StrategySelectorButton;
