import React, { useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Card, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LoadCellCalibrationDialog from "../components/RailBar/LoadCellCalibrationDialog";
import LoadCellsCard from "../components/LoadCellsCard";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  titleBar: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const LoadCells = () => {
  const { t } = useTranslation();
  const loadCellIds = useSelector((state) => Object.keys(state.loadCells), shallowEqual);
  const classes = useStyles();

  const [calibrationDialogOpen, setCalibrationDialogOpen] = useState(false);
  const [selectedLoadCellId, setSelectedLoadCellId] = useState(1);

  const handleCalibrate = (loadCellId) => {
    setSelectedLoadCellId(loadCellId);
    setCalibrationDialogOpen(true);
  };

  const handleClose = () => {
    setCalibrationDialogOpen(false);
  };

  return (
    <div className={classes.root}>
      <Card className={classes.titleBar}>
        <Typography variant="h1" gutterBottom>
          {t("loadCell:view_title")}
        </Typography>
      </Card>

      <div>
        <Grid container spacing={2}>
          {loadCellIds.map((loadCellId) => {
            return (
              <Grid item key={loadCellId} lg={2} md={3} xs={6}>
                <LoadCellsCard loadCellId={loadCellId} handleCalibrate={handleCalibrate} />
              </Grid>
            );
          })}
        </Grid>
      </div>

      <LoadCellCalibrationDialog
        loadCellId={selectedLoadCellId}
        open={calibrationDialogOpen}
        handleClose={handleClose}
      />
    </div>
  );
};

export default LoadCells;
