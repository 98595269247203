import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Grid,
  IconButton,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableSortLabel,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { withStyles } from "@mui/styles";
import { alpha } from "@mui/material/styles";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import { SETUP_NETWORK_DEVICES } from "../../constants/Paths";
import { sortingComparer } from "../../utils/sort";
import {
  useDeviceFromId,
  useHardwareControlsDevicesIds,
  useInterfaceDevicesIds,
  getAttributeForDevice,
} from "../../selectors/devices";
import { useCurrentRole } from "../../selectors/useRole";
import { rebootDevice } from "../../api/DeviceApi";
import SearchField from "../../components/SetupPages/SearchField";
import Loader from "../../components/Loader";
import { useDeviceTypeName, getDeviceTypeName } from "../../features/deviceTypes/deviceTypeSlice";
import store from "../../store";

const DevicesLists = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const [searchTerm, setSearchTerm] = useState(location.state?.searchTerm || "");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [view, setView] = useState(location.state?.view === "interfaces" ? "interfaces" : "controls");

  const handleOrderBy = (selected) => {
    if (selected === orderBy) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      setOrder("asc");
      setOrderBy(selected);
    }
  };

  const list = useRef();
  useEffect(() => {
    const scrollPos = location.state?.listScrollPos;
    if (scrollPos) {
      list.current.scrollTop = scrollPos;
    }
  }, [location.state?.listScrollPos]);

  const history = useHistory();
  const handleOnClick = (id) => {
    const newLocation = {
      pathname: `${SETUP_NETWORK_DEVICES}/${id}`,
      state: {
        listScrollPos: list.current.scrollTop,
        view,
        searchTerm,
      },
    };
    history.push(newLocation);
  };

  const handleViewChange = (newView) => {
    setView(newView);
    setSearchTerm(null);
    list.current.scrollTop = 0;
  };

  return (
    <Paper square={true} elevation={0} sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box padding={3} display="flex" justifyContent="space-between" alignItems="flex-end">
        <Typography variant="h1">{t("networkDevicesSetup:network_devices")}</Typography>

        <ButtonGroup disableElevation variant="outlined">
          <Button
            sx={(theme) => ({ backgroundColor: view === "controls" && alpha(theme.palette.info.light, 0.1) })}
            onClick={() => handleViewChange("controls")}
          >
            {t("networkDevicesSetup:controls_hardware")}
          </Button>
          <Button
            sx={(theme) => ({ backgroundColor: view === "interfaces" && alpha(theme.palette.info.light, 0.1) })}
            onClick={() => handleViewChange("interfaces")}
          >
            {t("networkDevicesSetup:interfaces")}
          </Button>
        </ButtonGroup>
      </Box>

      <Box pl={3} pr={3} pb={2}>
        <Grid container justifyContent="space-between" alignItems="flex-end">
          <Typography
            variant="h3"
            color="secondary"
            sx={{ display: "flex", justifyContent: "center", textTransform: "uppercase" }}
          >
            {t(`networkDevicesSetup:${view === "controls" ? "controls_hardware" : "interfaces"}`)}
          </Typography>

          <SearchField
            key={view}
            sx={{ width: 350 }}
            initialValue={searchTerm}
            placeholder={t("common:search_by_name")}
            onSubmitSearchTerm={(searchTerm) => setSearchTerm(searchTerm)}
          />
        </Grid>
      </Box>

      <Divider />

      <TableContainer ref={list}>
        {view === "controls" ? (
          <ControlsHardwareDevices
            searchTerm={searchTerm}
            orderBy={orderBy}
            order={order}
            onChangeOrderBy={handleOrderBy}
            onDeviceClicked={handleOnClick}
          />
        ) : (
          <InterfacesDevices
            searchTerm={searchTerm}
            orderBy={orderBy}
            order={order}
            onChangeOrderBy={handleOrderBy}
            onDeviceClicked={handleOnClick}
          />
        )}
      </TableContainer>
    </Paper>
  );
};

const ControlsHardwareDevices = ({ searchTerm, orderBy, order, onChangeOrderBy, onDeviceClicked }) => {
  const { t } = useTranslation();
  const controlHardwareDevicesIds = useHardwareControlsDevicesIds();
  const filteredDeviceIds = filterDevicesIds(controlHardwareDevicesIds, searchTerm);
  const sortedDeviceIds = sortIds({ ids: filteredDeviceIds, orderBy, order, t }) || [];

  if (sortedDeviceIds.length < 1) return null;
  return (
    <DevicesTable orderBy={orderBy} order={order} onChangeOrderBy={onChangeOrderBy} onDeviceClicked={onDeviceClicked}>
      {sortedDeviceIds.map((id) => (
        <DeviceRow key={id} id={id} onClick={() => onDeviceClicked(id)} />
      ))}
    </DevicesTable>
  );
};

const InterfacesDevices = ({ searchTerm, orderBy, order, onChangeOrderBy, onDeviceClicked }) => {
  const { t } = useTranslation();
  const interfaceDevicesIds = useInterfaceDevicesIds();
  const filteredDeviceIds = filterDevicesIds(interfaceDevicesIds, searchTerm);
  const sortedDeviceIds = sortIds({ ids: filteredDeviceIds, orderBy, order, t }) || [];

  if (sortedDeviceIds.length < 1) return null;
  return (
    <DevicesTable
      rebootable={true}
      orderBy={orderBy}
      order={order}
      onChangeOrderBy={onChangeOrderBy}
      onDeviceClicked={onDeviceClicked}
    >
      {sortedDeviceIds.map((id) => (
        <DeviceRow key={id} id={id} rebootable={true} onClick={() => onDeviceClicked(id)} />
      ))}
    </DevicesTable>
  );
};

const filterDevicesIds = (ids, searchTerm) => {
  const state = store.getState();
  return ids.filter((id) => {
    if (!searchTerm) return true;
    const name = _.toLower(getAttributeForDevice({ id, attribute: "name", state }));
    const nameMatches = _.includes(name, searchTerm?.toLowerCase());
    return nameMatches;
  });
};

const sortIds = ({ ids, orderBy, order, t }) => {
  if (ids.length < 1) return [];

  const state = store.getState();
  return ids.sort((a, b) => {
    let valueA = getAttributeForDevice({ id: a, attribute: orderBy, state });
    let valueB = getAttributeForDevice({ id: b, attribute: orderBy, state });

    if (orderBy === "deviceType") {
      const deviceTypeIdA = getAttributeForDevice({ id: a, attribute: "deviceType", state });
      valueA = getDeviceTypeName(state, deviceTypeIdA);

      const deviceTypeIdB = getAttributeForDevice({ id: b, attribute: "deviceType", state });
      valueB = getDeviceTypeName(state, deviceTypeIdB);
    }

    if (orderBy === "ipAddress") {
      valueA = getAttributeForDevice({ id: a, attribute: "ipAddress", state });
      valueB = getAttributeForDevice({ id: b, attribute: "ipAddress", state });
    }

    if (orderBy === "pingResult") {
      valueA = getAttributeForDevice({ id: a, attribute: "pingResult", state }) ? t("common:ok") : t("common:error");
      valueB = getAttributeForDevice({ id: b, attribute: "pingResult", state }) ? t("common:ok") : t("common:error");
    }

    return sortingComparer(valueA, valueB, order, orderBy === "lastPingAt");
  });
};

const DevicesTable = ({ rebootable, orderBy, order, children, onChangeOrderBy }) => {
  const { t } = useTranslation();
  return (
    <Table stickyHeader size="small">
      <TableHead>
        <TableRow>
          <Label active={orderBy === "name"} direction={order} onClick={() => onChangeOrderBy("name")}>
            {t("common:name")}
          </Label>

          <Label active={orderBy === "deviceType"} direction={order} onClick={() => onChangeOrderBy("deviceType")}>
            {t("networkDevicesSetup:device_type")}
          </Label>

          <StyledTableCell>{t("common:description")}</StyledTableCell>

          <Label
            align="right"
            active={orderBy === "ipAddress"}
            direction={order}
            onClick={() => onChangeOrderBy("ipAddress")}
          >
            {t("networkDevicesSetup:ip_address")}
          </Label>

          {rebootable && (
            <Label active={orderBy === "url"} direction={order} onClick={() => onChangeOrderBy("url")}>
              {t("networkDevicesSetup:url")}
            </Label>
          )}

          <Label active={orderBy === "lastPingAt"} direction={order} onClick={() => onChangeOrderBy("lastPingAt")}>
            {t("networkDevicesSetup:last_ping_at")}
          </Label>

          <Label active={orderBy === "pingResult"} direction={order} onClick={() => onChangeOrderBy("pingResult")}>
            {t("networkDevicesSetup:last_ping_result")}
          </Label>

          <StyledTableCell></StyledTableCell>
        </TableRow>
      </TableHead>

      <TableBody>{children}</TableBody>
    </Table>
  );
};

const Label = ({ align = "left", active, direction, children, onClick }) => {
  return (
    <StyledTableCell align={align}>
      <StyledTableSortLabel active={active} direction={direction} onClick={onClick}>
        {children}
      </StyledTableSortLabel>
    </StyledTableCell>
  );
};

const DeviceRow = ({ id, rebootable, onClick }) => {
  dayjs.extend(LocalizedFormat);
  const { t } = useTranslation();
  const device = useDeviceFromId(id) || {};
  const currentRole = useCurrentRole();

  return (
    <StyledTableRow>
      <StyledTableCell>
        <Typography variant="h5">{device.name}</Typography>
      </StyledTableCell>

      <StyledTableCell>
        <DeviceType deviceTypeId={device.deviceType} />
      </StyledTableCell>

      <StyledTableCell>{device.description}</StyledTableCell>
      <StyledTableCell align="right">{device.ipAddress}</StyledTableCell>
      {rebootable && <StyledTableCell>{device.url}</StyledTableCell>}
      <StyledTableCell>{device.lastPingAt ? dayjs(device.lastPingAt)?.format("LLLL") : ""}</StyledTableCell>
      <StyledTableCell>{device.pingResult ? t("common:ok") : t("common:error")}</StyledTableCell>
      <StyledTableCell sx={{ display: "flex", justifyContent: "flex-end" }}>
        {rebootable && (
          <>
            <DeviceRebootButton device={device} />

            <Divider
              orientation="vertical"
              flexItem={true}
              sx={(theme) => ({ border: `0.5px solid ${theme.palette.grey[300]}`, ml: 2, mr: 1 })}
            />
          </>
        )}

        <IconButton onClick={onClick}>{currentRole.canManageUsers ? <EditIcon /> : <VisibilityIcon />}</IconButton>
      </StyledTableCell>
    </StyledTableRow>
  );
};

const DeviceRebootButton = ({ device = {} }) => {
  const { t } = useTranslation();
  const [rebooting, setRebooting] = useState(false);

  return (
    <>
      <Button
        variant="contained"
        disableElevation={true}
        disabled={rebooting || !device.rebootable}
        onClick={() => {
          setRebooting(true);
          rebootDevice({
            deviceId: device.id,
            successCB: () => setRebooting(false),
            failureCB: () => setRebooting(false),
          });
        }}
      >
        {t("common:reboot")}
      </Button>

      <Loader open={rebooting} message={t("networkDevicesSetup:rebooting_device")} />
    </>
  );
};

const DeviceType = ({ deviceTypeId }) => {
  const deviceTypeName = useDeviceTypeName(deviceTypeId);
  return <Typography variant="h5">{deviceTypeName || ""}</Typography>;
};

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.grey[100],
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    padding: theme.spacing(2),
  },
  body: {
    fontSize: 14,
    padding: theme.spacing(2),

    "& .MuiBox-root": {
      display: "flex",
      alignItems: "center",
    },
    "& .MuiAvatar-root": {
      marginRight: theme.spacing(1),
    },
  },
}))(TableCell);

const StyledTableSortLabel = withStyles((theme) => ({
  root: {
    "&$active": {
      color: theme.palette.common.black,
    },
  },
  active: {},
  icon: {
    color: "inherit !important",
  },
}))(TableSortLabel);

export default DevicesLists;
