import _ from "lodash";
import { useSelector, shallowEqual } from "react-redux";

export const useCategory = (categoryId) => {
  return useSelector((state) => state.categories[categoryId] || {}, shallowEqual);
};

export const useCategories = () => {
  return useSelector((state) => Object.values(state.categories), _.isEqual);
};

export const useCategoriesByIds = (ids) =>
  useSelector((state) => {
    return ids?.map((id) => state.categories[id]);
  }, _.isEqual);

export const useCategoryIds = () =>
  useSelector((state) => {
    return Object.keys(state.categories).map((id) => parseInt(id));
  }, shallowEqual);

export const getCategoryName = (state, categoryId) => {
  const category = state.categories[categoryId];
  return category && category.name;
};

export const getCategoryNumber = (state, categoryId) => {
  const category = state.categories[categoryId];
  return category && category.number;
};

export const useSuperCategoryIds = () =>
  useSelector((state) => {
    return Object.values(state.categories).reduce((acc, category) => {
      if (!category.superCategoryId) return acc;
      acc.push(category.superCategoryId);
      return acc;
    }, []);
  }, shallowEqual);

export const useCategoryIdFromNumber = (number) =>
  useSelector((state) => {
    return Object.values(state.categories)?.find((cat) => cat.number === number)?.id;
  });
