import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Button, FormControlLabel } from "@mui/material";
import RemoveIcon from "@mui/icons-material/RemoveCircleOutlineSharp";
import { getRailVisitsCountForRail } from "../../selectors/railVisits";
import { useCurrentRole } from "../../selectors/useRole";
import { useSlingInputStation } from "../../selectors/slingInputStations";
import { useRail } from "../../selectors/rails";
import { deleteSlingFromSis } from "../../api/SlingInputStationApi";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "50%",
    backgroundColor: theme.palette.error["main"],
    color: "white",

    "&:disabled": {
      backgroundColor: theme.palette.grey[200],
    },
    "&:hover:enabled": {
      backgroundColor: theme.palette.error["dark"],
    },
  },
  xLargeButton: {
    padding: theme.spacing(2),

    "& #start-icon": {
      fontSize: 65,
    },
  },
}));

const DeleteSlingButtonContent = ({ sx, sis, size, disabled }) => {
  const classes = useStyles();
  const { canControlInputLifts } = useCurrentRole();
  const rail = useRail(sis.railId) || {};
  const { t } = useTranslation();

  return (
    <FormControlLabel
      control={
        <Button
          className={clsx({
            [classes.button]: true,
            [classes.xLargeButton]: size === "xLarge",
          })}
          color="error"
          size={size === "xLarge" ? "large" : size}
          disabled={disabled || !canControlInputLifts}
          onClick={() => deleteSlingFromSis(sis.id)}
        >
          <RemoveIcon id="start-icon" />
        </Button>
      }
      label={t(rail.maintenance ? "common:remove_bag" : "slingInputStation:remove_load")}
      labelPlacement="top"
      sx={sx}
    />
  );
};

const ConciseDeleteSlingButton = ({ sisId, ...rest }) => {
  const sis = useSlingInputStation(sisId) || {};
  const railVisitCount = useSelector((state) => getRailVisitsCountForRail(state, sis.railId));

  if (railVisitCount < 1) return null;
  return <DeleteSlingButtonContent sis={sis} {...rest} />;
};

ConciseDeleteSlingButton.defaultProps = {
  size: "medium",
};

ConciseDeleteSlingButton.propTypes = {
  sisId: PropTypes.number,
  size: PropTypes.oneOf(["small", "medium", "large", "xLarge"]),
  disabled: PropTypes.bool,
};

export default ConciseDeleteSlingButton;
