import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { shallowEqual, useSelector } from "react-redux";
import { toCamelCase } from "../../utils";

const initialState = {
  servoLifts: [],
  status: "idle",
  error: null,
};

export const fetchServoLifts = createAsyncThunk("servoLifts/fetchServoLifts", async (arg, thunkAPI) => {
  const url = `/control_api/v1/servo_lifts`;
  const response = await fetch(url);
  const data = await response.json();
  const { dataLoaded } = servoLiftsSlice.actions;
  return thunkAPI.dispatch(dataLoaded(toCamelCase(data)));
});

const servoLiftsSlice = createSlice({
  name: "servo_lifts",
  initialState,
  reducers: {
    dataLoaded: (state, action) => {
      state.servoLifts = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchServoLifts.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchServoLifts.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(fetchServoLifts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default servoLiftsSlice.reducer;

export const useServoLiftFromRailId = (railId) =>
  useSelector((state) => {
    const servoLifts = state.servoLifts.servoLifts;
    return servoLifts.find((sv) => sv.railId === railId);
  }, shallowEqual);

export const useCallStatus = () => useSelector((state) => state.servoLifts.status);
