import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";

export const useLocaleCode = () => {
  const currentUserId = useSelector((state) => state.session.currentUserId);
  const language = useSelector((state) => state.users[currentUserId]?.language || state.uiSettings?.defaultLanguage);
  const [languageCode, setLanguageCode] = useState("en");
  const [countryCode, setCountryCode] = useState("US");

  useEffect(() => {
    if (language) {
      setLanguageCode(language.substr(0, 2) || "en");
      setCountryCode(language.substr(3, 2) || "US");
    }
  }, [language]);

  return {
    languageCode,
    countryCode,
  };
};
