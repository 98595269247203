import produce from "immer";
import * as actions from "../actions/types";
import { objFromArray } from "../utils";

const initialState = {};

export default function loadCellReducer(state = initialState, action) {
  switch (action.type) {
    case actions.INITIALIZE_LOAD_CELLS:
      return objFromArray(action.loadCells);

    case actions.UPDATE_LOAD_CELLS:
      const merged = action.loadCells.reduce((acc, lc) => {
        acc[lc.id] = { ...state[lc.id], ...lc };
        return acc;
      }, {});

      return { ...state, ...merged };

    case actions.UPDATE_LOAD_CELL:
      const { loadCell } = action;
      const nextState = produce(state, (draftState) => {
        const newLoadCell = {
          ...draftState[loadCell.id],
          ...loadCell,
        };

        draftState[loadCell.id] = newLoadCell;
      });

      return nextState;

    default:
      return state;
  }
}
