import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CloseIcon from "@mui/icons-material/CloseSharp";
import { useTranslation } from "react-i18next";
import { useUser, useControlUsersIds, getUserFromUsername, getUser } from "../selectors/useUser";
import { useRecentLogins } from "../selectors/local";
import { parseLabel } from "../utils";
import store from "../store";
import LoginSelectionUserCard from "./LoginSelectionUserCard";
import SaveButton from "./SaveButton";

const useStyles = makeStyles((theme) => ({
  footer: {
    display: "flex",
    justifyContent: "center",
  },
  textField: {
    marginRight: theme.spacing(1),
    width: "50%",
  },
}));

const RecentLogins = ({ onSelect }) => {
  const { t } = useTranslation();
  const recentLogins = useRecentLogins() || [];

  useEffect(() => {
    const persistRecentLogins = (recentLogins) => {
      if (recentLogins.length > 0) {
        window.sessionStorage.setItem("recentLogins", JSON.stringify(recentLogins));
      }
    };

    window.addEventListener("beforeunload", () => persistRecentLogins(recentLogins));
    return () => {
      window.removeEventListener("beforeunload", () => persistRecentLogins(recentLogins));
    };
  }, []);

  if (recentLogins.length < 1) return null;
  return (
    <>
      <Typography variant="h5">{t("users:recent_logins")}</Typography>

      <Grid container spacing={2} sx={{ marginBottom: 5 }}>
        {recentLogins.map((userId) => {
          const user = getUser(userId);
          const userFullName = `${user.firstname || ""} ${user.lastname || ""}`;
          return (
            <Grid item key={`recent-login-${userId}`}>
              <Avatar src={user.imageUrl} sx={{ width: 56, height: 56 }} onClick={() => onSelect(userId)}>
                {parseLabel(userFullName)}
              </Avatar>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

const UserNameTextField = ({ username, handleSubmitUsername }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [textFieldUserName, setTextFieldUserName] = useState(null);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (username) {
      setTextFieldUserName(username);
    }
  }, [username]);

  const onSubmit = () => {
    const foundUser = getUserFromUsername(textFieldUserName) || {};
    if (foundUser.id) {
      setShowError(false);
      handleSubmitUsername(foundUser.id);
    } else {
      setShowError(true);
    }
  };

  return (
    <form
      className={classes.footer}
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <TextField
        className={classes.textField}
        margin="none"
        variant="outlined"
        label={t("users:username")}
        value={textFieldUserName || ""}
        error={showError}
        helperText={showError && t("users:user_not_found")}
        onChange={(e) => setTextFieldUserName(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
      />

      <SaveButton onClick={onSubmit}>{t("common:login")}</SaveButton>
    </form>
  );
};

const sortUserIdsByName = (userIds, sortBy, order) => {
  const state = store.getState();
  return userIds?.sort((userIdA, userIdB) => {
    const userA = getUser(userIdA, state) || {};
    let nameA = (sortBy === "firstname" ? userA.firstname : userA.lastname) || "";
    nameA = nameA.toLowerCase();

    const userB = getUser(userIdB, state) || {};
    let nameB = (sortBy === "firstname" ? userB.firstname : userB.lastname) || "";
    nameB = nameB.toLowerCase();

    if (nameA === "") return 1;
    if (nameB === "") return -1;
    if (order === "asc") {
      return nameB > nameA ? -1 : 1;
    } else {
      return nameB < nameA ? -1 : 1;
    }
    return 0;
  });
};

const SortingButtons = ({ sortBy, order, onChangeSortBy }) => {
  const { t } = useTranslation();
  return (
    <ButtonGroup variant="outlined" size="large" disableRipple={true}>
      <Button
        color={sortBy === "firstname" ? "secondary" : "primary"}
        endIcon={sortBy === "firstname" && order === "desc" ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
        onClick={() => onChangeSortBy("firstname")}
      >
        {t("common:first_name")}
      </Button>

      <Button
        color={sortBy === "lastname" ? "secondary" : "primary"}
        endIcon={sortBy === "lastname" && order === "desc" ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
        onClick={() => onChangeSortBy("lastname")}
      >
        {t("common:last_name")}
      </Button>
    </ButtonGroup>
  );
};

const useDialogStyles = makeStyles((theme) => ({
  paper: {
    margin: "90px 32px 32px 32px",
  },
  scrollPaper: {
    maxHeight: "calc(100% - 120px)",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const UserSelectionDialog = (props) => {
  const { open, handleClose, handleSelect, selectedUserId, handleSubmitUsername } = props;
  const classes = useDialogStyles();
  const { t } = useTranslation();
  const selectedUser = useUser(selectedUserId);

  const userIds = useControlUsersIds();
  const [sortBy, setSortBy] = useState("id");
  const [order, setOrder] = useState("asc");
  const sortedUserIds = sortBy === "id" ? userIds : sortUserIdsByName(userIds, sortBy, order);

  const handleOrderBy = (selected) => {
    if (selected === sortBy) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      setOrder("asc");
      setSortBy(selected);
    }
  };

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth={true}
      classes={{ paper: classes.paper, paperScrollPaper: classes.scrollPaper }}
    >
      <Box pt={2} pb={2} pl={3} pr={3}>
        <Typography variant="h3">{t("users:user_login_title")}</Typography>
        <Typography sx={{ mb: 2 }} gutterBottom>
          {t("users:user_login_subtitle")}
        </Typography>
        <SortingButtons sortBy={sortBy} order={order} onChangeSortBy={handleOrderBy} />

        <IconButton className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent className={classes.dialogContent} dividers={true}>
        <RecentLogins onSelect={handleSelect} />

        <Grid container spacing={2}>
          {sortedUserIds?.map((userId) => (
            <Grid item key={userId}>
              <LoginSelectionUserCard
                key={userId}
                userId={userId}
                onClick={() => handleSelect(userId)}
                highlight={userId === selectedUser.id}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Box padding={2} width="100%">
          <UserNameTextField username={selectedUser.login} handleSubmitUsername={handleSubmitUsername} />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

UserSelectionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  selectedUserId: PropTypes.number,
  handleSelect: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmitUsername: PropTypes.func.isRequired,
};

export default UserSelectionDialog;
