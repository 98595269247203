import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid, Typography } from "@mui/material";
import { assignCategoryToSis, assignCustomerToSis, updateSis } from "../../api/SlingInputStationApi";
import { useCustomer } from "../../selectors/customers";
import { useCategory } from "../../selectors/categories";
import { useSlingInputStation } from "../../selectors/slingInputStations";
import { useRailVisit } from "../../selectors/railVisits";
import { useCurrentRole } from "../../selectors/useRole";
import { useBatch } from "../../selectors/batches";
import CustomerFlagSelector from "../FlagSelectors/CustomerFlagSelector";
import CategoryFlagSelector from "../FlagSelectors/CategoryFlagSelector";
import DestinationRailFlagSelector from "../FlagSelectors/DestinationRailFlagSelector";
import WeightStatusWithBag from "./WeightStatusWithBag";

const CustomCondensedSingleSlingInput = ({ sisId, label }) => {
  const { t } = useTranslation();
  const { canControlInputLifts } = useCurrentRole();
  const sis = useSlingInputStation(sisId);

  const railVisit = useRailVisit({ railId: sis?.railId, position: 1 });
  const batch = useBatch(railVisit?.batchId);
  const weight = batch?.weight;
  const categoryId = batch?.categoryId;
  const category = useCategory(categoryId);
  const customerId = batch?.customerId;
  const customer = useCustomer(customerId) || {};
  const tagId = batch?.tag;

  const handleCategorySelect = (categoryId) => assignCategoryToSis({ sisId, categoryId, position: 1 });
  const handleCustomerSelect = (customerId) => assignCustomerToSis({ sisId, customerId, position: 1 });
  const handleTagSelect = (tagId) => updateSis({ sisId, data: { tag1: tagId } });

  return (
    <Grid container direction="column" alignItems="center">
      <Grid
        item
        sx={{
          mb: 2,
          "& span": {
            fontSize: 40,
          },
        }}
      >
        <Typography variant="overline">{label}</Typography>
      </Grid>

      <Grid item sx={{ width: "100%" }}>
        <WeightStatusWithBag
          weight={weight || 0}
          sis={sis}
          category={category}
          slingLoaded={batch?.id}
          position={1}
          showWeight={true}
          condensedVersion={true}
        />
      </Grid>

      <Grid item container justifyContent="center" alignItems="flex-start" spacing={2} sx={{ pt: 4, pb: 2 }}>
        <Grid item>
          <Typography variant="h5">{t("common:category")}</Typography>
          <Box>
            <CategoryFlagSelector
              selectedId={categoryId}
              size="large"
              disabled={!canControlInputLifts}
              onSelectionClick={handleCategorySelect}
            />
          </Box>
        </Grid>

        <Grid item>
          <Typography variant="h5">{t("common:customer")}</Typography>
          <Box>
            <CustomerFlagSelector
              selectedId={customer.id}
              size="large"
              handleSelect={handleCustomerSelect}
              disabled={!canControlInputLifts}
            />
          </Box>
        </Grid>

        {sis?.enableTagSelection && (
          <Grid item>
            <Typography variant="h5">{t("common:destination")}</Typography>
            <Box>
              <DestinationRailFlagSelector
                railId={sis?.railId}
                selectedTagId={tagId}
                size="large"
                disabled={!canControlInputLifts}
                onSelect={handleTagSelect}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default CustomCondensedSingleSlingInput;
