import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Paper, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  item: {
    display: "flex",
  },
  paper: {
    color: "#fff",
    backgroundColor: (props) => props.color,
    padding: 16,
    width: "100%",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(96px, 1fr))",
    gridGap: "4px",
  },
  [theme.breakpoints.down("sm")]: {
    grid: {
      gridTemplateColumns: "repeat(auto-fill, minmax(64px, 1fr))",
    },
  },
}));

const MenuGroup = (props) => {
  const { children, color, title, ...rest } = props;
  const classes = useStyles(props);

  return (
    <Box className={classes.item}>
      <Paper className={classes.paper}>
        <Typography variant="h3" color="inherit" gutterBottom>
          {title}
        </Typography>

        <Box className={classes.grid}>{children}</Box>
      </Paper>
    </Box>
  );
};

export default MenuGroup;
