import React from "react";
import { Card, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import CloneButton from "./CloneButton";
import CreateButton from "./CreateButton";
import CustomerList from "./CustomerList";
import DeleteButton from "./DeleteButton";
import RenameButton from "./RenameButton";
import SaveButton from "./SaveButton";
import StorageConfigSelector from "./StorageConfigSelector";
import styled from "styled-components";
import MappingButton from "./MappingButton";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
`;

const Selector = styled.div`
  margin-right: 6px;
`;

const Actions = styled.div`
  flex: 1;
  text-align: right;
  & > * {
    margin: 6px;
  }
`;

const useStyles = makeStyles((theme) => ({
  titleBar: {
    flex: "0 0 auto",
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const TitleBar = () => {
  const classes = useStyles();
  const systems = useSelector(
    (state) => Object.values(state.systems).filter((x) => x.storageRailIds.length > 0),
    _.isEqual
  );
  const { t } = useTranslation();

  return (
    <Card className={classes.titleBar}>
      <Typography variant="h1" gutterBottom>
        {t("storageAssignments:storage_assignments")}
      </Typography>

      <Row>
        <Selector>
          <StorageConfigSelector />
        </Selector>
        <Actions>
          <MappingButton />
          <CreateButton />
          <CloneButton />
          <RenameButton />
          <SaveButton />
          <DeleteButton />
        </Actions>
      </Row>

      <Row>
        <CustomerList />
      </Row>
    </Card>
  );
};

export default TitleBar;
