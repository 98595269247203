import React from "react";
import PropTypes from "prop-types";
import { CircularProgress, Dialog, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    width: 300,
    padding: theme.spacing(2),
  },
  message: {
    marginBottom: theme.spacing(1),
    color: theme.palette.info["main"],
  },
  loader: {
    color: theme.palette.info["main"],
  },
}));

const Loader = ({ open, message, transitionDuration }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} transitionDuration={transitionDuration}>
      <div className={classes.main}>
        {message && (
          <div className={classes.message}>
            <Typography variant="body1" gutterBottom>
              {message}
            </Typography>
          </div>
        )}
        <CircularProgress className={classes.loader} />
      </div>
    </Dialog>
  );
};

Loader.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string,
};

export default Loader;
