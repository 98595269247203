import _ from "lodash";
import store from "../store";
import { showAlertError, showAlertSuccess } from "../actions/alertActions";
import Client from "./Client.js";
import i18n from "../i18n";

export const getLaborTasks = ({ successCB }) => {
  Client.get("control_api/v1/labor_tasks")
    .then((response) => successCB && successCB(response.data))
    .catch((error) => {
      store.dispatch(showAlertError(i18n.t("laborTasksSetup:labor_tasks_fetch_failed")));
    });
};

export const addLaborTask = ({ laborTask, successCB, failureCB }) => {
  Client.post("control_api/v1/labor_tasks", laborTask)
    .then((response) => {
      if (successCB) {
        successCB(response.data);
      }

      store.dispatch(showAlertSuccess(i18n.t("common:success")));
    })
    .catch((error) => {
      const errorsByKey = _.get(error, "response.data.error", "API Error");
      if (failureCB) {
        failureCB(errorsByKey);
      }

      store.dispatch(showAlertError(`${i18n.t("common:error")}: ${JSON.stringify(errorsByKey)}`));
    });
};

export const updateLaborTask = ({ laborTaskId, laborTask, successCB, failureCB }) => {
  Client.put(`control_api/v1/labor_tasks/${laborTaskId}`, laborTask)
    .then((response) => {
      if (successCB) {
        successCB(response.data);
      }

      store.dispatch(showAlertSuccess(i18n.t("common:success")));
    })
    .catch((error) => {
      const errorsByKey = _.get(error, "response.data.error", "API Error");
      if (failureCB) {
        failureCB(errorsByKey);
      }

      store.dispatch(showAlertError(`${i18n.t("common:error")}: ${JSON.stringify(errorsByKey)}`));
    });
};

export const fetchLaborTask = ({ id, successCB }) => {
  Client.get(`control_api/v1/labor_tasks/${id}`)
    .then((response) => {
      if (successCB) {
        successCB(response.data);
      }
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data.error", "API Error"));
      store.dispatch(showAlertError(i18n.t("common:fetch_error", { error: errorMessage })));
    });
};
