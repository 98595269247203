import store from "../store";

export const getConveyors = (receivedState) => {
  const state = receivedState || store.getState();
  return state.conveyors || {};
};

export const getConveyorById = (conveyorId, state) => getConveyors(state)[conveyorId];

export const getConveyorsCount = () => Object.values(getConveyors()).length;
