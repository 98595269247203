import React from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch, useLocation, useHistory } from "react-router-dom";
import { Box, ButtonGroup, Button, Divider, Paper, Typography } from "@mui/material";
import { TECHNICIAN_PORTAL } from "../constants/Paths";
import { useEvuePlcIp, useSLXHost } from "../selectors/uiSettings";
import useRedirectOnLogout from "../features/technicianPortal/hooks/useRedirectOnLogout";

const ContentIframe = ({ src }: { src: string }) => {
  return (
    <iframe
      style={{ border: 0, display: "block", height: "100%", width: "100%"}}
      title="technician-portal"
      src={src}
    ></iframe>
  );
};

const TechnicianPortal = () => {
  const { t } = useTranslation();

  useRedirectOnLogout();

  const eVueServerHost = window.location.hostname;
  const plcHost = useEvuePlcIp();
  const slxServerHost = useSLXHost();
  const devEnv = process.env.NODE_ENV === "development";
  const eVueSvrPort = devEnv ? ":3000" : "";
  const monitorPort = devEnv ? ":3003" : "";

  const links = [
    { path: "monitor", label: "Monitor", url: `http://${eVueServerHost}${monitorPort}/monitor/dashboard` },
    {
      path: "plc_interface",
      label: "PLC Interface",
      url: `http://${eVueServerHost}${eVueSvrPort}/plc-interface`,
    },
    { path: "kai", label: "KAI", url: `http://${eVueServerHost}:4040/kai` },
    { path: "portainer", label: "Portainer", url: `http://${eVueServerHost}:9000` },
    {
      path: "slx_health",
      label: "SLX Health",
      url: `http://${slxServerHost}/settings/#/health`,
    },
    { path: "plc", label: "PLC", url: `http://${plcHost}/sdm` },
    {
      path: "service_page",
      label: "Service Page",
      url: `http://${plcHost}:81/index.html?visuid=service`,
    },
    { path: "sidekiq", label: "Sidekiq", url: `http://${eVueServerHost}${eVueSvrPort}/sidekiq` },
  ];

  const history = useHistory();
  const location = useLocation();

  const handleChange = (path: string) => {
    history.push(path);
  };

  return (
    <Paper square={true} elevation={0} sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box padding={3} display="flex" justifyContent="space-between" alignItems="flex-end">
        <Typography variant="h1">{t("menus:technician_portal")}</Typography>

        <ButtonGroup disableElevation variant="outlined" color="secondary">
          {links.map((link) => (
            <Button
              key={link.label}
              sx={(theme) => ({
                "&:hover": {
                  backgroundColor: theme.palette.secondary.light,
                },
                backgroundColor: location.pathname === `${TECHNICIAN_PORTAL}/${link.path}` ?
                  theme.palette.secondary.light :
                  "inherit",
              })}
              onClick={() => handleChange(`${TECHNICIAN_PORTAL}/${link.path}`)}
            >
              {link.label}
            </Button>
          ))}
        </ButtonGroup>
      </Box>

      <Divider />

      <Box sx={{ height: "100%" }}>
        <Switch>
          <Redirect exact from={TECHNICIAN_PORTAL} to={`${TECHNICIAN_PORTAL}/${links[0].path}`} />

          {links.map((link) => (
            <Route
              key={link.path}
              path={`${TECHNICIAN_PORTAL}/${link.path}`}
              render={() => <ContentIframe src={link.url} />}
            />
          ))}
        </Switch>
      </Box>
    </Paper>
  );
};

export default TechnicianPortal;
