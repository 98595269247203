import store from "../store";

export const getMachineVisits = (receivedState) => {
  const state = receivedState || store.getState();
  return state.machineVisits || {};
};

export const getBatchIdsWithActiveMVs = (receivedState) => {
  const activeBatchIds = [];
  const machineVisits = getMachineVisits(receivedState);
  Object.values(machineVisits)
    ?.flat()
    ?.forEach((machineVisit) => {
      activeBatchIds.push(machineVisit.batchId);
    });

  return activeBatchIds;
};
