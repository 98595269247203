import _ from "lodash";
import { useTranslation } from "react-i18next";
import { showAlertError, showAlertSuccess } from "../../../actions";
import { useAppDispatch } from "../../../hooks/useAppDispatch";

// TODO: Promote this since more features will probably use it
const useStorageGroupAlertMessages = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const onSuccess = () => dispatch(showAlertSuccess(t("common:success")));
  const onFailure = (error?: {}) => {
    const errorsByKey = error && _.get(error, "response.data.error", "API Error");
    return dispatch(showAlertError(`${t("common:error")}${errorsByKey && ":"} ${JSON.stringify(errorsByKey)}`))
  };

  return { onSuccess, onFailure };
};

export default useStorageGroupAlertMessages;
