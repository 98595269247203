import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { useRailVisitsCountForRail } from "../../selectors/railVisits";
import CircleIndicator from "../../components/CircleIndicator";
import { railInputEnabled, railOutputEnabled } from "../../api/RailApi";
import type { Rail } from "../../dto/eVue/Rail";
import { IndicatorStatusTypes } from "../panels/enum/IndicatorStatusTypes";
import CompManualSendButton from "./CompManualSendButton";
import CompManualOpenButton from "./CompManualOpenButton";
import ConcisePrintButton from "./ConcisePrintButton";

interface Props<T> {
  rail: T;
};

const TitleBar = <T extends Rail>({ rail }: Props<T>) => {
  const { t } = useTranslation();

  const { id, name, inputEnabled, outputEnabled, plcState } = rail;
  const rvCount = useRailVisitsCountForRail(id);
  const slingLoaded = rvCount >= 1;

  const handleToggleInbound = () => railInputEnabled({ id, inputEnabled });
  const handleToggleOutbound = () => railOutputEnabled({ id, outputEnabled });

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="h1" gutterBottom>
        {name}
      </Typography>

      <Typography variant="overline" sx={{ mb: 1 }}>
        {t<string>("railStates:state_state", {
          state: t(`railStates:${plcState}`),
        })}
      </Typography>

      <Box display="flex" justifyContent="space-between" alignItems="flex-end">
        <div>
          <CircleIndicator
            label={t("io:in")}
            disabled={false}
            status={inputEnabled ? IndicatorStatusTypes.ON : IndicatorStatusTypes.OFF}
            onClick={handleToggleInbound}
          />

          <CircleIndicator
            label={t("io:out")}
            disabled={false}
            status={outputEnabled ? IndicatorStatusTypes.ON : IndicatorStatusTypes.OFF}
            onClick={handleToggleOutbound}
          />
        </div>

        <Box display="flex">
          <CompManualSendButton railId={id} disabled={plcState !== "transferring" || rvCount < 1} />
          <CompManualOpenButton railId={id} disabled={!slingLoaded || plcState !== "ready_to_transfer"} />
          <ConcisePrintButton railId={id} />
        </Box>
      </Box>
    </Box>
  );
};

export default TitleBar;
