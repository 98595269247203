import React, { useState } from "react";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Edit } from "@mui/icons-material";
import { renameList } from "../../redux/productionListEditor";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useCurrentRole } from "../../selectors/useRole";
import TextEditorDialog from "../TextEditorDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 200,
    "&:disabled": {
      backgroundColor: theme.palette.grey[200],
    },
  },
}));

const RenameButton = () => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enableRename, name } = useSelector((state) => state.productionListEdit);
  const { t } = useTranslation();
  const role = useCurrentRole();

  return (
    <>
      <Button
        className={classes.root}
        variant="outlined"
        disabled={!role.canEditPicklists || !enableRename}
        onClick={() => setOpen(true)}
        startIcon={<Edit />}
      >
        {t("common:rename")}
      </Button>

      <TextEditorDialog
        open={open}
        fullWidth={true}
        maxWidth="md"
        message={t("productionList:enter_new_name")}
        initialValue={name}
        onClose={() => setOpen(false)}
        onSubmit={(name) => {
          setOpen(false);
          dispatch(renameList(name));
        }}
      />
    </>
  );
};

export default RenameButton;
