import React from "react";
import { Box, CircularProgress, SxProps } from "@mui/material";

interface Props {
  sx?: SxProps;
};

const ConciseButtonLoadingState = ({ sx }: Props) => {
  return (
    <Box
      sx={{
        backgroundColor: "lightgrey",
        borderRadius: "50%",
        width: 60,
        height: 60,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "grey.500",
        ...sx,
      }}
    >
      <CircularProgress color="inherit" />
    </Box>
  );
};

export default ConciseButtonLoadingState;
