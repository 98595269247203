import React, { useState } from "react";
import { Fab } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material/styles";
import PaintIcon from "@mui/icons-material/FormatColorFill";
import ColorSelectionDialog from "./ColorSelectionDialog";

const useStyles = makeStyles((theme) => ({
  fab: {
    backgroundColor: (props) => props.color || theme.palette.grey[300],
    boxShadow: "none",

    "&:hover": {
      backgroundColor: (props) => props.color || theme.palette.grey[300],
    },

    "&:disabled": {
      backgroundColor: (props) => alpha(props.color || theme.palette.grey[300], 0.4),
    },
  },
  icon: {
    color: (props) => theme.palette.getContrastText(props.color || theme.palette.grey[300]),
  },
}));

const SwatchColorInput = ({ selectedColor, disabled, onChange }) => {
  const classes = useStyles({ color: selectedColor });

  const [openSelector, setOpenSelector] = useState(false);

  return (
    <>
      <Fab size="large" className={classes.fab} disabled={disabled} onClick={() => !disabled && setOpenSelector(true)}>
        <PaintIcon className={classes.icon} />
      </Fab>

      {openSelector && (
        <ColorSelectionDialog
          key={selectedColor}
          open={openSelector}
          selectedColor={selectedColor}
          onSelect={(color) => {
            onChange(color);
            setOpenSelector(false);
          }}
          onClose={() => setOpenSelector(false)}
        />
      )}
    </>
  );
};

export default SwatchColorInput;
