import React from "react";
import { Box, TextField } from "@mui/material";
import FormFieldLabel from "../../../../components/SetupPages/FormFieldLabel";

interface Props {
  label: string;
  initialValue: number | "";
  disabled: boolean;
  errorMessage?: string;
  onChange: (selection: number | "") => void;
};

const FormNumberInput = ({ label, initialValue, disabled, errorMessage, onChange }: Props) => {
  return (
    <Box display="flex" flexDirection="column">
      <FormFieldLabel label={label} />
      <TextField
        sx={{
          "& .MuiInputBase-root input": {
            maxWidth: 100,
            padding: "14px 10px",
            fontSize: 20,
            height: 20,
          },
          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            display: "none",
          },
        }}
        type="number"
        defaultValue={initialValue}
        margin="none"
        variant="outlined"
        error={Boolean(errorMessage)}
        helperText={errorMessage}
        disabled={disabled}
        InputLabelProps={{ shrink: true }}
        onChange={(e) => onChange(e.target.value ? parseFloat(e.target.value) : "")}
      />
    </Box>
  );
};

export default FormNumberInput;
