import { createSlice } from "@reduxjs/toolkit";
import { socket } from "../../utils/channel";
import { shallowEqual, useSelector } from "react-redux";

const DelaySlice = createSlice({
  name: "delays",
  initialState: [],
  reducers: {
    dataUpdate: (state, action) => {
      return action.payload.data;
    },
  },
});

export function delaySubscribe() {
  const delayChannel = socket.channel("delays");
  return (dispatch) => {
    delayChannel
      .join(30000)
      .receive("ok", (messages) => {
        // dispatch(DelaySlice.actions.initLocations(messages));
      })
      .receive("error", (reason) =>
        dispatch({
          type: "CHANNEL_JOIN_ERROR",
          channel: "SLX",
        })
      );

    delayChannel.on("delay_update", (msg) => {
      dispatch(DelaySlice.actions.dataUpdate(msg));
    });
  };
}

// Selectors
export const useDelays = () => useSelector((state) => state.delays, shallowEqual);

export default DelaySlice.reducer;
