import React from "react";
import { Box, Select, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import FormFieldLabel from "../SetupPages/FormFieldLabel";

const FormDropdownSelect = (props) => {
  const { fieldName, label, currentValues, options, showNone = false, disabled, onFieldChange } = props;
  const { t } = useTranslation();
  return (
    <Box display="flex" flexDirection="column">
      {label && <FormFieldLabel label={label} />}

      <Select
        variant="outlined"
        displayEmpty={true}
        value={currentValues[fieldName] || ""}
        disabled={disabled}
        onChange={(e) => onFieldChange(fieldName, e.target.value)}
      >
        {showNone && (
          <MenuItem value="">
            <em>{t("common:none")}</em>
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem key={option} value={option} disabled={disabled}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default FormDropdownSelect;
