import React from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/CloseSharp";
import { useTranslation } from "react-i18next";
import FilterControls from "./FilterControls";
import type { FilterSelections } from "./dto/FilterSelections";

interface SelectionDialogWithFilterProps {
  children: string | JSX.Element | JSX.Element[],
  handleClose: () => void,
  open: boolean,
  title: string,
  sortBy: FilterSelections,
  order: "asc" | "desc",
  onChangeSortBy: (selection: FilterSelections) => void,
  onChangeSearchTerm: (term: string) => void,
};

const SelectionDialogWithFilter = (props: SelectionDialogWithFilterProps) => {
  const { children, handleClose, open, title, sortBy, order, onChangeSortBy, onChangeSearchTerm } = props;
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      maxWidth="md"
      onBackdropClick={handleClose}
      fullWidth={true}
      sx={{
        "& .MuiDialog-paper": {
          maxHeight: "calc(100vh - 180px)",
          height: "100%",
        }
      }}
    >
      <Box display="flex" flexDirection="column" pt={2} pl={3} pb={2} pr={3}>
        <Typography variant="h3">{title}</Typography>

        <FilterControls
          sx={{ mt: 2 }}
          sortBy={sortBy}
          order={order}
          onChangeSortBy={onChangeSortBy}
          onChangeSearchTerm={onChangeSearchTerm}
        />
      </Box>

      <DialogContent sx={{ height: "100%" }} dividers={true}>
        {children}
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          autoFocus
          disableElevation
          disableFocusRipple
          onClick={handleClose}
          startIcon={<CloseIcon />}
        >
          {t<string>("common:close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectionDialogWithFilter;
