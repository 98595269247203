import _ from "lodash";
import store from "../store";
import { showAlertError, showAlertSuccess } from "../actions";
import i18n from "../i18n";
import Client from "./Client.js";

export const updateSystemParameter = ({ systemParameterId, successCB, failureCB, ...rest }) => {
  Client.put(`control_api/v1/system_parameters/${systemParameterId}`, { ...rest })
    .then((response) => {
      if (successCB) {
        successCB();
      }
      return store.dispatch(showAlertSuccess(i18n.t("common:success")));
    })
    .catch((error) => {
      const errorsByKey = _.get(error, "response.data", "API Error");
      if (failureCB) {
        failureCB(errorsByKey);
      }
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${JSON.stringify(errorsByKey)}`));
    });
};
