import React from "react";
import { Box, Typography } from "@mui/material";
import { getBagWeightStatus } from "../../../components/SlingInputStation/utils";
import { useWeightUnit } from "../../../selectors/uiSettings";
import { useRail } from "../../../selectors/rails";
import ConveyorInputGraphic from "./ConveyorInputGraphic";
import WeightThresholds from "./WeightThresholds";

const Weight = ({ weight, weightAsPercent, loadStatus }) => {
  const weightUnit = useWeightUnit();
  return (
    <Typography
      sx={[
        (theme) => ({
          fontSize: 50,
          color: theme.palette.warning["dark"],
        }),
        (theme) => loadStatus === "underweight" && { color: theme.palette.info["main"] },
        (theme) => loadStatus === "overweight" && { color: theme.palette.error["main"] },
        (theme) =>
          ["target_met", "almost_full", "full"].includes(loadStatus) && {
            color: theme.palette.success["dark"],
          },
      ]}
      variant="h1"
      align="center"
    >
      {`${weight || 0} ${weightUnit} | ${weightAsPercent}%`}
    </Typography>
  );
};

const WeightStatusWithConveyor = ({ weight = 0, sis, category }) => {
  const weightAsPercent = weight && category?.targetWeight ? Math.round((weight / category?.targetWeight) * 100) : 0;
  const loadStatus = getBagWeightStatus({ slingLoaded: true, sis, category, currentWeight: weight });
  const rail = useRail(sis?.railId);
  const conveyorMoving = rail?.plcState === "run_to_front";

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <ConveyorInputGraphic move={conveyorMoving} percent={weightAsPercent} loadStatus={loadStatus} />
      <Weight weight={weight} weightAsPercent={weightAsPercent} loadStatus={loadStatus} />
      <WeightThresholds categoryId={category?.id} currentWeight={weight} />
    </Box>
  );
};

export default WeightStatusWithConveyor;
