import React, { useState, useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import _ from "lodash";
import { FixedSizeList as List } from "react-window";
import { SETUP_RAILS } from "../../constants/Paths";
import { RailGroupNames } from "../../constants/RailGroup";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useTranslation } from "react-i18next";
import store from "../../store";
import { sortingComparer } from "../../utils/sort";
import { useRail, useRailsIds, getRailById } from "../../selectors/rails";
import { useSystem, getSystemFromId } from "../../selectors/systems";
import { useCurrentRole } from "../../selectors/useRole";
import SearchField from "../SearchField";
import RailGroupSelector from "./RailGroupSelector";
import SystemSelector from "./SystemSelector";

const StyledTableRow = withStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    boxSizing: "border-box",
    width: "100%",
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
}))(TableRow);

const StyledTableSortLabel = withStyles((theme) => ({
  root: {
    "&$active": {
      color: theme.palette.common.black,
    },
  },
  active: {},
  icon: {
    color: "inherit !important",
  },
}))(TableSortLabel);

const StyledTableCell = withStyles((theme) => ({
  head: {
    padding: theme.spacing(2),
    height: 74,
    borderBottom: "none",
  },
  body: {
    fontSize: 14,
    padding: theme.spacing(2),
    borderBottom: "none",

    "& .MuiBox-root": {
      display: "flex",
      alignItems: "center",
    },
    "& .MuiAvatar-root": {
      marginRight: theme.spacing(1),
    },
  },
}))(TableCell);

const columnWidths = {
  id: "10%",
  name: "40%",
  railGroup: "25%",
  system: "10%",
  fullQuantity: "10%",
  action: "5%",
};

const HeadersRow = ({ order, orderBy, onOrderBy }) => {
  const { t } = useTranslation();
  const headers = [
    {
      orderBy: "id",
      label: "ID",
      align: "left",
      width: columnWidths["id"],
    },
    {
      orderBy: "name",
      label: "name",
      align: "left",
      width: columnWidths["name"],
    },
    {
      orderBy: "railGroup",
      label: "rail_group",
      align: "left",
      width: columnWidths["railGroup"],
    },
    {
      orderBy: "system",
      label: "system",
      align: "left",
      width: columnWidths["system"],
    },
    {
      orderBy: "fullQuantity",
      label: "full_quantity",
      align: "right",
      width: columnWidths["fullQuantity"],
    },
    {
      orderBy: "action",
      label: "",
      align: "right",
      width: columnWidths["action"],
    },
  ];

  return (
    <StyledTableRow sx={{ pr: 2, minWidth: "100%" }} component="div">
      {headers.map((heading) => (
        <StyledTableCell
          key={heading.orderBy}
          id={`${heading.orderBy}-header`}
          align={heading.align}
          sx={{ flexBasis: heading.width }}
          component="div"
        >
          {heading.label && (
            <StyledTableSortLabel
              active={orderBy === heading.orderBy}
              direction={order}
              sx={{ width: "100%" }}
              onClick={() => onOrderBy(heading.orderBy)}
            >
              <Typography noWrap={true}>{t(`railsSetup:${heading.label}`)}</Typography>
            </StyledTableSortLabel>
          )}
        </StyledTableCell>
      ))}
    </StyledTableRow>
  );
};

const getHeaderWidth = (key) => {
  const header = document.getElementById(`${key}-header`);
  return header?.getBoundingClientRect().width;
};

const RailRow = ({ index, style, data: { items, onClick } }) => {
  const railId = items[index];
  const rail = useRail(railId);
  const { t } = useTranslation();
  const { id, name, railGroup, systemId, fullQuantity } = rail;
  const railGroupName = t(`railGroups:${RailGroupNames[railGroup] || ""}`);
  const system = useSystem(systemId) || {};
  const currentRole = useCurrentRole();

  return (
    <StyledTableRow style={style} sx={{ backgroundColor: index % 2 ? "common.white" : "grey.100" }} component="div">
      <StyledTableCell align="left" sx={{ width: getHeaderWidth("id") }} component="div">
        {id}
      </StyledTableCell>

      <StyledTableCell align="left" sx={{ width: getHeaderWidth("name") }} component="div">
        {name}
      </StyledTableCell>

      <StyledTableCell align="left" sx={{ width: getHeaderWidth("railGroup") }} component="div">
        {railGroupName}
      </StyledTableCell>

      <StyledTableCell align="left" sx={{ width: getHeaderWidth("system") }} component="div">
        {t(`common:${_.snakeCase(system?.name || "")}`)}
      </StyledTableCell>

      <StyledTableCell align="right" sx={{ width: getHeaderWidth("fullQuantity") }} component="div">
        {fullQuantity}
      </StyledTableCell>

      <StyledTableCell align="right" sx={{ width: getHeaderWidth("action") }} component="div">
        <IconButton onClick={() => onClick(id)}>
          {currentRole.canEditRails ? <EditIcon /> : <VisibilityIcon />}
        </IconButton>
      </StyledTableCell>
    </StyledTableRow>
  );
};

const DebouncedSearchField = ({ initialValue, onSubmitSearchTerm }) => {
  const { t } = useTranslation();

  const debouncedChangeHandler = useMemo(() => {
    return _.debounce(onSubmitSearchTerm, 300);
  }, []);

  return (
    <SearchField
      initialValue={initialValue}
      placeholder={t("railsSetup:search_instructions")}
      onChange={debouncedChangeHandler}
      onSubmitSearchTerm={onSubmitSearchTerm}
    />
  );
};

const filterRailIds = ({ ids, searchTerm, selectedRailGroup, selectedSystem }) => {
  const st = searchTerm?.toLowerCase();
  const state = store.getState();
  return ids.filter((id) => {
    const rail = getRailById(id, state);
    const railIdsMatch = rail?.id && st ? _.includes(`${rail.id}`, st) : true;
    const railNamesMatch = rail?.name && st ? _.includes(rail.name?.toLowerCase(), st) : true;
    const rallGroupsMatch = selectedRailGroup ? rail.railGroup === selectedRailGroup : true;
    const railSystemsMatch = selectedSystem ? rail.systemId === selectedSystem : true;

    return (railIdsMatch || railNamesMatch) && rallGroupsMatch && railSystemsMatch;
  });
};

const sortRailIds = ({ ids, orderBy, order }) => {
  const state = store.getState();
  if (ids.length < 1) return [];
  return ids.sort((a, b) => {
    const railA = getRailById(a, state);
    const railB = getRailById(b, state);
    let valueA = railA[orderBy];
    let valueB = railB[orderBy];

    if (orderBy === "system") {
      valueA = getSystemFromId(railA.systemId, state)?.name;
      valueB = getSystemFromId(railB.systemId, state)?.name;
    }

    if (orderBy === "railGroup") {
      valueA = RailGroupNames[railA.railGroup];
      valueB = RailGroupNames[railB.railGroup];
    }

    return sortingComparer(valueA, valueB, order);
  });
};

const RailsList = () => {
  const { t } = useTranslation();
  const railIds = useRailsIds();
  const location = useLocation();
  const initialScrollPos = location.state?.listScrollPos;

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [searchTerm, setSearchTerm] = useState(location.state?.searchTerm || "");
  const [selectedRailGroup, setSelectedRailGroup] = useState(location.state?.selectedRailGroup || "");
  const [selectedSystem, setSelectedSystem] = useState(location.state?.selectedSystem || "");
  const [scrollPos, setScrollPos] = useState(initialScrollPos);

  const handleOrderBy = (selected) => {
    if (selected === orderBy) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      setOrder("asc");
      setOrderBy(selected);
    }
  };

  const filteredRailIds = filterRailIds({ ids: railIds, searchTerm, selectedRailGroup, selectedSystem });
  const sortedRailIds = sortRailIds({ ids: filteredRailIds, orderBy, order });

  const [tableWidth, setTableWidth] = useState(null);
  const [tableHeight, setTableHeight] = useState(null);
  const tableContainerRef = useCallback((node) => {
    if (node !== null) {
      setTableWidth(node.getBoundingClientRect().width);
      setTableHeight(node.getBoundingClientRect().height - 76);
    }
  }, []);

  const [headersReady, setHeadersReady] = useState(false);
  const headersRef = useCallback((node) => {
    if (node !== null) {
      setHeadersReady(true);
    }
  }, []);

  const debouncedOnScrollHandler = useMemo(() => {
    return _.debounce((offset) => setScrollPos(offset), 300);
  }, []);

  const history = useHistory();
  const handleOnClick = (id) => {
    const newLocation = {
      pathname: `${SETUP_RAILS}/${id}`,
      state: { listScrollPos: scrollPos, searchTerm, selectedRailGroup, selectedSystem },
    };
    history.push(newLocation);
  };

  return (
    <Paper square={true} elevation={0} sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Grid container sx={{ padding: 3 }} justifyContent="space-between" alignItems="center" flexWrap="nowrap">
        <Grid item>
          <Typography variant="h1" noWrap={true}>
            {t("railsSetup:rails_setup")}
          </Typography>
        </Grid>

        <Grid item sx={{ display: "flex", flexWrap: "nowrap" }}>
          <Box width={350} pr={1}>
            <DebouncedSearchField initialValue={location.state?.searchTerm} onSubmitSearchTerm={setSearchTerm} />
          </Box>
          <Box pr={1}>
            <RailGroupSelector selectedRailGroup={selectedRailGroup} onChange={setSelectedRailGroup} />
          </Box>
          <SystemSelector selectedSystem={selectedSystem} onChange={setSelectedSystem} />
        </Grid>
      </Grid>

      <Divider />

      <TableContainer
        ref={tableContainerRef}
        sx={{ height: "calc(100vh - 185px)", overflowX: "auto", overflowY: "hidden" }}
      >
        <Table size="small" sx={{ height: "100%", width: "100%" }} component="div">
          <TableHead ref={headersRef} component="div">
            <HeadersRow order={order} orderBy={orderBy} onOrderBy={handleOrderBy} />
          </TableHead>

          <TableBody component="div">
            {headersReady && tableWidth && tableHeight && (
              <List
                height={tableHeight}
                width={tableWidth}
                itemCount={sortedRailIds.length}
                itemSize={74}
                itemData={{
                  items: sortedRailIds,
                  onClick: (id) => handleOnClick(id),
                }}
                initialScrollOffset={scrollPos}
                onScroll={({ scrollOffset }) => debouncedOnScrollHandler(scrollOffset)}
              >
                {RailRow}
              </List>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default RailsList;
