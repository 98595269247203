import React, { useEffect } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import RemoveIcon from "@mui/icons-material/RemoveCircleOutlineSharp";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { showAlertError } from "../../../actions";
import {
  showMessage,
  selectSelectedSortConfig,
  selectEnableDelete,
} from "../redux/sortConfigurationsSlice";
import { useCurrentRole } from "../../../selectors/useRole";
import BaseButton from "../../../components/BaseButton";
import { useDeleteSortConfigurationMutation } from "../services/sortConfigurations";

const DeleteButton = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [deleteConfig, response] = useDeleteSortConfigurationMutation();
  const selectedSortConfig = useAppSelector(selectSelectedSortConfig, _.isEqual);
  const enableDelete = useAppSelector(selectEnableDelete);
  const role = useCurrentRole();

  // TODO: Extract status messaging to hook
  const error = response.error as { data: { error?: string }};
  useEffect(() => {
    if (error) {
      dispatch(showAlertError(`${t("common:error")}: ${error.data?.error}`))
    };
  }, [response.isError])

  return (
    <BaseButton
      sx={{ maxWidth: 200 }}
      color="error"
      disabled={!enableDelete || !role.canEditSortConfigs || response.isLoading}
      startIcon={<RemoveIcon />}
      onClick={() => {
        dispatch(showMessage(t("sortAssignments:deleting_sort_configuration")));
        deleteConfig(selectedSortConfig?.id);
      }}
    >
      {t<string>("common:delete")}
    </BaseButton>
  );
};

export default DeleteButton;
