import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Avatar, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useCategory } from "../../selectors/categories";
import { useCustomer } from "../../selectors/customers";
import { useWeightUnit } from "../../selectors/uiSettings";

const groupingStyles = makeStyles((theme) => ({
  imageAndNumber: {
    display: "flex",
    alignItems: "center",
  },
  image: {
    height: 40,
    width: 40,
    backgroundColor: theme.palette.grey[200],
    borderRadius: 5,
    marginRight: theme.spacing(1),
  },
  avatar: {
    border: `1px solid ${theme.palette.white}`,
    minWidth: 40,
    width: "fit-content",
    padding: `${0}px ${theme.spacing(1)}px`,
  },
}));

const ContentGrouping = ({ title, resource }) => {
  const classes = groupingStyles();
  const { imageUrl, color, number, name } = resource || {};
  return (
    <>
      <Typography color="inherit" variant="overline" align="center">
        {title}
      </Typography>

      <div className={classes.imageAndNumber}>
        {imageUrl && <img className={classes.image} src={imageUrl} />}

        <Avatar className={classes.avatar} variant="rounded" style={{ backgroundColor: color }}>
          <Typography color="inherit" variant="h3">
            {number}
          </Typography>
        </Avatar>
      </div>

      <Typography color="inherit" variant="h4" align="center" style={{ marginBottom: 16 }}>
        {name}
      </Typography>
    </>
  );
};

const styles = makeStyles((theme) => ({
  slingPresent: {
    height: 300,
    width: 220,
    backgroundColor: theme.palette.info.light,
    border: `2px solid ${theme.palette.info.main}`,
    borderRadius: "10px 10px 80px 80px",
  },
  slingMissing: {
    backgroundColor: theme.palette.grey[300],
    borderColor: theme.palette.grey[400],
  },
  bagWrapper: {
    position: "relative",
    width: "fit-content",
    justifyContent: "center",
  },
  bagInfo: {
    height: 300,
    width: 220,
    borderRadius: "10px 10px 80px 80px",
    position: "absolute",
    padding: theme.spacing(1),
    color: theme.palette.common.white,
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  emptyText: {
    color: theme.palette.grey[600],
  },
}));

const Bag = ({ slingLoaded, categoryId, customerId, weight, top }) => {
  const classes = styles();
  const { t } = useTranslation();
  const category = useCategory(categoryId);
  const customer = useCustomer(customerId);
  const weightUnit = useWeightUnit();

  return (
    <Grid container className={classes.bagWrapper}>
      <Grid item className={classes.bagInfo}>
        {slingLoaded ? (
          <>
            <ContentGrouping title={t("common:category")} resource={category} />
            <ContentGrouping title={t("common:customer")} resource={customer} />
            <Typography color="inherit" variant="overline" align="center">
              {t("common:weight")}
            </Typography>
            <Typography color="inherit" variant="h4" align="center">
              {`${weight || 0} ${weightUnit}`}
            </Typography>
          </>
        ) : (
          <Typography variant="h3" align="center" className={classes.emptyText}>
            {t(`staticBag:${top ? "waiting_for_bag" : "waiting_for_load"}`)}
          </Typography>
        )}
      </Grid>

      <Grid item className={clsx({ [classes.slingPresent]: true, [classes.slingMissing]: !slingLoaded })}></Grid>
    </Grid>
  );
};

export default Bag;
