import _ from "lodash";
import { showAlertError, showAlertSuccess } from "../actions";
import store from "../store";
import i18n from "../i18n";
import Client from "./Client.js";

export const setSpeed = (conveyorId, newSpeed) => {
  Client.put(`control_api/v1/conveyors/${conveyorId}`, { speed: newSpeed })
    .then((response) => store.dispatch(showAlertSuccess(i18n.t("common:success"))))
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};
