import React, { useEffect, useState } from "react";
import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { useCreateSortConfigurationMutation } from "../services/sortConfigurations";
import { showMessage, selectSelectedSystem } from "../redux/sortConfigurationsSlice";
import { useCurrentRole } from "../../../selectors/useRole";
import TextEditorDialog from "../../../components/TextEditorDialog";
import { showAlertError } from "../../../actions";

const CreateButton = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const role = useCurrentRole();
  const selectedSystem = useAppSelector(selectSelectedSystem);
  const [createConfig, { isLoading, isError, error }] = useCreateSortConfigurationMutation();

  useEffect(() => {
    if (error) {
      const responseError = error as { data: { error?: string }};
      dispatch(showAlertError(`${t("common:error")}: ${responseError.data?.error}`))
    };
  }, [isError])

  return (
    <>
      <Button
        sx={{
          maxWidth: 200,
          "&:disabled": {
            backgroundColor: "grey.200",
          },
        }}
        variant="outlined"
        startIcon={<Add />}
        disabled={!role.canEditSortConfigs || isLoading}
        onClick={() => setOpen(true)}
      >
        {t("common:create")}
      </Button>

      <TextEditorDialog
        open={open}
        fullWidth={true}
        maxWidth="md"
        message={t("sortAssignments:enter_new_name")}
        initialValue={t("sortAssignments:new_sort_config")}
        onClose={() => setOpen(false)}
        onSubmit={(name: string) => {
          setOpen(false);
          dispatch(showMessage(t("sortAssignments:creating_sort_configuration")));
          createConfig({ name, systemId: selectedSystem?.id });
        }}
      />
    </>
  );
};

export default CreateButton;
