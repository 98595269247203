import React from "react";
import { Backdrop, Box, CardContent, CircularProgress, Divider, Grid, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import { SETUP_ROLES } from "../../constants/Paths";
import { useRole, useCurrentRole, formatForUpdate } from "../../selectors/useRole";
import { useAdminRoleId, useTechnicianRoleId } from "../../selectors/roles";
import { getHasRailSystem } from "../../selectors/rails";
import { getEvueLabor } from "../../selectors/uiSettings";
import { updateRole, addRole } from "../../api/RoleApi";
import FormEditor from "../SetupPages/FormEditor";
import SaveButton from "../SaveButton";
import FormTextField from "../SetupPages/FormTextField";
import FormCheckbox from "../SetupPages/FormCheckbox";
import RolesListButton from "./RolesListButton";

const SetupForm = ({ role }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const currentRole = useCurrentRole();
  const technicianRoleId = useTechnicianRoleId();
  const adminRoleId = useAdminRoleId();
  const hasRailSystem = getHasRailSystem();
  const hasLabor = getEvueLabor();

  return (
    <FormEditor
      originalValues={{ ...role }}
      onSubmit={({ currentValues, actions }) => {
        const valuesForSubmission = Object.keys(currentValues).reduce((acc, key) => {
          return { ...acc, [key]: currentValues[key] === "" ? null : currentValues[key] };
        }, {});

        role?.id
          ? updateRole({
              role: { ...formatForUpdate(valuesForSubmission) },
              successCB: () => {
                actions.finishSubmit();
                history.push(SETUP_ROLES);
              },
              errorCB: (errors) => {
                actions.finishSubmit(true);
                actions.catchErrors(errors);
              },
            })
          : addRole({
              role: { ...formatForUpdate(valuesForSubmission) },
              successCB: () => {
                actions.finishSubmit();
                history.push(SETUP_ROLES);
              },
              errorCB: (errors) => {
                actions.finishSubmit(true);
                actions.catchErrors(errors);
              },
            });
      }}
    >
      {(props) => {
        const { currentValues, dirtyForm, errors, isSubmitting, isDeleting, setFieldValue, handleSubmit } = props;

        const technicianRole = role?.id === technicianRoleId;
        const adminRole = role?.id === adminRoleId;
        const formDisabled = technicianRole || adminRole || isSubmitting || !currentRole.canManageUsers;
        return (
          <>
            <CardContent sx={{ height: "100%", overflow: "auto" }}>
              <Backdrop open={isSubmitting || isDeleting}>
                <CircularProgress color="inherit" />
              </Backdrop>

              <Box mb={3} maxWidth={600}>
                <FormTextField
                  fieldName="name"
                  label={t("common:name")}
                  currentValues={currentValues}
                  errors={errors}
                  disabled={formDisabled}
                  onFieldChange={setFieldValue}
                />
              </Box>

              <Grid container>
                {hasRailSystem && (
                  <Box mb={3} mr={4}>
                    <Divider />

                    <Typography variant="h4" sx={{ mt: 2, mb: 1 }}>
                      {t("common:bags")}
                    </Typography>

                    <Grid container direction="column">
                      {[
                        { attribute: "canTagSlings", label: "rolesSetup:tag_bags" },
                        { attribute: "canToggleRails", label: "rolesSetup:turn_rails_on_off" },
                        { attribute: "canHaltSystem", label: "rolesSetup:halt_system" },
                        { attribute: "canEditSlings", label: "rolesSetup:edit_bags" },
                        { attribute: "canAddSlings", label: "rolesSetup:add_bags" },
                        { attribute: "canDeleteSlings", label: "rolesSetup:delete_bags" },
                        {
                          attribute: "canValidateRail",
                          label: "rolesSetup:validation_station_operation",
                        },
                        { attribute: "canEditValidations", label: "rolesSetup:perform_validations" },
                        {
                          attribute: "canToggleScanners",
                          label: "rolesSetup:turn_scanners_on_and_off",
                        },
                      ].map((field) => (
                        <Grid key={field.attribute} item>
                          <FormCheckbox
                            fieldName={field.attribute}
                            label={t(field.label)}
                            currentValues={currentValues}
                            disabled={formDisabled}
                            onFieldChange={setFieldValue}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                )}

                <Box mb={3} mr={4}>
                  <Divider />

                  <Typography variant="h4" sx={{ mt: 2, mb: 1 }}>
                    {t("rolesSetup:system_setup")}
                  </Typography>

                  <Grid container direction="column">
                    {[
                      { attribute: "canEditMasterData", label: "rolesSetup:add_edit_master_data" },
                      { attribute: "canConfigureMetrics", label: "rolesSetup:configure_metrics" },
                      { attribute: "canManageUsers", label: "rolesSetup:manage_users" },
                      {
                        attribute: "canEditSystemParams",
                        label: "rolesSetup:edit_system_parameters",
                      },
                      { attribute: "canEditRails", label: "rolesSetup:edit_rail_options" },
                    ]
                      .filter((field) => !(field.attribute === "canEditRails" && !hasRailSystem))
                      .map((field) => (
                        <Grid key={field.attribute} item>
                          <FormCheckbox
                            fieldName={field.attribute}
                            label={t(field.label)}
                            currentValues={currentValues}
                            disabled={formDisabled}
                            onFieldChange={setFieldValue}
                          />
                        </Grid>
                      ))}
                  </Grid>
                </Box>

                {hasRailSystem && (
                  <Box mb={3} mr={4}>
                    <Divider />

                    <Typography variant="h4" sx={{ mt: 2, mb: 1 }}>
                      {t("rolesSetup:production_setup")}
                    </Typography>

                    <Grid container direction="column">
                      {[
                        {
                          attribute: "canEditSortConfigs",
                          label: "rolesSetup:add_edit_delete_sort_configurations",
                        },
                        {
                          attribute: "canOperatePicklists",
                          label: "rolesSetup:production_list_operations",
                        },
                        {
                          attribute: "canEditPicklists",
                          label: "rolesSetup:add_edit_delete_production_lists",
                        },
                        {
                          attribute: "canEditBarcodes",
                          label: "rolesSetup:bag_barcode_maintenance_configuration",
                        },
                        {
                          attribute: "canEditStorageConfigs",
                          label: "rolesSetup:configure_storage",
                        },
                      ].map((field) => (
                        <Grid key={field.attribute} item>
                          <FormCheckbox
                            fieldName={field.attribute}
                            label={t(field.label)}
                            currentValues={currentValues}
                            disabled={formDisabled}
                            onFieldChange={setFieldValue}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                )}

                {hasRailSystem && (
                  <Box mb={3} mr={4}>
                    <Divider />

                    <Typography variant="h4" sx={{ mt: 2, mb: 1 }}>
                      {t("rolesSetup:sort_area")}
                    </Typography>

                    <Grid container direction="column">
                      {[
                        {
                          attribute: "canSendSortStations",
                          label: "rolesSetup:manually_send_stations",
                        },
                        {
                          attribute: "canCustomerChange",
                          label: "rolesSetup:change_sort_area_customers",
                        },
                        {
                          attribute: "canForceCustomerChange",
                          label: "rolesSetup:force_customer_change",
                        },
                      ].map((field) => (
                        <Grid key={field.attribute} item>
                          <FormCheckbox
                            fieldName={field.attribute}
                            label={t(field.label)}
                            currentValues={currentValues}
                            disabled={formDisabled}
                            onFieldChange={setFieldValue}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                )}

                {hasRailSystem && (
                  <Box mb={3} mr={4}>
                    <Divider />

                    <Typography variant="h4" sx={{ mt: 2, mb: 1 }}>
                      {t("rolesSetup:Maintenance")}
                    </Typography>

                    <Grid container direction="column">
                      {[
                        { attribute: "canForceIO", label: "rolesSetup:force_io" },
                        { attribute: "canCalibrate", label: "rolesSetup:calibrate_weight_stations" },
                        { attribute: "canWasherComm", label: "rolesSetup:manually_open_bags" },
                        { attribute: "canUseExternalPage", label: "rolesSetup:use_external_page" },
                        { attribute: "canOperateConveyors", label: "rolesSetup:conveyor_operation" },
                      ].map((field) => (
                        <Grid key={field.attribute} item>
                          <FormCheckbox
                            fieldName={field.attribute}
                            label={t(field.label)}
                            currentValues={currentValues}
                            disabled={formDisabled}
                            onFieldChange={setFieldValue}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                )}

                {hasRailSystem && (
                  <Box mb={3} mr={4}>
                    <Divider />

                    <Typography variant="h4" sx={{ mt: 2, mb: 1 }}>
                      {t("rolesSetup:Alarms")}
                    </Typography>

                    <Grid container direction="column">
                      {[
                        {
                          attribute: "canTroubleshootAlarm",
                          label: "rolesSetup:alarm_troubleshooting",
                        },
                        { attribute: "canResetRail", label: "rolesSetup:manual_resets" },
                      ].map((field) => (
                        <Grid key={field.attribute} item>
                          <FormCheckbox
                            fieldName={field.attribute}
                            label={t(field.label)}
                            currentValues={currentValues}
                            disabled={formDisabled}
                            onFieldChange={setFieldValue}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                )}

                <Box mb={3} mr={4}>
                  <Divider />

                  <Typography variant="h4" sx={{ mt: 2, mb: 1 }}>
                    {t("rolesSetup:Other")}
                  </Typography>

                  <Grid container direction="column">
                    {hasRailSystem && (
                      <Grid item>
                        <FormCheckbox
                          fieldName="canControlInputLifts"
                          label={t("rolesSetup:sling_input_station")}
                          currentValues={currentValues}
                          disabled={formDisabled}
                          onFieldChange={setFieldValue}
                        />
                      </Grid>
                    )}

                    {hasLabor && (
                      <Grid item>
                        <FormCheckbox
                          fieldName="canManage"
                          label={t("rolesSetup:labor_administrator")}
                          currentValues={currentValues}
                          disabled={formDisabled}
                          onFieldChange={setFieldValue}
                        />
                      </Grid>
                    )}

                    <Grid item>
                      <FormTextField
                        fieldName="logoutTime"
                        label={t("rolesSetup:minutes_before_logout")}
                        currentValues={currentValues}
                        errors={errors}
                        disabled={formDisabled}
                        onFieldChange={setFieldValue}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </CardContent>

            <Divider />

            {!adminRole && (
              <Grid item container justifyContent="flex-end" sx={{ padding: 3 }}>
                <SaveButton disabled={formDisabled || !dirtyForm} onClick={handleSubmit}>
                  {t("common:submit")}
                </SaveButton>
              </Grid>
            )}
          </>
        );
      }}
    </FormEditor>
  );
};

const RoleEdit = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const params = useParams() || {};
  const isNew = params.id === "new";
  const role = useRole(params.id);
  const currentRole = useCurrentRole();

  let heading = currentRole.canManageUsers ? "edit_role" : "view_role";
  if (isNew) {
    heading = "add_role";
  }

  return (
    <Paper square={true} elevation={0} sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box padding={3} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h1">{t(`rolesSetup:${heading}`)}</Typography>
        <RolesListButton onClick={() => history.push(SETUP_ROLES)} />
      </Box>

      <Divider />

      {role ? (
        <SetupForm role={role} />
      ) : (
        <Backdrop open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Paper>
  );
};

export default RoleEdit;
