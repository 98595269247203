import React, { useState, useEffect } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import WarningIcon from "@mui/icons-material/WarningSharp";
import { makeStyles } from "@mui/styles";
import { Box, Divider, Grid, Paper, TextField, Typography } from "@mui/material";
import { useCurrentRole } from "../../selectors/useRole";
import { useSlingInputStation } from "../../selectors/slingInputStations";
import { useRail } from "../../selectors/rails";
import { getRailVisitsCountForRail, getFirstRVBatchId } from "../../selectors/railVisits";
import { useBarcodeFromId } from "../../selectors/barcodes";
import { useBatch } from "../../selectors/batches";
import { useCyclesPerMaintenance, useDaysPerMaintenance } from "../../selectors/uiSettings";
import { railInputEnabled, railOutputEnabled } from "../../api/RailApi";
import { updateNotes } from "../../api/BarcodeApi";
import { assignBarcodeToSis } from "../../api/SlingInputStationApi";
import { IndicatorStatusTypes } from "../../features/panels/enum/IndicatorStatusTypes";
import SlingNumberCard from "../SlingBar/SlingNumberCard";
import CircleIndicator from "../CircleIndicator";
import ConciseSaveSlingButton from "./ConciseSaveSlingButton";
import ConciseClearSlingButton from "./ConciseClearSlingButton";
import ConciseAddSlingButton from "./ConciseAddSlingButton";
import ConciseDeleteSlingButton from "./ConciseDeleteSlingButton";
import ConciseManSendButton from "./ConciseManSendButton";
import BagStatusIndicator from "./BagStatusIndicator";

const styles = makeStyles((theme) => ({
  warningIcon: {
    color: theme.palette.error.light,
    marginRight: theme.spacing(1),
    fontSize: 160,
  },
}));

const Heading = (props) => {
  const { rail, sisId, barcodeId, slingLoaded, generatedBarcode, originalBarcode, notes, onSaveSling, onClearChanges } =
    props;
  const { id, name, inputEnabled, outputEnabled } = rail;
  const { t } = useTranslation();

  const handleToggleInbound = () => railInputEnabled({ id, inputEnabled });
  const handleToggleOutbound = () => railOutputEnabled({ id, outputEnabled });
  return (
    <>
      <Typography variant="h1" gutterBottom>
        {name}
      </Typography>

      <Grid container alignItems="flex-end" justifyContent="space-between" spacing={1}>
        <div>
          <CircleIndicator
            label={t("common:in")}
            status={inputEnabled ? IndicatorStatusTypes.ON : IndicatorStatusTypes.OFF}
            onClick={handleToggleInbound}
          />
          <CircleIndicator
            label={t("common:out")}
            status={outputEnabled ? IndicatorStatusTypes.ON : IndicatorStatusTypes.OFF}
            onClick={handleToggleOutbound}
          />
        </div>

        <Box display="flex">
          <ActionButtons
            sisId={sisId}
            barcodeId={barcodeId}
            plcState={rail.plcState}
            slingLoaded={slingLoaded}
            generatedBarcode={generatedBarcode}
            originalBarcode={originalBarcode}
            notes={notes}
            onSaveSling={onSaveSling}
            onClearChanges={onClearChanges}
          />
        </Box>
      </Grid>
    </>
  );
};

const StatusIndicator = (props) => {
  const { slingLoaded, manualMaintenanceDue, scheduledMaintenanceDue, overMaintTimeLimit, overMaintCyclesLimit } =
    props;

  const classes = styles();
  const { t } = useTranslation();

  return (
    <Box display="flex" justifyContent="center" alignItems="center" sx={{ mb: 2 }}>
      {!slingLoaded ? (
        <BagStatusIndicator status="missing" />
      ) : (
        (manualMaintenanceDue || scheduledMaintenanceDue || overMaintTimeLimit || overMaintCyclesLimit) && (
          <>
            <WarningIcon className={classes.warningIcon} />
            <Typography color="error" variant="h1">
              {manualMaintenanceDue
                ? t("slingInputStation:maintenance_requested")
                : t("slingInputStation:scheduled_maintenance")}
            </Typography>
          </>
        )
      )}
    </Box>
  );
};

const BatchDetails = (props) => {
  const { generatedBarcode, barcodeId, rail, notes, slingLoaded, onBarcodeIdChange, onNotesChange } = props;
  const { lastMaintainedAt, cyclesSinceMaintenance, scheduledMaintenanceDue, manualMaintenanceDue } = generatedBarcode;

  const classes = styles();
  const { t } = useTranslation();
  const { canEditSlings } = useCurrentRole();

  const maintDaysLimit = useDaysPerMaintenance();
  const daysSinceLastMaint = moment.duration(moment().diff(moment(lastMaintainedAt))).asDays();
  const overMaintTimeLimit = daysSinceLastMaint > maintDaysLimit;

  const cyclesPerMaint = useCyclesPerMaintenance();
  const overMaintCyclesLimit = cyclesSinceMaintenance > cyclesPerMaint;

  return (
    <Box display="flex" flexDirection="column">
      <StatusIndicator
        slingLoaded={slingLoaded}
        manualMaintenanceDue={manualMaintenanceDue}
        scheduledMaintenanceDue={scheduledMaintenanceDue}
        overMaintTimeLimit={overMaintTimeLimit}
        overMaintCyclesLimit={overMaintCyclesLimit}
      />

      <Grid container justifyContent="center">
        <Grid item container flexDirection="column" alignItems="center" sx={{ width: "fit-content" }}>
          <Box display="flex" sx={{ mb: 2 }}>
            <Box sx={{ maxWidth: 250, pr: 2 }}>
              {generatedBarcode?.id && (
                <Typography variant="h5">{`${t("slingInputStation:barcode_id")} ${generatedBarcode.id}`}</Typography>
              )}

              <SlingNumberCard
                barcodeId={barcodeId}
                systemId={rail.systemId}
                size="large"
                onChange={onBarcodeIdChange}
              />
            </Box>

            {generatedBarcode.id && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="h5" className={classes.heading}>
                  {t("slingInputStation:last_maintenance_date")}
                </Typography>
                <Typography variant="h6" color={overMaintTimeLimit ? "error" : "initial"}>
                  {lastMaintainedAt
                    ? moment(lastMaintainedAt).format("LLLL")
                    : t("slingInputStation:no_date_available")}
                </Typography>

                <Typography variant="h5" className={classes.heading}>
                  {t("slingInputStation:cycles_since_last_maintenance")}
                </Typography>
                <Typography variant="h6" color={overMaintCyclesLimit ? "error" : "initial"}>
                  {`${cyclesSinceMaintenance}`}
                </Typography>
              </Box>
            )}
          </Box>

          {generatedBarcode.id && (
            <Box sx={{ width: "100%" }}>
              <Typography variant="h5" sx={{ mt: 1 }}>
                {`${t("common:notes")}:`}
              </Typography>

              <TextField
                sx={{ width: "100%", maxWidth: 500 }}
                margin="none"
                variant="outlined"
                multiline={true}
                rows="12"
                value={notes || ""}
                disabled={!canEditSlings}
                onChange={(event) => onNotesChange(event.target.value)}
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

const ActionButtons = (props) => {
  const {
    sisId,
    barcodeId,
    plcState,
    onClearChanges,
    slingLoaded,
    onSaveSling,
    generatedBarcode,
    originalBarcode,
    firstBatch,
    notes,
  } = props;

  const { t } = useTranslation();
  const { canEditSlings } = useCurrentRole();

  const slingSaveable = barcodeId !== (originalBarcode.id || "") || barcodeId !== (firstBatch?.barcodeId || "");
  const notesSaveable = (originalBarcode.notes || "") !== notes;
  const unsavedChanges = slingSaveable || notesSaveable;

  return (
    <>
      <ConciseManSendButton
        sisId={sisId}
        size="xLarge"
        externalDisabledReasons={[
          unsavedChanges && t("slingInputStation:unsaved_changes"),
          plcState !== "in_service_position" && t("slingInputStation:plc_not_in_service_pos"),
        ]}
      />

      <ConciseClearSlingButton
        id="maintenance-lift-clear-button"
        size="xLarge"
        disabled={!canEditSlings || !unsavedChanges}
        onClick={onClearChanges}
      />

      {slingLoaded && (
        <ConciseSaveSlingButton
          id="maintenance-lift-save-button"
          size="xLarge"
          disabled={!(canEditSlings && unsavedChanges)}
          onClick={onSaveSling}
        />
      )}

      {slingLoaded && <ConciseDeleteSlingButton sisId={sisId} size="xLarge" />}

      {!slingLoaded && (
        <ConciseAddSlingButton sisId={sisId} barcodeId={generatedBarcode?.id} barcodeNotes={notes} size="xLarge" />
      )}
    </>
  );
};

const SupertrackMaintLiftSIS = ({ sisId }) => {
  const [barcodeId, setBarcodeId] = useState("");
  const [notes, setNotes] = useState("");

  const sis = useSlingInputStation(sisId);
  const rail = useRail(sis.railId);

  const firstRVBatchId = useSelector((state) => getFirstRVBatchId(sis.railId, state));
  const firstBatch = useBatch(firstRVBatchId);
  useEffect(() => {
    if (firstBatch.id && firstBatch.barcodeId !== sis.barcode1Id) {
      assignBarcodeToSis({ sisId, barcodeId: firstBatch.barcodeId });
    }
  }, [firstBatch.barcodeId]);

  const originalBarcode = useBarcodeFromId(sis.barcode1Id);
  useEffect(() => {
    setBarcodeId(firstBatch.barcodeId || "");
    setNotes(originalBarcode.notes);
  }, [firstBatch.barcodeId]);

  useEffect(() => {
    setBarcodeId(originalBarcode?.id || "");
  }, []);

  const generatedBarcode = useBarcodeFromId(barcodeId, rail.systemId);
  useEffect(() => {
    setNotes(generatedBarcode.notes || "");
  }, [generatedBarcode.id, barcodeId]);

  const handleSaveSling = () => {
    if (barcodeId) {
      updateNotes(barcodeId, notes);
    }
    assignBarcodeToSis({ sisId, barcodeId });
  };

  const handleClearChanges = () => {
    setBarcodeId(originalBarcode.id || "");
    setNotes(originalBarcode.notes || "");
  };

  const railVisitsCount = useSelector((state) => getRailVisitsCountForRail(state, sis.railId));
  const slingLoaded = railVisitsCount >= 1;

  return (
    <Paper square={true} elevation={0} sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
      <Box padding={2}>
        <Heading
          rail={rail}
          sisId={sisId}
          barcodeId={barcodeId}
          slingLoaded={slingLoaded}
          generatedBarcode={generatedBarcode}
          originalBarcode={originalBarcode}
          notes={notes}
          onSaveSling={handleSaveSling}
          onClearChanges={handleClearChanges}
        />
      </Box>

      <Divider sx={{ borderColor: "grey.300" }} />

      <Box display="flex" justifyContent="center" padding={2}>
        <BatchDetails
          generatedBarcode={generatedBarcode}
          firstBatch={firstBatch}
          slingLoaded={slingLoaded}
          barcodeId={barcodeId}
          rail={rail}
          notes={notes}
          onBarcodeIdChange={setBarcodeId}
          onNotesChange={setNotes}
        />
      </Box>
    </Paper>
  );
};

export default SupertrackMaintLiftSIS;
