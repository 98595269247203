import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import resources from "./locales";

// init i18next
// for all options read: https://www.i18next.com/overview/configuration-options
i18n.use(initReactI18next).init({
  resources,
  lng: "en_US",
  fallbackLng: "en_US",
  fallbackNS: "common",
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});

export default i18n;
