import React from "react";
import { Box, Divider, Fade, IconButton, Paper, Popper, TextField } from "@mui/material";
import { StaticDateRangePicker } from "@mui/lab";
import DateIcon from "@mui/icons-material/DateRange";
import { RangeInput } from "@mui/lab/DateRangePicker/RangeTypes";
import dayjs, { Dayjs } from "dayjs";
import PresetDateRanges from "./PresetDateRanges";

interface Props {
  dateRange: RangeInput<Dayjs>,
  onDateRangeChange: (dateRange: RangeInput<Dayjs>) => void;
};

const DateRangeSelector = ({ dateRange, onDateRangeChange }: Props) => {
  const startTime = dayjs(dateRange[0]).locale(dayjs.locale());
  const endTime = dayjs(dateRange[1]).locale(dayjs.locale());
  const startHour = startTime.hour();
  const endHour = endTime.hour();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const id = open ? "date-selector" : undefined;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const closePopper = () => setAnchorEl(null);

  const handleOnDatesChange = (dateRange: RangeInput<Dayjs>) => {
    const startTime = dateRange?.[0] as Dayjs;
    const endTime = dateRange?.[1] as Dayjs;
    if (startTime && endTime) {
      onDateRangeChange([startTime.hour(startHour), endTime.hour(endHour)]);
    }
  };

  const handleOnPresetDateRangeSelected = (dateRange: RangeInput<Dayjs>) => {
    closePopper();
    handleOnDatesChange(dateRange);
  };

  return (
    <>
      <TextField
        fullWidth
        aria-readonly
        value={`${startTime.format("L LT")} \u{02192} ${endTime.format("L LT")}`}
        InputProps={{
          endAdornment:
            <>
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton aria-describedby={id} onClick={handleClick}>
                <DateIcon />
              </IconButton>
            </>,
        }}
      />

      {open ? (
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          style={{ zIndex: 1500 }}
          placement="bottom"
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper elevation={5}>
                <PresetDateRanges sx={{ padding: 2 }} onDateRangeChange={handleOnPresetDateRangeSelected} />

                <Divider />

                <Box sx={{ py: 0, px: 2 }}>
                  <StaticDateRangePicker
                    displayStaticWrapperAs="desktop"
                    value={[startTime, endTime]}
                    onChange={handleOnDatesChange}
                    onAccept={closePopper}
                    renderInput={(startProps, endProps) => {
                      return (
                        <>
                          <TextField {...startProps} />
                          <Box sx={{ mx: 1 }}> - </Box>
                          <TextField { ...endProps } />
                        </>
                      )
                    }}
                  />
                </Box>
              </Paper>
            </Fade>
          )}
        </Popper>
      ): undefined}
    </>
  );
};

export default DateRangeSelector;
