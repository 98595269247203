import i18n from "../i18n";

export const RailGroup = {
  INPUT: 1,
  PRESTORAGE: 2,
  STORAGE: 3,
  PREDEST: 4,
  DESTINATION: 5,
  EMPTY: 6,
};

export const RailGroupNames = {
  [RailGroup.INPUT]: "input",
  [RailGroup.PRESTORAGE]: "preStorage",
  [RailGroup.STORAGE]: "storage",
  [RailGroup.PREDEST]: "preDest",
  [RailGroup.DESTINATION]: "destination",
  [RailGroup.EMPTY]: "empty",
};

export const RailGroupNonEmpty = [
  RailGroup.INPUT,
  RailGroup.PRESTORAGE,
  RailGroup.STORAGE,
  RailGroup.PREDEST,
  RailGroup.DESTINATION,
];

export const RailGroupSortArea = [RailGroup.INPUT, RailGroup.PRESTORAGE, RailGroup.EMPTY];

export const RailGroupWashArea = [RailGroup.STORAGE, RailGroup.PREDEST, RailGroup.DESTINATION];
