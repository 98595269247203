// This utility gives us the channel object to bind to the scope of
// of an action file.
import _ from "lodash";
import { Socket } from "phoenix";
import store from "../store";
import * as actions from "../actions/types";
import { singularizeResource } from ".";

let socket = new Socket("/socket");
socket.connect();

export default function configureChannel(channelName, onJoin, onError) {
  let channel = socket.channel(channelName);

  channel
    .join(30000)
    .receive("ok", (messages) => {
      onJoin && store.dispatch(onJoin(messages));
    })

    .receive("error", (reason) =>
      store.dispatch({
        type: "CHANNEL_JOIN_ERROR",
        channel: channelName,
      })
    );
  return channel;
}

export function simpleSubscription(resource) {
  const channel = socket.channel(resource);
  channel
    .join(30000)
    .receive("ok", (messages) => {})
    .receive("error", (reason) => store.dispatch({ type: "ERROR" }));

  channel.on("init", (messages) => {
    store.dispatch({
      type: actions[`INITIALIZE_${resource.toUpperCase()}`],
      [_.camelCase(resource)]: messages.data,
    });
  });

  channel.on("update", (entity) => {
    store.dispatch({
      type: actions[`UPDATE_${singularizeResource(resource).toUpperCase()}`],
      entity: entity,
    });
  });

  channel.on("update_many", (entities) => {
    store.dispatch({
      type: `UPDATE_${resource.toUpperCase()}`,
      entities: entities.data,
    });
  });

  channel.on("delete", (entity) =>
    store.dispatch({
      type: actions[`DELETE_${singularizeResource(resource).toUpperCase()}`],
      id: entity.id,
    })
  );
  return channel;
}

export { socket };
