import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  sortStationButton: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: 200,
    padding: theme.spacing(1),
  },
  sortStationDetails: {
    display: "flex",
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.info["main"]}`,
    marginBottom: theme.spacing(1),
  },
}));

const SortStationAssignmentCard = ({ rail, categoryCard, disabled, onClick }) => {
  const classes = useStyles();

  return (
    <Button
      className={classes.sortStationButton}
      color="inherit"
      variant="outlined"
      disabled={disabled}
      onClick={onClick}
    >
      <Grid container direction="column" style={{ width: "inherit" }}>
        <Typography color="inherit" variant="body1" className={classes.sortStationDetails}>
          {rail.name}
        </Typography>

        {categoryCard}
      </Grid>
    </Button>
  );
};

export default SortStationAssignmentCard;
