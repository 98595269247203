import React from "react";
import PropTypes from "prop-types";
import FlagCard from "../FlagCard";

const CategoryCardStandard = ({ name, number, imageUrl, color, highlight, border, disabled, onClick }) => {
  return (
    <FlagCard
      name={name}
      label={number}
      imageUrl={imageUrl}
      color={color}
      handleClick={onClick}
      highlight={highlight}
      disabled={disabled}
      border={border}
    />
  );
};

CategoryCardStandard.propTypes = {
  name: PropTypes.string,
  number: PropTypes.number,
  imageUrl: PropTypes.string,
  color: PropTypes.string,
  highlight: PropTypes.bool,
  border: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

CategoryCardStandard.defaultProps = {
  disabled: false,
  border: true,
  onClick: () => ({}),
};

export default CategoryCardStandard;
