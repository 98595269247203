import React, { useState, SyntheticEvent } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import {
  Autocomplete,
  Box,
  Button,
  Drawer,
  Divider,
  IconButton,
  TextField,
  Typography,
  GlobalStyles,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import CheckIcon from "@mui/icons-material/CheckCircleOutline";
import ClearIcon from "@mui/icons-material/ClearAll";
import CloseIcon from "@mui/icons-material/CloseSharp";
import SaveButton from "../../../components/SaveButton";
import useAllRailNamesWithIds from "../hooks/useAllRailNamesWithIds";
import type { RMEListFilters } from "../dto/RMEListFilters";
import { useGetRailMessageEventsQuery } from "../services/railMessageEvents";
import { Rail } from "../../../dto/eVue/Rail";

type FilterFuncValues = {
  key: keyof RMEListFilters;
  value: RMEListFilters[keyof RMEListFilters];
};

interface Props {
  onApplyFilters: ({ railIds, levels, messageTextIds }: RMEListFilters) => void;
};

interface FiltersDrawerProps {
  levelOptions?: string[];
  messageTextIdOptions?: string[];
  open: boolean;
  filters: RMEListFilters;
  onSetFilter: ({ key, value }: FilterFuncValues) => void;
  onApply: VoidFunction;
  onClose: VoidFunction;
  onClear: VoidFunction;
};

const initialFilters: RMEListFilters = { railIds: [], levels: [], messageTextIds: [] };

const sortIds = (railNames: { id: number, name: string }[]) => railNames.slice()?.sort((a,b) => {
  if (a.id === b.id) return 0;
  return a.id > b.id ? 1 : -1;
});

const buildRailNamesFromIds = (railIds: number[], allRails: Partial<Rail>[]) => {
  const railNames = railIds.length > 0 ? railIds.map((rId) => {
    const found = allRails.find((obj) => obj.id === rId)
    return found ? `${found.id} - ${found.name}` : undefined;
  }).filter((rName) => rName && rName?.length > 0) as string[]: [];

  return railNames;
};

const FiltersDrawer = (props: FiltersDrawerProps) => {
  const {
    open,
    levelOptions,
    messageTextIdOptions,
    filters,
    onSetFilter,
    onApply,
    onClose,
    onClear,
  } = props;

  const { t } = useTranslation();
  const railNamesWithIds = useAllRailNamesWithIds();
  const selectedRailNames = buildRailNamesFromIds(filters.railIds, railNamesWithIds);
  const railNameOptions = sortIds(railNamesWithIds)?.map((r) => `${r.id} - ${r.name}`);
  const uniqueRNameOptions = [...new Set(railNameOptions)];

  const mtIdOptions = messageTextIdOptions ?
    messageTextIdOptions.map((mtId) => ({ key: mtId, label: t(`railMessages:${mtId}`)})) :
    [];

  const handleOnSelectRailName = (e: SyntheticEvent<Element, Event>, newInputValues?: string[] | undefined) => {
    if (!newInputValues) return null;
    const railIds = newInputValues.map((value) => parseInt(value.split(" - ")[0]))
    return onSetFilter({ key: "railIds", value: railIds })
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box display="flex" flexDirection="column" sx={{ minWidth: "20vw" }}>
        <Box sx={{ position: "relative" }}>
          <Typography variant="h2" sx={{ my: 2, mx: 3 }}>{t("common:filter")}</Typography>

          <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Divider />

        <Box sx={{ my: 2, mx: 3 }}>
          <Autocomplete
            multiple={true}
            disablePortal
            renderInput={(params) => <TextField {...params} label={t("common:rail")} />}
            value={selectedRailNames}
            options={uniqueRNameOptions}
            onChange={handleOnSelectRailName}
          />
        </Box>

        <Box sx={{ my: 2, mx: 3 }}>
          <Autocomplete
            multiple={true}
            disablePortal
            renderInput={(params) => <TextField {...params} label={t("railMessageEventsMetrics:level")} />}
            value={filters.levels}
            options={levelOptions || []}
            onChange={(event, newInputValues) => {
              if (!newInputValues) return null;
              onSetFilter({ key: "levels", value: newInputValues })
            }}
          />
        </Box>

        <Box sx={{ my: 2, mx: 3 }}>
          <Autocomplete
            multiple={true}
            disablePortal
            renderInput={(params) => <TextField {...params} label={t("railMessageEventsMetrics:message")} />}
            getOptionLabel={(option) => option.label}
            value={mtIdOptions.filter((mtIdOpt) => filters.messageTextIds.includes(mtIdOpt.key))}
            options={mtIdOptions}
            onChange={(event, newInputValues) => {
              if (!newInputValues) return null;
              onSetFilter({ key: "messageTextIds", value: newInputValues.map((value) => value.key) })
            }}
          />
        </Box>

        <Box display="flex" justifyContent="flex-end" mx={3} my={2}>
          <Button variant="outlined" autoFocus onClick={onClear} startIcon={<ClearIcon />} sx={{ mr: 2 }}>
            {t("common:clear")}
          </Button>

          <SaveButton sx={{ width: "fit-content" }} startIcon={<CheckIcon />} onClick={onApply}>
            {t("common:save")}
          </SaveButton>
        </Box>
      </Box>
    </Drawer>
  );
};

const FilterListButton = ({ onApplyFilters }: Props ) => {
  const [open, setOpen] = useState(false);

  const { data } = useGetRailMessageEventsQuery({});
  const levelOptions = data?.meta?.levelOptions || [];
  const messageTextIdOptions = data?.meta?.messageTextIds || [];

  const [filters, setFilters] = useState(initialFilters);
  const anyFiltersSet = filters.railIds.length > 0 || filters.levels.length > 0 || filters.messageTextIds.length > 0;

  const handleSetFilter = ({ key, value }: FilterFuncValues) => setFilters((storedFilters) => ({
    ...storedFilters,
    [key]: value,
  }));

  const handleOnApplyFilters = () => {
    onApplyFilters(filters);
    setOpen(false);
  };

  const handleOnClearFilters = () => {
    setFilters(initialFilters)
    onApplyFilters(initialFilters);
    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={() => setOpen(true)}>
        <FilterListIcon sx={{ color: anyFiltersSet ? "info.light" : "inherit" }} />
      </IconButton>

      <GlobalStyles styles={{ body: { "& .MuiModal-root": { zIndex: 1600 } }}} />

      {open && (
        <FiltersDrawer
          open={open}
          filters={filters}
          levelOptions={levelOptions}
          messageTextIdOptions={messageTextIdOptions}
          onApply={handleOnApplyFilters}
          onClose={() => setOpen(false)}
          onClear={handleOnClearFilters}
          onSetFilter={handleSetFilter}
        />
      )}
    </>
  );
};

export default FilterListButton;
