import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectConfig } from "../../redux/storageConfigEditor";
import { parseLabel } from "../../utils";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import FlagCard from "../FlagCard";
import SelectionDialog from "../SelectionDialog";

const StorageConfigSelector = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const { editorName, selectedConfig, selectedSystem, configs } = useSelector(
    (state) => state.storageConfigEdit,
    _.isEqual
  );
  const { t } = useTranslation();

  const handleSelect = (configId) => () => {
    setDialogOpen(false);
    dispatch(selectConfig(configId));
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleOpen = () => {
    setDialogOpen(true);
  };

  return (
    <>
      <FlagCard
        handleClick={handleOpen}
        name={editorName || t("storageAssignments:select_a_config")}
        label={parseLabel(editorName || "S C")}
      />
      <SelectionDialog
        open={dialogOpen}
        handleClose={handleClose}
        subtitle={t("storageAssignments:select_one_for_editing")}
        title={t("storageAssignments:storage_configurations")}
      >
        {Object.values(configs)
          .filter((x) => x.systemId === selectedSystem.id)
          .map((storageConfig) => {
            return (
              <FlagCard
                key={storageConfig.id}
                name={storageConfig.name}
                label={parseLabel(storageConfig.name)}
                handleClick={handleSelect(storageConfig.id)}
                highlight={storageConfig.id === selectedConfig.id}
              />
            );
          })}
      </SelectionDialog>
    </>
  );
};

export default StorageConfigSelector;
