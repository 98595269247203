import React from "react";
import { Select, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCurrentRole } from "../../selectors/useRole";
import { useTechnicianRoleId, useAdminRoleId } from "../../selectors/roles";
import FormFieldLabel from "../SetupPages/FormFieldLabel";

const FormRoleSelector = ({ currentValues, roleList, disabled, onFieldChange }) => {
  const { t } = useTranslation();
  const currentRole = useCurrentRole();
  const currentRolePermitted = currentRole?.canManage;

  const technicianRoleId = useTechnicianRoleId();
  const editedUserIsTechnician = currentValues["roleId"] === technicianRoleId;

  const adminRoleId = useAdminRoleId();
  const editedUserIsAdmin = currentValues["roleId"] === adminRoleId;

  let filteredRoles = roleList;
  if (!editedUserIsTechnician) {
    filteredRoles = roleList.filter((role) => role[0] !== technicianRoleId);
  }

  return (
    <>
      <FormFieldLabel label={t("usersSetup:role")} />

      <Select
        variant="outlined"
        value={currentValues["roleId"] || ""}
        disabled={disabled || editedUserIsTechnician || (editedUserIsAdmin && !currentRolePermitted)}
        onChange={(e) => onFieldChange("roleId", e.target.value)}
      >
        <MenuItem value="">{t("common:none")}</MenuItem>
        {filteredRoles.map((role) => (
          <MenuItem key={role[0]} value={role[0]} disabled={role[0] === adminRoleId && !currentRolePermitted}>
            {role[1]}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default FormRoleSelector;
