import _ from "lodash";
import store from "../store";
import { showAlertError, showAlertSuccess } from "../actions/alertActions";
import i18n from "../i18n";
import Client from "./Client.js";

export const sbRequestTransfer = (staticBagId) => {
  Client.post(`control_api/v1/static_bags/${staticBagId}/request_transfer`)
    .then((response) => store.dispatch(showAlertSuccess(i18n.t("common:success"))))
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const sbRequestOpen = (staticBagId) => {
  Client.post(`control_api/v1/static_bags/${staticBagId}/request_static_open`)
    .then((response) => store.dispatch(showAlertSuccess(i18n.t("common:success"))))
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const sbRequestClose = (staticBagId) => {
  Client.post(`control_api/v1/static_bags/${staticBagId}/request_static_close`)
    .then((response) => store.dispatch(showAlertSuccess(i18n.t("common:success"))))
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};
