import _ from "lodash";
import store from "../store";
import { showRailSystemControls, showAbout } from "../actions/localActions";
import {
  CUSTOMER_CHANGES,
  CUSTOM_VIEW,
  DISCHARGER,
  METRICS_MESSAGES_LOG,
  SETUP_CATEGORIES,
  SETUP_CATEGORY_GROUPS,
  SETUP_CUSTOMERS,
  SETUP_LABOR_TASKS,
  SETUP_NETWORK_DEVICES,
  SETUP_PRODUCTION_POINTS,
  SETUP_ROLES,
  SETUP_BAGS,
  SETUP_RAILS,
  SETUP_SYSTEM,
  SETUP_USERS,
  SLING_INPUT_STATION,
  SLX_WEBPAGE,
  SORT_ASSIGNMENTS,
  STORAGE_MANAGER,
  STATIC_BAG,
  TECHNICIAN_PORTAL,
} from "../constants/Paths";
import { SystemRoutingTypes } from "../dto/eVue/System";
import { getHasRailSystem, getRailById, getDischargers } from "./rails";
import { getCustChangeStations } from "./custChangeStations";
import { getSlingInputStationsIds, getSlingInputStationRailId } from "./slingInputStations";
import { getCustomerBagSystem, getSoiledSystem, getCleanSystem } from "./systems";
import { getSortStationsCount } from "./sortStations";
import { getCurrentRole } from "./useRole";
import {
  getEvuePro,
  getEvueLabor,
  getLaborButtons,
  getSLXEnabled,
  getSLXUiEnabled,
  getJobNumber,
  getShowCategoryGroups,
  getCustomUiMenuButtons,
} from "./uiSettings";
import { getStaticBags } from "./staticBags";
import { getConveyorsCount } from "./conveyors";
import { getLoadCellsCount } from "./loadCells";
import { getScannersCount } from "./scanners";
import { isTechnicianLoggedIn } from "./roles";
import { getIOPointsCount } from "./ioPoints";

// https://material.io/resources/color/#!/?view.left=0&view.right=0
// Used to calculate proper seconary color shade
const red = "#f44336";
const darkRed = "#aa2e25";
const blue = "#03a9f4";
const darkBlue = "#0276aa";
const pink = "#e91e63";
const darkPink = "#a31545";
const green = "#4caf50";
const darkGreen = "#357a38";
const purple = "#673ab7";
const darkPurple = "#482880";
const orange = "#ffb74d";
const darkOrange = "#c88719";
const sienna = "#ca6839";
const darkSienna = "#9c5635";

// Future Colors
// const orchid = "#c377e0";
// const darkOrchid = "#89609e";
// const violet = "#f472d0";
// const darkViolet = "#c759a9";

export const generateControlMenu = () => {
  const soiledSystem = getSoiledSystem();
  const cleanSystem = getCleanSystem();
  const customerBagSystem = getCustomerBagSystem();
  const eVueLabor = getEvueLabor();
  const jobNumber = getJobNumber();
  const groups = [];

  if (soiledSystem?.id && controlSoiledGroup().buttons.length) {
    groups.push(controlSoiledGroup());
  }

  if (cleanSystem?.id && controlCleanGroup().buttons.length) {
    groups.push(controlCleanGroup());
  }

  if (customerBagSystem?.id && controlCustomBagGroup().buttons.length) {
    // Surrey Alsco 2490 has a CB system but has no need for the config buttons
    if (jobNumber !== "2490") {
      groups.push(controlCustomBagGroup());
    }
  }

  groups.push(controlDevicesGroup());
  groups.push(controlMetricsGroup());
  groups.push(controlSetupGroup());

  if (eVueLabor) {
    groups.push(laborMenuGroup());
  }

  const groupsWithCustomButtons = appendCustomUiMenuButtons(groups);

  return groupsWithCustomButtons;
};

const controlSoiledGroup = () => {
  const soiledSystem = getSoiledSystem();
  const custChangeStations = Object.values(getCustChangeStations()).filter((x) => x.systemId === soiledSystem?.id);
  const custChangeSolo = custChangeStations.length <= 1;
  const staticBags = Object.values(getStaticBags());
  const dischargers = getDischargers() || [];
  const jobNumber = getJobNumber();
  const buttons = [];

  // Wulf Kiel has a custom sorting system
  if (getSortStationsCount(soiledSystem?.id) && jobNumber != "8245") {
    buttons.push({
      labelKey: "sort_assignments",
      icon: "AccountTree",
      linkTo: `${SORT_ASSIGNMENTS}`,
    });
  }

  if (soiledSystem.storageRailIds?.length) {
    if (soiledSystem.routing === SystemRoutingTypes.DYNAMIC) {
      buttons.push({
        labelKey: "storage_manager",
        icon: "Input",
        linkTo: `${STORAGE_MANAGER}/${soiledSystem?.id}`,
      });
    } else {
      buttons.push({
        labelKey: "storage_assignments",
        icon: "Input",
        linkTo: "/storage_assignments/soiled",
      });
    }
  }

  if (soiledSystem.storageDestRailIds?.length) {
    buttons.push({
      labelKey: "production_lists",
      icon: "FormatListNumbered",
      linkTo: "/production_lists",
    });
  }

  if (jobNumber != "8245") {
    custChangeStations.forEach((ccs) =>
      buttons.push({
        labelKey: custChangeSolo ? "customer_change" : "customer_change_num",
        labelParam: custChangeSolo ? {} : { param: ccs.id },
        icon: "Sync",
        linkTo: `${CUSTOMER_CHANGES}/${ccs.id}`,
      })
    );
  }

  let slingInputStationsIds = getSlingInputStationsIds();

  if (jobNumber === "1838") {
    slingInputStationsIds = slingInputStationsIds.slice()?.filter((id) => ![1, 2].includes(parseInt(id)));
    // The generic variant can be accessed by removing the layout flag from the URL
    // 1838 Cintas St Cloud Management wants to enforce using the weighing workflow.
    // buttons.push({
    //   label: "Input Lift Keypad",
    //   icon: "SwapHoriz",
    //   linkTo: `${SLING_INPUT_STATION}/1,2`,
    // });

    buttons.push({
      label: "Input Lift",
      icon: "SwapHoriz",
      linkTo: `${SLING_INPUT_STATION}/1,2/1838custom`,
    });
  }

  if (jobNumber === "1574") {
    slingInputStationsIds = slingInputStationsIds.slice()?.filter((id) => ![1, 2, 3, 4].includes(parseInt(id)));
    buttons.push(
      {
        label: "LD1",
        icon: "SwapHoriz",
        linkTo: `${SLING_INPUT_STATION}/1,2`,
      },
      {
        label: "LD2",
        icon: "SwapHoriz",
        linkTo: `${SLING_INPUT_STATION}/3,4`,
      }
    );
  }

  slingInputStationsIds?.length > 0 &&
    slingInputStationsIds.forEach((id) => {
      const railId = getSlingInputStationRailId(id);
      const rail = getRailById(railId) || {};

      const slingInputStation = {
        label: rail.name,
        icon: "Sync",
        linkTo: `${SLING_INPUT_STATION}/${id}`,
      };

      if (rail.systemId === soiledSystem.id) buttons.push(slingInputStation);
    });

  staticBags.forEach((sb) => {
    const rail = getRailById(sb.dischargerRailId);
    if (rail.systemId === soiledSystem.id) {
      buttons.push({
        label: rail.label,
        icon: "PlayForWork",
        linkTo: `${STATIC_BAG}/${sb.id}`,
      });
    }
  });

  dischargers.forEach((dr) => {
    const rail = getRailById(dr.id);
    if (rail.systemId === soiledSystem.id) {
      buttons.push({
        label: rail.label,
        icon: "PlayForWork",
        linkTo: `${DISCHARGER}/${dr.id}`,
      });
    }
  });

  return {
    labelKey: "soiled",
    groupColor: red,
    buttonColor: darkRed,
    buttons: buttons,
  };
};

const controlCleanGroup = () => {
  const cleanSystem = getCleanSystem();
  const staticBags = Object.values(getStaticBags());
  const custChangeStations = Object.values(getCustChangeStations()).filter((x) => x.systemId === cleanSystem?.id);
  const custChangeSolo = custChangeStations.length <= 1;
  const dischargers = getDischargers() || [];
  const buttons = [];

  if (getSortStationsCount(cleanSystem?.id)) {
    buttons.push({
      labelKey: "sort_assignments",
      icon: "AccountTree",
      linkTo: `${SORT_ASSIGNMENTS}`,
    });
  }

  custChangeStations.forEach((ccs) =>
    buttons.push({
      labelKey: custChangeSolo ? "customer_change" : "customer_change_num",
      labelParam: custChangeSolo ? {} : { param: ccs.id },
      icon: "Sync",
      linkTo: `${CUSTOMER_CHANGES}/${ccs.id}`,
    })
  );

  if (cleanSystem.storageRailIds.length) {
    if (cleanSystem.routing === SystemRoutingTypes.DYNAMIC) {
      buttons.push({
        labelKey: "storage_manager",
        icon: "Input",
        linkTo: `${STORAGE_MANAGER}/${cleanSystem?.id}`,
      });
    } else {
      buttons.push({
        labelKey: "storage_assignments",
        icon: "Input",
        linkTo: "/storage_assignments/clean",
      });
    }
  }

  if (cleanSystem.storageDestRailIds?.length) {
    buttons.push({
      labelKey: "production_lists",
      icon: "FormatListNumbered",
      linkTo: "/production_lists",
    });
  }

  const slingInputStationsIds = getSlingInputStationsIds();
  slingInputStationsIds.forEach((id) => {
    const railId = getSlingInputStationRailId(id);
    const rail = getRailById(railId) || {};

    const slingInputStation = {
      label: rail.name,
      icon: "Sync",
      linkTo: `${SLING_INPUT_STATION}/${id}`,
    };

    if (rail.systemId === cleanSystem.id) buttons.push(slingInputStation);
  });

  staticBags.forEach((sb) => {
    const rail = getRailById(sb.dischargerRailId);
    if (rail.systemId === cleanSystem.id) {
      buttons.push({
        labelKey: rail.label,
        icon: "PlayForWork",
        linkTo: `${STATIC_BAG}/${sb.id}`,
      });
    }
  });

  dischargers.forEach((dr) => {
    const rail = getRailById(dr.id);
    if (rail.systemId === cleanSystem.id) {
      buttons.push({
        label: rail.label,
        icon: "PlayForWork",
        linkTo: `${DISCHARGER}/${dr.id}`,
      });
    }
  });

  return {
    labelKey: "clean",
    groupColor: blue,
    buttonColor: darkBlue,
    buttons: buttons,
  };
};

const controlCustomBagGroup = () => {
  const customerBagSystem = getCustomerBagSystem();
  const staticBags = Object.values(getStaticBags());
  const custChangeStations = Object.values(getCustChangeStations()).filter((x) => x.systemId === customerBagSystem?.id);
  const custChangeSolo = custChangeStations.length <= 1;
  const buttons = [];

  if (getSortStationsCount(customerBagSystem?.id)) {
    buttons.push({
      labelKey: "sort_assignments",
      icon: "AccountTree",
      linkTo: `${SORT_ASSIGNMENTS}`,
    });
  }

  custChangeStations.forEach((ccs) =>
    buttons.push({
      labelKey: custChangeSolo ? "customer_change" : "customer_change_num",
      labelParam: custChangeSolo ? {} : { param: ccs.id },
      icon: "Sync",
      linkTo: `${CUSTOMER_CHANGES}/${ccs.id}`,
    })
  );

  if (customerBagSystem.storageRailIds?.length) {
    if (customerBagSystem.routing === SystemRoutingTypes.DYNAMIC) {
      buttons.push({
        labelKey: "storage_manager",
        icon: "Input",
        linkTo: `${STORAGE_MANAGER}/${customerBagSystem?.id}`,
      });
    } else {
      buttons.push({
        labelKey: "storage_assignments",
        icon: "Input",
        linkTo: "/storage_assignments/customer_bag",
      });
    }
  }

  if (customerBagSystem.storageDestRailIds?.length) {
    buttons.push({
      labelKey: "production_lists",
      icon: "FormatListNumbered",
      linkTo: "/production_lists",
    });
  }

  const slingInputStationsIds = getSlingInputStationsIds();
  slingInputStationsIds.forEach((id) => {
    const railId = getSlingInputStationRailId(id);
    const rail = getRailById(railId) || {};

    const slingInputStation = {
      label: rail.name,
      icon: "Sync",
      linkTo: `${SLING_INPUT_STATION}/${id}`,
    };

    if (rail.systemId === customerBagSystem.id) buttons.push(slingInputStation);
  });

  staticBags.forEach((sb) => {
    const rail = getRailById(sb.dischargerRailId);
    if (rail.systemId === customerBagSystem.id) {
      buttons.push({
        labelKey: rail.label,
        icon: "PlayForWork",
        linkTo: `${STATIC_BAG}/${sb.id}`,
      });
    }
  });

  return {
    labelKey: customerBagSystem.name.toLowerCase(),
    groupColor: sienna,
    buttonColor: darkSienna,
    buttons: buttons,
  };
};

const controlDevicesGroup = () => {
  const hasConveyors = getConveyorsCount();
  const hasLoadCells = getLoadCellsCount();
  const hasIOPoints = getIOPointsCount();
  const hasScanners = getScannersCount();
  const hasRailSystem = getHasRailSystem();
  const buttons = [];

  buttons.push({
    labelKey: "refresh",
    icon: "Autorenew",
    linkTo: () => {
      window.location.reload();
    },
  });

  if (hasRailSystem) {
    buttons.push({
      labelKey: "rail_system",
      icon: "SwapCalls",
      linkTo: () => store.dispatch(showRailSystemControls()),
    });

    buttons.push({
      labelKey: "rails",
      icon: "CallSplit",
      // Only comment one of two lines below. First is new ui, second is legacy
      linkTo: SETUP_RAILS,
      // linkTo: "/evue_webpage/setup/rails",
    });
  }

  if (hasScanners) {
    buttons.push({
      labelKey: "bags",
      icon: "BlurLinear",
      linkTo: SETUP_BAGS,
    });
  }

  if (hasIOPoints) {
    buttons.push({
      labelKey: "io",
      icon: "BlurLinear",
      linkTo: "/panels",
    });
  }

  if (hasLoadCells) {
    buttons.push({
      labelKey: "load_cells",
      icon: "SaveAlt",
      linkTo: "/load_cells",
    });
  }

  if (hasConveyors) {
    buttons.push({
      labelKey: "conveyors",
      icon: "CalendarViewDay",
      linkTo: "/conveyors",
    });
  }

  buttons.push({
    labelKey: "network_devices",
    icon: "Devices",
    linkTo: SETUP_NETWORK_DEVICES,
  });

  return {
    labelKey: "devices",
    groupColor: pink,
    buttonColor: darkPink,
    buttons: buttons,
  };
};

const controlMetricsGroup = () => {
  const eVuePro = getEvuePro();
  const eVueLabor = getEvueLabor();
  const buttons = [
    {
      labelKey: "production_points",
      icon: "DoubleArrow",
      linkTo: "/evue_webpage/metrics/production_points",
    },
    {
      labelKey: "categories",
      icon: "LocalOffer",
      linkTo: "/evue_webpage/metrics/sort_classifications",
    },
    {
      labelKey: "customers",
      icon: "Business",
      linkTo: "/evue_webpage/metrics/customers",
    },
    {
      labelKey: "maintenance",
      icon: "Build",
      linkTo: "/evue_webpage/metrics/maintenance",
    },
    {
      labelKey: "messages_log",
      icon: "SpeakerNotes",
      linkTo: METRICS_MESSAGES_LOG,
    },
  ];

  if (eVuePro) {
    buttons.unshift({
      labelKey: "dashboard",
      icon: "Dashboard",
      linkTo: "/evue_webpage/metrics/dashboard",
    });

    if (eVueLabor) {
      buttons.splice(-1, 0, {
        labelKey: "users",
        icon: "Person",
        linkTo: "/evue_webpage/metrics/users",
      });
    }
  }

  const slxEnabled = getSLXEnabled();
  const slxUiEnabled = getSLXUiEnabled();
  if (slxEnabled && slxUiEnabled) {
    buttons.push(
      {
        labelKey: "Areas", // TODO translation once better name chose
        icon: "Info",
        linkTo: "/slx/areas",
      }
      // {
      //   labelKey: "Machines",
      //   icon: "ListAlt",
      //   linkTo: `${SLX_WEBPAGE}/monitoring/#/machines`,
      // }
    );
  }

  buttons.push({
    labelKey: "about",
    icon: "Info",
    linkTo: () => store.dispatch(showAbout()),
  });

  return {
    labelKey: "metrics",
    groupColor: green,
    buttonColor: darkGreen,
    buttons: buttons,
  };
};

const controlSetupGroup = () => {
  const eVueLabor = getEvueLabor();
  const buttons = [
    {
      labelKey: "categories",
      icon: "Category",
      linkTo: SETUP_CATEGORIES,
    },
    {
      labelKey: "customers",
      icon: "Apartment",
      linkTo: SETUP_CUSTOMERS,
    },
    {
      labelKey: "production_points",
      icon: "PlayForWork",
      linkTo: SETUP_PRODUCTION_POINTS,
    },
    {
      labelKey: "users",
      icon: "Person",
      linkTo: SETUP_USERS,
    },
    {
      labelKey: "roles",
      icon: "SupervisorAccount",
      linkTo: SETUP_ROLES,
    },
  ];

  if (eVueLabor) {
    buttons.push({
      labelKey: "labor_tasks",
      icon: "GroupWork",
      linkTo: SETUP_LABOR_TASKS,
    });
  }

  const showCategoryGroups = getShowCategoryGroups();
  if (showCategoryGroups) {
    buttons.push({
      labelKey: "category_groups",
      icon: "DeveloperBoard",
      linkTo: SETUP_CATEGORY_GROUPS,
    });
  }

  const slxEnabled = getSLXEnabled();
  const currentRole = getCurrentRole();
  if (slxEnabled && currentRole.canEditMasterData) {
    buttons.push({
      labelKey: "laundry_management",
      icon: "LocalLaundryService",
      linkTo: `${SLX_WEBPAGE}/laundry-management`,
    });
  }

  const technicianLoggedIn = isTechnicianLoggedIn();
  if (technicianLoggedIn) {
    buttons.push({
      labelKey: "technician_portal",
      icon: "Engineering",
      linkTo: TECHNICIAN_PORTAL,
    });

    buttons.push({
      labelKey: "system",
      icon: "Settings",
      linkTo: SETUP_SYSTEM,
    });
  }

  return {
    labelKey: "setup",
    groupColor: purple,
    buttonColor: darkPurple,
    buttons: buttons,
  };
};

const laborMenuGroup = () => {
  const laborButtons = getLaborButtons();
  const buttons = [
    {
      labelKey: "login",
      icon: "Category",
      linkTo: "/evue_webpage/dashboards/production_points/generic_login_dashboard",
    },
  ];

  laborButtons.forEach((button) =>
    buttons.push({
      label: button.name,
      linkTo: button.url,
      icon: "GroupAdd",
    })
  );

  return {
    labelKey: "labor",
    groupColor: orange,
    buttonColor: darkOrange,
    buttons: buttons,
  };
};

const extractSISIds = (url = "") => {
  const sisWithIdsPattern = new RegExp(`${SLING_INPUT_STATION}/(.*)`);
  const foundMatch = (url.match(sisWithIdsPattern) || [])[0];

  if (foundMatch) {
    const sisIds = foundMatch
      .split("/")[1]
      .split(",")
      .map((id) => id.trim());
    return sisIds || [];
  }
  return [];
};

const removeDuplicateSISButtons = (buttons, customButtons) => {
  const sisIdsFromCustomButtons = customButtons.reduce((acc, button) => {
    const sisIds = extractSISIds(button.url);
    if (sisIds.length > 0) {
      return acc.concat(sisIds);
    }
    return acc;
  }, []);

  const filteredButtons = buttons.filter((button) => {
    const sisIds = typeof button.linkTo === "string" ? extractSISIds(button.linkTo) : [];
    if (sisIds.length < 1) return true;

    return sisIds.every((id) => !sisIdsFromCustomButtons.includes(id));
  });

  return filteredButtons || buttons;
};

const generateCustomUIButton = (button) => {
  const sisLink = button.url.includes(SLING_INPUT_STATION);
  let linkTo = "";
  if (button.iframe) {
    linkTo = { pathname: `${CUSTOM_VIEW}/${_.snakeCase(button.label)}`, state: { externalUrl: button.url } };
  } else if (sisLink) {
    linkTo = button.url;
  } else {
    linkTo = () => (window.location.href = button.url);
  }

  return {
    label: button.label,
    icon: button.icon || "Link",
    url: button.url,
    linkTo,
    iframe: button.iframe,
    menuGroup: button.menuGroup,
  };
};

const appendCustomUiMenuButtons = (groups = []) => {
  if (groups.length < 1) return groups;

  const customUiMenuButtons = getCustomUiMenuButtons() || [];
  const customMenuButtonsByGroup = customUiMenuButtons.reduce((acc, b) => {
    const button = generateCustomUIButton(b);

    const existingMenuGroup = acc[button.menuGroup];
    if (existingMenuGroup?.length > 0) {
      acc[button.menuGroup].push(button);
    } else {
      acc[button.menuGroup] = [button];
    }
    return acc;
  }, {});

  return groups.map((group) => {
    const customMenuButtons = customMenuButtonsByGroup[group.labelKey] || [];
    const groupButtonsWODuplicates = removeDuplicateSISButtons(group.buttons, customMenuButtons);
    return {
      ...group,
      buttons: groupButtonsWODuplicates.concat(customMenuButtons),
    };
  });
};
