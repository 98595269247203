import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Checkbox, FormControlLabel } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { updateCallEmpty } from "../../api/SlingInputStationApi";
import { useCurrentRole } from "../../selectors/useRole";
import { useCallEmpty, useShowCallEmptyButton } from "../../selectors/slingInputStations";
import { ReactComponent as EmptyBagIcon } from "../../assets/icons/bag-empty.svg";

const useStyles = makeStyles((theme) => ({
  main: {
    padding: 6,
    "& #bag-icon": {
      fill: theme.palette.grey[700],
    },
  },
  xLargeCheckbox: {
    "& #bag-icon": {
      width: 85,
      height: 85,
    },
  },
  checked: {
    "& #bag-icon": {
      fill: theme.palette.info["light"],
    },
  },
}));

const CallEmptyButton = ({ sisId, size }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const callEmpty = useCallEmpty(sisId);
  const { canControlInputLifts } = useCurrentRole();

  const showButton = useShowCallEmptyButton(sisId);
  if (!showButton) return null;
  return (
    <FormControlLabel
      control={
        <Checkbox
          disabled={!canControlInputLifts}
          className={clsx({
            [classes.main]: true,
            [classes.checked]: callEmpty,
            [classes.xLargeCheckbox]: size === "xLarge",
          })}
          checked={callEmpty}
          icon={<EmptyBagIcon id="bag-icon" />}
          checkedIcon={<EmptyBagIcon id="bag-icon" />}
          size={size === "xLarge" ? "medium" : size}
          onChange={() => updateCallEmpty({ sisId, callEmpty: !callEmpty })}
        />
      }
      labelPlacement="top"
      label={t("slingInputStation:call_empty")}
    />
  );
};

CallEmptyButton.propTypes = {
  sisId: PropTypes.number,
  size: PropTypes.oneOf(["small", "medium", "xLarge"]),
};

export default CallEmptyButton;
