import React from "react";
import { Box, Chip } from "@mui/material";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import CheckIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/WarningSharp";

import { useWeightUnit } from "../../../selectors/uiSettings";
import { useCategory } from "../../../selectors/categories";

const WeightThresholds = ({ categoryId, currentWeight = 0 }) => {
  const weightUnit = useWeightUnit();
  const category = useCategory(categoryId);
  const { t } = useTranslation();
  let currentThreshold;
  if (currentWeight >= category?.maxWeight) {
    currentThreshold = "max_met";
  } else if (currentWeight >= category?.targetWeight) {
    currentThreshold = "target_met";
  } else if (currentWeight >= category?.minWeight) {
    currentThreshold = "min_met";
  }

  if (!category) return null;
  return (
    <Box display="flex" justifyContent="center">
      <WeightChip
        type="min"
        sx={{ mr: 1 }}
        label={`${t("slingInputStation:min_weight")}: ${category.minWeight || 0} ${weightUnit}`}
        highlight={currentThreshold === "min_met"}
      />
      <WeightChip
        type="target"
        sx={{ mr: 1 }}
        label={`${t("slingInputStation:target_weight")}: ${category.targetWeight || 0} ${weightUnit}`}
        highlight={currentThreshold === "target_met"}
      />
      <WeightChip
        type="max"
        label={`${t("slingInputStation:max_weight")}: ${category.maxWeight || 0} ${weightUnit}`}
        highlight={currentThreshold === "max_met"}
      />
    </Box>
  );
};

const WeightChip = ({ type, sx, label, highlight }) => {
  const colors = getColorsForType({ type, highlight });
  return (
    <Chip
      color={colors.bg}
      variant={highlight ? "filled" : "outlined"}
      label={label}
      sx={sx}
      icon={
        highlight && type === "max" ? (
          <WarningIcon />
        ) : highlight ? (
          <CheckIcon />
        ) : (
          <Box
            sx={{
              ml: 1,
              borderRadius: "50%",
              height: 20,
              width: 20,
              backgroundColor: colors.icon,
            }}
          />
        )
      }
    />
  );
};

const getColorsForType = ({ type, highlight }) => {
  if (type === "min") {
    return {
      bg: highlight ? "warning" : "default",
      icon: highlight ? "warning.dark" : "warning.dark",
    };
  }
  if (type === "target") {
    return {
      bg: highlight ? "success" : "default",
      icon: highlight ? "success.dark" : "success.main",
    };
  }
  if (type === "max") {
    return {
      bg: highlight ? "error" : "default",
      icon: highlight ? "error.dark" : "error.main",
    };
  }
};

export default WeightThresholds;
