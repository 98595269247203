import React from "react";
import type { StorageGroup } from "../dto/StorageGroup";
import { useGetStorageGroupQuery } from "../services/storageGroups";

interface Props {
  id: number;
  children: (storageGroup: StorageGroup) => JSX.Element;
};

const StorageGroupDataWrapper = ({ id, children }: Props) => {
  const storageGroupQuery = useGetStorageGroupQuery(id);
  return storageGroupQuery.data ? children(storageGroupQuery.data) : null;
};

export default StorageGroupDataWrapper;
