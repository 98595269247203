import * as actions from "../actions/types";

const initialState = {
  railId: null,
  railShowId: false,
  selectedIOPoints: null,
  selectedMachine: null,
  selectedMachineVisit: null,
  selectedRailport: null,
  selectedRailVisit: null,
  showLogin: false,
  showRailBar: false,
  showRailSystemControls: false,
  showRailSystemSettings: false,
  batchTopText: null,
  batchBottomText: null,
  batchTopColor: null,
  batchBottomColor: null,
  showPLCTroubleshoot: false,
  showServerTroubleshoot: false,
  showBatchHighlighter: false,
  batchCategoryHighlight: null,
  batchCustomerHighlight: null,
  batchBarcodeHighlight: null,
  showAbout: false,
  defaults: {},
  recentLogins: [],
};

export default function localReducer(state = initialState, action) {
  switch (action.type) {
    case actions.INITIALIZE_LOCAL:
      return state;

    case actions.INITIALIZE_UI_SETTINGS:
      return {
        ...state,
        batchTopText: action.data.batchTopText,
        batchBottomText: action.data.batchBottomText,
        batchTopColor: action.data.batchTopColor,
        batchBottomColor: action.data.batchBottomColor,
        recentLogins: action.data.recentLogins || [],
      };

    case actions.UPDATE_UI_SETTINGS:
      return {
        ...state,
        defaults: {
          batchTopText: action.data.batchTopText,
          batchBottomText: action.data.batchBottomText,
          batchTopColor: action.data.batchTopColor,
          batchBottomColor: action.data.batchBottomColor,
        },
      };

    case actions.ADD_BATCH:
      return {
        ...state,
        railId: action.railId,
        showRailBar: false,
      };

    case actions.DESELECT_BATCH:
      return {
        ...state,
        railId: null,
        selectedMachineVisit: null,
        selectedRailVisit: null,
        showRailBar: false,
      };

    case actions.DESELECT_IO_POINTS:
      return {
        ...state,
        selectedIOPoints: null,
      };

    case actions.DESELECT_MACHINE:
      return {
        ...state,
        selectedMachine: null,
      };

    case actions.DESELECT_MACHINE_VISIT:
      return {
        ...state,
        selectedMachineVisit: null,
      };

    case actions.DESELECT_RAIL:
      return {
        ...state,
        railId: null,
        selectedMachineVisit: null,
        selectedRailVisit: null,
        showRailBar: false,
      };

    case actions.DESELECT_RAILPORT:
      return {
        ...state,
        selectedRailport: null,
      };

    case actions.SHOW_LOGIN:
      return {
        ...state,
        showLogin: true,
      };

    case actions.HIDE_LOGIN:
      return {
        ...state,
        showLogin: false,
      };

    case actions.TOGGLE_LOGIN:
      return {
        ...state,
        showLogin: !state.showLogin,
      };

    case actions.SHOW_RAIL_SYSTEM_CONTROLS:
      return {
        ...state,
        showRailSystemControls: true,
      };

    case actions.HIDE_RAIL_SYSTEM_CONTROLS:
      return {
        ...state,
        showRailSystemControls: false,
      };

    case actions.SELECT_BATCH:
      return {
        ...state,
        railId: null,
        selectedIOPoints: null,
        selectedMachine: null,
        selectedMachineVisit: null,
        selectedRailVisit: {
          railId: action.railId,
          position: action.position,
          batchId: action.batchId,
          startTime: action.startTime,
        },
        showRailBar: false,
        selectedRailport: null,
      };

    case actions.SELECT_IO_POINTS:
      return {
        ...state,
        railId: null,
        selectedIOPoints: action.ioPointIds,
        selectedMachine: null,
        selectedMachineVisit: null,
        selectedRailVisit: null,
        showRailBar: false,
        selectedRailport: null,
      };

    case actions.SELECT_MACHINE:
      return {
        ...state,
        railId: null,
        selectedIOPoints: null,
        selectedMachine: action.machineId,
        selectedMachineVisit: null,
        selectedRailVisit: null,
        showRailBar: false,
        selectedRailport: null,
      };

    case actions.SELECT_MACHINE_VISIT:
      return {
        ...state,
        railId: null,
        selectedIOPoints: null,
        selectedMachine: null,
        selectedMachineVisit: {
          machineId: action.machineId,
          position: action.position,
          batchId: action.batchId,
        },
        selectedRailVisit: null,
        showRailBar: false,
        selectedRailport: null,
      };

    case actions.SELECT_RAIL:
      return {
        ...state,
        railId: action.railId,
        selectedIOPoints: null,
        selectedMachine: null,
        selectedMachineVisit: null,
        selectedRailVisit: null,
        showRailBar: true,
        selectedRailport: null,
      };

    case actions.SELECT_RAILPORT:
      return {
        ...state,
        railId: null,
        selectedIOPoints: null,
        selectedMachine: null,
        selectedMachineVisit: null,
        selectedRailVisit: null,
        showRailBar: false,
        selectedRailport: action.deviceId,
      };

    case actions.SHOW_RAIL_SETTINGS:
      return { ...state, showRailSystemSettings: true };

    case actions.HIDE_RAIL_SETTINGS:
      return { ...state, showRailSystemSettings: false };

    case actions.TOGGLE_RAIL_SETTINGS:
      return { ...state, showRailSystemSettings: !state.showRailSystemSettings };

    case actions.SET_TOP_TEXT_TYPE:
      return { ...state, batchTopText: action.data };

    case actions.SET_BOTTOM_TEXT_TYPE:
      return { ...state, batchBottomText: action.data };

    case actions.SET_TOP_COLOR_TYPE:
      return { ...state, batchTopColor: action.data };

    case actions.SET_BOTTOM_COLOR_TYPE:
      return { ...state, batchBottomColor: action.data };

    case actions.LOGIN_SUCCESS:
      let updatedRecentLogins = state.recentLogins.slice();
      if (action.data.userId) {
        updatedRecentLogins = updatedRecentLogins.filter((userId) => userId !== action.data.userId);
        updatedRecentLogins.unshift(action.data.userId);
      }
      if (updatedRecentLogins.length > 5) {
        updatedRecentLogins.pop();
      }
      return {
        ...state,
        defaults: {
          batchTopColor: state.batchTopColor,
          batchBottomColor: state.batchBottomColor,
          batchTopText: state.batchTopText,
          batchBottomText: state.batchBottomText,
        },
        batchTopColor: action.data.batchTopColor || state.batchTopColor,
        batchBottomColor: action.data.batchBottomColor || state.batchBottomColor,
        batchTopText: action.data.batchTopText || state.batchTopText,
        batchBottomText: action.data.batchBottomText || state.batchBottomText,
        recentLogins: updatedRecentLogins,
      };

    case actions.LOGOUT_SUCCESS:
      return {
        ...state,
        batchTopColor: state.defaults.batchTopColor,
        batchBottomColor: state.defaults.batchBottomColor,
        batchTopText: state.defaults.batchTopText,
        batchBottomText: state.defaults.batchBottomText,
        defaults: {},
      };

    case actions.SET_RAIL_SHOW_ID:
      return { ...state, railShowId: action.data };

    case actions.SHOW_PLC_TROUBLESHOOT:
      return { ...state, showPLCTroubleshoot: true };

    case actions.HIDE_PLC_TROUBLESHOOT:
      return { ...state, showPLCTroubleshoot: false };

    case actions.SHOW_SERVER_TROUBLESHOOT:
      return { ...state, showServerTroubleshoot: true };

    case actions.HIDE_SERVER_TROUBLESHOOT:
      return { ...state, showServerTroubleshoot: false };

    case actions.SHOW_BATCH_HIGHLIGHTER:
      return { ...state, showBatchHighlighter: true };

    case actions.HIDE_BATCH_HIGHLIGHTER:
      return {
        ...state,
        showBatchHighlighter: false,
        batchCategoryHighlight: null,
        batchCustomerHighlight: null,
        batchBarcodeHighlight: null,
      };

    case actions.TOGGLE_BATCH_HIGHLIGHTER:
      if (state.showBatchHighlighter === true)
        return {
          ...state,
          showBatchHighlighter: false,
          batchCategoryHighlight: null,
          batchCustomerHighlight: null,
          batchBarcodeHighlight: null,
        };
      else {
        return { ...state, showBatchHighlighter: true };
      }

    case actions.SET_CATEGORY_HIGHLIGHT:
      return { ...state, batchCategoryHighlight: action.data };

    case actions.SET_CUSTOMER_HIGHLIGHT:
      return { ...state, batchCustomerHighlight: action.data };

    case actions.SET_BARCODE_HIGHLIGHT:
      return { ...state, batchBarcodeHighlight: action.data };

    case actions.SHOW_ABOUT:
      return { ...state, showAbout: true };

    case actions.HIDE_ABOUT:
      return { ...state, showAbout: false };

    default:
      return state;
  }
}
