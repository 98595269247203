import React, { useState } from "react";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { addCategoryGroup } from "../../api/CategoryGroupApi";
import { CategoryGroup } from "../../definitions/CategoryGroup";
import TextEditorDialog from "../TextEditorDialog";

interface Props {
  size: "small" | "medium" | "large",
  disabled: boolean,
}

const AddCategoryGroupButton = ({ size, disabled }: Props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <>
      <Fab size={size} color="primary" disabled={disabled} onClick={() => setOpen(true)}>
        <AddIcon />
      </Fab>

      {setOpen && (
        <TextEditorDialog
          open={open}
          fullWidth={true}
          maxWidth="md"
          message={t("categoryGroupsSetup:enter_name_for_cat_group")}
          initialValue={t("categoryGroupsSetup:new_category_group")}
          onClose={() => setOpen(false)}
          onSubmit={(name: string) => {
            addCategoryGroup({
              categoryGroup: { name } as CategoryGroup,
              successCB: () => setOpen(false),
              errorCB: () => setOpen(false),
            });
          }}
        />
      )}
    </>
  );
};

export default AddCategoryGroupButton;
