import React from "react";
import { Box, Chip } from "@mui/material";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import { useWeightUnit } from "../../selectors/uiSettings";
import { useCategory } from "../../selectors/categories";

const WeightThresholds = ({ categoryId }) => {
  const weightUnit = useWeightUnit();
  const category = useCategory(categoryId);
  const { t } = useTranslation();

  if (!category) return null;
  return (
    <Box display="flex" justifyContent="center">
      <WeightChip
        type="min"
        sx={{ mr: 1 }}
        label={`${t("slingInputStation:min_weight")}: ${category.minWeight || 0} ${weightUnit}`}
      />
      <WeightChip
        type="target"
        sx={{ mr: 1 }}
        label={`${t("slingInputStation:target_weight")}: ${category.targetWeight || 0} ${weightUnit}`}
      />
      <WeightChip type="max" label={`${t("slingInputStation:max_weight")}: ${category.maxWeight || 0} ${weightUnit}`} />
    </Box>
  );
};

const WeightChip = ({ type, sx, label }) => {
  return (
    <Chip
      icon={
        <Box
          sx={(theme) => ({
            ml: 1,
            borderRadius: "50%",
            height: 20,
            width: 20,
            backgroundColor: getColorForType({ theme, type }),
          })}
        />
      }
      variant="outlined"
      label={label}
      sx={sx}
    />
  );
};

const getColorForType = ({ theme, type }) => {
  if (type === "min") {
    return theme.palette.warning.dark;
  }
  if (type === "target") {
    return theme.palette.success.main;
  }
  if (type === "max") {
    return theme.palette.error.main;
  }
};

export default WeightThresholds;
