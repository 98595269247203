import React from "react";
import _ from "lodash";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { sortingComparer } from "../../../../utils/sort";
import { useCustomers } from "../../../../selectors/customers";
import { Customer } from "../../../../dto/eVue/Customer";
import type { StorageRuleset } from "../../dto/StorageRuleset";
import type { FilterOptions } from "../../dto/FilterOptions";
import type { Order } from "../../dto/Order";
import { updateSelectionsWithPick } from "../../utils";
import CustomerSelections from "./CustomerSelections";

interface Props {
  selections: number[];
  hiddenSelections?: number[];
  disabled: boolean;
  filterOptions: FilterOptions;
  onSelect: (selections: number[]) => void;
};

const filterCustomersByTerm = (props: { customers?: Customer[], searchTerm?: string }) => {
  const { customers = [], searchTerm = "" } = props;
  const term = _.toLower(searchTerm);

  if (term.length < 1) return customers;
  return customers.length > 0 ? customers.filter((customer) => {
    const name = _.toLower(customer.name || "");
    return (term === `${customer.number}`) || name?.includes(term);
  }) : [];
};

const sortCustomers = (props: { customers?: Customer[], sortBy: FilterOptions["sortBy"], order: Order }) => {
  const { customers = [], sortBy, order } = props;

  if (customers?.length < 1) return [];
  return customers.sort((a, b) => {
    let valueA = a[sortBy];
    let valueB = b[sortBy];

    return sortingComparer(valueA, valueB, order);
  });
};

const CustomerSelectionView = ({ selections, hiddenSelections = [], disabled, filterOptions, onSelect }: Props) => {
  const { t } = useTranslation();
  const allCustomers = useCustomers() || [];
  const filteredCustomers =
    filterCustomersByTerm({
      customers: allCustomers,
      searchTerm: filterOptions.searchTerm || "",
    }).reduce((acc, customer) => {
      if (!hiddenSelections.includes(customer.id)) {
        acc.push(customer);
      };
      return acc;
    }, [] as Customer[]);
  const sortedCustomers = sortCustomers({
    customers: filteredCustomers,
    sortBy: filterOptions.sortBy,
    order: filterOptions.order,
  });

  const handleSelect = (id: number) => !disabled && onSelect(updateSelectionsWithPick({ selections, id }));

  return allCustomers?.length > 0 ? (
    <CustomerSelections
      allCustomers={sortedCustomers}
      selections={selections}
      disabled={disabled}
      onSelect={handleSelect}
    />
  ) : (
    <Typography>{t("common:none")}</Typography>
  );
};

export default CustomerSelectionView;
