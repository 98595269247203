import _ from "lodash";
import store from "../store";
import { showAlertError, showAlertInfo, showAlertSuccess } from "../actions/alertActions";
import Client from "./Client.js";
import i18n from "../i18n";

export const railInputEnabled = (rail) => {
  Client.put(`control_api/v1/rails/${rail.id}`, { inputEnabled: !rail.inputEnabled })
    .then((response) => {
      console.info("Rail set_input_enabled ok");
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const railOutputEnabled = (rail) => {
  const offNote = !rail.outputEnabled ? "" : rail.offNote;

  Client.put(`control_api/v1/rails/${rail.id}`, { outputEnabled: !rail.outputEnabled, offNote })
    .then((response) => {
      console.info("Rail set_output_enabled ok");
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const railReset = (railId) => {
  store.dispatch(showAlertInfo(i18n.t("railApi:resetting")));
  Client.post(`control_api/v1/rails/${railId}/reset`, {})
    .then((response) => store.dispatch(showAlertSuccess(i18n.t("common:success"))))
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const manualOpen = (railId) => {
  Client.post(`control_api/v1/rails/${railId}/manual_open`)
    .then((response) => {
      console.info("Rail manual_open ok");
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const manualSend = (railId) => {
  Client.post(`control_api/v1/rails/${railId}/manual_send`)
    .then((response) => {
      console.info("Rail manual_send ok");
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const getTroubleshootInfo = ({ railId, successCB }) => {
  Client.get(`control_api/v1/rails/${railId}/troubleshoot_info`)
    .then((response) => successCB(response.data))
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(i18n.t("common:fetch_error", { error: errorMessage })));
    });
};

export const troubleshoot = ({ railId, toRailId, successCB, failureCB }) => {
  Client.post(`control_api/v1/rails/${railId}/troubleshoot`, { toRail: toRailId })
    .then((response) => {
      console.log("Rail troubleshoot ok");
      successCB();
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      failureCB && failureCB();
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const setRailOffNote = ({ railId, offNote }) => {
  Client.put(`control_api/v1/rails/${railId}`, { offNote })
    .then((response) => {
      console.info("Rail set off note ok");
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const resetTripCount = (railId) => {
  store.dispatch(showAlertInfo(i18n.t("railApi:trip_count_resetting")));
  Client.post(`control_api/v1/rails/${railId}/reset_trip_count`, {})
    .then((response) => store.dispatch(showAlertSuccess(i18n.t("common:success"))))
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const updateRail = ({ railId, successCB, failureCB, ...rest }) => {
  Client.put(`control_api/v1/rails/${railId}`, { ...rest })
    .then((response) => {
      if (successCB) {
        successCB();
      }
      return store.dispatch(showAlertSuccess(i18n.t("common:success")));
    })
    .catch((error) => {
      const errorsByKey = _.get(error, "response.data.error", "API Error");
      if (failureCB) {
        failureCB(errorsByKey);
      }
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${JSON.stringify(errorsByKey)}`));
    });
};

export const bringLoadToWasher = ({ railId, successCB, failureCB }) => {
  return Client.get(`control_api/v1/rails/${railId}/bring_load_to_washer`)
    .then((response) => {
      if (successCB) {
        successCB();
      }
      return store.dispatch(showAlertSuccess(i18n.t("common:success")));
    })
    .catch((error) => {
      const errorsByKey = _.get(error, "response.data.error", "API Error");
      if (failureCB) {
        failureCB(errorsByKey);
      }
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${JSON.stringify(errorsByKey)}`));
    });
};
