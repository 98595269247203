import React from "react";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    border: 0,
    display: "block",
    height: "100%",
    width: "100%",
  },
});

type Location = {
  search?: string;
  hash?: string;
  state: {
    externalUrl: string;
  }
};

const CustomView = () => {
  const classes = useStyles();
  const location: Location = useLocation();
  const hostWithPath = location?.state?.externalUrl;

  return <iframe className={classes.root} src={hostWithPath}></iframe>;
};

export default CustomView;
