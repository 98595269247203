import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@mui/styles";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Divider,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import CalendarViewDayIcon from "@mui/icons-material/CalendarViewDay";
import WarningIcon from "@mui/icons-material/Warning";
import { ReactComponent as SendIcon } from "../assets/icons/send.svg";
import { ReactComponent as EmptyIcon } from "../assets/icons/empty.svg";
import { useTranslation } from "react-i18next";
import { useCurrentRole } from "../selectors/useRole";

const useStyles = makeStyles((theme) => ({
  actionRequired: {
    marginRight: theme.spacing(1),
    color: "red",
  },
  avatar: {
    margin: "0 auto",
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  buttonEmptied: {
    backgroundColor: "red",
    color: "white",
  },
  cardHeader: {
    display: "flex",
  },
  cardHeaderIcon: {
    width: "32px",
    height: "auto",
  },
  cardHeaderText: {
    display: "flex",
    flexDirection: "column",
  },
  changeStatus: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "16px 0 8px 0",
  },
  complete: {
    marginRight: theme.spacing(1),
    color: "green",
    maxWidth: "32px",
    maxHeight: "32px",
  },
  custChanging: {
    color: "red",
    animationName: "$blinker",
    animationDuration: "1s",
    animationTimingFunction: "linear",
    animationIterationCount: "infinite",
  },
  icon: {
    width: "18px",
    height: "18px",
  },
  inProgress: {
    marginRight: theme.spacing(1),
    color: "orange",
  },
  list: {
    padding: "4px",
  },
  pending: {
    marginRight: theme.spacing(1),
    color: "yellow",
  },
  "@keyframes blinker": {
    "0%": { opacity: 0 },
    "66%": { opacity: 1 },
    "100%": { opacity: 0 },
  },
}));

const CustChangeSortStationCard = ({
  currentCategory = {},
  nextCategory = {},
  currentCustomer = {},
  nextCustomer = {},
  weight,
  name,
  manualEmpty,
  ackManualEmpty,
  manSend,
  changeStatus,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { canSendSortStations } = useCurrentRole();

  const buildMyIcon = (changeStatus) => {
    if (changeStatus === "COMPLETE") {
      return <BeenhereIcon className={clsx(classes.complete, classes.cardHeaderIcon)} />;
    } else if (changeStatus === "PENDING") {
      return <AvTimerIcon className={clsx(classes.pending, classes.cardHeaderIcon)} />;
    } else if (changeStatus === "IN_PROGRESS") {
      return <CalendarViewDayIcon className={clsx(classes.inProgress, classes.cardHeaderIcon)} />;
    } else if (changeStatus === "ACTION_REQUIRED") {
      return <WarningIcon className={clsx(classes.actionRequired, classes.cardHeaderIcon)} />;
    }
  };

  const Header = ({ title, changeStatus }) => (
    <Box className={classes.cardHeader}>
      <Box>{buildMyIcon(changeStatus)}</Box>
      <Box className={classes.cardHeaderText}>
        {name}
        <Typography variant="overline">{t(`customerChange:change_status_${changeStatus.toLowerCase()}`)}</Typography>
      </Box>
    </Box>
  );

  const Content = withStyles({
    root: {
      position: "relative",
      "&::before": {
        content: '""',
        display: "block",
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        zIndex: 1,
        opacity: "0.4",
        backgroundImage: currentCategory.imageUrl && `url(${currentCategory.imageUrl})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right 8px bottom 8px",
      },
    },
  })(CardContent);

  const labelFor = (thing) => `${thing.number} - ${thing.name}`;
  const custChangeInProgress = changeStatus === "IN_PROGRESS" || changeStatus === "ACTION_REQUIRED";

  return (
    <Card className={classes.card}>
      <CardHeader title={[<Header key="cust-change-station-card-header" title={name} changeStatus={changeStatus} />]} />
      <Divider />

      <Content>
        <List>
          <ListItem className={classes.list}>
            <Typography
              variant="overline"
              className={currentCategory.id !== nextCategory.id ? classes.custChanging : ""}
            >
              {custChangeInProgress && currentCategory.id !== nextCategory.id
                ? `${labelFor(currentCategory)} => ${labelFor(nextCategory)}`
                : labelFor(currentCategory)}
            </Typography>
          </ListItem>
          <ListItem className={classes.list}>
            <Typography variant="overline" className={custChangeInProgress ? classes.custChanging : ""}>
              {custChangeInProgress && currentCustomer.id !== nextCustomer.id
                ? `${labelFor(currentCustomer)} => ${labelFor(nextCustomer)}`
                : labelFor(currentCustomer)}
            </Typography>{" "}
          </ListItem>
          <ListItem className={classes.list}>
            <Typography variant="overline">
              {t("common:weight")}: {weight}
            </Typography>
          </ListItem>
        </List>
      </Content>

      <Divider />
      <CardActions>
        {manualEmpty && (
          <>
            <Button
              size="small"
              variant="contained"
              className={classes.buttonEmptied}
              onClick={ackManualEmpty}
              disabled={!canSendSortStations}
              startIcon={<EmptyIcon className={classes.icon} fill="#ffffff" />}
            >
              {t("customerChange:emptied")}
            </Button>
            <Typography variant="overline">{t("customerChange:or")}</Typography>
          </>
        )}

        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={manSend}
          disabled={!canSendSortStations}
          startIcon={<SendIcon className={classes.icon} fill="#ffffff" />}
        >
          {t("customerChange:send")}
        </Button>
      </CardActions>
    </Card>
  );
};

CustChangeSortStationCard.propTypes = {
  name: PropTypes.string.isRequired,
};

export default CustChangeSortStationCard;
