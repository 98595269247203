import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getFirstRVBatchId } from "../../../selectors/railVisits";
import { useBatch } from "../../../selectors/batches";
import { assignBarcodeToSis } from "../../../api/SlingInputStationApi";

const useAddBatchBarcodeToSIS = (sis) => {
  const firstRVBatchId = useSelector((state) => getFirstRVBatchId(sis.railId, state));
  const firstBatch = useBatch(firstRVBatchId);
  useEffect(() => {
    if (firstBatch?.id && firstBatch?.barcodeId !== sis?.barcode1Id) {
      assignBarcodeToSis({ sisId: sis?.id, barcodeId: firstBatch?.barcodeId });
    }
  }, [firstBatch?.barcodeId]);
};

export default useAddBatchBarcodeToSIS;
