import React from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { lighten } from "@mui/material/styles";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "fit-content",
    border: (props) => {
      if (!props.border) return null;
      const borderHighlight = props.disabled ? lighten(theme.palette.info.light, 0.8) : theme.palette.info.light;
      const borderStandard = props.disabled ? lighten(theme.palette.common.black, 0.8) : theme.palette.common.black;

      return props.highlight ? `3px solid ${borderHighlight}` : `1px solid ${borderStandard}`;
    },
    borderRadius: (props) => props.border && 5,
    padding: (props) => props.border && theme.spacing(1),
    cursor: (props) => !props.disabled && "pointer",
  },
  image: {
    height: 30,
    width: 30,
    backgroundColor: (props) => props.color || theme.palette.grey[100],
    color: (props) => (props.color ? theme.palette.getContrastText(props.color) : theme.palette.common.black),
    borderRadius: 5,
    marginRight: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    width: `calc(100% - ${30 + theme.spacing(1)}px)`,
  },
  disabled: {
    opacity: 0.4,
  },
}));

const ImageSquare = ({ classes, imageUrl, disabled, imageIcon }) => {
  return imageUrl ? (
    <img className={clsx({ [classes.image]: true, [classes.disabled]: disabled })} src={imageUrl} alt="" />
  ) : (
    <div className={classes.image}>{imageIcon}</div>
  );
};

const FlagCardSlim = ({ imageUrl, imageIcon, color, number, name, border = false, disabled, highlight, onClick }) => {
  const classes = useStyles({ border, highlight, disabled, color });
  const handleOnClick = () => {
    if (!disabled && onClick) {
      onClick();
    }
  };

  return (
    <div className={classes.main} onClick={handleOnClick}>
      <ImageSquare classes={classes} imageUrl={imageUrl} disabled={disabled} imageIcon={imageIcon} />

      <div className={clsx({ [classes.text]: true, [classes.disabled]: disabled })}>
        <Typography align="left" color="inherit" variant="body1" sx={{ wordBreak: "break-word" }}>
          {`${number} - ${name}`}
        </Typography>
      </div>
    </div>
  );
};

export default FlagCardSlim;
