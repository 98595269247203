import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useRole } from "../selectors/useRole";
import { useUser } from "../selectors/useUser";
import * as actions from "../actions";
import SaveButton from "./SaveButton";
import PasswordTextField from "./PasswordTextField";

const useStyles = makeStyles((theme) => ({
  content: {
    height: 400,
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    width: 100,
    height: 100,
    objectFit: "cover",
    backgroundColor: theme.palette.grey[200],
    borderRadius: 10,
    marginBottom: theme.spacing(1),
  },
}));

const UserPasswordDialog = ({ selectedUserId, open, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");

  const user = useUser(selectedUserId);
  const role = useRole(user && user.roleId) || {};

  if (!user) return null;
  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handleLogin = (e) => {
    e.preventDefault();
    dispatch(actions.login(user.login, password));
  };

  return (
    <Dialog
      open={open}
      onBackdropClick={() => onClose()}
      maxWidth="md"
      fullWidth={true}
      TransitionProps={{
        onExited: () => setPassword(""),
      }}
    >
      <form className={classes.content} onSubmit={handleLogin}>
        {user.imageUrl ? <img className={classes.image} src={user.imageUrl} /> : <div className={classes.image}></div>}
        <Typography variant="h4">{`${user.firstname} ${user.lastname}`}</Typography>
        <Typography variant="h6" gutterBottom>
          {role.name}
        </Typography>

        <PasswordTextField value={password} onChange={handlePasswordChange} autoFocus={true} />

        <SaveButton onClick={handleLogin}>{t("common:login")}</SaveButton>
      </form>
    </Dialog>
  );
};

UserPasswordDialog.propTypes = {
  selectedUserId: PropTypes.number.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default UserPasswordDialog;
