import _ from "lodash";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { getRails } from "../../../selectors/rails";
import { Rail } from "../../../dto/eVue/Rail";

const useAllRailNamesWithIds = (): { id: number, name: string}[] => useAppSelector((state) => {
  const rails = getRails(state);
  return rails.map((r: Rail) => ({ id: r.id, name: r.name }));
}, _.isEqual);

export default useAllRailNamesWithIds;
