import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import GroupIcon from "@mui/icons-material/Workspaces";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useCurrentRole } from "../../../../selectors/useRole";
import FlagCardSlim from "../../../../components/FlagSelectors/FlagCardSlim";
import type { StorageRuleDTO } from "../../dto/StorageRuleDTO";
import StorageGroupDataWrapper from "../../dataWrappers/StorageGroupDataWrapper";

interface Props {
  rules: StorageRuleDTO[];
  selectedRules?: number[];
  addButton?: JSX.Element;
  onSelectRule?: (id: number) => void;
};

const StorageGroupsRules = ({ rules, selectedRules = [], addButton, onSelectRule }: Props) => {
  const { t } = useTranslation();
  const currentRole = useCurrentRole();

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Typography variant="overline" color="secondary" sx={{ mb: addButton ? 0 : 1 }}>
          {t("storageManager:storage_groups")}
        </Typography>

        {addButton}
      </Box>

      <Grid container spacing={1}>
        {rules?.map((rule) => {
          return rule.storageGroupId ? (
            <Grid item key={`storageGroupIds-${rule.storageGroupId}`}>
              <StorageGroupDataWrapper id={rule.storageGroupId}>
                {(storageGroup) => (
                  <FlagCardSlim
                    imageUrl={""}
                    imageIcon={<GroupIcon />}
                    color={""}
                    number={storageGroup.id}
                    name={storageGroup.name}
                    disabled={!currentRole.canOperatePicklists}
                    border={true}
                    highlight={rule?.id && selectedRules?.includes(rule.id)}
                    onClick={() => onSelectRule && rule?.id && onSelectRule(rule.id)}
                  />
                )}
              </StorageGroupDataWrapper>
            </Grid>
          ) : undefined
        })}
      </Grid>
    </Box>
  )
};

export default StorageGroupsRules;
