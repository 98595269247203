import React from "react";
import { useLiveWeightForRailId } from "../../selectors/rails";
import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useWeightUnit } from "../../selectors/uiSettings";

const styles = makeStyles({
  textField: {
    width: 145,
  },
  input: {
    fontSize: 40,
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none",
    },
  },
});

const LiveWeight = ({ railId, label }) => {
  const classes = styles();
  const { t } = useTranslation();
  const liveWeight = useLiveWeightForRailId(railId);
  const weightUnit = useWeightUnit();
  return (
    <Box display="flex" flexDirection="column">
      <Typography>{label || t("slingInputStation:floor_scale_weight")}</Typography>
      <TextField
        className={classes.textField}
        type="number"
        variant="outlined"
        value={liveWeight}
        size="small"
        InputLabelProps={{ shrink: true }}
        InputProps={{
          classes: { root: classes.input },
          readOnly: true,
          endAdornment: <InputAdornment position="end">{weightUnit}</InputAdornment>,
        }}
      />
    </Box>
  );
};

export default LiveWeight;
