import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Button, FormControlLabel } from "@mui/material";
import { makeStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/EditSharp";
import { editWeightForSis } from "../../api/SlingInputStationApi";
import NumpadField from "../NumpadField";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "50%",
  },
  xLargeButton: {
    padding: theme.spacing(2),
    fontSize: 40,

    "& #start-icon": {
      fontSize: 40,
    },
  },
}));

const WeightInputButton = ({ sisId, weight, position, size = "medium", disabled }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  useEffect(() => {
    if (weight == null) {
      setValue("");
    } else {
      setValue(weight);
    }
  }, [weight]);

  const handleChange = (value) => {
    setValue(value);
    editWeightForSis({ sisId, weight: parseInt(value), position });
  };

  return (
    <NumpadField
      key="sis-weight-input"
      value={value}
      submitValue={handleChange}
      title={t("slingInputStation:enter_weight")}
      disabled={disabled}
    >
      <FormControlLabel
        control={
          <Button
            className={clsx({
              [classes.button]: true,
              [classes.xLargeButton]: size === "xLarge",
            })}
            size={size === "xLarge" ? "large" : size}
            disableElevation={true}
            color="primary"
            variant="contained"
            disabled={disabled}
          >
            <EditIcon id="start-icon" />
          </Button>
        }
        label={t("slingInputStation:enter_weight")}
        labelPlacement="top"
      />
    </NumpadField>
  );
};

WeightInputButton.propTypes = {
  sisId: PropTypes.number,
  weight: PropTypes.number,
  position: PropTypes.number,
  size: PropTypes.oneOf(["small", "medium", "large", "xLarge"]),
  disabled: PropTypes.bool,
};

export default WeightInputButton;
