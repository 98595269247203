import _ from "lodash";
import store from "../store";
import { showAlertError, showAlertSuccess } from "../actions/alertActions";
import i18n from "../i18n";
import Client from "./Client.js";

export const aplEnabled = (apl) => {
  Client.put(`control_api/v1/active_production_lists/${apl.id}`, { enabled: !apl.enabled })
    .then((response) => {
      store.dispatch(showAlertSuccess(i18n.t("common:success")));
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const aplSetCustomer = (apl, customerId) => {
  Client.put(`control_api/v1/active_production_lists/${apl.id}`, { customerId: customerId })
    .then((response) => {
      store.dispatch(showAlertSuccess(i18n.t("common:success")));
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const aplSetList = (apl, productionListId) => {
  Client.put(`control_api/v1/active_production_lists/${apl.id}`, {
    productionListId: productionListId,
  })
    .then((response) => {
      store.dispatch(showAlertSuccess(i18n.t("common:success")));
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const aplUpdate = (id, data) => {
  Client.put(`control_api/v1/active_production_lists/${id}`, data)
    .then((response) => {
      console.info("APL updated");
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(i18n.t("productionList:set_position_error", { error: errorMessage })));
    });
};

export const productionListUpdate = (productionListId, data, successCB) => {
  Client.put(`control_api/v1/production_lists/${productionListId}`, data)
    .then((response) => {
      if (successCB) {
        successCB();
      }
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const productionListCreate = (data, successCB) => {
  Client.post(`control_api/v1/production_lists`, data)
    .then((response) => {
      if (successCB) {
        successCB(response.data.id);
      }
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const productionListDelete = (productionListId, successCB, failureCB) => {
  Client.delete(`control_api/v1/production_lists/${productionListId}`)
    .then((response) => {
      if (successCB) {
        successCB();
      }
    })
    .catch((error) => {
      if (failureCB) {
        failureCB();
      }
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common_error")}: ${errorMessage}`));
    });
};

export const clearTags = (tagRailId) => {
  Client.get("control_api/v1/active_production_lists/clear_all_tags", { tagRailId })
    .then((response) => store.dispatch(showAlertSuccess(i18n.t("common:success"))))
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};
