import React from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import FormNumberField from "../SetupPages/FormNumberField";
import FormPickSizesSelector from "./FormPickSizesSelector";
import StyledGridItem from "./StyledGridItem";

const LoadSizingGroup = (props) => {
  const { currentValues, errors, setupData, disabled, onFieldChange, onArrayFieldChange } = props;
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="h4">{t("categoriesSetup:load_sizing")}</Typography>

      <Grid container>
        <StyledGridItem item>
          <FormNumberField
            fieldName="minWeight"
            label={t("categoriesSetup:min_weight")}
            currentValues={currentValues}
            errors={errors}
            setupData={setupData}
            disabled={disabled}
            onFieldChange={onFieldChange}
          />
        </StyledGridItem>

        <StyledGridItem item>
          <FormNumberField
            fieldName="targetWeight"
            label={t("categoriesSetup:target_weight")}
            currentValues={currentValues}
            errors={errors}
            setupData={setupData}
            disabled={disabled}
            onFieldChange={onFieldChange}
          />
        </StyledGridItem>

        <StyledGridItem item>
          <FormNumberField
            fieldName="maxWeight"
            label={t("categoriesSetup:max_weight")}
            currentValues={currentValues}
            errors={errors}
            setupData={setupData}
            disabled={disabled}
            onFieldChange={onFieldChange}
          />
        </StyledGridItem>
      </Grid>

      <StyledGridItem item sx={{ maxWidth: 408 }}>
        <FormNumberField
          fieldName="loadSize"
          label={t("categoriesSetup:load_size")}
          currentValues={currentValues}
          errors={errors}
          setupData={setupData}
          disabled={disabled}
          helperText={t("categoriesSetup:load_size_helper_text")}
          onFieldChange={onFieldChange}
        />
      </StyledGridItem>

      <FormPickSizesSelector
        currentValues={currentValues}
        setupData={setupData}
        disabled={disabled}
        onArrayFieldChange={onArrayFieldChange}
      />
    </>
  );
};

export default LoadSizingGroup;
