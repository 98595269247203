import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Button, FormControlLabel } from "@mui/material";
import MoveIcon from "@mui/icons-material/MoveUp";
import { getRailVisitsCountForRail } from "../../selectors/railVisits";
import { useCurrentRole } from "../../selectors/useRole";
import { useSlingInputStation } from "../../selectors/slingInputStations";
import { performCustomAction } from "../../api/SlingInputStationApi";

const buttonStyles = (theme, xLarge) => ({
  borderRadius: "50%",
  backgroundColor: theme.palette.secondary["main"],
  color: "white",

  "&:disabled": {
    backgroundColor: theme.palette.grey[200],
  },
  "&:hover:enabled": {
    backgroundColor: theme.palette.secondary["dark"],
  },

  ...(xLarge && {
    padding: 2,
    fontSize: 40,

    "& #start-icon": {
      fontSize: 40,
    },
  }),
});

const Content = ({ sx, sis, size, disabled }) => {
  const { canControlInputLifts } = useCurrentRole();

  return (
    <FormControlLabel
      control={
        <Button
          sx={(theme) => buttonStyles(theme, size === "xLarge")}
          size={size === "xLarge" ? "large" : size}
          disabled={disabled || !canControlInputLifts}
          onClick={() => performCustomAction({ sisId: sis?.id })}
        >
          <MoveIcon id="start-icon" />
        </Button>
      }
      label="Bring to Front"
      labelPlacement="top"
      sx={sx}
    />
  );
};

const ConciseBrintToFrontButton = ({ sisId, ...rest }) => {
  const sis = useSlingInputStation(sisId) || {};
  const railVisitCount = useSelector((state) => getRailVisitsCountForRail(state, sis.railId));

  if (railVisitCount < 1) return null;
  return <Content sis={sis} {...rest} />;
};

ConciseBrintToFrontButton.defaultProps = {
  size: "medium",
};

ConciseBrintToFrontButton.propTypes = {
  sisId: PropTypes.number,
  size: PropTypes.oneOf(["small", "medium", "large", "xLarge"]),
  disabled: PropTypes.bool,
};

export default ConciseBrintToFrontButton;
