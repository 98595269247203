import _ from "lodash";
import { useSelector, shallowEqual } from "react-redux";
import store from "../store";
import { getSystemIdForRail } from "./rails";
import { getStorageRailIdsForSystemId, getPreDestRailIdsForSystemId } from "./systems";
import { getBatchById } from "./batches";

export const getRailVisits = (receivedState) => {
  const state = receivedState || store.getState();
  return state.railVisits || {};
};

export const getRailVisitsForRailId = (railId, receivedState) => {
  const allRailVisits = getRailVisits(receivedState);
  return allRailVisits[railId] || [];
};

export const getRailVisit = (receivedState, railId, position) => {
  return getRailVisitsForRailId(railId, receivedState)[position - 1];
};

export const useIsRVInTransit = (railId, position) =>
  useSelector((state) => !getRailVisit(state, railId, position)?.startTime);

export const useRVBatchIdForRail = (railId, position) =>
  useSelector((state) => {
    return getRailVisit(state, railId, position)?.batchId;
  });

export const getRailVisitsCountForRail = (receivedState, railId) => {
  return getRailVisitsForRailId(railId, receivedState).length;
};
export const useRailVisitsCountForRail = (railId) =>
  useSelector((state) => {
    return getRailVisitsCountForRail(state, railId);
  });

export const useIsSlingLoadedForRail = (railId) =>
  useSelector((state) => {
    const rVCountForRail = getRailVisitsCountForRail(state, railId);
    return rVCountForRail >= 1;
  });

export const getBatchIdsWithActiveRVs = (receivedState) => {
  const railVisits = getRailVisits(receivedState);
  const activeBatchIds = Object.values(railVisits)
    ?.flat()
    ?.map((railVisit) => railVisit.batchId);

  return _.uniq(activeBatchIds);
};

export const getFirstRVBatchId = (railId, receivedState) => {
  const railVisits = getRailVisits(receivedState);
  const visitsForRail = railVisits[railId];
  const firstRV = visitsForRail?.find((visit) => visit.position === 1) || {};
  return firstRV?.batchId;
};
export const useFirstRVBatchId = (railId) =>
  useSelector((state) => {
    return getFirstRVBatchId(railId, state);
  });

export const getBatchIdsForRailIds = (railIds, receivedState) => {
  const allRailVisits = getRailVisits(receivedState);
  return railIds.reduce((acc, id) => {
    const railVisits = allRailVisits[id] || [];
    if (railVisits.length < 1) return acc;
    railVisits.forEach((rv) => rv?.batchId && acc.push(rv.batchId));
    return acc;
  }, []);
};

export const useBatchIdsForRailIds = (railIds) =>
  useSelector((state) => {
    return _.uniq(getBatchIdsForRailIds(railIds, state));
  }, shallowEqual);

export const useCountsForTaggedRVsToRail = (destRailId) =>
  useSelector((state) => {
    const systemId = getSystemIdForRail(destRailId, state);

    let storageCount = 0;
    const storageRailIds = getStorageRailIdsForSystemId(systemId, state);
    storageRailIds.forEach((id) => {
      const rvs = getRailVisitsForRailId(id, state);
      rvs.length > 0 &&
        rvs.forEach((rv) => {
          const { tag } = getBatchById(rv.batchId, state) || {};
          if (!rv.endTime && tag) {
            storageCount = tag === destRailId ? storageCount + 1 : storageCount;
          }
        });
    });

    let predestCount = 0;
    const preDestRailIds = getPreDestRailIdsForSystemId(systemId, state);
    preDestRailIds.forEach((id) => {
      const rvs = getRailVisitsForRailId(id, state);
      rvs.length > 0 &&
        rvs.forEach((rv) => {
          const { tag } = getBatchById(rv.batchId, state) || {};
          if (!rv.endTime && tag) {
            predestCount = tag === destRailId ? predestCount + 1 : predestCount;
          }
        });
    });

    return [storageCount, predestCount];
  }, shallowEqual);

export const useRailVisitsForRail = (railId) => useSelector((state) => state.railVisits[railId] || [], shallowEqual);

export const useRailVisit = ({ railId, position }) =>
  useSelector((state) => {
    const railVisitsForRail = getRailVisitsForRailId(railId, state);
    return railVisitsForRail.length > 0 ? railVisitsForRail.find((rv) => rv.position === position) : {};
  }, shallowEqual);
