import { useSelector } from "react-redux";
export const useSession = () => useSelector((state) => state.session);

export const useCurrentUserId = () => {
  const session = useSession();
  return session.currentUserId;
};

export const getCurrentUserId = (state) => state.session.currentUserId;

export const useBusy = () => {
  const session = useSession();
  return session.busy;
};
