import React from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { Card, Divider, Typography } from "@mui/material";
import { DragDropContext } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/styles";
import { useTranslation } from "react-i18next";
import LocalOfferIcon from "@mui/icons-material/LocalOfferSharp";
import CallSplitIcon from "@mui/icons-material/CallSplitSharp";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import CategoryGroupIcon from "@mui/icons-material/DeveloperBoard";

import Column from "./Column";
import PriorityButton from "./PriorityButton";
import SequentialButton from "./SequentialButton";
import TabContainer from "./TabContainer";
import { RailGroup } from "../../constants/RailGroup";
import { setPicks } from "../../redux/productionListEditor";
import { useCurrentRole } from "../../selectors/useRole";
import { useCategoryGroups } from "../../selectors/categoryGroups";
import { useShowCategoryGroups } from "../../selectors/uiSettings";
import { nameComparer, numberComparer } from "../../utils/sort";

const Actions = styled.div`
  text-align: right;
  & > * {
    margin: 6px;
  }
`;

const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-wrap: nowrap;
  overflow: hidden;
  width: 100%;
`;

const Grow = styled.div`
  flex-grow: 1;
`;

const Left = styled(Card)`
  flex: 1;
  overflow: hidden;
  padding: 16px;
  margin-right: 12px;
`;

const Right = styled(Card)`
  flex: 1;
  overflow: hidden;
  padding: 16px;
  margin-left: 12px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  height: 48px;
`;

const Selections = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const categories = useSelector((state) => state.categories);
  const rails = useSelector((state) => state.rails);
  const productionLists = useSelector((state) => state.productionLists);

  const showCategoryGroups = useShowCategoryGroups();
  const categoryGroups = useCategoryGroups();

  const prodListEdit = useSelector((state) => state.productionListEdit);
  const { id, name, sortBy } = prodListEdit;
  const picks = showCategoryGroups
    ? prodListEdit.picks
    : prodListEdit.picks.slice().filter((pick) => pick.pickType !== "group");
  const { t } = useTranslation();
  const role = useCurrentRole();
  const allowed = role.canEditPicklists;

  const categoryList = Object.values(categories)
    .sort(sortBy === "number" ? numberComparer : nameComparer)
    .map((category) => {
      return {
        id: `category-${category.id}`,
        pickId: category.id,
        pickType: "category",
        content: category.name,
        entity: category,
      };
    });

  const railList = Object.values(rails)
    .filter((rail) => rail.railGroup === RailGroup.STORAGE)
    .map((rail) => {
      return {
        id: `rail-${rail.id}`,
        pickId: rail.id,
        pickType: "rail",
        content: rail.name,
        entity: rail,
      };
    });

  const productionListsList = Object.values(productionLists)
    .filter(
      (list) => list.id !== id && list.hasNested === false && (id ? productionLists?.[id]?.isNested === false : true)
    )
    .map((productionList) => {
      return {
        id: `list-${productionList.id}`,
        pickId: productionList.id,
        pickType: "list",
        content: productionList.name,
        entity: productionList,
      };
    });

  const categoryGroupsList = Object.values(categoryGroups).map((catGroup) => {
    return {
      id: `group-${catGroup.id}`,
      pickId: catGroup.id,
      pickType: "group",
      content: catGroup.name,
      entity: catGroup,
    };
  });

  const onPickRemove = (droppableId, index) => {
    dispatch(setPicks(remove(picks, index)));
  };

  const onUpdateStrategy = (droppableId, index, strategy) => {
    dispatch(setPicks(updateStrategy(picks, index, strategy)));
  };

  /**
   *  Utility function to reorder a list
   */
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  /**
   * Utility function to copy a list
   */
  const copy = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const item = sourceClone[droppableSource.index];

    destClone.splice(droppableDestination.index, 0, { ...item, strategy: item.strategy || "oldest", id: uuidv4() });
    return destClone;
  };

  /**
   * Utility function to move an item in a list
   */
  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  /**
   * Utility function to remove an item in a list
   */
  const remove = (list, startIndex) => {
    const result = Array.from(list);
    result.splice(startIndex, 1);

    return result;
  };

  const updateStrategy = (list, startIndex, strategy) => {
    const result = Array.from(list);
    const updatedPick = {
      ...result[startIndex],
      strategy,
    };
    result.splice(startIndex, 1, updatedPick);
    return result;
  };

  /**
   * Handle drag and drop operation
   */
  const onDragEnd = (result) => {
    const { source, destination } = result;

    // Dropped outside the list - remove from picks
    if (!destination) {
      if (source.droppableId === "picks") {
        dispatch(setPicks(remove(picks, source.index)));
      }
      return;
    }

    switch (source.droppableId) {
      case destination.droppableId:
        dispatch(setPicks(reorder(picks, source.index, destination.index)));
        break;
      case "categories":
        dispatch(setPicks(copy(categoryList, picks, source, destination)));
        break;
      case "lists":
        dispatch(setPicks(copy(productionListsList, picks, source, destination)));
        break;
      case "rails":
        dispatch(setPicks(copy(railList, picks, source, destination)));
        break;
      case "groups":
        dispatch(setPicks(copy(categoryGroupsList, picks, source, destination)));
        break;
      default:
        console.error(`Unknown production list source type: ${source.droppableId}`);
        break;
    }
  };

  return (
    <>
      {name && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Container>
            <Left>
              <Row>
                <Typography variant={"h4"} color="inherit">
                  {t("productionList:selections")}
                </Typography>
                <Grow />
                <Actions>
                  <SequentialButton />
                  <PriorityButton />
                </Actions>
              </Row>

              <Divider
                sx={{
                  opacity: 1,
                  borderColor:
                    theme.palette.mode === "dark" ? theme.palette.dark.light + 15 : theme.palette.primary.light,
                }}
              />

              <TabContainer
                tab1Label={t("productionList:production_list")}
                tab1Icon={<LocalOfferIcon />}
                tab1={
                  <Column
                    droppableId="picks"
                    items={picks}
                    isRemovable={allowed}
                    isDropDisabled={!allowed}
                    isDragDisabled={!allowed}
                    clone={false}
                    showPosition={true}
                    onPickRemove={onPickRemove}
                    onUpdateStrategy={onUpdateStrategy}
                  />
                }
              />
            </Left>

            <Right>
              <Row>
                <Typography variant={"h4"} color="inherit" gutterBottom>
                  {t("productionList:available_selections")}
                </Typography>
              </Row>

              <Divider
                sx={{
                  opacity: 1,
                  borderColor:
                    theme.palette.mode === "dark" ? theme.palette.dark.light + 15 : theme.palette.primary.light,
                }}
              />

              <TabContainer
                tab1Label={t("common:categories")}
                tab1Icon={<LocalOfferIcon sx={{ fontSize: "1.3rem", display: "inline-block" }} />}
                tab1={
                  <Column
                    droppableId="categories"
                    items={categoryList}
                    isRemovable={false}
                    isDropDisabled={true}
                    isDragDisabled={!allowed}
                    clone={true}
                  />
                }
                tab2Label={t("common:rails")}
                tab2Icon={<CallSplitIcon />}
                tab2={
                  <Column
                    droppableId="rails"
                    items={railList}
                    isRemovable={false}
                    isDropDisabled={true}
                    isDragDisabled={!allowed}
                    clone={true}
                  />
                }
                tab3Label={t("productionList:production_lists")}
                tab3Icon={<FormatListNumberedIcon />}
                tab3={
                  <Column
                    droppableId="lists"
                    items={productionListsList}
                    isRemovable={false}
                    isDropDisabled={true}
                    isDragDisabled={!allowed}
                    clone={true}
                    message={t("productionList:nested_list_included")}
                  />
                }
                tab4Label={showCategoryGroups && t("productionList:category_groups")}
                tab4Icon={showCategoryGroups && <CategoryGroupIcon />}
                tab4={
                  showCategoryGroups && (
                    <Column
                      droppableId="groups"
                      items={categoryGroupsList}
                      isRemovable={false}
                      isDropDisabled={true}
                      isDragDisabled={!allowed}
                      clone={true}
                    />
                  )
                }
              />
            </Right>
          </Container>
        </DragDropContext>
      )}
    </>
  );
};

export default Selections;
