import React, { useEffect } from "react";
import * as d3 from "d3";
import { Box, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ReactComponent as ConveyorBucketIcon } from "../../../assets/icons/conveyor-drop-bucket.svg";
import ConveyorIcon from "./ConveyorIcon";

const innerBucketId = "inner-bucket";
const clipPathGroupId = "clip-path-wave-group";
const clipPathId = "clip-path-wave";
const singleWaveWidth = 40;

const getOutterBucket = () => d3.select("#outter-bucket");
const getInnerBucketGroup = () => d3.select("#inner-bucket-group");
const getInnerBucket = () => d3.select(`#${innerBucketId}`);
const getWaveClipWidth = () => (getInnerBucket()?.node()?.getBoundingClientRect()?.width || 0) * 2;
const getAllowedHeight = () => getOutterBucket()?.node()?.getBBox()?.height;

const drawBucketOutlines = () => {
  d3.select("#bucket-wrapper").selectAll("svg").attr("transform", "translate(0, -5)");

  const drawOutterBucket = () => {
    const outterBucket = getOutterBucket();
    const boundingRect = outterBucket.node()?.getBoundingClientRect();
    outterBucket
      .style("stroke-width", 1)
      .attr("transform", `translate(${boundingRect?.width / 2}, ${boundingRect?.height / 2 + 2})`);
  };

  const drawInnerBucket = () => {
    const innerGroup = getInnerBucketGroup().attr("clip-path", `url(#${clipPathGroupId})`);
    const parentBoundRect = getOutterBucket().node()?.getBoundingClientRect();
    const boundingRect = innerGroup.node()?.getBoundingClientRect();
    const widthDiff = parentBoundRect.width - boundingRect.width;
    const xPos = (boundingRect?.width + widthDiff) / 2;
    const heightDiff = parentBoundRect.height - boundingRect.height;
    const yPos = (boundingRect?.height + (heightDiff - 12)) / 2;
    innerGroup.attr("transform", `translate(${xPos}, ${yPos})`);
    innerGroup.selectAll("path").attr("transform", "scale(0.9)");
  };

  drawOutterBucket();
  drawInnerBucket();
};

const generateClipPathScales = (waveHeight) => {
  const waveClipWidth = getWaveClipWidth();
  const waveScaleX = d3
    .scaleLinear()
    .domain([0, 1])
    .range([0, waveClipWidth * 2]);
  const waveScaleY = d3.scaleLinear().domain([0, 1]).range([0, waveHeight]);
  const waveHeightScale = d3.scaleLinear().domain([0, 50, 100]).range([0, 0.125, 0]);
  const innerBucketBounds = getInnerBucket()?.node()?.getBoundingClientRect();
  const yCoor = -innerBucketBounds?.height / 2;
  const waveRiseScale = d3
    .scaleLinear()
    .domain([0, 100])
    .range([yCoor + innerBucketBounds?.height, yCoor]);
  return { waveScaleX, waveScaleY, waveHeightScale, waveRiseScale };
};

const generateClipPath = (waveHeight) => {
  const waveScales = generateClipPathScales(waveHeight);
  const allowedHeight = getAllowedHeight();

  const generateClipAreaData = () => {
    const totalWidth = singleWaveWidth * 2;
    const halfWidth = singleWaveWidth;
    const waveClipAreaData = [];

    for (var i = 0; i <= totalWidth; i++) {
      waveClipAreaData.push({
        x: i / totalWidth,
        y: i / halfWidth,
      });
    }

    return waveClipAreaData;
  };

  const generateClipArea = () => {
    const waveCeiling = allowedHeight + waveHeight;
    return d3
      .area()
      .x((d) => waveScales?.waveScaleX(d.x))
      .y1((d) => waveCeiling)
      .y0((d) => {
        const fullCircle = Math.PI * 2;
        const degrees = fullCircle + d.y * fullCircle;
        const yCoordinate = Math.sin(degrees);
        const waveFloor = waveScales?.waveScaleY(yCoordinate);
        return Math.round(waveFloor * 100) / 100;
      });
  };

  return { data: generateClipAreaData(), area: generateClipArea() };
};

const setColorsForGraphics = ({ theme, loadStatus }) => {
  const outterBucket = getOutterBucket();
  const innerBucket = getInnerBucket();
  let bucketColor, waveColor;

  if (loadStatus === "underweight") {
    bucketColor = theme.palette.info["dark"];
    waveColor = theme.palette.info["light"];
  } else if (loadStatus === "overweight") {
    bucketColor = theme.palette.error["main"];
    waveColor = theme.palette.error["light"];
  } else if (["target_met", "almost_full", "full"].includes(loadStatus)) {
    bucketColor = theme.palette.success["main"];
    waveColor = theme.palette.success["light"];
  } else {
    bucketColor = theme.palette.warning["dark"];
    waveColor = theme.palette.warning["main"];
  }

  outterBucket.style("fill", bucketColor).style("stroke", bucketColor);
  innerBucket.style("fill", waveColor);
};

const animateWave = ({ percent = 0, theme, loadStatus, initialRendering, waveScales }) => {
  const wave = d3.select(`#${clipPathId}`);
  const outterBucketWidth = getOutterBucket()?.node()?.getBoundingClientRect()?.width;
  const startXCoor = -outterBucketWidth / 2;
  const moveToXCoor = startXCoor - singleWaveWidth * 6;
  const startYCoor = waveScales.waveRiseScale(percent);

  setColorsForGraphics({ theme, loadStatus });

  if (initialRendering) {
    wave.attr("transform", `translate(${startXCoor}, ${waveScales.waveRiseScale(0)})`);
  }

  const moveWaveVertically = () => {
    wave
      .transition()
      .duration(1000)
      .ease(d3.easeLinear)
      .attr("T", 1)
      .attr("transform", `translate(${startXCoor}, ${waveScales.waveRiseScale(percent)})`);
  };

  const snapWaveBackToStart = () => {
    wave.attr("T", 0);
    wave.attr("transform", `translate(${startXCoor}, ${startYCoor})`);
  };

  const moveWaveHorizontally = () => {
    wave
      .transition()
      .duration(1000)
      .ease(d3.easeLinear)
      .attr("T", 1)
      .attr("transform", `translate(${moveToXCoor}, ${startYCoor})`)
      .on("end", () => {
        snapWaveBackToStart();
        moveWaveHorizontally();
      });
  };

  moveWaveVertically();
  moveWaveHorizontally();
};

const generateWaveHeight = (percent) => {
  const waveScales = generateClipPathScales();
  const contentOutline = getInnerBucket()?.node()?.getBoundingClientRect();
  return (contentOutline?.height / 2) * waveScales.waveHeightScale(percent);
};

const instantiateWaveClipPath = ({ percent, theme, loadStatus }) => {
  const waveHeight = generateWaveHeight(percent);
  const waveScales = generateClipPathScales(waveHeight);
  const clipPath = generateClipPath(waveHeight);

  const createWaveClipPath = () => {
    d3.select("defs")
      .append("clipPath")
      .attr("id", clipPathGroupId)
      .append("path")
      .attr("id", clipPathId)
      .datum(clipPath.data)
      .attr("d", clipPath.area)
      .attr("T", 0);
  };

  createWaveClipPath();
  animateWave({ percent, theme, loadStatus, initialRendering: true, waveScales });
};

const regenerateWaveClip = ({ percent, theme, loadStatus }) => {
  const waveHeight = generateWaveHeight(percent);
  const waveScales = generateClipPathScales(waveHeight);
  const clipPath = generateClipPath(waveHeight);
  const wave = d3.select(`#${clipPathId}`);
  wave.attr("d", clipPath.area).attr("T", 0);
  animateWave({ percent, theme, loadStatus, waveScales });
};

const ConveyorInputBucket = ({ percent, loadStatus }) => {
  const theme = useTheme();

  useEffect(() => {
    drawBucketOutlines();
    instantiateWaveClipPath({ percent, theme });
  }, []);

  useEffect(() => {
    regenerateWaveClip({ percent, theme, loadStatus });
  }, [percent, loadStatus]);

  return (
    <Box id="bucket-wrapper" display="flex" justifyContent="center" width="100%" height="inherit">
      <ConveyorBucketIcon />
    </Box>
  );
};

const ConveyorInputGraphic = ({ move, percent = 0, loadStatus }) => {
  return (
    <Grid container flexDirection="column" alignItems="center">
      <Grid item sx={{ width: "100%", height: "inherit" }}>
        <ConveyorInputBucket percent={percent > 100 ? 100 : percent} loadStatus={loadStatus} />
      </Grid>

      <Grid item>
        <ConveyorIcon move={move} />
      </Grid>
    </Grid>
  );
};

export default ConveyorInputGraphic;
