import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Box, Grid, Typography } from "@mui/material";
import {
  assignBarcodeToSis,
  assignCategoryToSis,
  assignCustomerToSis,
  updateSis,
} from "../../api/SlingInputStationApi";
import { useCustomer } from "../../selectors/customers";
import { useCategory } from "../../selectors/categories";
import { useSlingInputStation } from "../../selectors/slingInputStations";
import { useLiveWeightForRailId } from "../../selectors/rails";
import { getRailVisitsCountForRail } from "../../selectors/railVisits";
import { useCurrentRole } from "../../selectors/useRole";
import { useBatchSerialLabel } from "../../selectors/uiSettings";
import CustomerFlagSelector from "../FlagSelectors/CustomerFlagSelector";
import CategoryFlagSelector from "../FlagSelectors/CategoryFlagSelector";
import DestinationRailFlagSelector from "../FlagSelectors/DestinationRailFlagSelector";
import WeightInputButton from "./WeightInputButton";
import ConciseCaptureWeightButton from "./ConciseCaptureWeightButton";
import { NONE, KEYPAD } from "./weightInputTypes";
import SerialInput from "./SerialInput";
import WeightStatusWithBag from "./WeightStatusWithBag";
import { getWeightToUse } from "./utils";
import SlingNumberCard from "./SlingNumberCard";

const SingleSlingInput = ({ sisId, position, showCaptureWeightButton }) => {
  const { t } = useTranslation();
  const { canControlInputLifts } = useCurrentRole();

  const slingInputStation = useSlingInputStation(sisId);
  const {
    railId,
    category1Id,
    category2Id,
    customer1Id,
    customer2Id,
    enableTagSelection,
    tag1,
    serial1,
    serial2,
    weightInputType = KEYPAD,
    enableSerial,
    enableBagNumber,
    barcode1Id,
    barcode2Id,
  } = slingInputStation;

  const serialLabel = useBatchSerialLabel();

  const liveWeight = useLiveWeightForRailId(railId);
  const weight = getWeightToUse({ weightInputType, liveWeight, position, sis: slingInputStation });

  const categoryId = position === 1 ? category1Id : category2Id;
  const category = useCategory(categoryId);

  const customerId = position === 1 ? customer1Id : customer2Id;
  const customer = useCustomer(customerId) || {};

  const railVisitsCount = useSelector((state) => getRailVisitsCountForRail(state, railId));
  const systemId = useSelector((state) => state.rails[railId]?.systemId);
  const slingLoaded = railVisitsCount >= position;

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item sx={{ width: "100%" }}>
        <WeightStatusWithBag
          weight={weight || 0}
          sis={slingInputStation}
          category={category}
          slingLoaded={slingLoaded}
          position={position}
          showWeight={weightInputType !== NONE}
        />
      </Grid>

      <Grid item container justifyContent="center" spacing={2} sx={{ pt: 4, pb: 2 }}>
        <Grid item>
          <Typography variant="h5">{t("common:category")}</Typography>
          <Box>
            <CategoryFlagSelector
              selectedId={categoryId}
              size="large"
              onSelectionClick={(categoryId) => assignCategoryToSis({ sisId, categoryId, position })}
              disabled={!canControlInputLifts}
            />
          </Box>
        </Grid>

        <Grid item>
          <Typography variant="h5">{t("common:customer")}</Typography>
          <Box>
            <CustomerFlagSelector
              selectedId={customer.id}
              size="large"
              handleSelect={(customerId) => assignCustomerToSis({ sisId, customerId, position })}
              disabled={!canControlInputLifts}
            />
          </Box>
        </Grid>

        {enableTagSelection && (
          <Grid item>
            <Typography variant="h5">{t("common:destination")}</Typography>
            <Box>
              <DestinationRailFlagSelector
                railId={railId}
                selectedTagId={tag1}
                size="large"
                disabled={!canControlInputLifts}
                onSelect={(tagId) => updateSis({ sisId, data: { tag1: tagId } })}
              />
            </Box>
          </Grid>
        )}

        {enableSerial && (
          <Grid item>
            <Typography variant="h5">{serialLabel}</Typography>
            <SerialInput
              serial={position === 1 ? serial1 : serial2}
              updateSerial={(serial) =>
                updateSis({ sisId, data: { [position === 1 ? "serial1" : "serial2"]: serial } })
              }
            />
          </Grid>
        )}

        {enableBagNumber && (
          <Grid item>
            <Typography variant="h5">{t("common:bag_number")}</Typography>
            <Box>
              <SlingNumberCard
                systemId={systemId}
                barcodeId={position === 1 ? barcode1Id : barcode2Id}
                onChange={(barcodeId) => assignBarcodeToSis({ sisId, barcodeId, position })}
              />
            </Box>
          </Grid>
        )}

        {showCaptureWeightButton && (
          <Grid item>
            <ConciseCaptureWeightButton sisId={sisId} position={position} size="xLarge" />
          </Grid>
        )}

        {weightInputType === KEYPAD && (
          <Grid item>
            <WeightInputButton
              key={`weight-input-${sisId}-${position}`}
              sisId={sisId}
              weight={slingLoaded ? weight : 0}
              position={position}
              size="xLarge"
              disabled={!canControlInputLifts || !slingLoaded}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default SingleSlingInput;
