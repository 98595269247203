import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from "@mui/material";
import { withStyles, useTheme } from "@mui/styles";
import CheckIcon from "@mui/icons-material/Done";
import CloseButton from "../../CloseButton";
import colorGroups from "./colorGroups";

const StyledDialog = withStyles((theme) => ({
  paper: {
    maxHeight: "calc(100vh - 180px)",

    "& .MuiDialogContent-root": {
      display: "flex",
      flexWrap: "wrap",
      padding: theme.spacing(2),
    },
    "& .colorGroups": {
      display: "flex",
      flexDirection: "column",
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(4),
    },
    "& .swatch": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: 25,
      width: 100,
      height: 50,
      marginBottom: 2,
      border: `1px solid ${theme.palette.grey[200]}`,
    },
  },
}))(Dialog);

const ColorSelectionDialog = ({ open, selectedColor, onSelect, onClose }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <StyledDialog open={open} maxWidth="md" onBackdropClick={onClose} fullWidth={true}>
      <DialogTitle disableTypography>
        <Typography variant="h3">{t("categoriesSetup:color_picker")}</Typography>
        <Typography>{t("categoriesSetup:choose_color")}</Typography>
      </DialogTitle>

      <DialogContent dividers={true}>
        {Object.values(colorGroups).map((group, i) => (
          <Box key={i} className="colorGroups">
            {group.map((color) => {
              const selected = color === selectedColor;
              return (
                <Box
                  key={color}
                  id={color}
                  className="swatch"
                  style={{ backgroundColor: color }}
                  onClick={() => onSelect(color)}
                >
                  {selected && <CheckIcon style={{ color: theme.palette.getContrastText(selectedColor) }} />}
                </Box>
              );
            })}
          </Box>
        ))}
      </DialogContent>

      <DialogActions>
        <CloseButton onClick={onClose} />
      </DialogActions>
    </StyledDialog>
  );
};

export default ColorSelectionDialog;
