import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useRail } from "../../selectors/rails";
import { useRailVisitsCountForRail, useFirstRVBatchId } from "../../selectors/railVisits";
import { useBatch } from "../../selectors/batches";
import { useCurrentRole } from "../../selectors/useRole";
import CircleIndicator from "../../components/CircleIndicator";
import { railInputEnabled, railOutputEnabled } from "../../api/RailApi";
import { sbRequestOpen, sbRequestClose } from "../../api/StaticBagApi";
import { IndicatorStatusTypes } from "../../features/panels/enum/IndicatorStatusTypes";
import Bag from "./Bag";
import TransferButton from "./TransferButton";

const RailOnOffs = ({ rail = {} }) => {
  const { t } = useTranslation();
  const { canToggleRails } = useCurrentRole();
  const { id, inputEnabled, outputEnabled } = rail;
  const handleToggleInbound = () => railInputEnabled({ id, inputEnabled });
  const handleToggleOutbound = () => railOutputEnabled({ id, outputEnabled });

  return (
    <Grid container item spacing={1}>
      <Grid item>
        <CircleIndicator
          label={t("common:in")}
          status={inputEnabled ? IndicatorStatusTypes.ON : IndicatorStatusTypes.OFF}
          disabled={!canToggleRails}
          onClick={handleToggleInbound}
        />
      </Grid>

      <Grid item>
        <CircleIndicator
          label={t("common:out")}
          status={outputEnabled ? IndicatorStatusTypes.ON : IndicatorStatusTypes.OFF}
          disabled={!canToggleRails}
          onClick={handleToggleOutbound}
        />
      </Grid>
    </Grid>
  );
};

const actionButtonStyles = makeStyles((theme) => ({
  button: {
    minWidth: 250,
    padding: theme.spacing(2),
    fontSize: 50,
    wordBreak: "break-all",
  },
}));

const ActionButton = ({ staticBag, rail }) => {
  const classes = actionButtonStyles();
  const { t } = useTranslation();
  const { canControlInputLifts } = useCurrentRole();
  const { plcState } = rail;

  const handleOpen = () => sbRequestOpen(staticBag.id);
  const handleClose = () => sbRequestClose(staticBag.id);

  if (plcState !== "transferring" && plcState !== "ready_to_transfer") return null;

  return (
    <Button
      className={classes.button}
      color="primary"
      variant="contained"
      disabled={!canControlInputLifts}
      onClick={plcState === "transferring" ? handleClose : handleOpen}
    >
      {plcState === "transferring" ? t("common:close") : t("common:open")}
    </Button>
  );
};

const styles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    height: "100%",
  },
  railInfo: {
    marginBottom: theme.spacing(3),
  },
}));

const RailControls = ({ discharger, staticBag }) => {
  const classes = styles();
  const { t } = useTranslation();

  const { dischargerRailId, staticBagRailId } = staticBag;
  const railId = discharger ? dischargerRailId : staticBagRailId;

  const rail = useRail(railId);
  const rvCount = useRailVisitsCountForRail(rail.id);
  const firstRailBatchId = useFirstRVBatchId(rail.id);
  const railBatch = useBatch(firstRailBatchId);

  return (
    <Card className={classes.card}>
      <Grid item container justifyContent="center">
        <Grid item xs={4} container direction="column">
          <Grid item className={classes.railInfo}>
            <Typography variant="h3" color="primary">
              {rail.name || ""}
            </Typography>
            <Typography variant="overline">
              {t("railStates:state_state", {
                state: t(`railStates:${rail.plcState}`),
              })}
            </Typography>
          </Grid>

          <RailOnOffs rail={rail} />
        </Grid>

        <Grid item xs={4} container justifyContent="center">
          <Grid item>
            <Bag
              slingLoaded={rvCount >= 1}
              categoryId={railBatch.categoryId}
              customerId={railBatch.customerId}
              weight={railBatch?.weight}
              top={discharger}
            />
          </Grid>
        </Grid>

        <Grid item xs={4} container alignItems="center">
          <Grid item>
            {discharger ? (
              !staticBag.autoLoad && <TransferButton staticBag={staticBag} />
            ) : (
              <ActionButton staticBag={staticBag} rail={rail} />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default RailControls;
