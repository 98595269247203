import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline, GlobalStyles, StyledEngineProvider } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/lab";
import DayJsAdaptor from "@date-io/dayjs";
import { useSelector } from "react-redux";
import DataSubscriber from "./views/DataSubscriber";
import MainLayout from "./layouts/MainLayout";
import Routes from "./Routes";
import theme from "./theme";
import globalOverrides from "./theme/globalOverrides";
import i18n from "./i18n";
import dayjs, { mapToLocaleString } from "./dayjs";
import { useDefaultLanguage } from "./selectors/uiSettings";
import { useCurrentDayjsLocale } from "./hooks/useCurrentDayjsLocale";

const App = () => {
  const customization = useSelector((state) => state.customization);
  const currentLocale = useCurrentDayjsLocale();

  const defaultLanguage = useDefaultLanguage();
  useEffect(() => {
    i18n.changeLanguage(defaultLanguage);
    dayjs.locale(mapToLocaleString(defaultLanguage));
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme(customization)}>
        <LocalizationProvider dateAdapter={DayJsAdaptor} locale={currentLocale}>
          <CssBaseline />
          <GlobalStyles styles={globalOverrides} />
          <DataSubscriber>
            <BrowserRouter basename="/evue-ui">
              <MainLayout>
                <Routes />
              </MainLayout>
            </BrowserRouter>
          </DataSubscriber>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
