import { createSlice } from "@reduxjs/toolkit";

const themeSlice = createSlice({
  name: "customization",
  initialState: {
    borderRadius: 12,
    fontFamily: `'Roboto', sans-serif`,
    isOpen: [], //for active default menu
    navType: "light",
    opened: true,
    presetColor: "",
  },
  reducers: {},
});

export const {} = themeSlice.actions;

export default themeSlice.reducer;
