import React from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import { useSlingInputStation } from "../../selectors/slingInputStations";
import { useRail } from "../../selectors/rails";
import { useCustomer } from "../../selectors/customers";
import { useCategory } from "../../selectors/categories";
import { useCurrentRole } from "../../selectors/useRole";
import { railInputEnabled, railOutputEnabled } from "../../api/RailApi";
import { assignCategoryToSis, assignCustomerToSis } from "../../api/SlingInputStationApi";
import { IndicatorStatusTypes } from "../../features/panels/enum/IndicatorStatusTypes";
import CustomerFlagSelector from "../FlagSelectors/CustomerFlagSelector";
import CategoryFlagSelector from "../FlagSelectors/CategoryFlagSelector";
import CircleIndicator from "../CircleIndicator";
import SlingPresenceIndicator from "./SlingPresenceIndicator";
import ConfigurationButton from "./ConfigurationButton";
import EmptyRailSISConfigOptions from "./EmptyRailSISConfigOptions";
import ConciseManSendButton from "./ConciseManSendButton";

interface Props {
  sisId: number;
};

const EmptyRailSIS = ({ sisId }: Props) => {
  const { t } = useTranslation();

  const slingInputStation = useSlingInputStation(sisId);
  const sisConfiguration = slingInputStation?.configuration;
  const { railId } = slingInputStation;
  const rail = useRail(railId);
  const { name, inputEnabled, outputEnabled, plcState } = rail;

  const handleToggleInbound = () => railInputEnabled({ id: railId, inputEnabled });
  const handleToggleOutbound = () => railOutputEnabled({ id: railId, outputEnabled });

  return (
    <Paper square={true} elevation={0} sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
      <Box padding={2} display="flex" justifyContent="space-between">
        <Box display="flex" flexDirection="column">
          <Typography variant="h1">{name}</Typography>
          <Typography variant="overline" gutterBottom>
            {t("railStates:state_state", {
              state: t(`railStates:${plcState}`),
            })}
          </Typography>

          <Box display="flex" mt={2}>
            <CircleIndicator
              label={t("io:in")}
              status={inputEnabled ? IndicatorStatusTypes.ON : IndicatorStatusTypes.OFF}
              disabled={false}
              onClick={handleToggleInbound}
            />
            <CircleIndicator
              label={t("io:out")}
              status={outputEnabled ? IndicatorStatusTypes.ON : IndicatorStatusTypes.OFF}
              disabled={false}
              onClick={handleToggleOutbound}
            />
          </Box>
        </Box>

        <Box display="flex" alignItems="flex-end">
          {sisConfiguration?.showSendLoadButton && (
            <ConciseManSendButton sisId={sisId} size="xLarge" />
          )}
          <ConfigurationButton>
            <EmptyRailSISConfigOptions sisId={sisId} />
          </ConfigurationButton>
        </Box>
      </Box>

      <Divider />

      <Box padding={2}>
        <SingleSlingInput key={`sling-${sisId}-${1}`} sisId={sisId} position={1} />
      </Box>
    </Paper>
  );
};

interface SingleSlingInputProps {
  sisId: number;
  position: number;
};

const SingleSlingInput = ({ sisId, position }: SingleSlingInputProps) => {
  const { t } = useTranslation();
  const { canControlInputLifts } = useCurrentRole();

  const slingInputStation = useSlingInputStation(sisId);
  const { category1Id, category2Id, customer1Id, customer2Id } = slingInputStation;

  const categoryId = position === 1 ? category1Id : category2Id;
  const category = useCategory(categoryId);

  const customerId = position === 1 ? customer1Id : customer2Id;
  const customer = useCustomer(customerId) || {};

  const handleCategorySelect = (categoryId: number) => {
    return assignCategoryToSis({ sisId, categoryId, position });
  };

  const handleCustomerSelect = (customerId: number) => {
    return assignCustomerToSis({ sisId, customerId, position });
  };

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item container justifyContent="center" alignItems="center" spacing={2}>
        <Grid item>
          <SlingPresenceIndicator slingLoaded={true} size={80} />
        </Grid>

        <Grid item>
          <Typography variant="h5">{t("common:category")}</Typography>
          <Box>
            <CategoryFlagSelector
              selectedId={category.id}
              size="large"
              onSelectionClick={handleCategorySelect}
              disabled={!canControlInputLifts}
            />
          </Box>
        </Grid>

        <Grid item>
          <Typography variant="h5">{t("common:customer")}</Typography>
          <Box>
            <CustomerFlagSelector
              selectedId={customer.id}
              size="large"
              handleSelect={handleCustomerSelect}
              disabled={!canControlInputLifts}
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EmptyRailSIS;
