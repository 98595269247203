import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Dialog, DialogActions, DialogContent, IconButton, Typography } from "@mui/material";
import PlaylistAddCircleIcon from "@mui/icons-material/PlaylistAddCircle";
import FilterControls from "../../../../components/FlagSelectors/FilterControls";
import useSortingAndFiltering from "../../hooks/useSortingAndFiltering";
import useStorageGroupAlertMessages from "../../hooks/useStorageGroupAlertMessages";
import { StorageRuleTypes } from "../../enum/StorageRuleTypes";
import type { StorageRuleDTO } from "../../dto/StorageRuleDTO";
import type { StorageRuleset } from "../../dto/StorageRuleset";
import { separateRulesByType } from "../../utils";
import { useUpdateStorageRulesetMutation } from "../../services/storageRulesets";
import SaveButton from "../../../../components/SaveButton";
import { useSystemByRailId } from "../../../../selectors/systems";
import { consolidateOldWithNewRules } from "../../utils";
import SourceRailSelectionView from "../RailOverridePage/SourceRailSelectionView";
import CustomerSelectionView from "../RailOverridePage/CustomerSelectionView";
import CategorySelectionView from "../RailOverridePage/CategorySelectionView";
import StorageGroupSelectionView from "../RailOverridePage/StorageGroupSelectionView";

interface StrgRuleTypeSelectorProps {
  onSelect: (ruleType: StorageRuleTypes) => void;
  onClose: VoidFunction;
};

const StorageRuleTypeSelector = ({ onSelect, onClose }: StrgRuleTypeSelectorProps) => {
  const { t } = useTranslation();

  const buttons = [
    {key: StorageRuleTypes.RAIL, name: t("common:rail")},
    {key: StorageRuleTypes.CUSTOMER, name: t("common:customer")},
    {key: StorageRuleTypes.CATEGORY, name: t("common:category")},
    {key: StorageRuleTypes.STORAGE_GROUP, name: t("storageManager:storage_group")},
  ];

  return (
    <>
      <Box pt={2} pb={2} pl={3} pr={3}>
        <Typography variant="h3">{t("storageManager:add_rule")}</Typography>
        <Typography sx={{ mb: 2 }} gutterBottom>
          {t("common:select_type")}
        </Typography>
      </Box>

      <DialogContent dividers={true} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        {buttons.map((rule) => (
          <Button
            key={rule.key}
            variant="contained"
            disableElevation
            sx={{ mb: 2 }}
            onClick={() => onSelect(rule.key)}
          >
            {rule.name}
          </Button>
        ))}
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3}}>
        <Button variant="outlined" color="error" onClick={onClose}>{t("common:cancel")}</Button>
      </DialogActions>
    </>
  );
};

interface RuleSelectorSwitchProps {
  storageRuleset: StorageRuleset;
  ruleType: StorageRuleTypes;
  onSubmit: (storageRules: StorageRuleDTO[]) => void;
  onCancel: VoidFunction;
};

const RuleSelectorSwitch = (props: RuleSelectorSwitchProps) => {
  const { storageRuleset, ruleType, onSubmit, onCancel } = props;
  const system = useSystemByRailId(storageRuleset.railId);
  const { t } = useTranslation();
  const filterOptions = useSortingAndFiltering();

  const rulesByType = separateRulesByType(storageRuleset.storageRules);
  const origSourceRailIds = rulesByType.sourceRails.map((sr) => sr.railId) as number[];
  const origCustomerIds = rulesByType.customers.map((sr) => sr.customerId) as number[];
  const origCategoryIds = rulesByType.categories.map((sr) => sr.categoryId) as number[];
  const origStorageGroupIds = rulesByType.storageGroups.map((sr) => sr.storageGroupId) as number[];

  const [updatedSrcRailIds, setUpdatedSrcRailIds] = useState(origSourceRailIds || []);
  const [updatedCustomerIds, setUpdatedCustomerIds] = useState(origCustomerIds || []);
  const [updatedCategoryIds, setUpdatedCategoryIds] = useState(origCategoryIds || []);
  const [updatedStrgGroupIds, setUpdatedStrgGroupIds] = useState(origStorageGroupIds || []);

  const handleOnSelect = (selections: number[], ruleType: StorageRuleTypes) => {
    switch (ruleType) {
      case StorageRuleTypes.RAIL:
        return setUpdatedSrcRailIds(selections);
      case StorageRuleTypes.CUSTOMER:
        return setUpdatedCustomerIds(selections);
      case StorageRuleTypes.CATEGORY:
        return setUpdatedCategoryIds(selections);
      case StorageRuleTypes.STORAGE_GROUP:
        return setUpdatedStrgGroupIds(selections);
    };
  };

  const handleOnSubmit = () => {
    const updatedSourceRailRules = consolidateOldWithNewRules({
      storageRulesetId: storageRuleset.id,
      existingRules: rulesByType.sourceRails,
      selectedIds: updatedSrcRailIds,
      fieldName: "railId",
    });
    const updatedCustomerRules = consolidateOldWithNewRules({
      storageRulesetId: storageRuleset.id,
      existingRules: rulesByType.customers,
      selectedIds: updatedCustomerIds,
      fieldName: "customerId",
    });
    const updatedCategoryRules = consolidateOldWithNewRules({
      storageRulesetId: storageRuleset.id,
      existingRules: rulesByType.categories,
      selectedIds: updatedCategoryIds,
      fieldName: "categoryId",
    });
    const updatedStorageGroupRules = consolidateOldWithNewRules({
      storageRulesetId: storageRuleset.id,
      existingRules: rulesByType.storageGroups,
      selectedIds: updatedStrgGroupIds,
      fieldName: "storageGroupId",
    });

    onSubmit([
      ...updatedSourceRailRules,
      ...updatedCustomerRules,
      ...updatedCategoryRules,
      ...updatedStorageGroupRules,
    ]);
  };

  let content;
  if (ruleType === StorageRuleTypes.RAIL) {
    content =
      <SourceRailSelectionView
        selections={updatedSrcRailIds}
        hiddenSelections={origSourceRailIds}
        railId={storageRuleset.railId}
        disabled={false}
        filterOptions={filterOptions}
        onSelect={(selections) => handleOnSelect(selections, StorageRuleTypes.RAIL)}
      />
  } else if (ruleType === StorageRuleTypes.CUSTOMER) {
    content =
      <CustomerSelectionView
        selections={updatedCustomerIds}
        hiddenSelections={origCustomerIds}
        disabled={false}
        filterOptions={filterOptions}
        onSelect={(selections) => handleOnSelect(selections, StorageRuleTypes.CUSTOMER)}
      />
  } else if (ruleType === StorageRuleTypes.CATEGORY) {
    content =
      <CategorySelectionView
        selections={updatedCategoryIds}
        hiddenSelections={origCategoryIds}
        disabled={false}
        filterOptions={filterOptions}
        onSelect={(selections) => handleOnSelect(selections, StorageRuleTypes.CATEGORY)}
      />
  } else if (ruleType === StorageRuleTypes.STORAGE_GROUP) {
    content =
      <StorageGroupSelectionView
        systemId={system?.id}
        selections={updatedStrgGroupIds}
        hiddenSelections={origStorageGroupIds}
        disabled={false}
        filterOptions={filterOptions}
        onSelect={(selections) => handleOnSelect(selections, StorageRuleTypes.STORAGE_GROUP)}
      />
  } else {
    content = undefined;
  };

  return (
    <>
      <Box pt={2} pb={2} pl={3} pr={3}>
        <Typography variant="h3">{t("storageManager:add_rule")}</Typography>
        <Typography sx={{ mb: 2 }} gutterBottom>
          {t("storageManager:select_object")}
        </Typography>

        <FilterControls
          sx={{ mt: 2 }}
          sortBy={filterOptions?.sortBy}
          order={filterOptions?.order}
          onChangeSortBy={filterOptions?.handleChangeSortBy}
          onChangeSearchTerm={filterOptions?.handleChangeSearchTerm}
        />
      </Box>

      <DialogContent dividers={true}>
        {content}
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3}}>
        <Button variant="outlined" color="error" onClick={onCancel}>{t("common:cancel")}</Button>
        <SaveButton onClick={handleOnSubmit}>{t("common:add")}</SaveButton>
      </DialogActions>
    </>
  )
};

interface Props {
  storageRuleset: StorageRuleset;
  disabled?: boolean;
};

// TODO: Consider after CRUD operation messages using rtk api. Should they happen everywhere or just once from api file?
const StorageRuleCreator = ({ storageRuleset, disabled }: Props) => {
  const { t } = useTranslation();
  const [openCreator, setOpenCreator] = useState(false);
  const [strgRuleType, setStrgRuleType] = useState<StorageRuleTypes | undefined>(undefined);

  const handleOnClose = () => {
    setOpenCreator(false);
    setStrgRuleType(undefined);
  };

  const [updateStorageRuleset, { isLoading: isUpdating }] = useUpdateStorageRulesetMutation();
  const { onSuccess, onFailure } = useStorageGroupAlertMessages();
  const handleOnSubmit = (storageRules: StorageRuleDTO[]) => {
    return updateStorageRuleset({
      ...storageRuleset,
      storageRules,
    }).then(() => {
      onSuccess();
      handleOnClose();
    }).catch((error) => {
      onFailure(error);
    });
  };

  return (
    <>
      <IconButton color="primary" disabled={disabled || !storageRuleset?.id} onClick={() => setOpenCreator(true)}>
        <PlaylistAddCircleIcon sx={{ fontSize: 30 }} />
      </IconButton>

      {openCreator ? (
        <Dialog
          open={true}
          maxWidth="md"
          fullWidth
          onBackdropClick={handleOnClose}
          TransitionProps={{
            unmountOnExit: true,
          }}
          sx={{ "& .MuiDialog-paper": { maxHeight: "calc(100vh - 180px)", height: "100%" } }}
        >
          {strgRuleType ? (
            <RuleSelectorSwitch
              storageRuleset={storageRuleset}
              ruleType={strgRuleType}
              onSubmit={handleOnSubmit}
              onCancel={handleOnClose}
            />
          ) : (
            <StorageRuleTypeSelector
              onSelect={(ruleType: StorageRuleTypes) => setStrgRuleType(ruleType)}
              onClose={handleOnClose}
            />
          )}
        </Dialog>
      ) : undefined}
    </>
  );
};

export default StorageRuleCreator;
