import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useCurrentUserId } from "../selectors/session";
import { useShowLogin } from "../selectors/local";
import UserSelectionDialog from "../components/UserSelectionDialog";
import UserPasswordDialog from "../components/UserPasswordDialog";
import * as actions from "../actions";

const LoginDialog = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [showPassDialog, setShowPassDialog] = useState(false);

  const showLoginDialog = useShowLogin();
  const currentLoggedInUserId = useCurrentUserId();

  useEffect(() => {
    if (currentLoggedInUserId) {
      setShowPassDialog(false);
      handleClose();
      setSelectedUserId(null);
    }
  }, [currentLoggedInUserId]);

  const handleUserClicked = (userId) => {
    setSelectedUserId(userId);
    setShowPassDialog(true);
  };

  const handleSubmitUsername = (userId) => {
    if (userId) {
      setSelectedUserId(userId);
      setShowPassDialog(true);
    } else {
      setSelectedUserId(null);
      dispatch(actions.showAlertError(t("users:user_not_found")));
    }
  };

  const handleClose = () => {
    dispatch(actions.hideLogin());
    setSelectedUserId(null);
  };

  return (
    <>
      {showLoginDialog && (
        <UserSelectionDialog
          open={showLoginDialog}
          selectedUserId={selectedUserId}
          handleSelect={handleUserClicked}
          handleClose={handleClose}
          handleSubmitUsername={handleSubmitUsername}
        />
      )}

      {selectedUserId && showPassDialog && (
        <UserPasswordDialog
          selectedUserId={selectedUserId}
          open={showPassDialog}
          onClose={() => setShowPassDialog(false)}
        />
      )}
    </>
  );
};

export default LoginDialog;
