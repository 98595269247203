import React from "react";
import { Box, Typography } from "@mui/material";

const FormFieldGroupLabel = ({ label }) => {
  return (
    <Box mt={1}>
      <Typography variant="overline">{label}</Typography>
    </Box>
  );
};

export default FormFieldGroupLabel;
