import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/CloseSharp";
import InfoIcon from "@mui/icons-material/Info";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useExternalCustomerId } from "../selectors/custChangeStations";
import { useCurrentRole } from "../selectors/useRole";
import { acceptExternalCustomerChange, denyExternalCustomerChange } from "../api/CustChangeStationApi";
import CustomerFlagCard from "./FlagSelectors/CustomerFlagCard";

const useDialogStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  acceptButton: {
    backgroundColor: theme.palette.success["light"],
    color: theme.palette.white,
  },
  denyButton: {
    backgroundColor: theme.palette.error["light"],
    color: theme.palette.white,
  },
}));

const ExtCustChangeConfirmDialog = ({ open, externalCustomerId, onClose, onReject, onAccept }) => {
  const classes = useDialogStyles();
  const { t } = useTranslation();

  return (
    <Dialog open={open} maxWidth="md" fullWidth={true} onClose={onClose}>
      <DialogTitle>
        <Typography variant="h3">{t("customerChange:ext_cust_change_request")}</Typography>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers={true}>
        <DialogContentText>{t("customerChange:are_you_sure_ext_cust_change")}</DialogContentText>

        <CustomerFlagCard selectedId={externalCustomerId} />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" disableElevation={true} className={classes.acceptButton} onClick={onAccept}>
          {t("common:yes")}
        </Button>
        <Button variant="contained" className={classes.denyButton} disableElevation={true} onClick={onReject}>
          {t("common:no")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.palette.info["main"],
    color: theme.palette.white,
    boxShadow: "none",
    "& svg": {
      marginRight: theme.spacing(1),
    },
    "&:hover": {
      backgroundColor: theme.palette.info["light"],
    },
  },
}));

const ExtCustChangeButton = ({ custChangeStationId }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { canCustomerChange } = useCurrentRole();
  const [openDialog, setOpenDialog] = useState(false);

  const location = useLocation();
  useEffect(() => {
    const showPendingRequest = location.state?.showPendingRequest;
    if (showPendingRequest) {
      setOpenDialog(showPendingRequest);
    }
  }, []);

  const externalCustomerId = useExternalCustomerId(custChangeStationId);
  if (!externalCustomerId) return null;
  return (
    <>
      <Fab
        className={classes.button}
        variant="extended"
        disabled={!canCustomerChange}
        onClick={() => setOpenDialog(true)}
      >
        <InfoIcon />
        {t("customerChange:review_change_request")}
      </Fab>

      {openDialog && (
        <ExtCustChangeConfirmDialog
          open={openDialog}
          externalCustomerId={externalCustomerId}
          onClose={() => setOpenDialog(false)}
          onAccept={() => {
            acceptExternalCustomerChange(custChangeStationId);
            setOpenDialog(false);
          }}
          onReject={() => {
            denyExternalCustomerChange(custChangeStationId);
            setOpenDialog(false);
          }}
        />
      )}
    </>
  );
};

export default ExtCustChangeButton;
