import React from "react";
import { Box, FormControlLabel, Radio, RadioGroup, Switch } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSlingInputStation } from "../../selectors/slingInputStations";
import { useRail } from "../../selectors/rails";
import { updateConfiguration, updateSis } from "../../api/SlingInputStationApi";
import MainCard from "../../ui-component/cards/MainCard";

const DefaultSISConfigurationOptions = ({ sisId }) => {
  const { t } = useTranslation();
  const sis = useSlingInputStation(sisId) || {};
  const rail = useRail(sis.railId) || {};
  const showTwoSlings = rail.fullQuantity > 1;
  const configuration = sis.configuration || {};

  const handleUpdateConfiguration = (e) => {
    updateConfiguration({
      sisId,
      configuration: {
        ...sis.configuration,
        slingOrder: e.target.value || "rtl",
      },
    });
  };

  const handleToggleEnableDestSelection = () => {
    updateSis({
      sisId,
      data: { enableTagSelection: !sis.enableTagSelection },
    });
  };

  return (
    <Box margin={4} marginTop={14} minWidth={200} display="flex" flexDirection="column">
      {showTwoSlings && (
        <MainCard title={t("slingInputStation:sling_order")}>
          <RadioGroup row={true} value={configuration.slingOrder || "rtl"} onChange={handleUpdateConfiguration}>
            <FormControlLabel value="ltr" control={<Radio />} label="LTR" />
            <FormControlLabel value="rtl" control={<Radio />} label="RTL" />
          </RadioGroup>
        </MainCard>
      )}

      <MainCard title={t("slingInputStation:enable_destination_selection")} sx={{ mt: 4 }}>
        <Switch checked={sis.enableTagSelection} onChange={handleToggleEnableDestSelection} />
      </MainCard>
    </Box>
  );
};

export default DefaultSISConfigurationOptions;
