import { combineReducers } from "redux";
import _ from "lodash";

import simpleReducer from "./simpleReducer";
import alertReducer from "./alertReducer";
import batchHistorySlice from "../features/batchHistory/batchHistorySlice";
import batchReducer from "./batchReducer";
import delayReducer from "../features/delays/DelaySlice";
import deviceTypeSlice from "../features/deviceTypes/deviceTypeSlice";
import ioPointReducer from "./ioPointReducer";
import loadCellReducer from "./loadCellReducer";
import loaderReducer from "./loaderReducer";
import localReducer from "./localReducer";
import machineBarSlice from "../features/machineBar/machineBarSlice";
import machineVisitReducer from "./machineVisitReducer";
import overviewSlice from "../features/overview/overviewSlice";
import railSystemReducer from "./railSystemReducer";
import railVisitReducer from "./railVisitReducer";
import panelsSlice from "../features/panels/redux/panelsSlice";
import productionListEditReducer from "../redux/productionListEditor";
import productionListReducer from "./productionListReducer";
import productionListStatusSlice from "../features/productionListStatus/productionListStatusSlice";
import serverStatusReducer from "./serverStatusReducer";
import servoLiftSlice from "../features/servoLifts/servoLiftsSlice";
import sessionReducer from "./sessionReducer";
import slxSlice from "../features/SLX/SLXSlice";
import sortConfigurationsReducer from "../features/sortAssignments/redux/sortConfigurationsSlice";
import storageConfigReducer from "../redux/storageConfigEditor";
import uiSettingReducer from "./uiSettingReducer";
import railMessageEventSlice from "../features/railMessageEvents/railMessageEventsSlice";
import themeSlice from "../features/theme/themeSlice";
import perspectiveSlice from "../features/perspectives/perspectiveSlice";
import { vecturaApi } from "../features/overview/services/vectura";
import tagSelectionSlice from "../features/tagSelection/tagSelectionSlice";
import { slxPrintApi } from "../features/SLX/slxPrint";
import { eVueApi } from "../services/eVue";
import { Customer } from "../dto/eVue/Customer";
import { System } from "../dto/eVue/System";

export default combineReducers({
  activeProductionLists: simpleReducer("active_production_lists"),
  batches: batchReducer,
  batchHistory: batchHistorySlice,
  barcodes: simpleReducer("barcodes"),
  barcodeChecks: simpleReducer("barcode_checks"),
  callOffs: simpleReducer("call_offs"),
  categories: simpleReducer("categories"),
  categoryGroups: simpleReducer("category_groups"),
  conveyors: simpleReducer("conveyors"),
  custChangeStations: simpleReducer("cust_change_stations"),
  customers: simpleReducer<Customer>("customers"),
  customization: themeSlice,
  delays: delayReducer,
  devices: simpleReducer("devices"),
  deviceTypes: deviceTypeSlice,
  [eVueApi.reducerPath]: eVueApi.reducer,
  ioPoints: ioPointReducer,
  loadCells: loadCellReducer,
  loader: loaderReducer,
  local: localReducer,
  machineBar: machineBarSlice,
  machines: simpleReducer("machines"),
  machineVisits: machineVisitReducer,
  overview: overviewSlice,
  panels: panelsSlice,
  perspectives: perspectiveSlice,
  plcChannels: simpleReducer("plc_channels"),
  productionListEdit: productionListEditReducer,
  productionLists: productionListReducer,
  productionListStatus: productionListStatusSlice,
  rails: simpleReducer("rails"),
  railIoTypes: simpleReducer("rail_io_types"),
  railMessageEvents: railMessageEventSlice,
  railSystem: railSystemReducer,
  railVisits: railVisitReducer,
  roles: simpleReducer("roles"),
  scanners: simpleReducer("scanners"),
  serverStatus: serverStatusReducer,
  servoLifts: servoLiftSlice,
  slingInputStations: simpleReducer("sling_input_stations"),
  slx: slxSlice,
  [slxPrintApi.reducerPath]: slxPrintApi.reducer,
  sortConfigurations: sortConfigurationsReducer,
  sortStations: simpleReducer("sort_stations"),
  staticBags: simpleReducer("static_bags"),
  storageConfigEdit: storageConfigReducer,
  systems: simpleReducer<System>("systems"),
  session: sessionReducer,
  tagSelection: tagSelectionSlice,
  ui: alertReducer,
  uiSettings: uiSettingReducer,
  users: simpleReducer("users"),
  [vecturaApi.reducerPath]: vecturaApi.reducer,
  weightChecks: simpleReducer("weight_checks"),
});
