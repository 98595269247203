import React, { useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import * as actions from "../actions";
import { railMessageEventSubscribe } from "../features/railMessageEvents/railMessageEventsSlice";
import { perspectiveSubscribe } from "../features/perspectives/perspectiveSlice";
import { slxSubscribe } from "../features/SLX/SLXSlice";
import { panelsSubscribe } from "../features/panels/redux/panelsSlice";
import { simpleSubscription } from "../utils/channel";
import AppLoader from "./AppLoader";
import { delaySubscribe } from "../features/delays/DelaySlice";
import { tagSelectionLoad } from "../features/tagSelection/tagSelectionSlice";

const DataSubscriber = ({ children }) => {
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.loader, shallowEqual);
  const loaded = Object.keys(loader).every((key) => loader[key]);

  useEffect(() => {
    simpleSubscription("active_production_lists");
    simpleSubscription("barcodes");
    simpleSubscription("barcode_checks");
    simpleSubscription("call_offs");
    simpleSubscription("categories");
    simpleSubscription("category_groups");
    simpleSubscription("conveyors");
    simpleSubscription("customers");
    simpleSubscription("cust_change_stations");
    simpleSubscription("machines");
    simpleSubscription("rails");
    simpleSubscription("rail_io_types");
    simpleSubscription("roles");
    simpleSubscription("scanners");
    simpleSubscription("sling_input_stations");
    simpleSubscription("static_bags");
    simpleSubscription("sort_stations");
    simpleSubscription("systems");
    simpleSubscription("users");
    simpleSubscription("weight_checks");
    simpleSubscription("plc_channels");

    dispatch(actions.batchSubscribe());
    dispatch(actions.deviceSubscribe());
    dispatch(actions.ioPointSubscribe());
    dispatch(actions.railVisitSubscribe());
    dispatch(actions.loadCellSubscribe());
    dispatch(actions.machineVisitSubscribe());
    dispatch(actions.productionListSubscribe());
    dispatch(actions.uiSettingSubscribe());
    dispatch(actions.serverStatusSubscribe());
    dispatch(railMessageEventSubscribe());
    dispatch(delaySubscribe());
    dispatch(slxSubscribe());
    dispatch(panelsSubscribe());
    dispatch(perspectiveSubscribe());
    dispatch(tagSelectionLoad());
  }, []);

  useEffect(() => {
    if (loaded) {
      // Re-initialize session so that language
      // can be set properly (requires users loaded)
      dispatch(actions.initializeSession());

      // Enable the rail system overview screen to update
      dispatch(actions.dataLoaded(true));
    }
  }, [loaded]);

  return <div>{loaded ? children : <AppLoader />}</div>;
};

export default DataSubscriber;
