import { useSelector } from "react-redux";
import store from "../store";

export const getSortStations = (receivedState) => {
  const state = receivedState || store.getState();
  return state.sortStations || {};
};

export const getSortStationsCount = (systemId) =>
  Object.values(getSortStations()).filter((x) => x.systemId === systemId).length;

export const getSortStationBankNumber = (id) => store.getState().sortStations[id]?.bankNumber;

export const useAnyPurgeableSortStations = () =>
  useSelector((state) => {
    return Object.values(state.sortStations).some((el, i, sortStations) => el.purgeable);
  });
