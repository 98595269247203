import React from "react";
import PropTypes from "prop-types";
import { Select, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCurrentRole } from "../../selectors/useRole";
import FormFieldLabel from "../SetupPages/FormFieldLabel";

const FormBatchTextSelector = ({ currentValues, variant, disabled, onFieldChange }) => {
  const { t } = useTranslation();
  const currentRole = useCurrentRole();

  const textSelections = {
    category: t("common:category"),
    customer: t("common:customer"),
    weight: t("common:weight"),
    slingNumber: t("common:bag_number"),
  };

  const affectedParameter = variant === "top" ? "batchTopText" : "batchBottomText";

  const handleOnFieldChange = (e) => {
    return onFieldChange("uiSettings", {
      ...currentValues["uiSettings"],
      [affectedParameter]: e.target.value,
    });
  };

  return (
    <>
      <FormFieldLabel label={t(`usersSetup:batch_${variant}_text`)} />

      <Select
        variant="outlined"
        value={currentValues["uiSettings"][affectedParameter] || ""}
        displayEmpty={true}
        disabled={disabled || !currentRole.canManageUsers}
        onChange={handleOnFieldChange}
      >
        <MenuItem value="">
          <em>{t("common:none")}</em>
        </MenuItem>
        {Object.keys(textSelections).map((entry) => (
          <MenuItem key={entry} value={entry} disabled={disabled || !currentRole.canManageUsers}>
            {textSelections[entry]}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

FormBatchTextSelector.propTypes = {
  currentValues: PropTypes.object,
  variant: PropTypes.oneOf(["top", "bottom"]),
  disabled: PropTypes.bool,
  onFieldChange: PropTypes.func,
};

export default FormBatchTextSelector;
