import React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Logo from "../../assets/logos/evue_logo_small.png";

const useStyles = makeStyles((theme) => ({
  logo: {
    maxHeight: 48,
  },
}));

const EvueLogo = () => {
  const classes = useStyles();
  const location = useLocation();

  return (
    <RouterLink to={{ pathname: "/overview", state: { prevPath: location.pathname } }}>
      <img className={classes.logo} src={Logo} />
    </RouterLink>
  );
};

export default EvueLogo;
