import React from "react";
import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";

const StyledGridItem = withStyles((theme) => ({
  root: {
    maxWidth: 600,
    paddingRight: theme.spacing(2),
    "&:last-child": {
      paddingRight: 0,
    },
  },
}))(Grid);

export default StyledGridItem;
