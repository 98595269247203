const buildResources = () => {
  const languages = [
    "en_US",
    "en_US_2490G",
    "de_DE",
    "es_ES",
    "fr_FR",
    "el_GR",
    "fi_FI",
    "it_IT",
    "hu_HU",
    "nl_BE",
    "pt_BR",
    "sv_SE",
    "zh_CN",
    "ja_JP",
  ];
  const namespaces = [
    "about",
    "barcodeCheck",
    "barcodeScanner",
    "bagsSetup",
    "batchHistory",
    "categoriesSetup",
    "categoryGroupsSetup",
    "customersSetup",
    "common",
    "conveyors",
    "customerChange",
    "delays",
    "faults",
    "io",
    "laborTasksSetup",
    "loadCell",
    "machineBar",
    "menus",
    "networkDevicesSetup",
    "overview",
    "plcTroubleshootDialog",
    "productionList",
    "productionPointsSetup",
    "railApi",
    "railBar",
    "railGroups",
    "railMessages",
    "railMessageAckActions",
    "railMessageEventsMetrics",
    "railportBar",
    "railsSetup",
    "railStates",
    "railSystem",
    "rolesSetup",
    "serverTroubleshootDialog",
    "slingInputStation",
    "slx",
    "sortAssignments",
    "staticBag",
    "storageAssignments",
    "storageManager",
    "troubleshooter",
    "users",
    "usersSetup",
    "visitBar",
    "weightCheck",
    "weightCheckSettings",
  ];

  const myData = languages.reduce((acc, lang) => {
    acc[lang] = namespaces.reduce((acc2, namespace) => {
      try {
        acc2[namespace] = require(`./${lang}/${namespace}.json`);
      } catch {
        // console.warn(`Namespace missing for ${lang} - ${namespace}`);
      }
      return acc2;
    }, {});
    return acc;
  }, {});

  return myData;
};

const resources = buildResources();
export default resources;
