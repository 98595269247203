import React, { useState } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useBatchSerialLabel } from "../../selectors/uiSettings";

const useStyles = makeStyles((theme) => ({
  textField: {
    width: "fit-content",
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottomColor: theme.palette.common.white,
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: theme.palette.common.white,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.palette.info.light,
    },
    "& .MuiOutlinedInput-input": {
      "&.Mui-disabled": {
        WebkitTextFillColor: "rgb(255, 255, 255, 1)",
      },
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.common.white,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.common.white,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.common.white,
      },
      "&.Mui-disabled fieldset": {
        borderColor: theme.palette.common.white,
      },
    },
    marginBottom: theme.spacing(1),
  },
  input: {
    color: theme.palette.common.white,
  },
  disabled: {
    opacity: 0.4,
  },
}));

const SerialInput = ({ disabled, updateSerial, serial, dark }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const label = useBatchSerialLabel();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    if (disabled) return;
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    updateSerial(event.target.serial.value);
    setOpen(false);
  };

  return (
    <>
      <Box>
        <TextField
          className={clsx({
            [classes.textField]: dark,
            [classes.disabled]: disabled,
          })}
          variant="outlined"
          value={serial || ""}
          disabled={disabled}
          onClick={handleClickOpen}
          inputProps={{
            readOnly: true,
            inputMode: "numeric",
            pattern: "[0-9]*",
            className: dark ? classes.input : "",
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" className={dark ? classes.input : ""}>
                <Typography variant="body1">{label}:</Typography>
              </InputAdornment>
            ),
          }}
        />
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
          <DialogTitle>
            <Typography variant="h2">{label}:</Typography>
          </DialogTitle>
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <TextField autoFocus margin="dense" id="serial" fullWidth variant="standard" />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>{t("common:cancel")}</Button>
              <Button type="submit">{t("common:submit")}</Button>
            </DialogActions>
          </form>
        </Dialog>
      </Box>
    </>
  );
};

export default SerialInput;
