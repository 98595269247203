import React from "react";
import _ from "lodash";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useRail, useInputRailIdsForSystem, getRailById } from "../../../../selectors/rails";
import { sortingComparer } from "../../../../utils/sort";
import { updateSelectionsWithPick } from "../../utils";
import type { FilterOptions } from "../../dto/FilterOptions";
import type { Order } from "../../dto/Order";
import SourceRailCard from "./SourceRailCard";

interface Props {
  selections: number[];
  hiddenSelections?: number[];
  railId: number;
  disabled: boolean;
  filterOptions: FilterOptions;
  onSelect: (selections: number[]) => void;
};

const filterRailIdsByTerm = ({ ids = [], searchTerm = "" }: { ids?: number[], searchTerm?: string }) => {
  const term = _.toLower(searchTerm);

  if (term.length < 1) return ids;
  return ids.length > 0 ? ids.filter((id) => {
    const rail = getRailById(id);
    const railName = _.toLower(rail.name || "");
    return (term === `${rail.number}`) || railName?.includes(term);
  }) : [];
};

const sortRailIds = (props: { ids?: number[], sortBy: FilterOptions["sortBy"], order: Order }) => {
  const { ids = [], sortBy, order } = props;

  if (ids?.length < 1) return [];
  return ids.sort((a, b) => {
    const railA = getRailById(a);
    const railB = getRailById(b);
    let valueA = railA[sortBy];
    let valueB = railB[sortBy];

    return sortingComparer(valueA, valueB, order);
  });
};

const SourceRailSelectionView = (props: Props) => {
  const { selections, hiddenSelections = [], railId, disabled, filterOptions, onSelect } = props;
  const { t } = useTranslation();
  const rail = useRail(railId);

  const availableRailIds = useInputRailIdsForSystem(rail?.systemId);
  const filteredIds = filterRailIdsByTerm({ ids: availableRailIds, searchTerm: filterOptions.searchTerm || "" }).reduce((acc, id) => {
    if (!hiddenSelections.includes(id)) {
      acc.push(id);
    };
    return acc;
  }, [] as number[]);
  const sortedRailIds = sortRailIds({ ids: filteredIds, sortBy: filterOptions.sortBy, order: filterOptions.order });

  const handleSelect = (id: number) => !disabled && onSelect(updateSelectionsWithPick({ selections, id }));

  return (
    <Grid container spacing={2}>
      {sortedRailIds?.length > 0 ? (
        sortedRailIds.map((id: number) => (
          <Grid item key={id} sm={4} md={3}>
            <SourceRailCard
              railId={id}
              selected={selections?.includes(id)}
              disabled={disabled}
              onClick={handleSelect}
            />
          </Grid>
        ))
      ) : (
        <Grid item>
          <Typography>{t("common:none")}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default SourceRailSelectionView;
