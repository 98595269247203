import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Divider, Grid, Typography } from "@mui/material";

import { useWeightUnit } from "../../selectors/uiSettings";
import BagStatusIndicator from "./BagStatusIndicator";
import WeightIndicatorBag from "./WeightIndicatorBag";
import { getBagWeightStatus } from "./utils";
import WeightThresholds from "./WeightThresholds";

const useStyles = makeStyles((theme) => ({
  weight: {
    fontSize: 50,
    color: theme.palette.success["dark"],
    "&.missing": {
      color: theme.palette.common.black,
    },
    "&.underweight": {
      color: theme.palette.warning["dark"],
    },
    "&.normal": {
      color: theme.palette.info["light"],
    },
    "&.overweight": {
      color: theme.palette.error["main"],
    },
  },
  divider: {
    borderBottomWidth: "thick",
    borderColor: theme.palette.success["dark"],

    "&.missing": {
      borderColor: theme.palette.common.black,
    },
    "&.underweight": {
      borderColor: theme.palette.warning["dark"],
    },
    "&.normal": {
      borderColor: theme.palette.info["light"],
    },
    "&.overweight": {
      borderColor: theme.palette.error["main"],
    },
  },
}));

const HorizontalWeightDisplay = (props) => {
  const classes = useStyles();
  const { showWeightGauge, showWeight, documentHeight, weightAsPercent, position, bagStatus, weight, categoryId } =
    props;
  const weightUnit = useWeightUnit();

  return (
    <Box display="flex" flexDirection="column">
      <Grid container alignItems="center" justifyContent="center">
        {showWeightGauge && (
          <Grid item sx={{ height: documentHeight / 5 }}>
            <WeightIndicatorBag position={position} percent={weightAsPercent} status={bagStatus} />
          </Grid>
        )}

        {showWeight && bagStatus !== "missing" && (
          <Grid item>
            <Typography className={`${classes.weight} ${bagStatus}`} variant="h1" align="center">
              {`${weight} ${weightUnit}`}
            </Typography>

            <Divider className={`${classes.divider} ${bagStatus}`} />

            <Typography className={`${classes.weight} ${bagStatus}`} variant="h1" align="center">
              {`${weightAsPercent}%`}
            </Typography>
          </Grid>
        )}
      </Grid>

      <WeightThresholds categoryId={categoryId} />
    </Box>
  );
};

const VerticalWeightDisplay = (props) => {
  const { showWeightGauge, showWeight, documentHeight, weightAsPercent, position, bagStatus, weight, categoryId } =
    props;
  const classes = useStyles();
  const weightUnit = useWeightUnit();

  return (
    <>
      {showWeightGauge && (
        <Grid item sx={{ height: documentHeight / 3.5 }}>
          <WeightIndicatorBag position={position} percent={weightAsPercent} status={bagStatus} />
        </Grid>
      )}

      {showWeight && bagStatus !== "missing" && (
        <Typography className={`${classes.weight} ${bagStatus}`} variant="h1" align="center">
          {`${weight} ${weightUnit} | ${weightAsPercent}%`}
        </Typography>
      )}

      <WeightThresholds categoryId={categoryId} />
    </>
  );
};

const WeightStatusWithBag = (props) => {
  const { weight = 0, category = {}, sis = {}, slingLoaded, position, showWeight, condensedVersion } = props;
  const minWeight = category.minWeight || 0;
  const targetWeight = category.targetWeight || 0;
  const maxWeight = category.maxWeight || 0;

  const bagStatus = getBagWeightStatus({ slingLoaded, sis, category, currentWeight: weight });

  const showWeightGauge = bagStatus !== "missing" && showWeight && slingLoaded && maxWeight > 0;
  const weightAsPercent = weight && targetWeight ? Math.round((weight / targetWeight) * 100) : 0;
  const documentHeight = document.body.getBoundingClientRect().height;

  return (
    <Grid
      container
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{ position: "relative", width: "100%" }}
    >
      <Grid item position={bagStatus === "missing" ? "initial" : "absolute"}>
        <BagStatusIndicator
          status={bagStatus}
          currentWeight={weight}
          minWeight={minWeight}
          targetWeight={targetWeight}
          maxWeight={maxWeight}
        />
      </Grid>

      {condensedVersion ? (
        <HorizontalWeightDisplay
          showWeightGauge={showWeightGauge}
          showWeight={showWeight}
          documentHeight={documentHeight}
          weightAsPercent={weightAsPercent}
          position={position}
          bagStatus={bagStatus}
          weight={weight}
          categoryId={category.id}
        />
      ) : (
        <VerticalWeightDisplay
          showWeightGauge={showWeightGauge}
          showWeight={showWeight}
          documentHeight={documentHeight}
          weightAsPercent={weightAsPercent}
          position={position}
          bagStatus={bagStatus}
          weight={weight}
          categoryId={category.id}
        />
      )}
    </Grid>
  );
};

export default WeightStatusWithBag;
