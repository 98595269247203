import React, { lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AboutDialog from "./components/AboutDialog";
import BagEdit from "./components/BagsSetup/BagEdit";
import Categories from "./views/Categories";
import CategoriesSetup from "./views/CategoriesSetup";
import CategoryEdit from "./components/CategoriesSetup/CategoryEdit";
import CategoryGroupsSetup from "./views/CategoryGroupsSetup";
import CustomersSetup from "./views/CustomersSetup";
import CustomerEdit from "./components/CustomersSetup/CustomerEdit";
import CustomFields from "./views/CustomFields";
import Conveyors from "./views/Conveyors";
import CustomerChange from "./views/CustomerChange";
import CustomView from "./views/CustomView";
import Customers from "./views/Customers";
import EvueWebpage from "./views/EvueWebpage";
import LaborTaskEdit from "./components/LaborTasksSetup/LaborTaskEdit";
import LaborTasksSetup from "./views/LaborTasksSetup";
import Loadable from "./ui-component/Loadable";
import LoadCells from "./views/LoadCells";
import LoginDialog from "./views/LoginDialog";
import MetricsRailMessages from "./views/MetricsRailMessages";
import NetworkDeviceEdit from "./components/DevicesSetup/NetworkDeviceEdit";
import NetworkDevicesSetup from "./views/NetworkDevicesSetup";
import PLCTroubleshootDialog from "./views/PLCTroubleshootDialog";
import ProductionLists from "./views/ProductionLists";
import ProductionPointEdit from "./components/ProductionPointsSetup/ProductionPointEdit";
import ProductionPointsSetup from "./views/ProductionPointsSetup";
import Rails from "./views/Rails";
import RailSystemControlsDialog from "./views/RailSystemControlsDialog";
import RailEdit from "./components/RailsSetup/RailEdit";
import RailsSetup from "./views/RailsSetup";
import RoleEdit from "./components/RolesSetup/RoleEdit";
import RolesSetup from "./views/RolesSetup";
import BagsSetup from "./views/BagsSetup";
import UserEdit from "./components/UsersSetup/UserEdit";
import UsersSetup from "./views/UsersSetup";
import ServerTroubleshootDialog from "./views/ServerTroubleshootDialog";
import ServoLiftWebpage from "./views/ServoLiftWebpage";
import SlingInputStation from "./views/SlingInputStation";
import SLXAreasView from "./features/SLX/SLXAreasView";
import SLXProducerViewView from "./features/SLX/SLXProducerViewView";
import SLXWebpage from "./views/SLXWebpage";
import SortAssignments from "./views/SortAssignments";
import StaticBagInterface from "./views/StaticBagInterface";
import StorageAssignments from "./views/StorageAssignments";
import StorageManager from "./views/StorageManager";
import SupertrackDischarger from "./views/SupertrackDischarger";
import SystemSetup from "./views/SystemSetup";
import TechnicianPortal from "./views/TechnicianPortal";

import {
  CATEGORIES,
  CONVEYORS,
  CUSTOMER_CHANGES,
  CUSTOM_VIEW,
  CUSTOMERS,
  DISCHARGER,
  EVUE_WEBPAGE,
  LOAD_CELLS,
  METRICS_MESSAGES_LOG,
  SETUP_NETWORK_DEVICES,
  OVERVIEW,
  PANELS,
  PRODUCTION_LISTS,
  RAILS,
  SERVO_LIFT_CONTROLS,
  SETUP_CATEGORIES,
  SETUP_CUSTOMERS,
  SETUP_LABOR_TASKS,
  SETUP_PRODUCTION_POINTS,
  SETUP_ROLES,
  SETUP_BAGS,
  SETUP_CATEGORY_GROUPS,
  SETUP_RAILS,
  SETUP_SYSTEM,
  SETUP_USERS,
  SLING_INPUT_STATION,
  SLX_WEBPAGE,
  SORT_ASSIGNMENTS,
  STATIC_BAG,
  STORAGE_ASSIGNMENTS,
  STORAGE_MANAGER,
  TECHNICIAN_PORTAL,
} from "./constants/Paths";

const Overview = Loadable(lazy(() => import("./features/overview/Overview")));
const PanelsIndex = Loadable(lazy(() => import("./views/PanelsIndex")));
const PanelShow = Loadable(lazy(() => import("./views/PanelShow")));

const Routes = () => {
  return (
    <>
      <LoginDialog />
      <RailSystemControlsDialog />
      <ServerTroubleshootDialog />
      <PLCTroubleshootDialog />
      <AboutDialog />

      <Switch>
        <Redirect exact from="/" to={OVERVIEW} />
        <Route exact path={CATEGORIES} component={Categories} />
        <Route exact path={CONVEYORS} component={Conveyors} />
        <Route path={`${CUSTOM_VIEW}/*`} component={CustomView} />
        <Route exact path={CUSTOMERS} component={Customers} />
        <Route exact path={PANELS} component={PanelsIndex} />
        <Route exact path={`${PANELS}/:id?`} component={PanelShow} />
        <Route exact path={`${PRODUCTION_LISTS}/:id?`} component={ProductionLists} />
        <Route exact path={LOAD_CELLS} component={LoadCells} />
        <Route path={METRICS_MESSAGES_LOG} component={MetricsRailMessages} />
        <Route exact path={`${SETUP_NETWORK_DEVICES}/:id`} component={NetworkDeviceEdit} />
        <Route exact path={SETUP_NETWORK_DEVICES} component={NetworkDevicesSetup} />
        <Route exact path={`${OVERVIEW}`} component={Overview} />
        <Route exact path={`${CUSTOMER_CHANGES}/:id`} component={CustomerChange} />
        <Route exact path={`${SLING_INPUT_STATION}/:ids/:layout?`} component={SlingInputStation} />
        <Route exact path={`${STATIC_BAG}/:id`} component={StaticBagInterface} />
        <Route exact path={RAILS} component={Rails} />
        <Route path={`${EVUE_WEBPAGE}/*`} component={EvueWebpage} />
        <Route exact path={`${SERVO_LIFT_CONTROLS}/:railId`} component={ServoLiftWebpage} />
        <Route exact path={SETUP_CATEGORIES} component={CategoriesSetup} />
        <Route path={`${SETUP_CATEGORIES}/:id`} component={CategoryEdit} />
        <Route path={SETUP_CATEGORY_GROUPS} component={CategoryGroupsSetup} />
        <Route exact path={SETUP_CUSTOMERS} component={CustomersSetup} />
        <Route path={`${SETUP_CUSTOMERS}/:id`} component={CustomerEdit} />
        <Route path={`${SETUP_LABOR_TASKS}/:id`} component={LaborTaskEdit} />
        <Route path={SETUP_LABOR_TASKS} component={LaborTasksSetup} />
        <Route exact path={SETUP_PRODUCTION_POINTS} component={ProductionPointsSetup} />
        <Route path={`${SETUP_PRODUCTION_POINTS}/:id`} component={ProductionPointEdit} />
        <Route exact path={SETUP_RAILS} component={RailsSetup} />
        <Route exact path={`${SETUP_RAILS}/:id`} component={RailEdit} />
        <Route exact path={SETUP_ROLES} component={RolesSetup} />
        <Route path={`${SETUP_ROLES}/:id`} component={RoleEdit} />
        <Route exact path={SETUP_BAGS} component={BagsSetup} />
        <Route path={`${SETUP_BAGS}/:id`} component={BagEdit} />
        <Route exact path={SETUP_USERS} component={UsersSetup} />
        <Route path={`${SETUP_USERS}/:id`} component={UserEdit} />
        <Route path={SETUP_SYSTEM} component={SystemSetup} />
        <Route path={SORT_ASSIGNMENTS} component={SortAssignments} />
        <Route path={`${SLX_WEBPAGE}/*`} render={() => <SLXWebpage />} />
        <Route path={`${STORAGE_ASSIGNMENTS}/:systemType?`} component={StorageAssignments} />
        <Route path={`${STORAGE_MANAGER}/:systemId`} component={StorageManager} />
        <Route path={`${DISCHARGER}/:id`} component={SupertrackDischarger} />
        <Route path={`/slx/areas`} component={SLXAreasView} />
        <Route path={"/slx/producer_views/:id"} component={SLXProducerViewView} />
        <Route path={`/custom_fields`} component={CustomFields} />
        <Route path={TECHNICIAN_PORTAL} component={TechnicianPortal} />
      </Switch>
    </>
  );
};

export default Routes;
