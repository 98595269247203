import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import CloseIcon from "@mui/icons-material/CloseSharp";
import { useTranslation } from "react-i18next";
import { updateSystemParameter } from "../../api/SystemParametersApi";
import { useCurrentRole } from "../../selectors/useRole";
import { useDaysPerMaintenance, useCyclesPerMaintenance, useSystemParameterId } from "../../selectors/uiSettings";
import FormNumberField from "../SetupPages/FormNumberField";
import SaveButton from "../SaveButton";

const MaintenanceSettingsDialog = ({ open, onClose }) => {
  const { t } = useTranslation();

  const currentRole = useCurrentRole();

  const initialCyclesPerMaint = useCyclesPerMaintenance() || "";
  const [cyclesPerMaint, setCyclesPerMaint] = useState(initialCyclesPerMaint);

  const initialDaysPerMaint = useDaysPerMaintenance() || "";
  const [daysPerMaint, setDaysPerMaint] = useState(initialDaysPerMaint);

  const systemParameterId = useSystemParameterId();

  const handleSubmit = () => {
    updateSystemParameter({
      systemParameterId,
      cyclesPerMaintenance: cyclesPerMaint,
      daysPerMaintenance: daysPerMaint,
    });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"sm"} fullWidth={true}>
      <DialogTitle>
        <Typography variant="h3">{t("slingInputStation:settings")}</Typography>

        <IconButton sx={{ position: "absolute", right: 8, top: 8, color: "grey.500" }} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <Divider />

      <DialogContent>
        <Grid container flexDirection="column">
          <Grid item sx={{ maxWidth: 300, mb: 2, mr: 2 }}>
            <FormNumberField
              fieldName="cyclesPerMaint"
              label={t("slingInputStation:loops_before_maint_due")}
              currentValues={{ cyclesPerMaint }}
              disabled={!currentRole.canEditBarcodes}
              onFieldChange={(_, newValue) => setCyclesPerMaint(newValue)}
            />
          </Grid>

          <Grid item sx={{ maxWidth: 300 }}>
            <FormNumberField
              fieldName="daysPerMaint"
              label={t("slingInputStation:days_before_maint_due")}
              currentValues={{ daysPerMaint }}
              disabled={!currentRole.canEditBarcodes}
              onFieldChange={(_, newValue) => setDaysPerMaint(newValue)}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <Divider />

      <DialogActions sx={{ display: "flex", flexDirection: "flex-end" }}>
        <SaveButton disabled={!currentRole.canEditBarcodes} onClick={handleSubmit}>
          {t("common:submit")}
        </SaveButton>
      </DialogActions>
    </Dialog>
  );
};

const CompMaintSettingsButton = () => {
  const { t } = useTranslation();

  const [openSettings, setOpenSettings] = useState(false);

  return (
    <>
      <FormControlLabel
        control={
          <IconButton size="large" onClick={() => setOpenSettings(true)}>
            <SettingsIcon sx={{ color: "info.light", fontSize: 50 }} />
          </IconButton>
        }
        labelPlacement="top"
        label={t("slingInputStation:settings")}
      />

      {openSettings && <MaintenanceSettingsDialog open={openSettings} onClose={() => setOpenSettings(false)} />}
    </>
  );
};

export default CompMaintSettingsButton;
