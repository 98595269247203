import _ from "lodash";
import store from "../store";
import { showAlertError, showAlertSuccess } from "../actions/alertActions";
import Client from "./Client.js";
import i18n from "../i18n";

export const updateDevice = ({ deviceId, successCB, failureCB, ...rest }) => {
  Client.put(`control_api/v1/devices/${deviceId}`, { ...rest })
    .then((response) => {
      successCB();
      return store.dispatch(showAlertSuccess(i18n.t("common:success")));
    })
    .catch((error) => {
      const errorsByKey = _.get(error, "response.data", "API Error");
      failureCB(errorsByKey);
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorsByKey}`));
    });
};

export const rebootDevice = ({ deviceId, successCB, failureCB }) => {
  Client.get(`control_api/v1/devices/${deviceId}/reboot`)
    .then((response) => {
      successCB && successCB();
      store.dispatch(showAlertSuccess(i18n.t("common:success")));
    })
    .catch((error) => {
      failureCB && failureCB();
      const errorMessage = JSON.stringify(_.get(error, "response.data.error", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const getRecentUserActivities = ({ deviceId, successCB, failureCB }) => {
  Client.get(`control_api/v1/devices/${deviceId}/recent_user_activities`)
    .then((response) => successCB && successCB(response.data))
    .catch((error) => {
      failureCB && failureCB();

      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};
