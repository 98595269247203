import { configureStore } from "@reduxjs/toolkit";
import reducer from "./reducers";
import { vecturaApi } from "./features/overview/services/vectura";
import { slxPrintApi } from "./features/SLX/slxPrint";
import { eVueApi } from "./services/eVue";

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([
    vecturaApi.middleware,
    slxPrintApi.middleware,
    eVueApi.middleware,
  ])
});

export default store;

// Infer the 'RootState' and 'AppDispatch' types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
