import React from "react";
import _ from "lodash";
import { useSelector, shallowEqual } from "react-redux";
import { Card, Grid, Typography, CardHeader, Divider, CardContent, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import NumpadField from "../components/NumpadField";
import { useCurrentRole } from "../selectors/useRole";

import { setSpeed } from "../api/ConveyorAPI";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  titleBar: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const Conveyors = () => {
  const conveyors = useSelector((state) => state.conveyors, shallowEqual);
  const activeConveyors = Object.values(conveyors).filter((x) => x.active);
  const classes = useStyles();
  const { t } = useTranslation();
  const currentRole = useCurrentRole();

  return (
    <div className={classes.root}>
      <Card className={classes.titleBar}>
        <Typography variant="h1" gutterBottom>
          {t("conveyors:conveyors")}
        </Typography>
      </Card>

      <div>
        <Grid container spacing={2}>
          {activeConveyors.map((conveyor) => {
            const updateSpeed = (newSpeed) => setSpeed(conveyor.id, _.clamp(newSpeed, 0, conveyor.gearRatio));

            return (
              <Grid item key={conveyor.id} lg={2} md={3} xs={4}>
                <Card>
                  <CardHeader title={conveyor.name} />
                  <Divider />

                  <CardContent>
                    <NumpadField
                      value={conveyor.speed}
                      submitValue={updateSpeed}
                      required={true}
                      disabled={!currentRole.canOperateConveyors}
                    >
                      <TextField
                        className={classes.textField}
                        label={t("conveyors:speed")}
                        variant="outlined"
                        value={conveyor.speed}
                        type="number"
                        placeholder={t("common:required")}
                        disabled={!currentRole.canOperateConveyors}
                        InputLabelProps={{
                          shrink: true,
                          style: { fontSize: 30 },
                        }}
                        InputProps={{
                          readOnly: true,
                          style: { fontSize: 50 },
                        }}
                      />
                    </NumpadField>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </div>
  );
};

export default Conveyors;
