import { eVueApi } from "../../../services/eVue";
import type { StorageRuleset } from "../dto/StorageRuleset";

export const storageRulesetsApi = eVueApi.injectEndpoints({
  endpoints: (builder) => ({
    getStorageRulesets: builder.query<StorageRuleset[], void>({
      query: () => "/storage_rulesets",
      providesTags: (result) => result ?
        [
          ...result.map(({ id }) => ({ type: "StorageRulesets", id } as const)),
          { type: "StorageRulesets", id: "LIST" },
        ]
      :
        [{ type: "StorageRulesets", id: "LIST" }],
    }),

    addStorageRuleset: builder.mutation<StorageRuleset, Partial<StorageRuleset>>({
      query(body) {
        return {
          url: "/storage_rulesets",
          method: "POST",
          body,
        }
      },
      invalidatesTags: [{ type: "StorageRulesets", id: "LIST" }],
    }),

    updateStorageRuleset: builder.mutation<StorageRuleset, StorageRuleset>({
      query({ id, ...body }) {
        return {
          url: `/storage_rulesets/${id}`,
          method: "PUT",
          body: body,
        };
      },
      invalidatesTags: (result, error, { id }) => [{ type: "StorageRulesets", id }],
    }),

    deleteStorageRulesets: builder.mutation({
      query(id) {
        return {
          url: `/storage_rulesets/${id}`,
          method: "DELETE",
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: "StorageRulesets", id }],
    })
  })
});


// SELECTORS
export const getStorageRulesetForRailId = (state: StorageRuleset[], railId: number) => {
  return state.find((sr) => sr.railId === railId);
};

export const getRailIdsForStorageRulesets = (state: StorageRuleset[]) => {
  return state?.map((sr) => sr.railId);
};


export const {
  useGetStorageRulesetsQuery,
  useAddStorageRulesetMutation,
  useUpdateStorageRulesetMutation,
  useDeleteStorageRulesetsMutation,
} = storageRulesetsApi;
