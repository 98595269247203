import React from "react";
import _ from "lodash";
import {
  TableContainer,
  Table,
  TableBody,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Loader from "../../../../components/Loader";
import { useCurrentRole } from "../../../../selectors/useRole";
import type { System } from "../../../../dto/eVue/System";
import useStrgRailIdsWithOverrides from "../../hooks/useStrgRailIdsWithOverrides";
import StorageRulesetDataWrapper from "../../dataWrappers/StorageRulesetDataWrapper";
import { useGetStorageRulesetsQuery } from "../../services/storageRulesets";
import TableHeading from "./TableHeading";
import RailStorageDetails from "./RailStorageDetails";
import RailOverrideSelection from "./RailOverrideSelection";

interface Props {
  system: System;
};

const OverridesView = ({ system }: Props) => {
  const currentRole = useCurrentRole();
  const { t } = useTranslation();
  const { data, isLoading } = useGetStorageRulesetsQuery();
  const railIdsWithOverrides = useStrgRailIdsWithOverrides(system.id, data);

  return (
    <div>
      <Loader open={isLoading} message={t("common:loading")} transitionDuration={0} />

      {data && (
        <TableContainer>
          <Table stickyHeader size="small">
            <TableHeading>
              <RailOverrideSelection systemId={system?.id} disabled={!currentRole.canOperatePicklists} />
            </TableHeading>

            <TableBody sx={{ backgroundColor: "common.white" }}>
              {railIdsWithOverrides.map((railId: number) => (
                <StorageRulesetDataWrapper key={railId} railId={railId} data={data}>
                  {(rail, storageRuleset) => <RailStorageDetails storageRuleset={storageRuleset} rail={rail} />}
                </StorageRulesetDataWrapper>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  )
}

export default OverridesView;
