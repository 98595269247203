import React from "react";
import { Avatar, Box, Typography } from "@mui/material";
import TaskIcon from "@mui/icons-material/Assignment";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  main: {
    width: 200,
    borderWidth: (props) => (props.highlight ? "3px" : "1px"),
    borderStyle: "solid",
    borderColor: (props) => (props.highlight ? theme.palette.info["light"] : theme.palette.grey[200]),
    borderRadius: 5,
    padding: 5,
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap",
  },
  imageWrapper: {
    marginRight: theme.spacing(1),
  },
  image: {
    backgroundColor: theme.palette.info["light"],
  },
}));

const SlimLaborTaskFlagCard = ({ laborTask, highlight, onClick }) => {
  const classes = useStyles({ highlight });
  return (
    <Box className={classes.main} onClick={onClick}>
      <Avatar variant="rounded" className={classes.imageWrapper}>
        {laborTask.metricsPhotoUrl ? <img className={classes.image} src={laborTask.metricsPhotoUrl} /> : <TaskIcon />}
      </Avatar>

      <Typography>{laborTask.name}</Typography>
    </Box>
  );
};

export default SlimLaborTaskFlagCard;
