import React from "react";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  main: {
    maxWidth: 200,
    backgroundColor: theme.palette.success["dark"],

    "&:disabled": {
      backgroundColor: theme.palette.grey[200],
    },
    "&:hover": {
      backgroundColor: theme.palette.success["main"],
    },
  },
}));

const SaveButton = (props) => {
  const { id, onClick, disabled, startIcon, disableElevation = true, children, ...rest } = props;
  const classes = useStyles();

  return (
    <Button
      id={id}
      classes={{ root: classes.main }}
      disabled={disabled}
      variant="contained"
      startIcon={startIcon}
      disableElevation={disableElevation}
      onClick={onClick}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default SaveButton;
