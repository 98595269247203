import React, { useEffect } from "react";
import _ from "lodash";
import { Button } from "@mui/material";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { useTranslation } from "react-i18next";
import CheckIcon from "@mui/icons-material/CheckCircleOutlineSharp";
import { showMessage, selectSelectedSortConfig } from "../redux/sortConfigurationsSlice";
import { useCurrentRole } from "../../../selectors/useRole";
import { useUpdateSortConfigurationMutation } from "../services/sortConfigurations";
import { showAlertError } from "../../../actions";

const SaveButton = () => {
  const dispatch = useAppDispatch();
  const selectedSortConfiguration = useAppSelector(selectSelectedSortConfig, _.isEqual);
  const { t } = useTranslation();
  const role = useCurrentRole();

  const [updateSortConfiguration, { isLoading, isError, error }] = useUpdateSortConfigurationMutation();
  useEffect(() => {
    if (error) {
      const responseError = error as { data: { error?: string }};
      dispatch(showAlertError(`${t("common:error")}: ${responseError.data?.error}`))
    };
  }, [isError])

  return (
    <Button
      sx={{
        maxWidth: 200,
        backgroundColor: "success.dark",

        "&:disabled": {
          backgroundColor: "grey.200",
        },
        "&:hover": {
          backgroundColor: "success.main",
        },
      }}
      variant="contained"
      disabled={!selectedSortConfiguration || !selectedSortConfiguration?.dirty || !role.canEditSortConfigs || isLoading}
      startIcon={<CheckIcon />}
      disableElevation={true}
      onClick={() => {
        dispatch(showMessage(t("sortAssignments:saving_sort_configuration")));
        if (selectedSortConfiguration) {
          updateSortConfiguration({
            id: selectedSortConfiguration?.id,
            name: selectedSortConfiguration?.name,
            systemId: selectedSortConfiguration?.systemId,
            slingAssignments: selectedSortConfiguration?.slingAssignments,
          });
        }
      }}
    >
      {t("common:save")}
    </Button>
  );
};

export default SaveButton;
