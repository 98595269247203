import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/CloseSharp";
import CheckIcon from "@mui/icons-material/CheckCircleOutlineSharp";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  confirmButton: {
    backgroundColor: theme.palette.success.main,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
    },
  },
  dialogContentText: {
    display: "flex",
    alignItems: "center",
  },
  infoIcon: {
    width: "128px",
    height: "auto",
    marginRight: theme.spacing(2),
    color: theme.palette.info.main,
  },
}));

const ManSendConfirmDialog = ({ open, onClose, onConfirm }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"sm"} fullWidth={true}>
      <DialogTitle>
        <Typography variant="h3">{t("common:confirm_selection")}</Typography>

        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <DialogContentText className={classes.dialogContentText} variant="body1">
          <InfoIcon className={classes.infoIcon} />
          {t("railBar:are_you_sure_send_away")}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button variant="contained" autoFocus onClick={onClose} startIcon={<CloseIcon />}>
          {t("common:cancel")}
        </Button>

        <Button variant="contained" className={classes.confirmButton} onClick={onConfirm} startIcon={<CheckIcon />}>
          {t("common:yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ManSendConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
};

export default ManSendConfirmDialog;
