import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { Button, FormControlLabel } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AddIcon from "@mui/icons-material/AddCircleOutlineSharp";
import { useRailFullQuantity, useRail } from "../../selectors/rails";
import { getRailVisitsCountForRail } from "../../selectors/railVisits";
import { useCurrentRole } from "../../selectors/useRole";
import { useSlingInputStation } from "../../selectors/slingInputStations";
import { addSlingToSis, addBagToMaintLiftSis } from "../../api/SlingInputStationApi";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "50%",
    backgroundColor: theme.palette.success["dark"],

    "&:disabled": {
      backgroundColor: theme.palette.grey[200],
    },
    "&:hover:enabled": {
      backgroundColor: theme.palette.success["main"],
    },
  },
  xLargeButton: {
    padding: theme.spacing(2),

    "& #start-icon": {
      fontSize: 65,
    },
  },
}));

const AddSlingButtonContent = (props) => {
  const { sis, barcodeId, barcodeNotes, size = "medium", disableElevation = true, weight } = props;
  const classes = useStyles();
  const rail = useRail(sis.railId) || {};
  const { canControlInputLifts } = useCurrentRole();
  const { t } = useTranslation();

  const handleConfirm = () => {
    return rail.maintenance
      ? addBagToMaintLiftSis({ sisId: sis.id, barcodeId, barcodeNotes })
      : addSlingToSis({ sisId: sis.id, weight });
  };

  return (
    <FormControlLabel
      control={
        <Button
          id="add-sling-to-sis"
          className={clsx({
            [classes.button]: true,
            [classes.xLargeButton]: size === "xLarge",
          })}
          size={size === "xLarge" ? "large" : size}
          disabled={!canControlInputLifts}
          variant="contained"
          disableElevation={disableElevation}
          onClick={handleConfirm}
        >
          <AddIcon id="start-icon" />
        </Button>
      }
      label={t(rail.maintenance ? "slingInputStation:add_bag" : "slingInputStation:add_load")}
      labelPlacement="top"
    />
  );
};

const ConciseAddSlingButton = ({ sisId, ...rest }) => {
  const sis = useSlingInputStation(sisId) || {};
  const railVisitCount = useSelector((state) => getRailVisitsCountForRail(state, sis.railId)) || 0;
  const fullQuantity = useRailFullQuantity(sis.railId);

  if (railVisitCount >= fullQuantity) return null;
  return <AddSlingButtonContent sis={sis} {...rest} />;
};

ConciseAddSlingButton.propTypes = {
  sisId: PropTypes.number,
  barcodeId: PropTypes.number,
  size: PropTypes.oneOf(["small", "medium", "large", "xLarge"]),
  disabled: PropTypes.bool,
};

export default ConciseAddSlingButton;
