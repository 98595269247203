import { useState, useEffect } from "react";
import { isEqual } from "lodash";
import PropTypes from "prop-types";

const FormEditor = ({ originalValues, onSubmit, onDelete, onCancel, children }) => {
  const [initialValues, setInitialValues] = useState(null);
  const [currentValues, setCurrentValues] = useState(null);

  if (!initialValues) {
    setInitialValues({ ...originalValues });
  }

  if (!currentValues) {
    setCurrentValues({ ...originalValues });
  }

  const [submitting, setSubmitting] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [canceling, setCanceling] = useState(false);
  const [errors, setErrors] = useState({});

  const handleSetValue = (field, value) => setCurrentValues({ ...currentValues, [field]: value });

  const handleSetArrayValue = ({ field, operation, index, value }) => {
    let originalValuesCopy = currentValues[field]?.slice();
    switch (operation) {
      case "add":
        if (originalValuesCopy) {
          originalValuesCopy.push(value);
        } else {
          originalValuesCopy = [value];
        }
        break;

      case "remove":
        originalValuesCopy.splice(index, 1);
        break;

      default:
        originalValuesCopy[index] = value;
        break;
    }
    return handleSetValue(field, originalValuesCopy);
  };

  const handleSubmit = () => setSubmitting(true);
  useEffect(() => {
    if (submitting) {
      onSubmit({
        initialValues,
        currentValues,
        actions: {
          finishSubmit: (submitFailed) => {
            setSubmitting(false);
            if (!submitFailed) {
              setInitialValues({ ...currentValues });
            }
            setErrors({});
          },
          catchErrors: (errors) => {
            let formattedErrors = {};
            if (errors) {
              Object.keys(errors).forEach((fieldName) => {
                const errorsForFieldName = errors[fieldName];
                formattedErrors[fieldName] = errorsForFieldName[0];
              });
              setErrors(formattedErrors);
            }
          },
        },
      });
    }
  }, [submitting]);

  const handleDelete = () => setDeleting(true);
  useEffect(() => {
    if (deleting) {
      onDelete({
        actions: {
          finishDelete: () => setDeleting(false),
        },
      });
    }
  }, [deleting]);

  const handleCancel = () => setCanceling(true);
  useEffect(() => {
    if (canceling) {
      onCancel({
        actions: {
          finishCancel: () => {
            setCurrentValues({ ...initialValues });
            setCanceling(false);
          },
        },
      });
    }
  }, [canceling]);

  if (!currentValues) return null;
  return children({
    currentValues,
    dirtyForm: !isEqual(currentValues, initialValues),
    errors,
    isSubmitting: submitting,
    isDeleting: deleting,
    isCanceling: canceling,
    setFieldValue: handleSetValue,
    setArrayFieldValue: handleSetArrayValue,
    handleSubmit,
    handleDelete,
    handleCancel,
  });
};

FormEditor.propTypes = {
  originalValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
  onCancel: PropTypes.func,
  children: PropTypes.func,
};

export default FormEditor;
