import React from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import { useSlingInputStation } from "../../../selectors/slingInputStations";
import { useRail } from "../../../selectors/rails";
import { useCurrentRole } from "../../../selectors/useRole";
import { useCategory } from "../../../selectors/categories";
import { manualSend, railOutputEnabled } from "../../../api/RailApi";
import { assignCategoryToSis, assignCustomerToSis } from "../../../api/SlingInputStationApi";
import CustomerFlagSelector from "../../../components/FlagSelectors/CustomerFlagSelector";
import CategoryFlagSelector from "../../../components/FlagSelectors/CategoryFlagSelector";
import CircleIndicator from "../../../components/CircleIndicator";
import { IndicatorStatusTypes } from "../../panels/enum/IndicatorStatusTypes";
import ConciseManSendButton from "./ConciseManSendButton";
import WeightStatusWithConveyor from "./WeightStatusWithConveyor";

const generateDisableReasons = ({ sis, rail, canOperateConveyors }) => {
  const disabledReasons = [];
  if (!canOperateConveyors) disabledReasons.push(i18n.t("slingInputStation:insufficient_permissions"));
  if (!rail.outputEnabled) disabledReasons.push(i18n.t("slingInputStation:output_disabled"));
  if (sis?.overweightValidation && sis?.status === "overweight") {
    disabledReasons.push(i18n.t("slingInputStation:overweight"));
  }
  if (sis?.underweightValidation && sis?.status === "underweight") {
    disabledReasons.push(i18n.t("slingInputStation:underweight"));
  }

  return disabledReasons;
};

const WeighingConveyorSIS = ({ sisId }) => {
  const { t } = useTranslation();
  const { canOperateConveyors } = useCurrentRole();

  const sis = useSlingInputStation(sisId) || {};
  const rail = useRail(sis?.railId) || {};
  const categoryId = sis?.category1Id;
  const category = useCategory(categoryId);
  const customerId = sis?.customer1Id;
  const weight = sis?.weight1;

  const disabledReasons = generateDisableReasons({ sis, rail, canOperateConveyors });

  return (
    <Paper square={true} elevation={0} sx={{ width: "100%", height: "100%" }}>
      <Box display="flex" flexDirection="column" padding={2}>
        <Typography variant="h1">{rail?.name}</Typography>
        <Typography variant="overline">
          {t("railStates:state_state", {
            state: t(`railStates:${rail?.plcState}`),
          })}
        </Typography>

        <Box display="flex" justifyContent="space-between">
          <CircleIndicator
            label={t("common:out")}
            status={rail?.outputEnabled ? IndicatorStatusTypes.ON : IndicatorStatusTypes.OFF}
            onClick={() => railOutputEnabled({ id: rail?.id, outputEnabled: rail?.outputEnabled })}
          />

          <ConciseManSendButton
            railIoTypeId={rail?.railIoTypeId}
            label={t("common:send_load")}
            size="xLarge"
            disabled={disabledReasons?.length > 0}
            disabledReasons={disabledReasons}
            onClick={() => manualSend(rail?.id)}
          />
        </Box>
      </Box>

      <Divider />

      <Grid container direction="column" alignItems="center" sx={{ padding: 2 }}>
        <Grid item sx={{ width: "100%" }}>
          <WeightStatusWithConveyor weight={weight} sis={sis} category={category} />
        </Grid>

        <Grid item container justifyContent="center" spacing={2} sx={{ pt: 4, pb: 2 }}>
          <Grid item>
            <Typography variant="h5">{t("common:category")}</Typography>
            <CategoryFlagSelector
              selectedId={categoryId}
              size="large"
              onSelectionClick={(catId) => assignCategoryToSis({ sisId, categoryId: catId, position: 1 })}
              disabled={!canOperateConveyors}
            />
          </Grid>

          <Grid item>
            <Typography variant="h5">{t("common:customer")}</Typography>
            <CustomerFlagSelector
              selectedId={customerId}
              size="large"
              handleSelect={(custId) => assignCustomerToSis({ sisId, customerId: custId, position: 1 })}
              disabled={!canOperateConveyors}
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default WeighingConveyorSIS;
