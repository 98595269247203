import * as actions from "../actions/types";

const initialState = {
  batches: false,
  categories: false,
  conveyors: false,
  custChangeStations: false,
  customers: false,
  machines: false,
  machineVisits: false,
  rails: false,
  railIoTypes: false,
  railSystem: true,
  railVisits: false,
  roles: false,
  serverStatus: false,
  slingInputStations: false,
  sortStations: false,
  systems: false,
  uiSettings: false,
  users: false,
};

export default function loaderReducer(state = initialState, action) {
  switch (action.type) {
    case actions.INITIALIZE_BATCHES:
      return { ...state, batches: true };
    case actions.INITIALIZE_CATEGORIES:
      return { ...state, categories: true };
    case actions.INITIALIZE_CONVEYORS:
      return { ...state, conveyors: true };
    case actions.INITIALIZE_CUST_CHANGE_STATIONS:
      return { ...state, custChangeStations: true };
    case actions.INITIALIZE_CUSTOMERS:
      return { ...state, customers: true };
    case actions.INITIALIZE_MACHINES:
      return { ...state, machines: true };
    case actions.INITIALIZE_MACHINE_VISITS:
      return { ...state, machineVisits: true };
    case actions.INITIALIZE_RAILS:
      return { ...state, rails: true };
    case actions.INITIALIZE_RAIL_IO_TYPES:
      return { ...state, railIoTypes: true };
    case actions.INITIALIZE_RAIL_SYSTEM:
      return { ...state, railSystem: true };
    case actions.INITIALIZE_RAIL_VISITS:
      return { ...state, railVisits: true };
    case actions.INITIALIZE_ROLES:
      return { ...state, roles: true };
    case actions.INITIALIZE_SERVER_STATUS:
      return { ...state, serverStatus: true };
    case actions.INITIALIZE_SLING_INPUT_STATIONS:
      return { ...state, slingInputStations: true };
    case actions.INITIALIZE_SORT_STATIONS:
      return { ...state, sortStations: true };
    case actions.INITIALIZE_SYSTEMS:
      return { ...state, systems: true };
    case actions.INITIALIZE_UI_SETTINGS:
      return { ...state, uiSettings: true };
    case actions.INITIALIZE_USERS:
      return { ...state, users: true };
    default:
      return state;
  }
}
