import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Button, FormControlLabel } from "@mui/material";
import clsx from "clsx";
import PublishIcon from "@mui/icons-material/Publish";
import { captureWeightSingleFloorScale, captureWeightDoubleFloorScale } from "../../api/SlingInputStationApi";
import { useSlingInputStation } from "../../selectors/slingInputStations";
import { useCurrentRole } from "../../selectors/useRole";
import { FLOOR_SCALE_SINGLE } from "./weightInputTypes";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "50%",
  },
  xLargeButton: {
    padding: theme.spacing(2),
    fontSize: 40,

    "& #start-icon": {
      fontSize: 40,
    },
  },
}));

const ConciseCaptureWeightButton = ({ sisId, position, size }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { canControlInputLifts } = useCurrentRole();
  const sis = useSlingInputStation(sisId);

  return (
    <FormControlLabel
      control={
        <Button
          className={clsx({
            [classes.button]: true,
            [classes.xLargeButton]: size === "xLarge",
          })}
          size={size === "xLarge" ? "large" : size}
          disableElevation={true}
          variant="contained"
          disabled={!canControlInputLifts}
          onClick={() =>
            sis?.weightInputType === FLOOR_SCALE_SINGLE
              ? captureWeightSingleFloorScale({ sisId, position })
              : captureWeightDoubleFloorScale({ sisId, position })
          }
        >
          <PublishIcon id="start-icon" />
        </Button>
      }
      label={t("slingInputStation:capture_weight")}
      labelPlacement="top"
    />
  );
};

export default ConciseCaptureWeightButton;
