import React from "react";
import _ from "lodash";
import { Grid, Typography } from "@mui/material";
import CategoryIcon from "@mui/icons-material/Category";
import { useTranslation } from "react-i18next";
import { sortingComparer } from "../../../../utils/sort";
import { useCategories } from "../../../../selectors/categories";
import FlagCardSlim from "../../../../components/FlagSelectors/FlagCardSlim";
import type { Category } from "../../../../dto/eVue/Category";
import { updateSelectionsWithPick } from "../../utils";
import type { Order } from "../../dto/Order";
import type { StorageRuleset } from "../../dto/StorageRuleset";
import type { FilterOptions } from "../../dto/FilterOptions";

interface Props {
  selections: number[];
  hiddenSelections?: number[];
  disabled: boolean;
  filterOptions: FilterOptions;
  onSelect: (selections: number[]) => void;
};

const filterCategoriesByTerm = (props: { categories?: Category[], searchTerm?: string }) => {
  const { categories = [], searchTerm = "" } = props;
  const term = _.toLower(searchTerm);

  if (term.length < 1) return categories;
  return categories.length > 0 ? categories.filter((category) => {
    const name = _.toLower(category.name || "");
    return (term === `${category.number}`) || name?.includes(term);
  }) : [];
};

const sortCategories = (props: { categories?: Category[], sortBy: FilterOptions["sortBy"], order: Order }) => {
  const { categories = [], sortBy, order } = props;

  if (categories?.length < 1) return [];
  return categories.sort((a, b) => {
    const valueA = a[sortBy];
    const valueB = b[sortBy];
    return sortingComparer(valueA, valueB, order);
  });
};

const CategorySelectionView = ({ selections, hiddenSelections = [], disabled, filterOptions, onSelect }: Props) => {
  const { t } = useTranslation();

  const allCategories = useCategories();
  const filteredCategories =
    filterCategoriesByTerm({
      categories: allCategories,
      searchTerm: filterOptions.searchTerm,
    }).reduce((acc, category) => {
      if (!hiddenSelections.includes(category.id)) {
        acc.push(category);
      };
      return acc;
    }, [] as Category[]);
  const sortedCategories = sortCategories({
    categories: filteredCategories,
    sortBy: filterOptions.sortBy,
    order: filterOptions.order,
  });

  const handleSelect = (id: number) => !disabled && onSelect(updateSelectionsWithPick({ selections, id }));

  return (
    <Grid container spacing={2}>
      {sortedCategories?.length > 0 ? (
        sortedCategories.map((cat) => (
          <Grid item key={cat.id} sm={4} md={3}>
            <FlagCardSlim
              imageUrl={cat.imageUrl}
              imageIcon={<CategoryIcon />}
              color={cat.color}
              number={cat.number}
              name={cat.name}
              disabled={disabled}
              border={true}
              highlight={selections?.includes(cat?.id)}
              onClick={() => handleSelect(cat.id)}
            />
          </Grid>
        ))
      ) : (
        <Grid item>
          <Typography>{t("common:none")}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default CategorySelectionView;
