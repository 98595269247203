import React, { ChangeEvent } from "react";
import { Box, MenuItem, SxProps, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { updateSis } from "../../../api/SlingInputStationApi";
import { useGetSlingTypesQuery } from "../services/slingTypes";
import useGetSlingTypeId from "../hooks/useGetSlingTypeId";

interface Props {
  sisId: number;
  sx?: SxProps;
};

const SlingTypeSelector = ({ sisId, sx }: Props) => {
  const { t } = useTranslation();

  const { data } = useGetSlingTypesQuery();
  const slingTypeId = useGetSlingTypeId(sisId);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const typeId = e.target.value;
    return updateSis({ sisId, data: { slingTypeId: typeof typeId === "number" && typeId > 0 ? typeId : "" } });
  };

  return (
    <Box display="flex" flexDirection="column" sx={sx}>
      <Typography>{t("common:sling_type")}</Typography>
      <TextField
        sx={{ width: 145 }}
        select
        value={slingTypeId ? slingTypeId?.toString() : "none"}
        onChange={handleOnChange}
        InputProps={{
          sx: {
            height: 70,
          },
        }}
      >
        <MenuItem value="none">
          <em>{t("common:none")}</em>
        </MenuItem>

        {data?.map((st) => (
          <MenuItem key={st.id} value={st.id}>
            {st.name}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
};

export default SlingTypeSelector;
