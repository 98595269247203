import React, { ChangeEvent } from "react";
import { Box, Card, CardHeader, CardContent, Divider, Switch } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSlingInputStation } from "../../selectors/slingInputStations";
import { updateConfiguration } from "../../api/SlingInputStationApi";

interface Props {
  sisId: number;
};

const EmptyRailSISConfigOptions = ({ sisId }: Props) => {
  const { t } = useTranslation();
  const sis = useSlingInputStation(sisId) || {};
  const configuration = sis.configuration || {};

  const handleToggleShowSendLoad = (e: ChangeEvent<HTMLInputElement>) => {
    updateConfiguration({
      sisId,
      configuration: {
        ...sis.configuration,
        showSendLoadButton: e.target.checked,
      },
    });
  };

  return (
    <Box margin={4} marginTop={14} minWidth={200} display="flex" flexDirection="column">
      <Card variant="outlined">
        <CardHeader title={t("slingInputStation:show_send_load_button")} />
        <Divider />
        <CardContent>
          <Switch checked={configuration.showSendLoadButton || false} onChange={handleToggleShowSendLoad} />
        </CardContent>
      </Card>
    </Box>
  );
};

export default EmptyRailSISConfigOptions;
