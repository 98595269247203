import React from "react";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material"
import { withStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import CategoryCardSlim from "../../../../components/FlagSelectors/CategoryCardSlim";
import { useCurrentRole } from "../../../../selectors/useRole";
import type { System } from "../../../../dto/eVue/System";
import type { StorageGroup } from "../../dto/StorageGroup";
import { useGetStorageGroupsQuery } from "../../services/storageGroups";
import AddStorageGroupButton from "./AddStorageGroupButton";
import AssignCategoriesButton from "./AssignCategoriesButton";
import CategoryDataWrapper from "../../dataWrappers/CategoryDataWrapper";
import DeleteStorageGroupButton from "./DeleteStorageGroupButton";
import NameEditButton from "./NameEditButton";

interface Props {
  system: System;
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    padding: theme.spacing(2),
  },
  body: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`
  },
}))(TableCell);

// TODO: Possibly change this to trello column style card view where category cards can be dragged to selected groups
const StorageGroupManagerView = ({ system }: Props) => {
  const { t } = useTranslation();
  const currentRole = useCurrentRole();
  const storageGroupsQuery = useGetStorageGroupsQuery();
  const storageGroups: StorageGroup[] = storageGroupsQuery.data || [];
  const storageGroupsForSystem = storageGroups?.filter((sg) => sg.systemId === system?.id) || [];

  if (storageGroupsQuery.isLoading || !storageGroups) return null;
  return (
    <TableContainer>
      <Table stickyHeader size="small" sx={{ tableLayout: "fixed" }}>
        <TableHead>
          <TableRow>
            <StyledTableCell sx={{ width: "15%" }}>{t("storageManager:storage_group")}</StyledTableCell>
            <StyledTableCell sx={{ maxWidth: 500, width: "70%" }}>
              {t("common:categories")}
            </StyledTableCell>
            <StyledTableCell align="right" sx={{ width: "15%" }}>
              <AddStorageGroupButton system={system} disabled={!currentRole.canOperatePicklists} />
            </StyledTableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {storageGroupsForSystem?.map((sg) => (
            <TableRow key={`storage-group-${sg.id}`}>
              <StyledTableCell>
                {sg.name}
              </StyledTableCell>

              <StyledTableCell>
                <Grid container spacing={1} alignItems="center">
                  {sg.storageGroupAssignments?.map((sga) => (
                    <Grid item xs={12} md={6} lg={4} xl={3} key={sga.sortClassificationId}>
                      <CategoryDataWrapper categoryId={sga.sortClassificationId}>
                        {(category) => (
                          <CategoryCardSlim
                            imageUrl={category.imageUrl}
                            number={category.number}
                            name={category.name}
                            border={true}
                            disabled={false}
                            highlight={false}
                            onClick={() => ({})}
                          />
                        )}
                      </CategoryDataWrapper>
                    </Grid>
                  ))}
                </Grid>
              </StyledTableCell>

              <StyledTableCell>
                <Box display="flex" justifyContent="flex-end" flexWrap="wrap">
                  <NameEditButton storageGroup={sg} disabled={!currentRole.canOperatePicklists} />
                  <AssignCategoriesButton storageGroupId={sg.id} disabled={!currentRole.canOperatePicklists} />
                  <DeleteStorageGroupButton storageGroupId={sg.id} disabled={!currentRole.canOperatePicklists} />
                </Box>
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
};

export default StorageGroupManagerView;
