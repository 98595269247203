import React from "react";
import PropTypes from "prop-types";
import { Box, TextField } from "@mui/material";
import { withStyles } from "@mui/styles";
import FormFieldLabel from "./FormFieldLabel";

const StyledTextField = withStyles({
  root: {
    "& .MuiInputBase-root input": {
      maxWidth: 100,
      padding: "14px 10px",
      fontSize: 20,
      height: 20,
    },
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none",
    },
  },
})(TextField);

const FormNumberField = (props) => {
  const { fieldName, label, helperText, currentValues, errors, type, maxLength, disabled, onFieldChange } = props;
  let value = currentValues[fieldName];
  value = type === "float" ? parseFloat(value) : parseInt(value);

  return (
    <Box display="flex" flexDirection="column">
      <FormFieldLabel label={label} />
      <StyledTextField
        name={fieldName}
        type="number"
        value={value >= 0 ? value : ""}
        margin="none"
        variant="outlined"
        error={Boolean(errors[fieldName])}
        helperText={errors[fieldName] || helperText}
        disabled={disabled}
        InputLabelProps={{ shrink: true }}
        onChange={(e) => {
          const newValue = e.target.value;
          if (newValue.length <= maxLength && onFieldChange) {
            onFieldChange(fieldName, newValue);
          }
        }}
      />
    </Box>
  );
};

FormNumberField.defaultProps = {
  fieldName: "",
  label: "",
  helperText: "",
  currentValues: {},
  errors: {},
  type: "int",
  maxLength: 6,
  disabled: false,
};

FormNumberField.propTypes = {
  fieldName: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.string,
  currentValues: PropTypes.object,
  errors: PropTypes.object,
  type: PropTypes.oneOf(["int", "float"]),
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  onFieldChange: PropTypes.func,
};

export default FormNumberField;
