import _ from "lodash";
import type { RuleInfo } from "./dto/RuleInfo";
import type { StorageRuleset } from "./dto/StorageRuleset";
import type { StorageRuleDTO } from "./dto/StorageRuleDTO";
import type { StorageRulesetDataTypes } from "./dto/StorageRulesetDataTypes";
import StorageRule from "./classes/StorageRule";

export const parseRuleInfo = (rule: string): RuleInfo => {
  const ruleBreakdown = rule.split("-");
  const ruleType = ruleBreakdown[0] as keyof StorageRuleset;
  const ruleId = parseInt(ruleBreakdown[1]);
  return {
    ruleType: ruleType,
    id: ruleId,
  }
};

export const updateSelectionsWithPick = ({ selections, id }: { selections: number[], id: number }) => {
  const foundIdIndex = selections?.findIndex((existingId) => existingId === id);
  const updatedSelections = selections?.slice() || [];
  if (foundIdIndex >= 0) {
    updatedSelections.splice(foundIdIndex, 1);
  } else {
    updatedSelections.push(id);
  }

  return updatedSelections;
};

type StorageRuleTypes = {
  sourceRails: StorageRuleDTO[];
  customers: StorageRuleDTO[];
  categories: StorageRuleDTO[];
  storageGroups: StorageRuleDTO[];
};

export const separateRulesByType = (storageRules?: StorageRuleDTO[]) => {
  const blankRulesByType: StorageRuleTypes = {
    sourceRails: [],
    customers: [],
    categories: [],
    storageGroups: [],
  };

  if (!storageRules) return blankRulesByType;
  return storageRules.reduce((acc, rule) => {
    if (rule.railId) {
      acc.sourceRails.push(rule);
    } else if (rule.customerId) {
      acc.customers.push(rule);
    } else if (rule.categoryId) {
      acc.categories.push(rule);
    } else if (rule.storageGroupId) {
      acc.storageGroups.push(rule);
    }

    return acc;
  }, blankRulesByType);
};

interface Consolidation {
  storageRulesetId?: number;
  existingRules: StorageRuleDTO[];
  selectedIds: number[];
  fieldName: "railId" | "categoryId" | "customerId" | "storageGroupId";
};

// TODO: Rename function?
export const consolidateOldWithNewRules = (props: Consolidation) => {
  const { storageRulesetId, existingRules, selectedIds, fieldName } = props;
  let rules = existingRules.slice() || [];
  const existingIds = rules.map((rule) => rule[fieldName]).filter((id) => id);

  const newRules: StorageRuleDTO[] = [];
  selectedIds.forEach((selectedId) => {
    if (!existingIds.includes(selectedId)) {
      const newStorageRule = new StorageRule({ storageRulesetId, [fieldName]: selectedId });
      newRules.push(newStorageRule);
    };
  });

  rules = rules.map((rule) => {
    const resourceId = rule[fieldName] as number;
    if (resourceId && !selectedIds.includes(resourceId)) {
      return {
        ...rule,
        _destroy: true,
      };
    };
    return rule;
  });

  return existingRules.concat(newRules);
};
