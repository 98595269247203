import React, { useState } from "react";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { cloneConfig, showMessage } from "../../redux/storageConfigEditor";
import { useCurrentRole } from "../../selectors/useRole";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import TextEditorDialog from "../TextEditorDialog";

const useStyles = makeStyles((theme) => ({
  actionButton: {
    maxWidth: 200,
    "&:disabled": {
      backgroundColor: theme.palette.grey[200],
    },
  },
}));

const CloneButton = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enableClone, selectedConfig } = useSelector((state) => state.storageConfigEdit);
  const { t } = useTranslation();
  const role = useCurrentRole();
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        className={classes.actionButton}
        variant="outlined"
        disabled={!enableClone || !role.canEditStorageConfigs}
        startIcon={<FileCopyIcon />}
        onClick={() => setOpen(true)}
      >
        {t("common:clone")}
      </Button>

      <TextEditorDialog
        open={open}
        fullWidth={true}
        maxWidth="md"
        message={t("storageAssignments:enter_new_name")}
        initialValue={`${t("common:copy_of")} ${selectedConfig?.name}`}
        onClose={() => setOpen(false)}
        onSubmit={(name) => {
          setOpen(false);
          dispatch(showMessage(t("storageAssignments:creating_configuration")));
          dispatch(cloneConfig(name));
        }}
      />
    </>
  );
};

export default CloneButton;
