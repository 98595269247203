import React, { useEffect } from "react";
import { Prompt } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { clearEditor, selectList } from "../redux/productionListEditor";
import Loader from "../components/Loader";
import Selections from "../components/ProductionLists/Selections";
import TitleBar from "../components/ProductionLists/TitleBar";
import { PRODUCTION_LISTS } from "../constants/Paths";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexFlow: "column",
    height: "100%",
    padding: theme.spacing(3),
  },
}));

const ProductionLists = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enableSave, loaderOpen, loaderMessage } = useSelector((state) => state.productionListEdit);
  const { id } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    const productionListId = parseInt(id);
    if (productionListId) dispatch(selectList(productionListId));
    return () => {
      dispatch(clearEditor());
    };
  }, [id]);

  return (
    <div className={classes.root}>
      <TitleBar />
      <Selections />

      <Loader open={loaderOpen} message={loaderMessage} />
      <Prompt
        when={enableSave}
        message={(location) => (location.pathname === PRODUCTION_LISTS ? true : t("common:unsaved_changes"))}
      />
    </div>
  );
};

export default ProductionLists;
