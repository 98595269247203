import React from "react";
import Avatar from "../../ui-component/extended/Avatar";
import { AvatarGroup } from "@mui/material";
import { useUser } from "../../selectors/useUser";

const CurrentOperators = ({ operatorIds }) => {
  return (
    <AvatarGroup total={8} sx={{ pt: 1 }}>
      {operatorIds.map((operatorId) => (
        <OperatorAvatar operatorId={operatorId} />
      ))}
    </AvatarGroup>
  );
};

const OperatorAvatar = ({ operatorId }) => {
  const operator = useUser(operatorId);

  return (
    <Avatar src={operator.imageUrl} color="warning" sx={{ width: 32, height: 32 }}>
      {`${operator.firstname.charAt(0)}${operator.lastname.charAt(0)}`}
    </Avatar>
  );
};

export default CurrentOperators;
