import React from "react";
import { useTranslation } from "react-i18next";
import { Box, FormHelperText } from "@mui/material";
import FormFieldLabel from "../SetupPages/FormFieldLabel";
import PickSizesSelector from "./PickSizesSelector";

const FormPickSizesSelector = ({ currentValues, setupData, disabled, onArrayFieldChange }) => {
  const { t } = useTranslation();
  return (
    <Box display="flex" flexDirection="column">
      <FormFieldLabel label={t("categoriesSetup:pick_sizes")} />
      <PickSizesSelector
        pickSizes={currentValues["pickSizes"] || []}
        pickSizeRailsList={setupData.pickSizeRailsList}
        disabled={disabled}
        onRemove={(id) => {
          const foundIdIndex = currentValues["pickSizes"]?.findIndex((pickSize) => pickSize.id === id);
          if (foundIdIndex >= 0) {
            return onArrayFieldChange({
              field: "pickSizes",
              operation: "remove",
              index: foundIdIndex,
            });
          }
        }}
        onUpdate={(updatedPickSize) => {
          const foundIdIndex = currentValues["pickSizes"]?.findIndex((pickSize) => {
            return pickSize.id === updatedPickSize.id;
          });
          if (foundIdIndex >= 0) {
            return onArrayFieldChange({
              field: "pickSizes",
              operation: "update",
              index: foundIdIndex,
              value: updatedPickSize,
            });
          }
        }}
        onAdd={(newPickSize) =>
          onArrayFieldChange({
            field: "pickSizes",
            operation: "add",
            value: newPickSize,
          })
        }
      />

      <FormHelperText sx={{ ml: 2 }}>{t("categoriesSetup:pick_sizes_helper_text")}</FormHelperText>
    </Box>
  );
};

export default FormPickSizesSelector;
