import React from "react";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useSLXHost } from "../selectors/uiSettings";
import { SLX_WEBPAGE } from "../constants/Paths";

const useStyles = makeStyles({
  root: {
    border: 0,
    display: "block",
    height: "100%",
    width: "100%",
  },
});

const SLXWebpage = () => {
  const classes = useStyles();
  const location = useLocation();
  const slxHost = useSLXHost();

  const hostWithPath = `http://${location.pathname.replace(SLX_WEBPAGE, slxHost)}`;
  const params = location.search;
  const hash = location.hash;
  const slxUrl = `${hostWithPath}${params}${hash}`;

  return <iframe className={classes.root} title="slx-webpage" src={slxUrl}></iframe>;
};

export default SLXWebpage;
