import dayjs, { Dayjs } from "dayjs";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { getPlantStartTime } from "../../../selectors/uiSettings";

const useDefaultPlantStartEndTimes = (): { startTime: Dayjs, endTime: Dayjs } => {
  const plantStartHour = useAppSelector((state) => getPlantStartTime(state));
  const startTime = dayjs().hour(plantStartHour).minute(0).second(0);
  const endTime = startTime.add(1, "day");

  return { startTime, endTime };
};

export default useDefaultPlantStartEndTimes;
