import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Box, Divider, Paper, Typography } from "@mui/material";
import SlingTypeSelector from "../../features/slingInputStations/components/SlingTypeSelector";
import ConciseCustomActionButton from "../../features/slingInputStations/components/ConciseCustomActionButton";
import { useSlingInputStation } from "../../selectors/slingInputStations";
import { useRailVisit } from "../../selectors/railVisits";
import { useRail, useLiveWeightForRailId } from "../../selectors/rails";
import { useJobNumber } from "../../selectors/uiSettings";
import { useBatch } from "../../selectors/batches";
import { railInputEnabled, railOutputEnabled } from "../../api/RailApi";
import { editWeightForSis } from "../../api/SlingInputStationApi";
import { IndicatorStatusTypes } from "../../features/panels/enum/IndicatorStatusTypes";
import CircleIndicator from "../CircleIndicator";
import ConciseManSendButton from "./ConciseManSendButton";
import ConciseAddSlingButton from "./ConciseAddSlingButton";
import ConciseDeleteSlingButton from "./ConciseDeleteSlingButton";
import CondensedSingleSlingInput from "./CondensedSingleSlingInput";
import CustomCondensedSingleSlingInput from "./CustomCondensedSingleSlingInput";
import ConciseBrintToFrontButton from "./ConciseBringToFrontButton";
import ConciseCaptureLCWeightButton from "./ConciseCaptureLCWeightButton";
import { FLOOR_SCALE_SINGLE, FLOOR_SCALE_DOUBLE } from "./weightInputTypes";
import LiveWeight from "./LiveWeight";

const CombinedWeightCapture = ({ frontSIS, rearSIS }) => {
  const { t } = useTranslation();
  const frontRail = useRail(frontSIS?.railId);
  const rearRail = useRail(rearSIS?.railId);

  const rv1 = useRailVisit({ railId: frontRail?.id, position: 1 });
  const batch1 = useBatch(rv1?.batchId);
  const rv2 = useRailVisit({ railId: rearRail?.id, position: 1 });
  const batch2 = useBatch(rv2?.batchId);
  const liveWeight = useLiveWeightForRailId(frontRail?.id) || 0;
  const railIdWithLoadCell = frontRail?.loadCellId ? frontRail?.id : rearRail?.id;

  const handleCaptureWeight1 = () => {
    const sling2Loaded = batch2?.id;
    !sling2Loaded && editWeightForSis({ sisId: rearSIS?.id, weight: 0, position: 1 });
    return editWeightForSis({
      sisId: frontSIS?.id,
      weight: sling2Loaded ? liveWeight - (batch2?.weight || 0) : liveWeight,
      position: 1,
    });
  };

  const handleCaptureWeight2 = () => {
    const sling1Loaded = batch1?.id;
    !sling1Loaded && editWeightForSis({ sisId: frontSIS?.id, weight: 0, position: 1 });
    return editWeightForSis({
      sisId: rearSIS?.id,
      weight: sling1Loaded ? liveWeight - (batch1?.weight || 0) : liveWeight,
      position: 1,
    });
  };

  return (
    <>
      <Box
        sx={{
          fontSize: 40,
          borderRadius: "50%",
          position: "absolute",
          bottom: 0,
          left: "50%",
          transform: "translate(-50%, -10%)",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={(theme) => ({
            padding: 1,
            backgroundColor: theme.palette.common.white,
          })}
        >
          <LiveWeight railId={railIdWithLoadCell} label={t("slingInputStation:total_weight")} />
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={(theme) => ({
            padding: 1,
            backgroundColor: theme.palette.common.white,
          })}
        >
          <ConciseCaptureLCWeightButton sx={{ mb: 1 }} onClick={handleCaptureWeight1} />
          <ConciseCaptureLCWeightButton arrowDirection="right" onClick={handleCaptureWeight2} />
        </Box>
      </Box>
    </>
  );
};

const SingleSlingLayout = ({ rail, sisId }) => {
  const { t } = useTranslation();

  const slingInputStation = useSlingInputStation(sisId);
  const configuration = slingInputStation?.configuration;
  const { name, inputEnabled, outputEnabled, plcState } = rail;

  const handleToggleInbound = () => railInputEnabled({ id: rail.id, inputEnabled });
  const handleToggleOutbound = () => railOutputEnabled({ id: rail.id, outputEnabled });

  return (
    <>
      <Box display="flex" flexDirection="column" padding={2}>
        <Typography variant="h1" gutterBottom>
          {name}
        </Typography>
        <Typography variant="overline">
          {t("railStates:state_state", {
            state: t(`railStates:${plcState}`),
          })}
        </Typography>

        <Box display="flex" justifyContent="space-between" alignItems="flex-end" mt={2}>
          <div>
            <CircleIndicator
              label={t("common:in")}
              status={inputEnabled ? IndicatorStatusTypes.ON : IndicatorStatusTypes.OFF}
              onClick={handleToggleInbound}
            />
            <CircleIndicator
              label={t("common:out")}
              status={outputEnabled ? IndicatorStatusTypes.ON : IndicatorStatusTypes.OFF}
              onClick={handleToggleOutbound}
            />
          </div>

          <Box display="flex" alignItems="flex-end">
            <ConciseManSendButton sisId={sisId} size="xLarge" />
            <ConciseAddSlingButton sisId={sisId} size="xLarge" />
            <ConciseDeleteSlingButton sisId={sisId} size="xLarge" />
            <ConciseCustomActionButton sisId={sisId} />
            {configuration?.showSlingTypeSelector && <SlingTypeSelector sisId={sisId} sx={{ ml: 1, mr: 1 }} />}
          </Box>
        </Box>
      </Box>

      <Divider />

      <Box display="flex" justifyContent="center" padding={2}>
        <CondensedSingleSlingInput
          key={`sling-${sisId}`}
          sisId={sisId}
          position={1}
          showCaptureWeightButton={[FLOOR_SCALE_SINGLE, FLOOR_SCALE_DOUBLE].includes(
            slingInputStation?.weightInputType
          )}
        />
      </Box>
    </>
  );
};

const CustomSingleSlingLayout = ({ rail, sisId, frontSIS }) => {
  const { t } = useTranslation();
  const { name, inputEnabled, outputEnabled, plcState } = rail;

  const slingInputStation = useSlingInputStation(sisId);
  const configuration = slingInputStation?.configuration;

  const handleToggleInbound = () => railInputEnabled({ id: rail.id, inputEnabled });
  const handleToggleOutbound = () => railOutputEnabled({ id: rail.id, outputEnabled });

  return (
    <>
      <Box display="flex" flexDirection="column" padding={2}>
        <Typography variant="h1" gutterBottom>
          {name}
        </Typography>
        <Typography variant="overline">
          {t("railStates:state_state", {
            state: t(`railStates:${plcState}`),
          })}
        </Typography>

        <Box display="flex" justifyContent="space-between" alignItems="flex-end" mt={2}>
          <div>
            {!frontSIS && (
              <CircleIndicator
                label={t("common:in")}
                status={inputEnabled ? IndicatorStatusTypes.ON : IndicatorStatusTypes.OFF}
                onClick={handleToggleInbound}
              />
            )}
            <CircleIndicator
              label={t("common:out")}
              status={outputEnabled ? IndicatorStatusTypes.ON : IndicatorStatusTypes.OFF}
              onClick={handleToggleOutbound}
            />
          </div>

          <Box display="flex" alignItems="flex-end">
            {frontSIS && <ConciseManSendButton sisId={sisId} size="xLarge" iconDirection="backward" />}
            <ConciseAddSlingButton sisId={sisId} size="xLarge" weight={0} />
            <ConciseDeleteSlingButton sisId={sisId} size="xLarge" />
            {!frontSIS && <ConciseBrintToFrontButton sisId={sisId} size="xLarge" />}
            {configuration?.showSlingTypeSelector && <SlingTypeSelector sisId={sisId} sx={{ ml: 1, mr: 1 }} />}
          </Box>
        </Box>
      </Box>

      <Divider />

      <Box display="flex" justifyContent="center" padding={2}>
        <CustomCondensedSingleSlingInput key={`sling-${sisId}`} sisId={sisId} />
      </Box>
    </>
  );
};

const DualSIS = ({ frontSISId, rearSISId, layout }) => {
  const frontSIS = useSlingInputStation(frontSISId);
  const frontRail = useRail(frontSIS?.railId);
  const rearSIS = useSlingInputStation(rearSISId);
  const rearRail = useRail(rearSIS?.railId);
  const jobNumber = useJobNumber();

  return (
    <Box display="flex" height="100%">
      <Paper square={true} elevation={0} sx={{ width: "100%", height: "100%" }}>
        {jobNumber === "1838" && layout === "1838custom" ? (
          <CustomSingleSlingLayout sisId={frontSIS?.id} rail={frontRail} frontSIS={true} />
        ) : (
          <SingleSlingLayout sisId={frontSIS?.id} rail={frontRail} />
        )}
      </Paper>

      <Divider orientation="vertical" sx={{ border: "1px solid black" }} />

      <Paper square={true} elevation={0} sx={{ width: "100%", height: "100%" }}>
        {jobNumber === "1838" && layout === "1838custom" ? (
          <CustomSingleSlingLayout sisId={rearSIS?.id} rail={rearRail} />
        ) : (
          <SingleSlingLayout sisId={rearSIS?.id} rail={rearRail} />
        )}
      </Paper>

      {jobNumber === "1838" && layout === "1838custom" && (
        <CombinedWeightCapture frontSIS={frontSIS} rearSIS={rearSIS} />
      )}
    </Box>
  );
};

DualSIS.propTypes = {
  frontSISId: PropTypes.number,
  rearSISId: PropTypes.number,
  layout: PropTypes.string,
};

export default DualSIS;
