import _ from "lodash";
import store from "../store";
import { showAlertError, showAlertSuccess } from "../actions/alertActions";
import i18n from "../i18n";
import Client from "./Client.js";

export const forceEndCustomerChange = (custChangeStationId) => {
  Client.put(`control_api/v1/customer_change_stations/${custChangeStationId}`, {
    forceCustChange: true,
  })
    .then((response) => store.dispatch(showAlertSuccess(i18n.t("common:success"))))
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const newCustomerChange = (custChangeStationId, customerId) => {
  Client.post(`control_api/v1/customer_change_stations/${custChangeStationId}/new_customer_change`, {
    customerId: customerId,
  })
    .then((response) => store.dispatch(showAlertSuccess(i18n.t("common:success"))))
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const acceptExternalCustomerChange = (custChangeStationId) => {
  Client.post(`control_api/v1/customer_change_stations/${custChangeStationId}/accept_external_customer_change`)
    .then((response) => store.dispatch(showAlertSuccess(i18n.t("common:success"))))
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const denyExternalCustomerChange = (custChangeStationId) => {
  Client.post(`control_api/v1/customer_change_stations/${custChangeStationId}/deny_external_customer_change`)
    .then((response) => store.dispatch(showAlertSuccess(i18n.t("common:success"))))
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data.error", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const ackManualEmpty = (sortStationId) => {
  Client.put(`control_api/v1/sort_stations/${sortStationId}`, { manualEmptyAck: true })
    .then((response) => store.dispatch(showAlertSuccess(i18n.t("common:success"))))
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const custChangeManSend = (sortStationId) => {
  Client.post(`control_api/v1/sort_stations/${sortStationId}/cust_change_man_send`)
    .then((response) => store.dispatch(showAlertSuccess(i18n.t("common:success"))))
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};
