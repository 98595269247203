import React from "react";
import _ from "lodash";
import { Box, TableHead, TableRow, TableCell } from "@mui/material";
import { withStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

const StyledTableCell = withStyles((theme) => ({
  head: {
    padding: theme.spacing(2),
  },
}))(TableCell);

interface Props {
  children?: JSX.Element;
};

const TableHeading = ({ children }: Props) => {
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell>{t("common:rail")}</StyledTableCell>
        <StyledTableCell>{t("storageManager:routing_type")}</StyledTableCell>
        <StyledTableCell sx={{ maxWidth: 500 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            {t("storageManager:ruleset")}
          </Box>
        </StyledTableCell>
        <StyledTableCell align="right">
          {children}
        </StyledTableCell>
      </TableRow>
    </TableHead>
  )
}

export default TableHeading;
