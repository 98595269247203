export enum SystemRoutingTypes {
  DYNAMIC = "dynamic",
  LEGACY = "legacy",
};

export type SystemType = "clean" | "soiled";

export type System = {
  id: number;
  name: string;
  number: number;
  soiled: boolean;
  clean: boolean;
  customerBag: boolean;
  maxSlingWeight: number | null;
  routing?: SystemRoutingTypes;
  systemType: SystemType;
};
