import React from "react";
import _ from "lodash";
import { useRail } from "../../../selectors/rails";
import { Rail } from "../../../dto/eVue/Rail";
import { StorageRuleset } from "../dto/StorageRuleset";

interface Props {
  railId: number;
  data: StorageRuleset[];
  children: (rail: Rail, storageRuleset: StorageRuleset) => JSX.Element;
};

const StorageRulesetDataWrapper = ({ railId, data, children }: Props) => {
  const rail = useRail(railId) || {};
  const storageRuleset = data.find((ruleset) => ruleset.railId === railId) as StorageRuleset;
  return children(rail, storageRuleset);
};

export default StorageRulesetDataWrapper;
