import React from "react";
import Button from "@mui/material/Button";
import { useCurrentRole } from "../../../selectors/useRole";

const EmptyCartButton = ({ onClick }) => {
  const { canControlInputLifts } = useCurrentRole();

  return (
    <Button
      id="empty-cart-button"
      color="primary"
      variant="contained"
      disabled={!canControlInputLifts}
      disableElevation={true}
      onClick={onClick}
    >
      Empty Cart
    </Button>
  );
};

export default EmptyCartButton;
