import _ from "lodash";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { shallowEqual } from "react-redux";
import { getIoPointIdsForPanel, getHasAnyForcedPoints } from "../../../selectors/ioPoints";
import store from "../../../store";
import { socket } from "../../../utils/channel";
import { objFromArray } from "../../../utils";
import { useAppSelector } from "../../../hooks/useAppSelector";
import type { AppDispatch } from "../../../store";
import type { Panel } from "../../../dto/eVue/Panel";
import type { IOPoint } from "../../../dto/eVue/IOPoint";
import { PanelTypes } from "../enum/PanelTypes";

interface PanelState {
  [id: number]: Panel
}

export const panelsSubscribe = () => {
  const channel = socket.channel("panels");
  return (dispatch: AppDispatch) => {
    channel
      .join(30000)
      .receive("ok", () => {})
      .receive("error", () =>
        dispatch({
          type: "CHANNEL_JOIN_ERROR",
          channel: "panels",
        })
      );

    channel.on("init", (msg: Panel[]) => {
      dispatch(panelsSlice.actions.initAction(msg));
    });

    channel.on("update", (msg: Panel) => {
      dispatch(panelsSlice.actions.updateAction(msg));
    });
  };
};

const initialState: PanelState = {};

const panelsSlice = createSlice({
  name: "panels",
  initialState,
  reducers: {
    initAction: (state, action) => {
      return objFromArray(action.payload.data);
    },
    updateAction: (state: PanelState, action: PayloadAction<Panel>) => {
      const { id } = action.payload;
      state[id] = { ...state[id], ...action.payload };
    },
  },
});

export const determineOnlineStatus = (panel: Panel) => {
  return panel?.panelType === PanelTypes.GE || panel?.panelType === PanelTypes.BNR || panel?.online;
};

interface FormatPanelProps {
  panel: Panel;
  ioPointIds: number[];
  anyForcedPoints: boolean | null;
};

const formatPanel = ({ panel, ioPointIds, anyForcedPoints }: FormatPanelProps): Panel => {
  return {
    description: panel.description,
    id: panel.id,
    ipAddress: panel.ipAddress,
    location: panel.location,
    name: panel.name,
    notes: panel.notes,
    online: determineOnlineStatus(panel),
    fault: !getIsPanelOn(panel.id),
    ioPointIds,
    hasForcedPoints: anyForcedPoints || false,
    panelType: panel.panelType,
  };
};

// SELECTORS
export const usePanelsIds = () => useAppSelector((state) => {
  return Object.keys(state.panels).map(id => parseInt(id));
}, shallowEqual);

export const usePanel = (panelId?: number) => useAppSelector((state) => {
  if (!panelId) return null;
  const panel = state.panels[panelId] || {};
  const ioPointIds = getIoPointIdsForPanel(panelId, state);
  const anyForcedPoints = getHasAnyForcedPoints(ioPointIds, state);
  return formatPanel({ panel, ioPointIds, anyForcedPoints });
}, _.isEqual);

export const usePanelName = (panelId: number) => useAppSelector((state) => state.panels[panelId]?.name);

export const getPanelName = (panelId: number, state = store.getState()) => state.panels[panelId]?.name;

export const getAllPanelIds = (state = store.getState()) => Object.keys(state.panels);

export const getIsPanelOn = (panelId: number, state = store.getState()) => {
  const panel = state.panels[panelId];
  // TODO: Find out how / if you can detect when a GE or BNR panel is on, then remove / edit condition below
  if (panel?.panelType === PanelTypes.GE || panel?.panelType === PanelTypes.BNR) return true;

  const ioPoints: IOPoint[] = Object.values(state.ioPoints);
  const powerIOPoint = ioPoints.find((ioPoint) => ioPoint.panelId === panelId && ioPoint.point === 1);
  return powerIOPoint?.value;
};

export default panelsSlice.reducer;
