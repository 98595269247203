import { createApi, fetchBaseQuery, BaseQueryFn, FetchArgs, FetchBaseQueryError, } from "@reduxjs/toolkit/query/react";
import type { RootState } from "../../store";
import { getSLXUiEnabled, getSLXHost } from "../../selectors/uiSettings";
import type { PrintRequestParams } from "../../dto/slx/printApi/PrintRequestParams";
import type { PrintRequestResponse } from "../../dto/slx/printApi/PrintRequestResponse";

const rawBaseQuery = fetchBaseQuery({
  baseUrl: "/",
});

const customBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const slxUiEnabled = getSLXUiEnabled();
  const slxHost = getSLXHost(api.getState() as RootState);

  if (!slxUiEnabled || !slxHost) {
    return {
      error: {
        status: 400,
        statusText: "Bad Request",
        data: "SLX Not Available",
      }
    }
  }

  const urlEnd = typeof args === "string" ? args : args.url
  const adjustedUrl = `http://${slxHost}/api/prints/${urlEnd}`
  const adjustedArgs = typeof args === "string" ? adjustedUrl : { ...args, url: adjustedUrl }

  return rawBaseQuery(adjustedArgs, api, extraOptions);
};

export const slxPrintApi = createApi({
  reducerPath: "slxPrintApi",
  baseQuery: customBaseQuery,
  endpoints: (builder) => ({
    getPrinters: builder.query({
      query: () => "v4/printers",
    }),
    addPrintJobRequest: builder.mutation<PrintRequestResponse, PrintRequestParams>({
      query: ({ customerId, customerName, categoryId, categoryName, batchId }) => ({
        url: "v3/requests/print-job-requests",
        method: "POST",
        body: {
          printData: {
            customerId,
            customerName,
            categoryId,
            categoryName,
            batchId,
          }
        }
      }),
    }),
  }),
});

export const { useGetPrintersQuery, useAddPrintJobRequestMutation } = slxPrintApi;
