import _ from "lodash";
export { default as configureChannel } from "./channel";
export { toCamelCase, toSnakeCase } from "./casing";

export function objFromArray(array) {
  return array.reduce((accumulator, current) => {
    accumulator[current.id] = current;
    return accumulator;
  }, {});
}

export function singularizeResource(resource) {
  switch (resource) {
    case "batches":
      return "batch";
    case "categories":
      return "category";
    default:
      return resource.slice(0, -1);
  }
}

export const parseLabel = (name = "") => {
  const matches = name?.match(/\b(\w)|\d/g) ?? [];
  const acronym = matches.join("").substring(0, 3);
  return acronym.toUpperCase();
};
