import React, { useEffect } from "react";
import { clearEditor, loadConfigs } from "../redux/storageConfigEditor";
import { makeStyles } from "@mui/styles";
import { Prompt, useParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useSystemByType } from "../selectors/systems";
import { useTranslation } from "react-i18next";
import AssignmentGrid from "../components/StorageAssignments/AssignmentGrid";
import CustomerMapping from "../components/StorageAssignments/CustomerMapping";
import Loader from "../components/Loader";
import TitleBar from "../components/StorageAssignments/TitleBar";
import TitleBarMapping from "../components/StorageAssignments/TitleBarMapping";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexFlow: "column",
    height: "100%",
    padding: theme.spacing(3),
  },
}));

const StorageAssignments = () => {
  const { enableSave, loaderOpen, loaderMessage, mappingOpen } = useSelector(
    (state) => state.storageConfigEdit,
    shallowEqual
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const params = useParams();
  const system = useSystemByType(params.systemType);

  useEffect(() => {
    dispatch(loadConfigs(system));
    return () => {
      dispatch(clearEditor());
    };
  }, [system]);

  return (
    <div className={classes.root}>
      {mappingOpen ? (
        <>
          <TitleBarMapping />
          <CustomerMapping />
        </>
      ) : (
        <>
          <TitleBar />
          <AssignmentGrid />
        </>
      )}

      <Loader open={loaderOpen} message={loaderMessage} />
      <Prompt when={enableSave} message={(location) => t("common:unsaved_changes")} />
    </div>
  );
};

export default StorageAssignments;
