import React from "react";
import { Select, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { RailGroup, RailGroupNames } from "../../constants/RailGroup";

const RailGroupSelector = ({ selectedRailGroup, onChange }) => {
  const { t } = useTranslation();
  const railGroups = Object.values(RailGroup);

  return (
    <Select
      variant="outlined"
      displayEmpty={true}
      value={selectedRailGroup || ""}
      onChange={(e) => onChange(e.target.value)}
    >
      <MenuItem value="">
        <em>{t("railsSetup:select_group")}</em>
      </MenuItem>
      {railGroups.map((railGroupId) => (
        <MenuItem key={railGroupId} value={railGroupId}>
          {t(`railGroups:${RailGroupNames[railGroupId] || ""}`)}
        </MenuItem>
      ))}
    </Select>
  );
};

export default RailGroupSelector;
