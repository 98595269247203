import React from "react";
import { hyphenateSync as hyphenDe } from "hyphen/de";
import { hyphenateSync as hyphenEnGb } from "hyphen/en-gb";
import { hyphenateSync as hyphenEnUs } from "hyphen/en-us";
import { hyphenateSync as hyphenFr } from "hyphen/fr";
import { hyphenateSync as hyphenZh } from "hyphen/zh";
import { useLocaleCode } from "./useLocaleCode";

export const useHyphenate = () => {
  const { languageCode, countryCode } = useLocaleCode();
  let fn = hyphenEnUs;

  if (languageCode === "de") fn = hyphenDe;
  else if (languageCode === "fr") fn = hyphenFr;
  else if (languageCode === "en" && countryCode === "GB") fn = hyphenEnGb;
  else if (languageCode === "zh") fn = hyphenZh;
  else fn = hyphenEnUs;

  return { hyphenate: fn };
};
