import React, { useState } from "react";
import { Box, IconButton, TableCell, TableRow, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import ClearIcon from "@mui/icons-material/Clear";
import { withStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useCurrentRole } from "../../../../selectors/useRole";
import type { Rail } from "../../../../dto/eVue/Rail";
import Loader from "../../../../components/Loader";
import type { StorageRuleset } from "../../dto/StorageRuleset";
import { StrgRulesetRoutingTypes } from "../../enum/StrgRulesetRoutingTypes";
import { separateRulesByType } from "../../utils";
import { useUpdateStorageRulesetMutation, useDeleteStorageRulesetsMutation } from "../../services/storageRulesets";
import useStorageGroupAlertMessages from "../../hooks/useStorageGroupAlertMessages";
import SourceRailsRules from "../RulePresentations/SourceRailsRules";
import CustomersRules from "../RulePresentations/CustomersRules";
import CategoriesRules from "../RulePresentations/CategoriesRules";
import StorageGroupsRules from "../RulePresentations/StorageGroupsRules";
import RoutingTypeDropdown from "../RailOverridePage/RoutingTypeDropdown";
import StorageRuleCreator from "./StorageRuleCreator";

const StyledTableCell = withStyles((theme) => ({
  body: {
    fontSize: 14,
    padding: theme.spacing(2),

    "& .MuiAvatar-root": {
      marginRight: theme.spacing(1),
    },
  },
}))(TableCell);

interface Props {
  storageRuleset: StorageRuleset;
  rail: Rail;
};

const RailStorageDetails = ({ storageRuleset, rail }: Props) => {
  const { t } = useTranslation();
  const currentRole = useCurrentRole();
  const storageRules = storageRuleset.storageRules || [];
  const rules = separateRulesByType(storageRules);
  const [updateStorageRuleset, { isLoading: isUpdating }] = useUpdateStorageRulesetMutation();
  const [deleteStorageRuleset] = useDeleteStorageRulesetsMutation();

  const [selectedRuleIds, setSelectedRuleIds] = useState<number[]>([]);

  const { onSuccess, onFailure } = useStorageGroupAlertMessages();

  const handleOnRoutingTypeChanged = (routingType: StrgRulesetRoutingTypes) => {
    const actionConfirmed = routingType === StrgRulesetRoutingTypes.CUSTOM ?
      true :
      window.confirm(`${t("storageManager:action_will_delete_ruleset")}\n${t("common:do_you_wish_to_proceed")}`);

    let action = undefined;
    if (routingType === StrgRulesetRoutingTypes.AUTO) {
      action = actionConfirmed ? deleteStorageRuleset(storageRuleset.id) : undefined;
    } else {
      action = actionConfirmed ? updateStorageRuleset({
        ...storageRuleset,
        routingType: routingType,
      }) : undefined;
    };

    if (!action) return null;
    return action.then(() => {
      onSuccess();
    }).catch((error) => {
      onFailure(error);
    });
  };

  const handleOnSelectRule = (ruleId: number) => {
    const foundRule = selectedRuleIds.findIndex((rId) => rId === ruleId);
    const updatedRules = foundRule >= 0 ?
      selectedRuleIds.slice().filter((rId) => rId !== ruleId) :
      selectedRuleIds.concat([ruleId]);
    setSelectedRuleIds(updatedRules);
  };

  const handleOnDeleteRules = () => {
    const updatedRules = storageRules.slice().map((rule) => ({
      ...rule,
      _destroy: rule.id ? selectedRuleIds.includes(rule.id) : false,
    }));

    return updateStorageRuleset({
      ...storageRuleset,
      storageRules: updatedRules,
    }).then(() => {
      onSuccess();
      setSelectedRuleIds([]);
    }).catch((error) => {
      onFailure(error);
    });
  };

  const handleOnClearSelections = () => setSelectedRuleIds([]);

  const editMode = selectedRuleIds.length > 0;
  return (
    <TableRow
      key={`rail-row-${rail.id}`}
      sx={{ "& > *": { borderBottom: "unset" }, backgroundColor: editMode ? "primary.light" : "inherit" }}
    >
      <Loader open={isUpdating} message={t("common:in_progress")} transitionDuration={0} />

      <StyledTableCell sx={{ width: "10%" }}>
        <Typography>{rail.name}</Typography>
      </StyledTableCell>

      <StyledTableCell sx={{ width: "10%", mb: 4 }}>
        <RoutingTypeDropdown
          routingType={storageRuleset.routingType || StrgRulesetRoutingTypes.AUTO}
          disabled={!currentRole.canOperatePicklists}
          onFieldChange={(field, value) => handleOnRoutingTypeChanged(value)}
        />
      </StyledTableCell>

      {storageRuleset.routingType === StrgRulesetRoutingTypes.CUSTOM ? (
        <StyledTableCell sx={{ width: "70%"}}>
          <Box sx={{ display: "flex", flexWrap: "wrap", columnGap: 3, rowGap: 2 }}>
            {rules.sourceRails?.length > 0 && (
              <SourceRailsRules
                rules={rules.sourceRails}
                selectedRules={selectedRuleIds}
                onSelectRule={handleOnSelectRule}
              />
            )}

            {rules.customers?.length > 0 && (
              <CustomersRules
                rules={rules.customers}
                selectedRules={selectedRuleIds}
                onSelectRule={handleOnSelectRule}
              />
            )}

            {rules.categories?.length > 0 && (
              <CategoriesRules
                rules={rules.categories}
                selectedRules={selectedRuleIds}
                onSelectRule={handleOnSelectRule}
              />
            )}

            {(rules.storageGroups?.length ?? 0) > 0 && (
              <StorageGroupsRules
                rules={rules.storageGroups}
                selectedRules={selectedRuleIds}
                onSelectRule={handleOnSelectRule}
              />
            )}
          </Box>
        </StyledTableCell>
      ) : (
        <StyledTableCell>
          <Typography>{t("storageManager:ruleset_not_allowed_for_routing_type")}</Typography>
        </StyledTableCell>
      )}

      <StyledTableCell sx={{ width: "10%"}} align="right">
        {editMode ?
          (
            <>
              <IconButton
                color="primary"
                disabled={!storageRuleset?.id}
                onClick={handleOnDeleteRules}
              >
                <DeleteIcon sx={{ fontSize: 30 }} />
              </IconButton>

              <IconButton disabled={!storageRuleset?.id} onClick={handleOnClearSelections}>
                <ClearIcon sx={{ fontSize: 30 }} />
              </IconButton>
            </>
          ) :
          storageRuleset.routingType === StrgRulesetRoutingTypes.CUSTOM ?
            <StorageRuleCreator disabled={!currentRole.canOperatePicklists} storageRuleset={storageRuleset} /> : undefined
        }
      </StyledTableCell>
    </TableRow>
  );
};

export default RailStorageDetails;
