import React from "react";
import { BuildSharp } from "@mui/icons-material";
import { Button } from "@mui/material";
import { toggleMapping } from "../redux/sortConfigurationsSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const MappingButton = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClick = () => dispatch(toggleMapping());

  return (
    <Button variant="outlined" startIcon={<BuildSharp />} onClick={handleClick}>
      {t("sortAssignments:mapping")}
    </Button>
  );
};

export default MappingButton;
