import React from "react";
import { deleteList, showMessage } from "../../redux/productionListEditor";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import RemoveIcon from "@mui/icons-material/RemoveCircleOutlineSharp";
import { useCurrentRole } from "../../selectors/useRole";
import BaseButton from "../BaseButton";

const DeleteButton = () => {
  const dispatch = useDispatch();
  const { enableDelete } = useSelector((state) => state.productionListEdit);
  const { t } = useTranslation();
  const role = useCurrentRole();

  return (
    <BaseButton
      color="error"
      sx={{ maxWidth: 200 }}
      disabled={!role.canEditPicklists || !enableDelete}
      startIcon={<RemoveIcon />}
      onClick={() => {
        dispatch(showMessage(t("productionList:deleting_production_list")));
        dispatch(deleteList());
      }}
    >
      {t("common:delete")}
    </BaseButton>
  );
};

export default DeleteButton;
