import React from "react";
import { Backdrop, Box, CardContent, CircularProgress, Divider, Grid, Paper, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import RailIcon from "@mui/icons-material/CallSplit";
import { useTranslation } from "react-i18next";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useRail } from "../../selectors/rails";
import { useCurrentRole } from "../../selectors/useRole";
import { SETUP_RAILS } from "../../constants/Paths";
import { updateRail } from "../../api/RailApi";
import FormEditor from "../SetupPages/FormEditor";
import FormTextField from "../SetupPages/FormTextField";
import FormNumberField from "../SetupPages/FormNumberField";
import ImageUploadButton from "../SetupPages/ImageUploadButton";
import FormFieldLabel from "../SetupPages/FormFieldLabel";
import SwatchColorInput from "../SetupPages/SwatchColorInput";
import SaveButton from "../SaveButton";
import RailsListButton from "./RailsListButton";
import FormMultiDeviceSelector from "./FormMultiDeviceSelector";

const StyledGridItem = withStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
  },
}))(Grid);

const SetupForm = ({ rail, onSubmitDone }) => {
  const { t } = useTranslation();
  const currentRole = useCurrentRole();

  return (
    <FormEditor
      originalValues={{
        name: rail.name,
        label: rail.label,
        imageUrl: rail.imageUrl,
        color: rail.color,
        fullQuantity: rail.fullQuantity,
        secondsToAlarm: rail.secondsToAlarm,
        notes: rail.notes,
        permissions: rail.permissions,
      }}
      onSubmit={({ initialValues, currentValues, actions }) => {
        const valuesForSubmission = Object.keys(currentValues).reduce((acc, key) => {
          if (key === "imageUrl" && initialValues["imageUrl"] === currentValues["imageUrl"]) {
            return acc;
          }
          return { ...acc, [key]: currentValues[key] === "" ? null : currentValues[key] };
        }, {});

        updateRail({
          railId: rail.id,
          successCB: () => {
            actions.finishSubmit();
            onSubmitDone();
          },
          failureCB: (errors) => {
            actions.finishSubmit(true);
            actions.catchErrors(errors);
          },
          ...valuesForSubmission,
        });
      }}
    >
      {(props) => {
        const { currentValues, dirtyForm, errors, isSubmitting, setFieldValue, handleSubmit } = props;
        const disableForm = isSubmitting || !currentRole.canEditRails;

        return (
          <>
            <CardContent sx={{ height: "100%", overflow: "auto" }}>
              <Grid container>
                <StyledBackdrop open={isSubmitting}>
                  <CircularProgress color="inherit" />
                </StyledBackdrop>

                <Grid flexDirection="column" sx={{ pr: 7, width: "50%", maxWidth: 550 }}>
                  <Grid item sx={{ mb: 1 }}>
                    <Typography variant="h4">{t("common:description")}</Typography>
                  </Grid>

                  <Grid item container sx={{ mt: 1 }} alignItems="end">
                    <Grid item xs={4}></Grid>

                    <Grid item xs={4} sx={{ display: "flex", justifyContent: "center" }}>
                      <ImageUploadButton
                        defaultIcon={<RailIcon sx={{ fontSize: 80 }} />}
                        imageUrl={currentValues["imageUrl"]}
                        disabled={disableForm}
                        onUpload={(imageUrl) => setFieldValue("imageUrl", imageUrl)}
                      />
                    </Grid>

                    <Grid item xs={4} sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <Box>
                        <FormFieldLabel label={t("common:color")} />
                        <SwatchColorInput
                          selectedColor={currentValues["color"] || ""}
                          disabled={disableForm}
                          onChange={(color) => setFieldValue("color", color)}
                        />
                      </Box>
                    </Grid>
                  </Grid>

                  <StyledGridItem>
                    <FormTextField
                      fieldName="name"
                      label={t("common:name")}
                      currentValues={currentValues}
                      errors={errors}
                      disabled={disableForm}
                      onFieldChange={setFieldValue}
                    />
                  </StyledGridItem>

                  <StyledGridItem>
                    <FormTextField
                      fieldName="label"
                      label={t("railsSetup:label")}
                      currentValues={currentValues}
                      errors={errors}
                      disabled={disableForm}
                      onFieldChange={setFieldValue}
                    />
                  </StyledGridItem>
                </Grid>

                <Grid flexDirection="column" sx={{ pr: 7, width: "50%", maxWidth: 550 }}>
                  <Grid item sx={{ mb: 1 }}>
                    <Typography variant="h4">{t("common:other")}</Typography>
                  </Grid>

                  <StyledGridItem>
                    <FormNumberField
                      fieldName="fullQuantity"
                      label={t("railsSetup:full_quantity")}
                      currentValues={currentValues}
                      errors={errors}
                      disabled={disableForm}
                      onFieldChange={setFieldValue}
                    />
                  </StyledGridItem>

                  <StyledGridItem>
                    <FormNumberField
                      fieldName="secondsToAlarm"
                      label={t("railsSetup:seconds_until_alarm")}
                      currentValues={currentValues}
                      errors={errors}
                      disabled={disableForm}
                      onFieldChange={setFieldValue}
                    />
                  </StyledGridItem>

                  <StyledGridItem>
                    <FormTextField
                      fieldName="notes"
                      label={t("common:notes")}
                      currentValues={currentValues}
                      errors={errors}
                      disabled={disableForm}
                      multiline={true}
                      minRows={4}
                      maxRows={8}
                      onFieldChange={setFieldValue}
                    />
                  </StyledGridItem>

                  <StyledGridItem>
                    <FormMultiDeviceSelector
                      currentValues={currentValues}
                      disabled={disableForm}
                      onFieldChange={setFieldValue}
                    />
                  </StyledGridItem>
                </Grid>
              </Grid>
            </CardContent>

            <Divider />

            <Grid item container justifyContent="flex-end" sx={{ padding: 3 }}>
              <SaveButton disabled={disableForm || !dirtyForm} onClick={handleSubmit}>
                {t("common:submit")}
              </SaveButton>
            </Grid>
          </>
        );
      }}
    </FormEditor>
  );
};

const StyledBackdrop = withStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}))(Backdrop);

const RailEdit = () => {
  const { t } = useTranslation();
  const params = useParams();
  const history = useHistory();
  const rail = useRail(params.id);
  const currentRole = useCurrentRole();
  const heading = currentRole.canEditRails ? "edit_rail" : "view_rail";

  const location = useLocation();
  const handleGoToList = () => {
    const newLocation = {
      pathname: SETUP_RAILS,
      state: {
        listScrollPos: location.state?.listScrollPos,
        searchTerm: location.state?.searchTerm,
        selectedRailGroup: location.state?.selectedRailGroup,
        selectedSystem: location.state?.selectedSystem,
      },
    };

    history.push(newLocation);
  };

  return (
    <Paper square={true} elevation={0} sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box padding={3} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h1">{t(`railsSetup:${heading}`)}</Typography>
        <RailsListButton onClick={handleGoToList} />
      </Box>

      <Divider />

      {rail ? (
        <SetupForm rail={rail} onSubmitDone={handleGoToList} />
      ) : (
        <StyledBackdrop open={true}>
          <CircularProgress color="inherit" />
        </StyledBackdrop>
      )}
    </Paper>
  );
};

export default RailEdit;
