import React from "react";
import _ from "lodash";

// material-ui
import { makeStyles } from "@mui/styles";
import { List, ListItem, ListItemAvatar, ListItemText, Grid, Typography, Divider } from "@mui/material";

// project imports
import Avatar from "../../ui-component/extended/Avatar";

// assets
import ImageIcon from "@mui/icons-material/ImageTwoTone";
import WorkIcon from "@mui/icons-material/WorkOffTwoTone";
import WarningIcon from "@mui/icons-material/Warning";
import ErrorIcon from "@mui/icons-material/Error";
import { useMessagesForProducerView, useProducer } from "./SLXSlice";
import SubCard from "../../ui-component/cards/SubCard";

import moment from "moment";
import MessageClassIcon from "./MessageClassIcon";

// style constant
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

const MessageItem = ({ message }) => {
  const { messageText, messageId, messageClass, validFrom, producerId } = message;
  const producer = useProducer(producerId);
  const classes = useStyles();
  const vf = moment(validFrom);
  return (
    <>
      <Grid container direction="column" padding={1}>
        <Grid item xs={12}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <Grid item container alignItems="center" justifyContent="space-between">
                <MessageClassIcon messageClass={messageClass} />
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" color="inherit">
                {messageText}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="subtitle2" className={classes.successDark}>
                {`${producer.name} | ${producer.id}`}
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="subtitle2" className={classes.successDark}>
                {`${vf.format("LLL")}`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
    </>
  );
};

const MessageList = ({ producerViewId }) => {
  const classes = useStyles();
  const messages = useMessagesForProducerView(producerViewId);
  const sortedMessages = _.reverse(_.sortBy(messages, "validFrom"));
  return (
    <SubCard
      title={
        <>
          <Typography variant="h3">Messages</Typography>
        </>
      }
    >
      <div className={classes.root}>
        {messages.length ? (
          sortedMessages.map((x) => <MessageItem key={x.id} message={x} />)
        ) : (
          <Typography>No Active Messages</Typography>
        )}
      </div>
    </SubCard>
  );
};

export default MessageList;
