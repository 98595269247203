import React, { SyntheticEvent } from "react";
import { Box, Checkbox } from "@mui/material";
import FormFieldLabel from "../../../../components/SetupPages/FormFieldLabel";

interface Props {
  label: string;
  currentValue: boolean;
  disabled: boolean;
  onChange: (checked: boolean) => void;
};

// TODO: Promote or move where it makes sense
const FormCheckbox = ({ label, currentValue, disabled, onChange }: Props) => {
  const handleCheck = (event: SyntheticEvent<Element, Event>, checked: boolean) => {
    return onChange(checked);
  }

  return (
    <Box display="flex" flexDirection="column" width="fit-content">
      <FormFieldLabel label={label} />
      <Checkbox checked={currentValue} disabled={disabled} onChange={handleCheck} />
    </Box>
  );
};

export default FormCheckbox;
