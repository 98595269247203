import React from "react";
import { useRole } from "../selectors/useRole";
import { useUser } from "../selectors/useUser";
import UserCard from "./UserCard";

const LoginSelectionUserCard = ({ userId, onClick, highlight }) => {
  const user = useUser(userId);
  const { firstname, lastname, employeeNumber, roleId, imageUrl, login } = user;
  const role = useRole(roleId) || {};

  return (
    <UserCard
      name={`${firstname || ""} ${lastname || ""}` || login}
      employeeNumber={employeeNumber || ""}
      imageUrl={imageUrl}
      roleName={role.name || ""}
      border={true}
      highlight={highlight}
      onClick={onClick}
    />
  );
};

export default LoginSelectionUserCard;
