import React from "react";
import { Button, Hidden } from "@mui/material";
import { setListType } from "../../redux/productionListEditor";
import { ProductionListType } from "../../constants/ProductionListType";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import LowPriorityIcon from "@mui/icons-material/LowPriority";

const SequentialButton = () => {
  const dispatch = useDispatch();
  const { enableListType, listType } = useSelector((state) => state.productionListEdit);
  const { t } = useTranslation();

  return (
    <Button
      variant="contained"
      disabled={!enableListType}
      color={listType === ProductionListType.SEQUENTIAL ? "secondary" : "inherit"}
      startIcon={<LowPriorityIcon />}
      onClick={() => dispatch(setListType(ProductionListType.SEQUENTIAL))}
    >
      <Hidden smDown>{t("productionList:sequential")}</Hidden>
    </Button>
  );
};

export default SequentialButton;
