import React from "react";
import _, { sortBy } from "lodash";
import { Grid, Typography } from "@mui/material";
import GroupIcon from "@mui/icons-material/Workspaces";
import { useTranslation } from "react-i18next";
import FlagCardSlim from "../../../../components/FlagSelectors/FlagCardSlim";
import { sortingComparer } from "../../../../utils/sort";
import { updateSelectionsWithPick } from "../../utils";
import type { StorageRuleset } from "../../dto/StorageRuleset";
import type { FilterOptions } from "../../dto/FilterOptions";
import type { StorageGroup } from "../../dto/StorageGroup";
import type { Order } from "../../dto/Order";
import { useGetStorageGroupsQuery } from "../../services/storageGroups";

interface Props {
  systemId: number;
  selections: number[];
  hiddenSelections?: number[];
  disabled: boolean;
  filterOptions: FilterOptions;
  onSelect: (selections: number[]) => void;
};

const filterStorageGroupsByTerm = (props: { storageGroups?: StorageGroup[], searchTerm?: string }) => {
  const { storageGroups = [], searchTerm = "" } = props;
  const term = _.toLower(searchTerm);

  if (term.length < 1) return storageGroups;
  return storageGroups.length > 0 ? storageGroups.filter((storageGroup) => {
    const name = _.toLower(storageGroup.name || "");
    return (term === `${storageGroup.id}`) || name?.includes(term);
  }) : [];
};

interface StorageGroupSorting { storageGroups?: StorageGroup[], sortBy: FilterOptions["sortBy"], order: Order };
const sortStorageGroups = ({ storageGroups = [], sortBy, order }: StorageGroupSorting) => {
  if (storageGroups?.length < 1) return [];
  return storageGroups.sort((a, b) => {
    let valueA = sortBy === "name" ? a.name : a.id;
    let valueB = sortBy === "name" ? b.name : b.id;

    return sortingComparer(valueA, valueB, order);
  });
};

const StorageGroupSelectionView = (props: Props) => {
  const { systemId, selections, hiddenSelections = [], disabled, filterOptions, onSelect } = props;
  const { t } = useTranslation();
  const storageGroupsQuery = useGetStorageGroupsQuery();
  const allStorageGroups = storageGroupsQuery.data || [];
  const storageGroupsForSystem = allStorageGroups.length > 0 ?
    allStorageGroups.filter((sg) => sg.systemId === systemId) :
    [];
  const filteredStorageGroups =
    filterStorageGroupsByTerm({
      storageGroups: storageGroupsForSystem,
      searchTerm: filterOptions.searchTerm,
    }).reduce((acc, sg) => {
      if (!hiddenSelections.includes(sg.id)) {
        acc.push(sg);
      };
      return acc;
    }, [] as StorageGroup[]);
  const sortedStorageGroups = sortStorageGroups({
    storageGroups: filteredStorageGroups,
    sortBy: filterOptions.sortBy,
    order: filterOptions.order,
  });

  const handleSelect = (id: number) => !disabled && onSelect(updateSelectionsWithPick({ selections, id }));

  return (
    <Grid container spacing={2}>
      {sortedStorageGroups?.length > 0 ? (
        sortedStorageGroups.map((storageGroup) => (
          <Grid item key={storageGroup.id} sm={4} md={3}>
            <FlagCardSlim
              imageUrl={""}
              imageIcon={<GroupIcon />}
              color={""}
              number={storageGroup.id}
              name={storageGroup.name}
              disabled={disabled}
              border={true}
              highlight={selections?.includes(storageGroup?.id)}
              onClick={() => handleSelect(storageGroup.id)}
            />
          </Grid>
        ))
      ) : (
        <Grid item>
          <Typography>{t("common:none")}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default StorageGroupSelectionView;
