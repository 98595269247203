import { useLiveWeightForRailId } from "../../../selectors/rails";
import type { SlingInputStation } from "../../../dto/eVue/SlingInputStation";

const useGoodsWeight = (sis: SlingInputStation) => {
  const liveWeight = useLiveWeightForRailId(sis?.railId) || 0;
  if (!sis) return 0;

  const cartWeight1 = sis.cartWeight1 || 0;
  const cartWeight2 = sis.cartWeight2 || 0;
  const weight1 = sis.weight1 || 0;

  if (weight1 > 0) {
    return liveWeight - cartWeight1 - cartWeight2 - weight1;
  } else {
    return liveWeight - cartWeight1;
  };
};

export default useGoodsWeight;
