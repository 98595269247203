import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Button, FormControlLabel } from "@mui/material";
import RemoveIcon from "@mui/icons-material/RemoveCircleOutlineSharp";
import { getRailVisitsCountForRail } from "../../selectors/railVisits";
import { useSlingInputStation } from "../../selectors/slingInputStations";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "50%",
    backgroundColor: theme.palette.error["main"],
    color: "white",

    "&:disabled": {
      backgroundColor: theme.palette.grey[200],
    },
    "&:hover:enabled": {
      backgroundColor: theme.palette.error["dark"],
    },
  },
  xLargeButton: {
    padding: theme.spacing(2),
    fontSize: 40,

    "& #start-icon": {
      fontSize: 40,
    },
  },
}));

const BagRemovedButtonContent = ({ sx, size, disabled, onClick }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <FormControlLabel
      control={
        <Button
          className={clsx({
            [classes.button]: true,
            [classes.xLargeButton]: size === "xLarge",
          })}
          color="error"
          size={size === "xLarge" ? "large" : size}
          disabled={disabled}
          onClick={onClick}
        >
          <RemoveIcon id="start-icon" />
        </Button>
      }
      label={t("slingInputStation:bag_removed")}
      labelPlacement="top"
      sx={sx}
    />
  );
};

const ConciseBagRemovedButton = ({ sisId, ...rest }) => {
  const sis = useSlingInputStation(sisId) || {};
  const railVisitCount = useSelector((state) => getRailVisitsCountForRail(state, sis.railId));

  if (railVisitCount < 1) return null;
  return <BagRemovedButtonContent {...rest} />;
};

ConciseBagRemovedButton.defaultProps = {
  size: "medium",
};

ConciseBagRemovedButton.propTypes = {
  sisId: PropTypes.number,
  size: PropTypes.oneOf(["small", "medium", "large", "xLarge"]),
  disabled: PropTypes.bool,
};

export default ConciseBagRemovedButton;
