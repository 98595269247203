import React, { useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Box, Button, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/CheckCircleOutlineSharp";
import EditGroupIcon from "@mui/icons-material/AppRegistration";
import SelectionDialog from "../../../../components/SelectionDialog";
import FlagCard from "../../../../components/FlagCard";
import Loader from "../../../../components/Loader";
import { useCategories } from "../../../../selectors/categories";
import { useGetStorageGroupsQuery, useUpdateStorageGroupMutation } from "../../services/storageGroups";
import type { StorageGroup } from "../../dto/StorageGroup";
import type { StorageGroupAssignment } from "../../dto/StorageGroupAssignment";
import type{ Category } from "../../../../dto/eVue/Category";
import FilterControls from "../../../../components/FlagSelectors/FilterControls";
import { filterAndSortSelections } from "../../../../components/FlagSelectors/utilities";
import SaveButton from "../../../../components/SaveButton";
import useSortingAndFiltering from "../../hooks/useSortingAndFiltering";
import useStorageGroupAlertMessages from "../../hooks/useStorageGroupAlertMessages";

interface Props {
  storageGroupId: number;
  disabled: boolean;
};

interface SelectorProps {
  storageGroupId: number;
  onClose: () => void;
  onSubmit: (storageGroup: StorageGroup) => void;
};

interface ReservedCategoriesProps {
  storageGroups: StorageGroup[];
  currentStorageGroup?: StorageGroup;
};

interface SelectableCategoriesProps {
  categories: Category[];
  reservedCategoryIds: number[];
};

const getReservedCategoryIds = ({ storageGroups, currentStorageGroup }: ReservedCategoriesProps) => {
  if (!currentStorageGroup) return [];
  const storageGroupsForSystem = storageGroups?.filter((sg) => sg.systemId === currentStorageGroup.systemId) || [];
  const remainingCategories = storageGroupsForSystem.reduce((acc: number[], sg: StorageGroup) => {
    if (sg.id === currentStorageGroup?.id) return acc;
    const assignments = sg.storageGroupAssignments || [];
    assignments.forEach((as) => as && acc.push(as?.sortClassificationId))

    return acc
  }, []);

  return remainingCategories;
};

const getSelectableCategories = ({ categories, reservedCategoryIds }: SelectableCategoriesProps) => {
  return categories.slice().filter((cat) => !reservedCategoryIds.includes(cat.id));
};

const MultiCategorySelector = ({ storageGroupId, onSubmit, onClose }: SelectorProps) => {
  const { t } = useTranslation();

  const storageGroupsQuery = useGetStorageGroupsQuery();
  const storageGroups: StorageGroup[] = storageGroupsQuery.data || [];

  const currentStorageGroup = storageGroups.find((sg) => sg.id === storageGroupId);
  const reservedCategoryIds = getReservedCategoryIds({ storageGroups, currentStorageGroup });
  const categories = useCategories();
  const selectableCategories = getSelectableCategories({ categories, reservedCategoryIds });

  const { order, sortBy, handleChangeSortBy, searchTerm, handleChangeSearchTerm } = useSortingAndFiltering();
  const sortedFilteredCategories: Category[] | [] = filterAndSortSelections({
    selections: selectableCategories.slice(),
    searchTerm,
    sortBy,
    order,
  }) || [];

  const [groupSelections, setGroupSelections] = useState<StorageGroupAssignment[]>(
    currentStorageGroup?.storageGroupAssignments || []
  );
  const handleOnClickCategory = (catId: number) => {
    const updatedSelections = groupSelections.slice();
    const foundIndex = updatedSelections.findIndex((gs) => gs?.sortClassificationId === catId);
    if (foundIndex >= 0) {
      updatedSelections.splice(foundIndex, 1);
    } else {
      if (!currentStorageGroup) return null;
      updatedSelections.push({
        storageGroupId: currentStorageGroup.id,
        sortClassificationId: catId,
      })
    };

    setGroupSelections(updatedSelections);
  };

  const handleOnSubmit = () => {
    if (!currentStorageGroup) return null;
    onSubmit({
      ...currentStorageGroup,
      storageGroupAssignments: groupSelections,
    });
  };

  return (
    <SelectionDialog
      open={true}
      title=""
      subtitle=""
      fullWidth={true}
      maxWidth="md"
      closeOnClick={false}
      handleClose={onClose}
      header={
        <Box display="flex" flexDirection="column" px={3} py={2}>
          <Typography variant="h3">{t("common:select_categories")}</Typography>

          <FilterControls
            sx={{ mt: 2 }}
            sortBy={sortBy}
            order={order}
            onChangeSortBy={handleChangeSortBy}
            onChangeSearchTerm={handleChangeSearchTerm}
          />
        </Box>
      }
      footer={
        <Box display="flex" justifyContent="flex-end">
          <Button
            sx={{ mr: 2 }}
            variant="outlined"
            autoFocus
            disableElevation
            onClick={onClose}
            startIcon={<CloseIcon />}
          >
            {t("common:close")}
          </Button>

          <SaveButton startIcon={<CheckIcon />} onClick={handleOnSubmit}>
            {t("common:submit")}
          </SaveButton>
        </Box>
      }
    >
      {sortedFilteredCategories.length > 0 ?
        sortedFilteredCategories.map((selection) => (
          <FlagCard
            key={selection.id}
            name={selection.name}
            label={selection.number}
            imageUrl={selection.imageUrl}
            color={selection.color}
            handleClick={() => handleOnClickCategory(selection.id)}
            highlight={groupSelections.findIndex((s) => s?.sortClassificationId === selection.id) >= 0}
          />
        )) :
        [<Typography>{t("common:none")}</Typography>]
      }
    </SelectionDialog>
  )
}

const AssignCategoriesButton = ({ storageGroupId, disabled }: Props) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const { onSuccess, onFailure } = useStorageGroupAlertMessages();
  const [updateStorageGroup, { isLoading }] = useUpdateStorageGroupMutation();
  const handleOnSubmit = (storageGroup: StorageGroup) => {
    updateStorageGroup(storageGroup)
      .unwrap()
      .then((payload) => {
        onSuccess();
        setOpen(false);
      })
      .catch((error) => onFailure(error));
  };

  return (
    <>
      <IconButton color="primary" disabled={disabled} onClick={() => setOpen(true)}>
        <EditGroupIcon sx={{ fontSize: 30 }} />
      </IconButton>

      {isLoading && (
        <Loader open={isLoading} message={t("storageManager:saving_storage_group")} transitionDuration={300} />
      )}

      {open && (
        <MultiCategorySelector storageGroupId={storageGroupId} onClose={() => setOpen(false)} onSubmit={handleOnSubmit} />
      )}
    </>
  );
};

export default AssignCategoriesButton;
