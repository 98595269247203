import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const machineBarSlice = createSlice({
  name: "machineBar",
  initialState: {
    open: false,
  },
  reducers: {
    setMachineBarOpen: (state, action) => {
      state.open = action.payload;
    },
  },
});

export const { setMachineBarOpen } = machineBarSlice.actions;

// SELECTORS
export const machineBarOpenSelector = (state) => state.machineBar.open;

export default machineBarSlice.reducer;
