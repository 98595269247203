import React from "react";
import { Button } from "@mui/material";
import BagsIcon from "@mui/icons-material/BlurLinear";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SETUP_BAGS } from "../../constants/Paths";

const BagsSetupButton = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Button
      variant="outlined"
      size="large"
      color="primary"
      startIcon={<BagsIcon />}
      onClick={() => history.push(SETUP_BAGS)}
    >
      {t("common:bags")}
    </Button>
  );
};

export default BagsSetupButton;
