import React from "react";
import { useTranslation } from "react-i18next";
import FormFieldGroupLabel from "../SetupPages/FormFieldGroupLabel";
import FormCheckbox from "../SetupPages/FormCheckbox";

const FormReportsCheckboxes = ({ currentValues, disabled, onArrayFieldChange }) => {
  const { t } = useTranslation();
  const reportList = currentValues["reportList"];

  return (
    <>
      <FormFieldGroupLabel label={t("usersSetup:types_of_reports")} />

      {reportList.map((report) => (
        <FormCheckbox
          key={report.reportKey}
          fieldName={report.reportKey}
          label={report.title}
          currentValues={{ [report.reportKey]: report.sendReport }}
          disabled={disabled}
          onFieldChange={() => {
            const field = report.reportKey;
            const checked = !report.sendReport;
            const existingReportIndex = currentValues["reportList"]?.findIndex((report) => report.reportKey === field);

            if (existingReportIndex >= 0) {
              onArrayFieldChange({
                field: "reportList",
                index: existingReportIndex,
                value: { ...report, sendReport: checked },
              });
            }
          }}
        />
      ))}
    </>
  );
};

export default FormReportsCheckboxes;
