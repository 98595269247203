import * as actions from "../actions/types";

const initialState = {
  menuButtons: [],
  laborButtons: [],
  eVuePro: undefined,
  guestRoleId: undefined,
  adminMode: undefined,
  defaultLanguage: "en_US",
  eVueLabor: false,
};

export default function uiSettingReducer(state = initialState, action) {
  switch (action.type) {
    case actions.INITIALIZE_UI_SETTINGS:
      return {
        ...state,
        ...action.data,
        adminMode: action.data.flexAdminMode,
      };
    case actions.UPDATE_UI_SETTINGS:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
}
