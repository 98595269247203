import React from "react";
import dayjs, { Dayjs } from "dayjs";
import { Chip, TableCell, TableRow, TableRowProps } from "@mui/material";
import StopIcon from "@mui/icons-material/HighlightOff";
import WarningIcon from "@mui/icons-material/ErrorOutline";
import InfoIcon from "@mui/icons-material/InfoSharp";
import ServiceIcon from "@mui/icons-material/HelpOutline";
import { SxProps, styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import type { RailMessageEvent } from "../../../dto/eVue/RailMessageEvent";
import { getRailById } from "../../../selectors/rails";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { getSystemByRailId } from "../../../selectors/systems";
import columnWidths from "../columnWidths";

const StyledTableRow = styled(TableRow)<TableRowProps>(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.grey[100],
  },
}));

interface Props {
  railMessageEvent: RailMessageEvent;
};

const formatDuration = ({ startTime, endTime }: { startTime: Dayjs, endTime: Dayjs }) => {
  const diff = endTime.diff(startTime, "second");
  const duration = dayjs.duration({
    minutes: Math.floor(diff / 60) || 0,
    seconds: Math.floor(diff % 60) || 0,
  }).format("mm:ss");

  return duration;
};

const LevelIcon = ({ level, sx }: { level: string, sx?: SxProps }) => {
  let color: "error" | "warning" | "info" | "success" | "primary" = "primary";
  let icon;
  if (level === "STOP") {
    color = "error";
    icon = <StopIcon />;
  } else if (level === "WARNING") {
    color = "warning";
    icon = <WarningIcon />;
  } else if (level === "INFO") {
    color = "info";
    icon = <InfoIcon />;
  } else if (level === "SERVICE") {
    color = "success";
    icon = <ServiceIcon />;
  } else {
    color = "primary";
  };

  return (
    <Chip
      sx={{ ...sx }}
      color={color || "primary"}
      icon={icon}
      label={level}
    />
  )
};

// TODO: Sorting for message_text_id should be done by translation value

const RailMessageEventRow = ({ railMessageEvent }: Props) => {
  const { t } = useTranslation();
  const { railId, level, messageTextId } = railMessageEvent;
  const railName = useAppSelector((state) => getRailById(railId, state)?.name);
  const systemName = useAppSelector((state) => getSystemByRailId(state, railId)?.name);

  const startTime = dayjs(railMessageEvent.startTime);
  const endTime = dayjs(railMessageEvent.endTime);
  const duration = formatDuration({ startTime, endTime });

  const cells: { value: string, align: "left" | "right", width: string }[] = [
    { value: systemName, align: "left", width: columnWidths.system },
    { value: `${railId} - ${railName}`, align: "left", width: columnWidths.rail },
    { value: startTime.format("L LT"), align: "right", width: columnWidths.startTime },
    { value: endTime.format("L LT"), align: "right", width: columnWidths.endTime },
    { value: duration, align: "right", width: columnWidths.duration },
    { value: t(`railMessages:${messageTextId}`), align: "left", width: columnWidths.messageTextId },
    { value: <LevelIcon level={level} />, align: "left", width: columnWidths.level },
  ];

  return (
    <StyledTableRow>
      {cells.map((cell, i) => (
        <TableCell
          key={`rail-message-event-row-${i}`}
          component="th"
          scope="row"
          align={cell.align}
          sx={{ padding: 2, width: cell.width }}
        >
          {cell.value}
        </TableCell>
      ))}
    </StyledTableRow>
  );
};

export default RailMessageEventRow;
