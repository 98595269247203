import { INITIALIZE_SERVER_STATUS, UPDATE_SERVER_STATUS, HEARTBEAT_SUCCESS, HEARTBEAT_FAILURE } from "./types";
import { configureChannel } from "../utils";
import Client from "../api/Client";

export function serverStatusSubscribe() {
  return (dispatch) => {
    // Kickoff the polling heartbeat
    dispatch(apiHeartbeat());

    serverStatusChannel.on("init", (data) => {
      dispatch(initializeServerStatus(data.data));
    });

    serverStatusChannel.on("update", (data) => {
      dispatch(updateServerStatus(data));
    });
  };
}

export function updateServerStatus(data) {
  return {
    type: UPDATE_SERVER_STATUS,
    data: data,
  };
}

export function initializeServerStatus(data) {
  return {
    type: INITIALIZE_SERVER_STATUS,
    data: data,
  };
}

export const serverStatusChannel = configureChannel("server_status");

export const apiHeartbeat = () => {
  return (dispatch, getState) => {
    setTimeout(() => dispatch(apiHeartbeat()), 10000);
    const lastHb = getState().serverStatus.heartbeat;
    Client.get("control_api/v1/heartbeat")
      .then((_res) => {
        if (!lastHb) dispatch({ type: HEARTBEAT_SUCCESS });
      })
      .catch((_err) => {
        dispatch({ type: HEARTBEAT_FAILURE });
      });
  };
};
