import { useEffect, useState } from "react";

let buffer = "";
let timer = null;
const TIME = 500;
const MIN_LENGTH = 5;

/**
 * On KeyDown, start capture of input from the barcode scanner,
 * unless currently typing in an input field
 */
const keyDownHandler = (event, onScan) => {
  const el = event.target.tagName.toLowerCase();
  if (el === "input" || el === "textarea") return;
  if (!timer) start(onScan);

  manageKeyPress(event.key);
};

const manageKeyPress = (key) => {
  switch (key) {
    case "Alt":
    case "ArrowDown":
    case "ArrowLeft":
    case "ArrowRight":
    case "ArrowUp":
    case "Backspace":
    case "CapsLock":
    case "Control":
    case "End":
    case "Enter":
    case "Home":
    case "Meta":
    case "PageDown":
    case "PageUp":
    case "Shift":
    case "Tab":
      // Disregard keys:
      break;

    default:
      buffer += key;
      break;
  }
};

/**
 * Start the capture of input, clearing the buffer and
 * starting a timer for the specified `TIME` length.
 */
const start = (onScan) => {
  buffer = "";
  const barcodeScanHandler = () => timerHandler(onScan);
  timer = setTimeout(barcodeScanHandler, TIME);
};

/**
 * Clear the text buffer and timer.
 */
const reset = () => {
  buffer = "";
  timer = clearTimeout(timer);
};

const timerHandler = (onScan) => {
  if (buffer.trim()?.length >= MIN_LENGTH && isBarcode(buffer)) {
    const splitBuffer = buffer
      .trim()
      .split(/\*|,/)
      ?.filter((str) => str);
    const customer = splitBuffer?.at(0);
    const category = splitBuffer?.at(1);
    onScan(customer, category);
  }

  reset();
};

/**
 * Determines if input is barcode scan.
 */
export const isBarcode = (buffer) => buffer.trim()[0] === "*" && buffer.trim().slice(-1) === "*";

/**
 * React hook for barcode scans.
 */
export const useTicketScanner = () => {
  const [scannedValues, setScannedValues] = useState({ scanned: 0 });

  useEffect(() => {
    const handleScan = (customerNumber, categoryNumber) => {
      setScannedValues((scannedValues) => ({ customerNumber, categoryNumber, scanned: scannedValues.scanned + 1 }));
    };
    const scanHandler = (e) => keyDownHandler(e, handleScan);
    window.addEventListener("keydown", scanHandler, { capture: true });

    return () => window.removeEventListener("keydown", scanHandler, { capture: true });
  }, []);

  return [scannedValues?.customerNumber, scannedValues?.categoryNumber, scannedValues?.scanned];
};
