import React from "react";
import { Card, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNormWeight, useProducerView } from "./SLXSlice";

import { useParams } from "react-router-dom";
import MessageList from "./MessageList";
import OperatorList from "./OperatorList";
import { useTheme } from "@emotion/react";
import { useWeightUnit } from "../../selectors/uiSettings";
import EquipmentTile from "./EquipmentTile";
import StatCard from "./StatCard";
import TimelineIcon from "@mui/icons-material/Timeline";

import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";

const gridSpacing = 3;
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  titleBar: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
}));

const SLXProducerViewView = () => {
  const classes = useStyles();
  const { id: producerViewId } = useParams();
  const producerView = useProducerView(producerViewId);

  const theme = useTheme();
  const wtUnit = useWeightUnit();
  const productionPieces = producerView.unit === "pieces";
  const { actualKilogramPerformance: weight, actualPiecesPerformance: pieces } = producerView.performance || {};
  const displayWeight = Math.round(useNormWeight(weight));
  const tileWidth = producerView?.producerIds?.length > 2 ? 3 : 4;

  if (!producerView.id) return null;

  return (
    <div className={classes.root}>
      <Card className={classes.titleBar}>
        <Grid container direction="row" spacing={2}>
          <Grid item>
            <Typography variant="h1" gutterBottom>
              {producerView.name}
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2}>
          {producerView.producerIds.map((pid) => (
            <Grid item xs={12} sm={6} md={tileWidth}>
              <EquipmentTile producerId={pid} />
            </Grid>
          ))}
          <Grid item xs={tileWidth}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <StatCard
                  color={theme.palette.success.dark}
                  title={productionPieces ? "Pieces" : `${wtUnit}`}
                  iconPrimary={TimelineIcon}
                  primary={productionPieces ? pieces?.toLocaleString() : displayWeight?.toLocaleString()}
                />
              </Grid>
              {producerView.hasOperatorTracking && (
                <Grid item>
                  <StatCard
                    color={theme.palette.warning.dark}
                    title="Op Hours"
                    iconPrimary={SentimentVerySatisfiedIcon}
                    primary={`${(producerView?.operatorHours || 0).toFixed(2)} hrs`}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Card>

      <Grid container direction="column" spacing={gridSpacing}>
        {producerView.hasOperatorTracking && (
          <Grid item>
            <Grid container alignItems={"stretch"}>
              <Grid item xs={12}>
                <OperatorList producerViewId={producerView.id} />
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item lg={6} xs={12}>
          <MessageList producerViewId={producerView.id} />
        </Grid>
      </Grid>
    </div>
  );
};

export default SLXProducerViewView;
