import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import * as d3 from "d3";
import { v4 as uuidv4 } from "uuid";
import { useWindowDimensions } from "../../utils/resize";

class BagUpdater {
  constructor({ wrapperId, updaterId, percent, config = {}, classes, status }) {
    const defaultConfig = this.getDefaultConfig();
    this.config = { ...defaultConfig, ...config };
    this.wrapperId = wrapperId;
    this.updaterId = updaterId;
    this.classes = classes;

    this.bagSVG = null;

    this.allowedHeight = null;
    this.waveCountForClip = null;
    this.waveClipWidth = null;

    this.bagGroup = null;
    this.bagOutlineId = `outline-${updaterId}`;
    this.bagWaveId = `wave-${updaterId}`;
    this.wave = null;

    this.waveScales = {
      waveAnimateScale: null,
      waveScaleX: null,
      waveScaleY: null,
      waveRiseScale: null,
    };

    this.initializeUpdater({ percent, status });
  }

  getDefaultConfig = () => {
    return {
      waveHeight: 0.05, // The wave height as a percentage of the bag's inner height.
      waveCount: 1, // The number of full waves per width of the bag.
      waveRiseTime: 1000, // The amount of time in milliseconds for the wave to rise from 0 to its final height.
      waveAnimateTime: 1000, // The amount of time in milliseconds for a full wave to enter the bag.
      waveHeightScaling: true, // Controls wave size scaling at low and high fill percentages. When true, wave height reaches it's maximum at 50% fill, and minimum at 0% and 100% fill. This helps to prevent the wave from making the bag appear totally full or empty when near it's minimum or maximum fill.
      startRiseFromZero: true,
      animateWaveSideScroll: true,
    };
  };

  initializeUpdater = ({ status, percent }) => {
    this.appendSVGToWrapper();

    const gaugeBoundClient = this.bagSVG.node().getBoundingClientRect();
    this.allowedHeight = gaugeBoundClient.height;
    const waveLength = this.allowedHeight / this.config.waveCount;
    this.waveCountForClip = 1 + this.config.waveCount;
    this.waveClipWidth = waveLength * this.waveCountForClip;

    this.drawBag({ status });

    const waveHeight = this.generateWaveHeight(percent);
    this.generateScalesForWaveClipPath(waveHeight);
    this.insertWaveClipPathInBag(waveHeight);

    this.moveWaveVertically({ percent, initialRendering: true });
    if (this.config.animateWaveSideScroll) {
      this.startPerpetualWaveSideScroll();
    }
  };

  appendSVGToWrapper = () => {
    const wrapperSelection = d3.select(`#${this.wrapperId}`);
    this.bagSVG = wrapperSelection
      .append("svg")
      .attr("id", this.updaterId)
      .attr("width", "100%")
      .attr("height", wrapperSelection.node().clientHeight - 25);
  };

  generateWaveHeightScale = () => {
    const { waveHeightScaling, waveHeight } = this.config;
    if (waveHeightScaling) {
      return d3.scaleLinear().range([0, waveHeight, 0]).domain([0, 50, 100]);
    } else {
      return d3.scaleLinear().range([waveHeight, waveHeight]).domain([0, 100]);
    }
  };

  regenerateWave = (percent) => {
    const waveHeight = this.generateWaveHeight(percent);
    this.generateScalesForWaveClipPath(waveHeight);
    const waveClipArea = this.generateNewClipArea(waveHeight);
    this.wave.attr("d", waveClipArea).attr("T", 0);
  };

  generateWaveHeight = (percent) => {
    const contentOutline = d3.select(`#${this.bagWaveId}`).node().getBoundingClientRect();
    const waveHeightScale = this.generateWaveHeightScale();
    return (contentOutline.height / 2) * waveHeightScale(percent);
  };

  generateScalesForWaveClipPath = (waveHeight) => {
    // Scales for controlling the size of the clipping path.
    const waveScaleX = d3.scaleLinear().range([0, this.waveClipWidth]).domain([0, 1]);
    const waveScaleY = d3.scaleLinear().range([0, waveHeight]).domain([0, 1]);

    // Scales for controlling the position of the clipping path.
    const innerHeight = d3.select(`#${this.bagWaveId}`).node().getBoundingClientRect();
    const waveRiseScale = d3
      .scaleLinear()
      .range([innerHeight.height + waveHeight, waveHeight])
      .domain([0, 100]);
    const waveAnimateScale = d3
      .scaleLinear()
      .range([0, this.waveClipWidth - this.allowedHeight])
      .domain([0, 1]);

    this.waveScales = { waveAnimateScale, waveScaleX, waveScaleY, waveRiseScale };
  };

  drawBag = ({ status }) => {
    const svgPathData = this.generateSVGPathData(this.allowedHeight);
    this.bagGroup = this.createAndCenterBagGroupInSVG(svgPathData);
    this.drawBagOutline({ svgPathData, status });
    this.drawBagContentOutline({ svgPathData, status });
  };

  generateSVGPathData = (allowedHeight) => {
    const outterPath =
      "M 0 18.5 c 0 1.4 1 2 2 2 h 8 c 1.1 0 2 -1.1 2 -2 V 3.5 H 0 V 18.5 M 2 5.5 L 10 5.5 L 10 17.5 C 10 17.5 10 18.5 9 18.5 L 3 18.5 C 2 18.5 2 17.5 2 17.5 L 2 5.5 z M 2.5 1.5 A 1 1 0 0 0 5.5 1.5 A 1 1 0 0 0 2.5 1.5 z M 6.5 1.5 A 1 1 0 0 0 9.5 1.5 A 1 1 0 0 0 6.5 1.5";

    const innerPath = "M2.5 6 2.5 17C2.5 17 2.5 18 3.5 18L8.5 18C8.5 18 9.5 18 9.5 17L9.5 6 2.5 6Z";
    const height = 20.5;

    return {
      outterPath,
      innerPath,
      height,
      width: 12,
      scale: parseFloat(allowedHeight / height),
    };
  };

  createAndCenterBagGroupInSVG = (svgPathData) => {
    const gaugeBoundClient = this.bagSVG.node().getBoundingClientRect();
    const scaledSVGWidth = svgPathData.scale * svgPathData.width;
    const svgXPos = gaugeBoundClient.width / 2 - scaledSVGWidth / 2;

    return this.bagSVG.append("g").attr("transform", `translate(${svgXPos}, 0)`);
  };

  drawBagOutline = ({ svgPathData, status }) => {
    return this.bagGroup
      .append("path")
      .attr("id", this.bagOutlineId)
      .attr("d", svgPathData.outterPath)
      .attr("class", this.determineStylesFromStatus({ status, outline: true }))
      .attr("transform", `scale(${svgPathData.scale})`);
  };

  drawBagContentOutline = ({ svgPathData, status }) => {
    const clipPathId = `clipPath-${this.updaterId}`;
    this.bagGroup
      .append("g")
      .attr("clip-path", `url(#${clipPathId})`)
      .append("path")
      .attr("id", this.bagWaveId)
      .attr("d", svgPathData.innerPath)
      .attr("class", this.determineStylesFromStatus({ status }))
      .attr("transform", `scale(${svgPathData.scale})`);
  };

  determineStylesFromStatus = ({ outline, status }) => {
    if (outline) {
      if (status === "overweight") return this.classes.outlineOverweight;
      if (status === "underweight") return this.classes.outlineUnderweight;
      if (["target_met", "almost_full", "full"].includes(status)) return this.classes.outlineOnTarget;
      return this.classes.outlineNormal;
    } else {
      if (status === "overweight") return this.classes.liquidOverweight;
      if (status === "underweight") return this.classes.liquidUnderweight;
      if (["target_met", "almost_full", "full"].includes(status)) return this.classes.liquidOnTarget;
      return this.classes.liquidNormal;
    }
  };

  insertWaveClipPathInBag = (waveHeight) => {
    const clipPathId = `clipPath-${this.updaterId}`;
    const waveGroup = this.bagGroup.append("defs").append("clipPath").attr("id", clipPathId);

    const singleWaveWidth = 40;
    const waveClipAreaData = [];
    for (var i = 0; i <= singleWaveWidth * this.waveCountForClip; i++) {
      waveClipAreaData.push({
        x: i / (singleWaveWidth * this.waveCountForClip),
        y: i / singleWaveWidth,
      });
    }
    const waveClipArea = this.generateNewClipArea(waveHeight);
    const wave = waveGroup.append("path").datum(waveClipAreaData).attr("d", waveClipArea).attr("T", 0);

    this.waveGroup = waveGroup;
    this.wave = wave;
  };

  generateNewClipArea = (waveHeight) => {
    const { waveScaleX, waveScaleY } = this.waveScales;
    const waveCeiling = this.allowedHeight + waveHeight;
    return d3
      .area()
      .x((d) => waveScaleX(d.x))
      .y1((d) => waveCeiling)
      .y0((d) => {
        const fullCircle = Math.PI * 2;

        const waveCountOffset = 1;
        const offsetFullCircle = fullCircle;
        const offsetWaveCount = waveCountOffset - this.config.waveCount;

        const currentYMultiplied = d.y * fullCircle;
        const degrees = fullCircle * offsetFullCircle * offsetWaveCount + currentYMultiplied;
        const yCoordinate = Math.sin(degrees);

        const waveFloor = waveScaleY(yCoordinate);
        const roundedWaveFloor = Math.round(waveFloor * 100) / 100;

        return roundedWaveFloor;
      });
  };

  moveWaveVertically = ({ percent = 0, initialRendering }) => {
    const translateToNewValue = (newValue) => {
      const waveGroupXPosition = this.allowedHeight - this.waveClipWidth;
      const boundClient = d3.select(`#${this.bagWaveId}`).node().getBoundingClientRect();
      const slightOffset = boundClient.height * (percent > 0 ? 0.05 : 0);
      const startingYCoor = boundClient.height / 2 - slightOffset;
      return `translate(${waveGroupXPosition}, ${startingYCoor + this.waveScales.waveRiseScale(newValue)})`;
    };

    if (initialRendering) {
      if (this.config.startRiseFromZero) {
        this.waveGroup
          .attr("transform", translateToNewValue(0))
          .transition()
          .duration(this.config.waveRiseTime)
          .attr("transform", translateToNewValue(percent));
      } else {
        this.waveGroup.attr("transform", translateToNewValue(percent));
      }
    } else {
      this.waveGroup.transition().duration(this.config.waveRiseTime).attr("transform", translateToNewValue(percent));
    }
  };

  startPerpetualWaveSideScroll = () => {
    const xCoor = 0;
    const yCoor = 0;

    const snapWaveBackToStart = () => {
      this.wave.attr("T", 0);
      this.wave.attr("transform", `translate(${xCoor}, ${yCoor})`);
    };

    this.wave
      .transition()
      .duration(this.config.waveAnimateTime * (1 - this.wave.attr("T")))
      .ease(d3.easeLinear)
      .attr("T", 1)
      .attr("transform", `translate(${this.waveScales.waveAnimateScale(1)}, ${yCoor})`)
      .on("end", () => {
        snapWaveBackToStart();
        this.startPerpetualWaveSideScroll();
      });
  };

  redraw = ({ percent, status, boundsChanged }) => {
    if (boundsChanged) {
      this.remove();
      this.initializeUpdater({ percent, status });
    } else {
      const bagOutline = d3.select(`#${this.bagOutlineId}`);
      bagOutline.attr("class", this.determineStylesFromStatus({ status, outline: true }));

      const bagWave = d3.select(`#${this.bagWaveId}`);
      bagWave.attr("class", this.determineStylesFromStatus({ status }));
    }
  };

  update = (percent) => {
    this.regenerateWave(percent);
    this.moveWaveVertically({ percent });
  };

  remove = () => {
    this.bagSVG.remove();
  };
}

const useStyles = makeStyles((theme) => ({
  outlineUnderweight: {
    fill: theme.palette.warning["dark"],
  },
  outlineNormal: {
    fill: theme.palette.info["dark"],
  },
  outlineOnTarget: {
    fill: theme.palette.success["main"],
  },
  outlineOverweight: {
    fill: theme.palette.error["main"],
  },
  liquidUnderweight: {
    fill: theme.palette.warning["main"],
  },
  liquidNormal: {
    fill: theme.palette.info["light"],
  },
  liquidOnTarget: {
    fill: theme.palette.success["light"],
  },
  liquidOverweight: {
    fill: theme.palette.error["light"],
  },
}));

const WeightIndicatorBag = ({ position, percent, status }) => {
  const classes = useStyles();
  const gaugeRef = useRef();
  const [wrapperId, setWrapperId] = useState(`bag-wrapper-${uuidv4()}`);
  const displayedPercent = percent > 100 ? 100 : percent;

  useEffect(() => {
    gaugeRef.current = new BagUpdater({
      wrapperId: wrapperId,
      updaterId: `bag-weight-indicator-${uuidv4()}`,
      percent: displayedPercent,
      classes,
      status,
    });

    return () => {
      if (gaugeRef.current) {
        gaugeRef.current.remove();
      }
    };
  }, []);

  useEffect(() => {
    if (gaugeRef.current) {
      gaugeRef.current.update(displayedPercent);
    }
  }, [displayedPercent]);

  useEffect(() => {
    if (gaugeRef.current) {
      gaugeRef.current.redraw({ status });
    }
  }, [status]);

  const [width, height] = useWindowDimensions();
  useEffect(() => {
    if (gaugeRef.current) {
      gaugeRef.current.redraw({ percent: displayedPercent, status, boundsChanged: true });
    }
  }, [width, height]);

  return <Box id={wrapperId} display="flex" justifyContent="center" width="100%" height="inherit"></Box>;
};

WeightIndicatorBag.propTypes = {
  position: PropTypes.number,
  percent: PropTypes.number,
  status: PropTypes.string,
};

WeightIndicatorBag.defaultProps = {
  position: 1,
  percent: 0,
};

export default WeightIndicatorBag;
