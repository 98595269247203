import React, { useState, useEffect } from "react";
import { Backdrop, Box, CircularProgress, Divider, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { fetchUser, fetchUserSetupOptions } from "../../api/UserApi";
import { SETUP_USERS } from "../../constants/Paths";
import SetupForm from "./SetupForm";
import UsersListButton from "./UsersListButton";

const UserEdit = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [user, setUser] = useState();
  const [setupData, setSetupData] = useState();
  const params = useParams();
  const isNew = params.id === "new";

  useEffect(() => {
    fetchUserSetupOptions({ successCB: (data) => setSetupData({ ...data }) });

    if (!isNew) {
      fetchUser({
        userId: params.id,
        successCB: (data) => {
          setUser({ ...data.user, password: "", passwordConfirmation: "" });
        },
      });
    } else {
      setUser({});
    }
  }, []);

  let heading = isNew ? "add_user" : "edit_user";
  if (user?.deletedAt) {
    heading = "view_deleted_user";
  }

  const location = useLocation();
  const handleGoToList = () => {
    const newLocation = {
      pathname: SETUP_USERS,
      state: { listScrollPos: location.state?.listScrollPos, searchTerm: location.state?.searchTerm },
    };

    history.push(newLocation);
  };

  return (
    <Paper square={true} elevation={0} sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box padding={3} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h1">{t(`usersSetup:${heading}`)}</Typography>
        <UsersListButton onClick={handleGoToList} />
      </Box>

      <Divider />

      {user && setupData ? (
        <SetupForm user={user} isNew={isNew} setupData={setupData} onSubmitDone={handleGoToList} />
      ) : (
        <Backdrop open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Paper>
  );
};

export default UserEdit;
