import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import BackspaceIcon from "@mui/icons-material/Backspace";

const useStyles = makeStyles((theme) => ({
  numberButton: {
    height: 90,
    width: 90,
    color: theme.palette.common.white,
  },
}));

const NumberButton = ({ number, clickHandler }) => {
  const classes = useStyles();
  return (
    <Button
      variant="contained"
      color="primary"
      size="large"
      className={classes.numberButton}
      onKeyPress={(e) => e.key === "Enter" && e.preventDefault()}
      onClick={() => clickHandler(number)}
    >
      <Typography color="inherit" variant="h3">
        {number}
      </Typography>
    </Button>
  );
};

const Keypad = ({ onClickNumber, onClear, onDelete }) => {
  const classes = useStyles();
  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Grid container direction="row" spacing={1} justify="space-between">
          {[1, 2, 3].map((num) => (
            <Grid key={num} item xs={4}>
              <NumberButton number={num} clickHandler={onClickNumber} />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="row" spacing={1} justify="space-between">
          {[4, 5, 6].map((num) => (
            <Grid key={num} item xs={4}>
              <NumberButton number={num} clickHandler={onClickNumber} />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="row" spacing={1} justify="space-between">
          {[7, 8, 9].map((num) => (
            <Grid key={num} item xs={4}>
              <NumberButton number={num} clickHandler={onClickNumber} />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="row" spacing={1} justify="space-between">
          <Grid item xs={4}>
            <Button variant="contained" color="primary" size="large" className={classes.numberButton} onClick={onClear}>
              <Typography color="inherit" variant="h3">
                C
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={4}>
            <NumberButton number={0} clickHandler={onClickNumber} />
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.numberButton}
              onClick={onDelete}
            >
              <BackspaceIcon />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Keypad;
