import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Edit } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { renameConfig } from "../../redux/storageConfigEditor";
import { useCurrentRole } from "../../selectors/useRole";
import TextEditorDialog from "../TextEditorDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 200,
    "&:disabled": {
      backgroundColor: theme.palette.grey[200],
    },
  },
}));

const RenameButton = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { editorName, enableRename } = useSelector((state) => state.storageConfigEdit);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const role = useCurrentRole();

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <Button
        className={classes.root}
        disabled={!enableRename || !role.canEditStorageConfigs}
        variant="outlined"
        onClick={handleDialogOpen}
        startIcon={<Edit />}
      >
        {t("common:rename")}
      </Button>

      <TextEditorDialog
        open={dialogOpen}
        fullWidth={true}
        maxWidth="md"
        message={t("storageAssignments:enter_name")}
        initialValue={editorName}
        onClose={handleDialogClose}
        onSubmit={(name) => dispatch(renameConfig(name)) && handleDialogClose()}
      />
    </>
  );
};

export default RenameButton;
