import React from "react";
import { useTranslation } from "react-i18next";
import FormFieldGroupLabel from "../SetupPages/FormFieldGroupLabel";
import FormCheckbox from "../SetupPages/FormCheckbox";

const FormEmailsCheckboxes = ({ currentValues, disabled, onFieldChange }) => {
  const { t } = useTranslation();
  const emailTypeList = [
    ["receiveEmail", "daily_and_weekly_summary_emails"],
    ["receiveWeeklyMaintenanceEmail", "weekly_maintenance_email"],
    ["receiveDailyMaintenanceEmail", "daily_maintenance_email"],
    ["receiveDailyProductEmail", "daily_product_email"],
    ["receiveDailyCustomerEmail", "daily_customer_email"],
  ];

  return (
    <>
      <FormFieldGroupLabel label={t("usersSetup:types_of_emails")} />

      {emailTypeList.map((emailType) => (
        <FormCheckbox
          key={emailType[0]}
          fieldName={emailType[0]}
          label={t(`usersSetup:${emailType[1]}`)}
          currentValues={currentValues}
          disabled={disabled}
          onFieldChange={onFieldChange}
        />
      ))}
    </>
  );
};

export default FormEmailsCheckboxes;
