import React from "react";
import { Box, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";
import FormFieldLabel from "../../../../components/SetupPages/FormFieldLabel";

interface Props {
  label: string;
  currentValue: string;
  options: { key: string | number, value: string, text: string }[];
  disabled?: boolean;
  showNone?: boolean;
  onChange: (selection: string) => void;
};

const FormDropdown = ({ label, currentValue = "", options, disabled, showNone, onChange }: Props) => {
  const { t } = useTranslation();
  const handleChange = (event: SelectChangeEvent) => onChange(event.target.value);

  return (
    <Box display="flex" flexDirection="column">
      {label && <FormFieldLabel label={label} />}

      <Select
        variant="outlined"
        displayEmpty={true}
        value={currentValue || ""}
        disabled={disabled}
        onChange={handleChange}
      >
        {showNone && (
          <MenuItem value="">
            <em>{t("common:none")}</em>
          </MenuItem>
        )}

        {options.map((option) => (
          <MenuItem key={option.key} value={option.value} disabled={disabled}>
            {option.text}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default FormDropdown;
