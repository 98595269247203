import { ALERT_CLEAR, ALERT_ERROR, ALERT_INFO, ALERT_SUCCESS, ALERT_WARNING } from "./types";

export const showAlertSuccess = (message) => {
  return (dispatch) => {
    dispatch({ type: ALERT_SUCCESS, message });
  };
};

export const showAlertError = (message) => {
  return (dispatch) => {
    dispatch({ type: ALERT_ERROR, message });
  };
};

export const showAlertInfo = (message) => {
  return (dispatch) => {
    dispatch({ type: ALERT_INFO, message });
  };
};

export const showAlertWarning = (message) => {
  return (dispatch) => {
    dispatch({ type: ALERT_WARNING, message });
  };
};

export const clearAlert = () => {
  return (dispatch) => {
    dispatch({ type: ALERT_CLEAR });
  };
};
