import { ProductionListType } from "../constants/ProductionListType";
import { v4 as uuidv4 } from "uuid";
import { productionListCreate, productionListDelete, productionListUpdate } from "../api/ActiveProductionListApi";
import { showAlertError } from "../actions";
import i18n from "../i18n";

const CLEAR = "PRODUCTION_LIST_CLEAR";
const CREATE = "PRODUCTION_LIST_CREATE";
const CREATE_REQUEST = "PRODUCTION_LIST_CREATE_REQUEST";
const CREATE_SUCCESS = "PRODUCTION_LIST_CREATE_SUCCESS";
const DELETE_FAILURE = "PRODUCTION_LIST_DELETE_FAILURE";
const DELETE_REQUEST = "PRODUCTION_LIST_DELETE_REQUEST";
const DELETE_SUCCESS = "PRODUCTION_LIST_DELETE_SUCCESS";
const LOADER_SHOW = "PRODUCTION_LIST_LOADER_SHOW";
const RENAME = "PRODUCTION_LIST_RENAME";
const SELECT_LIST = "PRODUCTION_LIST_SELECT_LIST";
const SET_LIST_TYPE = "PRODUCTION_LIST_SET_LIST_TYPE";
const SET_PICKS = "PRODUCTION_LIST_SET_PICKS";
const SET_SORT = "PRODUCTION_LIST_SET_SORT";
const UPDATE_REQUEST = "UPDATE_REQUEST";
const UPDATE_SUCCESS = "PRODUCTION_LIST_UPDATE_SUCCESS";

const initialState = {
  enableDelete: false,
  enableListType: false,
  enableRename: false,
  enableSave: false,
  listType: null,
  loaderMessage: null,
  loaderOpen: false,
  name: null,
  id: null,
  selectedProductionList: null,
  sortBy: "number",
  picks: [],
};

export const clearEditor = () => ({ type: CLEAR });

export const createList = (name) => {
  return {
    type: CREATE,
    name: name,
  };
};

export const deleteList = () => {
  return (dispatch, getState) => {
    dispatch({ type: DELETE_REQUEST });
    const { id } = getState().productionListEdit;

    productionListDelete(
      id,
      () => dispatch({ type: DELETE_SUCCESS }),
      () => dispatch({ type: DELETE_FAILURE })
    );
  };
};

export const setListType = (listType) => {
  return {
    type: SET_LIST_TYPE,
    listType,
  };
};

export const setPicks = (picks) => {
  return {
    type: SET_PICKS,
    picks,
  };
};

export const setSort = (sortBy) => {
  return {
    type: SET_SORT,
    sortBy,
  };
};

export const renameList = (name) => {
  return {
    type: RENAME,
    name,
  };
};

export const selectList = (productionListId) => {
  return (dispatch, getState) => {
    const state = getState();
    const productionLists = state.productionLists;
    const categories = state.categories;
    const rails = state.rails;
    const categoryGroups = state.categoryGroups;

    const productionList = productionLists[productionListId];

    if (!productionList) {
      dispatch(showAlertError(i18n.t("common:not_found")));
      return;
    }

    dispatch({
      type: SELECT_LIST,
      listType: productionList.listType,
      name: productionList.name,
      selectedProductionList: productionList,
      picks: Object.values(productionList.picks).map((pick) => {
        let name;
        let entity;
        switch (pick.pickType) {
          case "category":
            entity = categories[pick.pickId];
            name = categories[pick.pickId].name;
            break;
          case "rail":
            entity = rails[pick.pickId];
            name = rails[pick.pickId].name;
            break;
          case "list":
            entity = productionLists[pick.pickId];
            name = productionLists[pick.pickId].name;
            break;
          case "group":
            entity = categoryGroups[pick.pickId];
            name = categoryGroups[pick.pickId].name;
            break;
        }

        return {
          id: uuidv4(),
          pickId: pick.pickId,
          pickType: pick.pickType,
          entity: entity,
          content: name,
          strategy: pick.strategy,
        };
      }),
    });
  };
};

export const updateList = () => {
  return (dispatch, getState) => {
    const { id, listType, name, picks } = getState().productionListEdit;

    const payload = {
      name: name,
      picks: picks,
      listType: listType,
    };

    if (id) {
      dispatch({ type: UPDATE_REQUEST });
      productionListUpdate(id, payload, () => {
        dispatch({ type: UPDATE_SUCCESS });
      });
    } else {
      dispatch({ type: CREATE_REQUEST });
      productionListCreate(payload, (newId) => {
        dispatch({ type: CREATE_SUCCESS, id: newId });
      });
    }
  };
};

export const showMessage = (loaderMessage) => {
  return { type: LOADER_SHOW, loaderMessage };
};

export default function productionListReducer(state = initialState, action) {
  switch (action.type) {
    case CLEAR:
      return {
        ...state,
        enableDelete: false,
        enableListType: false,
        enableRename: false,
        enableSave: false,
        listType: null,
        loaderMessage: null,
        loaderOpen: false,
        name: null,
        id: null,
        selectedProductionList: null,
        picks: [],
      };

    case CREATE:
      return {
        ...state,
        enableDelete: false,
        enableListType: true,
        enableRename: true,
        enableSave: true,
        id: null,
        listType: ProductionListType.SEQUENTIAL,
        name: action.name,
        picks: [],
      };

    case CREATE_SUCCESS:
      return {
        ...state,
        enableDelete: true,
        enableSave: false,
        id: action.id,
        loaderOpen: false,
      };

    case DELETE_SUCCESS:
      return {
        ...state,
        enableDelete: false,
        enableListType: false,
        enableRename: false,
        enableSave: false,
        loaderOpen: false,
        listType: null,
        id: null,
        name: null,
        selectedProductionList: null,
      };

    case DELETE_FAILURE:
      return {
        ...state,
        loaderOpen: false,
      };

    case SET_LIST_TYPE:
      return {
        ...state,
        enableSave: true,
        listType: action.listType,
      };

    case LOADER_SHOW:
      return {
        ...state,
        loaderMessage: action.loaderMessage,
        loaderOpen: true,
      };

    case SET_PICKS:
      return {
        ...state,
        enableSave: true,
        picks: action.picks,
      };

    case SET_SORT:
      return {
        ...state,
        sortBy: action.sortBy,
      };

    case RENAME:
      return {
        ...state,
        name: action.name,
        enableSave: true,
      };

    case SELECT_LIST:
      return {
        ...state,
        enableDelete: true,
        enableListType: true,
        enableRename: true,
        enableSave: false,
        id: action.selectedProductionList.id,
        listType: action.listType,
        name: action.name,
        picks: action.picks,
        selectedProductionList: action.selectedProductionList,
      };

    case UPDATE_SUCCESS:
      return {
        ...state,
        enableDelete: true,
        enableSave: false,
        loaderOpen: false,
      };

    default:
      return state;
  }
}
