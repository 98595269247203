import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/CloseSharp";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: "90px 32px 32px 32px",
  },
  scrollPaper: {
    maxHeight: "calc(100% - 120px)",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  content: {
    minHeight: (props) => props.minHeight,
    maxHeight: (props) => props.maxHeight,
  },
  loader: {
    color: theme.palette.info["main"],
  },
  progressContainer: {
    alignItems: "center",
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
  },
}));

const BaseDialog = (props) => {
  const {
    actionButtons = [],
    children,
    closeOnClick = true,
    dividers = true,
    footer,
    fullWidth = false,
    handleClose,
    maxHeight,
    maxWidth = "md",
    minHeight,
    onExited,
    open,
    showLoader,
    subtitle,
    titleContent,
    title,
  } = props;

  const classes = useStyles({ maxHeight, minHeight });
  const { t } = useTranslation();
  const handleOnClose = () => {
    if (closeOnClick) {
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      classes={{ paper: classes.paper, paperScrollPaper: classes.scrollPaper }}
      maxWidth={maxWidth}
      onBackdropClick={handleOnClose}
      fullWidth={fullWidth}
      TransitionProps={{
        onExited: onExited,
      }}
    >
      <Box pt={2} pb={2} pl={3} pr={3}>
        <Typography variant="h3">{title}</Typography>
        {subtitle && <Typography gutterBottom>{subtitle}</Typography>}
        {titleContent && <>{titleContent}</>}
        <IconButton className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent dividers={dividers} className={classes.content}>
        {showLoader && (
          <div className={classes.progressContainer}>
            <CircularProgress className={classes.loader} />
          </div>
        )}
        {children}
      </DialogContent>

      <DialogActions>
        {footer && <Box width="100%">{footer}</Box>}
        {!footer && (
          <>
            {actionButtons}
            <Button variant="contained" autoFocus onClick={handleClose} startIcon={<CloseIcon />}>
              {t("common:close")}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

BaseDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  maxWidth: PropTypes.string,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

export default BaseDialog;
