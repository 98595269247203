import moment from "moment";
import { configureChannel } from "../utils";
import { getBatches, getActiveBatchIds } from "../selectors/batches";
import { INITIALIZE_BATCHES, REMOVE_BATCHES, UPDATE_BATCH } from "./types";

export const batchSubscribe = () => {
  return (dispatch) => {
    dispatch(garbageCollection());

    batchesChannel.on("init", (msg) => {
      dispatch(initializeBatches(msg.data));
    });

    batchesChannel.on("update", (msg) => {
      dispatch(updateBatch(msg));
    });
  };
};

export const updateBatch = (batch) => ({
  type: UPDATE_BATCH,
  batch,
});

export const removeBatches = (batchIds) => ({
  type: REMOVE_BATCHES,
  batchIds,
});

export const initializeBatches = (batches) => ({
  type: INITIALIZE_BATCHES,
  batches: batches,
});

export const batchesChannel = configureChannel("batches");

const garbageCollection = () => {
  return (dispatch) => {
    setTimeout(() => dispatch(garbageCollection()), 3600000);
    const activeBatchIds = getActiveBatchIds();
    const batches = getBatches();

    const staleBatchIds = [];
    Object.values(batches).forEach((batch) => {
      const currentTime = new moment();
      const batchMoment = new moment(batch.createdAt);
      const timeDiff = currentTime.diff(batchMoment);
      const minutesEllapsed = Math.floor(moment.duration(timeDiff).asMinutes());
      if (!activeBatchIds.includes(batch.id) && minutesEllapsed >= 10) {
        staleBatchIds.push(batch.id);
      }
    });

    if (staleBatchIds.length > 0) {
      dispatch(removeBatches(staleBatchIds));
    }
  };
};
