import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import _ from "lodash";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableSortLabel,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LaborTaskIcon from "@mui/icons-material/GroupWork";
import { withStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { SETUP_LABOR_TASKS } from "../../constants/Paths";
import { useCurrentRole } from "../../selectors/useRole";
import { getLaborTasks } from "../../api/LaborTaskApi";
import Loader from "../../components/Loader";
import { sortingComparer } from "../../utils/sort";
import SearchField from "../SetupPages/SearchField";

const StyledTableCell = withStyles((theme) => ({
  head: {
    padding: theme.spacing(2),
  },
  body: {
    fontSize: 14,
    padding: theme.spacing(2),

    "& .MuiBox-root": {
      display: "flex",
      alignItems: "center",
    },
    "& .MuiAvatar-root": {
      marginRight: theme.spacing(1),
    },
    "& .MuiTextField-root": {
      width: "100%",
    },
  },
}))(TableCell);

const StyledTableSortLabel = withStyles((theme) => ({
  root: {
    "&$active": {
      color: theme.palette.common.black,
    },
  },
  active: {},
  icon: {
    color: "inherit !important",
  },
}))(TableSortLabel);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.grey[100],
    },
  },
}))(TableRow);

const LaborTaskRow = ({ laborTask = {}, onClick }) => {
  const currentRole = useCurrentRole();

  return (
    <StyledTableRow>
      <StyledTableCell sx={{ display: "flex", alignItems: "center" }}>
        <Avatar src={laborTask.imageUrl}>{!laborTask.imageUrl && <LaborTaskIcon />}</Avatar>
        <Typography variant="h5">{laborTask.name}</Typography>
      </StyledTableCell>

      <StyledTableCell>{laborTask.perspectiveName}</StyledTableCell>

      <StyledTableCell align="right">
        <IconButton onClick={() => onClick(laborTask.id)}>
          {currentRole.canConfigureMetrics ? <EditIcon /> : <VisibilityIcon />}
        </IconButton>
      </StyledTableCell>
    </StyledTableRow>
  );
};

const filterLaborTasks = ({ laborTasks, searchTerm }) => {
  return laborTasks.filter((laborTask) => {
    if (!searchTerm) return true;
    return _.includes(laborTask.name?.toLowerCase(), searchTerm?.toLowerCase());
  });
};

const sortLaborTasks = ({ laborTasks = [], order, orderBy }) => {
  if (laborTasks.length < 1) return [];
  return laborTasks.sort((a, b) => {
    const valueA = orderBy === "name" ? a.name : a.perspectiveName;
    const valueB = orderBy === "name" ? b.name : b.perspectiveName;
    return sortingComparer(valueA, valueB, order);
  });
};

const LaborTasksContent = ({ laborTasks }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [searchTerm, setSearchTerm] = useState(location.state?.searchTerm);

  const handleOrderBy = (selected) => {
    if (selected === orderBy) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      setOrder("asc");
      setOrderBy(selected);
    }
  };

  const filteredLaborTasks = filterLaborTasks({ laborTasks, searchTerm });
  const sortedLaborTasks = sortLaborTasks({ laborTasks: filteredLaborTasks, order, orderBy });

  const list = useRef();
  useEffect(() => {
    const scrollPos = location.state?.listScrollPos;
    if (scrollPos) {
      list.current.scrollTop = scrollPos;
    }
  }, [location.state?.listScrollPos]);

  const history = useHistory();
  const handleOnClick = (id) => {
    const newLocation = {
      pathname: `${SETUP_LABOR_TASKS}/${id}`,
      state: { listScrollPos: list.current.scrollTop, searchTerm },
    };
    history.push(newLocation);
  };

  return (
    <Paper square={true} elevation={0} sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box padding={3} display="flex" justifyContent="space-between" alignItems="flex-end">
        <Typography variant="h1">{t("laborTasksSetup:labor_tasks_setup")}</Typography>
        <SearchField
          sx={{ width: 350 }}
          initialValue={location.state?.searchTerm}
          placeholder={t("common:search_by_name")}
          onSubmitSearchTerm={(searchTerm) => setSearchTerm(searchTerm)}
        />
      </Box>

      <Divider />

      <TableContainer ref={list}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <StyledTableSortLabel
                  active={orderBy === "name"}
                  direction={order}
                  onClick={() => handleOrderBy("name")}
                >
                  {t("common:name")}
                </StyledTableSortLabel>
              </StyledTableCell>

              <StyledTableCell>
                <StyledTableSortLabel
                  active={orderBy === "perspective"}
                  direction={order}
                  onClick={() => handleOrderBy("perspective")}
                >
                  {t("laborTasksSetup:perspective")}
                </StyledTableSortLabel>
              </StyledTableCell>

              <StyledTableCell align="right"></StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {sortedLaborTasks.map((laborTask) => (
              <LaborTaskRow key={laborTask.id} laborTask={laborTask} onClick={handleOnClick} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

const LaborTasksList = () => {
  const { t } = useTranslation();

  const [laborTasks, setLaborTasks] = useState();
  const [dataLoaded, setDataLoaded] = useState(false);
  useEffect(() => {
    getLaborTasks({
      successCB: (data) => {
        setLaborTasks(data || []);
        setDataLoaded(true);
      },
    });
  }, []);

  return dataLoaded ? (
    laborTasks?.length > 0 ? (
      <LaborTasksContent laborTasks={laborTasks} />
    ) : (
      <Typography variant="h4" gutterBottom>
        {t("laborTasksSetup:labor_tasks_not_found")}
      </Typography>
    )
  ) : (
    <Loader open={true} message={t("laborTasksSetup:fetching_labor_tasks")} />
  );
};

export default LaborTasksList;
