import React from "react";
import { Box, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import CloneButton from "./CloneButton";
import CreateButton from "./CreateButton";
import CustomerList from "./CustomerList";
import DeleteButton from "./DeleteButton";
import MappingButton from "./MappingButton";
import RenameButton from "./RenameButton";
import SaveButton from "./SaveButton";
import SortConfigurationSelector from "./SortConfigurationSelector";

const Row = ({ children }: { children: JSX.Element[] | JSX.Element }) => {
  return (
    <Box sx={{
      display: "flex",
      alignItems: "start",
    }}>
      {children}
    </Box>
  )
};

const Selector = ({ children }: { children: JSX.Element[] | JSX.Element }) => {
  return (
    <Box sx={{ marginRight: "6px" }}>
      {children}
    </Box>
  )
};

const Actions = ({ children }: { children: JSX.Element[] | JSX.Element }) => {
  return (
    <Box sx={{
      flex: 1,
      textAlign: "right",
      "& > button": {
        mr: 1,
        mb: 1,
      }
    }}>
      {children}
    </Box>
  )
};

const TitleBar = () => {
  const { t } = useTranslation();

  return (
    <Paper square={true} elevation={0} sx={{ flex: "0 0 auto", padding: 3 }}>
      <Typography variant="h1" gutterBottom>
        {t("sortAssignments:sort_assignments")}
      </Typography>

      <Row>
        <Selector>
          <SortConfigurationSelector />
        </Selector>
        <Actions>
          <MappingButton />
          <CreateButton />
          <CloneButton />
          <RenameButton />
          <SaveButton />
          <DeleteButton />
        </Actions>
      </Row>
      <Row>
        <CustomerList />
      </Row>
    </Paper>
  );
};

export default TitleBar;
