import React, { useState, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/CheckCircleOutlineSharp";
import type { System } from "../../../../dto/eVue/System";
import SaveButton from "../../../../components/SaveButton";
import Loader from "../../../../components/Loader";
import { useAddStorageGroupMutation } from "../../services/storageGroups";
import useStorageGroupAlertMessages from "../../hooks/useStorageGroupAlertMessages";
import AddButton from "../AddButton";

interface Props {
  system: System;
  disabled: boolean;
};

const AddStorageGroupButton = ({ system, disabled }: Props) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");

  const handleOnClose = () => {
    setOpen(false);
    setName("");
  };

  const { onSuccess, onFailure } = useStorageGroupAlertMessages();
  const [addStorageGroup, { isLoading }] = useAddStorageGroupMutation();
  const handleOnSubmit = () => {
    if (system?.id && name.length > 0) {
      addStorageGroup({ systemId: system?.id, name })
        .unwrap()
        .then((payload) => {
          onSuccess();
          handleOnClose();
        })
        .catch((error) => onFailure(error));
    }
  };

  return (
    <>
      <AddButton disabled={disabled} onClick={() => setOpen(true)} />

      {isLoading && (
        <Loader open={isLoading} message={t("storageManager:saving_storage_group")} transitionDuration={300} />
      )}

      {open && (
        <Dialog
          open={open}
          fullWidth={true}
          TransitionProps={{
            onExited: handleOnClose,
          }}
        >
          <Box px={3}>
            <Typography variant="h3">{t("storageManager:add_storage_group")}</Typography>
          </Box>

          <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
            <TextField
              sx={{ width: "100%" }}
              autoFocus
              margin="normal"
              variant="outlined"
              value={name}
              onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setName(event.target.value)}
            />
          </DialogContent>

          <DialogActions>
            <Button
              variant="outlined"
              autoFocus
              disableElevation
              onClick={handleOnClose}
              startIcon={<CloseIcon />}
            >
              {t("common:close")}
            </Button>

            <SaveButton startIcon={<CheckIcon />} onClick={handleOnSubmit}>
              {t("common:submit")}
            </SaveButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
};

export default AddStorageGroupButton;
