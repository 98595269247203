import React, { useState, useEffect } from "react";
import _ from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Box, CardContent, CircularProgress, Divider, Grid, Paper, Typography } from "@mui/material";
import WarningIcon from "@mui/icons-material/WarningSharp";

import { useSlingInputStation } from "../../selectors/slingInputStations";
import useWsSlingInputStation from "../../hooks/useSlingInputStation";
import { useRail } from "../../selectors/rails";
import { useBarcodeFromId } from "../../selectors/barcodes";
import { usePlcWsUrl, useCyclesPerMaintenance, useDaysPerMaintenance } from "../../selectors/uiSettings";
import { railInputEnabled, railOutputEnabled } from "../../api/RailApi";
import {
  fetchMaintenanceReasons,
  assignCategoryToSis,
  assignCustomerToSis,
  updateSis,
  assignBarcodeToSis,
  sendCallEmpty,
  sendLoad,
  bagRemoved,
  loadBag,
  submitBatchDetails,
  getEmptyOrExit,
  submitReEntry,
  returnBag,
  captureWeightForMaintHoist,
  lowerBag,
} from "../../api/SlingInputStationApi";
import { SocketProvider } from "../../contexts/SocketContext";
import { IndicatorStatusTypes } from "../../features/panels/enum/IndicatorStatusTypes";
import CustomerFlagSelector from "../FlagSelectors/CustomerFlagSelector";
import CategoryFlagSelector from "../FlagSelectors/CategoryFlagSelector";
import DestinationRailFlagSelector from "../FlagSelectors/DestinationRailFlagSelector";
import CircleIndicator from "../CircleIndicator";
import useAddBatchBarcodeToSIS from "./hooks/useAddBatchBarcodeToSIS";
import ConciseBagRemovedButton from "./ConciseBagRemovedButton";
import SlingPresenceIndicator from "./SlingPresenceIndicator";
import BagStatusIndicator from "./BagStatusIndicator";
import LiveWeight from "./LiveWeight";
import ConciseLoadBagButton from "./ConciseLoadBagButton";
import ConciseSubmitButton from "./ConciseSubmitButton";
import ConciseGetEmptyOrExitButton from "./ConciseGetEmptyOrExitButton";
import ConciseReturnBagButton from "./ConciseReturnBagButton";
import ConciseSendCallEmptyButton from "./ConciseSendCallEmptyButton";
import ConciseSendButton from "./ConciseSendButton";
import SlingNumberCard from "./SlingNumberCard";
import ConciseAcceptButton from "./ConciseAcceptButton";
import ConciseLowerBagButton from "./ConciseLowerBagButton";

export default (props) => {
  const wsUrl = usePlcWsUrl();
  return (
    <SocketProvider wsUrl={`${wsUrl}`}>
      <SupertrackMaintHoistSIS {...props} />
    </SocketProvider>
  );
};

const SupertrackMaintHoistSIS = ({ sisId }) => {
  const sis = useSlingInputStation(sisId) || {};
  const rail = useRail(sis.railId) || {};

  return (
    <Paper square={true} elevation={0} sx={{ width: "100%", height: "100%" }}>
      <Grid container flexDirection="column" sx={{ width: "100%" }}>
        <Grid item>
          <Heading rail={rail} />
        </Grid>

        <Divider />

        <Grid item>
          <CardContent>
            <MaintHoistContent sis={sis} rail={rail} />
          </CardContent>
        </Grid>
      </Grid>
    </Paper>
  );
};

const Heading = ({ rail }) => {
  const { t } = useTranslation();
  const { name, plcState, inputEnabled, outputEnabled } = rail;

  const handleToggleInbound = () => railInputEnabled({ id: rail.id, inputEnabled });
  const handleToggleOutbound = () => railOutputEnabled({ id: rail.id, outputEnabled });

  return (
    <CardContent sx={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="h1">{name}</Typography>
      <Typography variant="overline">
        {t("railStates:state_state", {
          state: t(`railStates:${plcState}`),
        })}
      </Typography>

      <Box display="flex" justifyContent="space-between" alignItems="flex-end" mt={2}>
        <div>
          <CircleIndicator
            label={t("common:in")}
            status={inputEnabled ? IndicatorStatusTypes.ON : IndicatorStatusTypes.OFF}
            onClick={handleToggleInbound}
          />

          <CircleIndicator
            label={t("common:out")}
            status={outputEnabled ? IndicatorStatusTypes.ON : IndicatorStatusTypes.OFF}
            onClick={handleToggleOutbound}
          />
        </div>
      </Box>
    </CardContent>
  );
};

const MaintHoistContent = ({ sis, rail }) => {
  const wsData = useWsSlingInputStation(sis.id) || {};
  const uiState = wsData.sis_data || 1000;

  useAddBatchBarcodeToSIS(sis);

  const handleOnSendCallEmpty = () => sendCallEmpty({ sisId: sis.id });
  const handleOnSend = () => sendLoad({ sisId: sis.id });
  const handleOnRemoved = () => bagRemoved({ sisId: sis.id });
  const handleOnLoad = () => loadBag({ sisId: sis.id });
  const handleOnSubmitDetails = () => submitBatchDetails({ sisId: sis.id });
  const handleOnGetEmptyOrExit = () => getEmptyOrExit({ sisId: sis.id });
  const handleOnSubmit = () => submitReEntry({ sisId: sis.id });
  const handleOnReturnBag = () => returnBag({ sisId: sis.id });
  const handleOnCaptureWeight = () => captureWeightForMaintHoist({ sisId: sis.id });
  const handleOnLowerBag = () => lowerBag({ sisId: sis.id });

  if (uiState === 900) {
    return <SafetyZoneOnNotice />;
  } else if (uiState === 1100) {
    return (
      <State1SlingOverview
        sis={sis}
        onSendCallEmpty={handleOnSendCallEmpty}
        onSend={handleOnSend}
        onRemoved={handleOnRemoved}
        onLoad={handleOnLoad}
      />
    );
  } else if (uiState === 1200) {
    return <State2BatchDetailsInput sis={sis} onSubmit={handleOnSubmitDetails} />;
  } else if (uiState === 1300) {
    return <State3SlingMissing sis={sis} onGetEmptyOrExit={handleOnGetEmptyOrExit} />;
  } else if (uiState === 1400) {
    return <State4SlingReEntry sis={sis} rail={rail} onSubmit={handleOnSubmit} />;
  } else if (uiState === 1500) {
    return <State5ReturnSling onReturnBag={handleOnReturnBag} />;
  } else if (uiState === 1600) {
    return <ManualWeightCapture sis={sis} onCaptureWeight={handleOnCaptureWeight} onLowerBag={handleOnLowerBag} />;
  } else {
    return <State0Waiting />;
  }
};

const SafetyZoneOnNotice = () => {
  const { t } = useTranslation();
  return (
    <Box display="flex" alignItems="center" flexDirection="column">
      <WarningIcon sx={(theme) => ({ color: theme.palette.error.main, fontSize: 160, marginRight: 1 })} />
      <Typography color="error" variant="h1" sx={{ textTransform: "uppercase" }}>
        {t("slingInputStation:cage_open")}
      </Typography>
      <Typography color="error" variant="h2">
        {t("slingInputStation:close_door_to_proceed")}
      </Typography>
    </Box>
  );
};

const State1SlingOverview = ({ sis = {}, onSendCallEmpty, onSend, onRemoved, onLoad }) => {
  const { t } = useTranslation();
  const maintInfo = useMaintInfo(sis) || {};
  const needsMaintenance = maintInfo.manualMaintenanceDue || maintInfo.scheduledMaintenanceDue;

  return (
    <Box display="flex" alignItems="center" flexDirection="column">
      <Typography variant="h1" sx={{ mb: 2 }}>
        {t("slingInputStation:live_bag_maint_overview")}
      </Typography>

      <MaintenanceBagStatusIndicator
        manualMaintenanceDue={maintInfo.manualMaintenanceDue}
        scheduledMaintenanceDue={maintInfo.scheduledMaintenanceDue}
      />

      <Grid container justifyContent="center" alignItems="center">
        <Grid item sx={{ mr: 2 }}>
          <SlingPresenceIndicator slingLoaded={true} size={200} />
        </Grid>

        <Grid item sx={{ pr: 6 }}>
          <BatchDetails sis={sis} />
        </Grid>

        <Box display="flex" flexDirection="column" alignItems="center">
          <ConciseSendCallEmptyButton sx={{ mb: 2 }} onClick={onSendCallEmpty} />
          <ConciseSendButton sx={{ mb: 2 }} onClick={onSend} />
          <ConciseBagRemovedButton sx={{ mb: 2 }} sisId={sis.id} size="xLarge" onClick={onRemoved} />
          {!needsMaintenance && <ConciseLoadBagButton sisId={sis.id} size="xLarge" onClick={onLoad} />}
        </Box>
      </Grid>
    </Box>
  );
};

const State2BatchDetailsInput = ({ sis = {}, onSubmit }) => {
  const { t } = useTranslation();

  const { railId, category1Id, customer1Id, tag1 } = sis;

  const handleCustomerSelect = (customerId) => assignCustomerToSis({ sisId: sis.id, customerId, position: 1 });
  const handleCategorySelect = (categoryId) => assignCategoryToSis({ sisId: sis.id, categoryId, position: 1 });

  return (
    <Box display="flex" alignItems="center" flexDirection="column">
      <Typography sx={{ mb: 2 }} variant="h1">
        {t("slingInputStation:batch_details_input")}
      </Typography>

      <Grid container justifyContent="center" spacing={2} sx={{ mb: 2 }}>
        <Grid item>
          <Typography variant="h5">{t("common:customer")}</Typography>
          <Box>
            <CustomerFlagSelector selectedId={customer1Id} size="large" handleSelect={handleCustomerSelect} />
          </Box>
        </Grid>

        <Grid item>
          <Typography variant="h5">{t("common:category")}</Typography>
          <Box>
            <CategoryFlagSelector selectedId={category1Id} size="large" onSelectionClick={handleCategorySelect} />
          </Box>
        </Grid>

        <Grid item>
          <Typography variant="h5">{t("common:destination")}</Typography>
          <Box>
            <DestinationRailFlagSelector
              railId={railId}
              selectedTagId={tag1}
              size="large"
              onSelect={(tagId) => updateSis({ sisId: sis.id, data: { tag1: tagId } })}
            />
          </Box>
        </Grid>
      </Grid>

      <Box display="flex" justifyContent="center" mb={2}>
        <LiveWeight railId={railId} label="Weight" />
      </Box>

      <ConciseSubmitButton onClick={onSubmit} />
    </Box>
  );
};

const State3SlingMissing = ({ onGetEmptyOrExit }) => {
  return (
    <Grid container flexDirection="column" alignItems="center">
      <Grid item>
        <BagStatusIndicator status="missing" />
      </Grid>

      <Grid item sx={{ mt: 4 }}>
        <ConciseGetEmptyOrExitButton onClick={onGetEmptyOrExit} />
      </Grid>
    </Grid>
  );
};

const State4SlingReEntry = ({ sis, rail = {}, onSubmit }) => {
  const { t } = useTranslation();
  const handleBarcodeSelect = (barcodeId) => assignBarcodeToSis({ sisId: sis.id, barcodeId });
  // TODO: There are technician field for user performing taks and maintenance_task. The first one can probably just be logged in user. Need to find out list for maintenance_tasks. Also, if submitting means that sling gets sent back up to the system and not sent to sling overview, the bag number selector might just be a way to tie maintenance reason and technician field with the correct barcode.
  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
      <Typography variant="h1" sx={{ mb: 2 }}>
        {t("slingInputStation:maint_bag_re_entry_form")}
      </Typography>

      <SlingNumberCard
        barcodeId={sis.barcode1Id}
        systemId={rail.systemId}
        size="large"
        onChange={handleBarcodeSelect}
      />

      <ConciseSubmitButton sx={{ mt: 2 }} onClick={onSubmit} />
    </Box>
  );
};

const State5ReturnSling = ({ onReturnBag }) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
      <Typography variant="h1" sx={{ mb: 2 }}>
        {t("slingInputStation:return_maintenance_bag")}
      </Typography>

      <ConciseReturnBagButton onClick={onReturnBag} />
    </Box>
  );
};

const ManualWeightCapture = ({ sis = {}, onCaptureWeight, onLowerBag }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
      <Typography variant="h1" sx={{ mb: 2 }}>
        {t("slingInputStation:manual_weight_capture")}
      </Typography>

      <LiveWeight railId={sis?.railId} label="Weight" />
      <ConciseAcceptButton sx={{ mt: 2 }} sis={sis} onClick={onCaptureWeight} />
      <ConciseLowerBagButton sx={{ mt: 2 }} onClick={onLowerBag} />
    </Box>
  );
};

const State0Waiting = () => {
  const { t } = useTranslation();
  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
      <Typography variant="h1" sx={{ mb: 2 }}>
        {t("slingInputStation:waiting_for_lift")}
      </Typography>
      <CircularProgress color="success" />
    </Box>
  );
};

const MaintenanceBagStatusIndicator = ({ manualMaintenanceDue, scheduledMaintenanceDue }) => {
  const { t } = useTranslation();

  if (!manualMaintenanceDue && !scheduledMaintenanceDue) return null;
  return (
    <Box
      display="flex"
      alignItems="center"
      sx={(theme) => ({
        "& #warning-icon": {
          color: theme.palette.error.light,
          marginRight: theme.spacing(1),
          fontSize: 160,
        },
      })}
    >
      <WarningIcon id="warning-icon" />
      <Typography color="error" variant="h1">
        {manualMaintenanceDue
          ? t("slingInputStation:maintenance_requested")
          : t("slingInputStation:scheduled_maintenance")}
      </Typography>
    </Box>
  );
};

const BatchDetails = ({ sis }) => {
  const { t } = useTranslation();

  const barcode = useBarcodeFromId(sis.barcode1Id) || {};
  const { id, barcodeNumber, slingNumber } = barcode;

  const maintInfo = useMaintInfo(sis) || {};
  const {
    reason,
    notes,
    previousCycles,
    previousMaintenanceAt,
    overMaintTimeLimit,
    overMaintCyclesLimit,
    manualMaintenanceDue,
  } = maintInfo;

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="h3">{`${t("common:bag_number")}: ${slingNumber || "--"}`}</Typography>

      <Typography variant="h3">{t("common:id_number", { number: id })}</Typography>

      <Typography variant="h3">{t("barcodeScanner:barcode_value", { value: barcodeNumber || "--" })}</Typography>

      <Typography sx={{ mt: 2 }} variant="h3">
        {t("common:maintenance_details")}
      </Typography>

      <Divider sx={(theme) => ({ borderColor: theme.palette.grey[400], mb: 1 })} />

      <Typography variant="h5">{t("slingInputStation:last_maintenance_date")}</Typography>
      <Typography variant="h4" color={overMaintTimeLimit ? "error" : "initial"}>
        {previousMaintenanceAt
          ? moment(previousMaintenanceAt).format("LLLL")
          : t("slingInputStation:no_date_available")}
      </Typography>

      <Typography variant="h5" sx={{ mt: 1 }}>
        {t("slingInputStation:cycles_since_last_maintenance")}
      </Typography>
      <Typography variant="h4" color={overMaintCyclesLimit ? "error" : "initial"}>
        {`${previousCycles}`}
      </Typography>

      <Typography variant="h5" sx={{ mt: 1 }}>
        {t("slingInputStation:reason")}
      </Typography>
      <Typography variant="h4">{generateMaintenanceReason({ reason, notes, manualMaintenanceDue })}</Typography>
    </Box>
  );
};

const computeReason = (barcode, previousCycles, cyclesPerMaint, daysSinceLastMaint, maintDaysLimit) => {
  const overCycleLimit = previousCycles > cyclesPerMaint;
  const overDaysLimit = daysSinceLastMaint > maintDaysLimit;

  if (!barcode) {
    return "no_read";
  } else if (barcode.manualMaintenanceDue) {
    return "requested";
  } else if (overCycleLimit) {
    return "cycle_limit";
  } else if (overDaysLimit) {
    return "day_limit";
  }
};

const useMaintInfo = (sis) => {
  const { t } = useTranslation();
  const barcode = useBarcodeFromId(sis.barcode1Id);
  let reason;
  let notes = barcode?.notes;
  let previousCycles = barcode?.cyclesSinceMaintenance;
  let previousMaintenanceAt = barcode?.lastMaintainedAt;

  const maintData = useIntervaledMaintReasons();
  const barcodeMaintData = maintData.find((x) => x.barcodeId === sis.barcode1Id);
  if (barcodeMaintData) {
    reason = barcodeMaintData.reason;
    notes = barcodeMaintData.notes;
    previousCycles = barcodeMaintData.previousCycles;
    previousMaintenanceAt = barcodeMaintData.previousMaintenanceAt;
  }

  const cyclesPerMaint = useCyclesPerMaintenance();
  const daysSinceLastMaint = moment.duration(moment().diff(moment(previousMaintenanceAt))).asDays();
  const maintDaysLimit = useDaysPerMaintenance();

  if (!reason) {
    reason = computeReason(barcode, previousCycles, cyclesPerMaint, daysSinceLastMaint, maintDaysLimit);
  }

  return {
    maintDaysLimit,
    previousCycles,
    previousMaintenanceAt,
    reason: reason && t(`slingInputStation:${reason}`),
    notes,
    overMaintTimeLimit: reason === "day_limit",
    overMaintCyclesLimit: reason === "cycle_limit",
    manualMaintenanceDue: reason === "requested",
    scheduledMaintenanceDue: reason === "day_limit" || reason === "cycle_limit",
  };
};

const useIntervaledMaintReasons = () => {
  const [maintData, setMaintData] = useState([]);
  useEffect(() => {
    fetchMaintenanceReasons({ successCB: (data) => setMaintData(data) });
    const timer = setInterval(() => fetchMaintenanceReasons({ successCB: (data) => setMaintData(data) }), 10000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return maintData;
};

const generateMaintenanceReason = ({ reason, notes, manualMaintenanceDue }) => {
  let maintenanceReason = _.capitalize(_.replace(reason || "", "_", " "));
  if (manualMaintenanceDue && notes) {
    maintenanceReason += ` - ${notes}`;
  }
  return maintenanceReason;
};
