import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import WarningIcon from "@mui/icons-material/WarningSharp";
import DeleteButtonWithConfirmation from "../DeleteButtonWithConfirmation";

interface Props {
  name?: string;
  disabled: boolean;
  onDelete: VoidFunction;
};

const DeleteCategoryButtonConfirmation = ({ name, disabled, onDelete }: Props) => {
  const { t } = useTranslation();

  return (
    <DeleteButtonWithConfirmation
      title={t<string>("categoriesSetup:delete_category")}
      disabled={disabled}
      onDelete={onDelete}
    >
      <>
        <WarningIcon sx={{ fontSize: 80, color: theme => theme.palette.error.main }} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            boxSizing: "border-box",
          }}
        >
          <Typography variant="body1">
            {t("common:you_are_about_to_delete", { name })}
          </Typography>
          <Typography variant="body1">
            {t("common:any_associations_will_be_removed")}
          </Typography>
          <Typography variant="body1">
            {t("common:do_you_wish_to_proceed")}
          </Typography>
        </Box>
      </>
    </DeleteButtonWithConfirmation>
  );
};

export default DeleteCategoryButtonConfirmation;
