import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import FormFieldLabel from "../SetupPages/FormFieldLabel";
import FormTextField from "../SetupPages/FormTextField";
import StyledGridItem from "../CategoriesSetup/StyledGridItem";
import CustomerFlagSelector from "../FlagSelectors/CustomerFlagSelector";
import FormCategoriesSelector from "./FormCategoriesSelector";
import LocalAttributesGroup from "./LocalAttributesGroup";

const OtherGroup = ({ currentValues, disabled, errors, setFieldValue, setArrayFieldValue }) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid item sx={{ mb: 1 }}>
        <Typography variant="h4">{t("common:other")}</Typography>
      </Grid>

      <Grid container>
        <Box mr={2} height="fit-content">
          <FormFieldLabel label={t("customersSetup:owner")} />
          <CustomerFlagSelector
            selectedId={currentValues["ownerId"]}
            handleSelect={(ownerId) => setFieldValue("ownerId", ownerId)}
            disabled={disabled}
          />
        </Box>

        <StyledGridItem item>
          <FormCategoriesSelector
            currentValues={currentValues}
            disabled={disabled}
            onArrayFieldChange={setArrayFieldValue}
          />
        </StyledGridItem>
      </Grid>

      <Box mt={3} maxWidth={600}>
        <StyledGridItem item>
          <FormTextField
            fieldName="notes"
            label={t("common:notes")}
            currentValues={currentValues}
            errors={errors}
            disabled={disabled}
            multiline={true}
            minRows={4}
            maxRows={8}
            onFieldChange={setFieldValue}
          />
        </StyledGridItem>
      </Box>

      <LocalAttributesGroup
        currentValues={currentValues}
        disabled={disabled}
        errors={errors}
        onFieldChange={setFieldValue}
      />
    </>
  );
};

export default OtherGroup;
