import { createSlice } from "@reduxjs/toolkit";

const batchHistorySlice = createSlice({
  name: "batchHistory",
  initialState: {
    batchParts: [],
    batches: [],
    category: null,
    customer: null,
    filterDryer: true,
    filterMachine: true,
    filterRail: false,
    filterRailAlarm: true,
    filterSlingTag: true,
    filterUserAction: true,
    filterVerificationFail: true,
    filterVerificationPass: true,
    hasDryer: false,
    hasMachine: false,
    hasRail: false,
    hasRailAlarm: false,
    hasSlingTag: false,
    hasUserAction: false,
    hasVerificationFail: false,
    hasVerificationPass: false,
  },
  reducers: {
    clearBatchHistoryData: (state) => {
      state.batchParts = [];
      state.batches = [];
      state.customer = null;
      state.category = null;
      state.hasDryer = false;
      state.hasMachine = false;
      state.hasRail = false;
      state.hasRailAlarm = false;
      state.hasSlingTag = false;
      state.hasUserAction = false;
      state.hasVerificationFail = false;
      state.hasVerificationPass = false;
    },
    setBatchHistoryData: (state, action) => {
      const { batches, batchParts } = action.payload;
      state.batches = batches;
      state.batchParts = batchParts;

      // Parse category and customer from the first summary array item:
      state.customer = batches[0]?.customer;
      state.category = batches[0]?.category;

      // Parse available event types
      let allEvents = [];
      batches.map((batch) => {
        allEvents = allEvents.concat(batch.events);
      });

      state.hasDryer = !!allEvents.some((event) => event.type === "dryer");
      state.hasMachine = !!allEvents.some((event) => event.type === "machine_visit");
      state.hasRail = !!allEvents.some((event) => event.type === "rail_visit");
      state.hasRailAlarm = !!allEvents.some((event) => event.type === "rail_alarm");
      state.hasSlingTag = !!allEvents.some((event) => event.type === "sling_tag");
      state.hasUserAction = !!allEvents.some((event) => event.type === "user_action");
      state.hasVerificationFail = !!allEvents.some((event) => event.type === "verification_fail");
      state.hasVerificationPass = !!allEvents.some((event) => event.type === "verification_pass");
    },
    setBatchHistoryFilterDryer: (state, action) => {
      state.filterDryer = action.payload;
    },
    setBatchHistoryFilterMachine: (state, action) => {
      state.filterMachine = action.payload;
    },
    setBatchHistoryFilterRail: (state, action) => {
      state.filterRail = action.payload;
    },
    setBatchHistoryFilterRailAlarm: (state, action) => {
      state.filterRailAlarm = action.payload;
    },
    setBatchHistoryFilterSlingTag: (state, action) => {
      state.filterSlingTag = action.payload;
    },
    setBatchHistoryFilterUserAction: (state, action) => {
      state.filterUserAction = action.payload;
    },
    setBatchHistoryFilterVerificationFail: (state, action) => {
      state.filterVerificationFail = action.payload;
    },
    setBatchHistoryFilterVerificationPass: (state, action) => {
      state.filterVerificationPass = action.payload;
    },
  },
});

export const {
  clearBatchHistoryData,
  setBatchHistoryData,
  setBatchHistoryFilterDryer,
  setBatchHistoryFilterMachine,
  setBatchHistoryFilterRail,
  setBatchHistoryFilterRailAlarm,
  setBatchHistoryFilterSlingTag,
  setBatchHistoryFilterUserAction,
  setBatchHistoryFilterVerificationFail,
  setBatchHistoryFilterVerificationPass,
} = batchHistorySlice.actions;

export const batchHistoryBatchPartsSelector = (state) => state.batchHistory.batchParts;
export const batchHistoryCategorySelector = (state) => state.batchHistory.category;
export const batchHistoryCustomerSelector = (state) => state.batchHistory.customer;
export const batchHistoryDataSelector = (state) => state.batchHistory.batches;
export const batchHistoryFilterDryerSelector = (state) => state.batchHistory.filterDryer;
export const batchHistoryFilterMachineSelector = (state) => state.batchHistory.filterMachine;
export const batchHistoryFilterRailSelector = (state) => state.batchHistory.filterRail;
export const batchHistoryFilterRailAlarmSelector = (state) => state.batchHistory.filterRailAlarm;
export const batchHistoryFilterSlingTagSelector = (state) => state.batchHistory.filterSlingTag;
export const batchHistoryFilterUserActionSelector = (state) => state.batchHistory.filterUserAction;
export const batchHistoryFilterVerificationFailSelector = (state) => state.batchHistory.filterVerificationFail;
export const batchHistoryFilterVerificationPassSelector = (state) => state.batchHistory.filterVerificationPass;
export const batchHistoryHasDryer = (state) => state.batchHistory.hasDryer;
export const batchHistoryHasMachine = (state) => state.batchHistory.hasMachine;
export const batchHistoryHasRail = (state) => state.batchHistory.hasRail;
export const batchHistoryHasRailAlarm = (state) => state.batchHistory.hasRailAlarm;
export const batchHistoryHasSlingTag = (state) => state.batchHistory.hasSlingTag;
export const batchHistoryHasUserAction = (state) => state.batchHistory.hasUserAction;
export const batchHistoryHasVerificationFail = (state) => state.batchHistory.hasVerificationFail;
export const batchHistoryHasVerificationPass = (state) => state.batchHistory.hasVerificationPass;

export default batchHistorySlice.reducer;
