import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const useStyles = makeStyles((theme) => ({
  form: {
    margin: theme.spacing(1),
    width: 200,
  },
}));

const PasswordTextField = ({ value, onChange, autoFocus }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisible = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => event.preventDefault();

  return (
    <FormControl className={classes.form}>
      <InputLabel>{t("common:password")}</InputLabel>
      <OutlinedInput
        label={t("common:password")}
        type={showPassword ? "text" : "password"}
        value={value}
        onChange={onChange}
        autoFocus={autoFocus}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={handleTogglePasswordVisible} onMouseDown={handleMouseDownPassword} edge="end">
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
};

export default PasswordTextField;
