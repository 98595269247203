import React from "react";
import { Button, Hidden } from "@mui/material";
import { ProductionListType } from "../../constants/ProductionListType";
import { setListType } from "../../redux/productionListEditor";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";

const PriorityButton = () => {
  const dispatch = useDispatch();
  const { enableListType, listType } = useSelector((state) => state.productionListEdit);
  const { t } = useTranslation();

  return (
    <Button
      variant="contained"
      disabled={!enableListType}
      color={listType === ProductionListType.PRIORITY ? "secondary" : "inherit"}
      startIcon={<PriorityHighIcon />}
      onClick={() => dispatch(setListType(ProductionListType.PRIORITY))}
    >
      <Hidden smDown>{t("productionList:priority")}</Hidden>
    </Button>
  );
};

export default PriorityButton;
