import React from "react";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/CloseSharp";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(1),
    fontSize: "40px",
  },
  button: {
    textAlign: "left",
    lineHeight: "20px",
    "&:disabled": {
      backgroundColor: "rgba(224, 224, 224, 0.4)",
    },
  },
}));

const CloseButton = ({ onClick, disabled }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Button className={clsx(classes.button)} variant="contained" disabled={disabled} onClick={onClick}>
      <CloseIcon className={clsx(classes.icon)} />
      {t("common:close")}
    </Button>
  );
};

export default CloseButton;
