import React, { useState, useEffect } from "react";
import moment from "moment";
import { makeStyles, withStyles } from "@mui/styles";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
  LinearProgress,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/CloseSharp";
import CheckIcon from "@mui/icons-material/CheckCircleOutlineSharp";
import { useTranslation } from "react-i18next";
import { forceEndCustomerChange, newCustomerChange } from "../../../api/CustChangeStationApi";
import { useCustomer } from "../../../selectors/customers";
import { useCurrentRole } from "../../../selectors/useRole";
import CustomerFlagSelector from "../../../components/FlagSelectors/CustomerFlagSelector";
import ExtCustChangeButton from "../../../components/ExtCustChangeButton";
import CustomerTicketScanner from "./CustomerTicketScanner";

const useConfirmStyles = makeStyles((theme) => ({
  confirmButton: {
    backgroundColor: theme.palette.success.main,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
    },
  },
  dialogContentText: {
    display: "flex",
    alignItems: "center",
  },
  infoIcon: {
    width: "128px",
    height: "auto",
    marginRight: theme.spacing(2),
    color: theme.palette.info.main,
  },
}));

const CustChangeConfirmDialog = ({ open, customerId, onClose, onConfirm }) => {
  const classes = useConfirmStyles();
  const { t } = useTranslation();

  const customer = useCustomer(customerId);

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"sm"} fullWidth={true}>
      <Box px={3} py={2}>
        <Typography variant="h3">{t("common:confirm_selection")}</Typography>
      </Box>

      <DialogContent>
        <DialogContentText className={classes.dialogContentText} variant="body1">
          <InfoIcon className={classes.infoIcon} />
          {t("customerChange:are_you_sure_cust_change", { name: customer.name })}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button variant="contained" autoFocus onClick={onClose} startIcon={<CloseIcon />}>
          {t("common:cancel")}
        </Button>

        <Button variant="contained" className={classes.confirmButton} onClick={onConfirm} startIcon={<CheckIcon />}>
          {t("common:yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  titleBar: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const DistanceProgress = withStyles((theme) => ({
  root: {
    height: 15,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[200],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.info.main,
  },
}))(LinearProgress);

const CustomerChangeDistance = ({ distance, total }) => {
  const { t } = useTranslation();
  const custChangePercent = Math.ceil((distance / total) * 100);
  if (!distance) return null;
  return (
    <>
      <DistanceProgress variant="determinate" value={custChangePercent} />
      <Typography variant="caption">
        {t("customerChange:distance")}
        {`${distance} / ${total}`}
      </Typography>
    </>
  );
};

const ElapsedTimeSinceChange = ({ custChangeStart }) => {
  const { t } = useTranslation();
  const [minutes, setMinutes] = useState();
  const [seconds, setSeconds] = useState();

  useEffect(() => {
    const startTime = moment(custChangeStart);

    const interval = setInterval(() => {
      const endTime = moment();
      const duration = Math.floor(moment.duration(endTime.diff(startTime)).asSeconds());
      const elapsedMinutes = Math.floor(duration / 60);
      const elapsedSeconds = duration % 60 < 10 ? `0${duration % 60}` : duration % 60;
      setMinutes(elapsedMinutes);
      setSeconds(elapsedSeconds);
    }, 1000);

    return () => clearInterval(interval);
  }, [custChangeStart]);

  if (!custChangeStart || Number.isNaN(minutes) || Number.isNaN(seconds)) return null;
  return (
    <>
      <Typography variant="h2" color="error">{`${minutes}:${seconds}`}</Typography>
      <Typography variant="caption">{t("customerChange:time_elapsed")}</Typography>
    </>
  );
};

const CustChangeControls = ({ id, currentCustomer, customerChange }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { canCustomerChange, canForceCustomerChange } = useCurrentRole();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const [customerChangeId, setCustomerChangeId] = useState(currentCustomer.id);
  useEffect(() => {
    if (currentCustomer.id !== customerChangeId) {
      setCustomerChangeId(currentCustomer.id);
    }
  }, [currentCustomer.id]);

  const handleCustomerSelect = (customerId) => {
    setCustomerChangeId(customerId);
    setOpenConfirmDialog(true);
  };

  const handleForceCustChangeComplete = () => forceEndCustomerChange(id);

  const { custChangeStart, custChangeDistance, custChangeTotalDistance } = customerChange;
  return (
    <Card className={classes.titleBar}>
      <Typography variant="h1" gutterBottom>
        {t("customerChange:customer_change_controls")}
      </Typography>

      <CustomerTicketScanner onScan={handleCustomerSelect} />

      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          {!openConfirmDialog && (
            <CustomerFlagSelector
              selectedId={currentCustomer.id}
              handleSelect={handleCustomerSelect}
              disabled={!canCustomerChange}
            />
          )}
        </Grid>

        <Grid container direction="column" spacing={2}>
          <Grid item>
            {custChangeStart && (
              <Typography color="error" variant="overline">
                {t("customerChange:customer_change_in_progress")}
              </Typography>
            )}
          </Grid>

          <Grid item>
            <ElapsedTimeSinceChange custChangeStart={custChangeStart} />
          </Grid>

          <Grid item>
            <CustomerChangeDistance distance={custChangeDistance} total={custChangeTotalDistance} />
          </Grid>
        </Grid>

        <Grid container justifyContent="space-between" spacing={1}>
          <Grid item>
            <ExtCustChangeButton custChangeStationId={id} />
          </Grid>

          <Grid item>
            <Button
              color="primary"
              variant="contained"
              disabled={!canForceCustomerChange}
              onClick={handleForceCustChangeComplete}
            >
              {t("customerChange:force_complete")}
            </Button>
          </Grid>
        </Grid>

        <CustChangeConfirmDialog
          open={openConfirmDialog}
          customerId={customerChangeId}
          onClose={() => setOpenConfirmDialog(false)}
          onConfirm={() => {
            newCustomerChange(id, customerChangeId);
            setOpenConfirmDialog(false);
          }}
        />
      </Grid>
    </Card>
  );
};

export default CustChangeControls;
