import React, { useMemo } from "react";
import _ from "lodash";
import { Button, ButtonGroup, Grid } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { useTranslation } from "react-i18next";
import SearchField from "../SearchField";

const FilterControls = ({ sx, sortBy, order, searchPlaceholder = "", onChangeSortBy, onChangeSearchTerm }) => {
  const debouncedChangeHandler = useMemo(() => {
    return _.debounce(onChangeSearchTerm, 300);
  }, []);

  const { t } = useTranslation();
  return (
    <Grid container alignItems="flex-end" justifyContent="space-between" sx={sx}>
      <ButtonGroup variant="outlined" size="large" disableRipple={true}>
        <Button
          color={sortBy === "number" ? "secondary" : "primary"}
          endIcon={sortBy === "number" && order === "desc" ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
          onClick={() => onChangeSortBy("number")}
        >
          {t("common:number")}
        </Button>

        <Button
          color={sortBy === "name" ? "secondary" : "primary"}
          endIcon={sortBy === "name" && order === "desc" ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
          onClick={() => onChangeSortBy("name")}
        >
          {t("common:name")}
        </Button>
      </ButtonGroup>

      <Grid item>
        <SearchField
          sx={{ width: 350 }}
          placeholder={searchPlaceholder}
          onChange={debouncedChangeHandler}
          onSubmitSearchTerm={onChangeSearchTerm}
        />
      </Grid>
    </Grid>
  );
};

export default FilterControls;
