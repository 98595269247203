import React from "react";
import _ from "lodash";
import { Select, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getSystems } from "../../selectors/systems";
import store from "../../store";

const SystemSelector = ({ selectedSystem, onChange }) => {
  const { t } = useTranslation();
  const systems = getSystems(store.getState());

  return (
    <Select
      variant="outlined"
      displayEmpty={true}
      value={selectedSystem || ""}
      onChange={(e) => onChange(e.target.value)}
    >
      <MenuItem value="">
        <em>{t("railsSetup:select_system")}</em>
      </MenuItem>
      {Object.values(systems).map((system) => (
        <MenuItem key={system.id} value={system.id}>
          {t(`common:${_.snakeCase(system?.name || "")}`)}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SystemSelector;
