import React from "react";
import { makeStyles } from "@mui/styles";
import * as MuiIcons from "@mui/icons-material";
import { Avatar, Box, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/CloseSharp";
import NumberBox from "../NumberBox";
import StrategySelectorButton from "./StrategySelectorButton";

const useStyles = makeStyles((theme) => ({
  rowNumber: {
    margin: "0 14px 0 6px",
  },
  root: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    borderRadius: "3px",
    background: "#fff",
    padding: "0.5rem",
    border: (props) => (props.isDragging ? "1px dashed #000" : "1px solid #ddd"),
  },
  content: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    color: "#ffffff",
    backgroundColor: "#424242",
    marginRight: "8px",
  },
  label: {
    marginLeft: "8px",
  },
}));

const ProductionListCard = (props) => {
  const { color, icon, index, isRemovable, isDragging, label, number, onClose, showPosition, strategySelector } = props;
  const classes = useStyles({ isDragging });
  const Icon = MuiIcons[icon];

  return (
    <>
      {showPosition && (
        <Typography variant="h4" className={classes.rowNumber}>
          {index + 1}
        </Typography>
      )}

      <div className={classes.root}>
        <div className={classes.content}>
          <Avatar variant="rounded" className={classes.icon}>
            <Icon />
          </Avatar>

          {!isNaN(number) && <NumberBox label={number} color={color} />}
          <Typography className={classes.label}>{label}</Typography>
        </div>

        <Box display="flex" alignItems="center">
          {strategySelector}
          {isRemovable && <CloseIcon sx={{ ml: 1 }} onClick={onClose} />}
        </Box>
      </div>
    </>
  );
};

export default ProductionListCard;
