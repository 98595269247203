import React, { useCallback, useEffect, useRef, useState } from "react";
import _ from "lodash";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/CloseSharp";
import AddIcon from "@mui/icons-material/AddCircle";
import { makeStyles, withStyles } from "@mui/styles";
import { v4 as uuidv4 } from "uuid";
import { VariableSizeGrid } from "react-window";

import FormFieldLabel from "../SetupPages/FormFieldLabel";
import { getCategoryName, useCategories } from "../../selectors/categories";
import store from "../../store";
import FilterControls from "../FlagSelectors/FilterControls";
import CategoryCardStandard from "../FlagSelectors/CategoryCardStandard";
import { filterAndSortSelections } from "../FlagSelectors/utilities";
import SaveButton from "../SaveButton";
import Keypad from "../SetupPages/Keypad";

const FormConfigPerCategoryManager = ({ currentValues, disabled, onArrayFieldChange }) => {
  const { t } = useTranslation();
  const categoryRates = currentValues["sortClassificationPerspectives"];
  const filteredCatRates = categoryRates.slice().filter((cr) => !cr._destroy);
  const state = store.getState();

  const [openEditor, setOpenEditor] = useState(false);
  const [selectedCatRate, setSelectedCatRate] = useState(null);

  const handleOnRemove = (catRateId) => {
    const foundIdIndex = categoryRates?.findIndex((sortClassPers) => {
      return sortClassPers.id === catRateId;
    });
    if (foundIdIndex >= 0) {
      return onArrayFieldChange({
        field: "sortClassificationPerspectives",
        operation: "update",
        index: foundIdIndex,
        value: {
          ...categoryRates[foundIdIndex],
          _destroy: true,
        },
      });
    }
  };

  const handleOnUpdate = (updatedCategoryRate) => {
    const foundIdIndex = categoryRates?.findIndex((sortClassPers) => {
      return sortClassPers.id === updatedCategoryRate.id;
    });
    if (foundIdIndex >= 0) {
      return onArrayFieldChange({
        field: "sortClassificationPerspectives",
        operation: "update",
        index: foundIdIndex,
        value: updatedCategoryRate,
      });
    }
  };

  const handleOnAdd = (newCategoryRate) => {
    onArrayFieldChange({
      field: "sortClassificationPerspectives",
      operation: "add",
      value: newCategoryRate,
    });
  };

  return (
    <Box display="flex" flexDirection="column">
      <FormFieldLabel label={t("productionPointsSetup:default_configured_rates")} />

      <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
        {filteredCatRates?.length > 0 &&
          filteredCatRates.map((catRate) => (
            <Chip
              key={catRate.id ? `chip-${catRate.id}` : `new-cat-rate-${uuidv4()}`}
              sx={{ mr: 1, mb: 1 }}
              label={`${getCategoryName(state, catRate.sortClassificationId)}: ${catRate.targetRate || 0}`}
              variant="outlined"
              color="primary"
              disabled={disabled}
              onDelete={() => handleOnRemove(catRate.id)}
              onClick={() => {
                setSelectedCatRate(catRate);
                setOpenEditor(true);
              }}
            />
          ))}

        <IconButton color="primary" disabled={disabled} onClick={() => setOpenEditor(true)}>
          <AddIcon sx={{ fontSize: 40 }} />
        </IconButton>
      </Box>

      {openEditor && (
        <CategoryRateEditor
          key={selectedCatRate?.id || "new-category-rate"}
          open={openEditor}
          categoryRate={selectedCatRate}
          onUpdate={handleOnUpdate}
          onAdd={handleOnAdd}
          onClose={() => {
            setOpenEditor(false);
            setSelectedCatRate(null);
          }}
        />
      )}
    </Box>
  );
};

const GridSelection = ({ selection, highlight, style, rowIndex, disabled, onClick, setRowHeight }) => {
  const selectionRef = useRef({});
  useEffect(() => {
    if (selectionRef.current) {
      setRowHeight(rowIndex, selectionRef.current.clientHeight);
    }
  }, [selectionRef]);

  return (
    <div
      ref={selectionRef}
      style={{
        ...style,
        height: "fit-content",
        paddingRight: 16,
        paddingBottom: 16,
      }}
    >
      <CategoryCardStandard
        name={selection.name}
        number={_.isNull(selection.number) ? " " : selection.number}
        imageUrl={selection.imageUrl}
        color={selection.color}
        highlight={highlight}
        disabled={disabled}
        onClick={onClick}
      />
    </div>
  );
};

const CategoriesList = ({ sortedCategories, setEditableCatRate, editableCatRate }) => {
  const defaultColumnWidth = 216;
  const defaultRowHeight = 116;

  const [dialogContentWidth, setDialogContentWidth] = useState(null);
  const [dialogContentHeight, setDialogContentHeight] = useState(null);
  const dialogContentRef = useCallback((node) => {
    if (node !== null) {
      setDialogContentWidth(node.getBoundingClientRect().width);
      setDialogContentHeight(node.getBoundingClientRect().height);
    }
  }, []);

  const columnCount = Math.floor(dialogContentWidth / defaultColumnWidth);
  const rowCount = Math.ceil(sortedCategories?.length / columnCount);

  const gridRef = useRef();
  const elementHeights = useRef({});
  const setRowHeight = (index, height) => {
    gridRef.current.resetAfterRowIndex(0);
    const rowHeight = elementHeights.current[index] || 0;

    if (height > rowHeight) {
      elementHeights.current = { ...elementHeights.current, [index]: height };
    }
  };

  const getRowHeight = (index) => {
    return elementHeights.current[index] || defaultRowHeight;
  };

  return (
    <Box ref={dialogContentRef} width="100%" height="100%">
      {dialogContentWidth && dialogContentHeight && (
        <VariableSizeGrid
          ref={gridRef}
          columnCount={columnCount}
          columnWidth={() => defaultColumnWidth}
          height={dialogContentHeight}
          width={dialogContentWidth}
          rowCount={rowCount}
          rowHeight={getRowHeight}
          overscanRowCount={5}
        >
          {({ columnIndex, rowIndex, style }) => {
            const foundIndex = rowIndex * columnCount + columnIndex;
            const selection = sortedCategories[foundIndex];

            if (!selection) return null;
            return (
              <GridSelection
                key={selection?.id}
                selection={selection}
                style={style}
                rowIndex={rowIndex}
                highlight={editableCatRate?.sortClassificationId === selection.id}
                setRowHeight={setRowHeight}
                onClick={() =>
                  setEditableCatRate({
                    ...editableCatRate,
                    sortClassificationId: selection.id,
                  })
                }
              />
            );
          }}
        </VariableSizeGrid>
      )}
    </Box>
  );
};

const StyledDialog = withStyles({
  paper: {
    maxHeight: "calc(100vh - 180px)",
  },
})(Dialog);

const CategoryRateEditor = ({ open, categoryRate, onClose, onUpdate, onAdd }) => {
  const { t } = useTranslation();
  const categories = useCategories();

  const [editableCatRate, setEditableCatRate] = useState(categoryRate || {});
  const handleOnKeypadValueChange = (rate) => setEditableCatRate({ ...editableCatRate, targetRate: rate });

  const [order, setOrder] = useState("asc");
  const [sortBy, setSortBy] = useState("number");
  const [searchTerm, setSearchTerm] = useState(null);
  const handleChangeSortBy = (selected) => {
    if (selected === sortBy) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      setOrder("asc");
      setSortBy(selected);
    }
  };

  const sortedCategories = filterAndSortSelections({
    selections: categories.slice(),
    searchTerm,
    sortBy,
    order,
  });

  return (
    <StyledDialog open={open} maxWidth="md" onBackdropClick={onClose} fullWidth={true}>
      <Box padding="16px 24px">
        <Typography variant="h3">
          {`${categoryRate ? t("common:edit") : t("common:add")} ${t("productionPointsSetup:category_rate")}`}
        </Typography>
        <Typography>{t("productionPointsSetup:select_category_and_rate")}</Typography>

        <FilterControls
          sx={{ mt: 2 }}
          searchPlaceholder={t("productionPointsSetup:search_by_name_or_number")}
          sortBy={sortBy}
          order={order}
          onChangeSortBy={handleChangeSortBy}
          onChangeSearchTerm={(searchTerm) => setSearchTerm(searchTerm)}
        />
      </Box>

      <DialogContent dividers={true} sx={{ height: 500, overflow: "hidden" }}>
        <Grid container sx={{ height: "100%" }}>
          <Grid item xs={8} container spacing={1} sx={{ height: "100%" }}>
            <CategoriesList
              sortedCategories={sortedCategories}
              editableCatRate={editableCatRate}
              setEditableCatRate={setEditableCatRate}
            />
          </Grid>
          <Grid item xs={4}>
            <FormKeypad value={editableCatRate.targetRate || ""} onChange={handleOnKeypadValueChange} />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        {categoryRate ? (
          <SaveButton
            disableElevation={true}
            onClick={() => {
              onUpdate(editableCatRate);
              onClose();
            }}
          >
            {t("common:update")}
          </SaveButton>
        ) : (
          <Button
            disabled={!editableCatRate.sortClassificationId || !editableCatRate.targetRate}
            variant="outlined"
            disableElevation={true}
            onClick={() => {
              onAdd(editableCatRate);
              onClose();
            }}
          >
            {t("common:add")}
          </Button>
        )}

        <Button variant="outlined" onClick={onClose}>
          <CloseIcon />
          {t("common:close")}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

const useKeypadStyles = makeStyles((theme) => ({
  titleText: {
    width: 220,
  },
  inputField: {
    width: "100%",
  },
  rootInput: {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none",
    },
  },
}));

const FormKeypad = ({ value, onChange }) => {
  const classes = useKeypadStyles();

  const handleNumberClick = (number) => {
    const updatedValue = value * 10 + number;
    onChange(updatedValue);
  };

  const handleClearField = () => onChange("");

  const handleDeleteSingleChar = () => {
    const newValue = value < 10 ? "" : Math.floor(value / 10);
    onChange(newValue);
  };

  const isTouchDevice = () =>
    "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

  const handleChange = (event) => {
    const updatedValue = event.target.value;
    updatedValue !== value && onChange(updatedValue);
  };

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <TextField
          className={classes.inputField}
          type="number"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            readOnly: isTouchDevice(),
            classes: { root: classes.rootInput },
          }}
          value={value}
          onChange={handleChange}
          onFocus={(event) => event.target.select()}
        />
      </Grid>

      <Grid item>
        <Keypad onClickNumber={handleNumberClick} onClear={handleClearField} onDelete={handleDeleteSingleChar} />
      </Grid>
    </Grid>
  );
};

export default FormConfigPerCategoryManager;
