import React from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import FormDropdownSelect from "../../../../components/SetupPages/FormDropdownSelect";
import type { StorageRuleset } from "../../dto/StorageRuleset";
import { StrgRulesetRoutingTypes } from "../../enum/StrgRulesetRoutingTypes";

interface Props {
  routingType: StrgRulesetRoutingTypes;
  disabled: boolean;
  onFieldChange: (field: keyof StorageRuleset, value: StorageRuleset["routingType"]) => void;
};

const RoutingTypeDropdown = ({ routingType, disabled, onFieldChange }: Props) => {
  const { t } = useTranslation();

  const translationMapping = {
    [t("storageManager:auto")]: StrgRulesetRoutingTypes.AUTO,
    [t("storageManager:custom")]: StrgRulesetRoutingTypes.CUSTOM,
    [t("storageManager:manual")]: StrgRulesetRoutingTypes.MANUAL,
  };

  const handleOnFieldChange = (field: keyof StorageRuleset, value: string) => {
    const translatedValue = translationMapping[value];
    return onFieldChange(field, translatedValue)
  };

  return (
    <FormDropdownSelect
      fieldName="routingType"
      currentValues={{ routingType: t(`storageManager:${routingType || StrgRulesetRoutingTypes.AUTO}`) }}
      options={[t("storageManager:auto"), t("storageManager:manual"), t("storageManager:custom")]}
      disabled={disabled}
      onFieldChange={handleOnFieldChange}
    />
  )
}

export default RoutingTypeDropdown;
