import { useEffect } from "react";
import { useCustomerIdFromNumber } from "../../../selectors/customers";
import { useCategoryIdFromNumber } from "../../../selectors/categories";
import { assignCategoryToSis, assignCustomerToSis } from "../../../api/SlingInputStationApi";
import { useTicketScanner } from "./useTicketScanner";

interface Props {
  sisId: number;
  position: number;
};

const useUserScannedValues = ({ sisId, position }: Props) => {
  const ticketScanInfo = useTicketScanner();

  const scannedCustomerNumber = parseInt(ticketScanInfo?.at(0));
  const customerId = useCustomerIdFromNumber(scannedCustomerNumber);

  const scannedCategoryNumber = parseInt(ticketScanInfo?.at(1));
  const categoryId = useCategoryIdFromNumber(scannedCategoryNumber);

  const scanCount = ticketScanInfo?.at(2);
  useEffect(() => {
    if (scanCount > 0) {
      assignCategoryToSis({ sisId, categoryId: categoryId || 0, position });
      assignCustomerToSis({ sisId, customerId: customerId || 0, position });
    }
  }, [scanCount, customerId, categoryId, sisId, position]);
};

export default useUserScannedValues;
