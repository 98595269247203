import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, FormControlLabel, Tooltip } from "@mui/material";
import PublishIcon from "@mui/icons-material/Publish";

import { useLiveWeightForRailId } from "../../selectors/rails";
import { useCategory } from "../../selectors/categories";
import { getBagWeightStatus } from "./utils";

const StyledTooltip = ({ open, title, children }) => {
  return (
    <Tooltip
      componentsProps={{
        popper: {
          sx: (theme) => ({
            zIndex: theme.zIndex.drawer,
          }),
        },
        tooltip: {
          sx: (theme) => ({
            fontSize: theme.typography.h3,
            color: theme.palette.common.white,
          }),
        },
      }}
      arrow
      placement="right"
      open={open}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title={title || ""}
    >
      {children}
    </Tooltip>
  );
};

const StyledButton = ({ disabled, children, onClick }) => {
  return (
    <Button
      sx={(theme) => ({
        borderRadius: "50%",
        padding: theme.spacing(2),
        fontSize: 40,
        backgroundColor: theme.palette.success["dark"],

        "&:disabled": {
          backgroundColor: theme.palette.grey[200],
        },
        "&:hover:enabled": {
          backgroundColor: theme.palette.success["main"],
        },

        "& #start-icon": {
          fontSize: 40,
        },
      })}
      size="large"
      disabled={disabled}
      variant="contained"
      disableElevation={true}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

const ConciseAcceptButton = ({ sx, sis, disabled, onClick }) => {
  const { t } = useTranslation();
  const category = useCategory(sis?.category1Id);
  const liveWeight = useLiveWeightForRailId(sis?.railId);
  const weightStatus = getBagWeightStatus({ slingLoaded: true, sis, category, currentWeight: liveWeight });
  const underweight = weightStatus === "underweight";
  const overweight = weightStatus === "overweight";
  const tooltipTitle =
    underweight || overweight ? t(`slingInputStation:${underweight ? "bag_underweight" : "bag_overweight"}`) : null;

  return (
    <StyledTooltip open={underweight || overweight} title={tooltipTitle}>
      <FormControlLabel
        control={
          <StyledButton disabled={disabled || overweight} onClick={onClick}>
            <PublishIcon id="start-icon" />
          </StyledButton>
        }
        label={t("slingInputStation:accept_send_load")}
        labelPlacement="top"
        sx={sx}
      />
    </StyledTooltip>
  );
};

ConciseAcceptButton.propTypes = {
  sx: PropTypes.object,
  sis: PropTypes.object,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default ConciseAcceptButton;
