import React from "react";
import PropTypes from "prop-types";
import { Button, FormControlLabel, Tooltip } from "@mui/material";
import ForwardIcon from "@mui/icons-material/Forward";
import { useTranslation } from "react-i18next";
import { IoConstant } from "../../../constants/IoConstant";

const ManSendButton = (props) => {
  const { disabled, size = "medium", disabledReasons = [], label, iconDirection = "forward", onClick } = props;
  const { t } = useTranslation();

  return (
    <Tooltip
      componentsProps={{
        popper: { sx: { zIndex: 1 } },
        tooltip: { sx: { fontSize: 15 } },
      }}
      arrow
      placement="bottom"
      open={disabledReasons.length > 0}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title={`${t("slingInputStation:disabled")}: ${disabledReasons.join(", ")} `}
    >
      <FormControlLabel
        control={
          <Button
            variant="contained"
            color="primary"
            sx={{
              borderRadius: "50%",
              padding: 2,
              fontSize: 40,

              "& #start-icon": {
                fontSize: 40,
              },
            }}
            size={size === "xLarge" ? "large" : size}
            disabled={disabled}
            disableElevation={true}
            onClick={onClick}
          >
            <ForwardIcon
              id="start-icon"
              sx={{ transform: `rotate(${iconDirection === "backward" ? "180deg" : "0"})` }}
            />
          </Button>
        }
        label={label}
        labelPlacement="top"
      />
    </Tooltip>
  );
};

const ConciseManSendButton = ({ railIoTypeId, ...rest }) => {
  const hideManualSend =
    IoConstant.SINGLE_THREE_STAGE_WITH_LOADCELL_IO_TYPE === railIoTypeId ||
    IoConstant.SINGLE_FREE_SWING_LIFT_IO_TYPE === railIoTypeId ||
    IoConstant.GUIDED_LOADING_LIFT_WITH_JOGGING === railIoTypeId;

  if (hideManualSend) return null;
  return <ManSendButton {...rest} />;
};

ConciseManSendButton.propTypes = {
  railIoType: PropTypes.number,
  size: PropTypes.oneOf(["small", "medium", "large", "xLarge"]),
  externalDisableReasons: PropTypes.array,
  iconDirection: PropTypes.oneOf(["forward", "backward"]),
};

export default ConciseManSendButton;
