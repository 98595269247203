import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import { useSlingInputStation } from "../../selectors/slingInputStations";
import { useRail } from "../../selectors/rails";
import { railInputEnabled, railOutputEnabled } from "../../api/RailApi";
import ConciseClearWeightsButton from "../../features/slingInputStations/components/ConciseClearWeightsButton";
import GoodsWeight from "../../features/slingInputStations/components/GoodsWeight";
import ScannerWrapper from "../../features/slingInputStations/components/ScannerWrapper";
import SlingTypeSelector from "../../features/slingInputStations/components/SlingTypeSelector";
import ConciseCustomActionButton from "../../features/slingInputStations/components/ConciseCustomActionButton";
import { IndicatorStatusTypes } from "../../features/panels/enum/IndicatorStatusTypes";
import CircleIndicator from "../CircleIndicator";
import SingleSlingInput from "./SingleSlingInput";
import CondensedSingleSlingInput from "./CondensedSingleSlingInput";
import LiveWeight from "./LiveWeight";
import { FLOOR_SCALE_SINGLE, FLOOR_SCALE_DOUBLE } from "./weightInputTypes";
import CallEmptyButton from "./CallEmptyButton";
import ConfigurationButton from "./ConfigurationButton";
import ConciseManSendButton from "./ConciseManSendButton";
import ConciseAddSlingButton from "./ConciseAddSlingButton";
import ConciseDeleteSlingButton from "./ConciseDeleteSlingButton";
import DefaultSISConfigurationOptions from "./DefaultSISConfigurationOptions";

const DefaultSIS = ({ sisId }) => {
  const sis = useSlingInputStation(sisId) || {};
  const rail = useRail(sis.railId) || {};
  const showTwoSlings = rail.fullQuantity > 1;

  return (
    <Paper square={true} elevation={0} sx={{ width: "100%", height: "100%" }}>
      {showTwoSlings ? (
        <DoubleSlingLayout rail={rail} slingInputStation={sis} />
      ) : (
        <SingleSlingLayout rail={rail} slingInputStation={sis} weightInputType={sis.weightInputType} />
      )}
    </Paper>
  );
};

const SingleSlingLayout = ({ rail, slingInputStation, weightInputType }) => {
  const { t } = useTranslation();

  const sisId = slingInputStation?.id;
  const configuration = slingInputStation?.configuration;
  const { name, inputEnabled, outputEnabled, plcState } = rail;

  const handleToggleInbound = () => railInputEnabled({ id: rail.id, inputEnabled });
  const handleToggleOutbound = () => railOutputEnabled({ id: rail.id, outputEnabled });

  return (
    <>
      <Box display="flex" flexDirection="column" padding={2}>
        <Typography variant="h1" gutterBottom>
          {name}
        </Typography>
        <Typography variant="overline">
          {t("railStates:state_state", {
            state: t(`railStates:${plcState}`),
          })}
        </Typography>

        <Box display="flex" justifyContent="space-between" alignItems="flex-end" mt={2}>
          <div>
            <CircleIndicator
              label={t("common:in")}
              status={inputEnabled ? IndicatorStatusTypes.ON : IndicatorStatusTypes.OFF}
              onClick={handleToggleInbound}
            />
            <CircleIndicator
              label={t("common:out")}
              status={outputEnabled ? IndicatorStatusTypes.ON : IndicatorStatusTypes.OFF}
              onClick={handleToggleOutbound}
            />
          </div>

          <Box display="flex" alignItems="flex-end">
            <ConciseManSendButton sisId={sisId} size="xLarge" />
            <ConciseAddSlingButton sisId={sisId} size="xLarge" />
            <ConciseDeleteSlingButton sisId={sisId} size="xLarge" />
            <CallEmptyButton sisId={sisId} size="xLarge" />
            <ConciseCustomActionButton sisId={sisId} />

            {[FLOOR_SCALE_SINGLE, FLOOR_SCALE_DOUBLE].includes(weightInputType) && (
              <Box display="flex" justifyContent="flex-end" alignItems="flex-end" ml={4}>
                <LiveWeight railId={rail.id} />
                {(slingInputStation?.cartWeight1 || 0) > 0 && <GoodsWeight sis={slingInputStation} sx={{ ml: 1 }} />}
              </Box>
            )}

            {configuration?.showSlingTypeSelector && <SlingTypeSelector sisId={sisId} sx={{ ml: 1, mr: 1 }} />}

            <ConfigurationButton>
              <DefaultSISConfigurationOptions sisId={sisId} />
            </ConfigurationButton>
          </Box>
        </Box>
      </Box>

      <Divider />

      <Box display="flex" justifyContent="center" padding={2}>
        {slingInputStation.enableBarcodeScan && <ScannerWrapper sisId={sisId} />}

        <SingleSlingInput
          key={`sling-${sisId}-${1}`}
          sisId={sisId}
          position={1}
          showCaptureWeightButton={[FLOOR_SCALE_SINGLE, FLOOR_SCALE_DOUBLE].includes(weightInputType)}
        />
      </Box>
    </>
  );
};

const DoubleSlingLayout = ({ rail, slingInputStation }) => {
  const { t } = useTranslation();

  const { name, inputEnabled, outputEnabled, plcState } = rail;

  const sisId = slingInputStation.id;
  const configuration = slingInputStation?.configuration;
  const slingOrder = slingInputStation.configuration?.slingOrder || "rtl";

  const handleToggleInbound = () => railInputEnabled({ id: rail.id, inputEnabled });
  const handleToggleOutbound = () => railOutputEnabled({ id: rail.id, outputEnabled });

  return (
    <>
      <Box display="flex" flexDirection="column" padding={2}>
        <Grid container justifyContent="space-between" sx={{ mb: 2 }}>
          <Grid item sx={{ flexDirection: "column" }}>
            <Typography variant="h1" gutterBottom>
              {name}
            </Typography>
            <Typography variant="overline">
              {t("railStates:state_state", {
                state: t(`railStates:${plcState}`),
              })}
            </Typography>
          </Grid>
        </Grid>

        <Grid container justifyContent="space-between" alignItems="flex-end">
          <Grid item sx={{ alignSelf: "flex-end" }}>
            <CircleIndicator
              label={t("common:in")}
              status={inputEnabled ? IndicatorStatusTypes.ON : IndicatorStatusTypes.OFF}
              onClick={handleToggleInbound}
            />
            <CircleIndicator
              label={t("common:out")}
              status={outputEnabled ? IndicatorStatusTypes.ON : IndicatorStatusTypes.OFF}
              onClick={handleToggleOutbound}
            />
          </Grid>

          <Box display="flex" alignItems="flex-end">
            <ConciseManSendButton sisId={sisId} size="xLarge" />
            <ConciseAddSlingButton sisId={sisId} size="xLarge" />
            <ConciseDeleteSlingButton sisId={sisId} size="xLarge" />
            <CallEmptyButton sisId={sisId} size="xLarge" />
            <ConciseCustomActionButton sisId={sisId} />

            {slingInputStation.weightInputType === FLOOR_SCALE_DOUBLE && <ConciseClearWeightsButton sisId={sisId} />}
            {configuration?.showSlingTypeSelector && <SlingTypeSelector sisId={sisId} sx={{ ml: 1, mr: 1 }} />}

            {[FLOOR_SCALE_SINGLE, FLOOR_SCALE_DOUBLE].includes(slingInputStation.weightInputType) && (
              <Box display="flex" justifyContent="flex-end" ml={4}>
                <LiveWeight railId={rail.id} />
                {(slingInputStation?.cartWeight1 || 0) > 0 && <GoodsWeight sis={slingInputStation} sx={{ ml: 1 }} />}
              </Box>
            )}

            <ConfigurationButton>
              <DefaultSISConfigurationOptions sisId={sisId} />
            </ConfigurationButton>
          </Box>
        </Grid>
      </Box>

      <Divider />

      <Grid item sx={{ display: "flex", padding: 2, marginTop: 2 }}>
        <Grid item sx={{ marginRight: 2 }} xs={6}>
          <CondensedSingleSlingInput
            key={`sling-${sisId}-${slingOrder === "ltr" ? 1 : 2}`}
            sisId={sisId}
            position={slingOrder === "ltr" ? 1 : 2}
            label={slingOrder === "ltr" ? t("slingInputStation:front") : t("slingInputStation:back")}
            showCaptureWeightButton={[FLOOR_SCALE_SINGLE, FLOOR_SCALE_DOUBLE].includes(
              slingInputStation?.weightInputType
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <CondensedSingleSlingInput
            key={`sling-${sisId}-${slingOrder === "ltr" ? 2 : 1}`}
            sisId={sisId}
            position={slingOrder === "ltr" ? 2 : 1}
            label={slingOrder === "ltr" ? t("slingInputStation:back") : t("slingInputStation:front")}
            showCaptureWeightButton={[FLOOR_SCALE_SINGLE, FLOOR_SCALE_DOUBLE].includes(
              slingInputStation?.weightInputType
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

DoubleSlingLayout.propTypes = {
  rail: PropTypes.object,
  slingInputStation: PropTypes.object,
};

DoubleSlingLayout.defaultProps = {
  rail: {},
  slingInputStation: {},
};

export default DefaultSIS;
