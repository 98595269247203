import { eVueApi } from "../../../services/eVue";
import type { SlingType } from "../../../dto/eVue/SlingType";

export const slingTypesApi = eVueApi.injectEndpoints({
  endpoints: (builder) => ({
    getSlingTypes: builder.query<SlingType[], void>({
      query: () => "/sling_types",
    }),
  }),
  overrideExisting: false,
});

export const { useGetSlingTypesQuery } = slingTypesApi;
