import React from "react";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useStaticBag } from "../selectors/staticBags";
import RailControls from "../components/StaticBag/RailControls";
import StaticBagRailLoaderDialog from "../components/StaticBag/StaticBagRailLoaderDialog";

const StaticBagInterface = () => {
  const params = useParams();
  const staticBag = useStaticBag(params.id);

  return (
    <Grid style={{ padding: 24 }} container direction="column">
      <Grid item container direction="column" wrap="nowrap">
        <Grid item>
          <RailControls discharger={true} staticBag={staticBag} />
        </Grid>

        <Grid item>
          <RailControls staticBag={staticBag} />
        </Grid>
      </Grid>

      <StaticBagRailLoaderDialog railId={staticBag.staticBagRailId} />
    </Grid>
  );
};

export default StaticBagInterface;
