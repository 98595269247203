import React, { useState, useEffect } from "react";
import _ from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import WarningIcon from "@mui/icons-material/WarningSharp";
import { makeStyles } from "@mui/styles";
import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import { useSlingInputStation } from "../../selectors/slingInputStations";
import { useRail } from "../../selectors/rails";
import { getRailVisitsCountForRail, getFirstRVBatchId } from "../../selectors/railVisits";
import { useBarcodeFromId } from "../../selectors/barcodes";
import { useBatch } from "../../selectors/batches";
import { useCyclesPerMaintenance, useDaysPerMaintenance } from "../../selectors/uiSettings";
import { railInputEnabled, railOutputEnabled } from "../../api/RailApi";
import { assignBarcodeToSis, fetchMaintenanceReasons } from "../../api/SlingInputStationApi";
import { IndicatorStatusTypes } from "../../features/panels/enum/IndicatorStatusTypes";
import CircleIndicator from "../CircleIndicator";
import ConciseAddSlingButton from "./ConciseAddSlingButton";
import ConciseDeleteSlingButton from "./ConciseDeleteSlingButton";
import ConciseManSendButton from "./ConciseManSendButton";
import BagStatusIndicator from "./BagStatusIndicator";
import CompMaintSettingsButton from "./CompMaintSettingsButton";
import MaintenanceQueue from "./MaintenanceQueue";
import BagsSetupButton from "./BagsSetupButton";

const styles = makeStyles((theme) => ({
  warningIcon: {
    color: theme.palette.error.light,
    marginRight: theme.spacing(1),
    fontSize: 160,
  },
}));

const Heading = ({ sisId, rail, slingLoaded }) => {
  const { id, name, inputEnabled, outputEnabled } = rail;

  const { t } = useTranslation();

  const handleToggleInbound = () => railInputEnabled({ id, inputEnabled });
  const handleToggleOutbound = () => railOutputEnabled({ id, outputEnabled });

  return (
    <Box sx={{ display: "flex", flexDirection: "column", padding: 2 }}>
      <Box display="flex" justifyContent="space-between" marginBottom={2}>
        <Typography variant="h1">{name}</Typography>
        <BagsSetupButton />
      </Box>

      <Grid container justifyContent="space-between" alignItems="flex-end">
        <Grid item>
          <CircleIndicator
            label={t("common:in")}
            status={inputEnabled ? IndicatorStatusTypes.ON : IndicatorStatusTypes.OFF}
            onClick={handleToggleInbound}
          />
          <CircleIndicator
            label={t("common:out")}
            status={outputEnabled ? IndicatorStatusTypes.ON : IndicatorStatusTypes.OFF}
            onClick={handleToggleOutbound}
          />
        </Grid>

        <Grid item>
          {slingLoaded && <ConciseManSendButton sisId={sisId} size="xLarge" />}
          {slingLoaded ? (
            <ConciseDeleteSlingButton sisId={sisId} size="xLarge" />
          ) : (
            <ConciseAddSlingButton sisId={sisId} size="xLarge" />
          )}
          <CompMaintSettingsButton />
        </Grid>
      </Grid>
    </Box>
  );
};

const MaintenanceBagStatusIndicator = ({ missingBarcode, manualMaintenanceDue, scheduledMaintenanceDue }) => {
  const classes = styles();
  const { t } = useTranslation();

  if (!missingBarcode && !manualMaintenanceDue && !scheduledMaintenanceDue) return null;

  if (missingBarcode) {
    return <Typography variant="h1">{t("slingInputStation:no_barcode_assigned")}</Typography>;
  }

  return (
    <>
      <WarningIcon className={classes.warningIcon} />
      <Typography color="error" variant="h1">
        {manualMaintenanceDue
          ? t("slingInputStation:maintenance_requested")
          : t("slingInputStation:scheduled_maintenance")}
      </Typography>
    </>
  );
};

const generateMaintenanceReason = ({ reason, notes, manualMaintenanceDue }) => {
  let maintenanceReason = _.capitalize(_.replace(reason || "", "_", " "));
  if (manualMaintenanceDue && notes) {
    maintenanceReason += ` - ${notes}`;
  }
  return maintenanceReason;
};

const BatchDetails = ({ barcodeId, slingLoaded, maintData }) => {
  const { t } = useTranslation();

  const barcode = useBarcodeFromId(barcodeId);
  const { id, barcodeNumber, slingNumber } = barcode;
  const barcodeMaintData = maintData.find((x) => x.barcodeId === barcodeId) || { reason: "unknown" };
  const { reason, notes, previousCycles, previousMaintenanceAt } = barcodeMaintData;
  const manualMaintenanceDue = reason === "requested";

  const daysSinceLastMaint = moment.duration(moment().diff(moment(previousMaintenanceAt))).asDays();
  const maintDaysLimit = useDaysPerMaintenance();
  const overMaintTimeLimit = daysSinceLastMaint > maintDaysLimit;
  const cyclesPerMaint = useCyclesPerMaintenance();
  const overMaintCyclesLimit = previousCycles > cyclesPerMaint;
  const scheduledMaintenanceDue = overMaintTimeLimit || overMaintCyclesLimit;

  return (
    <Box display="flex" alignItems="center" flexDirection="column">
      <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
        {!slingLoaded ? (
          <BagStatusIndicator status="missing" />
        ) : (
          <MaintenanceBagStatusIndicator
            missingBarcode={!barcode.id}
            manualMaintenanceDue={manualMaintenanceDue}
            scheduledMaintenanceDue={scheduledMaintenanceDue}
          />
        )}
      </Box>

      {slingLoaded && id && (
        <Grid container flexDirection="column">
          <Grid item sx={{ mb: 2 }}>
            <Typography variant="h2">{t("slingInputStation:barcode_details")}</Typography>
          </Grid>

          <Box display="flex" width="inherit" justifyContent={id ? "inherit" : "center"} sx={{ mb: 3 }}>
            <Box sx={{ mr: 2 }}>
              <Typography variant="h1">{slingNumber || "--"}</Typography>

              <Typography variant="h4">{t("common:id_number", { number: id })}</Typography>

              <Typography variant="h4">
                {t("barcodeScanner:barcode_value", { value: barcodeNumber || "--" })}
              </Typography>
            </Box>

            <Divider orientation="vertical" />

            <Box sx={{ ml: 2 }}>
              <Typography variant="h5">{t("slingInputStation:last_maintenance_date")}</Typography>
              <Typography variant="h6" color={overMaintTimeLimit ? "error" : "initial"}>
                {previousMaintenanceAt
                  ? moment(previousMaintenanceAt).format("LLLL")
                  : t("slingInputStation:no_date_available")}
              </Typography>

              <Typography variant="h5" sx={{ mt: 1 }}>
                {t("slingInputStation:cycles_since_last_maintenance")}
              </Typography>
              <Typography variant="h6" color={overMaintCyclesLimit ? "error" : "initial"}>
                {`${previousCycles}`}
              </Typography>
            </Box>
          </Box>

          <Typography variant="h4">{t("slingInputStation:maintenance_reason")}</Typography>
          <Typography variant="h5">{generateMaintenanceReason({ reason, notes, manualMaintenanceDue })}</Typography>
        </Grid>
      )}
    </Box>
  );
};

const useIntervaledMaintReasons = () => {
  const [maintData, setMaintData] = useState([]);
  useEffect(() => {
    fetchMaintenanceReasons({ successCB: (data) => setMaintData(data) });
    const timer = setInterval(() => fetchMaintenanceReasons({ successCB: (data) => setMaintData(data) }), 10000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return maintData;
};

const MaintenanceLiftSIS = ({ sisId }) => {
  const classes = styles();

  const sis = useSlingInputStation(sisId);
  const rail = useRail(sis.railId);

  const firstRVBatchId = useSelector((state) => getFirstRVBatchId(sis.railId, state));
  const firstBatch = useBatch(firstRVBatchId);
  useEffect(() => {
    if (firstBatch.barcodeId !== sis.barcode1Id) {
      assignBarcodeToSis({ sisId, barcodeId: firstBatch.barcodeId });
    }
  }, [firstBatch.barcodeId]);

  const railVisitsCount = useSelector((state) => getRailVisitsCountForRail(state, sis.railId));
  const slingLoaded = railVisitsCount >= 1;

  const maintData = useIntervaledMaintReasons();

  return (
    <Paper square={true} elevation={0} sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
      <Heading sisId={sisId} rail={rail} slingLoaded={slingLoaded} />
      <Divider sx={{ borderColor: "grey.300" }} />
      <Grid container justifyContent="center" sx={{ padding: 2 }}>
        <Grid item sx={{ pr: 6 }}>
          <MaintenanceQueue systemId={rail.systemId} railId={rail.id} maintData={maintData} />
        </Grid>

        <Grid item>
          <BatchDetails barcodeId={sis.barcode1Id} slingLoaded={slingLoaded} maintData={maintData} />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default MaintenanceLiftSIS;
