import { createSlice } from "@reduxjs/toolkit";
import { socket } from "../../utils/channel";
import { shallowEqual, useSelector } from "react-redux";
import _ from "lodash";

const railMessageEventSlice = createSlice({
  name: "railMessageEvents",
  initialState: { messages: [] },
  reducers: {
    dataUpdate: (state, action) => {
      const id = action.payload.id;
      // debugger
      if (state.messages.find((x) => x.id === id)) {
        if (action.payload.endTime) {
          state.messages = state.messages.filter((x) => x.id !== id);
        } else {
          const newState = state.messages.map((x) => (x.id === id ? { ...x, ...action.payload } : x));
          state.messages = newState;
        }
      } else {
        state.messages.push(action.payload);
      }
    },
    initMessages: (state, action) => {
      state.messages = action.payload;
    },
  },
});

export function railMessageEventSubscribe() {
  const railMessageEventChannel = socket.channel("rail_message_events");
  return (dispatch) => {
    railMessageEventChannel
      .join(30000)
      .receive("ok", (messages) => {})
      .receive("error", (reason) =>
        dispatch({
          type: "CHANNEL_JOIN_ERROR",
          channel: "rail_message_events",
        })
      );

    railMessageEventChannel.on("init", (msg) => {
      dispatch(railMessageEventSlice.actions.initMessages(msg.data));
    });

    railMessageEventChannel.on("update", (msg) => {
      dispatch(railMessageEventSlice.actions.dataUpdate(msg));
    });
  };
}

// Selectors
export const useTestAreas = () => useSelector((state) => state.slx, _.isEqual);

export const useRailMessages = (railId) =>
  useSelector((state) => {
    return {
      messages: state.railMessageEvents.messages.filter((message) => message.railId === railId),
    };
  }, _.isEqual);

export const useGroupedRailMessages = () =>
  useSelector((state) => {
    const grouped = {};

    state.railMessageEvents.messages.forEach((message) => {
      if (!grouped.hasOwnProperty(message.railId)) {
        grouped[message.railId] = [];
      }

      grouped[message.railId].push(message);
    });

    return {
      groupedMessages: grouped,
    };
  }, shallowEqual);

export const useMessagingRails = () =>
  useSelector((state) => {
    let railsWithMessages = [];

    state.railMessageEvents.messages.forEach((message) => {
      const existingIndex = railsWithMessages
        .map((x) => {
          return x.id;
        })
        .indexOf(message.railId);
      if (existingIndex < 0) {
        const rail = state.rails[message.railId];
        if (rail) railsWithMessages.push(rail);
      }
    });

    return {
      railsWithMessages: railsWithMessages,
    };
  }, shallowEqual);

export default railMessageEventSlice.reducer;
