import React from "react";
import PropTypes from "prop-types";
import { Select, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ContextMode, ContextType } from "@etechinc/overview-sdk";
import { useCurrentRole } from "../../selectors/useRole";
import FormFieldLabel from "../SetupPages/FormFieldLabel";

const FormOverviewContextSelector = ({ currentValues, disabled, onFieldChange }) => {
  const { t } = useTranslation();
  const currentRole = useCurrentRole();

  const textSelections = {
    [ContextMode.AUTO]: t("overview:auto"),
    [ContextType.RAIL]: t("common:rail"),
    [ContextType.EQUIPMENT]: t("common:equipment"),
  };

  const handleOnFieldChange = (e) => {
    return onFieldChange("uiSettings", {
      ...currentValues["uiSettings"],
      overviewContext: e.target.value,
    });
  };

  return (
    <>
      <FormFieldLabel label={t("common:overview_context")} />

      <Select
        variant="outlined"
        value={currentValues["uiSettings"]?.overviewContext || ""}
        displayEmpty={true}
        disabled={disabled || !currentRole.canManageUsers}
        onChange={handleOnFieldChange}
      >
        <MenuItem value="">
          <em>{t("common:none")}</em>
        </MenuItem>
        {Object.keys(textSelections).map((entry) => (
          <MenuItem key={entry} value={entry} disabled={disabled || !currentRole.canManageUsers}>
            {textSelections[entry]}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

FormOverviewContextSelector.propTypes = {
  currentValues: PropTypes.object,
  disabled: PropTypes.bool,
  onFieldChange: PropTypes.func,
};

export default FormOverviewContextSelector;
