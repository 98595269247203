import {
  INITIALIZE_PRODUCTION_LISTS,
  UPDATE_PRODUCTION_LIST,
  DELETE_PRODUCTION_LIST,
  UPDATE_NESTED_STATES_PRODUCTION_LISTS,
} from "./types";
import { configureChannel } from "../utils";

export function productionListSubscribe() {
  return (dispatch) => {
    productionListsChannel.on("init", (msg) => {
      dispatch(initializeProductionLists(msg.data));
    });

    productionListsChannel.on("update", (msg) => {
      dispatch(updateProductionList(msg));
    });

    productionListsChannel.on("delete", (msg) => {
      dispatch(deleteProductionList(msg));
    });

    productionListsChannel.on("nested_state_update", (msg) => {
      dispatch(updateNestedStates(msg.data));
    });
  };
}

export const updateProductionList = (productionList) => {
  return {
    type: UPDATE_PRODUCTION_LIST,
    productionList: productionList,
  };
};

export const deleteProductionList = (productionList) => {
  return {
    type: DELETE_PRODUCTION_LIST,
    id: productionList.id,
  };
};

export const updateNestedStates = (nestedStatesData) => {
  return {
    type: UPDATE_NESTED_STATES_PRODUCTION_LISTS,
    data: nestedStatesData,
  };
};

export function initializeProductionLists(productionLists) {
  return {
    type: INITIALIZE_PRODUCTION_LISTS,
    productionLists: productionLists,
  };
}

export const productionListsChannel = configureChannel("production_lists");
