import { getRailById } from "../selectors/rails";
import dayjs from "dayjs";
/**
 * Sort compare function for objects with `name` property.
 */
export const nameComparer = (a, b) => {
  return a.name.localeCompare(b.name);
};

/**
 * Sort compare function for objects with `sortStationId` property.
 */
export const sortStationIdComparer = (a, b) => {
  const railA = getRailById(a.railId);
  const railB = getRailById(b.railId);
  return parseInt(railA?.sortStationId) - parseInt(railB?.sortStationId);
};

/**
 * Sort compare function for objects with `label` property.
 */
export const labelComparer = (a, b) => {
  return a.label.localeCompare(b.label);
};

/**
 * Sort compare function for objects with numerical `number` property.
 */
export const numberComparer = (a, b) => {
  return a.number - b.number;
};

const alphanumericComparer = (valueA, valueB, sortDirection) => {
  if (checkIfIpAddress(valueA) && checkIfIpAddress(valueB)) {
    valueA = changeIpToSortableString(valueA);
    valueB = changeIpToSortableString(valueB);
  }

  if (parseFloat(valueA) && parseFloat(valueB)) {
    if (valueA === valueB) return 0;

    valueA = parseFloat(valueA);
    valueB = parseFloat(valueB);

    return sortDirection >= 1 ? valueA - valueB : valueB - valueA;
  }

  valueA = valueA.toLowerCase();
  valueB = valueB.toLowerCase();
  return sortDirection === 1
    ? valueA.localeCompare(valueB, undefined, { numeric: true, sensitivity: "base" })
    : valueB.localeCompare(valueA, undefined, { numeric: true, sensitivity: "base" });
};

const dateComparer = (valueA, valueB, sortDirection) => {
  const a = dayjs(valueA);
  const b = dayjs(valueB);
  if (a.isValid() && b.isValid()) {
    return sortDirection >= 1 ? a.diff(b) : b.diff(a);
  }
};

/**
 * Sort compare function for alphanumeric values.
 */
export const sortingComparer = (a, b, direction = "asc", isDate = false) => {
  const sortDirection = direction === "desc" ? -1 : 1;
  let valueA = a;
  let valueB = b;

  if (!valueA || !valueB) {
    if (sortDirection >= 1) {
      return !valueA ? -1 : 1;
    } else {
      return !valueA ? 1 : -1;
    }
  }

  return isDate ? dateComparer(valueA, valueB, sortDirection) : alphanumericComparer(valueA, valueB, sortDirection);
};

const checkIfIpAddress = (ipString) => {
  const regexExp =
    /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi;
  return regexExp.test(ipString);
};

const changeIpToSortableString = (ip) => {
  if (!ip) return null;
  return ip
    .split(".")
    .map((subString) => `00${subString}`.slice(-3))
    .join("");
};
