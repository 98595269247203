import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import CustomerIcon from "@mui/icons-material/Business";
import { useTranslation } from "react-i18next";
import { VariableSizeGrid } from "react-window";
import FlagCardSlim from "../../../../components/FlagSelectors/FlagCardSlim";

// TODO: Add TS once a newer @types/react-window is released. Latest version is 1.8.5 and react-window is 1.8.6. Might be able to risk it and just test it extensibly, but just leave for now

const GridSelection = ({ style, rowIndex, setRowHeight, children }) => {
  const selectionRef = useRef({});

  useEffect(() => {
    if (selectionRef.current) {
      setRowHeight(rowIndex, selectionRef.current.clientHeight);
    }
  }, [selectionRef]);

  return (
    <div
      ref={selectionRef}
      style={{
        ...style,
        height: "fit-content",
        paddingRight: 16,
        paddingBottom: 16,
      }}
    >
      {children}
    </div>
  );
};

const CustomerSelections = ({ allCustomers, disabled, selections, onSelect }) => {
  const { t } = useTranslation();
  const [dialogContentWidth, setDialogContentWidth] = useState(null);
  const [dialogContentHeight, setDialogContentHeight] = useState(null);
  const dialogContentRef = useCallback((node) => {
    if (node !== null) {
      setDialogContentWidth(node.getBoundingClientRect().width);
      setDialogContentHeight(node.getBoundingClientRect().height);
    }
  }, []);

  const ROW_WIDTH = 216;
  const ROW_HEIGHT = 116;
  const columnCount = Math.floor(dialogContentWidth / ROW_WIDTH) || 0;
  const rowCount = columnCount > 0 ? Math.ceil(allCustomers.length / columnCount) : 0;

  const gridRef = useRef();
  const elementHeights = useRef({});
  const setRowHeight = (index, height) => {
    gridRef.current.resetAfterRowIndex(0);
    const rowHeight = elementHeights.current[index] || 0;

    if (height > rowHeight) {
      elementHeights.current = { ...elementHeights.current, [index]: height };
    }
  };

  const getRowHeight = (index) => {
    return elementHeights.current[index] || ROW_HEIGHT;
  };

  return (
    <Box ref={dialogContentRef} width="100%" height="100%">
      {allCustomers?.length > 0 ? (
        dialogContentWidth &&
        dialogContentHeight && (
          <VariableSizeGrid
            ref={gridRef}
            columnCount={columnCount}
            columnWidth={() => ROW_WIDTH}
            height={dialogContentHeight}
            width={dialogContentWidth}
            rowCount={rowCount}
            rowHeight={getRowHeight}
            overscanRowCount={5}
          >
            {({ columnIndex, rowIndex, style }) => {
              const foundIndex = rowIndex * columnCount + columnIndex;
              const customer = allCustomers[foundIndex];
              if (!customer) return null;

              return (
                <GridSelection key={customer?.id} style={style} rowIndex={rowIndex} setRowHeight={setRowHeight}>
                  <FlagCardSlim
                    name={customer.name}
                    imageUrl={customer.imageUrl}
                    imageIcon={<CustomerIcon />}
                    color={customer.color}
                    number={customer.number}
                    border={true}
                    highlight={selections?.includes(customer?.id)}
                    disabled={disabled}
                    onClick={() => {
                      !disabled && onSelect(customer.id);
                    }}
                  />
                </GridSelection>
              );
            }}
          </VariableSizeGrid>
        )
      ) : (
        <Typography>{t("common:none")}</Typography>
      )}
    </Box>
  );
};

export default CustomerSelections;
