import React from "react";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { showMessage, updateList } from "../../redux/productionListEditor";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CheckIcon from "@mui/icons-material/CheckCircleOutlineSharp";
import { useCurrentRole } from "../../selectors/useRole";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 200,
    backgroundColor: theme.palette.success["dark"],
    "&:disabled": {
      backgroundColor: theme.palette.grey[200],
    },
    "&:hover": {
      backgroundColor: theme.palette.success["main"],
    },
  },
}));

const SaveButton = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enableSave } = useSelector((state) => state.productionListEdit);
  const { t } = useTranslation();
  const role = useCurrentRole();

  return (
    <Button
      className={classes.root}
      variant="contained"
      disabled={!role.canEditPicklists || !enableSave}
      startIcon={<CheckIcon />}
      disableElevation={true}
      onClick={() => {
        dispatch(showMessage(t("productionList:saving_production_list")));
        dispatch(updateList());
      }}
    >
      {t("common:save")}
    </Button>
  );
};

export default SaveButton;
