import React from "react";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { useRailPlcState } from "../../selectors/rails";
import { useCurrentRole } from "../../selectors/useRole";
import { sbRequestTransfer } from "../../api/StaticBagApi";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 250,
    padding: theme.spacing(2),
    fontSize: 50,
    wordBreak: "break-all",
  },
}));

const TransferButton = ({ staticBag }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { canControlInputLifts } = useCurrentRole();

  const { id, dischargerRailId, staticBagRailId } = staticBag;
  const dischargerRailState = useRailPlcState(dischargerRailId);
  const staticBagRailState = useRailPlcState(staticBagRailId);

  if (staticBag.autoLoad) return null;

  return (
    <Button
      className={classes.root}
      color="primary"
      disabled={
        !canControlInputLifts ||
        dischargerRailState !== "ready_to_transfer" ||
        staticBagRailState !== "ready_to_receive"
      }
      variant="contained"
      onClick={() => sbRequestTransfer(id)}
    >
      {t("common:transfer")}
    </Button>
  );
};

export default TransferButton;
