import React from "react";

// material-ui
import { makeStyles, useTheme } from "@mui/styles";
import { Grid, Typography, useMediaQuery } from "@mui/material";

import { useTranslation } from "react-i18next";

// assets
import MainCard from "../../ui-component/cards/MainCard";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import TrafficIcon from "@mui/icons-material/Traffic";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";

// style constant
const useStyles = makeStyles((theme) => ({
  flatCardBody: {
    "& >div": {
      padding: "0px !important",
    },
    "& svg": {
      width: "45px",
      height: "45px",
      borderRadius: "14px",
      padding: "10px",
    },
  },
  flatCardBlock: {
    padding: "20px",
    borderLeft: "1px solid ",
    borderBottom: "1px solid ",
    borderLeftColor: theme.palette.mode === "dark" ? theme.palette.dark.main : theme.palette.grey[200],
    borderBottomColor: theme.palette.mode === "dark" ? theme.palette.dark.main : theme.palette.grey[200],
  },
}));

const PVDurationStats = ({ production, failure, lineRestraint, waiting }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchDownXs = useMediaQuery(theme.breakpoints.down("xs"));
  const { t } = useTranslation();

  const hourify = (val) => {
    if (val) {
      const minutes = Math.floor((val % 3600) / 60);

      return `${Math.floor(val / 3600)}h ${minutes < 10 ? "0" : ""}${minutes}m`;
    } else {
      return "0h 00m";
    }
  };

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12} sm={12} lg={12}>
        <MainCard border={false} className={classes.flatCardBody}>
          <Grid container alignItems="center" spacing={0}>
            <Grid item xs={12} sm={6} className={classes.flatCardBlock}>
              <Grid container alignItems="center" spacing={1} justify={matchDownXs ? "space-between" : "center"}>
                <Grid item>
                  <ThumbUpAltIcon
                    sx={{ color: theme.palette.success.dark, backgroundColor: theme.palette.success.light }}
                  />
                </Grid>
                <Grid item sm zeroMinWidth>
                  <Typography variant="h5" align="center">
                    {hourify(production)}
                  </Typography>
                  <Typography variant="subtitle2" align="center">
                    {t("slx:production").toUpperCase()}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.flatCardBlock}>
              <Grid container alignItems="center" spacing={1} justify={matchDownXs ? "space-between" : "center"}>
                <Grid item>
                  <HighlightOffIcon sx={{ color: theme.palette.error.dark, backgroundColor: "#e3b4b9" }} />
                </Grid>
                <Grid item sm zeroMinWidth>
                  <Typography variant="h5" align="center">
                    {hourify(failure)}
                  </Typography>
                  <Typography variant="subtitle2" align="center">
                    {t("slx:failure").toUpperCase()}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container alignItems="center" spacing={0}>
            <Grid item xs={12} sm={6} className={classes.flatCardBlock}>
              <Grid container alignItems="center" spacing={1} justify={matchDownXs ? "space-between" : "center"}>
                <Grid item>
                  <TrafficIcon
                    sx={{ color: theme.palette.warning.dark, backgroundColor: theme.palette.warning.light }}
                  />
                </Grid>
                <Grid item sm zeroMinWidth>
                  <Typography variant="h5" align="center">
                    {hourify(lineRestraint)}
                  </Typography>
                  <Typography variant="subtitle2" align="center">
                    {t("slx:line_restraint").toUpperCase()}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.flatCardBlock}>
              <Grid container alignItems="center" spacing={1} justify={matchDownXs ? "space-between" : "center"}>
                <Grid item>
                  <HourglassBottomIcon
                    sx={{ color: theme.palette.primary.dark, backgroundColor: theme.palette.primary.light }}
                  />
                </Grid>
                <Grid item sm zeroMinWidth>
                  <Typography variant="h5" align="center">
                    {hourify(waiting)}
                  </Typography>
                  <Typography variant="subtitle2" align="center">
                    {t("slx:waiting").toUpperCase()}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MainCard>
      </Grid>
    </Grid>
  );
};

export default PVDurationStats;
