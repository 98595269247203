import _ from "lodash";

export function toCamelCase(o) {
  if (isObject(o)) {
    const n = {};

    Object.keys(o).forEach((k) => {
      n[_.camelCase(k)] = toCamelCase(o[k]);
    });

    return n;
  } else if (isArray(o)) {
    return o.map((i) => {
      return toCamelCase(i);
    });
  }

  return o;
}

export function toSnakeCase(o) {
  if (isObject(o)) {
    const n = {};

    Object.keys(o).forEach((k) => {
      // Rails specific attribute that tells it to destroy record
      if (k === "_destroy") {
        n[k] = toSnakeCase(o[k]);
      } else {
        n[_.snakeCase(k)] = toSnakeCase(o[k]);
      }
    });

    return n;
  } else if (isArray(o)) {
    return o.map((i) => {
      return toSnakeCase(i);
    });
  }

  return o;
}

const isArray = function (a) {
  return Array.isArray(a);
};
const isObject = function (o) {
  return o === Object(o) && !isArray(o) && typeof o !== "function";
};
