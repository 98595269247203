import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import BaseDialog from "../components/BaseDialog";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { getShowSeverTroubleshoot } from "../selectors/local";
import * as actions from "../actions";

const ServerTroubleshootDialog = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const showTroubleshoot = useSelector((state) => getShowSeverTroubleshoot(state));

  const handleClose = () => dispatch(actions.hideServerTroubleshoot());

  return (
    <BaseDialog
      key="server-troubleshoot-dialog"
      title={t("serverTroubleshootDialog:server_offline")}
      fullWidth={true}
      closeOnClick={false}
      open={showTroubleshoot}
      minHeight={250}
      maxWidth="md"
      handleClose={handleClose}
    >
      <Grid container direction="column">
        <Typography>{t("serverTroubleshootDialog:server_troubleshooting_info")}</Typography>
      </Grid>
    </BaseDialog>
  );
};

export default ServerTroubleshootDialog;
