import React, { useState } from "react";
import { manualSend } from "../../api/RailApi";
import ManSendConfirmDialog from "../../components/ManSendConfirmDialog";
import ManualSendButton from "./ManualSendButton";

interface Props {
  railId: number;
  disabled?: boolean;
};

const CompManualSendButton = ({ railId, disabled }: Props) => {
  const [showSendLoadConfirm, setShowSendLoadConfirm] = useState(false);

  return (
    <>
      <ManualSendButton railId={railId} disabled={disabled} onClick={() => setShowSendLoadConfirm(true)} />

      <ManSendConfirmDialog
        open={showSendLoadConfirm}
        onClose={() => setShowSendLoadConfirm(false)}
        onConfirm={() => {
          manualSend(railId);
          setShowSendLoadConfirm(false);
        }}
      />
    </>
  );
};

export default CompManualSendButton;
