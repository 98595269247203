import React from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";
import CustChangeSortStation from "../features/customerChange/components/CustChangeSortStation";
import CustChangeControls from "../features/customerChange/components/CustChangeControls";
import { useCustomer } from "../selectors/customers";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  headerArea: {
    padding: theme.spacing(3),
    marginBottom: "20px",
  },
}));

const CustomerChange = () => {
  const classes = useStyles();
  const params = useParams();
  const customerChange = useSelector((state) => state.custChangeStations[params.id], _.isEqual) || {};

  const currentCustomer = useCustomer(customerChange.currentCustomerId, _.isEqual);

  const sortStations = useSelector((state) => state.sortStations, _.isEqual) || [];
  if (!customerChange) return null;

  const mySorts = Object.values(sortStations).filter((sort) => customerChange.sortStationIds.includes(sort.id));

  return (
    <div className={classes.root}>
      <CustChangeControls id={customerChange.id} currentCustomer={currentCustomer} customerChange={customerChange} />
      <Grid container spacing={2}>
        {mySorts.length > 0 &&
          mySorts.map((sort) => {
            return (
              <Grid item key={sort.id} lg={3} md={4} sm={6} xs={12}>
                <CustChangeSortStation
                  key={sort.id}
                  sortStation={sort}
                  bankCurrentCustomerId={customerChange.currentCustomerId}
                />
              </Grid>
            );
          })}
      </Grid>
    </div>
  );
};

export default CustomerChange;
