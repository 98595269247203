import { eVueApi } from "../../../services/eVue";
import type { StorageGroup } from "../dto/StorageGroup";

export const storageGroupsApi = eVueApi.injectEndpoints({
  endpoints: (builder) => ({
    getStorageGroups: builder.query<StorageGroup[], void>({
      query: () => "/storage_groups",
      providesTags: (result) => result ?
        [
          ...result.map(({ id }) => ({ type: "Groups", id } as const)),
          { type: "Groups", id: "LIST" },
        ]
      :
        [{ type: "Groups", id: "LIST" }],
    }),
    getStorageGroup: builder.query<StorageGroup, number>({
      query: (id) => ({ url: `/storage_groups/${id}` }),
      providesTags: (result, error, id) => [{ type: "Groups", id }],
    }),
    addStorageGroup: builder.mutation<StorageGroup, Partial<StorageGroup>>({
      query(body) {
        return {
          url: `/storage_groups`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: [{ type: "Groups", id: "LIST" }],
    }),
    updateStorageGroup: builder.mutation<StorageGroup, StorageGroup>({
      query({ id, ...body }) {
        return {
          url: `/storage_groups/${id})`,
          method: "PUT",
          body: body,
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: "Groups", id }],
    }),
    deleteStorageGroup: builder.mutation<{ success: boolean; id: number }, number>({
      query(id) {
        return {
          url: `storage_groups/${id}`,
          method: "DELETE",
        }
      },
      invalidatesTags: (result, error, id) => [{ type: "Groups", id }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetStorageGroupsQuery,
  useGetStorageGroupQuery,
  useAddStorageGroupMutation,
  useUpdateStorageGroupMutation,
  useDeleteStorageGroupMutation,
} = storageGroupsApi;
