import { INITIALIZE_MACHINE_VISITS, UPDATE_MACHINE_VISITS } from "./types";
import { configureChannel } from "../utils";

export function machineVisitSubscribe() {
  return (dispatch) => {
    machineVisitsChannel.on("init", (data) => {
      dispatch(initializeMachineVisits(data.data));
    });

    machineVisitsChannel.on("machine_visits_update", (data) => {
      dispatch(updateMachineVisits(data));
    });
  };
}

export function updateMachineVisits(data) {
  return {
    type: UPDATE_MACHINE_VISITS,
    data: data,
  };
}

export function initializeMachineVisits(machineVisits) {
  return {
    type: INITIALIZE_MACHINE_VISITS,
    machineVisits: machineVisits,
  };
}

export const machineVisitsChannel = configureChannel("machine_visits");
