import store from "../store";
import { useSelector } from "react-redux";

const getUiSettings = (receivedState) => {
  const state = receivedState || store.getState();
  return state.uiSettings || {};
};

export const getAdminMode = (state) => {
  const uiSettings = getUiSettings(state);

  return uiSettings.adminMode;
};

export const getEvuePro = (receivedState) => {
  return getUiSettings(receivedState).eVuePro;
};

export const getCyclesPerMaint = (state) => getUiSettings(state).cyclesPerMaintenance;
export const useCyclesPerMaintenance = () => useSelector(getCyclesPerMaint);

export const getDaysPerMaintenance = (state) => getUiSettings(state).daysPerMaintenance;
export const useDaysPerMaintenance = () => useSelector(getDaysPerMaintenance);
export const getEvueLabor = () => store.getState().uiSettings?.eVueLabor;
export const useEvueLabor = () => useSelector((state) => state.uiSettings?.eVueLabor);

export const useWeightUnit = () => useSelector((state) => (state.uiSettings.metric ? "kgs" : "lbs"));
export const useMetric = () => useSelector((state) => state.uiSettings.metric);
export const useJobNumber = () => useSelector((state) => state.uiSettings.jobNumber);
export const getJobNumber = (state = store.getState()) => state.uiSettings?.jobNumber;

export const useVersion = () => useSelector((state) => state.uiSettings.version);

export const useMsgServicePhone = () => useSelector((state) => state.uiSettings.msgServicePhone);

export const useAppStartTime = () => useSelector((state) => state.uiSettings.appStartTime);

export const getLaborButtons = () => store.getState().uiSettings?.laborButtons || [];

export const useEvueServerRole = () => useSelector((state) => state.uiSettings.eVueServerRole);

export const useEvuePlcIp = () => useSelector((state) => state.uiSettings.eVuePlcIp);

export const useShowFormula = () => useSelector((state) => state.uiSettings.showFormula);

export const useShowExternal = () => useSelector((state) => state.uiSettings.showExternal);

export const useShowCategorySlingType = () => useSelector((state) => state.uiSettings.showCategorySlingType);

export const useShowBarcodeSlingType = () => useSelector((state) => state.uiSettings.showBarcodeSlingType);

export const useHasCakeBreakers = () => useSelector((state) => state.uiSettings.hasCakeBreakers);

export const useSendToDryer = () => useSelector((state) => state.uiSettings.showSendToDryer);

export const useShowBufflectorFullOpen = () =>
  useSelector((state) => {
    return state.uiSettings.showBufflectorFullOpen;
  });

export const useDefaultLanguage = () => useSelector((state) => state.uiSettings.defaultLanguage);

export const useSLXHost = () => useSelector((state) => state.uiSettings.slxHost);

export const getSLXHost = (state) => state.uiSettings.slxHost;

export const getSLXEnabled = () => store.getState().uiSettings?.slxEnabled;
export const useSLXEnabled = () => useSelector((state) => state.uiSettings?.slxEnabled);

export const getSLXUiEnabled = () => store.getState().uiSettings?.slxUiEnabled;

export const useSystemParameterId = () => useSelector((state) => state.uiSettings.systemParameterId);

export const useBatchSerialEnabled = () => useSelector((state) => state.uiSettings.batchSerialEnabled);

export const useBatchSerialLabel = () => useSelector((state) => state.uiSettings.batchSerialLabel || "Serial");

export const usePlcWsUrl = () => useSelector((state) => state.uiSettings.wsUrl);

export const getShowCategoryGroups = (state = store.getState()) => state.uiSettings.showCategoryGroups;
export const useShowCategoryGroups = () => useSelector(getShowCategoryGroups);

export const getCustomUiMenuButtons = (state = store.getState()) => state.uiSettings.customUiMenuButtons;

export const useShowLoginCardValue = () => useSelector((state) => state.uiSettings.showLoginCardValue);

export const useIsTrolley = () => useSelector((state) => state.uiSettings.jobNumber === "2490G");

export const getMenuButtons = (state = store.getState()) => state.uiSettings.menuButtons;

export const getShowSoilFactor = (state = store.getState()) => state.uiSettings.showSoilFactor;

export const getShowPieceWeight = (state = store.getState()) => state.uiSettings.showPieceWeight;

export const getPlantStartTime = (state = store.getState()) => state.uiSettings.plantStartTime;
