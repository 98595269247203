import React, { Fragment } from "react";
import _ from "lodash";
import { Box, Paper, Typography } from "@mui/material";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { useTranslation } from "react-i18next";
import { sortStationIdComparer } from "../../../utils/sort";
import { useCurrentRole } from "../../../selectors/useRole";
import { getRailById } from "../../../selectors/rails";
import { getSortStationBankNumber } from "../../../selectors/sortStations";
import SortStationAssignment from "./SortStationAssignment";
import CategoryFlagSelector from "../../../components/FlagSelectors/CategoryFlagSelector";
import CategoryCardSlim from "../../../components/FlagSelectors/CategoryCardSlim";
import { setSlingAssignRail, setSlingAssignments, selectSelectedSortConfig } from "../redux/sortConfigurationsSlice";
import { SlingAssignment } from "../../../dto/eVue/SlingAssignment";

type Accumulator = {
  [key: number]: SlingAssignment[];
};

const ConfigurationDetails = () => {
  const dispatch = useAppDispatch();
  const selectedSortConfiguration = useAppSelector(selectSelectedSortConfig, _.isEqual);
  const { t } = useTranslation();
  const role = useCurrentRole();

  if (!selectedSortConfiguration) return null;

  const sortedSlingAssigns = selectedSortConfiguration.slingAssignments.slice().sort(sortStationIdComparer);
  const sortAssignsByBank = sortedSlingAssigns.reduce((acc, sa) => {
    const rail = getRailById(sa.railId) || {};
    const bankNumber = getSortStationBankNumber(rail.sortStationId) as number;
    if (!rail.sortStationId) return acc;

    if (bankNumber in acc) {
      acc[bankNumber].push(sa);
    } else {
      acc[bankNumber] = [sa]
    };
    return acc;
  }, {} as Accumulator);

  const bankKeys = Object.keys(sortAssignsByBank);

  return (
    <Paper
      sx={{
        height: "100%",
        display: "flex",
        flex: "0 1 auto",
        flexDirection: "column",
        overflowY: "auto",
      }}
    >
      <Box sx={{ display: "flex", flexWrap: "wrap", marginLeft: 1, padding: 3 }}>
        {bankKeys.map((key) => {
          return (
            <Fragment key={key}>
              {bankKeys.length > 1 && (
                <Typography key={key} variant="h2" gutterBottom sx={{ width: "100%" }}>
                  {t("sortAssignments:bank_number", { number: key })}
                </Typography>
              )}

              {sortAssignsByBank[parseInt(key)].map((sa) => {
                return (
                  <Box key={sa.railId} sx={{ mr: 4, mb: 3, height: "fit-content" }}>
                    <CategoryFlagSelector
                      selectedId={sa.sortClassificationId}
                      customSelectionElement={CategoryCardSlim}
                      onSelectionClick={(categoryId) => dispatch(setSlingAssignments(categoryId))}
                      disabled={!role.canEditSortConfigs}
                      customDisplayedButton={
                        <SortStationAssignment
                          railId={sa.railId}
                          sortClassId={sa.sortClassificationId}
                          onClick={() => dispatch(setSlingAssignRail(sa.railId))}
                        />
                      }
                    />
                  </Box>
                );
              })}
            </Fragment>
          );
        })}
      </Box>
    </Paper>
  );
};

export default ConfigurationDetails;
