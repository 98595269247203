import React from "react";
import clsx from "clsx";
import _ from "lodash";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { Avatar } from "@mui/material";

const defaultColor = "#167adf";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: 200,
    height: "fit-content",
    borderWidth: (props) => (props.border ? (props.highlight ? "3px" : "1px") : 0),
    borderStyle: "solid",
    borderColor: (props) => (props.highlight ? theme.palette.info.light : theme.palette.grey[400]),
    borderRadius: 5,
    padding: (props) => (props.border ? 5 : 0),
  },
  numberAndImage: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: 5,
    borderBottom: `2px solid ${theme.palette.info["main"]}`,
    marginBottom: 5,
  },
  avatar: {
    color: (props) => theme.palette.getContrastText(props.color || defaultColor),
    backgroundColor: (props) => props.color || defaultColor,
    width: (props) => {
      const largeCard = props.size === "large";
      const longLabel = props.labelLength > 3;
      const xLongLabel = props.labelLength > 5;
      const xxLongLabel = props.labelLength > 6;

      let width = largeCard ? 50 : 40;
      if (longLabel) {
        width = largeCard ? 55 : 40;
      }

      if (xLongLabel) {
        width = largeCard ? 70 : 50;
      }

      if (xxLongLabel) {
        width = largeCard ? 80 : 55;
      }

      return width;
    },
    height: (props) => (props.size === "large" ? 50 : 40),
    fontSize: (props) => {
      const longLabel = props.labelLength > 3;
      const largeCard = props.size === "large";
      return theme.typography.pxToRem(longLabel ? (largeCard ? 20 : 14) : largeCard ? 30 : 20);
    },
  },
  name: {
    display: "box",
    lineClamp: 2,
    boxOrient: "vertical",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  disabled: {
    opacity: 0.4,
  },
}));

const FlagCard = ({ label, name, imageUrl, color, highlight, disabled, size, border, handleClick }) => {
  const labelLength = label && label.toString().length;
  const classes = useStyles({ highlight, color, labelLength, size, border });

  const onClick = (event) => {
    if (!disabled && _.isFunction(handleClick)) handleClick(event);
  };

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.disabled]: disabled,
      })}
      onClick={onClick}
    >
      <div className={classes.numberAndImage}>
        <Avatar variant="rounded" className={classes.avatar}>
          {_.isNull(label) ? " " : label}
        </Avatar>
        {imageUrl && <Avatar variant="rounded" src={imageUrl} className={classes.avatar} />}
      </div>

      <Typography variant={size === "large" ? "h4" : "body1"} color="inherit" className={classes.name}>
        {name}
      </Typography>
    </div>
  );
};

FlagCard.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  handleClick: PropTypes.func,
  highlight: PropTypes.bool,
  imageUrl: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  size: PropTypes.oneOf(["large", "medium"]),
  border: PropTypes.bool,
};

FlagCard.defaultProps = {
  color: defaultColor,
  disabled: false,
  label: null,
  size: "medium",
  border: true,
};

export default FlagCard;
