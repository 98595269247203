import React, { useState } from "react";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/CloseSharp";
import AddIcon from "@mui/icons-material/AddCircle";
import { sortingComparer } from "../../utils/sort";
import SaveButton from "../SaveButton";
import FlagCard from "../FlagCard";
import Keypad from "../SetupPages/Keypad";

const useKeypadStyles = makeStyles({
  rootInput: {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none",
    },
  },
});

const FormKeypad = ({ label, value, maxValue, onChange }) => {
  const classes = useKeypadStyles();

  const handleNumberClick = (number) => {
    const updatedValue = value * 10 + number;
    updatedValue <= maxValue && onChange(updatedValue);
  };

  const handleClearField = () => onChange("");

  const handleDeleteSingleChar = () => {
    const newValue = value < 10 ? "" : Math.floor(value / 10);
    onChange(newValue);
  };

  const isTouchDevice = () =>
    "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

  const handleChange = (event) => {
    const updatedValue = event.target.value;
    const valueTooHigh = updatedValue.length > 0 && parseInt(updatedValue) > maxValue;
    updatedValue !== value && !valueTooHigh && onChange(updatedValue);
  };

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item sx={{ mb: 2 }}>
        <TextField
          sx={{ width: "100%" }}
          type="number"
          label={label}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            readOnly: isTouchDevice(),
            classes: { root: classes.rootInput },
          }}
          value={value}
          onChange={handleChange}
          onFocus={(event) => event.target.select()}
        />
      </Grid>

      <Keypad onClickNumber={handleNumberClick} onClear={handleClearField} onDelete={handleDeleteSingleChar} />
    </Grid>
  );
};

const PickSizeEditor = ({ open, pickSize, pickSizeRailsList, existingPickSizes, onClose, onUpdate, onAdd }) => {
  const { t } = useTranslation();
  const sortedRails = pickSizeRailsList.slice().sort((a, b) => sortingComparer(a?.name, b?.name));
  const [editablePS, setEditablePS] = useState(pickSize || {});

  const reservedRails = existingPickSizes.reduce((acc, ps) => {
    if (ps.railId !== pickSize?.railId) {
      acc.push(ps.railId);
    }
    return acc;
  }, []);

  const handleSave = () => {
    pickSize ? onUpdate(editablePS) : onAdd({ ...editablePS, id: uuidv4(), tempId: true });
    onClose();
  };

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth={true}
      PaperProps={{ sx: { maxHeight: "calc(100% - 180px)" } }}
      onBackdropClick={onClose}
    >
      <Box display="flex" flexDirection="column" py={2} px={3}>
        <Typography variant="h3">
          {pickSize ? t("categoriesSetup:edit_pick_size") : t("categoriesSetup:add_pick_size")}
        </Typography>
        <Typography>{t("categoriesSetup:pick_sizes_label")}</Typography>
      </Box>

      <DialogContent dividers={true}>
        <Grid container>
          <Grid item xs={8} container spacing={1} sx={{ height: 500, overflow: "auto" }}>
            {sortedRails &&
              sortedRails.map((rail) => (
                <Grid key={rail.id} item>
                  <FlagCard
                    key={rail.id}
                    name={rail.name}
                    label={_.isNull(rail.label) ? " " : rail.label}
                    color={rail.color}
                    disabled={reservedRails.includes(rail.id)}
                    handleClick={() => setEditablePS({ ...editablePS, railId: rail.id, name: rail.name })}
                    highlight={editablePS && editablePS.railId === rail.id}
                  />
                </Grid>
              ))}
          </Grid>
          <Grid item xs={4} sx={{ pl: 2 }}>
            <FormKeypad
              label={t("categoriesSetup:pick_sizes_instructions")}
              value={editablePS.size || ""}
              maxValue={50}
              onChange={(size) => setEditablePS({ ...editablePS, size })}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        {pickSize ? (
          <SaveButton disableElevation={true} onClick={handleSave}>
            {t("common:update")}
          </SaveButton>
        ) : (
          <Button
            disabled={!editablePS.railId || !editablePS.size}
            variant="outlined"
            disableElevation={true}
            onClick={handleSave}
          >
            {t("common:add")}
          </Button>
        )}

        <Button variant="outlined" onClick={onClose}>
          <CloseIcon />
          {t("common:close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const PickSizesSelector = ({ pickSizes, pickSizeRailsList, disabled, onRemove, onUpdate, onAdd }) => {
  const [openEditor, setOpenEditor] = useState(false);
  const [selectedPS, setSelectedPS] = useState(null);

  return (
    <Grid container wrap="wrap" spacing={1}>
      {pickSizes?.length > 0 &&
        pickSizes.map((pickSize) => (
          <Grid item key={`chip-${pickSize.railId}-${pickSize?.size}`}>
            <Chip
              avatar={<Avatar>{pickSize.size}</Avatar>}
              label={pickSize.name}
              variant="outlined"
              color="primary"
              disabled={disabled}
              onDelete={() => onRemove(pickSize.id)}
              onClick={() => {
                if (!disabled) {
                  setSelectedPS(pickSize);
                  setOpenEditor(true);
                }
              }}
            />
          </Grid>
        ))}

      <Grid item>
        <IconButton color="primary" disabled={disabled} sx={{ padding: 0 }} onClick={() => setOpenEditor(true)}>
          <AddIcon sx={{ fontSize: 35 }} />
        </IconButton>
      </Grid>

      {openEditor && (
        <PickSizeEditor
          key={selectedPS?.id}
          open={openEditor}
          pickSize={selectedPS}
          existingPickSizes={pickSizes}
          pickSizeRailsList={pickSizeRailsList}
          onUpdate={onUpdate}
          onAdd={onAdd}
          onClose={() => {
            setOpenEditor(false);
            setSelectedPS(null);
          }}
        />
      )}
    </Grid>
  );
};

export default PickSizesSelector;
