import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid, Typography } from "@mui/material";
import CustomerIcon from "@mui/icons-material/Business";
import { FixedSizeGrid } from "react-window";
import PropTypes from "prop-types";

import { useCustomer, useCustomers } from "../../selectors/customers";
import FlagCardSlim from "../FlagSelectors/FlagCardSlim";
import FlagCard from "../FlagCard";
import EmptyCard from "../EmptyCard";
import SelectionDialogWithFilter from "./SelectionDialogWithFilter";
import { filterAndSortSelections } from "./utilities";
import EmptyCardSlim from "./EmptyCardSlim";

const CustomerFlagSelector = ({ selectedId, size, slimButton, disabled, handleSelect }) => {
  const columnWidth = 200;
  const rowHeight = 116;
  const columnGap = 8;

  const selected = useCustomer(selectedId);
  const customers = useCustomers() || [];
  const { t } = useTranslation();

  const [dialogContentWidth, setDialogContentWidth] = useState(null);
  const [dialogContentHeight, setDialogContentHeight] = useState(null);
  const dialogContentRef = useCallback((node) => {
    if (node !== null) {
      setDialogContentWidth(node.getBoundingClientRect().width);
      setDialogContentHeight(node.getBoundingClientRect().height);
    }
  }, []);

  const [dialogOpen, setDialogOpen] = useState(false);

  const [order, setOrder] = useState("asc");
  const [sortBy, setSortBy] = useState("number");
  const [searchTerm, setSearchTerm] = useState(null);

  const handleChangeSortBy = (selected) => {
    if (selected === sortBy) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      setOrder("asc");
      setSortBy(selected);
    }
  };

  const sortedAndFilteredSelections = filterAndSortSelections({
    selections: customers.slice(),
    searchTerm,
    sortBy,
    order,
  });

  const columnCount = Math.floor(dialogContentWidth / (columnWidth + columnGap));
  const rowCount = Math.ceil(sortedAndFilteredSelections.length / columnCount);
  return (
    <>
      {selected?.id ? (
        slimButton ? (
          <FlagCardSlim
            name={selected.name}
            imageUrl={selected.imageUrl}
            imageIcon={<CustomerIcon />}
            color={selected.color}
            number={selected.number}
            border={true}
            disabled={disabled}
            onClick={() => {
              if (!disabled) setDialogOpen(true);
            }}
          />
        ) : (
          <FlagCard
            name={selected.name}
            label={selected.number}
            imageUrl={selected.imageUrl}
            color={selected.color}
            size={size}
            disabled={disabled}
            handleClick={() => {
              if (!disabled) setDialogOpen(true);
            }}
          />
        )
      ) : slimButton ? (
        <EmptyCardSlim
          message={t("common:select_customer")}
          size={size}
          disabled={disabled}
          onClick={() => setDialogOpen(true)}
        />
      ) : (
        <EmptyCard
          message={t("common:select_customer")}
          size={size}
          onClick={() => {
            if (!disabled) setDialogOpen(true);
          }}
          disabled={disabled}
        />
      )}

      {dialogOpen && (
        <SelectionDialogWithFilter
          open={dialogOpen}
          title={t("common:select_customer")}
          sortBy={sortBy}
          order={order}
          onChangeSortBy={handleChangeSortBy}
          onChangeSearchTerm={(searchTerm) => setSearchTerm(searchTerm)}
          handleClose={() => setDialogOpen(false)}
        >
          {sortedAndFilteredSelections.length < 1 ? (
            <Typography variant="overline" color="primary">
              {t("visitBar:no_customers_available")}
            </Typography>
          ) : (
            <Box ref={dialogContentRef} width="100%" height="100%">
              {dialogContentWidth && dialogContentHeight && (
                <FixedSizeGrid
                  columnCount={columnCount}
                  columnWidth={columnWidth + columnGap}
                  height={dialogContentHeight}
                  width={dialogContentWidth}
                  rowCount={rowCount}
                  rowHeight={rowHeight}
                  overscanRowCount={5}
                >
                  {({ columnIndex, rowIndex, style }) => {
                    const foundIndex = rowIndex * columnCount + columnIndex;
                    const selection = sortedAndFilteredSelections[foundIndex];
                    if (!selection) return null;

                    return (
                      <Grid
                        key={selection.id}
                        item
                        style={{
                          ...style,
                          paddingBottom: 16,
                        }}
                      >
                        <FlagCard
                          name={selection.name}
                          label={selection.number}
                          imageUrl={selection.imageUrl}
                          color={selection.color}
                          handleClick={() => {
                            setDialogOpen(false);
                            handleSelect(selection.id);
                          }}
                          highlight={selection.id === selected.id}
                        />
                      </Grid>
                    );
                  }}
                </FixedSizeGrid>
              )}
            </Box>
          )}
        </SelectionDialogWithFilter>
      )}
    </>
  );
};

CustomerFlagSelector.propTypes = {
  selectedId: PropTypes.number,
  size: PropTypes.oneOf(["large", "medium"]),
  disabled: PropTypes.bool,
  slimButton: PropTypes.bool,
  handleSelect: PropTypes.func,
};

CustomerFlagSelector.defaultProps = {
  disabled: false,
  size: "medium",
};

export default CustomerFlagSelector;
