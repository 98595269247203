import { createSlice } from "@reduxjs/toolkit";
import { socket } from "../../utils/channel";
import { shallowEqual, useSelector } from "react-redux";
import _ from "lodash";

const perspectiveSlice = createSlice({
  name: "perspectives",
  initialState: {},
  reducers: {
    laborUpdate: (state, action) => {
      return { ...action.payload };
    },
  },
});

export function perspectiveSubscribe() {
  const channel = socket.channel("perspectives");
  return (dispatch) => {
    channel
      .join(30000)
      .receive("ok", (messages) => {})
      .receive("error", (reason) =>
        dispatch({
          type: "CHANNEL_JOIN_ERROR",
          channel: "perspectives",
        })
      );

    channel.on("labor_update", (msg) => {
      dispatch(perspectiveSlice.actions.laborUpdate(msg));
    });
  };
}

// Selectors
// export const useFoo = (id) => useSelector((state) => state.perspectives[id], _.isEqual);

export default perspectiveSlice.reducer;
