import React from "react";
import { Button, Paper, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import CheckIcon from "@mui/icons-material/CheckCircleOutlineSharp";
import { toggleMapping } from "../redux/sortConfigurationsSlice";

const TitleBarMapping = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClick = () => dispatch(toggleMapping());

  return (
    <Paper
      square={true}
      elevation={0}
      sx={{
        display: "flex",
        flex: "0 0 auto",
        flexFlow: "rew nowrap",
        justifyContent: "space-between",
        padding: 3,
      }}
    >
      <Typography variant="h1" gutterBottom>
        {t("sortAssignments:sort_assignments")}
      </Typography>

      <Button
        sx={{
          maxWidth: 200,
          backgroundColor: "success.dark",

          "&:disabled": {
            backgroundColor: "grey.200",
          },
          "&:hover": {
            backgroundColor: "success.main",
          },
        }}
        disableElevation={true}
        variant="contained"
        startIcon={<CheckIcon />}
        onClick={handleClick}
      >
        {t("common:done")}
      </Button>
    </Paper>
  );
};

export default TitleBarMapping;
