import { useAlarms } from "../selectors/useAlarms";
import { useCustChangeIdsForNotifications, useRailIdsForNotifications } from "../selectors/notifications";
import { useDelays } from "../features/delays/DelaySlice";
import { useFaultingProducerViews } from "../features/SLX/SLXSlice";
import { useGroupedRailMessages, useMessagingRails } from "../features/railMessageEvents/railMessageEventsSlice";
import React from "react";

export const useAlarmCount = () => {
  const alarms = useAlarms();
  const custChangeIdsForNotifications = useCustChangeIdsForNotifications();
  const delays = useDelays();
  const faultingProducerViews = useFaultingProducerViews();
  const railIdsForNotifications = useRailIdsForNotifications();
  const { railsWithMessages } = useMessagingRails();
  const { groupedMessages } = useGroupedRailMessages();

  let count = 0;

  if (alarms.serverOffline) ++count;
  if (alarms.systemHalted) ++count;
  if (alarms.plcOffline) ++count;
  if (alarms.ioForced) ++count;
  alarms.rails.forEach(() => ++count);
  delays.forEach(() => ++count);
  custChangeIdsForNotifications.forEach(() => ++count);
  railIdsForNotifications.forEach(() => ++count);
  faultingProducerViews.forEach(() => ++count);

  railsWithMessages.forEach((rail) => {
    if (groupedMessages.hasOwnProperty(rail.id)) {
      const messages = groupedMessages[rail.id];
      const mutedMessages = messages.filter((message) => message.muted).length;

      if (messages.length > 1 && messages.length > mutedMessages) {
        ++count;
      } else if (messages.length === 1 && mutedMessages === 0) {
        ++count;
      }
    }
  });

  return count;
};
