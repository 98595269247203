import React, { useState, useEffect } from "react";
import { Backdrop, Box, CardContent, CircularProgress, Divider, Grid, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { SETUP_LABOR_TASKS } from "../../constants/Paths";
import { fetchLaborTask, updateLaborTask } from "../../api/LaborTaskApi";
import { useCurrentRole } from "../../selectors/useRole";
import FormEditor from "../SetupPages/FormEditor";
import ImageUploadButton from "../SetupPages/ImageUploadButton";
import FormTextField from "../SetupPages/FormTextField";
import SaveButton from "../SaveButton";
import LaborTasksListButton from "./LaborTasksListButton";
import LaborTaskIcon from "@mui/icons-material/GroupWork";

const SetupForm = ({ laborTask = {}, onUpdateLaborTask }) => {
  const currentRole = useCurrentRole();
  const { t } = useTranslation();

  return (
    <FormEditor
      originalValues={{
        id: laborTask.id,
        imageUrl: laborTask.imageUrl || "",
        name: laborTask.name || "",
      }}
      onSubmit={({ initialValues, currentValues, actions }) => {
        const valuesForSubmission = Object.keys(currentValues).reduce((acc, key) => {
          if (key === "imageUrl" && initialValues["imageUrl"] === currentValues["imageUrl"]) {
            return acc;
          }
          return { ...acc, [key]: currentValues[key] === "" ? null : currentValues[key] };
        }, {});

        updateLaborTask({
          laborTaskId: currentValues.id,
          laborTask: { ...valuesForSubmission },
          successCB: (updatedLaborTask) => {
            actions.finishSubmit();
            onUpdateLaborTask(updatedLaborTask);
          },
          failureCB: (errors) => {
            actions.finishSubmit(true);
            actions.catchErrors(errors);
          },
        });
      }}
    >
      {({ currentValues, dirtyForm, errors, isSubmitting, setFieldValue, handleSubmit }) => {
        const formDisabled = isSubmitting || !currentRole.canConfigureMetrics;

        return (
          <>
            <CardContent sx={{ height: "100%", overflow: "auto" }}>
              <Backdrop open={isSubmitting}>
                <CircularProgress color="inherit" />
              </Backdrop>

              <Grid container>
                <Grid item sx={{ mr: 2 }}>
                  <ImageUploadButton
                    defaultIcon={<LaborTaskIcon sx={{ fontSize: 80 }} />}
                    imageUrl={currentValues["imageUrl"]}
                    disabled={formDisabled}
                    onUpload={(imageUrl) => setFieldValue("imageUrl", imageUrl)}
                  />
                </Grid>

                <Grid item sx={{ flexGrow: 1, maxWidth: 600 }}>
                  <FormTextField
                    fieldName="name"
                    label={t("common:name")}
                    currentValues={currentValues}
                    errors={errors}
                    disabled={formDisabled}
                    onFieldChange={setFieldValue}
                  />
                </Grid>
              </Grid>
            </CardContent>

            <Divider />

            <Box display="flex" justifyContent="flex-end" padding={3}>
              <SaveButton disabled={formDisabled || !dirtyForm} onClick={handleSubmit}>
                {t("common:submit")}
              </SaveButton>
            </Box>
          </>
        );
      }}
    </FormEditor>
  );
};

const LaborTaskEdit = () => {
  const { t } = useTranslation();
  const currentRole = useCurrentRole();
  const params = useParams();

  const [laborTask, setLaborTask] = useState();
  useEffect(() => {
    fetchLaborTask({
      id: params.id,
      successCB: (data) => setLaborTask(data),
    });
  }, [params.id]);

  const location = useLocation();
  const history = useHistory();
  const handleGoToList = () => {
    const newLocation = {
      pathname: SETUP_LABOR_TASKS,
      state: { listScrollPos: location.state?.listScrollPos, searchTerm: location.state?.searchTerm },
    };
    history.push(newLocation);
  };

  return (
    <Paper square={true} elevation={0} sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box padding={3} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h1">
          {t(`laborTasksSetup:${currentRole.canConfigureMetrics ? "edit_labor_task" : "view_labor_task"}`)}
        </Typography>
        <LaborTasksListButton onClick={handleGoToList} />
      </Box>

      <Divider />

      {laborTask ? (
        <SetupForm laborTask={laborTask} onUpdateLaborTask={handleGoToList} />
      ) : (
        <Backdrop open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Paper>
  );
};

export default LaborTaskEdit;
