import produce from "immer";
import * as actions from "../actions/types";
import { objFromArray } from "../utils";

const initialState = {};

export default function ioPointReducer(state = initialState, action) {
  switch (action.type) {
    case actions.INITIALIZE_IO_POINTS:
      return objFromArray(action.ioPoints);

    case actions.UPDATE_IO_POINTS:
      const merged = action.ioPoints.reduce((acc, io) => {
        acc[io.id] = { ...state[io.id], ...io };
        return acc;
      }, {});

      return { ...state, ...merged };

    case actions.UPDATE_IO_POINT:
      const { ioPoint } = action;
      const nextState = produce(state, (draftState) => {
        const newIoPoint = {
          ...draftState[ioPoint.id],
          ...ioPoint,
        };

        draftState[ioPoint.id] = newIoPoint;
      });

      return nextState;

    default:
      return state;
  }
}
