import * as actions from "../actions";
import store from "../store";
import { isBarcode } from "../features/slingInputStations/hooks/useTicketScanner";
import { isCustomerScannerActive } from "../features/customerChange/utils";
import { functionKeyRegex } from "../features/overview/hooks/useFunctionKeys";

let buffer = "";
let timer = null;
const TIME = 500;
const MIN_LENGTH = 3;

/**
 * Card reader may first attempt a mouse-click event.
 * Capture before bubbling and stop propagation.
 */
const clickHandler = (event) => {
  if (event.clientX === 0 && event.clientY === 0) {
    event.stopImmediatePropagation();
  }
};
/**
 * On KeyDown, start capture of input from the card reader,
 * unless currently typing in an input field
 */
const keyDownHandler = (event) => {
  const el = event.target.tagName.toLowerCase();
  if (el === "input" || el === "textarea") return;
  if (!timer) start();

  if (functionKeyRegex.test(event.key)) return null;
  switch (event.key) {
    case "Alt":
    case "ArrowDown":
    case "ArrowLeft":
    case "ArrowRight":
    case "ArrowUp":
    case "Backspace":
    case "CapsLock":
    case "Control":
    case "End":
    case "Enter":
    case "Home":
    case "Meta":
    case "PageDown":
    case "PageUp":
    case "Shift":
    case "Tab":
      // Disregard keys:
      break;

    default:
      buffer += event.key;
      break;
  }
};

/**
 * Start the capture of input, clearing the buffer and
 * starting a timer for the specified `TIME` length.
 */
const start = () => {
  buffer = "";
  timer = setTimeout(timerHandler, TIME);
};

/**
 * Clear the text buffer and timer.
 */
const reset = () => {
  buffer = "";
  timer = clearTimeout(timerHandler);
};

/**
 * Single place to add reasons for disabling this scanner
 */
const anyExternalDisabling = () => isBarcode(buffer) || isCustomerScannerActive();

/**
 * On timeout, login if the buffer has reached `MIN_LENGTH` and if the scan is not a barcode or customer scanner is active
 */
const timerHandler = () => {
  const noExternalDisabling = !isBarcode(buffer) && !anyExternalDisabling();
  if (buffer.trim().length > 0 && buffer.length >= MIN_LENGTH && noExternalDisabling) {
    store.dispatch(actions.loginCardReader(buffer));
  }

  reset();
};

/**
 * React hook to login via RFID card readers.
 */
export const useUserCardReader = () => {
  window.addEventListener("click", clickHandler, { capture: true });
  window.addEventListener("keydown", keyDownHandler, { capture: true });
};
