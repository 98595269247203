import React, { useState } from "react";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useTranslation } from "react-i18next";
import FormFieldLabel from "../SetupPages/FormFieldLabel";

const PasswordTextField = (props) => {
  const { currentValues, disabled, errors, isConfirmation, helperText, onFieldChange } = props;
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisible = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => event.preventDefault();

  const fieldName = isConfirmation ? "passwordConfirmation" : "password";
  return (
    <>
      <FormFieldLabel label={t(isConfirmation ? "usersSetup:password_confirmation" : "common:password")} />
      <TextField
        type={showPassword ? "text" : "password"}
        value={currentValues[fieldName] || ""}
        variant="outlined"
        disabled={disabled}
        error={Boolean(errors[fieldName])}
        helperText={errors[fieldName] || helperText}
        onChange={(e) => onFieldChange(fieldName, e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                disabled={disabled}
                onClick={handleTogglePasswordVisible}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};

export default PasswordTextField;
