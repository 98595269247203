import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { ReactComponent as Compass } from "../assets/icons/compass.svg";
import { useTranslation } from "react-i18next";
import { useCurrentRole } from "../selectors/useRole";

const useStyles = makeStyles((theme) => ({
  icon: {
    width: "18px",
    height: "18px",
  },
}));

const CellLeft = styled(TableCell)`
  padding: 3px 0;
`;
const CellRight = styled(TableCell)`
  padding: 3px 0;
  text-align: right;
`;

const Content = ({ label, value }) => (
  <TableRow>
    <CellLeft>{label}</CellLeft>
    <CellRight>{value}</CellRight>
  </TableRow>
);

const LoadCellsCard = (props) => {
  const { handleCalibrate, loadCellId } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const loadCell = useSelector((state) => state.loadCells[loadCellId]);
  const rail = useSelector((state) => state.rails[loadCell.railId]);
  const { canCalibrate } = useCurrentRole();

  return (
    <Card>
      <CardHeader title={rail.name} />
      <Divider />

      <CardContent>
        <TableContainer>
          <Table>
            <TableBody>
              <Content label={t("common:value")} value={loadCell.currentValue} />
              <Content label={t("loadCell:tare")} value={loadCell.tare} />
              <Content label={t("loadCell:span")} value={loadCell.span} />
              <Content label={t("common:weight")} value={loadCell.weight} />
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>

      <Divider />
      <CardActions>
        <Button
          size="small"
          variant="contained"
          color="primary"
          startIcon={<Compass className={classes.icon} fill="#ffffff" />}
          onClick={() => handleCalibrate(loadCellId)}
          disabled={!canCalibrate}
        >
          {t("loadCell:calibrate_button")}
        </Button>
      </CardActions>
    </Card>
  );
};

LoadCellsCard.propTypes = {
  loadCellId: PropTypes.number.isRequired,
};

export default LoadCellsCard;
