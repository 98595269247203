import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableSortLabel,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ProdPointIcon from "@mui/icons-material/PlayForWork";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { withStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { getProductionPoints } from "../../api/ProductionPointApi";
import { useCurrentRole } from "../../selectors/useRole";
import { sortingComparer } from "../../utils/sort";
import { SETUP_PRODUCTION_POINTS } from "../../constants/Paths";
import SearchField from "../SetupPages/SearchField";

const StyledTableCell = withStyles((theme) => ({
  head: {
    padding: theme.spacing(2),
  },
  body: {
    fontSize: 14,
    padding: theme.spacing(2),

    "& .MuiBox-root": {
      display: "flex",
      alignItems: "center",
    },
    "& .MuiAvatar-root": {
      marginRight: theme.spacing(1),
    },
  },
}))(TableCell);

const StyledTableSortLabel = withStyles((theme) => ({
  root: {
    "&$active": {
      color: theme.palette.common.black,
    },
  },
  active: {},
  icon: {
    color: "inherit !important",
  },
}))(TableSortLabel);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.grey[100],
    },
  },
}))(TableRow);

const ProductionPointRow = ({ productionPoint = {}, onClick }) => {
  const { t } = useTranslation();
  const currentRole = useCurrentRole();
  const { name, imageUrl, configuredTargetRate, configuredPpoh, configuredUnit } = productionPoint;

  return (
    <StyledTableRow>
      <StyledTableCell>
        <Box>
          <Avatar src={imageUrl}>{!imageUrl && <ProdPointIcon />}</Avatar>
          <Typography variant="h5">{name}</Typography>
        </Box>
      </StyledTableCell>
      <StyledTableCell align="left">{t(`common:${configuredUnit || ""}`)}</StyledTableCell>
      <StyledTableCell align="right">{configuredTargetRate}</StyledTableCell>
      <StyledTableCell align="right">{configuredPpoh}</StyledTableCell>
      <StyledTableCell align="right">
        <IconButton onClick={onClick}>{currentRole.canConfigureMetrics ? <EditIcon /> : <VisibilityIcon />}</IconButton>
      </StyledTableCell>
    </StyledTableRow>
  );
};

const filterProductionPoints = ({ productionPoints, searchTerm }) => {
  return productionPoints.filter((productionPoint) => {
    if (!searchTerm) return true;
    const prodPointName = productionPoint.name?.toLowerCase();

    return _.includes(prodPointName, searchTerm?.toLowerCase());
  });
};

const sortProductionPoints = ({ productionPoints = [], orderBy, order }) => {
  if (productionPoints.length < 1) return [];
  return productionPoints.sort((a, b) => {
    let valueA = a[orderBy];
    let valueB = b[orderBy];
    return sortingComparer(valueA, valueB, order);
  });
};

const ProductionPointsList = () => {
  const [productionPoints, setProductionPoints] = useState();
  useEffect(() => {
    getProductionPoints({ successCB: (data) => setProductionPoints(data) });
  }, []);

  if (!productionPoints) return null;
  return <ProductionPointsListContent productionPoints={productionPoints} />;
};

const ProductionPointsListContent = ({ productionPoints }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [searchTerm, setSearchTerm] = useState(location.state?.searchTerm);

  const handleOrderBy = (selected) => {
    if (selected === orderBy) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      setOrder("asc");
      setOrderBy(selected);
    }
  };

  const filteredProdPoints = filterProductionPoints({ productionPoints, searchTerm });
  const sortedProdPoints = sortProductionPoints({
    productionPoints: filteredProdPoints,
    orderBy,
    order,
  });

  const list = useRef();
  useEffect(() => {
    const scrollPos = location.state?.listScrollPos;
    if (scrollPos) {
      list.current.scrollTop = scrollPos;
    }
  }, [location.state?.listScrollPos]);

  const history = useHistory();
  const handleOnClick = (id) => {
    const newLocation = {
      pathname: `${SETUP_PRODUCTION_POINTS}/${id}`,
      state: { listScrollPos: list.current.scrollTop, searchTerm },
    };
    history.push(newLocation);
  };

  return (
    <Paper square={true} elevation={0} sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box padding={3} display="flex" justifyContent="space-between" alignItems="flex-end">
        <Typography variant="h1">{t("productionPointsSetup:production_points_setup")}</Typography>

        <SearchField
          sx={{ width: 350 }}
          initialValue={location.state?.searchTerm}
          placeholder={t("common:search_by_name")}
          onSubmitSearchTerm={(searchTerm) => setSearchTerm(searchTerm)}
        />
      </Box>

      <Divider />

      <TableContainer ref={list}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell sortDirection={order}>
                <StyledTableSortLabel
                  align="left"
                  active={orderBy === "name"}
                  direction={order}
                  onClick={() => handleOrderBy("name")}
                >
                  {t("common:name")}
                </StyledTableSortLabel>
              </StyledTableCell>

              <StyledTableCell align="left" sortDirection={order}>
                <StyledTableSortLabel
                  active={orderBy === "configuredUnit"}
                  direction={order}
                  onClick={() => handleOrderBy("configuredUnit")}
                >
                  {t("productionPointsSetup:configured_unit")}
                </StyledTableSortLabel>
              </StyledTableCell>

              <StyledTableCell align="right" sortDirection={order}>
                <StyledTableSortLabel
                  active={orderBy === "configuredTargetRate"}
                  direction={order}
                  onClick={() => handleOrderBy("configuredTargetRate")}
                >
                  {t("productionPointsSetup:configured_target_rate")}
                </StyledTableSortLabel>
              </StyledTableCell>

              <StyledTableCell align="right" sortDirection={order}>
                <StyledTableSortLabel
                  active={orderBy === "configuredPPOH"}
                  direction={order}
                  onClick={() => handleOrderBy("configuredPPOH")}
                >
                  {t("productionPointsSetup:configured_ppoh")}
                </StyledTableSortLabel>
              </StyledTableCell>

              <StyledTableCell align="right"></StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {sortedProdPoints.map((productionPoint) => (
              <ProductionPointRow
                key={productionPoint.id}
                productionPoint={productionPoint}
                onClick={() => handleOnClick(productionPoint.id)}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ProductionPointsList;
