import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// TODO: Use SLX Host from uiSettings.slxHost
export const vecturaApi = createApi({
  reducerPath: "vecturaApi",
  baseQuery: fetchBaseQuery({ baseUrl: "http://slx-coop.eastus.cloudapp.azure.com/api/states/v1/production/" }),
  endpoints: (builder) => ({
    getVecturaStacks: builder.query({
      query: () => `stacks?embed=STACK_METADATA`,
    }),
  }),
});

export const { useGetVecturaStacksQuery } = vecturaApi;
