import React from "react";
import { useTranslation } from "react-i18next";
import { useAnyPurgeableSortStations } from "../../selectors/sortStations";
import { useCategories, useSuperCategoryIds } from "../../selectors/categories";
import CategoryFlagSelector from "../FlagSelectors/CategoryFlagSelector";
import FormFieldLabel from "../SetupPages/FormFieldLabel";

const FormSuperCategoryIdSelector = ({ currentValues, disabled, onFieldChange }) => {
  const anyPurgeableSortStations = useAnyPurgeableSortStations();
  const allSuperCategoryIds = useSuperCategoryIds();
  const notASuperCategory = !allSuperCategoryIds.includes(currentValues.id);

  if (!anyPurgeableSortStations || !notASuperCategory || currentValues.purgeCategory) return null;
  return <SelectorContent currentValues={currentValues} disabled={disabled} onFieldChange={onFieldChange} />;
};

const SelectorContent = ({ currentValues, disabled, onFieldChange }) => {
  const { t } = useTranslation();
  const categorySelections = useCategories()
    .slice()
    .filter((category) => category.id !== currentValues.id && !category.purgeCategory);
  return (
    <>
      <FormFieldLabel label={t("categoriesSetup:super_category")} />
      <CategoryFlagSelector
        selectedId={currentValues.superCategoryId}
        providedCategoriesList={categorySelections}
        onSelectionClick={(id) => onFieldChange("superCategoryId", id)}
        disabled={disabled}
      />
    </>
  );
};

export default FormSuperCategoryIdSelector;
