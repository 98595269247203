import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { fetchServoLifts, useServoLiftFromRailId, useCallStatus } from "../features/servoLifts/servoLiftsSlice";

const useStyles = makeStyles({
  root: {
    border: 0,
    display: "block",
    height: "100%",
    width: "100%",
  },
});

const ServoLiftWebpage = () => {
  const classes = useStyles();
  const params = useParams() || {};

  const status = useCallStatus();
  const dispatch = useDispatch();
  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchServoLifts());
    }
  }, []);

  const servoLift = useServoLiftFromRailId(parseInt(params.railId)) || {};

  if (status !== "succeeded" || !servoLift.ipAddress) return null;
  return (
    <iframe
      className={classes.root}
      title="servo-lift-webpage"
      src={`https://${servoLift.ipAddress}/index.html?visuid=VCHoist_2`}
    ></iframe>
  );
};

export default ServoLiftWebpage;
