import React, { useEffect } from "react";
import _ from "lodash";
import { Box, Divider } from "@mui/material";
import { Prompt } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../hooks/useAppDispatch";
import { useAppSelector } from "../hooks/useAppSelector";
import ConfigurationDetails from "../features/sortAssignments/components/ConfigurationDetails";
import CustomerMapping from "../features/sortAssignments/components/CustomerMapping";
import Loader from "../components/Loader";
import TitleBar from "../features/sortAssignments/components/TitleBar";
import TitleBarMapping from "../features/sortAssignments/components/TitleBarMapping";
import { useGetSortConfigurationsQuery } from "../features/sortAssignments/services/sortConfigurations";
import { selectMappingOpen } from "../features/sortAssignments/redux/sortConfigurationsSlice";
import {
  clearEditor,
  selectLoaderOpen,
  selectLoaderMessage,
  selectSelectedSortConfig,
  setSelectedSystem,
} from "../features/sortAssignments/redux/sortConfigurationsSlice";
import { showAlertError } from "../actions";
import { getSystemsWithSortStations } from "../selectors/systems";
import store from "../store";
import type { System } from "../dto/eVue/System";

const SortAssignments = () => {
  const selectedSortConfiguration = useAppSelector(selectSelectedSortConfig, _.isEqual);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const mappingOpen = useAppSelector(selectMappingOpen);
  const loaderOpen = useAppSelector(selectLoaderOpen);
  const loaderMessage = useAppSelector(selectLoaderMessage);

  const { isError, error } = useGetSortConfigurationsQuery();
  useEffect(() => {
    const sortSystems: System[] = getSystemsWithSortStations(store.getState());
    dispatch(setSelectedSystem(sortSystems[0]))

    if (error) {
      const errorResponse = error as { data: { error?: string }};
      dispatch(showAlertError(t("common:fetch_error", { error: errorResponse.data.error })));
    }

    return () => {
      dispatch(clearEditor());
    };
  }, [isError]);

  return (
    <Box display="flex" flexDirection="column" height="100%">
      {mappingOpen ? (
        <>
          <TitleBarMapping />
          <Divider sx={(theme) => ({ zIndex: 1, borderColor: theme.palette.grey[400] })} />
          <CustomerMapping />
        </>
      ) : (
        <>
          <TitleBar />
          <Divider sx={(theme) => ({ zIndex: 1, borderColor: theme.palette.grey[400] })} />
          <ConfigurationDetails />
        </>
      )}

      <Loader open={loaderOpen} message={loaderMessage} transitionDuration={1000} />
      <Prompt
        when={Boolean(selectedSortConfiguration?.dirty)}
        message={t("sortAssignments:unsaved_changes")}
      />
    </Box>
  );
};

export default SortAssignments;
