import React from "react";
import { Backdrop, Box, CardContent, CircularProgress, Divider, Grid, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { addUser, updateUser, deleteUser } from "../../api/UserApi";
import { useCurrentRole } from "../../selectors/useRole";
import {
  useShowExternal,
  getEvueLabor,
  useDefaultLanguage,
  useShowLoginCardValue,
  useJobNumber,
} from "../../selectors/uiSettings";
import { useUserUiSettings } from "../../selectors/useUser";
import FormEditor from "../SetupPages/FormEditor";
import ImageUploadButton from "../SetupPages/ImageUploadButton";
import FormTextField from "../SetupPages/FormTextField";
import FormNumberField from "../SetupPages/FormNumberField";
import FormCheckbox from "../SetupPages/FormCheckbox";
import SaveButton from "../SaveButton";
import DeleteButton from "../DeleteButton";
import FormLanguageSelector from "./FormLanguageSelector";
import FormRoleSelector from "./FormRoleSelector";
import FormLaborTaskSelector from "./FormLaborTaskSelector";
import FormPasswordField from "./FormPasswordField";
import FormDeliveryMethodSelector from "./FormDeliveryMethodSelector";
import FormDaysForAlertsCheckboxes from "./FormDaysForAlertsCheckboxes";
import FormTimeWindowSelectors from "./FormTimeWindowSelectors";
import FormAreaAlertCheckboxes from "./FormAreaAlertCheckboxes";
import FormLegacyReportHour from "./FormLegacyReportHour";
import FormReportsCheckboxes from "./FormReportsCheckboxes";
import FormEmailsCheckboxes from "./FormEmailsCheckboxes";
import FormBatchTextSelector from "./FormBatchTextSelector";
import FormBatchColorSelector from "./FormBatchColorSelector";
import FormOverviewContextSelector from "./FormOverviewContextSelector";

const StyledSection = withStyles((theme) => ({
  root: {
    paddingRight: theme.spacing(4),
  },
}))(Grid);

const StyledGridItem = withStyles((theme) => ({
  root: {
    paddingRight: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
}))(Grid);

const SetupForm = ({ user, isNew, setupData, onSubmitDone }) => {
  const { t } = useTranslation();
  const currentRole = useCurrentRole();
  const showExternal = useShowExternal();
  const hasLabor = getEvueLabor();
  const defaultLanguage = useDefaultLanguage();
  const uiSettings = useUserUiSettings(user.id);
  const showCard = useShowLoginCardValue();
  const jobNumber = useJobNumber();

  return (
    <FormEditor
      originalValues={{
        ...user,
        monday: isNew ? true : user.monday,
        tuesday: isNew ? true : user.tuesday,
        wednesday: isNew ? true : user.wednesday,
        thursday: isNew ? true : user.thursday,
        friday: isNew ? true : user.friday,
        startHour: isNew ? 9 : user.startHour,
        endHour: isNew ? 17 : user.endHour,
        reportList: isNew ? setupData.reportList : user.reportList,
        controlEnabled: isNew ? true : user.controlEnabled,
        legacyReportHour: isNew ? 4 : user.legacyReportHour,
        language: (isNew ? defaultLanguage : user.language) || "en_US",
        deliveryMethod: user.deliveryMethod || "email",
        uiSettings: {
          batchTopText: uiSettings.batchTopText,
          batchBottomText: uiSettings.batchBottomText,
          batchTopColor: uiSettings.batchTopColor,
          batchBottomColor: uiSettings.batchBottomColor,
          overviewContext: uiSettings.overviewContext,
        },
      }}
      onSubmit={({ initialValues, currentValues, actions }) => {
        const valuesForSubmission = Object.keys(currentValues).reduce((acc, key) => {
          if (key === "imageUrl" && initialValues["imageUrl"] === currentValues["imageUrl"]) {
            return acc;
          }
          if (["password", "passwordConfirmation"].includes(key) && currentValues[key] === "") {
            return acc;
          }

          return { ...acc, [key]: currentValues[key] === "" ? null : currentValues[key] };
        }, {});

        user.id
          ? updateUser({
              user: { ...valuesForSubmission },
              successCB: () => {
                actions.finishSubmit();
                onSubmitDone();
              },
              failureCB: (errors) => {
                actions.finishSubmit(true);
                actions.catchErrors(errors);
              },
            })
          : addUser({
              successCB: () => {
                actions.finishSubmit();
                onSubmitDone();
              },
              failureCB: (errors) => {
                actions.finishSubmit(true);
                actions.catchErrors(errors);
              },
              user: { ...valuesForSubmission },
            });
      }}
      onDelete={({ actions }) => {
        deleteUser({
          userId: user.id,
          successCB: () => onSubmitDone(),
          failureCB: () => actions.finishDelete(),
        });
      }}
    >
      {(props) => {
        const {
          currentValues,
          dirtyForm,
          errors,
          isSubmitting,
          isDeleting,
          setFieldValue,
          setArrayFieldValue,
          handleSubmit,
          handleDelete,
        } = props;

        const formDisabled = !!user.deletedAt || isSubmitting || !currentRole.canManageUsers;
        return (
          <>
            <CardContent sx={{ height: "100%", overflow: "auto" }}>
              <Grid container>
                <Backdrop open={isSubmitting || isDeleting}>
                  <CircularProgress color="inherit" />
                </Backdrop>

                <Grid item xs={12} sm={5} lg={3} sx={{ pr: 7 }}>
                  <Typography variant="h4" sx={{ mb: 1 }}>
                    {t("common:description")}
                  </Typography>

                  <Grid container direction="column">
                    <Box textAlign="center">
                      <ImageUploadButton
                        imageUrl={currentValues["imageUrl"]}
                        disabled={formDisabled}
                        onUpload={(imageUrl) => setFieldValue("imageUrl", imageUrl)}
                      />
                    </Box>

                    <Grid item>
                      <FormTextField
                        fieldName="firstname"
                        label={t("common:first_name")}
                        currentValues={currentValues}
                        errors={errors}
                        disabled={formDisabled}
                        onFieldChange={setFieldValue}
                      />
                    </Grid>

                    <Grid item>
                      <FormTextField
                        fieldName="lastname"
                        label={t("common:last_name")}
                        currentValues={currentValues}
                        errors={errors}
                        disabled={formDisabled}
                        onFieldChange={setFieldValue}
                      />
                    </Grid>

                    {showCard && (
                      <Grid item>
                        <FormTextField
                          fieldName="cardValue"
                          label={t("usersSetup:card_number")}
                          currentValues={currentValues}
                          errors={errors}
                          disabled={formDisabled}
                          onFieldChange={setFieldValue}
                        />
                      </Grid>
                    )}

                    {showExternal && (
                      <Grid item>
                        <FormTextField
                          fieldName="externalReference"
                          label={t("common:external_reference")}
                          currentValues={currentValues}
                          errors={errors}
                          disabled={formDisabled}
                          onFieldChange={setFieldValue}
                        />
                      </Grid>
                    )}

                    {hasLabor && (
                      <Grid item>
                        <FormNumberField
                          fieldName="employeeNumber"
                          label={t("usersSetup:employee_number")}
                          currentValues={currentValues}
                          errors={errors}
                          disabled={formDisabled}
                          onFieldChange={setFieldValue}
                        />
                      </Grid>
                    )}

                    {jobNumber !== "2490G" && (
                      <Grid item>
                        <FormLanguageSelector
                          currentValues={currentValues}
                          disabled={formDisabled}
                          onFieldChange={setFieldValue}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={7} lg={9}>
                  <Box>
                    <Typography variant="h4" sx={{ mb: 1 }}>
                      {t("usersSetup:contact_and_password")}
                    </Typography>

                    <Grid container>
                      <StyledGridItem item>
                        <FormTextField
                          fieldName="phone"
                          type="tel"
                          label={t("usersSetup:phone")}
                          currentValues={currentValues}
                          errors={errors}
                          disabled={formDisabled}
                          onFieldChange={setFieldValue}
                        />
                      </StyledGridItem>

                      <StyledGridItem item>
                        <FormTextField
                          fieldName="email"
                          type="email"
                          helperText={t("usersSetup:email_instructions")}
                          label={t("usersSetup:email")}
                          currentValues={currentValues}
                          errors={errors}
                          disabled={formDisabled}
                          onFieldChange={setFieldValue}
                        />
                      </StyledGridItem>

                      <StyledGridItem item>
                        <FormPasswordField
                          currentValues={currentValues}
                          errors={errors}
                          disabled={formDisabled}
                          onFieldChange={setFieldValue}
                        />
                      </StyledGridItem>

                      <StyledGridItem item>
                        <FormPasswordField
                          currentValues={currentValues}
                          isConfirmation={true}
                          errors={errors}
                          disabled={formDisabled}
                          onFieldChange={setFieldValue}
                        />
                      </StyledGridItem>
                    </Grid>
                  </Box>

                  <Box mt={3}>
                    <Divider />

                    <Typography variant="h4" sx={{ mt: 2, mb: 1 }}>
                      {t("usersSetup:permissions")}
                    </Typography>

                    <Grid container>
                      <StyledGridItem item>
                        <FormRoleSelector
                          currentValues={currentValues}
                          roleList={setupData.roleList || []}
                          disabled={formDisabled}
                          onFieldChange={setFieldValue}
                        />
                      </StyledGridItem>

                      {hasLabor && (
                        <StyledGridItem item>
                          <FormLaborTaskSelector
                            currentValues={currentValues}
                            laborTaskList={setupData.laborTaskList || []}
                            disabled={formDisabled}
                            onArrayFieldChange={setArrayFieldValue}
                          />
                        </StyledGridItem>
                      )}

                      <StyledGridItem item>
                        <FormCheckbox
                          fieldName="controlEnabled"
                          label={t("usersSetup:evue_control_access")}
                          currentValues={currentValues}
                          disabled={formDisabled}
                          onFieldChange={setFieldValue}
                        />
                      </StyledGridItem>

                      {hasLabor && (
                        <>
                          <StyledGridItem item>
                            <FormCheckbox
                              fieldName="laborEnabled"
                              label={t("usersSetup:labor_enabled")}
                              currentValues={currentValues}
                              disabled={formDisabled}
                              onFieldChange={setFieldValue}
                            />
                          </StyledGridItem>
                          <StyledGridItem item>
                            <FormCheckbox
                              fieldName="maintenance"
                              label={t("usersSetup:maintenance_enabled")}
                              currentValues={currentValues}
                              disabled={formDisabled}
                              onFieldChange={setFieldValue}
                            />
                          </StyledGridItem>
                        </>
                      )}
                    </Grid>
                  </Box>

                  <Box mt={3} display="flex">
                    <StyledSection container direction="column">
                      <Divider />

                      <Typography variant="h4" sx={{ mt: 2, mb: 1 }}>
                        {t("usersSetup:alert_settings")}
                      </Typography>

                      <Grid container>
                        <StyledGridItem item>
                          <FormDaysForAlertsCheckboxes
                            currentValues={currentValues}
                            isNew={isNew}
                            disabled={formDisabled}
                            onFieldChange={setFieldValue}
                          />
                        </StyledGridItem>

                        <StyledGridItem item>
                          <FormAreaAlertCheckboxes
                            currentValues={currentValues}
                            disabled={formDisabled}
                            onFieldChange={setFieldValue}
                          />
                        </StyledGridItem>

                        <Box display="flex" flexDirection="column" mr={2}>
                          <StyledGridItem item>
                            <FormTimeWindowSelectors
                              currentValues={currentValues}
                              disabled={formDisabled}
                              onFieldChange={setFieldValue}
                            />
                          </StyledGridItem>

                          <StyledGridItem item>
                            <FormDeliveryMethodSelector
                              currentValues={currentValues}
                              disabled={formDisabled}
                              onFieldChange={setFieldValue}
                            />
                          </StyledGridItem>

                          {hasLabor && currentValues["maintenance"] && (
                            <StyledGridItem item>
                              <FormCheckbox
                                fieldName="receiveHelpRequests"
                                label={t("usersSetup:help_requests")}
                                currentValues={currentValues}
                                disabled={formDisabled}
                                onFieldChange={setFieldValue}
                              />
                            </StyledGridItem>
                          )}
                        </Box>
                      </Grid>
                    </StyledSection>

                    <StyledSection container direction="column">
                      <Divider />

                      <Typography variant="h4" sx={{ mt: 2, mb: 1 }}>
                        {t("usersSetup:reports_settings")}
                      </Typography>

                      <Grid container>
                        <StyledGridItem item>
                          <FormEmailsCheckboxes
                            currentValues={currentValues}
                            disabled={formDisabled}
                            onFieldChange={setFieldValue}
                          />
                        </StyledGridItem>

                        <StyledGridItem item>
                          <FormReportsCheckboxes
                            currentValues={currentValues}
                            disabled={formDisabled}
                            onArrayFieldChange={setArrayFieldValue}
                          />
                        </StyledGridItem>

                        <StyledGridItem item>
                          <FormLegacyReportHour
                            currentValues={currentValues}
                            disabled={formDisabled}
                            onFieldChange={setFieldValue}
                          />
                        </StyledGridItem>
                      </Grid>
                    </StyledSection>

                    <Grid container direction="column">
                      <Divider />

                      <Typography variant="h4" sx={{ mt: 2, mb: 1 }}>
                        {t("usersSetup:ui_settings")}
                      </Typography>

                      <Grid container>
                        <StyledGridItem item>
                          <FormBatchTextSelector
                            currentValues={currentValues}
                            variant="top"
                            onFieldChange={setFieldValue}
                          />
                        </StyledGridItem>

                        <StyledGridItem item>
                          <FormBatchTextSelector
                            currentValues={currentValues}
                            variant="bottom"
                            onFieldChange={setFieldValue}
                          />
                        </StyledGridItem>

                        <StyledGridItem item>
                          <FormBatchColorSelector
                            currentValues={currentValues}
                            variant="top"
                            onFieldChange={setFieldValue}
                          />
                        </StyledGridItem>

                        <StyledGridItem item>
                          <FormBatchColorSelector
                            currentValues={currentValues}
                            variant="bottom"
                            onFieldChange={setFieldValue}
                          />
                        </StyledGridItem>

                        <StyledGridItem item>
                          <FormOverviewContextSelector currentValues={currentValues} onFieldChange={setFieldValue} />
                        </StyledGridItem>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>

            <Divider />

            {!user.deletedAt && (
              <Grid item container justifyContent="flex-end" sx={{ padding: 3 }}>
                {user.id && (
                  <Box mr={1}>
                    <DeleteButton disabled={formDisabled} onClick={handleDelete}>
                      {t("common:delete")}
                    </DeleteButton>
                  </Box>
                )}

                <SaveButton disabled={formDisabled || !dirtyForm} onClick={handleSubmit}>
                  {t("common:submit")}
                </SaveButton>
              </Grid>
            )}
          </>
        );
      }}
    </FormEditor>
  );
};

export default SetupForm;
