import { shallowEqual, useSelector } from "react-redux";
import store from "../store";
import _ from "lodash";

export const getSlingInputStationsIds = () => {
  const state = store.getState();
  return state.slingInputStations && Object.keys(state.slingInputStations);
};

export const getSlingInputStationRailId = (id) => {
  const state = store.getState();
  return state.slingInputStations[id]?.railId;
};

const formatConfigurations = (configuration) => {
  return {
    slingOrder: configuration?.slingOrder || "rtl",
    showSendLoadButton: Boolean(configuration?.showSendLoadButton),
    showCustomActionButton: Boolean(configuration?.showCustomActionButton),
    customActionButtonText: configuration?.customActionButtonText || "Custom action",
    showSlingTypeSelector: Boolean(configuration?.showSlingTypeSelector),
  };
};

export const useSlingInputStation = (id) =>
  useSelector((state) => {
    const sis = state.slingInputStations[id];
    return {
      ...sis,
      configuration: formatConfigurations(sis.configuration),
    };
  }, _.isEqual);

export const useCallEmpty = (id) =>
  useSelector((state) => {
    const sis = state.slingInputStations[id] || {};
    return sis.callEmpty;
  });

export const useShowCallEmptyButton = (id) =>
  useSelector((state) => {
    const sis = state.slingInputStations[id] || {};
    return sis.showCallEmptyButton;
  });

export const getSlingTypeId = (state = store.getState(), id) => state.slingInputStations[id]?.slingTypeId;

export const getShowCustomActionButton = (state, id) => {
  return state.slingInputStations[id]?.configuration?.showCustomActionButton;
};

export const getCustomActionButtonText = (state, id) => {
  return state.slingInputStations[id]?.configuration?.customActionButtonText;
};
