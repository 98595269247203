import React from "react";
import { useSelector } from "react-redux";
import { Card, CardContent, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
}));

const Categories = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { className, ...rest } = props;
  const categories = useSelector((state) => state.categories);

  return (
    <div className={classes.root}>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent>
          <Typography variant="h1" gutterBottom>
            {t("common:categories")}
          </Typography>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>{t("common:number")}</TableCell>
                <TableCell>{t("common:name")}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.values(categories).map((category) => {
                return (
                  <TableRow key={category.id}>
                    <TableCell>{category.id}</TableCell>
                    <TableCell>{category.number}</TableCell>
                    <TableCell>{category.name}</TableCell>
                    <TableCell>
                      <img src={category.imageUrl}></img>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  );
};

export default Categories;
