import React, { useState } from "react";
import { Drawer, FormControlLabel, IconButton } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import { useTranslation } from "react-i18next";
import { useCurrentRole } from "../../selectors/useRole";

interface Props {
  children: JSX.Element;
};

const ConfigurationButton = ({ children }: Props) => {
  const { t } = useTranslation();

  const [showConfigurations, setShowConfigurations] = useState(false);

  const role = useCurrentRole();
  if (!role.canManage) return null;
  return (
    <>
      <FormControlLabel
        control={
          <IconButton size="large" onClick={() => setShowConfigurations(true)}>
            <SettingsIcon sx={{ color: "info.light", fontSize: 50 }} />
          </IconButton>
        }
        labelPlacement="top"
        label={t<string>("slingInputStation:configuration")}
      />

      <Drawer anchor="right" open={showConfigurations} onClose={() => setShowConfigurations(false)}>
        {children}
      </Drawer>
    </>
  );
};

export default ConfigurationButton;
