import React, { useState } from "react";
import { manualOpen } from "../../api/RailApi";
import ManualOpenConfirmDialog from "../../components/SlingInputStation/ManualOpenConfirmDialog";
import ConciseManualOpenButton from "./ConciseManualOpenButton";

interface Props {
  railId: number,
  disabled?: boolean,
};

const CompManualOpenButton = ({ railId, disabled }: Props) => {
  const [showOpenConfirm, setShowOpenConfirm] = useState(false);

  return (
    <>
      <ConciseManualOpenButton disabled={disabled} onClick={() => setShowOpenConfirm(true)} />

      <ManualOpenConfirmDialog
        open={showOpenConfirm}
        onClose={() => setShowOpenConfirm(false)}
        onConfirm={() => {
          manualOpen(railId);
          setShowOpenConfirm(false);
        }}
      />
    </>
  );
};

export default CompManualOpenButton;
