import React, { useEffect } from "react";
import * as d3 from "d3";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { ReactComponent as Conveyor } from "../../../assets/icons/conveyor.svg";

const setupSVG = () => {
  const parent = d3.select("#conveyor-wrapper");
  const svg = d3.select("#conveyor");
  return svg.attr("width", parent.node()?.clientWidth + 10 || 300).attr("height", parent.node()?.clientHeight || 200);
};

const setupBelt = () => {
  const belt = d3.select("#belt");
  return belt.style("stroke", "black");
};

const setupCogs = () => {
  d3.select("#cog-2").style("fill", "black");
  d3.select("#cog-1").style("fill", "black");
};

const moveCog = ({ cog, move, theme }) => {
  const { x, y, width, height } = cog.node().getBBox();
  const cogCenterX = ((x || 0) + (width || 0)) / 2;
  const cogCenterY = ((y || 0) + (height || 0)) / 2;

  const interpolRotate = d3.interpolateString(
    `translate(${-cogCenterX}, ${-cogCenterY})rotate(0, ${cogCenterX}, ${cogCenterY})`,
    `translate(${-cogCenterX}, ${-cogCenterY})rotate(360, ${cogCenterX}, ${cogCenterY})`
  );

  cog.style("fill", move ? theme.palette.success.dark : theme.palette.error.main);
  const stopCog = () => cog.transition().duration(0);
  const rotateCog = () => {
    return cog
      .transition()
      .duration(4000)
      .ease(d3.easeLinear)
      .attrTween("transform", () => {
        return interpolRotate;
      })
      .on("end", () => rotateCog());
  };

  return move ? rotateCog() : stopCog();
};

const moveConveyor = ({ move, theme }) => {
  const leftCog = d3.select("#cog-2");
  moveCog({ cog: leftCog, move, theme });
  const rightCog = d3.select("#cog-1");
  moveCog({ cog: rightCog, move, theme });
};

const ConveyorIcon = ({ move }) => {
  const theme = useTheme();

  useEffect(() => {
    setupSVG();
    setupBelt();
    setupCogs();
  }, []);

  useEffect(() => {
    moveConveyor({ move, theme });
  }, [move]);

  return (
    <Box id="conveyor-wrapper" display="flex" justifyContent="center" width="100%" height="inherit">
      <Conveyor />
    </Box>
  );
};

export default ConveyorIcon;
