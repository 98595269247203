import _ from "lodash";

export const filterAndSortSelections = ({ selections, searchTerm, sortBy, order }) => {
  const filteredSelections = filterSelections({ selections, searchTerm });
  return sortSelections({ selections: filteredSelections, sortBy, order });
};

export const filterSelections = ({ selections, searchTerm }) => {
  if (selections?.length < 1) return [];
  if (!searchTerm) return selections;

  return selections.filter((selection) => {
    const number = selection.number.toString();
    const name = _.toLower(selection.name);
    const passedNumber = _.includes(number, searchTerm);
    const passedName = _.includes(name, searchTerm);

    return passedNumber || passedName;
  });
};

export const sortSelections = ({ selections, sortBy, order }) => {
  if (selections?.length < 1) return [];

  return selections.sort((a, b) => {
    const valueA = sortBy === "name" ? _.toLower(a.name) : a.number;
    const valueB = sortBy === "name" ? _.toLower(b.name) : b.number;

    if (order === "asc") {
      if (valueB > valueA) return -1;
      if (valueB < valueA) return 1;
    } else {
      if (valueB < valueA) return -1;
      if (valueB > valueA) return 1;
    }
    return 0;
  });
};
