import React, { useMemo, useRef, useState } from "react";
import _ from "lodash";
import { SortingOrder } from "../enum/eVue/SortingOrder";

interface SortProps<T> {
  selections: T[];
  sortBy: keyof T;
  order: SortingOrder;
};

interface FilterProps<T> {
  selections: T[];
  filterRules: (keyof T)[];
  searchTerm: string;
};

interface Props<T> {
  initialGroup: T[];
  filterRules: (keyof T)[];
  initialSortBy: keyof T;
};

const sortSelections = <T,>({ selections, sortBy, order }: SortProps<T>) => {
  if (selections?.length < 1) return [];

  return selections.sort((a, b) => {
    const valueA = a[sortBy] || "";
    const valueB = b[sortBy] || "";

    if (typeof valueA !== typeof valueB) return 0;
    if (order === SortingOrder.ASC) {
      if (valueB > valueA) return -1;
      if (valueB < valueA) return 1;
    } else {
      if (valueB < valueA) return -1;
      if (valueB > valueA) return 1;
    }
    return 0;
  });
};

const filterSelections = <T,>({ selections, filterRules = [], searchTerm = "" }: FilterProps<T>) => {
  if (selections?.length < 1) return [];
  if (!searchTerm) return selections;

  return selections.filter((selection) => {
    const attrsToMatch = filterRules.map((rule) => _.toLower(`${selection[rule]}`));
    let match = false;
    const searchTerms = searchTerm.split(",");
    for (const term of searchTerms) {
      if (match) {
        break;
      }
      match = _.includes(attrsToMatch.join(" "), term);
    }

    return match;
  });
};

const useSortingAndFiltering = <T>({ initialGroup, filterRules, initialSortBy }: Props<T>) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [order, setOrder] = useState<SortingOrder>(SortingOrder.ASC);
  const [sortBy, setSortBy] = useState<keyof T>(initialSortBy);
  const filterRulesRef = useRef(filterRules);

  const handleChangeSortBy = (selected: keyof T) => {
    if (selected === sortBy) {
      setOrder(order === SortingOrder.ASC ? SortingOrder.DESC : SortingOrder.ASC);
    } else {
      setOrder(SortingOrder.ASC);
      setSortBy(selected);
    }
  };

  const handleChangeSearchTerm = (term: string) => setSearchTerm(term);

  const sortedRails = useMemo(() =>
    sortSelections({ selections: initialGroup, sortBy, order }),
    [initialGroup, sortBy, order]);
  const filteredRails = useMemo(() =>
    filterSelections({
      selections: sortedRails,
      filterRules: filterRulesRef.current,
      searchTerm
    }), [sortedRails, filterRulesRef.current, searchTerm]);

  return { results: filteredRails, order, sortBy, searchTerm, handleChangeSortBy, handleChangeSearchTerm };
};

export default useSortingAndFiltering;
