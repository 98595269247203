import { INITIALIZE_UI_SETTINGS, UPDATE_UI_SETTINGS } from "./types";
import { configureChannel } from "../utils";
import store from "../store";
import i18n from "../i18n";

export function uiSettingSubscribe() {
  return (dispatch) => {
    uiSettingsChannel.on("init", (message) => {
      dispatch(initializeUiSettings(message.data));
    });

    uiSettingsChannel.on("ui_settings_updated", (message) => {
      console.info("Ui Settings Updated");
      if (message.data) {
        dispatch(updateUiSettings(message.data));
      }
    });

    uiSettingsChannel.on("refresh_client", () => {
      window.location.reload();
    });

    systemParametersChannel.on("system_parameters_update", (data) => {
      delete data.id;
      dispatch(updateUiSettings(data));
    });
  };
}

export function updateUiSettings(data) {
  return {
    type: UPDATE_UI_SETTINGS,
    data,
  };
}

export function initializeUiSettings(data) {
  const state = store.getState();
  const curLang = (state.users[state.session.currentUserId] || {}).language || data.defaultLanguage;
  i18n.changeLanguage(curLang);
  const recentLogins = JSON.parse(window.sessionStorage.getItem("recentLogins")) || [];

  return {
    type: INITIALIZE_UI_SETTINGS,
    data: {
      ...data,
      recentLogins,
    },
  };
}

export const uiSettingsChannel = configureChannel("ui_settings");

export const systemParametersChannel = configureChannel("system_parameters");
