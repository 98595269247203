import React from "react";
import { useDispatch } from "react-redux";
import { useUserAutoLogout } from "../selectors/useUserAutoLogout";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/CheckSharp";
import CloseIcon from "@mui/icons-material/CloseSharp";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import WarningIcon from "@mui/icons-material/WarningSharp";
import * as actions from "../actions";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  confirmButton: {
    backgroundColor: theme.palette.success.main,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
    },
  },
  dialogContentText: {
    display: "flex",
    alignItems: "center",
  },
  warningIcon: {
    width: "128px",
    height: "auto",
    marginRight: theme.spacing(2),
    color: theme.palette.warning.main,
  },
}));

const UserAutoLogout = () => {
  const { open, timeLeft } = useUserAutoLogout();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <Dialog open={open} maxWidth={"sm"} fullWidth={true}>
      <Box pt={2} pb={2} pl={3} pr={3}>
        <Typography variant="h3">{t("users:session_expire_title")}</Typography>
        <IconButton className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent>
        <DialogContentText className={classes.dialogContentText} variant="body1">
          <WarningIcon className={classes.warningIcon} />
          {t("users:session_expire_body", {
            time: timeLeft,
          })}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button variant="contained" autoFocus startIcon={<CloseIcon />}>
          {t("common:cancel")}
        </Button>

        <Button
          variant="contained"
          className={classes.confirmButton}
          onClick={() => dispatch(actions.logout())}
          startIcon={<CheckIcon />}
        >
          {t("user:logout")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserAutoLogout;
