import React from "react";
import { Button, FormControlLabel } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ClearIcon from "@mui/icons-material/ClearAll";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "50%",
  },
  xLargeButton: {
    padding: theme.spacing(2),
    fontSize: 40,

    "& #start-icon": {
      fontSize: 40,
    },
  },
}));

const ConciseClearSlingButton = (props) => {
  const { id, onClick, disabled, startIcon, disableElevation = true, size, children, ...rest } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <FormControlLabel
      control={
        <Button
          id={id}
          className={clsx({
            [classes.button]: true,
            [classes.xLargeButton]: size === "xLarge",
          })}
          size={size === "xLarge" ? "large" : size}
          disabled={disabled}
          variant="contained"
          disableElevation={disableElevation}
          onClick={onClick}
          {...rest}
        >
          {startIcon || <ClearIcon id="start-icon" />}
        </Button>
      }
      label={t("common:clear") || children}
      labelPlacement="top"
    />
  );
};

export default ConciseClearSlingButton;
