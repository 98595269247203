import * as actions from "../actions/types";
import { objFromArray } from "../utils";

const initialState = {};

export default function batchReducer(state = initialState, action) {
  switch (action.type) {
    case actions.INITIALIZE_BATCHES:
      return objFromArray(action.batches);

    case actions.UPDATE_BATCH:
      const { batch } = action;
      return { ...state, [batch.id]: { ...state[batch.id], ...batch } };

    case actions.REMOVE_BATCHES:
      const newState = { ...state };
      action.batchIds.forEach((id) => delete newState[id]);
      return newState;

    default:
      return state;
  }
}
