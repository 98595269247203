import { useCallback } from "react";
import { useSelector } from "react-redux";

export const useLoadCell = (loadCellId) => {
  const loadCells = useSelector((state) => state.loadCells);

  /**
   * Load Cell entity
   */
  const loadCell = loadCells[loadCellId] || {};

  /**
   * Return Load Cell entity
   * @returns {*|{}}
   */
  const getLoadCell = () => {
    return loadCell;
  };

  /**
   * Load Cell interface
   */
  return {
    calibratedAt: loadCell.calibratedAt,
    calibratedById: loadCell.calibratedById,
    calibrationWeight: loadCell.calibrationWeight,
    captureValue: loadCell.captureValue,
    captureWeight: loadCell.captureWeight,
    currentValue: loadCell.currentValue,
    getLoadCell: useCallback(getLoadCell),
    id: loadCell.id,
    railId: loadCell.railId,
    readOnly: loadCell.readOnly,
    span: loadCell.span,
    tare: loadCell.tare,
    weight: loadCell.weight,
  };
};
