import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/CloseSharp";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import WarningIcon from "@mui/icons-material/WarningSharp";
import { useTranslation } from "react-i18next";
import { getShowRailSystemControls } from "../selectors/local";
import { useCurrentRole } from "../selectors/useRole";
import { hideRailSystemControls } from "../actions/localActions";
import { useSystemHalted } from "../selectors/serverStatus";
import { haltSystem, unhaltSystem } from "../api/SystemApi";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContentText: {
    display: "flex",
    alignItems: "center",
  },
  warningIcon: {
    width: "128px",
    height: "auto",
    marginRight: theme.spacing(2),
    color: theme.palette.error.main,
  },
  resumeButton: {
    color: theme.palette.getContrastText(theme.palette.success.main),
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.main,
    },
  },
  haltButton: {
    color: theme.palette.getContrastText(theme.palette.error.main),
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.main,
    },
  },
}));

const RailSystemControlsDialog = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const showRailSystemControls = useSelector((state) => getShowRailSystemControls(state));
  const systemHalted = useSystemHalted();
  const currentRole = useCurrentRole();

  return (
    <Dialog open={showRailSystemControls} maxWidth={"xs"} fullWidth={true}>
      <DialogTitle>
        <Typography variant="h3">{t("railSystem:controls_title")}</Typography>

        <IconButton className={classes.closeButton} onClick={() => dispatch(hideRailSystemControls())}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <DialogContentText className={classes.dialogContentText} variant="body1">
          <WarningIcon className={classes.warningIcon} />
          {systemHalted ? t("railSystem:unhalt_system_warning") : t("railSystem:halt_system_warning")}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        {!systemHalted ? (
          <Button
            classes={{ root: classes.haltButton }}
            variant="contained"
            disableElevation={true}
            size="large"
            disabled={!currentRole.canHaltSystem}
            onClick={haltSystem}
          >
            {t("railSystem:halt_system")}
          </Button>
        ) : (
          <Button
            classes={{ root: classes.resumeButton }}
            variant="contained"
            disableElevation={true}
            size="large"
            disabled={!currentRole.canHaltSystem}
            onClick={unhaltSystem}
          >
            {t("railSystem:resume_system")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default RailSystemControlsDialog;
