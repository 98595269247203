import React from "react";
import PropTypes from "prop-types";
import { Button, FormControlLabel, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ForwardIcon from "@mui/icons-material/Forward";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { useSlingInputStation } from "../../selectors/slingInputStations";
import { useRail } from "../../selectors/rails";
import { getRailVisitsCountForRail } from "../../selectors/railVisits";
import { useCurrentRole } from "../../selectors/useRole";
import { useJobNumber } from "../../selectors/uiSettings";
import { manualSend } from "../../api/RailApi";
import { maintenanceManualSend } from "../../api/SlingInputStationApi";
import { IoConstant } from "../../constants/IoConstant";
import { RailGroup } from "../../constants/RailGroup";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "50%",
  },
  xLargeButton: {
    padding: theme.spacing(2),

    "& #start-icon": {
      fontSize: 65,
    },
  },
  popper: {
    zIndex: 1,
  },
  tooltip: {
    fontSize: 15,
  },
}));

const useGenerateDisableReasons = ({ sis, rail, externalDisableReasons }) => {
  const { status, overweightValidation, underweightValidation } = sis;
  const railVisitCount = useSelector((state) => getRailVisitsCountForRail(state, rail.id));
  const { canControlInputLifts } = useCurrentRole();
  const { t } = useTranslation();
  const ignoreSlingCount = rail.railGroup === RailGroup.EMPTY && !rail.tracked;

  const disabledReasons = externalDisableReasons?.length > 0 ? externalDisableReasons : [];
  if (!rail.outputEnabled) disabledReasons.push(t("slingInputStation:output_disabled"));
  if (railVisitCount < 1 && !ignoreSlingCount) disabledReasons.push(t("slingInputStation:sling_missing"));
  if (!canControlInputLifts) disabledReasons.push(t("slingInputStation:insufficient_permissions"));
  if (overweightValidation && status === "overweight") {
    disabledReasons.push(t("slingInputStation:overweight"));
  }
  if (underweightValidation && status === "underweight") {
    disabledReasons.push(t("slingInputStation:underweight"));
  }

  return disabledReasons;
};

const ManSendButton = ({ sis, rail, size = "medium", externalDisableReasons = [], iconDirection = "forward" }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const disabledReasons = useGenerateDisableReasons({ sis, rail, externalDisableReasons });

  return (
    <Tooltip
      classes={{ popper: classes.popper, tooltip: classes.tooltip }}
      arrow
      placement="top"
      open={disabledReasons.length > 0}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title={`${t("slingInputStation:disabled")}: ${disabledReasons.join(", ")} `}
    >
      <FormControlLabel
        control={
          <Button
            variant="contained"
            color="primary"
            className={clsx({
              [classes.button]: true,
              [classes.xLargeButton]: size === "xLarge",
            })}
            size={size === "xLarge" ? "large" : size}
            disabled={disabledReasons.length > 0}
            disableElevation={true}
            onClick={() => (rail.maintenance ? maintenanceManualSend(sis.id) : manualSend(rail.id))}
          >
            <ForwardIcon
              id="start-icon"
              sx={{ transform: `rotate(${iconDirection === "backward" ? "180deg" : "0"})` }}
            />
          </Button>
        }
        label={t(rail.maintenance ? "slingInputStation:send_bag" : "common:send_load")}
        labelPlacement="top"
      />
    </Tooltip>
  );
};

const ConciseManSendButton = ({ sisId, ...rest }) => {
  const sis = useSlingInputStation(sisId) || {};
  const rail = useRail(sis.railId);
  const jobNumber = useJobNumber();

  const restricted = [
    IoConstant.SINGLE_FREE_SWING_LIFT_IO_TYPE,
    IoConstant.GUIDED_LOADING_LIFT_WITH_JOGGING,
    IoConstant.SINGLE_THREE_STAGE_WITH_LOADCELL_IO_TYPE,
  ];
  if (jobNumber === "1895") {
    const threeStageLiftIndex = restricted.indexOf(IoConstant.SINGLE_THREE_STAGE_WITH_LOADCELL_IO_TYPE);
    restricted.splice(threeStageLiftIndex, 1);
  }
  const hideManualSend = restricted.includes(rail.railIoTypeId);

  if (hideManualSend) return null;
  return <ManSendButton sis={sis} rail={rail} {...rest} />;
};

ConciseManSendButton.propTypes = {
  sisId: PropTypes.number,
  size: PropTypes.oneOf(["small", "medium", "large", "xLarge"]),
  externalDisableReasons: PropTypes.array,
  iconDirection: PropTypes.oneOf(["forward", "backward"]),
};

export default ConciseManSendButton;
