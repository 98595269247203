import React from "react";
import styled from "styled-components";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import ProductionListCard from "./ProductionListCard";
import { Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setSort } from "../../redux/productionListEditor";
import StrategySelectorButton from "../ProductionLists/StrategySelectorButton";

const List = styled.div`
  border: 1px ${(props) => (props.isDraggingOver ? "dashed #000" : "solid #ddd")};
  background: #fff;
  padding: 0.5rem;
  border-radius: 3px;
  flex: 0 0 150px;
  font-family: sans-serif;
`;

const Kiosk = styled(List)``;

const Notice = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 3.5rem 0.5rem;
  margin: 0.5rem;
  border: 1px solid transparent;
  line-height: 1.5;
  color: #aaa;
`;

const Item = styled.div`
  display: flex;
  user-select: none;
  margin: 0 0 0.5rem 0;
  align-items: center;
  align-content: flex-start;
  line-height: 1.5;
  min-height: 52px;
`;

const Clone = styled(Item)`
  ~ div {
    transform: none !important;
  }
`;

const SortBy = styled.div`
  display: flex;
  align-items: baseline;
  margin: 10px;
`;

const SortButton = styled(Button)`
  margin: 8px;
`;

const Column = (props) => {
  const {
    clone,
    droppableId,
    items,
    isDropDisabled,
    isDragDisabled,
    isRemovable,
    message,
    onPickRemove,
    onUpdateStrategy,
    showPosition,
  } = props;
  const { sortBy } = useSelector((state) => state.productionListEdit);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Droppable key={droppableId} droppableId={droppableId} isDropDisabled={isDropDisabled}>
      {(provided, snapshot) => (
        <>
          {droppableId === "categories" && (
            <SortBy>
              <Typography variant={"h5"} color="inherit" gutterBottom>
                {t("common:sort_by")}:
              </Typography>
              <SortButton
                variant="contained"
                color={sortBy === "number" ? "secondary" : "inherit"}
                onClick={() => dispatch(setSort("number"))}
              >
                {t("common:number")}
              </SortButton>
              <SortButton
                variant="contained"
                color={sortBy === "name" ? "secondary" : "inherit"}
                onClick={() => dispatch(setSort("name"))}
              >
                {t("common:name")}
              </SortButton>
            </SortBy>
          )}
          <Kiosk ref={provided.innerRef} isDraggingOver={snapshot.isDraggingOver}>
            {items && items.length ? (
              items.map((item, index) => {
                if (!item) return null;

                let icon;
                let number;
                let color;
                switch (item.pickType) {
                  case "category":
                    icon = "LocalOffer";
                    number = item.entity.number;
                    color = item.entity.color;
                    break;
                  case "list":
                    icon = "FormatListNumbered";
                    break;
                  case "rail":
                    icon = "CallSplit";
                    break;
                  case "group":
                    icon = "DeveloperBoard";
                    break;
                  default:
                    icon = "Adjust";
                    break;
                }

                return (
                  <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={isDragDisabled}>
                    {(provided, snapshot) => (
                      <>
                        <Item
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={provided.draggableProps.style}
                        >
                          <ProductionListCard
                            isDragging={snapshot.isDragging}
                            index={index}
                            icon={icon}
                            label={item.content}
                            color={color}
                            number={number}
                            isRemovable={isRemovable}
                            showPosition={showPosition}
                            strategySelector={
                              item.pickType === "group" &&
                              onUpdateStrategy && (
                                <StrategySelectorButton
                                  pick={item}
                                  onSubmit={(strategy) => onUpdateStrategy(droppableId, index, strategy)}
                                />
                              )
                            }
                            onClose={() => {
                              onPickRemove(droppableId, index);
                            }}
                          />
                        </Item>
                        {clone && snapshot.isDragging && (
                          <Clone>
                            <ProductionListCard
                              index={index}
                              icon={icon}
                              label={item.content}
                              color={color}
                              number={number}
                              isRemovable={isRemovable}
                            />
                          </Clone>
                        )}
                      </>
                    )}
                  </Draggable>
                );
              })
            ) : (
              <Notice>{message ?? t("productionList:drop_items_here")}</Notice>
            )}
            {provided.placeholder}
          </Kiosk>
        </>
      )}
    </Droppable>
  );
};

export default Column;
