import { INITIALIZE_LOAD_CELLS, UPDATE_LOAD_CELLS, UPDATE_LOAD_CELL } from "./types";
import { configureChannel } from "../utils";

export function loadCellSubscribe() {
  return (dispatch) => {
    loadCellsChannel.on("multi_lc_update", (msg) => {
      dispatch(updateLoadCells(msg.data));
    });

    loadCellsChannel.on("init", (msg) => {
      dispatch(initializeLoadCells(msg.data));
    });

    loadCellsChannel.on("update", (msg) => {
      dispatch(updateLoadCell(msg));
    });
  };
}

export function updateLoadCells(loadCells) {
  return {
    type: UPDATE_LOAD_CELLS,
    loadCells: loadCells,
  };
}

export const updateLoadCell = (loadCell) => {
  return {
    type: UPDATE_LOAD_CELL,
    loadCell: loadCell,
  };
};

export function initializeLoadCells(loadCells) {
  return {
    type: INITIALIZE_LOAD_CELLS,
    loadCells: loadCells,
  };
}

export const loadCellsChannel = configureChannel("load_cells");
