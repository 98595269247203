import React, { useEffect } from "react";
import useNumberTicketScanner from "../hooks/useNumberTicketScanner";
import { useCustomerIdFromNumber } from "../../../selectors/customers";
import { isCustomerScannerActive } from "../utils";

// TODO: Figure out way to isolate these edga case features in a way that makes it obvious they are not meant for all jobs and prob dont want any of their functionality bleeding into more concrete components
interface Props {
  onScan: (customerId: number) => void;
};

const Content = ({ onScan }: Props) => {
  const [customerNumber, scanCount] = useNumberTicketScanner();
  const customerId = useCustomerIdFromNumber(parseInt(customerNumber || ""));
  useEffect(() => {
    if (customerId) {
      onScan(customerId)
    }
  }, [scanCount]);

  return null;
};

const CustomerTicketScanner = ({ onScan }: Props) => {
  return isCustomerScannerActive() && <Content onScan={onScan} />
}

export default CustomerTicketScanner;
