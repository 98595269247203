import { nameComparer } from "../utils/sort";
import { shallowEqual, useSelector } from "react-redux";
import _ from "lodash";

export const useSelectedStorageConfigSystemId = () => {
  return useSelector((state) => state.storageConfigEdit.selectedSystem?.id);
};

export const useStorageConfigEditLoaded = () => {
  return useSelector((state) => state.storageConfigEdit.loaded);
};

export const useStorageConfigEditLoader = () => {
  return useSelector((state) => state.storageConfigEdit.loaderOpen);
};

export const useStorageConfigEditLoaderMessage = () => {
  return useSelector((state) => state.storageConfigEdit.loaderMessage);
};

export const useStorageConfigById = (configId) => {
  return useSelector((state) => {
    const allConfigs = state.storageConfigEdit.configs;
    return allConfigs[configId] || {};
  }, _.isEqual);
};

export const useEditorAssignments = () => {
  return useSelector((state) => state.storageConfigEdit.editorAssignments, _.isEqual);
};

export const useCustomersOfStorageConfig = () => {
  const customers = useSelector((state) => state.customers, shallowEqual);
  const selectedSystemType = useSelector((state) => state.storageConfigEdit?.selectedSystem?.systemType);
  const storageConfigId = useSelector((state) => state.storageConfigEdit?.selectedConfig?.id);

  return Object.values(customers).filter((customer) => {
    switch (selectedSystemType) {
      case "soiled":
        return customer.soiledStorageConfigId === storageConfigId;
      case "clean":
        return customer.cleanStorageConfigId === storageConfigId;
      case "customer_bag":
        return customer.cbStorageConfigId === storageConfigId;
      default:
        return null;
    }
  });
};

export const useCustomerMappings = () => {
  const customers = useSelector((state) => state.customers, shallowEqual);
  const systems = useSelector((state) => state.systems, _.isEqual);
  const configs = useSelector((state) => state.storageConfigEdit.configs, _.isEqual);

  const cleanSystem = Object.values(systems).find((x) => x.systemType === "clean");
  const cleanConfigs = Object.values(configs ?? [])
    .filter((config) => config.systemId === cleanSystem?.id)
    .sort(nameComparer);

  const customerBagSystem = Object.values(systems).find((x) => x.systemType === "customer_bag");
  const customerBagConfigs = Object.values(configs ?? [])
    .filter((config) => config.systemId === customerBagSystem?.id)
    .sort(nameComparer);

  const soiledSystem = Object.values(systems).find((x) => x.systemType === "soiled");
  const soiledConfigs = Object.values(configs ?? [])
    .filter((config) => config.systemId === soiledSystem?.id)
    .sort(nameComparer);

  return {
    cleanConfigs,
    cleanSystem,
    configs,
    customerBagConfigs,
    customerBagSystem,
    customers,
    showCleanSystem: !!cleanSystem,
    showCustomerBagSystem: !!customerBagSystem,
    showSoiledSystem: !!soiledSystem,
    soiledConfigs,
    soiledSystem,
    systems,
  };
};
