import React from "react";
import { FormControl, Select, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDeviceTypes } from "../../features/deviceTypes/deviceTypeSlice";
import FormFieldLabel from "../SetupPages/FormFieldLabel";

const FormDeviceTypeSelector = ({ currentValues, disabled, onFieldChange }) => {
  const { t } = useTranslation();
  const allDeviceTypes = useDeviceTypes() || {};
  const deviceTypesIds = Object.keys(allDeviceTypes);

  const handleChange = (e) => onFieldChange(e.target.value === "none" ? null : parseInt(e.target.value));

  return (
    <FormControl classes={{ root: { width: "inherit" } }} variant="outlined">
      <FormFieldLabel label={t("networkDevicesSetup:device_type")} />

      <Select value={currentValues["deviceType"] || "none"} disabled={disabled} onChange={handleChange}>
        <MenuItem value="none">
          <em>{t("common:none")}</em>
        </MenuItem>
        {deviceTypesIds.map((dtId) => (
          <MenuItem key={dtId} value={dtId}>
            {allDeviceTypes[dtId]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FormDeviceTypeSelector;
