import React from "react";
import { Button, ButtonGroup } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { StorageMngrViews } from "../enum/StorageMngrViews";

interface Props {
  view: StorageMngrViews;
  onTabClick: (value: StorageMngrViews) => void;
};

const ViewTabs = ({ view, onTabClick }: Props) => {
  const { t } = useTranslation();

  return (
    <ButtonGroup disableElevation variant="outlined" sx={{ height: "fit-content" }}>
      <Button
        sx={{ backgroundColor: (theme) => view === StorageMngrViews.GROUPS ?
          alpha(theme.palette.info.light, 0.1) :
          "initial"
        }}
        onClick={() => onTabClick(StorageMngrViews.GROUPS)}
      >
        {t("storageManager:storage_groups")}
      </Button>

      <Button
        sx={{ backgroundColor: (theme) => view === StorageMngrViews.OVERRIDES ?
          alpha(theme.palette.info.light, 0.1) :
          "initial"
        }}
        onClick={() => onTabClick(StorageMngrViews.OVERRIDES)}
      >
        {t("storageManager:overrides")}
      </Button>
    </ButtonGroup>
  )
}

export default ViewTabs;
