import React from "react";
import { useHistory } from "react-router-dom";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { useCurrentRole } from "../../selectors/useRole";
import { SETUP_USERS } from "../../constants/Paths";

const AddUserButton = ({ size }) => {
  const currentRole = useCurrentRole();
  const history = useHistory();

  return (
    <Fab
      size={size}
      color="primary"
      disabled={!currentRole.canManageUsers}
      onClick={() => history.push(`${SETUP_USERS}/new`)}
    >
      <AddIcon />
    </Fab>
  );
};

export default AddUserButton;
