import React, { useCallback, useMemo, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/CloseSharp";
import { useTranslation } from "react-i18next";
import { getRails } from "../../../../selectors/rails";
import type { Rail } from "../../../../dto/eVue/Rail";
import useSortingAndFiltering from "../../../../hooks/useSortingAndFiltering";
import RailCardWrapper from "./RailCardWrapper";
import RailIconButton from "./RailIconButton";
import FilterControls from "./FilterControls";
import VirtualListWrapper from "./VirtualListWrapper";

// TODO: Translations - Add internalization support if feature is released to public

const RailSelectionDialogContent = ({ onSelectionMade }: { onSelectionMade: VoidFunction }) => {
  const { t } = useTranslation();
  const [railGroup, setRailGroup] = useState(0);

  const rails = useMemo(() => getRails() || [], []);
  const railsForRailGroup = useMemo(() => rails.filter((rail) => railGroup === 0 || rail.railGroup === railGroup), [railGroup]);
  const sortingAndFiltering = useSortingAndFiltering<Rail>({
    initialGroup: railsForRailGroup,
    filterRules: ["id", "name", "label"],
    initialSortBy: "id",
  });
  const { results, order, sortBy, handleChangeSortBy, handleChangeSearchTerm } = sortingAndFiltering;

  const handleOnRailSelect = useCallback((railId: number) => {
    window.overview.findByRail && window.overview.findByRail(railId);
    onSelectionMade();
  }, []);

  return (
    <>
      <Box display="flex" flexDirection="column" pt={2} pl={3} pb={2} pr={3}>
        <Typography variant="h3">{t("overview:select_rail")}</Typography>
        <Typography gutterBottom>Select a rail to find it in overview</Typography>

        <FilterControls
          sx={{ mt: 2 }}
          sortBy={sortBy}
          order={order}
          searchPlaceholder="ID, label or name"
          railGroup={railGroup}
          onChangeRailGroup={(railGroup: number) => setRailGroup(railGroup)}
          onChangeSortBy={(sortByValue) => handleChangeSortBy(sortByValue as keyof Rail)}
          onChangeSearchTerm={handleChangeSearchTerm}
        />
      </Box>

      <DialogContent sx={{ height: "100%" }} dividers={true}>
        {results.length > 0 ? (
          <VirtualListWrapper count={results.length || 0}>
            {(selectionIndex: number) => results[selectionIndex] && (
              <RailCardWrapper rail={results[selectionIndex]} onClick={handleOnRailSelect} />
            )}
          </VirtualListWrapper>
        ) : <Typography>{t("common:none")}</Typography>}
      </DialogContent>
    </>
  );
};

const ZoomToRailButton = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <>
      <RailIconButton onClick={() => setOpen(true)} />

      <Dialog
        open={open}
        maxWidth="md"
        onBackdropClick={() => setOpen(false)}
        fullWidth={true}
        sx={{
          "& .MuiDialog-paper": {
            maxHeight: "calc(100vh - 180px)",
            height: "100%",
          }
        }}
      >
        {open && <RailSelectionDialogContent onSelectionMade={() => setOpen(false)} />}

        <DialogActions>
          <Button
            variant="outlined"
            autoFocus
            disableElevation
            disableFocusRipple
            onClick={() => setOpen(false)}
            startIcon={<CloseIcon />}
          >
            {t<string>("common:close")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ZoomToRailButton;
