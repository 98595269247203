import { shallowEqual, useSelector } from "react-redux";
import _ from "lodash";
import { FaultType } from "@etechinc/overview-sdk";

import { RailGroup } from "../constants/RailGroup";
import { IoConstant } from "../constants/IoConstant";
import store from "../store";
import { sortingComparer } from "../utils/sort";

export const useRailsIds = () =>
  useSelector((state) => {
    const rails = state.rails;
    return Object.keys(rails);
  }, shallowEqual);

export const getRails = (state = store.getState()) => Object.values(state.rails).map((r) => formatRail(r));

export const useRailIdsForSystemId = (systemId) =>
  useSelector(
    (state) =>
      Object.values(state.rails)
        .filter((x) => x.systemId === systemId)
        .map((y) => y.id),
    shallowEqual
  );

export const useRail = (railId) => {
  return useSelector((state) => formatRail(state.rails[railId] || {}), _.isEqual);
};

export const getRailById = (railId, receivedState) => {
  const state = receivedState || store.getState();
  const rail = state.rails[railId];
  return formatRail(rail);
};

export const useRailFullQuantity = (railId) => useSelector((state) => state.rails[railId].fullQuantity || 0);

export const useLiveWeightForRailId = (railId) =>
  useSelector((state) => state.loadCells[state.rails[railId].loadCellId]?.weight || 0);

export const useRailPlcState = (railId) => useSelector((state) => state.rails[railId]?.plcState);

export const useRailLabel = (railId) => useSelector((state) => state.rails[railId]?.label);

export const useRailOffNote = (railId) => useSelector((state) => state.rails[railId]?.offNote);

export const useRailFaults = (railId) =>
  useSelector((state) => {
    const rail = state.rails[railId];
    return {
      hasBagStuckFault: rail?.faults?.some((fault) => fault.faultType === FaultType.BAG_STUCK),
      hasBagNotInSensorFault: rail?.faults?.some((fault) => fault.faultType === FaultType.BAG_NOT_IN_SENSOR),
      hasBagOverweightFault: rail?.faults?.some((fault) => fault.faultType === FaultType.BAG_OVERWEIGHT),
      hasBagUnexpectedFault: rail?.faults?.some((fault) => fault.faultType === FaultType.BAG_UNEXPECTED),
      hasTripCountFault: rail?.faults?.some((fault) => fault.faultType === FaultType.TRIP_COUNT),
      hasOverweightSort: rail?.faults?.some((fault) => fault.faultType === FaultType.SORT_OVERWEIGHT),
    };
  }, shallowEqual);

// export const useRailMessages = railId =>
//   useSelector(state => {
//     return {
//       messages: state.railMessageEvents.messages.filter((message) => message.railId === railId)
//     };
//   }, shallowEqual);

// export const useGroupedRailMessages = () =>
//   useSelector(state => {
//     const grouped = {};

//     state.railMessageEvents.messages.forEach((message) => {
//       if (!grouped.hasOwnProperty(message.railId)) {
//         grouped[message.railId] = [];
//       }

//       grouped[message.railId].push(message);
//     });

//     return {
//       groupedMessages: grouped
//     };
//   }, shallowEqual);

export const getSystemIdForRail = (railId, state) => state.rails[railId]?.systemId;

export const formatRail = (rail = {}) => {
  // TODO: Evaluate short circuit patterns. This broke troubleshooter. Might break other things. Will add back once more in depth testing done.
  // if (!rail.id) return null;

  /**
   * Rail interface
   */

  return {
    alarming: rail.alarming,
    color: rail.color,
    imageUrl: rail.imageUrl,
    destinationDeciderId: rail.destinationDeciderId,
    fullQuantity: rail.fullQuantity,
    id: rail.id,
    inputEnabled: rail.inputEnabled,
    label: rail.label,
    loadCellId: rail.loadCellId,
    name: rail.name,
    number: rail.number,
    railGroup: rail.railGroup,
    storageRail: rail.railGroup === RailGroup.STORAGE,
    railIoTypeId: rail.railIoTypeId,
    scannerId: rail.scannerId,
    outputEnabled: rail.outputEnabled,
    systemId: rail.systemId,
    manualComm: rail.manualComm,
    sortStationId: rail.sortStationId,
    plcState: rail.plcState,
    plcStateCode: rail.plcStateCode,
    maintenance: rail.maintenance,
    tracked: rail.tracked,
    offNote: rail.offNote,
    decisionMessageLabel: rail.decisionMessageLabel,
    decisionMessageText: rail.decisionMessageText,
    secondsToAlarm: rail.secondsToAlarm,
    notes: rail.notes,
    permissions: rail.permissions,
    routingType: rail.routingType,
    recirculationRailId: rail.recirculationRailId,
  };
};

export const getHasRailSystem = () => {
  const state = store.getState();
  const rails = Object.values(state.rails);
  return !!rails.filter((rail) => rail.railIoTypeId != IoConstant.SORT_DISPLAY_NON_RAIL_IO_TYPE).length;
};

export const getDischargers = () => {
  const state = store.getState();
  const rails = state.rails || {};
  return Object.values(rails).filter((rail) => rail.dischargerUi);
};

export const useMaintenanceRailsForSystem = (systemId) =>
  useSelector(
    (state) => Object.values(state.rails).filter((x) => x.maintenance && x.systemId === systemId),
    shallowEqual
  );

export const useInputRailIdsForSystem = (systemId) =>
  useSelector((state) => {
    const allRails = Object.values(state.rails);
    const filteredRails = allRails?.reduce((acc, rail) => {
      if (rail.railGroup === RailGroup.INPUT && rail.systemId === systemId) {
        acc.push(rail);
      }
      return acc;
    }, []);

    return filteredRails?.sort((a, b) => sortingComparer(a?.number, b?.number))?.map((rail) => rail?.id);
  }, shallowEqual);
