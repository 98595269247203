import { INITIALIZE_DEVICES, UPDATE_DEVICE } from "./types";
import { configureChannel } from "../utils";
import { fetchDeviceTypes } from "../features/deviceTypes/deviceTypeSlice";

export function deviceSubscribe() {
  return (dispatch) => {
    dispatch(fetchDeviceTypes());

    devicesChannel.on("init", (msg) => {
      dispatch(initializeDevices(msg.data));
    });

    devicesChannel.on("update", (msg) => {
      dispatch(updateDevice(msg));
    });
  };
}

export const updateDevice = (device) => ({
  type: UPDATE_DEVICE,
  entity: device,
});

export function initializeDevices(devices) {
  return {
    type: INITIALIZE_DEVICES,
    devices: devices,
  };
}

export const devicesChannel = configureChannel("devices");
