import React from "react";
import { Box, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import FormFieldLabel from "../../../../components/SetupPages/FormFieldLabel";

interface Props {
  label: string;
  currentValue: string;
  options: string[];
  disabled: boolean;
  onChange: (selection: string) => void;
};

const FormDropdown = ({ label, currentValue, options, disabled, onChange }: Props) => {
  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value as string);
  };

  return (
    <Box display="flex" flexDirection="column">
      {label && <FormFieldLabel label={label} />}

      <Select
        variant="outlined"
        displayEmpty={true}
        value={currentValue || ""}
        disabled={disabled}
        onChange={handleChange}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option} disabled={disabled}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default FormDropdown;
