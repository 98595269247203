import React from "react";

// material-ui
import { Grid, Typography } from "@mui/material";

// project imports
import Avatar from "../../ui-component/extended/Avatar";
import { useProducerView } from "./SLXSlice";
import { useUser } from "../../selectors/useUser";
import MainCard from "../../ui-component/cards/MainCard";

const OperatorItem = ({ userId }) => {
  const operator = useUser(userId);

  return (
    <Grid item container direction="column" xs={4} alignItems="center" spacing={2}>
      <Grid item>
        <Avatar size="md" color="warning" src={operator.imageUrl}>
          {`${operator.firstname.charAt(0)}${operator.lastname.charAt(0)}`}
        </Avatar>
      </Grid>
      <Grid item>
        <Typography align="center">{`${operator.firstname} ${operator.lastname}`}</Typography>
      </Grid>
    </Grid>
  );
};

const OperatorList = ({ producerViewId }) => {
  const { operatorIds = [] } = useProducerView(producerViewId);
  if (!operatorIds.length) return null;
  return (
    <MainCard>
      <Typography variant="h2" gutterBottom>
        Current Operators
      </Typography>
      <Grid container direction="row">
        {operatorIds.length ? (
          operatorIds.map((x) => <OperatorItem key={x} userId={x} />)
        ) : (
          <Typography variant="h5">There are no operators logged in</Typography>
        )}
      </Grid>
    </MainCard>
  );
};

export default OperatorList;
