import React, { useState, useEffect } from "react";
import { Backdrop, Box, CardContent, CircularProgress, Divider, Grid, Paper, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import ProdPointIcon from "@mui/icons-material/PlayForWork";
import { useTranslation } from "react-i18next";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { SETUP_PRODUCTION_POINTS } from "../../constants/Paths";
import { getProductionPoint, updateProductionPoint } from "../../api/ProductionPointApi";
import { useCurrentRole } from "../../selectors/useRole";
import { getEvueLabor } from "../../selectors/uiSettings";
import FormEditor from "../SetupPages/FormEditor";
import ImageUploadButton from "../SetupPages/ImageUploadButton";
import FormTextField from "../SetupPages/FormTextField";
import FormNumberField from "../SetupPages/FormNumberField";
import SaveButton from "../SaveButton";
import FormConfiguredUnitList from "./FormConfiguredUnitList";
import ProdPointsListButton from "./ProdPointsListButton";
import FormConfigPerCategoryManager from "./FormConfigPerCategoryManager";

const StyledGridItem = withStyles((theme) => ({
  root: {
    paddingRight: theme.spacing(3),
    marginBottom: theme.spacing(2),
    "&:last-child": {
      paddingRight: 0,
    },
  },
}))(Grid);

const SetupForm = ({ productionPoint, setupData, onSubmitDone }) => {
  const { t } = useTranslation();
  const currentRole = useCurrentRole();
  const hasLabor = getEvueLabor();

  return (
    <FormEditor
      originalValues={{ ...productionPoint }}
      onSubmit={({ initialValues, currentValues, actions }) => {
        const valuesForSubmission = Object.keys(currentValues).reduce((acc, key) => {
          if (key === "imageUrl" && initialValues["imageUrl"] === currentValues["imageUrl"]) {
            return acc;
          }
          if (key === "sortClassificationPerspectives") {
            acc["sortClassificationPerspectivesAttributes"] = currentValues[key];
            return acc;
          }
          return { ...acc, [key]: currentValues[key] === "" ? null : currentValues[key] };
        }, {});

        updateProductionPoint({
          productionPointId: productionPoint.id,
          productionPoint: { ...valuesForSubmission },
          successCB: () => {
            actions.finishSubmit();
            onSubmitDone();
          },
          failureCB: (errors) => {
            actions.finishSubmit(true);
            actions.catchErrors(errors);
          },
        });
      }}
    >
      {(props) => {
        const { currentValues, dirtyForm, errors, isSubmitting, setFieldValue, setArrayFieldValue, handleSubmit } =
          props;

        const formDisabled = isSubmitting || !currentRole.canConfigureMetrics;

        return (
          <>
            <CardContent sx={{ height: "100%", overflow: "auto" }}>
              <Grid container>
                <Backdrop open={isSubmitting}>
                  <CircularProgress color="inherit" />
                </Backdrop>

                <Grid item xs={12} sm={5} lg={3} sx={{ pr: 7 }}>
                  <Typography variant="h4" sx={{ mb: 1 }}>
                    {t("common:description")}
                  </Typography>

                  <Grid container direction="column">
                    <Box mt={1} textAlign="center">
                      <ImageUploadButton
                        defaultIcon={<ProdPointIcon style={{ fontSize: 80 }} />}
                        imageUrl={currentValues["imageUrl"]}
                        disabled={formDisabled}
                        onUpload={(imageUrl) => setFieldValue("imageUrl", imageUrl)}
                      />
                    </Box>

                    <FormTextField
                      fieldName="name"
                      label={t("common:name")}
                      currentValues={currentValues}
                      errors={errors}
                      disabled={formDisabled}
                      onFieldChange={setFieldValue}
                    />

                    <Box mt={2}>
                      <Divider />
                    </Box>

                    {hasLabor && (
                      <StyledGridItem item>
                        <FormNumberField
                          fieldName="autoLogout"
                          label={t("productionPointsSetup:auto_logout_timer")}
                          currentValues={currentValues}
                          errors={errors}
                          disabled={formDisabled}
                          onFieldChange={setFieldValue}
                        />
                      </StyledGridItem>
                    )}
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={7} lg={4}>
                  <Typography variant="h4" sx={{ mb: 1 }}>
                    {t("productionPointsSetup:configurations")}
                  </Typography>

                  <Grid container direction="column">
                    <Grid item container>
                      <StyledGridItem item xs={6}>
                        <FormNumberField
                          fieldName="configuredTargetRate"
                          label={t("productionPointsSetup:configured_target_rate")}
                          currentValues={currentValues}
                          errors={errors}
                          disabled={formDisabled}
                          onFieldChange={setFieldValue}
                        />
                      </StyledGridItem>

                      {hasLabor && (
                        <StyledGridItem item xs={6}>
                          <FormNumberField
                            fieldName="configuredPpoh"
                            label={t("productionPointsSetup:configured_ppoh")}
                            currentValues={currentValues}
                            errors={errors}
                            disabled={formDisabled}
                            onFieldChange={setFieldValue}
                          />
                        </StyledGridItem>
                      )}
                    </Grid>

                    <StyledGridItem>
                      <FormConfiguredUnitList
                        currentValues={currentValues}
                        disabled={formDisabled}
                        configuredUnitList={setupData.configuredUnitList}
                        onFieldChange={setFieldValue}
                      />
                    </StyledGridItem>

                    <StyledGridItem>
                      <FormConfigPerCategoryManager
                        currentValues={currentValues}
                        disabled={formDisabled}
                        onArrayFieldChange={setArrayFieldValue}
                      />
                    </StyledGridItem>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>

            <Divider />

            <Grid item container justifyContent="flex-end" sx={{ padding: 3 }}>
              <SaveButton disabled={formDisabled || !dirtyForm} onClick={handleSubmit}>
                {t("common:submit")}
              </SaveButton>
            </Grid>
          </>
        );
      }}
    </FormEditor>
  );
};

const ProductionPointEdit = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [setupData, setSetupData] = useState();
  const [productionPoint, setProductionPoint] = useState();
  const dataLoaded = productionPoint;
  const params = useParams();

  useEffect(() => {
    getProductionPoint({
      productionPointId: params.id,
      successCB: (data) => {
        setSetupData(data.meta);
        setProductionPoint(data.perspective || {});
      },
      failureCB: () => {
        setProductionPoint({});
      },
    });
  }, [params.id]);

  const location = useLocation();
  const handleGoToList = () => {
    const newLocation = {
      pathname: SETUP_PRODUCTION_POINTS,
      state: { listScrollPos: location.state?.listScrollPos, searchTerm: location.state?.searchTerm },
    };

    history.push(newLocation);
  };

  return (
    <Paper square={true} elevation={0} sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box padding={3} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h1">{t("productionPointsSetup:edit_production_point")}</Typography>
        <ProdPointsListButton onClick={handleGoToList} />
      </Box>

      <Divider />

      {!dataLoaded ? (
        <Backdrop open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        productionPoint.id && (
          <SetupForm productionPoint={productionPoint} setupData={setupData} onSubmitDone={handleGoToList} />
        )
      )}
    </Paper>
  );
};

export default ProductionPointEdit;
