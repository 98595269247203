import React from "react";
import _ from "lodash";
import { Grid, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import FormTextField from "../../../components/SetupPages/FormTextField";
import FormNumberField from "../../../components/SetupPages/FormNumberField";
import FormCheckbox from "../../../components/SetupPages/FormCheckbox";
import FormDropdownSelect from "../../../components/SetupPages/FormDropdownSelect";
import type { SystemConfig } from "../dto/SystemConfig";
import type { ResponseError } from "../dto/ResponseError";

const StyledGridItem = withStyles((theme) => ({
  root: {
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}))(Grid);

interface Props {
  config: SystemConfig;
  currentValues: { [key: string]: SystemConfig };
  setFieldValue: (key: string, value: string) => void;
  errors?: { [key: string]: ResponseError};
  disabled?: boolean;
};

const SingleConfig = ({ config, currentValues, setFieldValue, errors, disabled }: Props) => {
  const displayedName = _.capitalize(config?.parameter.replace(/_/gi, " "));

  return (
    <StyledGridItem sx={{ maxWidth: 300 }}>
      {config.type === "boolean" && (
        <FormCheckbox
          fieldName={_.camelCase(config.parameter)}
          label={displayedName}
          currentValues={currentValues}
          disabled={disabled}
          onFieldChange={setFieldValue}
        />
      )}

      {config.type === "string" && (config.options?.length ?? 0) > 0 && (
        <FormDropdownSelect
          fieldName={_.camelCase(config.parameter)}
          label={displayedName}
          currentValues={currentValues}
          options={config.options}
          errors={errors}
          disabled={disabled}
          onFieldChange={setFieldValue}
        />
      )}

      {config.type === "string" && !config.options && (
        <FormTextField
          fieldName={_.camelCase(config.parameter)}
          label={displayedName}
          currentValues={currentValues}
          errors={errors}
          disabled={disabled}
          onFieldChange={setFieldValue}
        />
      )}

      {config.type === "integer" && (
        <FormNumberField
          fieldName={_.camelCase(config.parameter)}
          label={displayedName}
          currentValues={currentValues}
          errors={errors}
          disabled={disabled}
          onFieldChange={setFieldValue}
        />
      )}

      <Typography
        sx={(theme) => ({ display: "flex", mt: 1, mb: 4, color: theme.palette.info.main })}
        variant="overline"
      >
        {config.description}
      </Typography>
    </StyledGridItem>
  );
};

export default SingleConfig;
