import _ from "lodash";
import store from "../store";
import { showAlertError, showAlertSuccess } from "../actions/alertActions";
import Client from "./Client.js";
import i18n from "../i18n";

export const getProductionPoints = ({ successCB }) => {
  Client.get("control_api/v1/perspectives")
    .then((response) => successCB(response.data))
    .catch((error) => {
      store.dispatch(showAlertError(i18n.t("productionPointsSetup:production_points_fetch_failed")));
    });
};

export const getProductionPoint = ({ productionPointId, successCB, failureCB }) => {
  Client.get(`control_api/v1/perspectives/${productionPointId}`)
    .then((response) => {
      if (successCB) {
        successCB(response.data);
      }
    })
    .catch((error) => {
      if (failureCB) {
        failureCB();
      }
      store.dispatch(showAlertError(i18n.t("common:fetch_error", { error: JSON.stringify(error) })));
    });
};

export const updateProductionPoint = (props) => {
  const { productionPointId, productionPoint, successCB, failureCB } = props;
  Client.put(`control_api/v1/perspectives/${productionPointId}`, productionPoint)
    .then((response) => {
      successCB();
      store.dispatch(showAlertSuccess(i18n.t("common:success")));
    })
    .catch((error) => {
      const errorsByKey = _.get(error, "response.data", "API Error");
      failureCB(errorsByKey);
      store.dispatch(showAlertError(`${i18n.t("common:error")}: ${JSON.stringify(errorsByKey)}`));
    });
};
