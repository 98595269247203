import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Image from "../assets/logos/kge_logo.png";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#091623",
    height: "100vh",
  },
  paperContainer: {
    backgroundColor: "#091623",
    width: "100vw",
    height: "100vh",
    backgroundImage: `url(${Image})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "auto",
  },
  contentWrapper: {
    position: "absolute",
    bottom: "calc(50% - 250px)",
    alignItems: "center",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  progressBar: {
    width: "75%",
    height: "20px",
  },
  resources: {
    width: "65%",
    marginTop: theme.spacing(4),
  },
}));

const AppLoader = () => {
  const classes = useStyles();
  const loader = useSelector((state) => state.loader);

  const total = Object.keys(loader).length;
  const complete = Object.keys(loader).filter((key) => loader[key]).length;
  const completed = Math.ceil(100 * (complete / total));

  return (
    <div className={classes.root}>
      <Paper className={classes.paperContainer}>
        <div className={classes.contentWrapper}>
          <LinearProgress className={classes.progressBar} variant="determinate" value={completed} />
          <Grid container justifyContent="center" spacing={2} className={classes.resources}>
            {Object.keys(loader).map((key) => {
              return (
                <Grid item key={key}>
                  <Typography variant="overline" style={{ color: loader[key] ? "#54bf6d" : "#848d9c" }}>
                    {key}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </Paper>
    </div>
  );
};

export default AppLoader;
