import usePhoenixChannel from "./usePhoenixChannel";

const reducer = (state, { event, payload }) => {
  switch (event) {
    case "update":
      return payload;
    case "joined":
      return payload;
    default:
      return state;
  }
};

const useSlingInputStation = (sisId) => {
  const [data, _dispatch] = usePhoenixChannel(`sling_input_station:${sisId}`, reducer, {});
  return data;
};

export default useSlingInputStation;
