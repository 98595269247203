import React from "react";
import _ from "lodash";
import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import { useSlingInputStation } from "../../../selectors/slingInputStations";
import { useRail } from "../../../selectors/rails";
import { useRailVisitsCountForRail } from "../../../selectors/railVisits";
import { performCustomAction } from "../../../api/SlingInputStationApi";
import BagStatusIndicator from "../BagStatusIndicator";
import EmptyCartButton from "./EmptyCartButton";
import Bag from "./Bag";

const GarbageChuteSIS = ({ sisId }) => {
  const slingInputStation = useSlingInputStation(sisId);
  const rail = useRail(slingInputStation.railId);
  const railVisitsCount = useRailVisitsCountForRail(rail.id);
  const slingLoaded = railVisitsCount >= 1;

  const handleEmptyCartClick = () => {
    return performCustomAction({ sisId, successMessage: "Empty Cart Success" });
  };

  return (
    <Paper square={true} elevation={0} sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
      <Box sx={{ padding: 2 }} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h1">{rail.name}</Typography>
        <EmptyCartButton onClick={handleEmptyCartClick} />
      </Box>

      <Divider />

      <Box sx={{ display: "flex", justifyContent: "center", padding: 2 }}>
        {slingLoaded ? (
          <Bag
            key={`garbage-chute-${slingInputStation.id}`}
            railId={slingInputStation.railId}
            slingLoaded={slingLoaded}
            categoryId={slingInputStation.category1Id}
            customerId={slingInputStation.customer1Id}
            loadCellId={rail.loadCellId}
          />
        ) : (
          <BagStatusIndicator status="missing" />
        )}
      </Box>
    </Paper>
  );
};

export default GarbageChuteSIS;
