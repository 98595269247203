export default {
  red: ["#c62828", "#e53935", "#ef5350", "#ef9a9a", "#ffebee"],
  pink: ["#ad1457", "#d81b60", "#ec407a", "#f48fb1", "#fce4ec"],
  purple: ["#6a1b9a", "#8e24aa", "#ab47bc", "#ce93d8", "#f3e5f5"],
  "deep-purple": ["#4527a0", "#5e35b1", "#7e57c2", "#b39ddb", "#ede7f6"],
  indigo: ["#283593", "#3949ab", "#5c6bc0", "#9fa8da", "#e8eaf6"],
  blue: ["#1565c0", "#1e88e5", "#42a5f5", "#90caf9", "#e3f2fd"],
  cyan: ["#00838f", "#00acc1", "#26c6da", "#80deea", "#e0f7fa"],
  teal: ["#00695c", "#00897b", "#26a69a", "#80cbc4", "#e0f2f1"],
  green: ["#2e7d32", "#43a047", "#66bb6a", "#a5d6a7", "#e8f5e9"],
  lime: ["#9e9d24", "#c0ca33", "#d4e157", "#e6ee9c", "#f9fbe7"],
  amber: ["#ff8f00", "#ffb300", "#ffca28", "#ffe082", "#fff8e1"],
  "deep-orange": ["#d84315", "#f4511e", "#ff7043", "#ffab91", "#fbe9e7"],
  brown: ["#4e342e", "#6d4c41", "#8d6e63", "#bcaaa4", "#efebe9"],
  "grey-mix": ["#546e7a", "#78909c", "#b0bec5", "#bdbdbd", "#ffffff"],
};
