import { DATA_LOADED, INITIALIZE_RAIL_SYSTEM } from "./types";

export const dataLoaded = (loaded) => {
  return {
    type: DATA_LOADED,
    loaded,
  };
};

export const initializeRailSystem = (batches) => ({
  type: INITIALIZE_RAIL_SYSTEM,
});
