import React, { useState, useEffect } from "react";
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/CloseSharp";
import { makeStyles } from "@mui/styles";
import BackspaceIcon from "@mui/icons-material/Backspace";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: theme.palette.grey[900],
  },
  titleContent: {
    color: theme.palette.common.white,
  },
  titleText: {
    width: 220,
  },
  inputField: {
    width: "100%",
  },
  rootInput: {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none",
    },
  },
  content: {
    padding: theme.spacing(1),
  },
  numberButton: {
    height: 90,
    width: 90,
    color: theme.palette.common.white,
  },
  submitButton: {
    height: 90,
    width: "100%",
    backgroundColor: theme.palette.success.main,
    color: theme.palette.white,
    "&:disabled": {
      backgroundColor: theme.palette.grey[200],
    },
    "&:hover": {
      backgroundColor: theme.palette.success.light,
    },
  },
}));

const NumberButton = ({ number, clickHandler }) => {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      color="primary"
      size="large"
      className={classes.numberButton}
      onKeyPress={(e) => e.key === "Enter" && e.preventDefault()}
      onClick={() => clickHandler(number)}
    >
      <Typography color="inherit" variant="h3">
        {number}
      </Typography>
    </Button>
  );
};

const NumpadField = (props) => {
  const {
    title,
    errorMessage,
    value,
    submitValue,
    disabled,
    onChange = () => ({}),
    onExited = () => ({}),
    children,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [isDirty, setIsDirty] = useState(false);
  const [myValue, setMyValue] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    setMyValue(value || "");
    setIsDirty(false);
  }, [value]);

  const handleNumberClick = (number) => {
    if (!isDirty) {
      setMyValue(number);
      setIsDirty(true);
      onChange(number);
    } else {
      setMyValue(myValue * 10 + number);
      onChange(myValue * 10 + number);
    }
  };

  const handleClearField = () => {
    setMyValue("");
    onChange("");
  };

  const handleDeleteSingleChar = () => {
    const newValue = myValue < 10 ? "" : Math.floor(myValue / 10);
    setMyValue(newValue);
    onChange(newValue);
  };

  const handleSubmit = () => {
    submitValue(myValue);
    setIsDirty(false);
    setDialogOpen(false);
  };

  const handleClose = () => {
    setIsDirty(false);
    setDialogOpen(false);
  };

  const handleOnExited = () => {
    setMyValue("");
    onExited();
  };

  const childClickHandler = () => {
    setMyValue(value || "");
    setDialogOpen(!disabled);
  };

  const isTouchDevice = () =>
    "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

  return (
    <>
      {React.cloneElement(children, {
        onClick: childClickHandler,
        onFocus: (e) => e.preventDefault(),
      })}
      <Dialog
        open={dialogOpen}
        onBackdropClick={handleClose}
        TransitionProps={{
          onExited: handleOnExited,
        }}
        maxWidth="md"
      >
        <DialogTitle className={classes.title}>
          <Grid className={classes.titleContent} container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography color="inherit" className={classes.titleText}>
                {title || t("common:new_value")}
              </Typography>

              <Typography color="error" className={classes.titleText}>
                {errorMessage && errorMessage}
              </Typography>
            </Grid>

            <Grid item>
              <IconButton color="inherit" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent className={classes.content}>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <TextField
                className={classes.inputField}
                type="number"
                variant="outlined"
                autoFocus={!isTouchDevice()}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: isTouchDevice(),
                  classes: { root: classes.rootInput },
                }}
                value={myValue}
                onChange={(event) => {
                  setMyValue(event.target.value);
                  onChange(event.target.value);
                }}
                onFocus={(event) => event.target.select()}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    handleSubmit();
                    event.preventDefault();
                  }
                }}
              />
            </Grid>

            <Grid item>
              <Grid container direction="row" spacing={1} justifyContent="space-between">
                <Grid item xs={4}>
                  <NumberButton number={1} clickHandler={handleNumberClick} />
                </Grid>
                <Grid item xs={4}>
                  <NumberButton number={2} clickHandler={handleNumberClick} />
                </Grid>
                <Grid item xs={4}>
                  <NumberButton number={3} clickHandler={handleNumberClick} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={1} justifyContent="space-between">
                <Grid item xs={4}>
                  <NumberButton number={4} clickHandler={handleNumberClick} />
                </Grid>
                <Grid item xs={4}>
                  <NumberButton number={5} clickHandler={handleNumberClick} />
                </Grid>
                <Grid item xs={4}>
                  <NumberButton number={6} clickHandler={handleNumberClick} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={1} justifyContent="space-between">
                <Grid item xs={4}>
                  <NumberButton number={7} clickHandler={handleNumberClick} />
                </Grid>
                <Grid item xs={4}>
                  <NumberButton number={8} clickHandler={handleNumberClick} />
                </Grid>
                <Grid item xs={4}>
                  <NumberButton number={9} clickHandler={handleNumberClick} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={1} justifyContent="space-between">
                <Grid item xs={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.numberButton}
                    onClick={handleClearField}
                  >
                    <Typography color="inherit" variant="h3">
                      C
                    </Typography>
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <NumberButton number={0} clickHandler={handleNumberClick} />
                </Grid>
                <Grid item xs={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.numberButton}
                    onClick={handleDeleteSingleChar}
                  >
                    <BackspaceIcon />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.submitButton}
                onClick={handleSubmit}
              >
                <Typography color="inherit" variant="h3">
                  OK
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

NumpadField.defaultProps = {
  value: "",
  disabled: false,
};

export default NumpadField;
