import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useIsLoggedInUserTechnician } from "../../../selectors/roles";

const useRedirectOnLogout = () => {
  const history = useHistory();
  const isTechnicianLoggedIn = useIsLoggedInUserTechnician();

  useEffect(() => {
    if (!isTechnicianLoggedIn) {
      history.push("/");
    };
  }, [isTechnicianLoggedIn]);
};

export default useRedirectOnLogout;
