import _ from "lodash";
import { showAlertError, showAlertSuccess } from "../actions";
import store from "../store";
import i18n from "../i18n";
import Client from "./Client";

export const fetchUserSetupOptions = ({ successCB }) => {
  Client.get("control_api/v1/users/setup_options")
    .then((response) => successCB(response.data))
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(i18n.t("common:fetch_error"), { error: errorMessage }));
    });
};

export const fetchUser = ({ userId, successCB }) => {
  Client.get(`control_api/v1/users/${userId}`)
    .then((response) => {
      if (successCB) {
        successCB(response.data);
      }
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data.error", "API Error"));
      store.dispatch(showAlertError(i18n.t("common:fetch_error", { error: errorMessage })));
    });
};

export const addUser = ({ successCB, failureCB, user }) => {
  Client.post("control_api/v1/users", user)
    .then((response) => {
      if (successCB) {
        successCB();
      }
      store.dispatch(showAlertSuccess(i18n.t("common:success")));
    })
    .catch((error) => {
      const errorsByKey = _.get(error, "response.data.errors", "API Error");
      if (failureCB) {
        failureCB(errorsByKey);
      }
      store.dispatch(showAlertError(`${i18n.t("common:error")}: ${JSON.stringify(errorsByKey)}`));
    });
};

export const updateUser = ({ user, successCB, failureCB }) => {
  Client.put(`control_api/v1/users/${user.id}`, user)
    .then((response) => {
      store.dispatch(showAlertSuccess(i18n.t("common:success")));
      if (successCB) {
        successCB();
      }
    })
    .catch((error) => {
      const errorMessage = _.get(error, "response.data.errors", "API Error");
      if (failureCB) {
        failureCB(errorMessage);
      }

      store.dispatch(showAlertError(`${i18n.t("common:error")}: ${JSON.stringify(errorMessage)}`));
    });
};

export const updateUserUISettings = ({ userId, uiSettings, successCB, failureCB }) => {
  Client.put(`control_api/v1/users/${userId}/update_ui_settings`, uiSettings)
    .then((response) => {
      store.dispatch(showAlertSuccess(i18n.t("common:success")));
      if (successCB) {
        successCB();
      }
    })
    .catch((error) => {
      const errorMessage = _.get(error, "response.data.errors", "API Error");
      if (failureCB) {
        failureCB(errorMessage);
      }

      store.dispatch(showAlertError(`${i18n.t("common:error")}: ${JSON.stringify(errorMessage)}`));
    });
};

export const deleteUser = ({ userId, successCB, failureCB }) => {
  Client.delete(`control_api/v1/users/${userId}`)
    .then((response) => {
      if (successCB) {
        successCB();
      }

      store.dispatch(showAlertSuccess(i18n.t("common:success")));
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data.errors", "API Error"));
      if (failureCB) {
        failureCB();
      }

      store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};
