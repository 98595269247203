import React from "react";
import { Avatar, Button } from "@mui/material";
import FocusIcon from "@mui/icons-material/CenterFocusStrong";

const RailIconButton = ({ onClick }: { onClick: VoidFunction }) => {
  return (
    <Button
      sx={{
        minWidth: {
          xs: 0,
          md: 64,
        },
      }}
      onClick={onClick}
    >
      <Avatar sx={{
        color: "common.white",
        backgroundColor: "#bdbdbd",
        width: {
          xs: 32,
          md: 40,
        },
        height: {
          xs: 32,
          md: 40,
        },
      }}>
        <FocusIcon sx={{
          fontSize: {
            xs: 22,
            md: 30,
          },
        }} />
      </Avatar>
    </Button>
  );
};

export default RailIconButton;
