import React, { useState } from "react";
import _ from "lodash";
import { Button } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { useCurrentRole } from "../../../selectors/useRole";
import TextEditorDialog from "../../../components/TextEditorDialog";
import { renameConfig, selectEnableRename, selectSelectedSortConfig } from "../redux/sortConfigurationsSlice";

const RenameButton = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const enableRename = useAppSelector(selectEnableRename);
  const selectedSortConfiguration = useAppSelector(selectSelectedSortConfig, _.isEqual);
  const { t } = useTranslation();
  const role = useCurrentRole();

  return (
    <>
      <Button
        sx={{
          maxWidth: 200,
          "&:disabled": {
            backgroundColor: "grey.200",
          },
        }}
        variant="outlined"
        disabled={!enableRename || !role.canEditSortConfigs}
        onClick={() => setOpen(true)}
        startIcon={<Edit />}
      >
        {t("common:rename")}
      </Button>

      <TextEditorDialog
        open={open}
        fullWidth={true}
        maxWidth="md"
        message={t("sortAssignments:edit_name")}
        initialValue={selectedSortConfiguration && selectedSortConfiguration.name}
        onClose={() => setOpen(false)}
        onSubmit={(name: string) => {
          setOpen(false);
          dispatch(renameConfig(name));
        }}
      />
    </>
  );
};

export default RenameButton;
