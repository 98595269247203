import React from "react";
import { Box, Typography } from "@mui/material";

const FormFieldLabel = ({ label }) => {
  return (
    <Box mt={1} mb={0.5}>
      <Typography variant="h6">{label}</Typography>
    </Box>
  );
};

export default FormFieldLabel;
