import _ from "lodash";
import {
  getStorageConfigurations,
  deleteStorageConfiguration,
  createStorageConfiguration,
  cloneStorageConfiguration,
} from "../api/StorageConfigurationsApi";
import Client from "../api/Client";

const ADD_ASSIGNMENT = "STORAGE_CONFIG_ADD_ASSIGNMENT";
const CLEAR_EDITOR = "STORAGE_CONFIG_CLEAR_EDITOR";
const CLONE_CONFIG_REQUEST = "STORAGE_CONFIG_CLONE_REQUEST";
const CLONE_CONFIG_SUCCESS = "STORAGE_CONFIG_CLONE_SUCCESS";
const CREATE_REQUEST = "STORAGE_CONFIG_CREATE_REQUEST";
const CREATE_SUCCESS = "STORAGE_CONFIG_CREATE_SUCCESS";
const CREATE_ERROR = "STORAGE_CONFIG_CREATE_ERROR";
const DELETE_REQUEST = "STORAGE_CONFIG_DELETE_REQUEST";
const DELETE_SUCCESS = "STORAGE_CONFIG_DELETE_SUCCESS";
const DELETE_ERROR = "STORAGE_CONFIG_DELETE_ERROR";
const LOAD_CONFIGS_REQUEST = "STORAGE_CONFIG_LOAD_CONFIGS_REQUEST";
const LOAD_CONFIGS_SUCCESS = "STORAGE_CONFIG_LOAD_CONFIGS_SUCCESS";
const LOAD_CONFIGS_ERROR = "STORAGE_CONFIG_LOAD_CONFIGS_ERROR";
const LOADER_SHOW = "STORAGE_CONFIG_LOADER_SHOW";
const LOADER_HIDE = "STORAGE_CONFIG_LOADER_HIDE";
const MAPPING_TOGGLE = "STORAGE_CONFIG_MAPPING_TOGGLE";
const REMOVE_ASSIGNMENT = "STORAGE_CONFIG_REMOVE_ASSIGNMENT";
const RENAME_CONFIG = "STORAGE_CONFIG_RENAME_CONFIG";
const SELECT_CONFIG = "STORAGE_CONFIG_SELECT_CONFIG";
const UPDATE_REQUEST = "STORAGE_CONFIG_UPDATE_REQUEST";
const UPDATE_SUCCESS = "STORAGE_CONFIG_UPDATE_SUCCESS";
const UPDATE_ERROR = "STORAGE_CONFIG_UPDATE_ERROR";

export const addAssignment = (categoryId, railId) => {
  return {
    type: ADD_ASSIGNMENT,
    categoryId: categoryId,
    railId: railId,
  };
};

export const clearEditor = () => ({ type: CLEAR_EDITOR });

export const cloneConfig = (name) => {
  return (dispatch, getState) => {
    dispatch({ type: CLONE_CONFIG_REQUEST });
    const { selectedSystem, selectedConfig } = getState().storageConfigEdit;

    cloneStorageConfiguration({
      name,
      systemId: selectedSystem.id,
      assignments: [...selectedConfig.assignments],
      successCB: (response) => {
        dispatch({
          type: CLONE_CONFIG_SUCCESS,
          data: response,
        });
      },
    });
  };
};

export const createConfig = (name) => {
  return (dispatch, getState) => {
    dispatch({ type: CREATE_REQUEST });
    const { selectedSystem } = getState().storageConfigEdit;
    createStorageConfiguration({
      name: name,
      systemId: selectedSystem.id,
      successCB: (response) => dispatch({ type: CREATE_SUCCESS, data: response }),
    });
  };
};

export const deleteConfig = () => {
  return (dispatch, getState) => {
    dispatch({ type: DELETE_REQUEST });
    const { selectedConfig } = getState().storageConfigEdit;
    deleteStorageConfiguration({
      id: selectedConfig.id,
      successCB: (response) => dispatch({ type: DELETE_SUCCESS, id: selectedConfig.id }),
      errorCB: (error) => dispatch({ type: DELETE_ERROR }),
    });
  };
};

export const loadConfigs = (system) => {
  return (dispatch, getState) => {
    const initialSystem = system ?? Object.values(getState().systems).find((x) => x.storageRailIds.length > 0); // TODO selector maybe?
    dispatch({ type: LOAD_CONFIGS_REQUEST, initialSystem: initialSystem });
    getStorageConfigurations({
      successCB: (configs) => dispatch({ type: LOAD_CONFIGS_SUCCESS, configs: configs }),
    });
    // TODO: Handle load data failure.
  };
};

export const removeAssignment = (categoryId, railId) => {
  return {
    type: REMOVE_ASSIGNMENT,
    categoryId: categoryId,
    railId: railId,
  };
};

export const renameConfig = (name) => ({ type: RENAME_CONFIG, name: name });

export const saveConfig = () => {
  return (dispatch, getState) => {
    dispatch({ type: UPDATE_REQUEST });
    const { selectedConfig, editorAssignments, editorName } = getState().storageConfigEdit;
    Client.put(`control_api/v1/storage_configurations/${selectedConfig.id}`, {
      name: editorName,
      assignments: editorAssignments,
    })
      .then((response) => dispatch({ type: UPDATE_SUCCESS, data: response.data }))
      .catch((error) => dispatch({ type: UPDATE_ERROR, data: error }));
  };
};

export const selectConfig = (id) => ({ type: SELECT_CONFIG, id: id });

export const showMessage = (message) => ({ type: LOADER_SHOW, message });

export const hideMessage = () => ({ type: LOADER_HIDE });

export const toggleMapping = () => ({ type: MAPPING_TOGGLE });

const initialState = {
  configs: {},
  editorName: "",
  enableClone: false,
  enableDelete: false,
  enableRename: false,
  enableSave: false,
  loaded: false,
  loaderOpen: false,
  loaderMessage: null,
  mappingOpen: false,
  selectedSystem: {},
  selectedConfig: {},
  editorAssignments: [],
};

export default function storageConfigReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_ASSIGNMENT:
      const computedPriority = state.editorAssignments.filter((x) => x.categoryId === action.categoryId).length + 1;
      return {
        ...state,
        editorAssignments: [
          ...state.editorAssignments,
          { railId: action.railId, categoryId: action.categoryId, priority: computedPriority },
        ],
        enableSave: true,
      };

    case CLEAR_EDITOR:
      return {
        ...state,
        editorName: "",
        enableClone: false,
        enableDelete: false,
        enableRename: false,
        enableSave: false,
        loaded: false,
        loaderOpen: false,
        loaderMessage: null,
        selectedSystem: {},
        selectedConfig: {},
        editorAssignments: [],
      };

    case CLONE_CONFIG_SUCCESS:
      return {
        ...state,
        configs: { ...state.configs, [action.data.id]: action.data },
        selectedConfig: action.data,
        editorAssignments: action.data.assignments,
        editorName: action.data.name,
        enableClone: true,
        enableDelete: true,
        enableRename: true,
        enableSave: false,
        loaderOpen: false,
      };

    case CREATE_SUCCESS:
      return {
        ...state,
        configs: { ...state.configs, [action.data.id]: action.data },
        selectedConfig: action.data,
        editorAssignments: action.data.assignments,
        editorName: action.data.name,
        enableClone: true,
        enableDelete: true,
        enableRename: true,
        enableSave: false,
        loaderOpen: false,
      };

    case DELETE_SUCCESS:
      return {
        ...state,
        configs: _.omit(state.configs, action.id),
        editorAssignments: [],
        editorName: "",
        enableClone: false,
        enableDelete: false,
        enableRename: false,
        enableSave: false,
        loaderOpen: false,
        selectedConfig: {},
      };

    case DELETE_ERROR:
      return { ...state, loaderOpen: false };

    case LOAD_CONFIGS_REQUEST:
      return { ...state, selectedSystem: action.initialSystem, loaded: false };

    case LOAD_CONFIGS_SUCCESS:
      return {
        ...state,
        configs: action.configs,
        selectedConfig: {},
        editorAssignments: [],
        editorName: "",
        loaded: true,
      };

    case LOADER_SHOW:
      return {
        ...state,
        loaderOpen: true,
        loaderMessage: action.message,
      };

    case LOADER_HIDE:
      return {
        ...state,
        loaderOpen: false,
      };

    case MAPPING_TOGGLE:
      return {
        ...state,
        mappingOpen: !state.mappingOpen,
      };

    case RENAME_CONFIG:
      return {
        ...state,
        editorName: action.name,
        enableSave: true,
      };

    case REMOVE_ASSIGNMENT:
      const [ours, others] = _.partition(state.editorAssignments, (x) => x.categoryId === action.categoryId);
      const [[me], siblings] = _.partition(ours, (x) => x.railId == action.railId);
      const updatedSiblings = siblings.map((x) => ({
        ...x,
        priority: x.priority > me.priority ? x.priority - 1 : x.priority,
      }));
      return {
        ...state,
        editorAssignments: [...others, ...updatedSiblings],
        enableSave: true,
      };

    case SELECT_CONFIG:
      const selectedConfig = state.configs[action.id];
      return {
        ...state,
        editorAssignments: selectedConfig.assignments,
        editorName: selectedConfig.name,
        enableClone: true,
        enableDelete: true,
        enableRename: true,
        selectedConfig: selectedConfig,
      };

    case UPDATE_SUCCESS:
      return {
        ...state,
        configs: {
          ...state.configs,
          [action.data.id]: action.data,
        },
        editorAssignments: action.data.assignments,
        editorName: action.data.name,
        enableSave: false,
        loaderOpen: false,
        selectedConfig: action.data,
      };

    default:
      return state;
  }
}
