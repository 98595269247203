import React from "react";
import _ from "lodash";
import { Box, Select, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import FormFieldLabel from "../SetupPages/FormFieldLabel";

const FormConfiguredUnitList = ({ currentValues, configuredUnitList, disabled, onFieldChange }) => {
  const { t } = useTranslation();
  return (
    <Box display="flex" flexDirection="column">
      <FormFieldLabel label={t("productionPointsSetup:configured_unit")} />

      <Select
        variant="outlined"
        displayEmpty={true}
        value={currentValues["configuredUnit"] || ""}
        disabled={disabled}
        onChange={(e) => onFieldChange("configuredUnit", e.target.value)}
      >
        <MenuItem value="">
          <em>{t("common:none")}</em>
        </MenuItem>
        {configuredUnitList.map((configuredUnit) => (
          <MenuItem key={configuredUnit[0]} value={configuredUnit[0]} disabled={disabled}>
            {t(`productionPointsSetup:${_.snakeCase(configuredUnit[1])}`)}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default FormConfiguredUnitList;
