import React from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import { Avatar } from "@mui/material";
import { parseLabel } from "../utils";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    width: 200,
    borderWidth: (props) => (props.highlight ? "3px" : "1px"),
    borderStyle: (props) => (props.border ? "solid" : "none"),
    borderColor: (props) => (props.highlight ? theme.palette.info.light : theme.palette.grey[400]),
    borderRadius: 10,
    padding: 5,
  },
  numberAndImage: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: 5,
    borderBottom: `2px solid ${theme.palette.info["main"]}`,
    marginBottom: 5,
  },
  number: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
  },
  name: {
    minHeight: theme.typography.body2.fontSize,
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
  },
  label: {
    color: (props) => theme.palette.getContrastText("#167adf"),
    backgroundColor: (props) => "#167adf",
  },
}));

const UserCard = ({ name, employeeNumber, imageUrl, roleName, onClick, highlight, border }) => {
  const classes = useStyles({ highlight, border });

  return (
    <div className={classes.main} onClick={onClick}>
      <div className={classes.numberAndImage}>
        <Typography className={classes.number}>{employeeNumber}</Typography>

        <Avatar variant="rounded" src={imageUrl} className={classes.label}>
          {parseLabel(name)}
        </Avatar>
      </div>

      <Typography className={classes.text}>{name}</Typography>
      <Typography className={classes.text}>{roleName}</Typography>
    </div>
  );
};

export default UserCard;
