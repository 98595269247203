import { useEffect, useState } from "react";

export const functionKeyRegex = new RegExp(/^F\d+$/i);

const useFunctionKeys = (): [string, number] => {
  const [key, setKey] = useState("");
  const [count, setCount] = useState(0);

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (functionKeyRegex.test(event.key)) {
        setKey(event.key);
        setCount((count) => count + 1)
      };
    };

    window.addEventListener("keydown", keyDownHandler);
    return () => window.removeEventListener("keydown", keyDownHandler);
  }, []);

  return [key || "", count];
};

export default useFunctionKeys;
