import _ from "lodash";
import store from "../store";
import i18n from "../i18n";
import { showAlertSuccess, showAlertError } from "../actions/alertActions";
import Client from "./Client.js";

export const updateSis = ({ sisId, data }) => {
  Client.put(`control_api/v1/sling_input_stations/${sisId}`, data)
    .then((response) => {
      store.dispatch(showAlertSuccess(i18n.t("common:success")));
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const editWeightForSis = ({ sisId, weight, position }) => {
  const attribute = position === 1 ? "weight1" : "weight2";
  Client.post(`control_api/v1/sling_input_stations/${sisId}/edit_weight`, {
    [attribute]: weight,
  })
    .then((response) => {
      store.dispatch(showAlertSuccess(i18n.t("common:success")));
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const clearWeightsForSis = (sisId) => {
  Client.post(`control_api/v1/sling_input_stations/${sisId}/clear_weights`)
    .then((response) => {
      store.dispatch(showAlertSuccess(i18n.t("common:success")));
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const assignCategoryToSis = ({ sisId, categoryId, position }) => {
  const attribute = position === 1 ? "sortClassification1Id" : "sortClassification2Id";
  Client.post(`control_api/v1/sling_input_stations/${sisId}/assign_sort_classification`, {
    [attribute]: categoryId,
  })
    .then((response) => {
      store.dispatch(showAlertSuccess(i18n.t("common:success")));
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const assignCustomerToSis = ({ sisId, customerId, position }) => {
  const attribute = position === 1 ? "customer1Id" : "customer2Id";
  Client.post(`control_api/v1/sling_input_stations/${sisId}/assign_customer`, {
    [attribute]: customerId,
  })
    .then((response) => {
      store.dispatch(showAlertSuccess(i18n.t("common:success")));
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const assignBarcodeToSis = ({ sisId, barcodeId }) => {
  Client.post(`control_api/v1/sling_input_stations/${sisId}/assign_barcode`, { barcodeId })
    .then((response) => {
      store.dispatch(showAlertSuccess(i18n.t("common:success")));
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const addSlingToSis = ({ sisId: sisId, weight }) => {
  Client.post(`control_api/v1/sling_input_stations/${sisId}/add_sling`, { weight })
    .then((response) => {
      store.dispatch(showAlertSuccess(i18n.t("common:success")));
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const deleteSlingFromSis = (sisId) => {
  Client.post(`control_api/v1/sling_input_stations/${sisId}/delete_sling`)
    .then((response) => {
      store.dispatch(showAlertSuccess(i18n.t("common:success")));
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const maintenanceManualSend = (sisId) => {
  Client.post(`control_api/v1/sling_input_stations/${sisId}/manual_send`)
    .then((response) => {
      store.dispatch(showAlertSuccess(i18n.t("common:success")));
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const addBagToMaintLiftSis = ({ sisId, barcodeId, barcodeNotes }) => {
  Client.post(`control_api/v1/sling_input_stations/${sisId}/add_bag_to_maint_lift`, {
    barcodeId,
    barcodeNotes,
  })
    .then((response) => {
      store.dispatch(showAlertSuccess(i18n.t("common:success")));
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const captureWeightSingleFloorScale = ({ sisId, position }) => {
  Client.post(`control_api/v1/sling_input_stations/${sisId}/capture_weight_single_floor_scale`, {
    position,
  })
    .then((response) => {
      store.dispatch(showAlertSuccess(i18n.t("common:success")));
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const captureWeightDoubleFloorScale = ({ sisId, position }) => {
  Client.post(`control_api/v1/sling_input_stations/${sisId}/capture_weight_double_floor_scale`, {
    position,
  })
    .then((response) => {
      store.dispatch(showAlertSuccess(i18n.t("common:success")));
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const performCustomAction = ({ sisId, successMessage }) => {
  Client.get(`control_api/v1/sling_input_stations/${sisId}/custom_action`)
    .then((response) => {
      store.dispatch(showAlertSuccess(successMessage));
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const performDecodeScan = ({ sisId, value }) => {
  Client.get(`control_api/v1/sling_input_stations/${sisId}/decode_scan`, { value })
    .then((response) => response)
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const updateCallEmpty = ({ sisId, callEmpty }) => {
  Client.put(`control_api/v1/sling_input_stations/${sisId}`, { callEmpty })
    .then((response) => {
      store.dispatch(showAlertSuccess(i18n.t("common:success")));
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const fetchMaintenanceReasons = ({ successCB }) => {
  Client.get(`control_api/v1/sling_maintenances`)
    .then((response) => {
      if (successCB) {
        successCB(response.data);
      }
    })
    .catch((error) => {
      console.log("error loading sling maintenances");
    });
};

export const updateConfiguration = ({ sisId, configuration }) => {
  Client.put(`control_api/v1/sling_input_stations/${sisId}/update_configuration`, { configuration })
    .then((response) => store.dispatch(showAlertSuccess(i18n.t("common:success"))))
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`Error: ${errorMessage}`));
    });
};

// TODO: Might reconsider and move this to RailApi since it seems more related to rail
// TODO: Need translations for error
export const sendCallEmpty = ({ sisId, successCB }) => {
  Client.get(`control_api/v1/sling_input_stations/${sisId}/send_call_empty`)
    .then((response) => {
      successCB && successCB(response.data);
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data.error", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const sendLoad = ({ sisId, successCB }) => {
  Client.get(`control_api/v1/sling_input_stations/${sisId}/send_load`)
    .then((response) => {
      successCB && successCB(response.data);
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data.error", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const bagRemoved = ({ sisId, successCB }) => {
  Client.post(`control_api/v1/sling_input_stations/${sisId}/bag_removed`)
    .then((response) => {
      successCB && successCB();
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data.error", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const loadBag = ({ sisId, successCB }) => {
  Client.get(`control_api/v1/sling_input_stations/${sisId}/load_bag`)
    .then((response) => {
      successCB && successCB(response.data);
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data.error", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const submitBatchDetails = ({ sisId, successCB }) => {
  Client.post(`control_api/v1/sling_input_stations/${sisId}/submit_batch_details`)
    .then((response) => {
      successCB && successCB();
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data.error", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const getEmptyOrExit = ({ sisId, successCB }) => {
  Client.get(`control_api/v1/sling_input_stations/${sisId}/get_empty_or_exit`)
    .then((response) => {
      successCB && successCB(response.data);
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data.error", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const submitReEntry = ({ sisId, successCB }) => {
  Client.get(`control_api/v1/sling_input_stations/${sisId}/submit_re_entry`)
    .then((response) => {
      successCB && successCB(response.data);
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data.error", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const returnBag = ({ sisId, successCB }) => {
  Client.get(`control_api/v1/sling_input_stations/${sisId}/return_bag`)
    .then((response) => {
      successCB && successCB(response.data);
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data.error", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const captureWeightForMaintHoist = ({ sisId, successCB }) => {
  return Client.get(`control_api/v1/sling_input_stations/${sisId}/capture_weight_maint_hoist`)
    .then((response) => {
      successCB && successCB(response.data);
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data.error", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const lowerBag = ({ sisId, successCB }) => {
  return Client.get(`control_api/v1/sling_input_stations/${sisId}/lower_bag`)
    .then((response) => {
      successCB && successCB(response.data);
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data.error", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};
