import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import _ from "lodash";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableSortLabel,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { withStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { SETUP_CUSTOMERS } from "../../constants/Paths";
import { useCustomersIds, getCustomerName, getCustomerNumber, useCustomerName } from "../../selectors/customers";
import { useCustomer } from "../../selectors/customers";
import { useCurrentRole } from "../../selectors/useRole";
import store from "../../store";
import SearchField from "../SetupPages/SearchField";
import AddCustomerButton from "./AddCustomerButton";

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.grey[100],
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    padding: theme.spacing(2),
  },
  body: {
    fontSize: 14,
    padding: theme.spacing(2),

    "& .MuiBox-root": {
      display: "flex",
      alignItems: "center",
    },
    "& .MuiAvatar-root": {
      marginRight: theme.spacing(1),
    },
  },
}))(TableCell);

const StyledTableSortLabel = withStyles((theme) => ({
  root: {
    "&$active": {
      color: theme.palette.common.black,
    },
  },
  active: {},
  icon: {
    color: "inherit !important",
  },
}))(TableSortLabel);

const CustomerRow = ({ id, onClick }) => {
  const customer = useCustomer(id) || {};
  const { number, imageUrl, name, ownerId } = customer;
  const ownerCustomerName = useCustomerName(ownerId);
  const displayedCustomerName = customer.id === ownerId ? "" : ownerCustomerName;
  const currentRole = useCurrentRole();

  return (
    <StyledTableRow>
      <StyledTableCell component="th" scope="row">
        <Typography variant="h5">{number}</Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Box>
          <Avatar src={imageUrl}></Avatar>
          <Typography variant="h5">{name}</Typography>
        </Box>
      </StyledTableCell>
      <StyledTableCell>{displayedCustomerName}</StyledTableCell>
      <StyledTableCell align="right">
        <IconButton onClick={() => onClick(id)}>
          {currentRole.canEditMasterData ? <EditIcon /> : <VisibilityIcon />}
        </IconButton>
      </StyledTableCell>
    </StyledTableRow>
  );
};

const filterIds = ({ ids, searchTerm, state }) => {
  return ids.filter((id) => {
    if (!searchTerm) return true;
    const number = getCustomerNumber(state, id).toString();
    const name = _.toLower(getCustomerName(state, id));
    const passedNumber = _.includes(number, searchTerm?.toLowerCase());
    const passedName = _.includes(name, searchTerm?.toLowerCase());

    return passedNumber || passedName;
  });
};

const sortIds = ({ ids, orderBy, order, state }) => {
  if (ids.length < 1) return [];
  return ids.sort((a, b) => {
    const valueA = orderBy === "name" ? getCustomerName(state, a) : getCustomerNumber(state, a);
    const valueB = orderBy === "name" ? getCustomerName(state, b) : getCustomerNumber(state, b);

    if (order === "asc") {
      if (valueB > valueA) return -1;
      if (valueB < valueA) return 1;
    } else {
      if (valueB < valueA) return -1;
      if (valueB > valueA) return 1;
    }
    return 0;
  });
};

const CustomersList = () => {
  const { t } = useTranslation();
  const ids = useCustomersIds();
  const location = useLocation();

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("number");
  const [searchTerm, setSearchTerm] = useState(location.state?.searchTerm);

  const state = store.getState();
  const filteredCustomerIds = filterIds({ ids, searchTerm, state });
  const sortedCustomerIds = sortIds({ ids: filteredCustomerIds, orderBy, order, state });

  const handleOrderBy = (selected) => {
    if (selected === orderBy) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      setOrder("asc");
      setOrderBy(selected);
    }
  };

  const list = useRef();
  useEffect(() => {
    const scrollPos = location.state?.listScrollPos;
    if (scrollPos) {
      list.current.scrollTop = scrollPos;
    }
  }, [location.state?.listScrollPos]);

  const history = useHistory();
  const handleOnClick = (id) => {
    const newLocation = {
      pathname: `${SETUP_CUSTOMERS}/${id}`,
      state: { listScrollPos: list.current.scrollTop, searchTerm },
    };
    history.push(newLocation);
  };

  return (
    <Paper square={true} elevation={0} sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box padding={3} display="flex" justifyContent="space-between" alignItems="flex-end">
        <Typography variant="h1">{t("customersSetup:customers_setup")}</Typography>

        <Box width={350}>
          <SearchField
            initialValue={location.state?.searchTerm}
            placeholder={t("customersSetup:search_instructions")}
            onSubmitSearchTerm={(searchTerm) => setSearchTerm(searchTerm)}
          />
        </Box>
      </Box>

      <Divider />

      <TableContainer ref={list}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell sortDirection={order}>
                <StyledTableSortLabel
                  active={orderBy === "number"}
                  direction={order}
                  onClick={() => handleOrderBy("number")}
                >
                  {t("common:number")}
                </StyledTableSortLabel>
              </StyledTableCell>
              <StyledTableCell sortDirection={order}>
                <StyledTableSortLabel
                  active={orderBy === "name"}
                  direction={order}
                  onClick={() => handleOrderBy("name")}
                >
                  {t("common:name")}
                </StyledTableSortLabel>
              </StyledTableCell>
              <StyledTableCell>{t("customersSetup:owner")}</StyledTableCell>
              <StyledTableCell align="right">
                <AddCustomerButton size="small" />
              </StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {sortedCustomerIds.map((id) => (
              <CustomerRow key={id} id={id} onClick={() => handleOnClick(id)} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default CustomersList;
