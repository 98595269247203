import store from "../store";
import { getBatchIdsWithActiveRVs } from "./railVisits";
import { getBatchIdsWithActiveMVs } from "./machineVisits";
import { useSelector, shallowEqual } from "react-redux";

const formatBatch = (batch = {}) => {
  /**
   * Batch interface
   */
  return {
    barcodeId: batch.barcodeId,
    categoryId: batch.categoryId,
    customerId: batch.customerId,
    id: batch.id,
    tag: batch.tag,
    serial: batch.serial,
    weight: batch.weight,
    createdAt: batch.createdAt,
    batch: batch.batch,
    batchStart: batch.batchStart,
  };
};

export const useBatch = (batchId) => useSelector((state) => formatBatch(state.batches[batchId] || {}), shallowEqual);

export const getBatchById = (batchId, state) => state.batches[batchId];

export const getBatches = (receivedState) => {
  const state = receivedState || store.getState();
  if (state.batches) {
    const formattedBatches = {};
    Object.values(state.batches).forEach((batch) => {
      formattedBatches[batch.id] = formatBatch(batch);
    });

    return formattedBatches;
  } else {
    return {};
  }
};

export const getActiveBatchIds = (receivedState) => {
  const activeBatchIdsForRVs = getBatchIdsWithActiveRVs(receivedState);
  const activeBatchIdsForMVs = getBatchIdsWithActiveMVs(receivedState);
  return activeBatchIdsForRVs.concat(activeBatchIdsForMVs);
};

export const useFrontBatchForRailId = (railId) =>
  useSelector((state) => {
    const visit = state.railVisits[railId][0] || {};
    const batch = state.batches[visit.batchId] || {};
    return formatBatch(batch);
  }, shallowEqual);
