import React from "react";
import { Avatar, Chip, useTheme } from "@mui/material";
import { shallowEqual, useSelector } from "react-redux";

const CustomerChip = ({ customerId }) => {
  const customer = useSelector((state) => state.customers[customerId], shallowEqual);
  const theme = useTheme();

  return (
    <Chip
      key={`customer-${customer?.id}`}
      avatar={
        <Avatar
          style={{
            fontSize: "0.4rem",
            backgroundColor: customer?.color,
            color: theme.palette.getContrastText(customer?.color ?? "#dbdbdb"),
          }}
        >
          {customer?.number}
        </Avatar>
      }
      label={customer?.name}
    />
  );
};

export default CustomerChip;
