import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import CategoryIcon from "@mui/icons-material/Category";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useCurrentRole } from "../../../../selectors/useRole";
import FlagCardSlim from "../../../../components/FlagSelectors/FlagCardSlim";
import type { StorageRuleDTO } from "../../dto/StorageRuleDTO";
import CategoryDataWrapper from "../../dataWrappers/CategoryDataWrapper";

interface Props {
  rules: StorageRuleDTO[];
  selectedRules?: number[];
  addButton?: JSX.Element;
  onSelectRule?: (id: number) => void;
};

const CategoriesRules = ({ rules, selectedRules = [], addButton, onSelectRule }: Props) => {
  const { t } = useTranslation();
  const currentRole = useCurrentRole();

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Typography variant="overline" color="secondary" sx={{ mb: addButton ? 0 : 1 }}>
          {t("common:categories")}
        </Typography>

        {addButton}
      </Box>

      <Grid container spacing={1}>
        {rules?.map((rule) => {
          return rule.categoryId ? (
            <Grid item key={`categoryIds-${rule.categoryId}`}>
              <CategoryDataWrapper categoryId={rule.categoryId}>
                {(category) => (
                  <FlagCardSlim
                    imageUrl={category.imageUrl}
                    imageIcon={<CategoryIcon />}
                    color={category.color}
                    number={category.number}
                    name={category.name}
                    disabled={!currentRole.canOperatePicklists}
                    border={true}
                    highlight={rule?.id && selectedRules?.includes(rule.id)}
                    onClick={() => onSelectRule && rule?.id && onSelectRule(rule.id)}
                  />
                )}
              </CategoryDataWrapper>
            </Grid>
          ) : undefined;
        })}
      </Grid>
    </Box>
  )
};

export default CategoriesRules;
