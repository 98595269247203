import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { useTranslation } from "react-i18next";
import { useShowExternal } from "../../selectors/uiSettings";
import FormNumberField from "../SetupPages/FormNumberField";
import ImageUploadButton from "../SetupPages/ImageUploadButton";
import SwatchColorInput from "../SetupPages/SwatchColorInput";
import FormFieldLabel from "../SetupPages/FormFieldLabel";
import FormTextField from "../SetupPages/FormTextField";

const DescriptionGroup = ({ currentValues, disabled, errors, setFieldValue }) => {
  const { t } = useTranslation();
  const showExternal = useShowExternal();
  const defaultCustomer = currentValues?.number === 0;

  return (
    <>
      <Grid item sx={{ mb: 1 }}>
        <Typography variant="h4">{t("common:description")}</Typography>
      </Grid>

      <Grid container direction="column">
        <Grid item container sx={{ mt: 1 }} alignItems="end">
          <Grid item xs={4}></Grid>

          <Grid item xs={4} sx={{ display: "flex", justifyContent: "center" }}>
            <ImageUploadButton
              defaultIcon={<ApartmentIcon sx={{ fontSize: 80 }} />}
              imageUrl={currentValues["imageUrl"]}
              disabled={disabled}
              onUpload={(imageUrl) => setFieldValue("imageUrl", imageUrl)}
            />
          </Grid>

          <Grid item xs={4} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Box>
              <FormFieldLabel label={t("common:color")} />
              <SwatchColorInput
                selectedColor={currentValues["color"] || ""}
                disabled={disabled}
                onChange={(color) => setFieldValue("color", color)}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid item>
          <FormTextField
            fieldName="name"
            label={t("common:name")}
            currentValues={currentValues}
            errors={errors}
            disabled={disabled}
            onFieldChange={setFieldValue}
          />
        </Grid>

        <Grid item>
          <FormNumberField
            fieldName="number"
            label={t("common:number")}
            currentValues={currentValues}
            errors={errors}
            disabled={disabled || defaultCustomer}
            onFieldChange={setFieldValue}
          />
        </Grid>

        {showExternal && (
          <Grid item>
            <FormTextField
              fieldName="externalReference"
              label={t("common:external_reference")}
              currentValues={currentValues}
              errors={errors}
              disabled={disabled}
              onFieldChange={setFieldValue}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default DescriptionGroup;
