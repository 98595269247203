import React from "react";
import { useTranslation } from "react-i18next";
import { Button, FormControlLabel } from "@mui/material";
import LeftArrowIcon from "@mui/icons-material/ArrowBack";
import RightArrowIcon from "@mui/icons-material/ArrowForward";
import { useCurrentRole } from "../../selectors/useRole";

const ConciseCaptureLCWeightButton = ({ arrowDirection = "left", sx, onClick }) => {
  const { t } = useTranslation();
  const { canControlInputLifts } = useCurrentRole();

  return (
    <FormControlLabel
      sx={sx}
      control={
        <Button
          sx={{
            borderRadius: "50%",
            padding: 2,
            fontSize: 40,
            "& #start-icon": {
              fontSize: 40,
            },
          }}
          size="large"
          disableElevation={true}
          variant="contained"
          disabled={!canControlInputLifts}
          onClick={onClick}
        >
          {arrowDirection === "left" ? <LeftArrowIcon id="start-icon" /> : <RightArrowIcon id="start-icon" />}
        </Button>
      }
      label={t("slingInputStation:capture_weight")}
      labelPlacement="top"
    />
  );
};

export default ConciseCaptureLCWeightButton;
