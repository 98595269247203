import React from "react";
import { Backdrop, Box, CardContent, CircularProgress, Divider, Grid, Paper, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useShowBarcodeSlingType } from "../../selectors/uiSettings";
import { useBarcodeFromId } from "../../selectors/barcodes";
import { useCurrentRole } from "../../selectors/useRole";
import { SETUP_BAGS } from "../../constants/Paths";
import { addBarcode, updateBarcode, deleteBarcode } from "../../api/BarcodeApi";
import i18n from "../../i18n";
import FormEditor from "../SetupPages/FormEditor";
import FormTextField from "../SetupPages/FormTextField";
import FormNumberField from "../SetupPages/FormNumberField";
import FormCheckbox from "../SetupPages/FormCheckbox";
import SaveButton from "../SaveButton";
import DeleteButton from "../DeleteButton";
import FormFieldLabel from "../SetupPages/FormFieldLabel";
import FormSystemList from "./FormSystemList";
import BagsListButton from "./BagsListButton";
import SlingTypeSelector from "./SlingTypeSelector";

const StyledGridItem = withStyles((theme) => ({
  root: {
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}))(Grid);

const performErrorChecks = (valuesForSubmission, showBarcodeSlingType) => {
  let errors = {};
  if (showBarcodeSlingType && !valuesForSubmission.slingTypeId) {
    errors["slingTypeId"] = [i18n.t("common:make_a_selection")];
  }

  return errors;
};

const SetupForm = ({ barcode, onSubmitDone }) => {
  const { t } = useTranslation();
  const currentRole = useCurrentRole();
  const isNew = !barcode.id;
  const showBarcodeSlingType = useShowBarcodeSlingType();

  return (
    <FormEditor
      originalValues={{
        slingNumber: isNew ? "" : barcode.slingNumber,
        barcodeNumber: isNew ? "" : barcode.barcodeNumber,
        slingTypeId: isNew ? "" : barcode.slingTypeId,
        systemId: isNew ? "" : barcode.systemId,
        notes: isNew ? "" : barcode.notes,
        manualMaintenanceDue: isNew ? false : barcode.manualMaintenanceDue,
      }}
      onSubmit={({ currentValues, actions }) => {
        const valuesForSubmission = Object.keys(currentValues).reduce((acc, key) => {
          return { ...acc, [key]: currentValues[key] === "" ? null : currentValues[key] };
        }, {});

        const precheckErrors = performErrorChecks(valuesForSubmission, showBarcodeSlingType);
        if (Object.keys(precheckErrors).length > 0) {
          actions.finishSubmit();
          actions.catchErrors(precheckErrors);
        } else {
          barcode.id
            ? updateBarcode({
                barcodeId: barcode.id,
                successCB: () => {
                  actions.finishSubmit();
                  onSubmitDone();
                },
                failureCB: (errors) => {
                  actions.finishSubmit();
                  actions.catchErrors(errors);
                },
                ...valuesForSubmission,
              })
            : addBarcode({
                successCB: () => {
                  actions.finishSubmit();
                  onSubmitDone();
                },
                failureCB: (errors) => {
                  actions.finishSubmit();
                  actions.catchErrors(errors);
                },
                ...valuesForSubmission,
              });
        }
      }}
      onDelete={({ actions }) => {
        deleteBarcode({
          barcodeId: barcode.id,
          successCB: () => onSubmitDone(),
          failureCB: () => actions.finishDelete(),
        });
      }}
    >
      {(props) => {
        const {
          currentValues,
          dirtyForm,
          errors,
          isSubmitting,
          isDeleting,
          setFieldValue,
          handleSubmit,
          handleDelete,
        } = props;

        return (
          <>
            <CardContent sx={{ height: "100%", overflow: "auto" }}>
              <Grid container>
                <StyledBackdrop open={isSubmitting || isDeleting}>
                  <CircularProgress color="inherit" />
                </StyledBackdrop>

                <Grid flexDirection="column" sx={{ pr: 7, width: "50%" }}>
                  <Grid item sx={{ mb: 1 }}>
                    <Typography variant="h4">{t("common:description")}</Typography>
                  </Grid>

                  <StyledGridItem>
                    <FormNumberField
                      fieldName="slingNumber"
                      label={t("common:bag_number")}
                      currentValues={currentValues}
                      errors={errors}
                      disabled={!currentRole.canEditBarcodes}
                      onFieldChange={setFieldValue}
                    />
                  </StyledGridItem>

                  <StyledGridItem>
                    <FormTextField
                      fieldName="barcodeNumber"
                      label={t("common:barcode_number")}
                      currentValues={currentValues}
                      errors={errors}
                      disabled={!currentRole.canEditBarcodes}
                      onFieldChange={setFieldValue}
                    />
                  </StyledGridItem>

                  {showBarcodeSlingType && (
                    <StyledGridItem>
                      <FormFieldLabel label={t("common:sling_type")} />
                      <SlingTypeSelector
                        error={errors["slingTypeId"]}
                        selectedST={currentValues["slingTypeId"]}
                        disabled={!currentRole.canEditBarcodes}
                        onChange={(stId) => setFieldValue("slingTypeId", stId)}
                      />
                    </StyledGridItem>
                  )}

                  <StyledGridItem>
                    <FormSystemList
                      currentValues={currentValues}
                      disabled={!currentRole.canEditBarcodes}
                      onFieldChange={setFieldValue}
                    />
                  </StyledGridItem>
                </Grid>

                <Grid flexDirection="column" sx={{ width: "50%" }}>
                  <Grid item>
                    <Typography variant="h4">{t("common:other")}</Typography>
                  </Grid>

                  <StyledGridItem>
                    <FormTextField
                      fieldName="notes"
                      label={t("common:notes")}
                      currentValues={currentValues}
                      errors={errors}
                      disabled={!currentRole.canEditBarcodes}
                      multiline={true}
                      minRows={4}
                      maxRows={8}
                      onFieldChange={setFieldValue}
                    />
                  </StyledGridItem>

                  <StyledGridItem>
                    <FormCheckbox
                      fieldName="manualMaintenanceDue"
                      label={t("bagsSetup:manual_maintenance_due")}
                      currentValues={currentValues}
                      disabled={!currentRole.canEditBarcodes}
                      onFieldChange={setFieldValue}
                    />
                  </StyledGridItem>
                </Grid>
              </Grid>
            </CardContent>

            <Divider />

            <Grid item container justifyContent="flex-end" sx={{ padding: 3 }}>
              {barcode?.id && (
                <Box mr={1}>
                  <DeleteButton disabled={isSubmitting || !currentRole.canEditBarcodes} onClick={handleDelete}>
                    {t("common:delete")}
                  </DeleteButton>
                </Box>
              )}

              <SaveButton disabled={isSubmitting || !currentRole.canEditBarcodes || !dirtyForm} onClick={handleSubmit}>
                {t("common:submit")}
              </SaveButton>
            </Grid>
          </>
        );
      }}
    </FormEditor>
  );
};

const StyledBackdrop = withStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}))(Backdrop);

const BagEdit = () => {
  const { t } = useTranslation();
  const params = useParams();
  const history = useHistory();
  const barcode = useBarcodeFromId(params.id);
  const currentRole = useCurrentRole();

  let heading = currentRole.canEditBarcodes ? "edit_bag" : "view_bag";
  const isNew = params.id === "new";
  if (isNew) {
    heading = "add_bag";
  }

  const location = useLocation();
  const handleGoToList = () => {
    const newLocation = {
      pathname: SETUP_BAGS,
      state: { listScrollPos: location.state?.listScrollPos, searchTerm: location.state?.searchTerm },
    };

    history.push(newLocation);
  };

  return (
    <Paper square={true} elevation={0} sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box padding={3} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h1">{t(`bagsSetup:${heading}`)}</Typography>
        <BagsListButton onClick={handleGoToList} />
      </Box>

      <Divider />

      {barcode ? (
        <SetupForm barcode={barcode} onSubmitDone={handleGoToList} />
      ) : (
        <StyledBackdrop open={true}>
          <CircularProgress color="inherit" />
        </StyledBackdrop>
      )}
    </Paper>
  );
};

export default BagEdit;
