import React from "react";
import { Box, InputAdornment, SxProps, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useWeightUnit } from "../../../selectors/uiSettings";
import { SlingInputStation } from "../../../dto/eVue/SlingInputStation";
import useGoodsWeight from "../hooks/useGoodsWeight";

interface Props {
  sis: SlingInputStation
  label?: string;
  sx?: SxProps;
};

const GoodsWeight = ({ sis, label, sx }: Props) => {
  const { t } = useTranslation();
  const goodsWeight = useGoodsWeight(sis);
  const weightUnit = useWeightUnit();
  return (
    <Box display="flex" flexDirection="column" sx={sx}>
      <Typography>{label || t("slingInputStation:goods_weight")}</Typography>
      <TextField
        sx={{
          maxWidth: 145,
        }}
        type="number"
        variant="outlined"
        value={goodsWeight || 0}
        size="small"
        InputLabelProps={{ shrink: true }}
        InputProps={{
          sx: {
            fontSize: 40,
            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
              display: "none",
            },
          },
          readOnly: true,
          endAdornment: <InputAdornment position="end">{weightUnit}</InputAdornment>,
        }}
      />
    </Box>
  );
};

export default GoodsWeight;
