import React from "react";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Avatar, Badge, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { toggleAlarmTray } from "../../features/overview/overviewSlice";
import { useDispatch } from "react-redux";
import { useAlarmCount } from "../../hooks/useAlarmCount";

const useStyles = makeStyles((theme) => ({
  appbar: {
    backgroundColor: "#313131",
  },
  avatar: {
    color: "#ffffff",
    backgroundColor: "#bdbdbd",
  },
  button: {
    minWidth: 64,
  },
  buttonMobile: {
    minWidth: 64,
  },
  icon: {
    fontSize: 30,
  },
  [theme.breakpoints.down("sm")]: {
    avatar: {
      width: 32,
      height: 32,
    },
    button: {
      minWidth: 0,
    },
    buttonMobile: {
      minWidth: 0,
    },
    icon: {
      fontSize: 22,
    },
  },
}));

const AlarmsIcon = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const alarmCount = useAlarmCount();
  const handleOpenAlarmTray = () => dispatch(toggleAlarmTray());

  return (
    <Button onClick={handleOpenAlarmTray} className={classes.buttonMobile}>
      <Badge badgeContent={alarmCount} color="error">
        <Avatar className={classes.avatar}>
          <NotificationsIcon className={classes.icon} />
        </Avatar>
      </Badge>
    </Button>
  );
};

export default AlarmsIcon;
