import { useSelector, shallowEqual } from "react-redux";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { toCamelCase } from "../../utils";
import store from "../../store";

export const fetchDeviceTypes = createAsyncThunk("devices/fetchDeviceTypes", async (arg, thunkAPI) => {
  const url = `/control_api/v1/devices/fetch_device_types`;
  const response = await fetch(url);
  const data = await response.json();
  const { dataLoaded } = deviceTypeSlice.actions;
  return thunkAPI.dispatch(dataLoaded(toCamelCase(data)));
});

const convertToObject = (data = []) =>
  data.reduce((acc, value) => {
    acc[value[0]] = value[1];
    return acc;
  }, {});

const deviceTypeSlice = createSlice({
  name: "device_types",
  initialState: {
    deviceTypes: {},
    status: "idle",
    error: null,
  },
  reducers: {
    dataLoaded: (state, action) => {
      state.deviceTypes = convertToObject(action.payload.deviceTypes || []);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchDeviceTypes.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchDeviceTypes.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(fetchDeviceTypes.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default deviceTypeSlice.reducer;

// SELECTORS
export const useDeviceTypes = () => useSelector((state) => state.deviceTypes.deviceTypes, shallowEqual);

const getDeviceTypes = (state) => state.deviceTypes.deviceTypes;

export const useDeviceTypeName = (deviceTypeId) => useSelector((state) => getDeviceTypes(state)[deviceTypeId]);

export const getDeviceTypeName = (state = store.getState(), deviceTypeId) => state.deviceTypes[deviceTypeId];
