import React from "react";
import { Button, Card, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { toggleMapping } from "../../redux/storageConfigEditor";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import CheckIcon from "@mui/icons-material/CheckCircleOutlineSharp";

const useStyles = makeStyles((theme) => ({
  titleBar: {
    display: "flex",
    flex: "0 0 auto",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  button: {
    maxWidth: 200,
    backgroundColor: theme.palette.success["main"],
    color: theme.palette.white,
    "&:hover": {
      backgroundColor: theme.palette.success.light,
    },
  },
}));

const TitleBarMapping = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();

  const handleClick = () => {
    dispatch(toggleMapping());
  };

  return (
    <Card className={classes.titleBar}>
      <Typography variant="h1" gutterBottom>
        {t("storageAssignments:storage_assignments")}
      </Typography>

      <Button className={classes.button} variant="outlined" startIcon={<CheckIcon />} onClick={handleClick}>
        {t("common:done")}
      </Button>
    </Card>
  );
};

export default TitleBarMapping;
