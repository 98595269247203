import { createSlice } from "@reduxjs/toolkit";
import { socket } from "../../utils/channel";
import { shallowEqual, useSelector } from "react-redux";
import _ from "lodash";
import { useMetric } from "../../selectors/uiSettings";

const SLXSlice = createSlice({
  name: "SLX",
  initialState: {
    areas: [],
    producers: [],
    producerViews: [],
  },
  reducers: {
    dataUpdate: (state, action) => {
      return { ...action.payload.data };
    },
  },
});

export function slxSubscribe() {
  const slxChannel = socket.channel("SLX");
  return (dispatch) => {
    slxChannel
      .join(30000)
      .receive("ok", (messages) => {
        // dispatch(SLXSlice.actions.initLocations(messages));
      })
      .receive("error", (reason) =>
        dispatch({
          type: "CHANNEL_JOIN_ERROR",
          channel: "SLX",
        })
      );

    slxChannel.on("producers_update", (msg) => {
      dispatch(SLXSlice.actions.dataUpdate(msg));
    });
  };
}

// Selectors
export const useTestAreas = () => useSelector((state) => state.slx, _.isEqual);

export const useSLXAreas = () => useSelector((state) => state.slx.areas, _.isEqual);

export const useDurations = (producerViewId) =>
  useSelector((state) => state.slx.producerViews.find((x) => x.id === producerViewId)?.durations || {}, shallowEqual);

export const usePerformance = (producerViewId) =>
  useSelector((state) => state.slx.producerViews.find((x) => x.id === producerViewId)?.performance || {}, shallowEqual);

export const useProducer = (producerId) =>
  useSelector((state) => state.slx.producers.find((x) => x.id === producerId) || {}, _.isEqual);

export const useProducerView = (producerViewId) =>
  useSelector((state) => state.slx.producerViews.find((x) => x.id === producerViewId) || {}, _.isEqual);

export const useMessagesForProducerView = (producerViewId) =>
  useSelector((state) => state.slx.producerViews.find((x) => x.id === producerViewId)?.messages || [], _.isEqual);

export const useFaultingProducerViews = () =>
  useSelector((state) => state.slx.producerViews.filter((x) => x.status === "FAILURE"), _.isEqual);

export const useNormWeight = (weight) => (useMetric() ? weight : weight * 2.205);

export default SLXSlice.reducer;
