import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { withStyles } from "@mui/styles";

const StyledFormControlLabel = withStyles((theme) => ({
  root: {
    "& .MuiTypography-root": {
      ...theme.typography.h6,
    },
  },
}))(FormControlLabel);

const FormCheckbox = (props) => {
  const { fieldName, label, currentValues, disabled, labelPlacement = "end", onFieldChange } = props;
  return (
    <StyledFormControlLabel
      checked={currentValues[fieldName] || false}
      disabled={disabled}
      label={label}
      labelPlacement={labelPlacement}
      control={<Checkbox />}
      onChange={() => onFieldChange(fieldName, !currentValues[fieldName])}
    />
  );
};

export default FormCheckbox;
