import React, { useEffect, useState } from "react";
import { InputAdornment, IconButton, OutlinedInput } from "@mui/material";
import CloseIcon from "@mui/icons-material/CloseSharp";
import SearchIcon from "@mui/icons-material/Search";

const SearchField = ({ initialValue, sx, placeholder, onSubmitSearchTerm, onChange }) => {
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (initialValue !== searchTerm) {
      setSearchTerm(initialValue || "");
    }
  }, []);

  return (
    <OutlinedInput
      sx={sx}
      value={searchTerm || ""}
      placeholder={placeholder}
      fullWidth={true}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          onSubmitSearchTerm(searchTerm);
        }
      }}
      onChange={(e) => {
        const newValue = e.target.value.length < 1 ? null : e.target.value;
        setSearchTerm(newValue);
        if (onChange) onChange(newValue);
      }}
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            disabled={searchTerm?.length < 1}
            onClick={() => {
              setSearchTerm("");
              onSubmitSearchTerm(null);
            }}
          >
            <CloseIcon />
          </IconButton>
        </InputAdornment>
      }
    />
  );
};

export default SearchField;
