import React, { useEffect, useRef } from "react";
import _ from "lodash";
import { makeStyles } from "@mui/styles";
import { Backdrop, Box, Zoom } from "@mui/material";
import { useTranslation } from "react-i18next";
import { generateControlMenu } from "../../selectors/menus";
import { useAppSelector } from "../../hooks/useAppSelector";
import MenuGroup from "./MenuGroup";
import MenuIcon from "./MenuIcon";
import { getSLXUiEnabled, getSLXEnabled, getShowCategoryGroups } from "../../selectors/uiSettings";
import { isTechnicianLoggedIn } from "../../selectors/roles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    alignItems: "flex-start",
    overflow: "auto",
    zIndex: 1900,
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(320px, 1fr))",
    gridGap: ".5rem",
    padding: "100px 0",
    width: "90%",
  },
  [theme.breakpoints.up("xl")]: {
    grid: {
      gridTemplateColumns: "repeat(auto-fit, minmax(320px, 1fr))",
    },
  },
  [theme.breakpoints.down("sm")]: {
    grid: {
      gridTemplateColumns: "repeat(auto-fill, minmax(224px, 1fr))",
    },
  },
}));

const LaunchPad = (props) => {
  const { open, handleClose, ...rest } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const slxEnabled = useAppSelector((state) => getSLXEnabled(state));
  const slxUiEnabled = useAppSelector((state) => getSLXUiEnabled(state));
  const techLoggedIn = useAppSelector((state) => isTechnicianLoggedIn(state));
  const showCategoryGroups = useAppSelector((state) => getShowCategoryGroups(state));
  const controlMenu = useRef(generateControlMenu());
  useEffect(() => {
    controlMenu.current = generateControlMenu();
  }, [slxEnabled, slxUiEnabled, techLoggedIn, showCategoryGroups]);

  if (!controlMenu.current) return null;
  return (
    <Backdrop open={open} onClick={handleClose} className={classes.backdrop}>
      <Zoom in={open}>
        <Box className={classes.grid}>
          {controlMenu.current.map((group) => (
            <MenuGroup key={group.labelKey} title={t(`menus:${group.labelKey}`)} color={group.groupColor}>
              {group.buttons.map((button, i) => {
                const label = button.labelKey ? t(`menus:${button.labelKey}`, button.labelParam || {}) : button.label;
                return (
                  <MenuIcon
                    key={`${label}-${i}`}
                    label={label}
                    color={group.buttonColor}
                    icon={button.icon}
                    linkTo={button.linkTo}
                  />
                );
              })}
            </MenuGroup>
          ))}
        </Box>
      </Zoom>
    </Backdrop>
  );
};

export default LaunchPad;
