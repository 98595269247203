import React from "react";
import _ from "lodash";
import clsx from "clsx";
import { Avatar, Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { addAssignment, removeAssignment } from "../../redux/storageConfigEditor";
import { colors } from "@mui/material";
import { useCurrentRole } from "../../selectors/useRole";
import { useDispatch, useSelector } from "react-redux";
import { useRailLabel } from "../../selectors/rails";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: "0 1 auto",
    flexDirection: "column",
    overflowY: "auto",
  },
  avatar: {
    width: 28,
    height: 28,
    fontSize: 12,
    color: "black",
    border: "2px solid #313131",
    backgroundColor: colors.grey[200],
  },
  priority: {
    color: "white",
    border: "2px solid #090909",
    fontSize: 14,
    fontWeight: 700,
    textRendering: "optimizeLegibility",
    backgroundColor: colors.blue[600],
  },
  priority1: {
    border: "2px solid black",
    backgroundColor: colors.orange[500],
  },
  disabled: {
    opacity: 0.4,
  },
  flex: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  cell: {
    textAlign: "center",
    padding: "6px 2px",
  },
  headerCell: {
    fontWeight: 700,
    textAlign: "center",
  },
  rowHighlight: {
    backgroundColor: "#f6f6f6",
  },
}));

const HeaderCell = ({ railId }) => {
  const classes = useStyles();
  const label = useRailLabel(railId);
  return <TableCell className={classes.headerCell}>{label}</TableCell>;
};

const GridCell = ({ railId, disabled, priority, categoryId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const railLabel = useRailLabel(railId);
  const label = priority ? priority : railLabel;
  const apiCall = priority ? removeAssignment : addAssignment;

  return (
    <TableCell className={classes.cell}>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Avatar
          className={clsx({
            [classes.avatar]: true,
            [classes.priority]: priority,
            [classes.priority1]: priority === 1,
            [classes.disabled]: disabled,
          })}
          onClick={() => !disabled && dispatch(apiCall(categoryId, railId))}
        >
          {label}
        </Avatar>
      </Box>
    </TableCell>
  );
};

const AssignmentGrid = () => {
  const { selectedConfig, selectedSystem, editorAssignments } = useSelector(
    (state) => state.storageConfigEdit,
    _.isEqual
  );
  const classes = useStyles();
  const categories = useSelector((state) => Object.values(state.categories), _.isEqual);
  const role = useCurrentRole();

  const indexedAssignments = editorAssignments.reduce((acc, x) => {
    acc[`${x.categoryId}:${x.railId}`] = x.priority;
    return acc;
  }, {});

  if (!selectedConfig.id) return null;

  return (
    <TableContainer component={Paper} className={classes.root}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {selectedSystem.storageRailIds.map((railId) => (
              <HeaderCell key={railId} railId={railId} />
            ))}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {_.sortBy(categories, (cat) => cat.number).map((cat, idx) => (
            <TableRow
              key={cat.id}
              className={clsx({
                [classes.rowHighlight]: idx % 3 === 0,
              })}
            >
              <TableCell>{`${cat.number} - ${cat.name.substring(0, 20)}`}</TableCell>
              {selectedSystem.storageRailIds.map((railId) => {
                const priority = indexedAssignments[`${cat.id}:${railId}`];

                return (
                  <GridCell
                    key={railId}
                    railId={railId}
                    disabled={!role.canEditStorageConfigs}
                    priority={priority}
                    categoryId={cat.id}
                  />
                );
              })}
              <TableCell>{`${cat.number} - ${cat.name.substring(0, 20)}`}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AssignmentGrid;
