import React from "react";
import FormFieldGroupLabel from "../SetupPages/FormFieldGroupLabel";
import FormCheckbox from "../SetupPages/FormCheckbox";
import { useTranslation } from "react-i18next";

const FormAreaAlertCheckboxes = ({ currentValues, disabled, onFieldChange }) => {
  const { t } = useTranslation();
  return (
    <>
      <FormFieldGroupLabel label={t("usersSetup:area_alerts")} />

      {[
        ["sorting_rail_alert", "sortingRailAlert"],
        ["washing_rail_alert", "washingRailAlert"],
        ["finishing_rail_alert", "finishingRailAlert"],
      ].map((alert) => (
        <FormCheckbox
          key={alert[1]}
          fieldName={alert[1]}
          label={t(`usersSetup:${alert[0]}`)}
          currentValues={currentValues}
          disabled={disabled}
          onFieldChange={(e) => onFieldChange(alert[1], !currentValues[alert[1]])}
        />
      ))}
    </>
  );
};

export default FormAreaAlertCheckboxes;
