import axios from "axios";
import { toCamelCase, toSnakeCase } from "../utils";
import qs from "qs";

export default class Client {
  static __resource(url) {
    // TODO: Need a solution for the 3001/3000 thing
    // const eVueHost = window.location.host.replace(/:3001/gi, ":3000");
    // return `${window.location.protocol}//${eVueHost}/${url}`;
    return `/${url}`;
  }

  static get(url, data, successCB, catchCB) {
    return Client.__perform("get", url, data, successCB, catchCB);
  }

  static post(url, data, successCB, catchCB) {
    return Client.__perform("post", url, data, successCB, catchCB);
  }

  static put(url, data, successCB, catchCB) {
    return Client.__perform("put", url, data, successCB, catchCB);
  }

  static delete(url, data, successCB, catchCB) {
    return Client.__perform("delete", url, data, successCB, catchCB);
  }

  static __getCSRFToken(name) {
    let cookieValue = null;

    if (document.cookie && document.cookie !== "") {
      let cookies = document.cookie.split(";");

      for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i].trim();
        // Does this cookie string begin with the name we want?

        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }

    return cookieValue;
  }

  static __perform(method, url, data, successCB, catchCB) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-CSRFToken": Client.__getCSRFToken("csrftoken"),
    };

    let params = null;

    if (method.toLowerCase() === "get" || method.toLowerCase() === "delete") {
      // If a GET or DELETE request
      // These request types use 'params' not 'data'
      params = { ...data };
      Object.freeze(params);
      data = null;
    }

    // You can intercept requests or responses before they are handled by then or catch.
    // https://github.com/axios/axios#interceptors

    // Add a response interceptor and camelCase return data (for JS)
    axios.interceptors.response.use(
      (response) => {
        response.data = toCamelCase(response.data);

        return response;
      },
      (error) => {
        error.response.data = toCamelCase(error.response.data);

        return Promise.reject(error);
      }
    );

    // Add a request interceptor and snakeCase POST data (for django)
    axios.interceptors.request.use(
      (config) => {
        config.params = toSnakeCase(config.params);
        config.data = toSnakeCase(config.data);

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    const response = axios({
      method: method,
      withCredentials: true,
      url: Client.__resource(url),
      headers: headers,
      params: params, // GET and DELETE requests have params
      // remove array params bracket
      paramsSerializer: (params) => {
        return qs.stringify(params, { indices: false });
      },
      data: data, // POST and PUT requests have data
    });

    if (successCB || catchCB) {
      return response.then(successCB).catch(catchCB);
    }

    return response;
  }
}
