import React from "react";
import { Box, TextField } from "@mui/material";
import FormFieldLabel from "../../../../components/SetupPages/FormFieldLabel";

interface Props {
  label: string;
  initialValue: string;
  disabled: boolean;
  errorMessage?: string;
  onChange: (selection: string) => void;
};

const FormTextInput = ({ label, initialValue, disabled, errorMessage, onChange }: Props) => {
  return (
    <Box display="flex" flexDirection="column">
      <FormFieldLabel label={label} />
      <TextField
        defaultValue={initialValue}
        margin="none"
        variant="outlined"
        error={Boolean(errorMessage)}
        helperText={errorMessage}
        disabled={disabled}
        InputLabelProps={{ shrink: true }}
        onChange={(e) => onChange(e.target.value)}
      />
    </Box>
  );
};

export default FormTextInput;
