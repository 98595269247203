import * as actions from "../actions/types";

const initialState = {};

export default function machineVisitReducer(state = initialState, action) {
  switch (action.type) {
    case actions.INITIALIZE_MACHINE_VISITS:
      return action.machineVisits;
    case actions.UPDATE_MACHINE_VISITS:
      return { ...state, ...action.data };
    default:
      return state;
  }
}
