import PropTypes from "prop-types";
import React from "react";

// material-ui
import { Card, CardHeader, CardMedia, Divider, Typography } from "@mui/material";

// project imports
import StatusIcon from "./StatusIcon";
import { useProducer } from "./SLXSlice";
import { useStatus } from "./useStatus";
import PVDurationStats from "./PVDurationStats";
import { useTheme } from "@emotion/react";

import KELogo from "../../assets/logos/kge_logo.png";

const imageSource = (type) => {
  if (/xfm/i.test(type)) {
    return `/evue-ui/images/overview/machine/xfm-iso.png`;
  } else if (/emt/i.test(type)) {
    return `/evue-ui/images/overview/machine/emt-iso.png`;
  } else if (/sfm/i.test(type)) {
    return `/evue-ui/images/overview/machine/sfm-iso.png`;
  } else if (/emv/i.test(type)) {
    return `/evue-ui/images/overview/machine/emv.png`;
  } else if (/rfs/i.test(type)) {
    return `/evue-ui/images/overview/machine/rfs-iso.png`;
  } else if (/hpm/i.test(type)) {
    return `/evue-ui/images/overview/machine/hpm-iso.png`;
  } else if (/hmk/i.test(type)) {
    return `/evue-ui/images/overview/machine/hpm-iso.png`;
  } else if (/dii/i.test(type)) {
    return `/evue-ui/images/overview/machine/d-ii-iso.png`;
  } else if (/pt\+/i.test(type)) {
    return `/evue-ui/images/overview/machine/pt-iso.png`;
  } else if (/pp\+/i.test(type)) {
    return `/evue-ui/images/overview/machine/pp-10-iso.png`;
  } else {
    return KELogo;
  }
};

const EquipmentTile = ({ producerId }) => {
  const producer = useProducer(producerId);
  const status = useStatus(producer?.status);
  const theme = useTheme();

  return (
    <Card variant="outlined">
      <CardHeader
        disableTypography
        avatar={<StatusIcon size="large" status={producer.status} />}
        title={
          <Typography variant="h3" sx={{ color: "white" }}>
            {producer.name}
          </Typography>
        }
        subheader={<Typography variant="subtitle" sx={{ color: "white" }}>{`ID: ${producer.id}`}</Typography>}
        sx={{ color: "white", bgcolor: status.backgroundColor }}
      />
      <CardMedia
        sx={{ padding: "10px", objectFit: "contain", backgroundColor: theme.palette.grey[200] }}
        component="img"
        height="140"
        image={imageSource(producer.name)}
        alt="Machine Image"
      />

      <Divider />
      <PVDurationStats {...producer.durations} />
    </Card>
  );
};

EquipmentTile.propTypes = {
  producerId: PropTypes.string,
};

export default EquipmentTile;
