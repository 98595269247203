import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import localeData from "dayjs/plugin/localeData";
import duration from "dayjs/plugin/duration";
import "dayjs/locale/es";
import "dayjs/locale/de";
import "dayjs/locale/el";
import "dayjs/locale/fi";
import "dayjs/locale/fr";
import "dayjs/locale/hu";
import "dayjs/locale/it";
import "dayjs/locale/pt";
import "dayjs/locale/sv";

export const mapToLocaleString = (value: string) => {
  if (value.includes("_")) {
    return value.split("_")[0];
  };

  return value;
};

dayjs.extend(LocalizedFormat);
dayjs.extend(localeData);
dayjs.extend(duration);

export default dayjs;
