import _ from "lodash";
import { shallowEqual, useSelector } from "react-redux";
import { SliceTypes } from "../enum/eVue/SliceTypes";
import store from "../store";
import { getScannerById } from "./scanners";
import { getLoadCellById } from "./loadCells";
import { getConveyors } from "./conveyors";

const ANALOG_INPUTS = ["AI", "LC", "GSG", "RS232"];
const ANALOG_OUTPUTS = ["AO"];

export const getIOPointsCount = (state = store.getState()) => {
  const ioPoints = state.ioPoints;
  return ioPoints && Object.values(ioPoints)?.length;
};

export const getIoPointIdsForPanel = (panelId, state) => {
  return Object.values(state.ioPoints)
    .map((io) => (io.panelId === panelId ? io.id : false))
    .filter((io) => io && io);
};

export const getHasAnyForcedPoints = (ioPointIds, state) => {
  if (!ioPointIds) return false;
  const forcedPoints = ioPointIds
    .map((id) => {
      const ioPoint = state.ioPoints[id];
      return ioPoint && ioPoint.forceEnabled ? true : false;
    })
    .filter((value) => value);

  return forcedPoints?.length > 0;
};

export const getPanelIdsWithForcedIO = (state) => {
  const ioPoints = Object.values(state.ioPoints) || [];
  if (ioPoints.length < 1) return [];

  return ioPoints.reduce((acc, io) => {
    if (io.forceEnabled) {
      acc.push(io.panelId);
    }
    return acc;
  }, []);
};

export const useIoPointsByIds = (ids) =>
  useSelector((state) => {
    if (!state.ioPoints) return null;
    return ids.map((ioId) => state.ioPoints[ioId]);
  }, _.isEqual);

export const useIoPoint = (id) => {
  return useSelector((state) => {
    const ioPoint = state.ioPoints[id];
    const typeAndPosition = getTypeAndPosition(ioPoint);
    const ioValue = getIOValueForPoint(ioPoint, state);
    return formatIOPoint(ioPoint, typeAndPosition, ioValue);
  }, shallowEqual);
};

export const getIoPoint = (state, id) => {
  const ioPoint = state.ioPoints[id];
  if (ioPoint?.id) {
    const typeAndPosition = getTypeAndPosition(ioPoint);
    const ioValue = getIOValueForPoint(ioPoint, state);
    return formatIOPoint(ioPoint, typeAndPosition, ioValue);
  }
};

// PanelPowerSliceAndNotes(Evue repo)
const getIOValueForPoint = (ioPoint, state) => {
  if (ANALOG_INPUTS.includes(ioPoint.sliceType)) {
    return getAnalogInputValue(ioPoint, state);
  } else if (ANALOG_OUTPUTS.includes(ioPoint.sliceType)) {
    return getAnalogOutputValue(ioPoint, state);
  } else {
    return null;
  }
};

// AIIOSLICE(Evue repo)
const getAnalogInputValue = (ioPoint, state) => {
  const { mapReference, sliceType } = ioPoint;
  const splitChar = sliceType === "RS232" ? "e" : "t";
  const tempArray = mapReference.split(splitChar);
  const position = tempArray[1];

  const scanner = getScannerById(position, state);
  const loadCell = getLoadCellById(position, state);

  return sliceType === "RS232" ? scanner.lastScannedValue : loadCell?.currentValue;
};

// AOIOSLICE(Evue repo)
const getAnalogOutputValue = (ioPoint, state) => {
  const conveyors = getConveyors(state);
  if (!conveyors) return null;
  const tempString = ioPoint.mapReference;
  const tempArray = tempString.toLowerCase().split("v");
  const position = tempArray[1] - 1;
  const conveyor = Object.values(conveyors)[position];
  return conveyor && conveyor.analogValue;
};

const formatIOPoint = (ioPoint, typeAndPosition, ioValue) => {
  return {
    ...ioPoint,
    type: typeAndPosition[0],
    position: typeAndPosition[1],
    ioValue,
    analog: isAnalog(ioPoint),
  };
};

const isAnalog = (ioPoint) => {
  return ANALOG_INPUTS.includes(ioPoint.sliceType) || ANALOG_OUTPUTS.includes(ioPoint.sliceType);
};

const getTypeAndPosition = (ioPoint) => {
  const { iotype, sliceType, point } = ioPoint;
  let type, position;

  // IOSlice.mxml(Evue repo)
  if (sliceType[0] === "G" || sliceType === SliceTypes.SM) {
    position = point;
    if (
      iotype === "DI" ||
      iotype === "P20" ||
      iotype === "P10" ||
      iotype === "PB" ||
      iotype === "AI" ||
      iotype === "IN"
    ) {
      type = "Input";
    } else if (
      iotype === "V" ||
      iotype === "DO" ||
      iotype === "OUT" ||
      iotype === "PL" ||
      iotype === "AO" ||
      iotype === "LV" ||
      iotype === "CR"
    ) {
      type = "Output";
    }
  } else {
    if (sliceType === "DI") {
      type = "Input";
      position = 100 + point;
    } else if (sliceType === "DO" || sliceType === "RO" || sliceType === "R2" || sliceType === "R4") {
      type = "Output";
      position = 200 + point;
    } else if (sliceType === "LC" || sliceType === "AI" || sliceType === "RS232") {
      type = "Input";
      position = 300 + point;
    } else if (sliceType === "AO") {
      type = "Output";
      position = 400 + point;
    } else if (sliceType === "RS") {
      type = "Serial";
      position = 500 + point;
    } else if (sliceType === "SIS") {
      type = "Input";
      position = 700 + point;
    } else if (sliceType === "SOS") {
      type = "Output";
      position = 800 + point;
    }
  }

  return [type, position];
};
