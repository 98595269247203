import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import { amber, green, red, yellow } from "@mui/material/colors";
import { SnackbarContent } from "@mui/material";
import clsx from "clsx";
import { clearAlert } from "../actions/alertActions";
import CheckCircleIcon from "@mui/icons-material/CheckCircleSharp";
import WarningIcon from "@mui/icons-material/WarningSharp";
import ErrorIcon from "@mui/icons-material/ErrorSharp";
import InfoIcon from "@mui/icons-material/InfoSharp";
import CloseIcon from "@mui/icons-material/CloseSharp";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1500,
  },
  anchorOriginTopCenter: {
    top: "24px",
  },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: red[600],
  },
  info: {
    backgroundColor: yellow[600],
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
  alertMessage: {
    whiteSpace: "pre-line",
  },
}));

export default function AlertSnackbar() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { alertMessage, alertOpen, alertVariant } = useSelector((state) => state.ui);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") return;

    dispatch(clearAlert());
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      variant={alertVariant}
      className={clsx(classes.root, classes.anchorOriginTopCenter)}
      open={alertOpen}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <SnackbarContent
        className={clsx(classes[alertVariant])}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            {renderAlertIcon({ classes, alertVariant })}
            <div className={classes.alertMessage}>{alertMessage}</div>
          </span>
        }
        action={[
          <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
}

const renderAlertIcon = ({ classes, alertVariant }) => {
  const Icon = variantIcon[alertVariant];

  return Icon && <Icon className={clsx(classes.icon, classes.iconVariant)} />;
};
