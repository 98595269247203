import React, { useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { nameComparer } from "../../utils/sort";
import { useCustomersOfStorageConfig } from "../../selectors/storageConfigEditor";
import { useTranslation } from "react-i18next";
import CustomerChip from "../CustomerChip";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    marginTop: theme.spacing(0.5),
    width: "100%",
    maxHeight: "512px",
    overflow: "auto",
  },
  chips: {
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));

const CustomerList = () => {
  const classes = useStyles();
  const customers = useCustomersOfStorageConfig();
  const [count, setCount] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    setCount(customers?.length ?? 0);
  }, [customers]);

  return (
    <>
      {customers.length > 0 && (
        <div className={classes.root}>
          <Accordion TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant={"h4"} gutterBottom>
                {count} {count === 1 ? t("common:customer") : t("common:customers")}:
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              classes={{
                root: classes.chips,
              }}
            >
              {customers.sort(nameComparer).map((customer, i) => (
                <CustomerChip key={`customer-chip-${customer.id}`} customerId={customer.id} />
              ))}
            </AccordionDetails>
          </Accordion>
        </div>
      )}
    </>
  );
};

export default CustomerList;
