import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as BagIcon } from "../../assets/icons/bag.svg";
import { ReactComponent as BagEmptyIcon } from "../../assets/icons/bag-empty.svg";

const styles = makeStyles((theme) => ({
  slingPresent: {
    fontSize: (props) => props.size || 55,
    color: theme.palette.info.light,
  },
  slingMissing: {
    fontSize: (props) => props.size || 55,
    color: theme.palette.common.black,
  },
}));

const SlingPresenceIndicator = ({ slingLoaded, size = 55 }) => {
  const classes = styles({ size });
  const borderThickness = _.round(size * 0.2);

  return (
    <Box
      sx={(theme) => ({
        border: `${borderThickness}px solid ${theme.palette.info.light}`,
        borderRadius: "50%",
        padding: theme.spacing(1),
      })}
    >
      <SvgIcon className={slingLoaded ? classes.slingPresent : classes.slingMissing}>
        {slingLoaded ? <BagIcon /> : <BagEmptyIcon />}
      </SvgIcon>
    </Box>
  );
};

SlingPresenceIndicator.propTypes = {
  slingLoaded: PropTypes.bool.isRequired,
};

export default SlingPresenceIndicator;
