import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { toCamelCase, toSnakeCase } from '../utils';

const baseQuery = fetchBaseQuery({
  baseUrl: "/control_api/v1",
  prepareHeaders: (headers, { getState }) => {
    headers.set("Accept", "application/json")
    return headers
  }
});

const baseQueryWithFormatting: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(toSnakeCase(args), api, extraOptions)
  const camelizedResponse = toCamelCase(result)
  return camelizedResponse
};

export const eVueApi = createApi({
  baseQuery: baseQueryWithFormatting,
  reducerPath: "eVueApi",
  tagTypes: ["Groups", "SortConfigs", "RailMessageEvents", "StorageRulesets", "SystemConfigs"],
  keepUnusedDataFor: 60,
  endpoints: () => ({}),
});
