import React, { ElementType, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/CloseSharp";
import DeleteButton from "./DeleteButton";

interface Props {
  title: string;
  disabled: boolean;
  children: JSX.Element;
  onDelete: VoidFunction;
};

const DeleteButtonWithConfirmation = ({ title, disabled, children, onDelete }: Props) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const handleOnDelete = () => {
    setOpen(false);
    onDelete();
  };

  return (
    <>
      <DeleteButton disabled={disabled} onClick={() => setOpen(true)}>
        {t("common:delete")}
      </DeleteButton>

      {open && (
        <Dialog
          open={open}
          fullWidth={true}
          sx={{
            ".MuiPaper-root": {
              maxHeight: "calc(100% - 180px)"
            }
          }}
        >
          <Box display="flex" flexDirection="column" px={3} py={2}>
            <Typography variant="h3">{title}</Typography>
          </Box>

          <DialogContent
            dividers={true}
            sx={{
              display: "flex",
              width: "100%",
              boxSizing: "border-box",
            }}
          >
            {children}
          </DialogContent>

          <DialogActions>
            <Button variant="outlined" autoFocus onClick={() => setOpen(false)} startIcon={<CloseIcon />}>
              {t<string>("common:cancel")}
            </Button>

            <DeleteButton disabled={disabled} onClick={handleOnDelete}>
              {t("common:delete")}
            </DeleteButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default DeleteButtonWithConfirmation;
