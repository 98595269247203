import _ from "lodash";
import { showAlertError, showAlertSuccess } from "../actions";
import store from "../store";
import i18n from "../i18n";
import Client from "./Client";

export const addRole = ({ role, successCB, errorCB }) => {
  Client.post("control_api/v1/roles", role)
    .then((response) => {
      if (successCB) {
        successCB();
      }

      store.dispatch(showAlertSuccess(i18n.t("common:success")));
    })
    .catch((error) => {
      const errorsByKey = _.get(error, "response.data.error", "API Error");
      if (errorCB) {
        errorCB(errorsByKey);
      }

      store.dispatch(showAlertError(`${i18n.t("common:error")}: ${JSON.stringify(errorsByKey)}`));
    });
};

export const updateRole = ({ role, successCB, errorCB }) => {
  Client.put(`control_api/v1/roles/${role.id}`, role)
    .then((response) => {
      store.dispatch(showAlertSuccess(i18n.t("common:success")));
      if (successCB) {
        successCB();
      }
    })
    .catch((error) => {
      const errorMessage = _.get(error, "response.data.error", "API Error");
      if (errorCB) {
        errorCB(errorMessage);
      }

      store.dispatch(showAlertError(`${i18n.t("common:error")}: ${JSON.stringify(errorMessage)}`));
    });
};
