import _ from "lodash";
import { useSelector, shallowEqual } from "react-redux";
import { useAppSelector } from "../hooks/useAppSelector";
import store from "../store";

export const getSystems = (receivedState) => {
  const state = receivedState || store.getState();
  return Object.values(state.systems);
};

export const useStorageRailIdsForSystemId = (systemId) => {
  return useSelector((state) => {
    return state.systems[systemId]?.storageRailIds || [];
  }, shallowEqual);
};

export const getStorageRailIdsForSystemId = (systemId, state) => {
  return state.systems[systemId]?.storageRailIds || [];
};

export const getPreDestRailIdsForSystemId = (systemId, state) => {
  return state.systems[systemId]?.preDestRailIds || [];
};

export const getSoiledSystem = (receivedState) => {
  const state = receivedState || store.getState();
  return Object.values(state.systems).find((x) => x.systemType === "soiled");
};

export const getCleanSystem = (receivedState) => {
  const state = receivedState || store.getState();
  return Object.values(state.systems).find((x) => x.systemType === "clean");
};

export const getCustomerBagSystem = (receivedState) => {
  const state = receivedState || store.getState();
  return Object.values(state.systems).find((x) => x.systemType === "customer_bag");
};

export const getSystemsWithSortStations = (receivedState) => {
  const state = receivedState || store.getState();
  const systemIds = _.uniq(Object.values(state.sortStations).map((x) => x.systemId));
  return systemIds.map((id) => state.systems[id]);
};

export const useSystemByRailId = (railId) => useAppSelector((state) => getSystemByRailId(state, railId), _.isEqual);

export const getSystemByRailId = (state, railId) => state.systems[state.rails[railId]?.systemId] || {};

export const getSystemFromId = (id, state) => state.systems[id];

export const useSystem = (id) => useSelector((state) => state.systems[id], _.isEqual);

export const useSystemByType = (systemType) => {
  const systems = useSelector((state) => state.systems, _.isEqual);
  return Object.values(systems).find((system) => system.systemType === systemType);
};
