import React from "react";
import { Avatar, useTheme } from "@mui/material";
import { shallowEqual, useSelector } from "react-redux";

const CustomerNumberAvatar = ({ customerId }) => {
  const customer = useSelector((state) => state.customers[customerId], shallowEqual);
  const theme = useTheme();

  return (
    <Avatar
      variant="rounded"
      style={{
        fontSize: "0.9rem",
        backgroundColor: customer?.color,
        color: theme.palette.getContrastText(customer?.color ?? "#dbdbdb"),
      }}
    >
      {customer?.number}
    </Avatar>
  );
};

export default CustomerNumberAvatar;
