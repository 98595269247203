import React from "react";
import { Select, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import FormFieldLabel from "../SetupPages/FormFieldLabel";

const FormDeliveryMethodSelector = ({ currentValues, disabled, onFieldChange }) => {
  const { t } = useTranslation();
  return (
    <>
      <FormFieldLabel label={t("usersSetup:delivery_method")} />

      <Select
        variant="outlined"
        value={currentValues["deliveryMethod"]}
        disabled={disabled}
        onChange={(e) => onFieldChange("deliveryMethod", e.target.value)}
      >
        <MenuItem value="email">{t("usersSetup:email")}</MenuItem>
        <MenuItem value="sms">{t("usersSetup:text")}</MenuItem>
      </Select>
    </>
  );
};

export default FormDeliveryMethodSelector;
