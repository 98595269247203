import * as actions from "../actions/types";

const initialState = {};

export default function railVisitReducer(state = initialState, action) {
  switch (action.type) {
    case actions.INITIALIZE_RAIL_VISITS:
      return action.railVisits;
    case actions.UPDATE_RAIL_VISITS:
      return { ...state, ...action.data };
    default:
      return state;
  }
}
