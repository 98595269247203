import React from "react";
import { useTranslation } from "react-i18next";
import { Button, FormControlLabel } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";

const ConciseReturnBagButton = ({ sx, disabled, onClick }) => {
  const { t } = useTranslation();

  return (
    <FormControlLabel
      sx={sx}
      control={
        <Button
          sx={(theme) => ({
            borderRadius: "50%",
            padding: theme.spacing(2),
            fontSize: 40,

            "& #start-icon": {
              fontSize: 40,
            },
          })}
          size="large"
          disableElevation={true}
          variant="contained"
          disabled={disabled}
          onClick={onClick}
        >
          <DownloadIcon id="start-icon" />
        </Button>
      }
      label={t("slingInputStation:lower_bag")}
      labelPlacement="top"
    />
  );
};

export default ConciseReturnBagButton;
