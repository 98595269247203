import { useSelector, shallowEqual } from "react-redux";
import _ from "lodash";

export const useWeightCheckIdForRailId = (railId) =>
  useSelector((state) => Object.values(state.weightChecks).find((wc) => wc.railId === railId)?.id);

export const useAlarmingWeightCheckRailIds = () =>
  useSelector((state) => {
    return Object.values(state.weightChecks)
      .filter((wc) => wc.state === "fail")
      .map((wc) => wc.railId);
  }, shallowEqual);
