import React from "react";
import { Button, FormControlLabel } from "@mui/material";
import ArrowDownIcon from "@mui/icons-material/ArrowDropDown";
import { useTranslation } from "react-i18next";

interface Props {
  disabled?: boolean;
  onClick: () => void;
};

const ConciseManualOpenButton = ({ disabled, onClick }: Props) => {
  const { t } = useTranslation();

  return (
    <FormControlLabel
      label={t<string>("railBar:manual_open")}
      labelPlacement="top"
      control={
        <Button
          variant="contained"
          color="primary"
          size="large"
          disabled={disabled}
          disableElevation={true}
          onClick={onClick}
          sx={{
            borderRadius: "50%",
            width: 60,
            height: 60,
          }}
        >
          <ArrowDownIcon id="start-icon" sx={{ fontSize: 40 }} />
        </Button>
      }
    />
  );
};

export default ConciseManualOpenButton;
