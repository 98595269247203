import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import type { System } from "../../../dto/eVue/System";
import type { SortConfiguration } from "../../../dto/eVue/SortConfiguration";
import { RootState } from "../../../store";
import { selectCustomers } from "../../../selectors/customers";
import { sortConfigurationsApi } from "../services/sortConfigurations";

type ModifiedSortConfiguration = SortConfiguration & { dirty?: boolean };

interface SortAssignmentsState {
  selectedSystem?: System | null;
  status: "idle" | "loading" | "saving" | "succeeded" | "failed";
  error?: string | null;
  mappingOpen: boolean;
  enableClone: boolean,
  enableDelete: boolean,
  enableRename: boolean,
  loaderMessage?: string,
  loaderOpen: boolean,
  renameDialogOpen: boolean,
  selectedSlingAssignRail?: number,
  selectedSortConfiguration?: ModifiedSortConfiguration,
  sortStationDialogOpen: boolean,
};

const initialState: SortAssignmentsState = {
  status: "idle",
  error: undefined,
  selectedSystem: undefined,
  mappingOpen: false,
  enableClone: false,
  enableDelete: false,
  enableRename: false,
  loaderMessage: undefined,
  loaderOpen: false,
  renameDialogOpen: false,
  selectedSlingAssignRail: undefined,
  selectedSortConfiguration: undefined,
  sortStationDialogOpen: false,
};

const sortConfigurationsSlice = createSlice({
  name: "sortConfigurations",
  initialState,
  reducers: {
    clearEditor: (state) => {
      state.enableClone = false;
      state.enableDelete = false;
      state.enableRename = false;
      state.loaderMessage = undefined;
      state.loaderOpen = false;
      state.renameDialogOpen = false;
      state.selectedSlingAssignRail = undefined;
      state.selectedSortConfiguration = undefined;
      state.selectedSystem = null;
      state.sortStationDialogOpen = false;
    },
    toggleMapping: (state) => {
      state.mappingOpen = !state.mappingOpen;
    },
    selectConfig: (state, action) => {
      state.enableRename = true;
      state.enableClone = true;
      state.enableDelete = true;
      state.selectedSortConfiguration = {
        ...action.payload,
        dirty: false,
      };
    },
    setSlingAssignRail: (state, action) => {
      state.selectedSlingAssignRail = action.payload;
    },
    setSlingAssignments: (state, action) => {
      if (state.selectedSlingAssignRail && state.selectedSortConfiguration) {
        const newSlingAssignments = _.cloneDeep(state.selectedSortConfiguration.slingAssignments || []).map((sa) => {
          if (sa?.railId === state.selectedSlingAssignRail) {
            sa.sortClassificationId = action.payload;
          }
          return sa;
        });

        state.selectedSortConfiguration.dirty = true;
        state.selectedSortConfiguration.slingAssignments = newSlingAssignments;
      }
    },
    renameConfig: (state, action) => {
      if (state.selectedSortConfiguration) {
        state.selectedSortConfiguration.dirty = true;
        state.selectedSortConfiguration.name = action.payload;
      };
    },
    showMessage: (state, action) => {
      state.loaderOpen = true;
      state.loaderMessage = action.payload;
    },
    hideMessage: (state) => {
      state.loaderOpen = false;
      state.loaderMessage = undefined;
    },
    setSelectedSystem: (state, action) => {
      state.selectedSystem = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(sortConfigurationsApi.endpoints.updateSortConfiguration.matchFulfilled, (state, action) => {
        state.selectedSortConfiguration = {
          ...action.payload,
          dirty: false,
        };
        state.loaderOpen = false;
      })
      .addMatcher(sortConfigurationsApi.endpoints.cloneSortConfiguration.matchFulfilled, (state, action) => {
        state.enableRename = true;
        state.loaderOpen = false;
        state.enableClone = true;
        state.enableDelete = true;
        state.selectedSortConfiguration = {
          ...action.payload,
          dirty: false,
        };
      })
      .addMatcher(sortConfigurationsApi.endpoints.createSortConfiguration.matchFulfilled, (state, action) => {
        state.enableRename = true;
        state.loaderOpen = false;
        state.enableClone = true;
        state.enableDelete = true;
        state.selectedSortConfiguration = {
          ...action.payload,
          dirty: false,
        };
      })
      .addMatcher(sortConfigurationsApi.endpoints.deleteSortConfiguration.matchFulfilled, (state) => {
        state.enableClone = false;
        state.loaderOpen = false;
        state.enableDelete = false;
        state.enableRename = false;
        state.selectedSortConfiguration = undefined;
      })
      .addMatcher(sortConfigurationsApi.endpoints.deleteSortConfiguration.matchRejected, (state) => {
        state.loaderOpen = false;
        state.loaderMessage = undefined;
      })
  },
});

// SELECTORS
export const selectSelectedSystem = (state: RootState) => state.sortConfigurations.selectedSystem;
export const selectSelectedSortConfig = (state: RootState) => state.sortConfigurations.selectedSortConfiguration;
export const selectEnableClone = (state: RootState) => state.sortConfigurations.enableClone;
export const selectMappingOpen = (state: RootState) => state.sortConfigurations.mappingOpen;
export const selectLoaderOpen = (state: RootState) => state.sortConfigurations.loaderOpen;
export const selectLoaderMessage = (state: RootState) => state.sortConfigurations.loaderMessage;
export const selectEnableDelete = (state: RootState) => state.sortConfigurations.enableDelete;
export const selectEnableRename = (state: RootState) => state.sortConfigurations.enableRename;

export const selectCustomersOfSortConfig = (state: RootState) => {
  const customers = selectCustomers(state);
  const selectedSystem = selectSelectedSystem(state);
  const selectedSortConfig = selectSelectedSortConfig(state);

  return Object.values(customers).filter((customer) => {
    switch (selectedSystem?.systemType) {
      case "soiled":
        return customer.soiledSortConfigId === selectedSortConfig?.id;
      case "clean":
        return customer.cleanSortConfigId === selectedSortConfig?.id;
      default:
        return null;
    }
  });
};

export const {
  clearEditor,
  toggleMapping,
  selectConfig,
  setSlingAssignRail,
  setSlingAssignments,
  renameConfig,
  showMessage,
  hideMessage,
  setSelectedSystem,
} = sortConfigurationsSlice.actions;
export default sortConfigurationsSlice.reducer;
