import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import HourglassFullIcon from "@mui/icons-material/HourglassFull";
import SnoozeIcon from "@mui/icons-material/Snooze";
import WarningIcon from "@mui/icons-material/Warning";
import { useTheme } from "@emotion/react";

export const useStatus = (status) => {
  const theme = useTheme();

  switch (status) {
    case "PRODUCTION":
      return {
        icon: <CheckIcon />,
        backgroundColor: theme.palette.success.dark,
      };

    case "WARNING":
      return {
        icon: <WarningIcon />,
        backgroundColor: theme.palette.warning.dark,
      };

    case "FAILURE":
    case "STOP":
      return {
        icon: <ErrorIcon />,
        backgroundColor: theme.palette.error.dark,
      };

    case "WAITING":
      return {
        icon: <HourglassFullIcon />,
        backgroundColor: theme.palette.grey[400],
      };

    default:
      return {
        icon: <SnoozeIcon />,
        backgroundColor: theme.palette.info.main,
      };
  }
};
