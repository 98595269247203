import PropTypes from "prop-types";
import React from "react";

// material-ui
import { Grid, Typography } from "@mui/material";

// project imports
import CurrentOperators from "./CurrentOperators";
import StatusIcon from "./StatusIcon";
import SubCard from "../../ui-component/cards/SubCard";
import { useNormWeight, useProducerView } from "./SLXSlice";
import { useStatus } from "./useStatus";
import { useTranslation } from "react-i18next";
import { useWeightUnit } from "../../selectors/uiSettings";

const PVCard = ({ producerViewId }) => {
  const producerView = useProducerView(producerViewId);
  const productionPieces = producerView.unit === "pieces";
  const { durations, performance } = producerView;
  const { actualKilogramPerformance: weight, actualPiecesPerformance: pieces } = performance;
  const { production: runtime = 0 } = durations;
  const { t } = useTranslation();
  const wtUnit = useWeightUnit();
  const status = useStatus(producerView.status);

  const displayWeight = Math.round(useNormWeight(weight));

  return (
    <SubCard
      sx={{ bgcolor: status.backgroundColor, height: "100%" }}
      title={
        <>
          <Typography variant="h3" sx={{ color: "#fff" }}>
            {producerView.name}
          </Typography>
        </>
      }
      secondary={
        <Typography
          variant="overline"
          sx={{
            textAlign: "center",
            color: "#fff",
            "& > svg": {
              width: 24,
              height: 24,
            },
          }}
          align="center"
          a
        >
          <StatusIcon status={producerView.status} />
        </Typography>
      }
    >
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Typography variant="h4" sx={{ color: "#fff" }}>
                {t("slx:status")}
              </Typography>
              <Typography variant="h5" sx={{ color: "#fff" }}>
                {producerView.status}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h4" sx={{ color: "#fff" }}>
                {productionPieces ? t("slx:pieces") : wtUnit}
              </Typography>
              <Typography variant="h5" sx={{ color: "#fff" }}>
                {((productionPieces ? pieces : displayWeight) ?? 0).toLocaleString()}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h4" sx={{ color: "#fff" }}>
                {t("slx:hours")}
              </Typography>
              <Typography variant="h5" sx={{ color: "#fff" }}>
                {(runtime / 3600).toFixed(2)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <CurrentOperators operatorIds={producerView.operatorIds} />
        </Grid>
      </Grid>
    </SubCard>
  );
};

PVCard.propTypes = {
  producerViewId: PropTypes.string,
};

export default PVCard;
