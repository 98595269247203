import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useShowCategorySlingType, useShowBufflectorFullOpen, useJobNumber } from "../../selectors/uiSettings";
import FormCheckbox from "../SetupPages/FormCheckbox";
import FormFieldLabel from "../SetupPages/FormFieldLabel";
import SlingTypeSelector from "./SlingTypeSelector";
import FormCustomersSelector from "./FormCustomersSelector";
import StyledGridItem from "./StyledGridItem";
import LocalAttributesGroup from "./LocalAttributesGroup";

const OtherGroup = ({ currentValues, setupData, errors, disabled, onFieldChange, onArrayFieldChange }) => {
  const { t } = useTranslation();
  const showCategorySlingType = useShowCategorySlingType();
  const showBufflectorFullOpen = useShowBufflectorFullOpen();
  const jobNumber = useJobNumber();
  const localAttrsForCategoryCount = Object.keys(currentValues.localAttrs || {})?.length || 0;
  const hideSection =
    jobNumber !== "2356" && !showCategorySlingType && !showBufflectorFullOpen && localAttrsForCategoryCount < 1;

  if (hideSection) return null;
  return (
    <>
      <Grid item>
        <Typography variant="h4">{t("common:other")}</Typography>
      </Grid>

      <Box display="flex" flexDirection="column">
        <Box display="flex">
          {jobNumber === "2356" && (
            <StyledGridItem item>
              <FormCustomersSelector
                currentValues={currentValues}
                disabled={disabled}
                onArrayFieldChange={onArrayFieldChange}
              />
            </StyledGridItem>
          )}

          {showCategorySlingType && (
            <StyledGridItem item>
              <FormFieldLabel label={t("categoriesSetup:sling_type")} />
              <SlingTypeSelector
                slingTypeList={setupData["slingTypeList"]}
                selectedST={currentValues["slingTypeId"]}
                disabled={disabled}
                onChange={(stId) => onFieldChange("slingTypeId", stId)}
              />
            </StyledGridItem>
          )}
        </Box>

        {showBufflectorFullOpen && (
          <StyledGridItem item>
            <FormCheckbox
              fieldName="bufflectorFullOpen"
              label={t("categoriesSetup:bufflector_full_open")}
              currentValues={currentValues}
              disabled={disabled}
              onFieldChange={onFieldChange}
            />
          </StyledGridItem>
        )}

        <LocalAttributesGroup currentValues={currentValues} errors={errors} onFieldChange={onFieldChange} />
      </Box>
    </>
  );
};

export default OtherGroup;
