import React, { useState } from "react";
import _ from "lodash";
import {
  Box,
  Divider,
  IconButton,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableSortLabel,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { withStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { SETUP_ROLES } from "../../constants/Paths";
import { useRole } from "../../selectors/useRole";
import { useRolesIds, getRoleName, useTechnicianRoleId } from "../../selectors/roles";
import { useCurrentRole } from "../../selectors/useRole";
import store from "../../store";
import SearchField from "../SetupPages/SearchField";
import AddRoleButton from "./AddRoleButton";

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.grey[100],
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    padding: theme.spacing(2),
  },
  body: {
    fontSize: 14,
    padding: theme.spacing(2),

    "& .MuiBox-root": {
      display: "flex",
      alignItems: "center",
    },
    "& .MuiAvatar-root": {
      marginRight: theme.spacing(1),
    },
  },
}))(TableCell);

const StyledTableSortLabel = withStyles((theme) => ({
  root: {
    "&$active": {
      color: theme.palette.common.black,
    },
  },
  active: {},
  icon: {
    color: "inherit !important",
  },
}))(TableSortLabel);

const RoleRow = ({ id, onClick }) => {
  const role = useRole(id) || {};
  const currentRole = useCurrentRole();
  return (
    <StyledTableRow>
      <StyledTableCell>
        <Box>{role.name}</Box>
      </StyledTableCell>
      <StyledTableCell align="right">
        <IconButton onClick={() => onClick(id)}>
          {currentRole.canManageUsers ? <EditIcon /> : <VisibilityIcon />}
        </IconButton>
      </StyledTableCell>
    </StyledTableRow>
  );
};

const filterIds = ({ ids, technicianRoleId, searchTerm, state }) => {
  return ids.filter((id) => {
    if (parseInt(id) === technicianRoleId) return false;
    if (!searchTerm) return true;

    const name = _.toLower(getRoleName(id, state));
    return _.includes(name, searchTerm?.toLowerCase());
  });
};

const sortIds = ({ ids, order, state }) => {
  if (ids.length < 1) return [];
  return ids.sort((a, b) => {
    const valueA = getRoleName(a, state);
    const valueB = getRoleName(b, state);

    if (order === "asc") {
      if (valueB > valueA) return -1;
      if (valueB < valueA) return 1;
    } else {
      if (valueB < valueA) return -1;
      if (valueB > valueA) return 1;
    }
    return 0;
  });
};

const RolesList = () => {
  const ids = useRolesIds();
  const history = useHistory();

  const { t } = useTranslation();

  const [order, setOrder] = useState("asc");
  const [searchTerm, setSearchTerm] = useState(null);

  const state = store.getState();
  const technicianRoleId = useTechnicianRoleId();
  const filteredRolesIds = filterIds({ ids, technicianRoleId, searchTerm, state });
  const sortedRolesIds = sortIds({ ids: filteredRolesIds, order, state });

  return (
    <Paper square={true} elevation={0} sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box padding={3} display="flex" justifyContent="space-between" alignItems="flex-end">
        <Typography variant="h1">{t("rolesSetup:roles_setup")}</Typography>

        <SearchField
          sx={{ width: 350 }}
          placeholder={t("rolesSetup:search_instructions")}
          onSubmitSearchTerm={(searchTerm) => setSearchTerm(searchTerm)}
        />
      </Box>

      <Divider />

      <TableContainer>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell sortDirection={order}>
                <StyledTableSortLabel
                  active={true}
                  direction={order}
                  onClick={() => setOrder(order === "asc" ? "desc" : "asc")}
                >
                  {t("common:name")}
                </StyledTableSortLabel>
              </StyledTableCell>

              <StyledTableCell align="right">
                <AddRoleButton size="small" />
              </StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {sortedRolesIds.map((id) => (
              <RoleRow key={id} id={id} onClick={() => history.push(`${SETUP_ROLES}/${id}`)} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default RolesList;
