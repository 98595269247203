import { useSelector, shallowEqual } from "react-redux";
import { getCurrentRole } from "./useRole";
import store from "../store";

export const useRolesIds = () => useSelector((state) => Object.keys(state.roles), shallowEqual);

export const getRoleName = (id, state) => {
  const receivedState = state || store.getState();
  const role = receivedState.roles[id];
  return role?.name;
};

export const useAdminRoleId = () =>
  useSelector((state) => {
    const adminRole = Object.values(state.roles).find((role) => role.name === "Administrator");
    return adminRole && parseInt(adminRole.id);
  });

export const useTechnicianRoleId = () =>
  useSelector((state) => {
    const technicianRole = Object.values(state.roles).find((role) => role.name === "Technician");
    return technicianRole && parseInt(technicianRole.id);
  });

export const useIsLoggedInUserTechnician = () => useSelector((state) => isTechnicianLoggedIn(state));

export const isTechnicianLoggedIn = (state = store.getState()) => {
  const technicianRole = Object.values(state.roles).find((role) => role.name === "Technician");
  const currentRole = getCurrentRole(state) || {};
  return currentRole.id === technicianRole?.id;
};
