import React from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    border: 0,
    display: "block",
    height: "100%",
    width: "100%",
  },
});

const setUrl = (url) => {
  const iframe = document.getElementById("eVue-webpage-iframe");
  if (!iframe) return null;

  const iframeLocation = iframe.contentWindow.location;
  iframeLocation.href = url;
};

const EvueWebpage = () => {
  const classes = useStyles();
  const params = useParams();
  const host = window.location.host.slice();
  const src = `http://${host.replace(/:3001/gi, ":3000")}/${params[0]}`;
  setUrl(src);

  return <iframe id="eVue-webpage-iframe" className={classes.root} title="evue-webpage" src={src}></iframe>;
};

export default EvueWebpage;
