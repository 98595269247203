import React from "react";

// material-ui
import { Card, CardContent, Grid, Typography } from "@mui/material";

export const StatCard = ({ iconPrimary, color, textColor = "white", title, primary }) => {
  const IconPrimary = iconPrimary;
  const primaryIcon = iconPrimary ? <IconPrimary fontSize="large" /> : null;

  return (
    <Card
      sx={{
        background: color,
        position: "relative",
        color: "#fff",
        "&:hover svg": {
          opacity: "1",
          transform: "scale(1.1)",
        },
      }}
    >
      <CardContent>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="subtitle2" sx={{ color: textColor }}>
              {primaryIcon}
            </Typography>
            <Typography variant="h4" color={textColor}>
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h3" color={textColor}>
              {primary}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default StatCard;
