import { createSlice } from "@reduxjs/toolkit";
import { ContextMode, ContextType } from "@etechinc/overview-sdk";
import * as actions from "../../actions/types";

const overviewSlice = createSlice({
  name: "overview",
  initialState: {
    contextMode: ContextMode.MANUAL,
    contextType: ContextType.RAIL,
    sceneCreated: false,
    showAlarmTray: false,
    showSavedViews: false,
  },
  reducers: {
    setOverviewContextMode: (state, action) => {
      state.contextMode = action.payload;
    },
    setOverviewContextType: (state, action) => {
      state.contextType = action.payload;
    },
    setOverviewSceneCreated: (state, action) => {
      state.sceneCreated = action.payload;
    },
    setShowAlarmTray: (state, action) => {
      state.showAlarmTray = action.payload;
    },
    toggleAlarmTray: (state, action) => {
      state.showAlarmTray = !state.showAlarmTray;
    },
    toggleSavedViews: (state, action) => {
      state.showSavedViews = !state.showSavedViews;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(actions.LOGIN_SUCCESS, (state, action) => {
        if (action.data.overviewContext === ContextMode.AUTO) {
          state.contextMode = ContextMode.AUTO;
          state.contextType = ContextType.ALL;
        } else {
          state.contextMode = ContextMode.MANUAL;
          state.contextType = action.data.overviewContext;
        }
      })
      .addCase(actions.LOGOUT_SUCCESS, (state, action) => {
        state.contextMode = ContextMode.MANUAL;
        state.contextType = ContextType.RAIL;
      });
  },
});

export const {
  setOverviewContextMode,
  setOverviewContextType,
  setOverviewSceneCreated,
  setShowAlarmTray,
  toggleAlarmTray,
  toggleSavedViews,
} = overviewSlice.actions;

export const overviewContextModeSelector = (state) => state.overview.contextMode;
export const overviewContextTypeSelector = (state) => state.overview.contextType;
export const overviewSceneCreatedSelector = (state) => state.overview.sceneCreated;
export const showAlarmTraySelector = (state) => state.overview.showAlarmTray;
export const showSavedViewsSelector = (state) => state.overview.showSavedViews;

export default overviewSlice.reducer;
