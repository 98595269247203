/**
 * IO types standard, ids or rail_io_types
 */
export const IoConstant = {
  SORTS_STANDARD_IO_TYPE: 1,
  RAILS_INX_IO_TYPE: 2,
  RAILS_STP_IO_TYPE: 3,
  LIFTS_SINGLE_INCLINE_IO_TYPE: 4,
  LIFTS_SINGLE_DECLINE_IO_TYPE: 5,
  CLOSERS_IO_TYPE: 6,
  OPENERS_STANDARD_IO_TYPE: 7,
  THREE_STAGE_FLOOR_SCALE_LIFT_IO_TYPE: 8,
  LOAD_DOUBLING_CONVEYOR: 9,
  TWO_TROLLEY_DOUBLE_HOPPER_SORT_IO_TYPE: 10,
  TWO_TROLLEY_DOUBLE_HOPPER_SORT_WITH_STAGING_IO_TYPE: 11,
  TWO_TROLLEY_SORT_EMPTY_STAGING_IO_TYPE: 12,
  TWO_TROLLEY_TWO_SLING_INCLINE_LIFT_IO_TYPE: 13,
  TWO_TROLLEY_OPENER_IO_TYPE: 14,
  TWO_TROLLEY_SPLIT_RAIL_INX_IO_TYPE: 15,
  SINGLE_THREE_STAGE_WITH_LOADCELL_IO_TYPE: 16,
  LIFTS_DOUBLE_INCLINE_IO_TYPE: 17,
  SINGLE_SPUR_BUFFLECTOR_SORT_IO_TYPE: 18,
  SINGLE_THREE_STAGE_AND_INCLINE_WITH_WEIGHING_LIFT_IO_TYPE: 19,
  TWO_TROLLEY_DOUBLE_HOPPER_SORT_WITH_PAIRING_AFTER_IO_TYPE: 20,
  TWO_TROLLEY_SORT_PAIRING_STOP_IO_TYPE: 21,
  SORTS_BUFFLECTOR_IO_TYPE: 22,
  TRANSFER_IO_TYPE: 23,
  STOP_WITH_MANUAL_SEND_IO_TYPE: 24,
  SINGLE_FREE_SWING_LIFT_IO_TYPE: 25,
  SINGLE_INCLINE_LIFT_WITHOUT_STOP_IO_TYPE: 26,
  TWO_TROLLEY_SPLIT_RAIL_STP_IO_TYPE: 27,
  TWO_DOOR_POSITION_BUFFLECTOR_SORT_IO_TYPE: 28,
  SORT_ON_RAIL_WITH_HOPPER_DOOR_IO_TYPE: 29,
  OPENER_INX_IO_TYPE: 30,
  SINGLE_LOADING_CONVEYOR: 31,
  SORT_DISPLAY_NON_RAIL_IO_TYPE: 32,
  SORT_ON_RAIL_WITH_DEDICATED_PAIRING_IO_TYPE: 33,
  INX_WITHOUT_SENSOR: 34,
  STP_WITHOUT_SENSOR: 35,
  TWO_SLING_MIDDLE_STOP_INCLINE_LIFT_IO_TYPE: 36,
  TWO_TROLLEY_OPENER_WITH_PENDANT_IO_TYPE: 37,
  TWO_SLING_TWO_STOP_INCLINE_LIFT_IO_TYPE: 38,
  GUIDED_FREE_SWING_INPUT_LIFT_IO_TYPE: 39,
  TRANSFER_LIFT: 40,
  INX_SLANT_LIFT: 41,
  DEBAGGER: 42,
  BIN_TO_BELT: 43,
  FUTURE_RAIL_OPENER_CLOSER: 44,
  HOIST_LIFT_INCLINE: 45,
  TIPPER: 46,
  FLIPPER: 47,
  BIN_TO_BELT_WITH_LOADCELL: 48,
  HOIST_LIFT_DECLINE: 49,
  PAIRING_SPUR: 50,
  SORT_ON_RAIL_BULK_LOADER: 51,
  CLOSERS_WITH_POA_IO_TYPE: 52,
  TWO_TROLLEY_SORTS_BUFFLECTOR_IO_TYPE: 53,
  TWO_STAGE_TRANSFER_LIFT: 54,
  BIN_TO_BELT_WITH_TOP_DOOR: 55,
  THREE_STAGE_TRANSFER_LIFT: 56,
  LIFTS_DOUBLE_DECLINE_IO_TYPE: 57,
  SORT_WITH_MOVING_RAIL: 58,
  BIN_TO_BELT_WITH_LOADCELL_AND_DOOR_SENSOR: 59,
  SINGLE_DECLINE_LIFT_WITHOUT_STOP_IO_TYPE: 60,
  TWO_TROLLEY_TWO_SLING_DECLINE_LIFT_IO_TYPE: 61,
  EIGHTEEN_INCH_RATCHET_LIFT: 62,
  DECLINE_SLANT_LIFT: 63,
  REVERSE_THREE_STAGE_LIFT: 64,
  SUPERTRACK_RATCHET_LIFT: 65,
  SUPERTRACK_DISCHARGER: 66,
  SUPERTRACK_INCLINE: 67,
  SUPERTRACK_EASISORT: 68,
  SUPERTRACK_BIN_TRANSFER: 69,
  SUPERTRACK_INDEXER: 70,
  EIGHTEEN_INCH_TWO_WHEEL_RATCHET_LIFT: 71,
  EIGHTEEN_INCH_SIX_INCH_TROLLEY_RATCHET_LIFT: 72,
  SUPERTRACK_SPLIT_RAIL_INDEXER: 73,
  SUPERTRACK_STACKER_ONGOING: 74,
  SUPERTRACK_STP_IO_TYPE: 75,
  THIRTY_SIX_INCH_RATCHET_LIFT: 76,
  THIRTY_SIX_INCH_TWO_WHEEL_RATCHET_LIFT: 77,
  THIRTY_SIX_INCH_SIX_INCH_TROLLEY_RATCHET_LIFT: 78,
  TWO_TROLLEY_BULK_LOADER: 79,
  SUPERTRACK_STACKER_OFFGOING: 80,
  TRANSFER_SERVO_LIFT: 81,
  BELT_CONVEYOR: 82,
  BELT_TRANSFER: 83,
  SUPERTRACK_SPLIT_RAIL_STP: 84,
  SUPERTRACK_SLING_TO_BELT: 85,
  STRAIGHT_RAIL_SERVO_LIFT: 86,
  SUPERTRACK_SPLIT_RAIL_TRANSFER: 87,
  WEIGHING_CONVEYOR: 88,
  SUPERTRACK_PNEUMATIC_VC: 89,
  SUPERTRACK_BELT_CONVEYOR: 90,
  SUPERTRACK_BELT_TRANSFER: 91,
  SUPERTRACK_WEIGHING_CONVEYOR: 92,
  SUPERTRACK_PNEUMATIC_MAINTENANCE_VC: 93,
  SUPERTRACK_RUDDER_STP: 94,
  SUPERTRACK_STATIC_BAG: 95,
  SUPERTRACK_LIFTING_STATIC_BAG: 96,
  SUPERTRACK_SERVO_MAINTENANCE_HOIST: 97,
  SUPERTRACK_TRF: 102,
  SUPERTRACK_ROTARY_TRF: 103,
  SUPERTRACK_BAG_CLOSER: 104,
  SUPERTRACK_PRIMARY_FULL_BODY_INDEXER: 105,
  SUPERTRACK_PRIMARY_INCLINE: 106,
  SUPERTRACK_PRIMARY_LOADING_POINT: 107,
  SUPERTRACK_PRIMARY_UNLOADING_POINT: 108,
  SUPERTRACK_PRIMARY_BAG_REMOVER: 109,
  SUPERTRACK_ROTARY_TRN: 110,
  GUIDED_LOADING_LIFT_WITH_JOGGING: 111,
  SUPERTRACK_PRIMARY_HALF_BODY_INDEXER: 112,
  SUPERTRACK_SERVO_FLIPTOP: 113,
  SUPERTRACK_ROTASORT: 114,
  SUPERTRACK_NO_COUNT_INDEXER: 115,
  SUPERTRACK_SERVO_TWO_POSITION: 119,
  TRANSFER_SERVO_LIFT_WITH_LOADCELL: 120,
  CB_DEBAGGER: 121,
  CB_UNTRACKED_INX: 122,
  CB_UNTRACKED_SLANT_LIFT: 123,
  SUPERTRACK_SPLIT_RAIL_BULK_LOADER: 124,
  SUPERTRACK_REAR_TROLLEY_REMOVER: 125,
  SUPERTRACK_ROLLER_SWITCH_VC: 126,
  SUPERTRACK_SLIDING_WEIGH_STATION: 127,
  SUPERTRACK_FLIPTOP_ROLLER_SWITCH_VC: 128,
  SUPERTRACK_SERVO_FOUR_POSITION: 129,
  SUPERTRACK_SERVO_SIX_POSITION: 130,
  SUPERTRACK_CUSTOM_SERVO: 131,
  SUPERTRACK_INDEXER_STP: 132,
  SUPERTRACK_WEIGH_BELT_TRANSFER: 133,
};
