import React from "react";
import RailIcon from "@mui/icons-material/CallSplit";
import { useRail } from "../../../../selectors/rails";
import FlagCardSlim from "../../../../components/FlagSelectors/FlagCardSlim";

interface Props {
  railId: number;
  disabled: boolean;
  selected: boolean;
  onClick: (id: number) => void;
};

const SourceRailCard = ({ railId, disabled, selected, onClick }: Props) => {
  const rail = useRail(railId);
  if (!rail.id) return null;
  return (
    <FlagCardSlim
      imageUrl={rail.imageUrl}
      imageIcon={<RailIcon />}
      color={rail.color}
      number={rail.number}
      name={rail.name}
      border={true}
      highlight={selected}
      disabled={disabled}
      onClick={() => onClick(rail.id)}
    />
  );
};

export default SourceRailCard;
