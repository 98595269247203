import { INITIALIZE_RAIL_VISITS, UPDATE_RAIL_VISITS } from "./types";
import { configureChannel } from "../utils";

export function railVisitSubscribe() {
  return (dispatch) => {
    railVisitsChannel.on("init", (data) => {
      dispatch(initializeRailVisits(data.data));
    });

    railVisitsChannel.on("rail_visits_update", (data) => {
      dispatch(updateRailVisits(data));
    });
  };
}

export function updateRailVisits(data) {
  return {
    type: UPDATE_RAIL_VISITS,
    data: data,
  };
}

export function initializeRailVisits(railVisits) {
  return {
    type: INITIALIZE_RAIL_VISITS,
    railVisits: railVisits,
  };
}

export const railVisitsChannel = configureChannel("rail_visits");
