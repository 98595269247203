import React, { useCallback } from "react";
import _ from "lodash";
import { AvatarProps, Box, Typography, Theme, SxProps } from "@mui/material";

interface Props {
  label?: string;
  topText?: string;
  bottomText?: string;
  imageUrl?: string;
  color?: string;
  highlight?: boolean;
  disabled?: boolean;
  size?: "medium" | "large";
  border?: boolean;
  handleClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

interface StyledAvatarInterface {
  color?: string;
  size?: string;
  labelLength?: number;
  variant?: AvatarProps["variant"];
  label?: string | number;
  children?: AvatarProps["children"];
};

interface GenerateStylesInterface {
  color?: string;
  defaultColor: string;
  size: string;
  labelLength: number;
};

interface GenFlagCardStylesInterface {
  border?: boolean;
  highlight?: boolean;
  disabled?: boolean;
};

const generateAvatarStyles = ({ color, defaultColor, size, labelLength }: GenerateStylesInterface) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "100%",
  backgroundColor: color || defaultColor,
  borderRadius: "5px 0px 0px 5px",
  padding: 1,
  color: (theme: Theme) => theme.palette.getContrastText(color || defaultColor),
  width: () => {
    const largeCard = size === "large";
    const longLabel = labelLength > 2;
    const xLongLabel = labelLength > 4;
    const xxLongLabel = labelLength > 5;

    let width = largeCard ? 50 : 40;
    if (longLabel) {
      width = largeCard ? 55 : 45;
    }

    if (xLongLabel) {
      width = largeCard ? 70 : 50;
    }

    if (xxLongLabel) {
      width = largeCard ? 80 : 55;
    }

    return width;
  },
  fontSize: (theme: Theme) => {
    const longLabel = labelLength > 3;
    const largeCard = size === "large";
    return theme.typography.pxToRem(longLabel ? (largeCard ? 20 : 14) : largeCard ? 30 : 20);
  },
});

const StyledAvatar = ({ color, size = "medium", labelLength = 0, children }: StyledAvatarInterface) => {
  const defaultColor = "#167adf";
  const styles = useCallback(() =>
    generateAvatarStyles({ color, defaultColor, size, labelLength }), [color, defaultColor, size, labelLength])() as SxProps;

  return (
    <Box sx={styles}>
      {children}
    </Box>
  );
};

const generateFlagCardStyles = (props: GenFlagCardStylesInterface): { [key: string]: SxProps } => {
  const { border, highlight, disabled } = props;

  return {
    main: {
      display: "flex",
      width: 200,
      height: "fit-content",
      borderWidth: border ? (highlight ? "3px" : "1px") : 0,
      borderStyle: "solid",
      borderColor: highlight ? "info.light" : "grey.400",
      borderRadius: 2,
      opacity: disabled ? 0.4 : 1,
    },
    textWrapper: {
      display: "flex",
      flexDirection: "column",
      py: 0.5,
      px: 1,
    },
    topText: {
      display: "box",
      lineClamp: 2,
      boxOrient: "vertical",
      textOverflow: "ellipsis",
      overflow: "hidden",
      mb: 0.5,
    },
    bottomText: {
      display: "box",
      lineClamp: 2,
      boxOrient: "vertical",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
  };
};

const FlagCard = (props: Props) => {
  const { label, topText, bottomText, imageUrl, color, highlight, disabled, size, border, handleClick } = props;
  const onClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!disabled && _.isFunction(handleClick)) handleClick(event);
  };

  const styles = useCallback(() =>
    generateFlagCardStyles({ border, highlight, disabled }), [border, highlight, disabled])();

  return (
    <Box sx={styles.main} onClick={onClick}>
      <StyledAvatar variant="rounded" labelLength={label?.length} color={color}>
        {_.isNull(label) ? " " : label}
      </StyledAvatar>

      <Box sx={styles.textWrapper}>
        {topText && (
          <Typography variant={size === "large" ? "h3" : "h4"} color="inherit" sx={styles.topText}>
            {topText}
          </Typography>
        )}

        <Typography variant={size === "large" ? "h4" : "overline"} color="secondary.main" sx={styles.bottomText}>
          {bottomText}
        </Typography>
      </Box>
    </Box>
  );
};

export default FlagCard;
