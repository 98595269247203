import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import SaveButton from "../components/SaveButton";

const useStyles = makeStyles((theme) => ({
  content: {
    height: 400,
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "50%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(8),
  },
}));

const TextEditorDialog = (props) => {
  const { t } = useTranslation();
  const { onSubmit, message, initialValue = "", submitButtonText = t("common:submit"), ...rest } = props;
  const [value, setValue] = useState(initialValue);
  useEffect(() => setValue(initialValue), [initialValue]);
  const classes = useStyles();

  return (
    <Dialog {...rest} maxWidth="sm">
      <DialogContent className={classes.content}>
        <Typography variant="h4" gutterBottom>
          {message}
        </Typography>

        <TextField
          autoFocus
          className={classes.textField}
          margin="normal"
          variant="outlined"
          value={value}
          onChange={(event) => setValue(event.target.value)}
          onFocus={(event) => event.target.select()}
        />

        <SaveButton onClick={() => onSubmit(value)}>{submitButtonText}</SaveButton>
      </DialogContent>
    </Dialog>
  );
};

export default TextEditorDialog;
