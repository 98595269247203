import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import RemoveIcon from "@mui/icons-material/RemoveCircleOutlineSharp";
import { deleteConfig, showMessage } from "../../redux/storageConfigEditor";
import { useCurrentRole } from "../../selectors/useRole";
import BaseButton from "../BaseButton";

const DeleteButton = () => {
  const dispatch = useDispatch();
  const { enableDelete } = useSelector((state) => state.storageConfigEdit);
  const { t } = useTranslation();
  const role = useCurrentRole();

  return (
    <BaseButton
      color="error"
      sx={{ maxWidth: 200 }}
      disabled={!enableDelete || !role.canEditStorageConfigs}
      startIcon={<RemoveIcon />}
      onClick={() => {
        dispatch(showMessage(t("storageAssignments:deleting_configuration")));
        dispatch(deleteConfig());
      }}
    >
      {t("common:delete")}
    </BaseButton>
  );
};

export default DeleteButton;
