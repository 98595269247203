import React from "react";
import { Select, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import FormFieldLabel from "../SetupPages/FormFieldLabel";

const languageList = [
  ["en_US", "english"],
  ["de_DE", "german"],
  ["es_ES", "spanish"],
  ["fr_FR", "french"],
  ["zh_CN", "chinese"],
  ["ja_JP", "japanese"],
  ["el_GR", "greek"],
  ["it_IT", "italian"],
  ["hu_HU", "hungarian"],
  ["pt_BR", "portuguese"],
  ["sv_SE", "swedish"],
  ["nl_BE", "dutch"],
];

const FormLanguageSelector = ({ currentValues, disabled, onFieldChange }) => {
  const { t } = useTranslation();
  const handleOnSelect = (e) => onFieldChange("language", e.target.value);

  return (
    <>
      <FormFieldLabel label={i18n.t("usersSetup:language")} />

      <Select variant="outlined" value={currentValues["language"]} disabled={disabled} onChange={handleOnSelect}>
        {languageList.map((language) => (
          <MenuItem key={language[0]} value={language[0]}>
            {t(`usersSetup:${language[1]}`)}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default FormLanguageSelector;
