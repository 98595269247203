import { useSelector, shallowEqual } from "react-redux";
import _ from "lodash";
import { RootState } from "../store";

const formatCustomer = (customer) => {
  return {
    imageUrl: customer.imageUrl,
    color: customer.color,
    id: customer.id,
    name: customer.name,
    number: customer.number,
    cleanStorageConfigId: customer.cleanStorageConfigId,
    soiledStorageConfigId: customer.soiledStorageConfigId,
    cbStorageConfigId: customer.cbStorageConfigId,
    soiledSortConfigId: customer.soiledSortConfigId,
    cleanSortConfigId: customer.cleanSortConfigId,
    ownerId: customer.ownerId,
  };
};

export const useCustomer = (customerId) =>
  useSelector((state) => {
    const customer = state.customers[customerId] || {};

    return formatCustomer(customer);
  }, shallowEqual);

export const useCustomers = () =>
  useSelector((state) => {
    return Object.values(state.customers).map((customer) => {
      return formatCustomer(customer);
    });
  }, _.isEqual);

export const selectCustomers = (state) => {
  return Object.values(state.customers).map((customer) => {
    return formatCustomer(customer);
  });
};

export const useCustomersIds = () => useSelector((state) => Object.keys(state.customers), shallowEqual);

export const getCustomerNumber = (state, customerId) => {
  const customer = state.customers[customerId];
  return customer?.number;
};

export const getCustomerName = (state, customerId) => {
  const customer = state.customers[customerId];
  return customer?.name;
};

export const useCustomerName = (customerId) =>
  useSelector((state) => {
    return state.customers[customerId]?.name;
  });

export const useCustomerIdFromNumber = (number) =>
  useSelector((state) => {
    return Object.values(state.customers)?.find((cust) => cust.number === number)?.id;
  });
