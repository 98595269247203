import React, { useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { nameComparer } from "../../../utils/sort";
import { parseLabel } from "../../../utils";
import FlagCard from "../../../components/FlagCard";
import SelectionDialog from "../../../components/SelectionDialog";
import { selectSelectedSortConfig, selectConfig, selectSelectedSystem } from "../redux/sortConfigurationsSlice";
import { useGetSortConfigurationsQuery } from "../services/sortConfigurations";

const SortConfigurationSelector = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { data, isLoading, isFetching } = useGetSortConfigurationsQuery();
  const configs = Object.values(data || {});
  const selectedSortConfiguration = useAppSelector(selectSelectedSortConfig, _.isEqual);
  const selectedSystem = useAppSelector(selectSelectedSystem);

  const { t } = useTranslation();

  return (
    <>
      <FlagCard
        disabled={isLoading || isFetching || !data}
        handleClick={() => setOpen(true)}
        name={selectedSortConfiguration ? selectedSortConfiguration.name : t("sortAssignments:select_a_config")}
        label={parseLabel(selectedSortConfiguration ? selectedSortConfiguration.name : "S C")}
      />

      {open && configs.length > 0 && (
        <SelectionDialog
          open={open}
          handleClose={() => setOpen(false)}
          title={t("sortAssignments:sort_configurations")}
          subtitle={t("sortAssignments:select_one_for_editing")}
        >
          {configs
            .filter((sc) => sc.systemId === selectedSystem?.id)
            .sort(nameComparer)
            .map((sc) => {
              return (
                <FlagCard
                  key={sc.id}
                  name={sc.name}
                  label={parseLabel(sc.name)}
                  handleClick={() => dispatch(selectConfig(sc))}
                  highlight={selectedSortConfiguration && sc.id === selectedSortConfiguration.id}
                />
              );
            })
          }
        </SelectionDialog>
      )}
    </>
  );
};

export default SortConfigurationSelector;
