import _ from "lodash";
import store from "../store";
import { showAlertError } from "../actions/alertActions";
import i18n from "../i18n";
import Client from "./Client.js";

export const getSystems = ({ successCB }) => {
  Client.get("control_api/v1/systems")
    .then((response) => {
      successCB(response.data);
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(i18n.t("common:fetch_error", { error: errorMessage })));
    });
};

export const haltSystem = () => {
  Client.get("control_api/v1/systems/halt_system")
    .then((response) => {
      console.log("System Halted");
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const unhaltSystem = () => {
  Client.get("control_api/v1/systems/unhalt_system")
    .then((response) => {
      console.log("System Unhalted");
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};
