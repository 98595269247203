import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveIcon from "@mui/icons-material/RemoveCircleOutlineSharp";
import WarningIcon from "@mui/icons-material/WarningSharp";
import CloseIcon from "@mui/icons-material/CloseSharp";
import { useTranslation } from "react-i18next";
import { useDeleteStorageGroupMutation } from "../../services/storageGroups";

interface Props {
  storageGroupId: number;
  disabled?: boolean;
};

// TODO: Replace text with translations
const DeleteStorageGroupButton = ({ storageGroupId, disabled }: Props) => {
  const { t } = useTranslation();
  const [
    deleteStorageGroup,
    { isLoading: isUpdating, isSuccess, isError },
  ] = useDeleteStorageGroupMutation();

  const [open, setOpen] = useState(false);

  const handleDelete = () => {
    deleteStorageGroup(storageGroupId)
      .unwrap()
      .then((payload) => setOpen(false))
      .catch((error) => setOpen(false))
  };

  return (
    <>
      <IconButton color="error" disabled={disabled} onClick={() => setOpen(true)}>
        <DeleteIcon sx={{ fontSize: 30 }} />
      </IconButton>

      {open && (
        <Dialog open={open} onClose={() => setOpen(false)} maxWidth={"sm"} fullWidth={true}>
          <Typography sx={{ px: 3, py: 2 }} variant="h3">{t("common:warning")}</Typography>

          <DialogContent sx={{ display: "flex", alignItems: "center" }}>
            <WarningIcon sx={{ fontSize: 80, color: theme => theme.palette.error.main }} />
            <Typography variant="body1">
              {t("You are about to delete storage group. Do you wish to proceed?")}
            </Typography>
          </DialogContent>

          <DialogActions>
            <Button variant="outlined" autoFocus onClick={() => setOpen(false)} startIcon={<CloseIcon />}>
              {t("common:cancel")}
            </Button>
            <Button
              variant="contained"
              color="error"
              disableElevation={true}
              onClick={handleDelete}
              startIcon={<RemoveIcon />}
            >
              {t("common:delete")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default DeleteStorageGroupButton;
