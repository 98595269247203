import React from "react";
import PropTypes from "prop-types";
import BaseButton from "./BaseButton";

const DeleteButton = ({ disabled, children, onClick }) => {
  return (
    <BaseButton color="error" disabled={disabled} onClick={onClick}>
      {children}
    </BaseButton>
  );
};

DeleteButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default DeleteButton;
