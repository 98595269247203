import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useCurrentRole } from "../../selectors/useRole";
import { getBarcodeFromSlingNumberForSystem, useBarcodeFromId } from "../../selectors/barcodes";
import { useTranslation } from "react-i18next";
import NumpadField from "../NumpadField";
import EmptyCard from "../EmptyCard";
import { useSystem } from "../../selectors/systems";

const useStyles = makeStyles((theme) => ({
  slingNumberWrapper: {
    display: "flex",
    flexDirection: "column",
    minWidth: 200,
    flexGrow: 1,
  },
  borderedWrapper: {
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: 5,
    padding: 5,
  },
  slingNumber: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    "& .Mui-disabled": {
      color: "inherit",
    },
  },
  slingNumberInput: {
    ...theme.typography["h3"],
    color: "inherit",
  },
  disabled: {
    opacity: 0.4,
  },
}));

const SlingNumberCard = ({ barcodeId, systemId, size, disabled, onChange }) => {
  const classes = useStyles({ barcodeId });
  const { canEditSlings } = useCurrentRole();
  const { t } = useTranslation();

  const disableCard = disabled || !canEditSlings;

  const barcode = useBarcodeFromId(barcodeId) || {};
  const slingNumber = barcode.slingNumber || "";
  const [numpadError, setNumpadError] = useState();

  const slingNumberValid = slingNumber === "" || !!barcode.id;
  useEffect(() => {
    if (slingNumber.length > 0) {
      setNumpadError(slingNumberValid ? null : t("common:not_found"));
    }
  }, [slingNumber]);

  const handleOnNumpadChange = (number) => {
    const foundBarcode = getBarcodeFromSlingNumberForSystem(number.toString(), systemId);
    const slingNumberValid = number === "" || !!foundBarcode?.id;
    setNumpadError(slingNumberValid ? null : t("common:not_found"));
  };

  const system = useSystem(systemId);
  const handleOnNumpadSubmit = (number) => {
    if (!numpadError) {
      const foundBarcode = getBarcodeFromSlingNumberForSystem(number.toString(), systemId);
      const sentBarcodeId = system.scanners ? foundBarcode?.id : barcodeId;
      onChange(sentBarcodeId);
    }
  };

  return (
    <div
      className={clsx({
        [classes.slingNumberWrapper]: true,
        [classes.borderedWrapper]: barcodeId,
        [classes.disabled]: disableCard,
      })}
    >
      <NumpadField
        value={slingNumber}
        submitValue={handleOnNumpadSubmit}
        title={t("barcodeScanner:enter_bag_number")}
        errorMessage={numpadError}
        disabled={disableCard}
        onChange={handleOnNumpadChange}
        onExited={() => setNumpadError(slingNumberValid ? null : t("common:not_found"))}
      >
        {barcodeId ? (
          <Typography variant={size === "large" ? "h1" : "h3"} color="inherit" className={classes.slingNumber}>
            {slingNumber}
          </Typography>
        ) : (
          <EmptyCard size={size} message={t("barcodeScanner:enter_bag_number")} disabled={disableCard} />
        )}
      </NumpadField>

      <Typography variant={size === "large" ? "h4" : "body2"} color="inherit">
        {barcode.barcodeNumber ? t("barcodeScanner:barcode_value", { value: barcode.barcodeNumber }) : ""}
      </Typography>
    </div>
  );
};

SlingNumberCard.propTypes = {
  systemId: PropTypes.number,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default SlingNumberCard;
