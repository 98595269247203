import React from "react";
import { useTranslation } from "react-i18next";
import { Button, FormControlLabel } from "@mui/material";
import ForwardIcon from "@mui/icons-material/Forward";
import { useRail } from "../../selectors/rails";
import { IoConstant } from "../../constants/IoConstant";

interface Props {
  railId: number;
  disabled?: boolean;
  onClick: () => void;
};

const ManualSendButton = ({ railId, disabled, onClick }: Props) => {
  const { t } = useTranslation();
  const { outputEnabled, railIoTypeId } = useRail(railId);
  const hideManualSend =
    IoConstant.SINGLE_THREE_STAGE_WITH_LOADCELL_IO_TYPE === railIoTypeId ||
    IoConstant.SINGLE_FREE_SWING_LIFT_IO_TYPE === railIoTypeId ||
    IoConstant.GUIDED_LOADING_LIFT_WITH_JOGGING === railIoTypeId;

  if (hideManualSend) return null;
  return (
    <FormControlLabel
      label={t<string>("railBar:send_load")}
      labelPlacement="top"
      control={
        <Button
          variant="contained"
          color="primary"
          disabled={disabled || !outputEnabled}
          disableElevation={true}
          onClick={onClick}
          sx={{
            borderRadius: "50%",
            width: 60,
            height: 60,
          }}
        >
          <ForwardIcon id="start-icon" sx={{ fontSize: 40 }} />
        </Button>
      }
    />
  );
};

export default ManualSendButton;
