import React, { useState } from "react";
import { AppBar, Avatar, Box, Button, IconButton, Toolbar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { toggleBatchHighhlighter, toggleRailSettings } from "../../actions";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import AlarmsIcon from "./AlarmsIcon";
import EvueLogo from "./EvueLogo";
import LaunchPad from "./Launchpad";
import HighlightIcon from "@mui/icons-material/Highlight";
import LocationSearchingIcon from "@mui/icons-material/LocationSearchingSharp";
import MenuIcon from "@mui/icons-material/Menu";
import SettingsIcon from "@mui/icons-material/SettingsSharp";
import ZoomToRailButton from "../../features/overview/components/ZoomToRailButton";
import { toggleSavedViews } from "../../features/overview/overviewSlice";
import { useIsLoggedInUserTechnician } from "../../selectors/roles";
import UserAvatar from "./UserAvatar";

const useStyles = makeStyles((theme) => ({
  appbar: {
    backgroundColor: "#313131",
  },
  avatar: {
    color: "#ffffff",
    backgroundColor: "#bdbdbd",
  },
  button: {
    minWidth: 64,
  },
  icon: {
    fontSize: 30,
  },
  [theme.breakpoints.down("sm")]: {
    avatar: {
      width: 32,
      height: 32,
    },
    button: {
      minWidth: 0,
    },
    icon: {
      fontSize: 22,
    },
  },
}));

const Topbar = (props) => {
  const { ...rest } = props;
  const location = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const technicianLoggedIn = useIsLoggedInUserTechnician();

  const handleOpenBatchHighlighter = () => dispatch(toggleBatchHighhlighter());
  const handleOpenSystemSettings = () => dispatch(toggleRailSettings());
  const handleOpenSavedViews = () => dispatch(toggleSavedViews());

  const [open, setOpen] = useState(false);

  return (
    <AppBar {...rest} classes={{ root: classes.appbar }} elevation={0}>
      <Toolbar>
        <EvueLogo />

        <Box sx={{ flexGrow: 1 }} />

        {location.pathname === "/overview" && (
          <>
            <AlarmsIcon />

            <Button onClick={handleOpenSystemSettings} className={classes.button}>
              <Avatar className={classes.avatar}>
                <SettingsIcon className={classes.icon} />
              </Avatar>
            </Button>

            <Button onClick={handleOpenBatchHighlighter} className={classes.button}>
              <Avatar className={classes.avatar}>
                <HighlightIcon className={classes.icon} />
              </Avatar>
            </Button>

            {technicianLoggedIn && <ZoomToRailButton />}

            <Button onClick={handleOpenSavedViews} className={classes.button}>
              <Avatar className={classes.avatar}>
                <LocationSearchingIcon className={classes.icon} />
              </Avatar>
            </Button>
          </>
        )}

        <UserAvatar />

        <IconButton color="inherit" onClick={() => setOpen(!open)}>
          <MenuIcon />
        </IconButton>
      </Toolbar>

      {open && <LaunchPad open={open} handleClose={() => setOpen(false)} />}
    </AppBar>
  );
};

export default Topbar;
