import React, { useEffect, useState, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/CheckCircleOutlineSharp";
import EditIcon from "@mui/icons-material/DriveFileRenameOutlineSharp";
import SaveButton from "../../../../components/SaveButton";
import Loader from "../../../../components/Loader";
import { useUpdateStorageGroupMutation } from "../../services/storageGroups";
import { StorageGroup } from "../../dto/StorageGroup";
import useStorageGroupAlertMessages from "../../hooks/useStorageGroupAlertMessages";

interface Props {
  storageGroup: StorageGroup;
  disabled: boolean;
};

const NameInputWithDialog = ({ storageGroup, disabled }: Props) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [editedName, setEditedName] = useState(storageGroup.name || "");
  useEffect(() => {
    setEditedName(storageGroup.name)
  }, [storageGroup.name]);

  const handleOnClose = () => setOpen(false);

  const { onSuccess, onFailure } = useStorageGroupAlertMessages();
  const [updateStorageGroup, { isLoading }] = useUpdateStorageGroupMutation();
  const handleOnSubmit = () => {
    if (editedName.length > 0) {
      const { id, systemId } = storageGroup;
      updateStorageGroup({ id, systemId, name: editedName })
        .unwrap()
        .then(() => {
          onSuccess();
          handleOnClose();
        })
        .catch((error) => onFailure(error));
    }
  };

  return (
    <>
      <IconButton disabled={disabled} onClick={() => setOpen(true)}>
        <EditIcon />
      </IconButton>

      {isLoading && (
        <Loader open={isLoading} message={t("storageManager:saving_storage_group")} transitionDuration={300} />
      )}

      {open && (
        <Dialog
          open={open}
          fullWidth={true}
          TransitionProps={{
            onExited: handleOnClose,
          }}
        >
          <Box px={3}>
            <Typography variant="h3">{t("storageManager:edit_storage_group_name")}</Typography>
          </Box>

          <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
            <TextField
              sx={{ width: "100%" }}
              autoFocus
              margin="normal"
              variant="outlined"
              value={editedName}
              disabled={disabled}
              error={editedName.length < 1}
              label={editedName.length < 1 ? "Invalid name" : ""}
              onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                return setEditedName(event.target.value || "");
              }}
            />
          </DialogContent>

          <DialogActions>
            <Button
              variant="outlined"
              autoFocus
              disableElevation
              onClick={handleOnClose}
              startIcon={<CloseIcon />}
            >
              {t("common:close")}
            </Button>

            <SaveButton startIcon={<CheckIcon />} onClick={handleOnSubmit}>
              {t("common:submit")}
            </SaveButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default NameInputWithDialog;
