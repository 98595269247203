import * as actions from "../actions/types";

const alertReducer = (state = {}, action) => {
  switch (action.type) {
    case actions.ALERT_SUCCESS:
      return {
        ...state,
        alertOpen: true,
        alertMessage: action.message,
        alertVariant: "success",
      };
    case actions.ALERT_ERROR:
      return {
        ...state,
        alertOpen: true,
        alertMessage: action.message,
        alertVariant: "error",
      };
    case actions.ALERT_INFO:
      return {
        ...state,
        alertOpen: true,
        alertMessage: action.message,
        alertVariant: "info",
      };
    case actions.ALERT_WARNING:
      return {
        ...state,
        alertOpen: true,
        alertMessage: action.message,
        alertVariant: "warning",
      };
    case actions.ALERT_CLEAR:
      return {
        ...state,
        alertOpen: false,
      };
    default:
      return state;
  }
};

export default alertReducer;
