import { createSlice } from "@reduxjs/toolkit";

const productionListStatusSlice = createSlice({
  name: "productionListStatus",
  initialState: {
    open: false,
  },
  reducers: {
    setProductionListStatusOpen: (state, action) => {
      state.open = action.payload;
    },
  },
});

export const { setProductionListStatusOpen } = productionListStatusSlice.actions;

export const productionListStatusOpen = (state) => state.productionListStatus.open;

export default productionListStatusSlice.reducer;
