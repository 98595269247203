import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import {
  Card,
  CardContent,
  List,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  listItem: {
    marginTop: theme.spacing(6),
  },
}));

const Rails = (props) => {
  const classes = useStyles();
  const { className, ...rest } = props;
  const { t } = useTranslation();

  const batches = useSelector((state) => state.batches);
  const rails = useSelector((state) => state.rails);
  const railVisits = useSelector((state) => state.railVisits);

  return (
    <div className={classes.root}>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent>
          <Typography variant="h1" gutterBottom>
            {t("common:rails")}
          </Typography>

          <List className={classes.content}>
            {Object.values(rails).map((rail) => {
              const visits = railVisits[rail.id] || [];

              return (
                <ListItemText className={classes.listItem}>
                  <Typography variant="h3" color="textSecondary" gutterBottom>
                    {rail.name} ({rail.label}, ID: {rail.id})
                  </Typography>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>{t("common:position")}</TableCell>
                        <TableCell>{t("common:batch")} ID</TableCell>
                        <TableCell>{t("common:category")} ID</TableCell>
                        <TableCell>{t("common:customer")} ID</TableCell>
                        <TableCell>{t("common:weight")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {visits.map((railVisit) => {
                        const batch = batches[railVisit.batchId] || {};
                        return (
                          <TableRow key={`rail-${rail.id}-${railVisit.position}`}>
                            <TableCell>{railVisit.position}</TableCell>
                            <TableCell>{batch.id}</TableCell>
                            <TableCell>{batch.categoryId}</TableCell>
                            <TableCell>{batch.customerId}</TableCell>
                            <TableCell>{batch.weight}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </ListItemText>
              );
            })}
          </List>
        </CardContent>
      </Card>
    </div>
  );
};

export default Rails;
