import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Avatar, Box, Button, Chip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { updateCategoryGroup, deleteCategoryGroup } from "../../api/CategoryGroupApi";
import MultiCategorySelector from "./MultiCategorySelector";
import { CategoryGroup as CategoryGroupType } from "../../definitions/CategoryGroup";

interface Props {
  categoryGroup: CategoryGroupType,
  disabled: boolean,
}

const CategoryGroup = ({ categoryGroup, disabled }: Props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [cgEdits, setCgEdits] = useState({ ...categoryGroup });

  useEffect(() => {
    setCgEdits({ ...categoryGroup });
  }, [open]);

  const handleCategorySelect = (catId: number) => {
    const foundIdIndex = cgEdits?.categoryIds?.findIndex((id) => id === catId);
    const updatedCatIds = cgEdits?.categoryIds?.slice() || [];
    if (foundIdIndex >= 0) {
      updatedCatIds.splice(foundIdIndex, 1);
    } else {
      updatedCatIds.push(catId);
    }

    setCgEdits({
      ...cgEdits,
      categoryIds: updatedCatIds,
    });
  };

  const handleSaveCategoryGroup = () => {
    updateCategoryGroup({
      categoryGroupId: cgEdits?.id,
      categoryIds: cgEdits?.categoryIds,
    });
    setOpen(false);
  };

  if (!cgEdits) return null;
  return (
    <>
      <Chip
        avatar={<Avatar>{cgEdits?.id}</Avatar>}
        color="primary"
        label={cgEdits?.name}
        variant="outlined"
        disabled={disabled}
        onClick={() => setOpen(true)}
        onDelete={() => deleteCategoryGroup({
          categoryGroupId: cgEdits?.id,
        })}
      />

      {open && (
        <MultiCategorySelector
          open={open}
          selectedCategoryIds={cgEdits?.categoryIds}
          handleSelect={handleCategorySelect}
          onClose={() => setOpen(false)}
          footer={
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                color="success"
                disableElevation
                sx={{ mr: 1 }}
                disabled={disabled}
                onClick={handleSaveCategoryGroup}
              >
                {t<string>("common:save")}
              </Button>

              <Button variant="outlined" color="error" onClick={() => setOpen(false)}>
                {t<string>("common:cancel")}
              </Button>
            </Box>
          }
        />
      )}
    </>
  );
};

export default CategoryGroup;
