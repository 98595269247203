import { AnyAction } from "redux";
import { createReducer } from '@reduxjs/toolkit'
import _ from "lodash";
import { objFromArray, singularizeResource } from "../utils";

interface GenericState<T> { [key: string]: T }
interface GenericResource { [key: string]: string | boolean | number | {} | [] }
interface GenThing { [key: string]: string }

const simpleReducer = <T = GenericResource>(resource: string) => {
  const singular = singularizeResource(resource);
  const initAction = `INITIALIZE_${resource.toUpperCase()}`;
  const updateAction = `UPDATE_${singular.toUpperCase()}`;
  const updateManyAction = `UPDATE_${resource.toUpperCase()}`;
  const deleteAction = `DELETE_${singular.toUpperCase()}`;

  const initialState: GenericState<T> = {};

  const genericReducer = createReducer(initialState, (builder) => {
    builder
      .addCase(initAction, (state, action: AnyAction) => {
        return objFromArray(action[_.camelCase(resource)]);
      })
      .addCase(updateAction, (state, action: AnyAction) => {
        const resourceId = action.entity.id;
        state[resourceId] = { ...state[resourceId], ...action.entity };
      })
      .addCase(updateManyAction, (state, action: AnyAction) => {
        action.entities.forEach((entity: GenThing) => {
          const resourceId = entity.id;
          state[resourceId] = { ...state[resourceId], ...entity };
        });
      })
      .addCase(deleteAction, (state, action: AnyAction) => {
        delete state[action.id];
      })
  });

  return genericReducer;
}

export default simpleReducer;
