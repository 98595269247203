import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector, shallowEqual } from "react-redux";
import SortCard from "../../../components/CustChangeSortStationCard";
import { useRail } from "../../../selectors/rails";
import { useCategory } from "../../../selectors/categories";
import { useCustomer } from "../../../selectors/customers";
import { useBatch } from "../../../selectors/batches";
import { getRailVisit } from "../../../selectors/railVisits";
import { ackManualEmpty, custChangeManSend } from "../../../api/CustChangeStationApi";

const computeStatus = (ss, base) => {
  if (ss.currentCustomerId !== ss.nextCustomerId && ss.manualEmpty) return "ACTION_REQUIRED";
  if (ss.currentCustomerId !== ss.nextCustomerId) return "IN_PROGRESS";

  if (ss.currentCustomerId === base) return "COMPLETE";

  return "PENDING";
};

const CustChangeSortStation = ({ sortStation, bankCurrentCustomerId }) => {
  const rail = useRail(sortStation.railId);
  const currentCategory = useCategory(sortStation.categoryId);
  const nextCategory = useCategory(sortStation.nextCategoryId);
  const currentCustomer = useCustomer(sortStation.currentCustomerId);
  const nextCustomer = useCustomer(sortStation.nextCustomerId);
  const visit = useSelector((state) => getRailVisit(state, rail.id, 1), shallowEqual) || {};
  const batch = useBatch(visit.batchId);
  const { t } = useTranslation();

  // TODO: Unused variable
  const custChangeInProgress = sortStation.currentCustomerId !== sortStation.nextCustomerId;

  const changeStatus = computeStatus(sortStation, bankCurrentCustomerId);

  return (
    <SortCard
      sortStationId={sortStation.id}
      currentCategory={currentCategory}
      nextCategory={nextCategory}
      currentCustomer={currentCustomer}
      nextCustomer={nextCustomer}
      manualEmpty={sortStation.manualEmpty}
      ackManualEmpty={() => ackManualEmpty(sortStation.id)}
      manSend={() => custChangeManSend(sortStation.id)}
      changeStatus={changeStatus}
      name={rail.name}
      weight={batch.id ? batch.weight : t("customerChange:no_bag")}
    />
  );
};

CustChangeSortStation.propTypes = {
  sortStation: PropTypes.object.isRequired,
};

export default CustChangeSortStation;
