import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRail } from "../../selectors/rails";
import Loader from "../../components/Loader";

const StaticBagRailLoaderDialog = ({ railId }) => {
  const { t } = useTranslation();
  const [showLoader, setShowLoader] = useState(false);
  const [loaderMesssage, setLoaderMessage] = useState(null);
  const staticBagRail = useRail(railId);

  useEffect(() => {
    if (staticBagRail.plcState === "unclamping") {
      setLoaderMessage(t("staticBag:working"));
      setShowLoader(true);
    } else {
      loaderMesssage && setLoaderMessage(null);
      showLoader && setShowLoader(false);
    }
  }, [staticBagRail.plcState]);

  return <Loader open={showLoader} message={loaderMesssage} />;
};

export default StaticBagRailLoaderDialog;
