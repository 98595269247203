import React from "react";
import { Button, SxProps, Palette, PaletteColor } from "@mui/material";

interface Props {
  id?: number | string;
  color?: keyof Palette;
  colorType?: keyof PaletteColor;
  disableElevation?: boolean;
  endIcon?: JSX.Element;
  disabled?: boolean;
  startIcon?: JSX.Element;
  onClick?: () => void;
  children?: string;
  className?: string;
  variant?: "text" | "outlined" | "contained";
  sx?: SxProps;
};

const BaseButton = (props: Props) => {
  const {
    id,
    color = "primary",
    colorType = "main",
    disableElevation = true,
    endIcon,
    disabled,
    startIcon,
    onClick,
    children,
    className,
    variant = "contained",
    sx,
    ...rest
  } = props;

  return (
    <Button
      id={`${id}`}
      className={className}
      sx={{
        border: variant === "outlined" ? `1px solid ${color}.${colorType}` : "none",
        backgroundColor: variant === "outlined" ? "transparent" : `${color}.${colorType}`,
        color: variant === "outlined" ? `${color}.${colorType}` : "none",

        "&:hover": {
          border: variant === "outlined" ? `1px solid ${color}.${colorType}` : "none",
          backgroundColor: variant === "outlined" ? "transparent" : `${color}.${colorType}`,
        },
        ...sx,
      }}
      variant={variant || "text"}
      startIcon={startIcon}
      endIcon={endIcon}
      disabled={disabled}
      disableElevation={disableElevation}
      onClick={onClick}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default BaseButton;
