import React from "react";
import _ from "lodash";
import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCategoryGroups } from "../../selectors/categoryGroups";
import { useCurrentRole } from "../../selectors/useRole";
import { useShowCategoryGroups } from "../../selectors/uiSettings";
import AddCategoryGroupButton from "./AddCategoryGroupButton";
import CategoryGroup from "./CategoryGroup";
import { CategoryGroups } from "../../definitions/CategoryGroups";

const CategoryGroupsList = () => {
  const { t } = useTranslation();
  const categoryGroups: CategoryGroups = useCategoryGroups();
  const showCategoryGroups = useShowCategoryGroups();
  const currentRole = useCurrentRole();

  return (
    <Paper square={true} elevation={0} sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box padding={3} display="flex" justifyContent="space-between" alignItems="flex-end">
        <Typography variant="h1">{t<string>("categoryGroupsSetup:category_groups")}</Typography>
        <AddCategoryGroupButton size="small" disabled={!currentRole?.canManage || !showCategoryGroups} />
      </Box>

      <Divider />

      <Grid container spacing={3} sx={{ padding: 3 }}>
        {Object.values(categoryGroups)?.map((cg) => (
          <Grid key={cg?.id} item>
            <CategoryGroup categoryGroup={cg} disabled={!currentRole?.canManage || !showCategoryGroups} />
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

export default CategoryGroupsList;
