import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Badge, Button } from "@mui/material";
import CustomerIcon from "@mui/icons-material/Apartment";
import { useCustomers } from "../../selectors/customers";
import FlagCard from "../FlagCard";
import SelectionDialog from "../SelectionDialog";

const MultiCustomerSelector = ({ selectedCustomerIds = [], disabled, handleSelect }) => {
  const customers = useCustomers();
  const sortedCustomers = customers.slice().sort((a, b) => a.number - b.number);
  const { t } = useTranslation();

  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <Badge
        sx={{ opacity: disabled ? 0.4 : 1 }}
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        badgeContent={selectedCustomerIds.length || "0"}
        color="primary"
      >
        <Button
          variant="outlined"
          color="primary"
          disabled={disabled}
          sx={{ borderRadius: "50%", minWidth: 0, width: 60, height: 60, padding: 2 }}
          onClick={() => setDialogOpen(true)}
        >
          <CustomerIcon sx={{ fontSize: 40 }} />
        </Button>
      </Badge>

      {dialogOpen && (
        <SelectionDialog
          open={dialogOpen}
          title={t("common:select_customer")}
          subtitle=""
          handleClose={() => setDialogOpen(false)}
        >
          {sortedCustomers.map((selection) => {
            return (
              <FlagCard
                key={selection.id}
                name={selection.name}
                label={selection.number}
                imageUrl={selection.imageUrl}
                color={selection.color}
                handleClick={() => handleSelect(selection.id)}
                highlight={selectedCustomerIds.includes(selection.id)}
              />
            );
          })}
        </SelectionDialog>
      )}
    </>
  );
};

export default MultiCustomerSelector;
