import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Badge, Button } from "@mui/material";
import LaborTasksIcon from "@mui/icons-material/GroupWork";
import SelectionDialog from "../SelectionDialog";
import FormFieldLabel from "../SetupPages/FormFieldLabel";
import SlimLaborTaskFlagCard from "./SlimLaborTaskFlagCard";

const FormLaborTaskSelector = (props) => {
  const { currentValues, laborTaskList, disabled, onArrayFieldChange } = props;
  const selectedLaborTaskIds = currentValues["laborTaskIds"] || [];
  const sortedTaskList = laborTaskList.slice().sort((a, b) => a.name - b.name);
  const { t } = useTranslation();

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleOnSelect = (id) => {
    const foundIdIndex = currentValues["laborTaskIds"]?.findIndex((ltId) => ltId === id);
    if (foundIdIndex >= 0) {
      return onArrayFieldChange({
        field: "laborTaskIds",
        operation: "remove",
        index: foundIdIndex,
      });
    } else {
      return onArrayFieldChange({
        field: "laborTaskIds",
        operation: "add",
        value: id,
      });
    }
  };

  return (
    <>
      <FormFieldLabel label={t("common:labor_tasks")} />
      <Badge
        sx={{ opacity: disabled ? 0.4 : 1 }}
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        badgeContent={selectedLaborTaskIds.length || "0"}
        color="primary"
      >
        <Button
          variant="outlined"
          color="primary"
          disabled={disabled}
          sx={{ borderRadius: "50%", minWidth: 0, width: 60, height: 60, padding: 2 }}
          onClick={() => setDialogOpen(true)}
        >
          <LaborTasksIcon sx={{ fontSize: 40 }} />
        </Button>
      </Badge>

      {dialogOpen && (
        <SelectionDialog
          open={dialogOpen}
          title={t("usersSetup:select_labor_tasks")}
          closeOnClick={false}
          handleClose={() => setDialogOpen(false)}
        >
          {sortedTaskList.map((selection) => {
            return (
              <SlimLaborTaskFlagCard
                key={selection.id}
                laborTask={selection}
                highlight={selectedLaborTaskIds.includes(selection.id)}
                onClick={() => handleOnSelect(selection.id)}
              />
            );
          })}
        </SelectionDialog>
      )}
    </>
  );
};

export default FormLaborTaskSelector;
