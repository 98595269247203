import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import type { System } from "../../../dto/eVue/System";

interface Props {
  system: System
  children?: JSX.Element;
};

const StorageManagerHeading = ({ system, children }: Props) => {
  const { t } = useTranslation();
  const systemName = system.name ? ` | ${system.name}` : "";

  return (
    <Box padding={3} display="flex" justifyContent="space-between" flexWrap="wrap">
      <Typography variant="h1" sx={{ mb: 1 }}>
        {`${t("storageManager:storage_manager")}${systemName}`}
      </Typography>

      {children}
    </Box>
  )
};

export default StorageManagerHeading;
