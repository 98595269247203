import React, { useState } from "react";
import type { FilterSelections } from "../../../components/FlagSelectors/dto/FilterSelections";
import type { Order } from "../dto/Order";

const useSortingAndFiltering = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [order, setOrder] = useState<Order>("asc");
  const [sortBy, setSortBy] = useState<FilterSelections>("number");
  const handleChangeSortBy = (selected: FilterSelections) => {
    if (selected === sortBy) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      setOrder("asc");
      setSortBy(selected);
    }
  };

  const handleChangeSearchTerm = (term: string) => setSearchTerm(term);

  return { order, sortBy, handleChangeSortBy, searchTerm, handleChangeSearchTerm };
};

export default useSortingAndFiltering;
