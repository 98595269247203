import { useSelector } from "react-redux";
import _ from "lodash";
import { FaultUtils } from "@etechinc/overview-sdk";
import { useServerStatus } from "./serverStatus";
import { useAlarmingWeightCheckRailIds } from "./weightChecks";
import { useAlarmingBarcodeCheckRailIds } from "./barcodeChecks";
import { usePlcChannels } from "./plcChannels";

export const useAlarms = () => {
  const rails = useSelector((state) => state.rails, _.isEqual);
  const weightCheckFaultRailIds = useAlarmingWeightCheckRailIds();
  const barcodeCheckFaultRailIds = useAlarmingBarcodeCheckRailIds();
  const plcChannels = usePlcChannels();
  const { heartbeat, ioForced, plcOffline, systemHalted } = useServerStatus();
  const alarms = [];

  // Find all alarming rails with at least one fault
  Object.values(rails).map((rail) => {
    if (
      rail?.faults?.length > 0 ||
      weightCheckFaultRailIds.includes(rail.id) ||
      barcodeCheckFaultRailIds.includes(rail.id)
    ) {
      alarms.push(rail);
    }
  });

  // Sort alarming rails by error first, then warning
  alarms.sort(FaultUtils.errorComparer);

  return {
    rails: alarms,
    serverOffline: !heartbeat,
    ioForced,
    plcOffline,
    systemHalted,
    plcModbus: plcChannels.hasOwnProperty("modbus") && !plcChannels.modbus.online,
    plcOpcua: plcChannels.hasOwnProperty("opcua") && !plcChannels.opcua.online,
    plcTcp: plcChannels.hasOwnProperty("tcp") && !plcChannels.tcp.online,
    plcUdp: plcChannels.hasOwnProperty("udp") && !plcChannels.udp.online,
  };
};
