import React from "react";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import WarningIcon from "@mui/icons-material/WarningOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  "@keyframes blinker": {
    "0%": { opacity: 1 },
    "40%": { opacity: 0 },
    "50%": { opacity: 0 },
    "60%": { opacity: 0 },
    "70%": { opacity: 0 },
    "90%": { opacity: 1 },
    "100%": { opacity: 1 },
  },
  blinkEffect: {
    animationName: "$blinker",
    animationDuration: "4s",
    animationTimingFunction: "linear",
    animationIterationCount: "infinite",
  },
});

const BagStatusIndicator = ({ status = "normal", currentWeight, minWeight, targetWeight, maxWeight }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  if (status === "normal") return null;
  let wrapperStyles = {
    borderRadius: 5,
    border: "1px solid",
  };
  let icon, textValue, textSubValue;
  if (status === "missing") {
    icon = <InfoIcon sx={{ fontSize: 120 }} />;
    wrapperStyles = {
      color: "info.dark",
    };
    textValue = t("railStates:waiting_for_bag");
  } else if (status === "underweight") {
    wrapperStyles["backgroundColor"] = "warning.light";
    wrapperStyles["borderColor"] = "warning.dark";
    wrapperStyles["color"] = "warning.dark";

    icon = <WarningIcon sx={{ fontSize: 80 }} />;
    textValue = t("slingInputStation:bag_underweight");
    textSubValue = `${t("slingInputStation:min_weight")}: ${minWeight}`;
  } else if (status === "target_met") {
    wrapperStyles["backgroundColor"] = "success.light";
    wrapperStyles["borderColor"] = "success.dark";
    wrapperStyles["color"] = "common.white";

    icon = <CheckCircleIcon sx={{ fontSize: 80 }} />;
    textValue = t("slingInputStation:bag_ready");
    textSubValue = `${t("slingInputStation:target_weight")}: ${targetWeight}`;
  } else if (status === "overweight") {
    wrapperStyles["backgroundColor"] = "error.light";
    wrapperStyles["borderColor"] = "error.dark";
    wrapperStyles["color"] = "common.white";

    icon = <WarningIcon sx={{ fontSize: 80 }} />;
    textValue = t("slingInputStation:bag_overweight");
    textSubValue = `${t("slingInputStation:max_weight")}: ${maxWeight}`;
  } else {
    wrapperStyles["backgroundColor"] = "warning.light";
    wrapperStyles["borderColor"] = "warning.dark";
    wrapperStyles["color"] = "warning.dark";

    icon = <WarningIcon sx={{ fontSize: 80 }} />;
    textValue = t(`slingInputStation:${currentWeight >= maxWeight ? "bag_full" : "bag_almost_full"}`);
    textSubValue = `${t("slingInputStation:max_weight")}: ${maxWeight}`;
  }

  wrapperStyles.padding = "8px 16px";

  return (
    <Box
      display="flex"
      flexDirection={status === "missing" ? "column" : "row"}
      alignItems="center"
      justifyContent="center"
      className={classes.blinkEffect}
      sx={wrapperStyles}
    >
      {icon}
      <div>
        <Typography color="inherit" sx={{ textTransform: "uppercase" }} variant="h1" align="center">
          {textValue}
        </Typography>
        <Typography color="inherit" variant="h2" align="center">
          {textSubValue}
        </Typography>
      </div>
    </Box>
  );
};

export default BagStatusIndicator;
