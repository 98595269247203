import React, { useEffect, useState } from "react";
import moment from "moment";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { Box, Dialog, Grid, Table, TableBody, TableRow, TableCell } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useShowAbout } from "../selectors/local";
import {
  useJobNumber,
  useVersion,
  useMsgServicePhone,
  useAppStartTime,
  useEvueServerRole,
  useEvuePlcIp,
} from "../selectors/uiSettings";
import { hideAbout } from "../actions/localActions";
import KnLogo from "../assets/logos/kge_logo.png";
import eVueLogo from "../assets/logos/evue_logo_small.png";
import CloseButton from "./CloseButton";

const calculateElapsedTime = (appStartTime) => {
  const currentTime = moment();
  const elapsedSeconds = Math.floor(moment.duration(currentTime.diff(appStartTime)).asSeconds());
  const elapsedMinutes = Math.round(elapsedSeconds / 60);
  return elapsedMinutes;
};

const TimeInfo = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const appStartTime = useAppStartTime();
  const appStartTimeMoment = moment(appStartTime);

  const [elapsedMinutes, setElapsedMinutes] = useState(0);
  useEffect(() => {
    const elapsedMinutes = calculateElapsedTime(appStartTimeMoment);
    setElapsedMinutes(elapsedMinutes);

    const interval = setInterval(() => {
      const elapsedMinutes = calculateElapsedTime(appStartTimeMoment);
      setElapsedMinutes(elapsedMinutes);
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <TableRow key={t("about:time_app_loaded")}>
        <TableCell className={classes.cellHeading}>{t("about:time_app_loaded")}</TableCell>
        <TableCell className={classes.cellValue} align="right">
          {appStartTimeMoment.format("LT")}
        </TableCell>
      </TableRow>

      <TableRow key={t("about:time_elapsed")}>
        <TableCell className={classes.cellHeading}>{t("about:time_elapsed")}</TableCell>
        <TableCell className={classes.cellValue} align="right">
          {elapsedMinutes}
        </TableCell>
      </TableRow>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    maxHeight: "calc(100vh - 180px)",
    backgroundColor: "#091623",
  },
  content: {
    color: theme.palette.white,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  images: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  knLogo: {
    width: "40%",
  },
  table: {
    color: theme.palette.white,
    width: "fit-content",
  },
  spacedCell: {
    paddingTop: theme.spacing(2),
  },
  cellHeading: {
    color: theme.palette.grey[400],
    borderBottom: "none",
    textTransform: "uppercase",
    paddingRight: theme.spacing(2),
  },
  cellValue: {
    color: theme.palette.white,
    borderBottom: "none",
  },
  time: {
    marginTop: theme.spacing(1),
  },
}));

const AboutDialog = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const open = useShowAbout();
  const jobNumber = useJobNumber();
  const version = useVersion();
  const msgServicePhone = useMsgServicePhone();
  const eVueServerRole = useEvueServerRole();
  const eVuePlcIp = useEvuePlcIp();

  const info = [
    { label: t("about:software_version"), value: version },
    { label: t("about:site_id"), value: jobNumber },
    { label: t("about:etext_phone"), value: msgServicePhone },
    { label: t("about:plc_ip"), value: eVuePlcIp },
    { label: t("about:server_role"), value: eVueServerRole || "Not Configured" },
  ];

  if (!open) return null;
  return (
    <Dialog open={open} fullWidth={true} maxWidth="md" classes={{ paper: classes.dialogPaper }}>
      <Grid container direction="column" alignItems="center" className={classes.content}>
        <Box className={classes.images}>
          <img src={KnLogo} className={classes.knLogo} />
          <img src={eVueLogo} />
        </Box>

        <Table className={classes.table}>
          <TableBody>
            {info.map((field, i) => (
              <TableRow key={field.label}>
                <TableCell className={clsx({ [classes.cellHeading]: true, [classes.spacedCell]: i === 3 })}>
                  {field.label}
                </TableCell>
                <TableCell className={clsx({ [classes.cellValue]: true, [classes.spacedCell]: i === 3 })} align="right">
                  {field.value}
                </TableCell>
              </TableRow>
            ))}

            <TimeInfo />
          </TableBody>
        </Table>

        <Box mt={4}>
          <CloseButton onClick={() => dispatch(hideAbout())} />
        </Box>
      </Grid>
    </Dialog>
  );
};

export default AboutDialog;
