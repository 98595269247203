import React from "react";
import { FormControl, Select, Typography, MenuItem } from "@mui/material";
import { withStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

const StyledFormControl = withStyles({
  root: { width: "inherit" },
})(FormControl);

const SlingTypeSelector = ({ slingTypeList, selectedST, disabled, onChange }) => {
  const { t } = useTranslation();
  const handleChange = (e) => onChange(e.target.value === "none" ? null : e.target.value);

  return !slingTypeList || slingTypeList?.length === 0 ? (
    <Typography variant="caption">{t("categoriesSetup:no_sling_types")}</Typography>
  ) : (
    <StyledFormControl variant="outlined">
      <Select value={selectedST || "none"} disabled={disabled} onChange={handleChange}>
        <MenuItem value="none">
          <em>{t("categoriesSetup:none")}</em>
        </MenuItem>
        {slingTypeList.map((st) => (
          <MenuItem key={st.id} value={st.id}>
            {st.name}
          </MenuItem>
        ))}
      </Select>
    </StyledFormControl>
  );
};

export default SlingTypeSelector;
