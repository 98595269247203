import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import CategoryIcon from "@mui/icons-material/Category";
import { useTranslation } from "react-i18next";
import {
  useShowFormula,
  useShowExternal,
  useIsTrolley,
  getShowSoilFactor,
  getShowPieceWeight,
} from "../../selectors/uiSettings";
import { useAppSelector } from "../../hooks/useAppSelector";
import FormNumberField from "../SetupPages/FormNumberField";
import ImageUploadButton from "../SetupPages/ImageUploadButton";
import SwatchColorInput from "../SetupPages/SwatchColorInput";
import FormFieldLabel from "../SetupPages/FormFieldLabel";
import FormTextField from "../SetupPages/FormTextField";
import FormSuperCategoryIdSelector from "./FormSuperCategoryIdSelector";

const DescriptionGroup = ({ currentValues, errors, setupData, disabled, onFieldChange }) => {
  const { t } = useTranslation();
  const showExternal = useShowExternal();
  const isTrolley = useIsTrolley();
  const showFormula = useShowFormula();
  const defaultCategory = currentValues?.number === 0;
  const showSoilFactor = useAppSelector(getShowSoilFactor);
  const showPieceWeight = useAppSelector(getShowPieceWeight);

  return (
    <>
      <Grid item sx={{ mb: 1 }}>
        <Typography variant="h4">{t("common:description")}</Typography>
      </Grid>

      <Grid container direction="column">
        <Grid item container sx={{ mt: 1 }} alignItems="end">
          <Grid item xs={4}></Grid>

          <Grid item xs={4} sx={{ display: "flex", justifyContent: "center" }}>
            <ImageUploadButton
              defaultIcon={<CategoryIcon sx={{ fontSize: 80 }} />}
              imageUrl={currentValues["imageUrl"]}
              disabled={disabled}
              onUpload={(imageUrl) => onFieldChange("imageUrl", imageUrl)}
            />
          </Grid>

          <Grid item xs={4} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Box>
              <FormFieldLabel label={t("common:color")} />
              <SwatchColorInput
                selectedColor={currentValues["color"] || ""}
                disabled={disabled}
                onChange={(color) => onFieldChange("color", color)}
              />
            </Box>
          </Grid>
        </Grid>

        <FormTextField
          fieldName="name"
          label={t("common:name")}
          currentValues={currentValues}
          errors={errors}
          disabled={disabled}
          onFieldChange={onFieldChange}
        />

        {showExternal && (
          <FormTextField
            fieldName="externalReference"
            label={t("common:external_reference")}
            currentValues={currentValues}
            errors={errors}
            disabled={disabled}
            onFieldChange={onFieldChange}
          />
        )}

        <FormNumberField
          fieldName="number"
          label={t("common:number")}
          currentValues={currentValues}
          errors={errors}
          disabled={disabled || defaultCategory}
          onFieldChange={onFieldChange}
        />

        {showPieceWeight && !isTrolley && (
          <FormNumberField
            fieldName="pieceWeight"
            label={t("categoriesSetup:piece_weight")}
            currentValues={currentValues}
            errors={errors}
            type="float"
            disabled={disabled}
            onFieldChange={onFieldChange}
          />
        )}

        {showSoilFactor && !isTrolley && (
          <FormNumberField
            fieldName="soilfactor"
            label={t("categoriesSetup:soil_factor")}
            helperText={t("categoriesSetup:soil_factor_helper")}
            currentValues={currentValues}
            errors={errors}
            type="float"
            disabled={disabled}
            onFieldChange={onFieldChange}
          />
        )}

        {!isTrolley && showFormula && (
          <FormNumberField
            fieldName="formula"
            label={t("categoriesSetup:formula")}
            currentValues={currentValues}
            errors={errors}
            setupData={setupData}
            disabled={disabled}
            onFieldChange={onFieldChange}
          />
        )}

        <FormSuperCategoryIdSelector currentValues={currentValues} disabled={disabled} onFieldChange={onFieldChange} />
      </Grid>
    </>
  );
};

export default DescriptionGroup;
