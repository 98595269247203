import React from "react";
import { useCategory } from "../../../selectors/categories";
import { useRail } from "../../../selectors/rails";
import SortStationAssignmentCard from "../../../components/SortStationAssignmentCard";
import CategoryCardSlim from "../../../components/FlagSelectors/CategoryCardSlim";

interface Props {
  railId?: number;
  sortClassId?: number;
  disabled?: boolean;
  onClick: () => void;
};

const SortStationAssignment = ({ railId, sortClassId, disabled, onClick }: Props) => {
  const rail = useRail(railId);
  const sortClass = useCategory(sortClassId);
  const { imageUrl, name, number } = sortClass;

  return (
    <SortStationAssignmentCard
      categoryCard={
        <CategoryCardSlim
          imageUrl={imageUrl}
          number={number}
          name={name}
          border={false}
          disabled={false}
          highlight={false}
          onClick={() => ({})}
        />
      }
      rail={rail}
      disabled={disabled}
      onClick={onClick}
    />
  );
};

export default SortStationAssignment;
