import { useEffect, useState } from "react";
import { performDecodeScan } from "../../../api/SlingInputStationApi";

let buffer = "";
let timer: NodeJS.Timeout | undefined = undefined;
const TIME = 500;

/**
 * On KeyDown, start capture of input from the barcode scanner,
 * unless currently typing in an input field
 */
const keyDownHandler = (event: KeyboardEvent, onScan: (value: string) => void) => {
  const target = event.target as HTMLDivElement;
  const el = target.tagName.toLowerCase();
  if (el === "input" || el === "textarea") return;
  if (!timer) start(onScan);

  manageKeyPress(event.key);
};

const manageKeyPress = (key: string) => {
  switch (key) {
    case "Alt":
    case "ArrowDown":
    case "ArrowLeft":
    case "ArrowRight":
    case "ArrowUp":
    case "Backspace":
    case "CapsLock":
    case "Control":
    case "End":
    case "Enter":
    case "Home":
    case "Meta":
    case "PageDown":
    case "PageUp":
    case "Shift":
    case "Tab":
      // Disregard keys:
      break;

    default:
      buffer += key;
      break;
  }
};

/**
 * Start the capture of input, clearing the buffer and
 * starting a timer for the specified `TIME` length.
 */
const start = (onScan: (value: string) => void) => {
  buffer = "";
  const barcodeScanHandler = () => {
    if (buffer.trim()?.length > 0) {
      onScan(buffer);
    }

    reset();
  };
  timer = setTimeout(barcodeScanHandler, TIME);
};

/**
 * Clear the text buffer and timer.
 */
const reset = () => {
  buffer = "";
  clearTimeout(timer);
  timer = undefined;
};

export const useCustomBarcodeScanner = (sisId: number) => {
  const [scanCount, setScanCount] = useState(0);
  const [scannedValue, setScannedValue] = useState<string | number>("");

  useEffect(() => {
    const handleScan = (customerNumber: string) => {
      if (customerNumber) {
        setScannedValue(customerNumber);
        setScanCount((count) => count + 1);
      }
    };

    const scanHandler = (e: KeyboardEvent) => keyDownHandler(e, handleScan);
    window.addEventListener("keydown", scanHandler, { capture: true });

    return () => window.removeEventListener("keydown", scanHandler, { capture: true });
  }, []);

  useEffect(() => {
    if (scanCount > 0) {
      performDecodeScan({ sisId, value: scannedValue });
    }
  }, [scanCount, scannedValue, sisId]);
};
