import { useSelector, shallowEqual } from "react-redux";
import store from "../store";
import { getCurrentUserId } from "./session";
import { getRoleName } from "./roles";

const formatUser = (user = {}) => {
  return {
    imageUrl: user.imageUrl,
    firstname: user.firstname,
    lastname: user.lastname,
    roleId: user.roleId,
    id: user.id,
    login: user.login,
    employeeNumber: user.employeeNumber,
    controlEnabled: user.controlEnabled,
    language: user.language,
    cardValue: user.cardValue,
  };
};

export const useUsersIds = () => useSelector((state) => Object.keys(state.users), shallowEqual);

export const useUser = (userId) =>
  useSelector((state) => {
    return formatUser(state.users[userId] || {});
  }, shallowEqual);

export const getUser = (id, state) => {
  const receivedState = state || store.getState();
  return formatUser(receivedState.users[id] || {});
};

export const useCurrentUser = () =>
  useSelector((state) => {
    const currentUserId = getCurrentUserId(state);
    return formatUser(state.users[currentUserId]);
  }, shallowEqual);

export const getUserFromUsername = (username) => {
  const state = store.getState();
  const foundUser = Object.values(state.users).find((user) => user.login === username);
  return formatUser(foundUser);
};

export const useControlUsersIds = () =>
  useSelector((state) => {
    return Object.values(state.users).reduce((acc, user) => {
      if (user.controlEnabled) {
        acc.push(user.id);
      }
      return acc;
    }, []);
  }, shallowEqual);

export const getAttributeFromUser = ({ id, attribute, state }) => {
  const user = state.users[id] || {};
  if (attribute === "roleName") return getRoleName(user.roleId, state);
  return user[attribute];
};

export const useUserUiSettings = (userId) =>
  useSelector((state) => {
    return getUserUISettings(state, userId);
  }, shallowEqual);

export const getUserUISettings = (state, userId) => {
  const uiSettings = state.users[userId]?.uiSettings || {};
  return {
    batchTopColor: uiSettings.batchTopColor,
    batchBottomColor: uiSettings.batchBottomColor,
    batchTopText: uiSettings.batchTopText,
    batchBottomText: uiSettings.batchBottomText,
    overviewContext: uiSettings.overviewContext,
  };
};
