import React from "react";
import { Avatar, Badge, Button } from "@mui/material";
import { withStyles, makeStyles } from "@mui/styles";
import { alpha } from "@mui/material/styles";
import PhotoCamera from "@mui/icons-material/PhotoCamera";

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: 100,
    height: 100,
    border: (props) => {
      return `1px solid ${props.disabled ? alpha(theme.palette.primary["dark"], 0.4) : theme.palette.primary["dark"]}`;
    },
    backgroundColor: (props) => {
      return props.disabled ? alpha(theme.palette.primary["light"], 0.4) : theme.palette.primary["light"];
    },
    color: (props) => {
      return props.disabled ? alpha(theme.palette.primary["dark"], 0.4) : theme.palette.primary["dark"];
    },
  },
}));

const StyledButton = withStyles({
  text: {
    paddingLeft: 0,
  },
})(Button);

const StyledAvatar = withStyles((theme) => ({
  colorDefault: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.info.light,
  },
}))(Avatar);

const ImageUploadButton = ({ defaultIcon, imageUrl, disabled, onUpload }) => {
  const classes = useStyles({ disabled });
  const handleFileLoad = (event) => {
    let reader = new FileReader();
    let file = event.target.files[0];

    reader.onloadend = () => onUpload(reader.result);
    reader.readAsDataURL(file);
  };

  return (
    <>
      <input
        accept="image/*"
        disabled={disabled}
        style={{ display: "none" }}
        id="outlined-button"
        type="file"
        onChange={handleFileLoad}
      />
      <label htmlFor="outlined-button">
        <StyledButton component="span" disabled={disabled}>
          <Badge
            invisible={disabled}
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <StyledAvatar>
                <PhotoCamera />
              </StyledAvatar>
            }
          >
            <Avatar src={imageUrl} className={classes.avatar}>
              {!imageUrl && defaultIcon}
            </Avatar>
          </Badge>
        </StyledButton>
      </label>
    </>
  );
};

export default ImageUploadButton;
