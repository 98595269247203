import React from "react";
import { useTranslation } from "react-i18next";
import { Button, FormControlLabel } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import { useFirstRVBatchId } from "../../selectors/railVisits";
import { useGetPrintersQuery, useAddPrintJobRequestMutation } from "../SLX/slxPrint";
import { useSLXPrintJobRequestBody } from "./hooks/useSLXPrintJobRequestBody";
import ConciseButtonLoadingState from "./ConciseButtonLoadingState";
import { useInformRequestStatusToUser } from "./hooks/useInformRequestStatusToUser";

interface Props {
  railId: number;
};

interface ButtonContentProps {
  showLoading: boolean;
  disabled: boolean;
  onClick: () => void;
};

const ConcisePrintButton = ({ railId }: Props) => {
  const getPrintersQuery = useGetPrintersQuery({});

  const batchId = useFirstRVBatchId(railId);
  const requestParams = useSLXPrintJobRequestBody(batchId);
  const [addPrintJobRequest, pJMutationResult] = useAddPrintJobRequestMutation();
  useInformRequestStatusToUser(pJMutationResult);

  const onClick = () => addPrintJobRequest(requestParams);

  const anyPrintersAvailable = getPrintersQuery.isSuccess && getPrintersQuery.data?.length > 0;
  if (getPrintersQuery.isLoading || !anyPrintersAvailable) return null;
  return (
    <ButtonContent
      showLoading={pJMutationResult.isLoading}
      disabled={!requestParams.customerId && !requestParams.categoryId}
      onClick={onClick}
    />
  );
};

const ButtonContent = ({ showLoading, disabled, onClick }: ButtonContentProps) => {
  const { t } = useTranslation();
  return (
    <FormControlLabel
      label={t<string>("slingInputStation:print")}
      labelPlacement="top"
      disabled={showLoading}
      control={
        showLoading ?
        <ConciseButtonLoadingState /> :
        <Button
          variant="contained"
          color="primary"
          disableElevation={true}
          disabled={disabled}
          onClick={onClick}
          sx={{
            borderRadius: "50%",
            width: 60,
            height: 60,
          }}
        >
          <PrintIcon id="start-icon" sx={{ fontSize: 40 }} />
        </Button>
      }
    />
  );
};

export default ConcisePrintButton;
