import React, { useState } from "react";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import { loadCellTare, loadCellSpan } from "../../api/LoadCellApi";
import { useLoadCell } from "../../selectors/useLoadCell";
import { useRail } from "../../selectors/rails";
import { useUser } from "../../selectors/useUser";
import { useTranslation } from "react-i18next";
import NumpadField from "../NumpadField";

const useStyles = makeStyles((theme) => ({
  textField: {
    color: "white",
  },
}));

const LoadCellCalibrationDialog = (props) => {
  const classes = useStyles();
  const { open, handleClose, loadCellId } = props;
  const loadCell = useLoadCell(loadCellId);
  const rail = useRail(loadCell.railId);
  const [calibrationWeight, setCalibrationWeight] = useState("");
  const calibratedBy = useUser(loadCell.calibratedById);
  const { t } = useTranslation();
  /**
   * click handler for "Set Tare" action
   */
  const handleSetTare = () => {
    loadCellTare(loadCell.id);
  };

  /**
   * click handler for "Set Span" action
   */
  const handleSetSpan = () => {
    loadCellSpan(loadCell.id, calibrationWeight);
  };

  const calibratedAtDate = dayjs(loadCell.calibratedAt);

  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
      <DialogTitle id="alert-dialog-title">{t("loadCell:dialog_title", { location: rail.name })}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t("loadCell:instructions_1")}</DialogContentText>
        <DialogContentText>{t("loadCell:instructions_2")}</DialogContentText>
        <DialogContentText>{t("loadCell:instructions_3")}</DialogContentText>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>{t("loadCell:current_weight")}</TableCell>
              <TableCell>{loadCell.weight}</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t("loadCell:current_value")}</TableCell>
              <TableCell>{loadCell.currentValue}</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow key="tare-row">
              <TableCell>{t("loadCell:tare")}</TableCell>
              <TableCell>{loadCell.tare}</TableCell>
              <TableCell></TableCell>
              <TableCell>
                <Button size="medium" color="primary" variant="contained" onClick={handleSetTare}>
                  {t("loadCell:set_tare_button")}
                </Button>
              </TableCell>
            </TableRow>
            <TableRow key="span-row">
              <TableCell>{t("loadCell:span")}</TableCell>
              <TableCell>{loadCell.span}</TableCell>
              <TableCell>
                <NumpadField
                  value={calibrationWeight}
                  submitValue={setCalibrationWeight}
                  title={t("loadCell:calibration_weight")}
                >
                  <TextField
                    className={classes.textField}
                    id="calibration-weight-input"
                    label={t("loadCell:calibration_weight")}
                    value={calibrationWeight}
                    type="number"
                    placeholder={t("common:required")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </NumpadField>
              </TableCell>
              <TableCell>
                <Button
                  size="medium"
                  color="primary"
                  variant="contained"
                  disabled={!calibrationWeight || calibrationWeight < 1}
                  onClick={handleSetSpan}
                >
                  {t("loadCell:set_span_button")}
                </Button>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t("loadCell:last_calibrated")}</TableCell>
              <TableCell>{calibratedAtDate.isValid() && calibratedAtDate.format("llll")}</TableCell>
              <TableCell>{t("loadCell:last_calibrated_by")}</TableCell>
              <TableCell>{`${calibratedBy.firstname} ${calibratedBy.lastname}`}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          {t("common:close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

LoadCellCalibrationDialog.propTypes = {
  loadCellId: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default LoadCellCalibrationDialog;
