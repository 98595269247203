import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import _ from "lodash";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { withStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { getCategoryName, getCategoryNumber, useCategory, useCategoryIds } from "../../selectors/categories";
import { useIsTrolley, useShowFormula } from "../../selectors/uiSettings";
import { useCurrentRole } from "../../selectors/useRole";
import store from "../../store";
import { SETUP_CATEGORIES } from "../../constants/Paths";
import SearchField from "../SetupPages/SearchField";
import AddCategoryButton from "./AddCategoryButton";

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.grey[100],
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    padding: theme.spacing(2),
  },
  body: {
    fontSize: 14,
    padding: theme.spacing(2),

    "& .MuiBox-root": {
      display: "flex",
      alignItems: "center",
    },
    "& .MuiAvatar-root": {
      marginRight: theme.spacing(1),
    },
  },
}))(TableCell);

const StyledTableSortLabel = withStyles((theme) => ({
  root: {
    "&$active": {
      color: theme.palette.common.black,
    },
  },
  active: {},
  icon: {
    color: "inherit !important",
  },
}))(TableSortLabel);

const CategoryRow = ({ categoryId, showFormula, onClick }) => {
  const currentRole = useCurrentRole();
  const category = useCategory(categoryId) || {};
  const isTrolley = useIsTrolley();
  const { number, imageUrl, name, targetWeight, minWeight, maxWeight, loadSize, formula } = category;

  return (
    <StyledTableRow>
      <StyledTableCell component="th" scope="row">
        <Typography variant="h5">{number}</Typography>
      </StyledTableCell>
      <StyledTableCell align="left">
        <Box>
          <Avatar src={imageUrl}></Avatar>
          <Typography variant="h5">{name}</Typography>
        </Box>
      </StyledTableCell>
      {!isTrolley && (
        <>
          <StyledTableCell align="right">{targetWeight}</StyledTableCell>
          <StyledTableCell align="right">{minWeight}</StyledTableCell>
          <StyledTableCell align="right">{maxWeight}</StyledTableCell>
          <StyledTableCell align="right">{loadSize}</StyledTableCell>
        </>
      )}
      {!isTrolley && showFormula && <StyledTableCell align="right">{formula}</StyledTableCell>}
      <StyledTableCell align="right">
        <IconButton onClick={onClick}>{currentRole.canEditMasterData ? <EditIcon /> : <VisibilityIcon />}</IconButton>
      </StyledTableCell>
    </StyledTableRow>
  );
};

const filterCategoryIds = (categoryIds, searchTerm, state) => {
  return categoryIds.filter((id) => {
    if (!searchTerm) return true;
    const number = getCategoryNumber(state, id).toString();
    const name = _.toLower(getCategoryName(state, id));
    const passedNumber = _.includes(number, searchTerm?.toLowerCase());
    const passedName = _.includes(name, searchTerm?.toLowerCase());

    return passedNumber || passedName;
  });
};

const sortCategoryIds = (categoryIds, orderBy, order, state) => {
  if (categoryIds.length < 1) return [];
  return categoryIds.sort((a, b) => {
    const valueA = orderBy === "name" ? getCategoryName(state, a) : getCategoryNumber(state, a);
    const valueB = orderBy === "name" ? getCategoryName(state, b) : getCategoryNumber(state, b);

    if (order === "asc") {
      if (valueB > valueA) return -1;
      if (valueB < valueA) return 1;
    } else {
      if (valueB < valueA) return -1;
      if (valueB > valueA) return 1;
    }
    return 0;
  });
};

const CategoriesList = () => {
  const { t } = useTranslation();
  const categoryIds = useCategoryIds();
  const isTrolley = useIsTrolley();
  const showFormula = useShowFormula();
  const location = useLocation();

  const state = store.getState();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("number");
  const [searchTerm, setSearchTerm] = useState(location.state?.searchTerm);

  const filteredCategoryIds = filterCategoryIds(categoryIds, searchTerm, state);
  const sortedCategoryIds = sortCategoryIds(filteredCategoryIds, orderBy, order, state);

  const list = useRef();
  useEffect(() => {
    const scrollPos = location.state?.listScrollPos;
    if (scrollPos) {
      list.current.scrollTop = scrollPos;
    }
  }, [location.state?.listScrollPos]);

  const handleOrderBy = (selected) => {
    if (selected === orderBy) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      setOrder("asc");
      setOrderBy(selected);
    }
  };

  const history = useHistory();
  const handleOnCategoryClick = (id) => {
    const newLocation = {
      pathname: `${SETUP_CATEGORIES}/${id}`,
      state: { listScrollPos: list.current.scrollTop, searchTerm },
    };
    history.push(newLocation);
  };

  return (
    <Paper square={true} elevation={0} sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box padding={3} display="flex" justifyContent="space-between" alignItems="flex-end">
        <Typography variant="h1">{t("categoriesSetup:page_title")}</Typography>

        <SearchField
          sx={{ width: 350 }}
          initialValue={location.state?.searchTerm}
          placeholder={t("categoriesSetup:search_instructions")}
          onSubmitSearchTerm={(searchTerm) => setSearchTerm(searchTerm)}
        />
      </Box>

      <Divider />

      <TableContainer ref={list}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell sortDirection={order}>
                <StyledTableSortLabel
                  active={orderBy === "number"}
                  direction={order}
                  onClick={() => handleOrderBy("number")}
                >
                  {t("common:number")}
                </StyledTableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="left" sortDirection={order}>
                <StyledTableSortLabel
                  active={orderBy === "name"}
                  direction={order}
                  onClick={() => handleOrderBy("name")}
                >
                  {t("common:name")}
                </StyledTableSortLabel>
              </StyledTableCell>
              {!isTrolley && (
                <>
                  <StyledTableCell align="right">{t("categoriesSetup:target_weight")}</StyledTableCell>
                  <StyledTableCell align="right">{t("categoriesSetup:min_weight")}</StyledTableCell>
                  <StyledTableCell align="right">{t("categoriesSetup:max_weight")}</StyledTableCell>
                  <StyledTableCell align="right">{t("categoriesSetup:load_size")}</StyledTableCell>
                </>
              )}
              {!isTrolley && showFormula && (
                <StyledTableCell align="right">{t("categoriesSetup:formula")}</StyledTableCell>
              )}
              <StyledTableCell align="right">
                <AddCategoryButton size="small" />
              </StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {sortedCategoryIds.map((id) => (
              <CategoryRow
                key={id}
                categoryId={id}
                showFormula={showFormula}
                onClick={() => handleOnCategoryClick(id)}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default CategoriesList;
