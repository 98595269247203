import React from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Avatar, Box, Button, Typography } from "@mui/material";
import { useHyphenate } from "../../selectors/useHyphenate";
import * as MuiIcons from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    justifyContent: "center",
    textAlign: "center",
  },
  avatar: {
    color: "#fff",
    backgroundColor: (props) => props.color,
    width: 72,
    height: 72,
    marginBottom: 4,
  },
  button: {
    padding: 0,
    margin: 0,
  },
  text: {
    color: "#fff",
    fontSize: 16,
    maxWidth: 96,
    margin: "0 0 8px 0",
    // CSS Hyphenation on Windows
    // Tracking: https://bugs.chromium.org/p/chromium/issues/detail?id=652964
    // hyphens: "auto"
  },
  icon: {
    fontSize: 52,
  },
  [theme.breakpoints.down("sm")]: {
    avatar: {
      width: 48,
      height: 48,
    },
    icon: {
      fontSize: 32,
    },
    text: {
      fontSize: 12,
      maxWidth: 64,
    },
  },
}));

const MenuIcon = (props) => {
  const { color, icon, label, linkTo, ...rest } = props;
  const classes = useStyles(props);
  const { hyphenate } = useHyphenate();

  const Icon = MuiIcons[icon];

  if (_.isFunction(linkTo)) {
    return (
      <Box className={classes.root}>
        <div>
          <Button className={classes.button} onClick={linkTo}>
            <Avatar variant="square" className={classes.avatar}>
              <Icon className={classes.icon} />
            </Avatar>
          </Button>
          <Typography variant="h6" className={classes.text} align="center">
            {hyphenate(label, { minWordLength: 12 })}
          </Typography>
        </div>
      </Box>
    );
  } else {
    return (
      <Box className={classes.root}>
        <Link to={linkTo}>
          <Button className={classes.button}>
            <Avatar variant="square" className={classes.avatar}>
              <Icon className={classes.icon} />
            </Avatar>
          </Button>
          <Typography variant="h6" className={classes.text} align="center">
            {hyphenate(label, { minWordLength: 12 })}
          </Typography>
        </Link>
      </Box>
    );
  }
};

export default MenuIcon;
