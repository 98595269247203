import React from "react";
import { useParams } from "react-router-dom";
import { useSlingInputStation } from "../selectors/slingInputStations";
import { useRail } from "../selectors/rails";
import { useRailIoType } from "../selectors/railIoTypes";
import { useJobNumber } from "../selectors/uiSettings";
import BulkLoaderSIS from "../components/SlingInputStation/BulkLoaderSIS";
import DefaultSIS from "../components/SlingInputStation/DefaultSIS";
import MaintenanceLiftSIS from "../components/SlingInputStation/MaintenanceLiftSIS";
import SupertrackMaintLiftSIS from "../components/SlingInputStation/SupertrackMaintLiftSIS";
import SupertrackMaintHoistSIS from "../components/SlingInputStation/SupertrackMaintHoistSIS";
import GarbageChuteSIS from "../components/SlingInputStation/DenmanGarbageChute/GarbageChuteSIS";
import EmptyRailSIS from "../components/SlingInputStation/EmptyRailSIS";
import DualSIS from "../components/SlingInputStation/DualSIS";
import WeighingConveyorSIS from "../features/slingInputStations/components/WeighingConveyorSIS";
import usePageLogger from "../features/slingInputStations/hooks/usePageLogger";
import { IoConstant } from "../constants/IoConstant";
import { RailGroup } from "../constants/RailGroup";

const SlingInputStation = () => {
  const params = useParams();
  const sisIds = params?.ids
    ?.split(",")
    ?.map((id) => parseInt(id))
    ?.filter((id) => id);
  const dualSIS = sisIds?.length > 1;
  const layout = params?.layout;

  if (sisIds?.length < 1) return null;
  return dualSIS ? (
    <DualSIS frontSISId={sisIds[0]} rearSISId={sisIds[1]} layout={layout} />
  ) : (
    <SingleSIS sisId={sisIds[0]} />
  );
};

const SingleSIS = ({ sisId }) => {
  const sis = useSlingInputStation(sisId);
  const rail = useRail(sis.railId);
  const railIoType = useRailIoType(rail.railIoTypeId);
  const jobNumber = useJobNumber();
  let renderedComponent = undefined;
  let pageName = undefined;

  if (railIoType.bulkLoader) {
    pageName = "BulkLoaderSIS";
    renderedComponent = <BulkLoaderSIS sisId={sis.id} />;
  } else if (
    rail.maintenance &&
    (rail.railIoTypeId === IoConstant.SUPERTRACK_PNEUMATIC_MAINTENANCE_VC ||
      rail.railIoTypeId === IoConstant.SUPERTRACK_SERVO_MAINTENANCE_HOIST ||
      rail.railIoTypeId === IoConstant.SUPERTRACK_CUSTOM_SERVO ||
      rail.railIoTypeId === IoConstant.SUPERTRACK_INDEXER ||
      rail.railIoTypeId === IoConstant.SUPERTRACK_STP_IO_TYPE ||
      rail.railIoTypeId === IoConstant.SUPERTRACK_INDEXER_STP)
  ) {
    pageName = "SupertrackMaintLiftSIS";
    renderedComponent = <SupertrackMaintLiftSIS sisId={sis.id} />;
  } else if (sis?.maintHoist) {
    pageName = "SupertrackMaintHoistSIS";
    renderedComponent = <SupertrackMaintHoistSIS sisId={sis.id} />;
  } else if (rail.maintenance) {
    pageName = "MaintenanceLiftSIS";
    renderedComponent = <MaintenanceLiftSIS sisId={sis.id} />;
  } else if (jobNumber === "2356" && rail.id === 91) {
    pageName = "GarbageChuteSIS";
    renderedComponent = <GarbageChuteSIS sisId={sis.id} />;
  } else if (rail.railIoTypeId === IoConstant.WEIGHING_CONVEYOR) {
    pageName = "WeighingConveyorSIS";
    renderedComponent = <WeighingConveyorSIS sisId={sis.id} />;
  } else if (rail.railGroup === RailGroup.EMPTY) {
    pageName = "EmptyRailSIS";
    renderedComponent = <EmptyRailSIS sisId={sis.id} />;
  } else {
    pageName = "DefaultSIS";
    renderedComponent = <DefaultSIS sisId={sis.id} />;
  }

  usePageLogger(pageName);
  return renderedComponent;
};

export default SlingInputStation;
