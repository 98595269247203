import React, { useEffect, useState } from "react";
import { Box, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { VariableSizeList } from "react-window";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { nameComparer } from "../../../utils/sort";
import { updateCustomer } from "../../../api/CustomerAPI";
import { useWindowDimensions } from "../../../utils/resize";
import CustomerConfigSelector from "../../../components/CustomerConfigSelector";
import CustomerNumberAvatar from "../../../components/CustomerNumberAvatar";
import SearchField from "../../../components/SearchField";
import { useCurrentRole } from "../../../selectors/useRole";
import { showMessage, hideMessage } from "../redux/sortConfigurationsSlice";
import useCustomerMappings from "../hooks/useCustomerMappings";
import { useGetSortConfigurationsQuery } from "../services/sortConfigurations";
import type { Customer } from "../../../dto/eVue/Customer";

const getItemSize = () => 92;

const CustomerMapping = () => {
  const { data: configs } = useGetSortConfigurationsQuery();
  const {
    customers = [],
    showCleanSystem,
    showSoiledSystem,
    cleanConfigs,
    soiledConfigs,
  } = useCustomerMappings(configs);
  const role = useCurrentRole();
  const [width, height] = useWindowDimensions();
  const [items, setItems] = useState<Customer[]>([]);
  const [filter, setFilter] = useState("");
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (customers?.length > 0) {
      setItems(
        customers
          .filter((x) => !filter || x.name.toLowerCase().includes(filter) || x && x.number?.toString().includes(filter))
          .sort(nameComparer)
      );
    }
  }, [customers, filter]);

  const clearLoader = () => {
    dispatch(hideMessage());
  };

  const handleCleanSelection = (customerId: number, configId: number) => {
    dispatch(showMessage(t("sortAssignments:saving_customer_mapping")));
    updateCustomer({
      customer: {
        id: customerId,
        cleanSortConfigId: configId,
      },
      successCB: clearLoader,
      errorCB: clearLoader,
    });
  };

  const handleSoiledSelection = (customerId: number, configId: number) => {
    dispatch(showMessage(t("sortAssignments:saving_customer_mapping")));
    updateCustomer({
      customer: {
        id: customerId,
        soiledSortConfigId: configId,
      },
      successCB: clearLoader,
      errorCB: clearLoader,
    });
  };

  const filterChange = (term: string) => {
    setFilter(term?.toLowerCase());
  };

  return (
    <Paper sx={{ padding: 1, height: "100%", px: 3, py: 2 }}>
      {Object.values(customers).length > 10 && (
        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 1 }}>
          <Box sx={{ width: 260, mb: 2 }}>
            <SearchField
              initialValue=""
              sx={{}}
              placeholder={t("common:filter")}
              onChange={filterChange}
              onSubmitSearchTerm={filterChange}
            />
          </Box>
        </Box>
      )}

      <Box sx={{ display: "flex", alignItems: "baseline" }}>
        <Box width={50}></Box>
        <Box sx={{ flex: 1, whiteSpace: "nowrap", overflow: "hidden" }}>
          <Typography variant={"h5"}>{t("common:name")}</Typography>
        </Box>
        {showSoiledSystem && (
          <Box sx={{ margin: "0 4px", width: 200 }}>
            <Typography variant={"h5"}>{t("common:soiled")}</Typography>
          </Box>
        )}
        {showCleanSystem && (
          <Box sx={{ margin: "0 4px", width: 200 }}>
            <Typography variant={"h5"}>{t("common:clean")}</Typography>
          </Box>
        )}
      </Box>

      <VariableSizeList
        height={height - 320}
        width={"100%"}
        itemCount={items.length}
        itemSize={getItemSize}
        className="list-container"
      >
        {({ index, style }) => {
          const customer = items[index];
          const soiledConfig = customer.soiledSortConfigId && configs?.[customer.soiledSortConfigId];
          const cleanConfig = customer.cleanSortConfigId && configs?.[customer.cleanSortConfigId];

          return (
            <Box
              style={style}
              sx={{ display: "flex", alignItems: "center", borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}
            >
              <Box width={50}>
                <CustomerNumberAvatar customerId={customer.id} />
              </Box>
              <Box sx={{ flex: 1, whiteSpace: "nowrap", overflow: "hidden" }}>
                <Typography>{customer.name}</Typography>
              </Box>
              {showSoiledSystem && (
                <Box sx={{ margin: "0 4px", width: 200 }}>
                  <CustomerConfigSelector
                    title={t("common:soiled_configurations")}
                    subtitle={t("sortAssignments:choose_a_soiled_configuration")}
                    disabled={!role.canEditSortConfigs}
                    selections={soiledConfigs}
                    selected={soiledConfig}
                    customerId={customer.id}
                    handleSelect={handleSoiledSelection}
                  />
                </Box>
              )}
              {showCleanSystem && (
                <Box sx={{ margin: "0 4px", width: 200 }}>
                  <CustomerConfigSelector
                    title={t("common:clean_configurations")}
                    subtitle={t("sortAssignments:choose_a_clean_configuration")}
                    disabled={!role.canEditSortConfigs}
                    selections={cleanConfigs}
                    selected={cleanConfig}
                    customerId={customer.id}
                    handleSelect={handleCleanSelection}
                  />
                </Box>
              )}
            </Box>
          );
        }}
      </VariableSizeList>
    </Paper>
  );
};

export default CustomerMapping;
