import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Button, FormControlLabel } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "50%",
    padding: theme.spacing(2),
    fontSize: 40,

    "& #start-icon": {
      fontSize: 40,
    },
  },
}));

const ConciseSendCallEmptyButton = ({ sx, disabled, onClick }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <FormControlLabel
      control={
        <Button
          className={classes.button}
          size="large"
          disableElevation={true}
          variant="contained"
          disabled={disabled}
          onClick={onClick}
        >
          <DownloadIcon id="start-icon" />
        </Button>
      }
      label={t("slingInputStation:send_call_empty")}
      labelPlacement="top"
      sx={sx}
    />
  );
};

export default ConciseSendCallEmptyButton;
