import { NONE, LOAD_CELL } from "./weightInputTypes";

export const getBagWeightStatus = ({ slingLoaded, sis = {}, category = {}, currentWeight }) => {
  const { underweightValidation, overweightValidation } = sis;
  const { minWeight, targetWeight, maxWeight } = category;

  if (!slingLoaded) {
    return "missing";
  } else if (underweightValidation && currentWeight < minWeight) {
    return "underweight";
  } else if (overweightValidation && currentWeight > maxWeight) {
    return "overweight";
  } else if (currentWeight >= maxWeight - 5 && currentWeight < maxWeight) {
    return "almost_full";
  } else if (currentWeight === maxWeight) {
    return "full";
  } else if (currentWeight >= targetWeight && currentWeight < maxWeight - 5) {
    return "target_met";
  } else {
    return "normal";
  }
};

export const getWeightToUse = ({ weightInputType, liveWeight, position, sis = {} }) => {
  if (weightInputType === NONE) {
    return null;
  } else if (weightInputType === LOAD_CELL) {
    return liveWeight;
  } else {
    return position === 1 ? sis.weight1 : sis.weight2;
  }
};
