import React from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Box, Divider, Paper, Typography } from "@mui/material";
import { useSlingInputStation } from "../../selectors/slingInputStations";
import { useRail } from "../../selectors/rails";
import { railInputEnabled, railOutputEnabled } from "../../api/RailApi";
import useUserScannedValues from "../../features/slingInputStations/hooks/useUserScannedValues";
import { IndicatorStatusTypes } from "../../features/panels/enum/IndicatorStatusTypes";
import CircleIndicator from "../CircleIndicator";
import SingleSlingInput from "./SingleSlingInput";
import ConciseAddSlingButton from "./ConciseAddSlingButton";
import ConciseDeleteSlingButton from "./ConciseDeleteSlingButton";
import ConciseManSendButton from "./ConciseManSendButton";
import { FLOOR_SCALE_SINGLE, FLOOR_SCALE_DOUBLE } from "./weightInputTypes";

const BulkLoaderSIS = ({ sisId }) => {
  const { t } = useTranslation();

  const sis = useSlingInputStation(sisId);
  const rail = useRail(sis?.railId);
  const { name, inputEnabled, outputEnabled, plcState } = rail;
  useUserScannedValues({ sisId, position: 1 });

  const handleToggleInbound = () => railInputEnabled({ id: rail?.id, inputEnabled });
  const handleToggleOutbound = () => railOutputEnabled({ id: rail?.id, outputEnabled });

  return (
    <Paper square={true} elevation={0} sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
      <Box display="flex" flexDirection="column" padding={2}>
        <Typography variant="h1">{name}</Typography>
        <Typography variant="overline" gutterBottom>
          {t("railStates:state_state", {
            state: t(`railStates:${plcState}`),
          })}
        </Typography>

        <Box display="flex" justifyContent="space-between" alignItems="flex-end" mt={2}>
          <div>
            <CircleIndicator
              label={t("common:in")}
              status={inputEnabled ? IndicatorStatusTypes.ON : IndicatorStatusTypes.OFF}
              onClick={handleToggleInbound}
            />
            <CircleIndicator
              label={t("common:out")}
              status={outputEnabled ? IndicatorStatusTypes.ON : IndicatorStatusTypes.OFF}
              onClick={handleToggleOutbound}
            />
          </div>

          <Box>
            <ConciseAddSlingButton sisId={sisId} size="xLarge" />
            <ConciseManSendButton sisId={sisId} size="xLarge" />
            <ConciseDeleteSlingButton sisId={sisId} size="xLarge" />
          </Box>
        </Box>
      </Box>

      <Divider />

      <Box display="flex" justifyContent="center" padding={2}>
        <SingleSlingInput
          key={`sling-${sisId}-${1}`}
          sisId={sisId}
          position={1}
          showCaptureWeightButton={[FLOOR_SCALE_SINGLE, FLOOR_SCALE_DOUBLE].includes(sis?.weightInputType)}
        />
      </Box>
    </Paper>
  );
};

export default BulkLoaderSIS;
