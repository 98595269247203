// Active Production Lists
export const UPDATE_ACTIVE_PRODUCTION_LIST = "UPDATE_ACTIVE_PRODUCTION_LIST";
export const DELETE_ACTIVE_PRODUCTION_LIST = "DELETE_ACTIVE_PRODUCTION_LIST";
export const INITIALIZE_ACTIVE_PRODUCTION_LISTS = "INITIALIZE_ACTIVE_PRODUCTION_LISTS";

// Alerts
export const ALERT_SUCCESS = "ALERT_SUCCESS";
export const ALERT_INFO = "ALERT_INFO";
export const ALERT_ERROR = "ALERT_ERROR";
export const ALERT_WARNING = "ALERT_WARNING";
export const ALERT_CLEAR = "ALERT_CLEAR";

// Barcodes
export const INITIALIZE_BARCODES = "INITIALIZE_BARCODES";
export const UPDATE_BARCODE = "UPDATE_BARCODE";
export const DELETE_BARCODE = "DELETE_BARCODE";

// Barcode Checks
export const INITIALIZE_BARCODE_CHECKS = "INITIALIZE_BARCODE_CHECKS";
export const UPDATE_BARCODE_CHECK = "UPDATE_BARCODE_CHECK";
export const DELETE_BARCODE_CHECK = "DELETE_BARCODE_CHECK";

// Call Offs
export const INITIALIZE_CALL_OFFS = "INITIALIZE_CALL_OFFS";
export const UPDATE_CALL_OFF = "UPDATE_CALL_OFF";
export const DELETE_CALL_OFF = "DELETE_CALL_OFF";

// Batches
export const INITIALIZE_BATCHES = "INITIALIZE_BATCHES";
export const INITIALIZE_RAIL_VISITS = "INITIALIZE_RAIL_VISITS";
export const REMOVE_BATCHES = "REMOVE_BATCHES";
export const UPDATE_BATCH = "UPDATE_BATCH";
export const DELETE_BATCH = "DELETE_BATCH";
export const UPDATE_RAIL_VISITS = "UPDATE_RAIL_VISITS";

// Categories
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const INITIALIZE_CATEGORIES = "INITIALIZE_CATEGORIES";
export const DELETE_CATEGORY = "DELETE_CATEGORY";

// Category Groups
export const UPDATE_CATEGORY_GROUP = "UPDATE_CATEGORY_GROUP";
export const INITIALIZE_CATEGORY_GROUPS = "INITIALIZE_CATEGORY_GROUPS";
export const DELETE_CATEGORY_GROUP = "DELETE_CATEGORY_GROUP";

// Conveyors
export const INITIALIZE_CONVEYORS = "INITIALIZE_CONVEYORS";
export const UPDATE_CONVEYOR = "UPDATE_CONVEYOR";

// Customers
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const INITIALIZE_CUSTOMERS = "INITIALIZE_CUSTOMERS";

// Customer Change Stations
export const UPDATE_CUST_CHANGE_STATION = "UPDATE_CUST_CHANGE_STATION";
export const INITIALIZE_CUST_CHANGE_STATIONS = "INITIALIZE_CUST_CHANGE_STATIONS";

// Devices
export const INITIALIZE_DEVICES = "INITIALIZE_DEVICES";
export const UPDATE_DEVICE = "UPDATE_DEVICE";
export const DELETE_DEVICE = "DELETE_DEVICE";

// IO Points
export const INITIALIZE_IO_POINTS = "INITIALIZE_IO_POINTS";
export const UPDATE_IO_POINTS = "UPDATE_IO_POINTS";
export const UPDATE_IO_POINT = "UPDATE_IO_POINT";

// LoadCells
export const INITIALIZE_LOAD_CELLS = "INITIALIZE_LOAD_CELLS";
export const UPDATE_LOAD_CELLS = "UPDATE_LOAD_CELLS";
export const UPDATE_LOAD_CELL = "UPDATE_LOAD_CELL";

// Local
export const INITIALIZE_LOCAL = "INITIALIZE_LOCAL";
export const ADD_BATCH = "ADD_BATCH";
export const DESELECT_BATCH = "DESELECT_BATCH";
export const DESELECT_IO_POINTS = "DESELECT_IO_POINTS";
export const DESELECT_MACHINE = "DESELECT_MACHINE";
export const DESELECT_MACHINE_VISIT = "DESELECT_MACHINE_VISIT";
export const DESELECT_RAIL = "DESELECT_RAIL";
export const DESELECT_RAILPORT = "DESELECT_RAILPORT";
export const SELECT_BATCH = "SELECT_BATCH";
export const SELECT_IO_POINTS = "SELECT_IO_POINTS";
export const SELECT_MACHINE = "SELECT_MACHINE";
export const SELECT_MACHINE_VISIT = "SELECT_MACHINE_VISIT";
export const SELECT_RAIL = "SELECT_RAIL";
export const SELECT_RAILPORT = "SELECT_RAILPORT";
export const SHOW_LOGIN = "SHOW_LOGIN";
export const HIDE_LOGIN = "HIDE_LOGIN";
export const TOGGLE_LOGIN = "TOGGLE_LOGIN";
export const SHOW_RAIL_SETTINGS = "SHOW_RAIL_SETTINGS";
export const HIDE_RAIL_SETTINGS = "HIDE_RAIL_SETTINGS";
export const TOGGLE_RAIL_SETTINGS = "TOGGLE_RAIL_SETTINGS";
export const SET_TOP_TEXT_TYPE = "SET_TOP_TEXT_TYPE";
export const SET_BOTTOM_TEXT_TYPE = "SET_BOTTOM_TEXT_TYPE";
export const SET_TOP_COLOR_TYPE = "SET_TOP_COLOR_TYPE";
export const SET_BOTTOM_COLOR_TYPE = "SET_BOTTOM_COLOR_TYPE";
export const SET_RAIL_SHOW_ID = "SET_RAIL_SHOW_ID";
export const SHOW_RAIL_SYSTEM_CONTROLS = "SHOW_RAIL_SYSTEM_CONTROLS";
export const HIDE_RAIL_SYSTEM_CONTROLS = "HIDE_RAIL_SYSTEM_CONTROLS";
export const SHOW_PLC_TROUBLESHOOT = "SHOW_PLC_TROUBLESHOOT";
export const HIDE_PLC_TROUBLESHOOT = "HIDE_PLC_TROUBLESHOOT";
export const SHOW_SERVER_TROUBLESHOOT = "SHOW_SERVER_TROUBLESHOOT";
export const HIDE_SERVER_TROUBLESHOOT = "HIDE_SERVER_TROUBLESHOOT";
export const SHOW_BATCH_HIGHLIGHTER = "SHOW_BATCH_HIGHLIGHTER";
export const HIDE_BATCH_HIGHLIGHTER = "HIDE_BATCH_HIGHLIGHTER";
export const TOGGLE_BATCH_HIGHLIGHTER = "TOGGLE_BATCH_HIGHLIGHTER";
export const SET_CATEGORY_HIGHLIGHT = "SET_CATEGORY_HIGHLIGHT";
export const SET_CUSTOMER_HIGHLIGHT = "SET_CUSTOMER_HIGHLIGHT";
export const SET_BARCODE_HIGHLIGHT = "SET_BARCODE_HIGHLIGHT";
export const SHOW_ABOUT = "SHOW_ABOUT";
export const HIDE_ABOUT = "HIDE_ABOUT";

// Machines
export const UPDATE_MACHINE = "UPDATE_MACHINE";
export const INITIALIZE_MACHINES = "INITIALIZE_MACHINES";

// Machine Visits
export const INITIALIZE_MACHINE_VISITS = "INITIALIZE_MACHINE_VISITS";
export const UPDATE_MACHINE_VISITS = "UPDATE_MACHINE_VISITS";

// PLC Channels
export const INITIALIZE_PLC_CHANNELS = "INITIALIZE_PLC_CHANNELS";
export const UPDATE_PLC_CHANNEL = "UPDATE_PLC_CHANNEL";

// Production Lists
export const INITIALIZE_PRODUCTION_LISTS = "INITIALIZE_PRODUCTION_LISTS";
export const UPDATE_PRODUCTION_LIST = "UPDATE_PRODUCTION_LIST";
export const DELETE_PRODUCTION_LIST = "DELETE_PRODUCTION_LIST";
export const UPDATE_NESTED_STATES_PRODUCTION_LISTS = "UPDATE_NESTED_STATES_PRODUCTION_LISTS";

// Rails
export const INITIALIZE_RAILS = "INITIALIZE_RAILS";
export const UPDATE_RAIL = "UPDATE_RAIL";

// Rail Io Types
export const INITIALIZE_RAIL_IO_TYPES = "INITIALIZE_RAIL_IO_TYPES";

// Rail System
export const DATA_LOADED = "DATA_LOADED";
export const INITIALIZE_RAIL_SYSTEM = "INITIALIZE_RAIL_SYSTEM";

// Roles
export const INITIALIZE_ROLES = "INITIALIZE_ROLES";
export const UPDATE_ROLE = "UPDATE_ROLE";
export const DELETE_ROLE = "DELETE_ROLE";

// Scanners
export const INITIALIZE_SCANNERS = "INITIALIZE_SCANNERS";
export const UPDATE_SCANNER = "UPDATE_SCANNER";

// Server Status
export const INITIALIZE_SERVER_STATUS = "INITIALIZE_SERVER_STATUS";
export const UPDATE_SERVER_STATUS = "UPDATE_SERVER_STATUS";
export const HEARTBEAT_SUCCESS = "HEARTBEAT_SUCCESS";
export const HEARTBEAT_FAILURE = "HEARTBEAT_FAILURE";

// Session
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_STARTED = "LOGIN_STARTED";
export const LOGOUT_STARTED = "LOGOUT_STARTED";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const INITIALIZE_SESSION = "INITIALIZE_SESSION";

// Sling Input Stations
export const INITIALIZE_SLING_INPUT_STATIONS = "INITIALIZE_SLING_INPUT_STATIONS";
export const UPDATE_SLING_INPUT_STATION = "UPDATE_SLING_INPUT_STATION";

// Sort Stations
export const INITIALIZE_SORT_STATIONS = "INITIALIZE_SORT_STATIONS";
export const UPDATE_SORT_STATION = "UPDATE_SORT_STATION";

// Static Bags
export const INITIALIZE_STATIC_BAGS = "INITIALIZE_STATIC_BAGS";

// UI Settings
export const INITIALIZE_UI_SETTINGS = "INITIALIZE_UI_SETTINGS";
export const UPDATE_UI_SETTINGS = "UPDATE_UI_SETTINGS";

// Systems
export const INITIALIZE_SYSTEMS = "INITIALIZE_SYSTEMS";
export const UPDATE_SYSTEM = "UPDATE_SYSTEM";

// Users
export const INITIALIZE_USERS = "INITIALIZE_USERS";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";

// Weight Checks
export const INITIALIZE_WEIGHT_CHECKS = "INITIALIZE_WEIGHT_CHECKS";
export const UPDATE_WEIGHT_CHECK = "UPDATE_WEIGHT_CHECK";
