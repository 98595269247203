import { useSelector } from "react-redux";
import store from "../store";

export const getLoadCells = (receivedState) => {
  const state = receivedState || store.getState();
  return state.loadCells || {};
};

export const getLoadCellById = (loadCellId, receivedState) => {
  return getLoadCells(receivedState)[loadCellId];
};

export const getLoadCellWeight = (loadCellId, receivedState) => {
  const loadCell = getLoadCellById(loadCellId, receivedState);
  return (loadCell && loadCell.weight) || 0;
};

export const useLoadCellWeight = (loadCellId) =>
  useSelector((state) => {
    return state.loadCells[loadCellId]?.weight;
  });

export const getLoadCellsCount = () => Object.values(getLoadCells()).length;
