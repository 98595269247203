import _ from "lodash";
import { useAppSelector } from "../../../hooks/useAppSelector";
import type { SortConfiguration } from "../../../dto/eVue/SortConfiguration";
import type { Customer } from "../../../dto/eVue/Customer";
import type { SortStation } from "../../../dto/eVue/SortStation";
import { nameComparer } from "../../../utils/sort";
import { getCleanSystem, getSoiledSystem } from "../../../selectors/systems";
import { selectCustomers } from "../../../selectors/customers";
import { getSortStations } from "../../../selectors/sortStations";

const useCustomerMappings = (configs?: { [key: number]: SortConfiguration }) => useAppSelector((state) => {
  const customers = selectCustomers(state);
  const sortStations: { [key: number]: SortStation } = getSortStations(state);

  const cleanSystem = getCleanSystem(state);
  const cleanConfigs = Object.values(configs ?? [])
    .filter((config) => config.systemId === cleanSystem?.id)
    .sort(nameComparer);

  const soiledSystem = getSoiledSystem(state);
  const soiledConfigs = Object.values(configs ?? [])
    .filter((config) => config.systemId === soiledSystem?.id)
    .sort(nameComparer);

  const usedSystems = [...new Set(Object.values(sortStations).map((x) => x.systemId))] as number[];

  return {
    customers,
    showCleanSystem: usedSystems.includes(cleanSystem?.id),
    showSoiledSystem: usedSystems.includes(soiledSystem?.id),
    cleanConfigs,
    soiledConfigs,
  } as {
    customers: Customer[];
    showCleanSystem: boolean;
    showSoiledSystem: boolean;
    cleanConfigs: SortConfiguration[],
    soiledConfigs: SortConfiguration[];
  };
}, _.isEqual);

export default useCustomerMappings;
