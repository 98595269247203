import React from "react";
import { Card, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useSLXAreas } from "./SLXSlice";
import { useTranslation } from "react-i18next";
import MainCard from "../../ui-component/cards/MainCard";
import PVCard from "./PVCard";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  titleBar: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
}));

const SLXAreasView = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const areas = useSLXAreas();

  return (
    <div className={classes.root}>
      <Card className={classes.titleBar}>
        <Typography variant="h1">{t("slx:areas")}</Typography>
      </Card>

      {areas.map((area) => (
        <AreaSection key={area.id} area={area} />
      ))}
    </div>
  );
};

const AreaSection = ({ area }) => {
  return (
    <MainCard
      title={
        <>
          <Typography variant="h3">{area.areaType}</Typography>
        </>
      }
    >
      <Grid container spacing={2}>
        {area.producerViewIds.map((producerViewId) => {
          return (
            <Grid item key={producerViewId} xs={12} lg={3} sm={6}>
              <Link to={`/slx/producer_views/${producerViewId}`}>
                <PVCard producerViewId={producerViewId} />
              </Link>
            </Grid>
          );
        })}
      </Grid>
    </MainCard>
  );
};

export default SLXAreasView;
