import { useSelector } from "react-redux";
import store from "../store";

export const getCustChangeStations = (receivedState) => {
  const state = receivedState || store.getState();
  return state.custChangeStations || {};
};

export const useExternalCustomerId = (stationId) =>
  useSelector((state) => {
    return state.custChangeStations[stationId]?.externalCustomerId;
  });
