import React from "react";
import { useTranslation } from "react-i18next";
import FormFieldLabel from "../SetupPages/FormFieldLabel";
import MultiCustomerSelector from "./MultiCustomerSelector";

const FormCustomersSelector = ({ currentValues, disabled, onArrayFieldChange }) => {
  const { t } = useTranslation();
  return (
    <>
      <FormFieldLabel label={t("common:customers")} />
      <MultiCustomerSelector
        selectedCustomerIds={currentValues["customers"] || []}
        disabled={disabled}
        handleSelect={(id) => {
          const foundIdIndex = currentValues["customers"]?.findIndex((custId) => custId === id);
          if (foundIdIndex >= 0) {
            return onArrayFieldChange({
              field: "customers",
              operation: "remove",
              index: foundIdIndex,
            });
          } else {
            return onArrayFieldChange({
              field: "customers",
              operation: "add",
              value: id,
            });
          }
        }}
      />
    </>
  );
};

export default FormCustomersSelector;
