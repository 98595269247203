import { useSelector } from "react-redux";
import { useEffect } from "react";

export const useLocale = () => {
  const currentUserId = useSelector((state) => state.session.currentUserId);
  const language = useSelector((state) => state.users[currentUserId]?.language || state.uiSettings?.defaultLanguage);

  useEffect(() => {
    window.document.documentElement.lang = language ?? "en";
  }, [language]);
};
