import React, { useEffect, useState } from "react";
import { nameComparer } from "../../utils/sort";
import { hideMessage, showMessage } from "../../redux/storageConfigEditor";
import { Box, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { updateCustomer } from "../../api/CustomerAPI";
import { useCustomerMappings } from "../../selectors/storageConfigEditor";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useWindowDimensions } from "../../utils/resize";
import { VariableSizeList } from "react-window";
import CustomerConfigSelector from "../CustomerConfigSelector";
import CustomerNumberAvatar from "../CustomerNumberAvatar";
import SearchField from "../SearchField";
import { useCurrentRole } from "../../selectors/useRole";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 8,
  },
  header: {
    display: "flex",
    alignItems: "baseline",
    padding: "8px 0 12px 0",
  },
  filter: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "8px",
  },
  search: {
    maxWidth: "260px",
  },
  row: {
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
  },
  number: {
    width: 50,
  },
  name: {
    flex: 1,
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  selector: {
    margin: "0 4px",
    width: 200,
  },
}));

const getItemSize = () => 92;

const CustomerMapping = () => {
  const {
    cleanConfigs,
    configs,
    customerBagConfigs,
    customers,
    showCleanSystem,
    showCustomerBagSystem,
    showSoiledSystem,
    soiledConfigs,
  } = useCustomerMappings();
  const role = useCurrentRole();
  const [width, height] = useWindowDimensions();
  const [items, setItems] = useState([]);
  const [filter, setFilter] = useState("");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    setItems(
      Object.values(customers)
        .filter((x) => !filter || x.name.toLowerCase().includes(filter) || x.number.toString().includes(filter))
        .sort(nameComparer)
    );
  }, [customers, filter]);

  const clearLoader = () => {
    dispatch(hideMessage());
  };

  const handleCleanSelection = (customerId, configId) => {
    dispatch(showMessage(t("storageAssignments:saving_customer_mapping")));
    updateCustomer({
      customer: {
        id: customerId,
        cleanStorageConfigId: configId,
      },
      successCB: clearLoader,
      errorCB: clearLoader,
    });
  };

  const handleSoiledSelection = (customerId, configId) => {
    dispatch(showMessage(t("storageAssignments:saving_customer_mapping")));
    updateCustomer({
      customer: {
        id: customerId,
        soiledStorageConfigId: configId,
      },
      successCB: clearLoader,
      errorCB: clearLoader,
    });
  };

  const handleCustomerBagSelection = (customerId, configId) => {
    dispatch(showMessage(t("storageAssignments:saving_customer_mapping")));
    updateCustomer({
      customer: {
        id: customerId,
        cbStorageConfigId: configId,
      },
      successCB: clearLoader,
      errorCB: clearLoader,
    });
  };

  const filterChange = (term) => {
    setFilter(term?.toLowerCase());
  };

  return (
    <Paper className={classes.root}>
      {Object.values(customers).length > 10 && (
        <div className={classes.filter}>
          <Box sx={{ width: "260px" }}>
            <SearchField placeholder={t("common:filter")} onChange={filterChange} onSubmitSearchTerm={filterChange} />
          </Box>
        </div>
      )}
      <div className={classes.header}>
        <div className={classes.number}></div>
        <div className={classes.name}>
          <Typography variant={"h5"}>{t("common:name")}</Typography>
        </div>
        {showSoiledSystem && (
          <div className={classes.selector}>
            <Typography variant={"h5"}>{t("common:soiled")}</Typography>
          </div>
        )}
        {showCleanSystem && (
          <div className={classes.selector}>
            <Typography variant={"h5"}>{t("common:clean")}</Typography>
          </div>
        )}
        {showCustomerBagSystem && (
          <div className={classes.selector}>
            <Typography variant={"h5"}>{t("common:customer_bag")}</Typography>
          </div>
        )}
      </div>

      <VariableSizeList
        height={height - (items?.length > 10 ? 350 : 268)}
        width={"100%"}
        itemCount={items.length}
        itemSize={getItemSize}
        className="list-container"
      >
        {({ index, style }) => {
          const customer = items[index];
          const soiledConfig = configs?.[customer.soiledStorageConfigId];
          const cleanConfig = configs?.[customer.cleanStorageConfigId];
          const customerBagConfig = configs?.[customer.cbStorageConfigId];

          return (
            <div style={style} className={classes.row}>
              <div className={classes.number}>
                <CustomerNumberAvatar customerId={customer.id} />
              </div>
              <div className={classes.name}>
                <Typography>{customer.name}</Typography>
              </div>
              {showSoiledSystem && (
                <div className={classes.selector}>
                  <CustomerConfigSelector
                    title={t("common:soiled_configurations")}
                    subtitle={t("storageAssignments:choose_a_soiled_configuration")}
                    disabled={!role.canEditStorageConfigs}
                    selections={soiledConfigs}
                    selected={soiledConfig}
                    customerId={customer.id}
                    handleSelect={handleSoiledSelection}
                  />
                </div>
              )}
              {showCleanSystem && (
                <div className={classes.selector}>
                  <CustomerConfigSelector
                    title={t("common:clean_configurations")}
                    subtitle={t("storageAssignments:choose_a_clean_configuration")}
                    disabled={!role.canEditStorageConfigs}
                    selections={cleanConfigs}
                    selected={cleanConfig}
                    customerId={customer.id}
                    handleSelect={handleCleanSelection}
                  />
                </div>
              )}
              {showCustomerBagSystem && (
                <div className={classes.selector}>
                  <CustomerConfigSelector
                    title={t("storageAssignments:customer_bag_configurations")}
                    subtitle={t("storageAssignments:choose_a_customer_bag_configuration")}
                    disabled={!role.canEditStorageConfigs}
                    selections={customerBagConfigs}
                    selected={customerBagConfig}
                    customerId={customer.id}
                    handleSelect={handleCustomerBagSelection}
                  />
                </div>
              )}
            </div>
          );
        }}
      </VariableSizeList>
    </Paper>
  );
};

export default CustomerMapping;
