import { shallowEqual } from "react-redux";
import type { BatchDTO } from "@etechinc/overview-sdk";
import { getBatchById } from "../../../selectors/batches";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { getCustomerName, getCustomerNumber } from "../../../selectors/customers";
import { getCategoryName, getCategoryNumber } from "../../../selectors/categories";
import type { PrintRequestParams } from "../../../dto/slx/printApi/PrintRequestParams";

export const useSLXPrintJobRequestBody = (batchId: number): PrintRequestParams => useAppSelector((state) => {
  const railBatch: BatchDTO = getBatchById(batchId, state);

  return {
    customerId: getCustomerNumber(state, railBatch?.customerId),
    customerName: getCustomerName(state, railBatch?.customerId),
    categoryId: getCategoryNumber(state, railBatch?.categoryId),
    categoryName: getCategoryName(state, railBatch?.categoryId),
    batchId,
  };
}, shallowEqual);
