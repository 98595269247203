import React from "react";
import { useTranslation } from "react-i18next";
import { Button, FormControlLabel, SxProps } from "@mui/material";
import CustomActionIcon from "@mui/icons-material/SmartButton";
import { useCurrentRole } from "../../../selectors/useRole";
import { getShowCustomActionButton, getCustomActionButtonText } from "../../../selectors/slingInputStations";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { performCustomAction } from "../../../api/SlingInputStationApi";

interface Props {
  sisId: number;
  disabled?: boolean;
  sx?: SxProps;
};

const ConciseCustomActionButton = ({ sisId, disabled, sx }: Props) => {
  const { t } = useTranslation();
  const showCustomActionButton = useAppSelector((state) => getShowCustomActionButton(state, sisId));
  const customActionButtonText = useAppSelector((state) => getCustomActionButtonText(state, sisId));
  const { canControlInputLifts } = useCurrentRole();

  if (!showCustomActionButton) return null;
  return (
    <FormControlLabel
      control={
        <Button
          sx={(theme) => ({
            borderRadius: "50%",
            padding: 2,
            fontSize: 40,

            "&:disabled": {
              backgroundColor: theme.palette.grey[200],
            },

            "& #start-icon": {
              fontSize: 40,
            },
          })}
          variant="contained"
          disableElevation={true}
          size="large"
          disabled={disabled || !canControlInputLifts}
          onClick={() => performCustomAction({ sisId: sisId, successMessage: t("common:success") })}
        >
          <CustomActionIcon id="start-icon" />
        </Button>
      }
      label={customActionButtonText}
      labelPlacement="top"
      sx={sx}
    />
  );
};

export default ConciseCustomActionButton;
