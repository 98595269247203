import _ from "lodash";
import store from "../store";
import { showAlertError } from "../actions/alertActions";
import i18n from "../i18n";
import Client from "./Client.js";

export const getStorageConfigurations = ({ successCB }) => {
  Client.get("control_api/v1/storage_configurations")
    .then((response) => {
      const { storageConfigurations } = response.data;
      const storageConfigsLength = Object.entries(storageConfigurations).length;

      if (storageConfigsLength > 0) {
        successCB(storageConfigurations);
      }
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const cloneStorageConfiguration = ({ name, systemId, assignments, successCB }) => {
  Client.post(`control_api/v1/storage_configurations`, { name, assignments, systemId })
    .then((response) => {
      successCB(response.data);
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const createStorageConfiguration = ({ name, systemId, successCB }) => {
  Client.post(`control_api/v1/storage_configurations`, { name, systemId })
    .then((response) => {
      successCB(response.data);
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const updateStorageConfiguration = ({ routeConfig, successCB }) => {
  Client.put(`control_api/v1/storage_configurations/${routeConfig.id}`, routeConfig)
    .then((response) => {
      successCB(response.data.storageConfiguration);
    })
    .catch((error) => {
      const errorMessage = JSON.stringify(_.get(error, "response.data", "API Error"));
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${errorMessage}`));
    });
};

export const deleteStorageConfiguration = ({ id, successCB, errorCB }) => {
  Client.delete(`control_api/v1/storage_configurations/${id}`)
    .then((response) => {
      successCB(id);
    })
    .catch((error) => {
      const errorMessage = _.get(error, "response.data.error", "API Error");
      const customers = _.get(error, "response.data.customers", []);

      const messages = [`Error: ${errorMessage}`];
      if (customers.length > 0)
        messages.push(`\nThe following customers are still assigned: ${customers.map((name) => `\n${name}`)}.`);

      errorCB();
      return store.dispatch(showAlertError(`${i18n.t("common:error")}: ${messages.join(" ")}`));
    });
};
