import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { nameComparer } from "../../../utils/sort";
import { selectCustomersOfSortConfig } from "../redux/sortConfigurationsSlice";
import CustomerChip from "../../../components/CustomerChip";

const CustomerList = () => {
  const customers = useAppSelector(selectCustomersOfSortConfig, _.isEqual);
  const [count, setCount] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    setCount(customers?.length ?? 0);
  }, [customers]);

  return (
    <>
      {customers.length > 0 && (
        <Box sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          marginTop: 0.5,
          width: "100%",
          maxHeight: 117,
          overflow: "auto",
        }}>
          <Accordion TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant={"h4"} gutterBottom>
                {count} {count === 1 ? t("common:customer") : t("common:customers")}:
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                "& > *": {
                  margin: 0.5,
                },
              }}
            >
              {customers.sort(nameComparer).map((customer, i) => (
                <CustomerChip key={`customer-chip-${customer.id}`} customerId={customer.id} />
              ))}
            </AccordionDetails>
          </Accordion>
        </Box>
      )}
    </>
  );
};

export default CustomerList;
