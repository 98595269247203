import { shallowEqual } from "react-redux";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { getSystemFromId } from "../../../selectors/systems";
import { StrgRulesetRoutingTypes } from "../enum/StrgRulesetRoutingTypes";
import type { StorageRuleset } from "../dto/StorageRuleset";
import { getStorageRulesetForRailId } from "../services/storageRulesets";

const useStrgRailIdsWithOverrides = (systemId: number, storageRulesets: StorageRuleset[] = []) => {
  return useAppSelector((state) => {
    if (storageRulesets.length < 1) return [];
    const system = getSystemFromId(systemId, state);
    const railIdsWithOverrides = system?.storageRailIds?.reduce((acc: number[], railId: number) => {
      const storageRuleset = getStorageRulesetForRailId(storageRulesets, railId)
      if (storageRuleset && storageRuleset.routingType !== StrgRulesetRoutingTypes.AUTO) {
        acc.push(storageRuleset.railId)
      }
      return acc
    }, [])

    return railIdsWithOverrides;
  }, shallowEqual);
};

export default useStrgRailIdsWithOverrides;
