import React from "react";
import { useHistory } from "react-router-dom";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { useCurrentRole } from "../../selectors/useRole";
import { SETUP_CUSTOMERS } from "../../constants/Paths";

const AddCustomerButton = ({ size }) => {
  const currentRole = useCurrentRole();
  const history = useHistory();

  return (
    <Fab
      size={size}
      color="primary"
      disabled={!currentRole.canEditMasterData}
      onClick={() => history.push(`${SETUP_CUSTOMERS}/new`)}
    >
      <AddIcon />
    </Fab>
  );
};

export default AddCustomerButton;
