import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { parseLabel } from "../utils";
import FlagCard from "./FlagCard";
import SelectionDialog from "./SelectionDialog";

const ProductionListSelectionDialog = ({
  handleClose,
  handleSelect,
  open,
  selectedProductionListId,
  subtitle,
  title,
}) => {
  const productionLists = useSelector((state) => state.productionLists);

  return (
    <SelectionDialog open={open} handleClose={handleClose} subtitle={subtitle} title={title}>
      {Object.values(productionLists).map((productionList) => {
        return (
          <FlagCard
            key={productionList.id}
            name={productionList.name}
            label={parseLabel(productionList.name)}
            handleClick={handleSelect(productionList.id)}
            highlight={productionList.id === selectedProductionListId}
          />
        );
      })}
    </SelectionDialog>
  );
};

ProductionListSelectionDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedProductionListId: PropTypes.number,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

export default ProductionListSelectionDialog;
