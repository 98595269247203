import React from "react";
import { useTranslation } from "react-i18next";
import { Avatar, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useCategory } from "../../../selectors/categories";
import { useCustomer } from "../../../selectors/customers";
import { useWeightUnit } from "../../../selectors/uiSettings";
import { useLoadCellWeight } from "../../../selectors/loadCells";

const styles = makeStyles((theme) => ({
  heading: {
    textTransform: "uppercase",
  },
  bagWrapper: {
    height: 400,
    width: 293,
    backgroundColor: theme.palette.info.light,
    border: `2px solid ${theme.palette.info.main}`,
    borderRadius: "10px 10px 80px 80px",
  },
  bagInfo: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(1),
    color: theme.palette.common.white,
    width: "100%",
    borderRadius: "10px 10px 80px 80px",
  },
  imageAndNumber: {
    display: "flex",
    alignItems: "center",
  },
  image: {
    height: 50,
    width: 50,
    backgroundColor: theme.palette.grey[200],
    borderRadius: 5,
    marginRight: theme.spacing(1),
  },
  avatar: {
    border: `1px solid ${theme.palette.white}`,
    width: 50,
    height: 50,
    padding: `${0}px ${theme.spacing(1)}px`,
  },
}));

const Bag = ({ categoryId, customerId, loadCellId }) => {
  const classes = styles();
  const { t } = useTranslation();
  const category = useCategory(categoryId);
  const customer = useCustomer(customerId);
  const weight = useLoadCellWeight(loadCellId);
  const weightUnit = useWeightUnit();

  return (
    <Grid container className={classes.bagWrapper}>
      <Grid item className={classes.bagInfo}>
        <Typography color="inherit" variant="h5" align="center" className={classes.heading}>
          {t("common:category")}
        </Typography>

        <div className={classes.imageAndNumber}>
          {category.imageUrl && <img className={classes.image} src={category.imageUrl} />}

          <Avatar className={classes.avatar} variant="rounded" style={{ backgroundColor: customer.color }}>
            <Typography color="inherit" variant="h2">
              {category.number}
            </Typography>
          </Avatar>
        </div>

        <Typography color="inherit" variant="h3" align="center" style={{ marginBottom: 16 }}>
          {category.name}
        </Typography>

        <Typography color="inherit" variant="h5" align="center" className={classes.heading}>
          {t("common:customer")}
        </Typography>

        <div className={classes.imageAndNumber}>
          {customer.imageUrl && <img className={classes.image} src={customer.imageUrl} />}

          <Avatar className={classes.avatar} variant="rounded" style={{ backgroundColor: customer.color }}>
            <Typography color="inherit" variant="h2">
              {customer.number}
            </Typography>
          </Avatar>
        </div>

        <Typography color="inherit" variant="h3" align="center" style={{ marginBottom: 16 }}>
          {customer.name}
        </Typography>

        <Typography color="inherit" variant="h5" align="center" className={classes.heading}>
          {t("common:weight")}
        </Typography>
        <Typography color="inherit" variant="h2" align="center">
          {`${weight || 0} ${weightUnit}`}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Bag;
