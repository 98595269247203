import {
  INITIALIZE_LOCAL,
  ADD_BATCH,
  DESELECT_BATCH,
  DESELECT_MACHINE,
  DESELECT_RAIL,
  DESELECT_RAILPORT,
  SELECT_BATCH,
  SELECT_MACHINE,
  SELECT_MACHINE_VISIT,
  SELECT_RAILPORT,
  DESELECT_MACHINE_VISIT,
  SELECT_RAIL,
  SHOW_LOGIN,
  HIDE_LOGIN,
  TOGGLE_LOGIN,
  SELECT_IO_POINTS,
  DESELECT_IO_POINTS,
  SHOW_RAIL_SYSTEM_CONTROLS,
  HIDE_RAIL_SYSTEM_CONTROLS,
  SHOW_RAIL_SETTINGS,
  HIDE_RAIL_SETTINGS,
  TOGGLE_RAIL_SETTINGS,
  SET_TOP_TEXT_TYPE,
  SET_BOTTOM_TEXT_TYPE,
  SET_TOP_COLOR_TYPE,
  SET_BOTTOM_COLOR_TYPE,
  SET_RAIL_SHOW_ID,
  SHOW_PLC_TROUBLESHOOT,
  HIDE_PLC_TROUBLESHOOT,
  SHOW_SERVER_TROUBLESHOOT,
  HIDE_SERVER_TROUBLESHOOT,
  SHOW_BATCH_HIGHLIGHTER,
  HIDE_BATCH_HIGHLIGHTER,
  TOGGLE_BATCH_HIGHLIGHTER,
  SET_CATEGORY_HIGHLIGHT,
  SET_CUSTOMER_HIGHLIGHT,
  SET_BARCODE_HIGHLIGHT,
  SHOW_ABOUT,
  HIDE_ABOUT,
} from "./types";

export const initializeLocal = (data) => {
  return {
    type: INITIALIZE_LOCAL,
    data: data,
  };
};

// TODO: Unused function:
export const addBatch = (railId) => {
  return {
    type: ADD_BATCH,
    railId: railId,
  };
};

export function deselectBatch() {
  return {
    type: DESELECT_BATCH,
  };
}

export const deselectIOPoints = () => {
  return {
    type: DESELECT_IO_POINTS,
  };
};

export function deselectMachine() {
  return {
    type: DESELECT_MACHINE,
  };
}

export function deselectMachineVisit() {
  return {
    type: DESELECT_MACHINE_VISIT,
  };
}

export function deselectRail() {
  return {
    type: DESELECT_RAIL,
  };
}

export function deselectRailport() {
  return {
    type: DESELECT_RAILPORT,
  };
}

export const showLogin = () => {
  return {
    type: SHOW_LOGIN,
  };
};

export const hideLogin = () => {
  return {
    type: HIDE_LOGIN,
  };
};

export const toggleLogin = () => {
  return {
    type: TOGGLE_LOGIN,
  };
};

export function selectBatch(railId, position, batchId, startTime) {
  return {
    type: SELECT_BATCH,
    railId: railId,
    position: position,
    batchId: batchId,
    startTime: startTime,
  };
}

export const selectIOPoints = (ioPointIds) => {
  return {
    type: SELECT_IO_POINTS,
    ioPointIds,
  };
};

export function selectMachine(machineId) {
  return {
    type: SELECT_MACHINE,
    machineId: machineId,
  };
}

export function selectMachineVisit(machineId, position, batchId) {
  return {
    type: SELECT_MACHINE_VISIT,
    machineId: machineId,
    position: position,
    batchId: batchId,
  };
}

export function selectRail(railId) {
  return {
    type: SELECT_RAIL,
    railId: railId,
  };
}

export function selectRailport(deviceId) {
  return {
    type: SELECT_RAILPORT,
    deviceId: deviceId,
  };
}

export const showRailSettings = () => {
  return {
    type: SHOW_RAIL_SETTINGS,
  };
};

export const hideRailSettings = () => {
  return {
    type: HIDE_RAIL_SETTINGS,
  };
};

export const toggleRailSettings = () => ({
  type: TOGGLE_RAIL_SETTINGS,
});

export const showRailSystemControls = () => {
  return {
    type: SHOW_RAIL_SYSTEM_CONTROLS,
  };
};

export const hideRailSystemControls = () => {
  return {
    type: HIDE_RAIL_SYSTEM_CONTROLS,
  };
};

export const setTopTextType = (value) => {
  return {
    type: SET_TOP_TEXT_TYPE,
    data: value,
  };
};

export const setBottomTextType = (value) => {
  return {
    type: SET_BOTTOM_TEXT_TYPE,
    data: value,
  };
};

export const setTopColorType = (value) => {
  return {
    type: SET_TOP_COLOR_TYPE,
    data: value,
  };
};

export const setBottomColorType = (value) => {
  return {
    type: SET_BOTTOM_COLOR_TYPE,
    data: value,
  };
};

export const setRailShowId = (value) => {
  return {
    type: SET_RAIL_SHOW_ID,
    data: value,
  };
};

export const showPLCTroubleshoot = () => ({
  type: SHOW_PLC_TROUBLESHOOT,
});

export const hidePLCTroubleshoot = () => ({
  type: HIDE_PLC_TROUBLESHOOT,
});

export const showServerTroubleshoot = () => ({
  type: SHOW_SERVER_TROUBLESHOOT,
});

export const hideServerTroubleshoot = () => ({
  type: HIDE_SERVER_TROUBLESHOOT,
});

export const showBatchHighlighter = () => ({
  type: SHOW_BATCH_HIGHLIGHTER,
});

export const hideBatchHighlighter = () => ({
  type: HIDE_BATCH_HIGHLIGHTER,
});

export const toggleBatchHighhlighter = () => ({
  type: TOGGLE_BATCH_HIGHLIGHTER,
});

export const setCategoryHighlight = (categoryId) => ({
  type: SET_CATEGORY_HIGHLIGHT,
  data: categoryId,
});

export const setCustomerHighlight = (customerId) => ({
  type: SET_CUSTOMER_HIGHLIGHT,
  data: customerId,
});

export const setBarcodeHighlight = (barcodeId) => ({
  type: SET_BARCODE_HIGHLIGHT,
  data: barcodeId,
});

export const showAbout = () => ({
  type: SHOW_ABOUT,
});

export const hideAbout = () => ({
  type: HIDE_ABOUT,
});
