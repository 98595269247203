import React from "react";
import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";

const StyledGridItem = withStyles((theme) => ({
  root: {
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}))(Grid);

export default StyledGridItem;
