import React, { useState, useEffect } from "react";
import { Backdrop, Box, CardContent, CircularProgress, Divider, Grid, Hidden, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { fetchCustomer, addCustomer, deleteCustomer, setupUpdateCustomer } from "../../api/CustomerAPI";
import { SETUP_CUSTOMERS } from "../../constants/Paths";
import { useCurrentRole } from "../../selectors/useRole";
import FormEditor from "../SetupPages/FormEditor";
import SaveButton from "../SaveButton";
import CustomersListButton from "./CustomersListButton";
import DescriptionGroup from "./DescriptionGroup";
import OtherGroup from "./OtherGroup";
import DeleteCustomerButtonConfirmation from "./DeleteCustomerButtonConfirmation";

const SetupForm = ({ customer, onSubmitDone }) => {
  const { t } = useTranslation();
  const currentRole = useCurrentRole();

  return (
    <FormEditor
      originalValues={{ ...customer }}
      onSubmit={({ initialValues, currentValues, actions }) => {
        const valuesForSubmission = Object.keys(currentValues).reduce((acc, key) => {
          if (key === "imageUrl" && initialValues["imageUrl"] === currentValues["imageUrl"]) {
            return acc;
          }
          return { ...acc, [key]: currentValues[key] === "" ? null : currentValues[key] };
        }, {});

        customer?.id
          ? setupUpdateCustomer({
              customer: { ...valuesForSubmission },
              successCB: () => {
                actions.finishSubmit();
                onSubmitDone();
              },
              errorCB: (errors) => {
                actions.finishSubmit(true);
                actions.catchErrors(errors);
              },
            })
          : addCustomer({
              customer: { ...valuesForSubmission },
              successCB: () => {
                actions.finishSubmit();
                onSubmitDone();
              },
              failureCB: (errors) => {
                actions.finishSubmit(true);
                actions.catchErrors(errors);
              },
            });
      }}
      onDelete={({ actions }) => {
        deleteCustomer({
          customerId: customer.id,
          force: true,
          successCB: () => onSubmitDone(),
          failureCB: () => actions.finishDelete(),
        });
      }}
    >
      {(props) => {
        const {
          currentValues,
          dirtyForm,
          errors,
          isSubmitting,
          isDeleting,
          setFieldValue,
          setArrayFieldValue,
          handleSubmit,
          handleDelete,
        } = props;

        const formDisabled = customer.deletedAt || isSubmitting || !currentRole.canEditMasterData;
        const defaultCustomer = customer?.number === 0;

        return (
          <>
            <CardContent sx={{ height: "100%", overflow: "auto" }}>
              <Backdrop open={isSubmitting || isDeleting}>
                <CircularProgress color="inherit" />
              </Backdrop>

              <Grid container>
                <Grid item xs={12} sm={4} sx={{ pr: 7 }}>
                  <DescriptionGroup
                    currentValues={currentValues}
                    disabled={formDisabled}
                    errors={errors}
                    setFieldValue={setFieldValue}
                  />
                </Grid>

                <Grid item xs={12} sm={8}>
                  <Hidden smUp={true}>
                    <Divider sx={{ mt: 5, mb: 2 }} />
                  </Hidden>

                  <OtherGroup
                    currentValues={currentValues}
                    disabled={formDisabled}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    setArrayFieldValue={setArrayFieldValue}
                  />
                </Grid>
              </Grid>
            </CardContent>

            <Divider />

            <Grid item container justifyContent="flex-end" sx={{ padding: 3 }}>
              {customer.id && (
                <Box mr={1}>
                  <DeleteCustomerButtonConfirmation
                    name={currentValues.name}
                    disabled={formDisabled || defaultCustomer}
                    onDelete={handleDelete}
                  />
                </Box>
              )}

              <SaveButton disabled={formDisabled || !dirtyForm} onClick={handleSubmit}>
                {t("common:submit")}
              </SaveButton>
            </Grid>
          </>
        );
      }}
    </FormEditor>
  );
};

const CustomerEdit = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const currentRole = useCurrentRole();

  const params = useParams();
  const isNew = params.id === "new";

  const [customer, setCustomer] = useState();

  useEffect(() => {
    if (!isNew) {
      fetchCustomer({
        customerId: params.id,
        successCB: (customer) => setCustomer(customer),
      });
    } else {
      setCustomer({});
    }
  }, [isNew, params.id]);

  let heading = currentRole.canEditMasterData ? "edit_customer" : "view_customer";
  if (customer?.deletedAt) {
    heading = "view_deleted_customer";
  }
  if (isNew) {
    heading = "add_customer";
  }

  const location = useLocation();
  const handleGoToList = () => {
    const newLocation = {
      pathname: SETUP_CUSTOMERS,
      state: { listScrollPos: location.state?.listScrollPos, searchTerm: location.state?.searchTerm },
    };
    history.push(newLocation);
  };

  return (
    <Paper square={true} elevation={0} sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box padding={3} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h1">{t(`customersSetup:${heading}`)}</Typography>
        <CustomersListButton onClick={handleGoToList} />
      </Box>

      <Divider />

      {customer ? (
        <SetupForm customer={customer} onSubmitDone={handleGoToList} />
      ) : (
        <Backdrop open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Paper>
  );
};

export default CustomerEdit;
