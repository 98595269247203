import React, { useState } from "react";
import { Box, CardContent, Divider, Grid, Paper, Typography } from "@mui/material";
import Loader from "../../../components/Loader";
import { useIsLoggedInUserTechnician } from "../../../selectors/roles";
import FormEditor from "../../../components/SetupPages/FormEditor";
import SaveButton from "../../../components/SaveButton";
import type { SystemConfigs } from "../dto/SystemConfigs";
import type { ResponseError } from "../dto/ResponseError";
import SingleConfig from "./SingleConfig";
import Tabs from "./Tabs";
import { useGetSystemConfigsQuery, useUpdateSystemConfigsMutation } from "../services/systemConfigs";

type TemporaryConfigs = { [key: string]: string | number | boolean | undefined };

const generateInitialValues = (configs: SystemConfigs) => {
  return Object.keys(configs).reduce((acc, configKey) => {
    acc[configKey] = configs[configKey]?.value;
    return acc;
  }, {} as TemporaryConfigs);
};

const SetupForm = ({ view, configs }: { view: string, configs: SystemConfigs }) => {
  const originalValues = generateInitialValues(configs);
  const isTechnicianLoggedIn = useIsLoggedInUserTechnician();

  const [updateSystemConfigs, { isLoading }] = useUpdateSystemConfigsMutation();

  return (
    <FormEditor
      originalValues={originalValues}
      onSubmit={({ currentValues, actions }: { currentValues: TemporaryConfigs, actions: any }) => {
        const valuesForSubmission = Object.keys(currentValues).reduce((acc, key) => {
          return { ...acc, [key]: currentValues[key] === "" ? null : currentValues[key] };
        }, {});

        updateSystemConfigs({ configs: valuesForSubmission })
          .unwrap()
          .then((payload) => actions.finishSubmit())
          .catch((errors) => {
            actions.finishSubmit();
            actions.catchErrors(errors?.data?.error as ResponseError)
          });
      }}
    >
      {({ currentValues, dirtyForm, errors, setFieldValue, handleSubmit }) => {
        const configsForView = Object.values(configs).filter((config) => config.category === view);

        return (
          <>
            <CardContent sx={{ height: "100%", overflow: "auto" }}>
              <Grid container>
                <Loader open={isLoading} message="Submitting..." transitionDuration={1000} />

                {configsForView.map((config) => (
                  <SingleConfig
                    key={config.parameter}
                    config={config}
                    currentValues={currentValues}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    disabled={!isTechnicianLoggedIn || isLoading}
                  />
                ))}
              </Grid>
            </CardContent>

            <Divider />

            <Grid item container justifyContent="flex-end" sx={{ padding: 3 }}>
              <SaveButton disabled={!isTechnicianLoggedIn || isLoading || !dirtyForm} onClick={handleSubmit}>
                Submit
              </SaveButton>
            </Grid>
          </>
        );
      }}
    </FormEditor>
  );
};

const SystemConfigEdit = () => {
  const { data, isLoading, isSuccess, isError } = useGetSystemConfigsQuery();
  const [selectedTab, setSelectedTab] = useState("eVue Labor");

  return (
    <Paper square={true} elevation={0} sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box padding={3} display="flex" justifyContent="space-between" flexWrap="wrap" alignItems="flex-end">
        <Typography sx={{ mb: 0.5 }} variant="h1">
          System Configurations Setup
        </Typography>
        {isSuccess && <Tabs sx={{ mt: 0.5 }} selectedTab={selectedTab} onSelect={setSelectedTab} />}
      </Box>

      <Divider />

      {isLoading && <Loader open={true} transitionDuration={1000} message="Loading Configs..." />}
      {isSuccess && data && <SetupForm view={selectedTab} configs={data} />}
      {isError && <Typography>Configs Fetch Error</Typography>}
    </Paper>
  );
};

export default SystemConfigEdit;
