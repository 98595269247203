import React from "react";
import { useParams } from "react-router-dom";
import { Box, Divider, Paper } from "@mui/material";
import { useRail } from "../../selectors/rails";
import Bag from "../../components/SlingInputStation/DenmanGarbageChute/Bag";
import BagStatusIndicator from "../../components/SlingInputStation/BagStatusIndicator";
import { useIsSlingLoaded } from "./hooks/useIsSlingLoaded";
import { useFirstBatchFromRailId } from "./hooks/useFirstBatchFromRailId";
import TitleBar from "./TitleBar";

interface DischargerURLParams {
  id?: string | undefined;
};

const SupertrackDischarger = () => {
  const params: DischargerURLParams = useParams();
  const railId = params.id;
  const rail = useRail(railId);
  const batch = useFirstBatchFromRailId(rail?.id);
  const slingLoaded = useIsSlingLoaded(rail?.id);

  return (
    <Paper square={true} elevation={0} sx={{ width: "100%", height: "100%" }}>
      <Box padding={2}>
        <TitleBar rail={rail} />
      </Box>

      <Divider />

      <Box display="flex" justifyContent="center" padding={2}>
        {slingLoaded ? (
          <Bag
            categoryId={batch.categoryId}
            customerId={batch.customerId}
            loadCellId={rail.loadCellId}
          />
        ) : (
          <BagStatusIndicator status="missing" currentWeight={0} minWeight={0} targetWeight={0} maxWeight={0} />
        )}
      </Box>
    </Paper>
  );
};

export default SupertrackDischarger;
