import { useSelector, shallowEqual } from "react-redux";

export const useCustChangeIdsForNotifications = () =>
  useSelector((state) => {
    const custChangeStations = state.custChangeStations;
    return Object.keys(custChangeStations).filter((ccId) => {
      return custChangeStations[ccId]?.externalCustomerId;
    });
  }, shallowEqual);

export const useRailIdsForNotifications = () =>
  useSelector((state) => {
    const rails = state.rails;
    return Object.keys(rails).filter((railId) => rails[railId]?.decisionMessageLabel);
  }, shallowEqual);
