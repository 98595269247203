import React, { useState } from "react";
import { parseLabel } from "../../utils";
import { selectList } from "../../redux/productionListEditor";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import FlagCard from "../FlagCard";
import ProductionListSelectionDialog from "../ProductionListSelectionDialog";

const ProductionListSelector = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { name, id } = useSelector((state) => state.productionListEdit);
  const { t } = useTranslation();

  const handleSelect = (productionListId) => () => {
    setOpen(false);
    dispatch(selectList(productionListId));
  };

  return (
    <>
      <FlagCard
        handleClick={() => setOpen(true)}
        name={name ? name : t("productionList:select_a_production_list")}
        label={parseLabel(name ? name : "P L")}
      />

      <ProductionListSelectionDialog
        title={t("productionList:production_lists")}
        subtitle={t("productionList:select_one_for_editing")}
        selectedProductionListId={id}
        open={open}
        handleSelect={handleSelect}
        handleClose={() => setOpen(false)}
      />
    </>
  );
};

export default ProductionListSelector;
